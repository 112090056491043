import React, { useState } from 'react';
import styled from 'styled-components';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import AsmaImage from '../../Assets/SignUp/quotes/Asma_SR.jpeg';
import KaananImage from '../../Assets/SignUp/quotes/Kaanan.png';
import { useTranslation } from 'react-i18next';

const quotes = [
  {
    image: AsmaImage,
    fullName: 'asmaAhmed',
    quote: 'asmaQuote',
  },
  // {
  //   image: ClaireImage,
  //   fullName: 'clairePyer',
  //   quote:
  //     'claireQuote',
  // },
  {
    image: KaananImage,
    fullName: 'kaanan',
    quote: 'kaananQuote',
  },
];

const CarouselWithDot = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const onChange = (value) => {
    setValue(value);
  };

  return (
    <CarouselWrapper>
      <h2 style={{ marginBottom: 40 }}>{t('whatOurTherapistsSay')}</h2>
      <Carousel selectedItem={value} onChange={onChange} autoPlay={true} infiniteLoop={true} showThumbs={false}>
        {quotes.map(({ image, fullName, quote }) => (
          <ItemWrapper key={`carousel-item-${t(fullName)}`}>
            <Reviewer>
              <ImageWrapper>
                <Image src={image} />
              </ImageWrapper>
              <ReviewerInfo>
                <ReviewerName>{t(fullName)}</ReviewerName>
                <ReviewerQualification>{t('therapist')}</ReviewerQualification>
              </ReviewerInfo>
            </Reviewer>
            <ReviewerText>
              <span style={{ fontWeight: 600 }}>“</span>
              {t(quote)}
              <span style={{ fontWeight: 600 }}>”</span>
            </ReviewerText>
          </ItemWrapper>
        ))}
      </Carousel>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  padding: 48px 32px;

  max-width: 1000px;
  margin: 0 auto;

  .carousel {
    overflow: initial !important;
  }

  .control-arrow,
  .carousel-status {
    display: none;
  }

  .control-dots {
    bottom: -40px;
    li {
      width: 16px !important;
      height: 16px !important;
      background: #c4c4c4 !important;
      box-shadow: none !important;
      outline: none;
    }

    .selected {
      background: #00c8a0 !important;
    }
  }

  @media screen and (max-width: 590px) {
    padding: 32px 32px;
  }
`;

const ItemWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 590px) {
    flex-wrap: wrap;
  }
`;

const Reviewer = styled.div`
  width: 35%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 590px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  min-width: 80px;
  max-width: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
`;

const Image = styled.img`
  width: 100%;
`;

const ReviewerInfo = styled.div``;

const ReviewerName = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: black;
`;

const ReviewerQualification = styled.div`
  font-size: 16px;
  color: black;
`;

const ReviewerText = styled.div`
  width: 65%;

  margin-left: 40px;

  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #9d9d9d;
  mix-blend-mode: normal;

  @media screen and (max-width: 780px) {
    margin-left: 50px;
  }

  @media screen and (max-width: 590px) {
    margin-left: 100px;
    width: 100%;
  }

  @media screen and (max-width: 450px) {
    margin-left: 0;
    width: 100%;
  }
`;

export default CarouselWithDot;
