
import SocketIOClient from "socket.io-client";
let isConnected = false;
// const socket = SocketIOClient("https://chat.supportroom.com:3000",{
const socket = SocketIOClient(process.env.REACT_APP_SOCKET_URL, {
    transports: ['websocket']
});
socket.on('connect', () => {
    isConnected = true;
})

socket.on('disconnect', () => {
    isConnected = false;
})

export {
    socket,
    isConnected
}
