import React, { memo } from 'react';
import styled from 'styled-components';

const Switch = ({ isOn, handleToggle, title }) => {
  return (
    <ToggleSwitch className="switch" title={title || (isOn ? 'Online' : 'Offline')}>
      <input type="checkbox" name="status" id="status" onChange={handleToggle} checked={isOn} />
      <span className="slider round" />
    </ToggleSwitch>
  );
};

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 26px;
  height: 15px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.statusDanger};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 11px;
    width: 11px;
    left: 2px;
    bottom: 2px;
    background-color: ${({ theme }) => theme.white};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.statusSuccess};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(11px);
    -ms-transform: translateX(11px);
    transform: translateX(11px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 26px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export default memo(Switch);
