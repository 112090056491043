import React, { memo } from 'react';
import moment from 'moment';

const Intake = ({ notes, goToNote }) => {
  return (
    <>
      <h3>Therapy Journey &raquo; In-Take</h3>
      <table className="striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Note</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {notes && notes.length ? (
            notes.map((note) => {
              const comment = note.notes || note.note;
              return (
                <tr key={note.id}>
                  <td>{moment(note.created_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')}</td>
                  <td style={{ maxWidth: 500 }}>{comment.length > 70 ? `${comment.substring(0, 70)}...` : comment}</td>
                  <td style={{ cursor: 'pointer' }} onClick={() => goToNote(note.id)}>
                    View
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={3}>No notes available</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default memo(Intake);
