import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { switchTherapist } from '../../../Actions/chatroom.actions';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Input } from '../../Atoms';
import ModalComponent from '../../Atoms/Modal/Modal';

const SwitchTherapistPopup = ({ chatroomID, notificationID, closeForwardModal, markAsRead }) => {
  const [shorthandNotes, setShorthandNotes] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleSwitch = async (forwardNotes) => {
    await dispatch(
      switchTherapist({
        chatroom_id: chatroomID,
        forward_notes: forwardNotes,
        shorthand_notes: shorthandNotes || undefined,
        notification_id: notificationID,
      }),
    );
    markAsRead(notificationID);
    closeForwardModal();
  };

  return (
    <ModalComponent open closeModal={closeForwardModal}>
      <Heading level={3}>{t("forwardNotes")}</Heading>
      <p>{t("switchTherapist")}</p>
      <Input
        onChange={({ target: { value } }) => setShorthandNotes(value)}
        placeholder={t("switchTherapistPlaceholder")}
        value={shorthandNotes}
      />
      <ModalActions>
        <Button className="ghost" onClick={() => handleSwitch(0)}>
        {t("decline")}
        </Button>
        <Button onClick={() => handleSwitch(1)}>{t("forward")}</Button>
      </ModalActions>
    </ModalComponent>
  );
};

const ModalActions = styled.div`
  margin-top: ${({ theme }) => theme.spacing};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  gap: ${({ theme }) => theme.spacingSm};
  justify-content: center;

  ${({ theme }) => theme.md`
    justify-content: flex-end;
  `};
`;
ModalActions.displayName = 'ModalActions';

SwitchTherapistPopup.propTypes = {
  chatroomID: PropTypes.number.isRequired,
  notificationID: PropTypes.number.isRequired,
  closeForwardModal: PropTypes.func.isRequired,
};

export default SwitchTherapistPopup;
