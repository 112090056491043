import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ChevronIcon, TextBlock, Loading } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import Image from '../../Assets/support_room_welcome_finish.png';
import { MainSectionWrapper, BottomSectionWrapper } from '../Components/';
import { useDeviceType } from '../../Util';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import EmergencyBtn from '../../Components/Atoms/EmergencyBtn';

const Finish = () => {
  const { t } = useTranslation();
  // const socialRequestPopupStatus = useSelector((store) => store.auth.socialRequestPopupStatus);
  const profile = useSelector((store) => store?.user.profile);
  const loginInfo = localStorage.getItem('loginInfo');
  const isLoggedIn = !!loginInfo;
  const history = useHistory();
  const userMeta = useSelector((store) => store.user);
  const isMobile = useDeviceType();

if (isLoggedIn && !profile?.quiz_group && profile?.payment_status === "paid") history.push('/welcome/questionnaire');

  return (
    <WelcomeLayout noPadding noTopBar>
      <section>
        <MainSectionWrapper isMobile={isMobile} noPadding>
          {!userMeta?.profile?.first_name && <Loading />}
          <TextBlock type={'primary'}>
          {t("readyToStart")}{' '}
            <span style={{ textTransform: 'capitalize' }}>{`${userMeta?.profile?.first_name || ''} ${
              userMeta?.profile?.last_name || ''
            }`}</span>
            .
          </TextBlock>
          <TextBlock type={'secondary'}>{t("letsShow")}</TextBlock>
          <ImageWrapper>
            <img src={Image} height="250" alt="finish" />
          </ImageWrapper>
          <ButtonWrapper isMobile={isMobile}>
            <Button
              variant="secondary"
              style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
              onClick={() => history.push('/welcome/general')}
            >
              {t("checkItOut")}
              <ChevronIcon direction="right" width="13" height="13" />
            </Button>
          </ButtonWrapper>
        </MainSectionWrapper>
      </section>
      <section>
        <BottomSectionWrapper>
          <EmergencyBtn />
        </BottomSectionWrapper>
      </section>
    </WelcomeLayout>
  );
};

const ImageWrapper = styled.div`
  margin: 25px 0;
`;

const ButtonWrapper = styled.div`
  position: ${({ isMobile }) => (isMobile ? 'absolute' : 'inherit')};
  bottom: 0;
  z-index: 999;
`;
export default memo(Finish);
