import React, { memo, useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, TextBlock, ChevronIcon, Loading } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import { addProgress, startquiz, submitQuizAnswer, terminateQuestionnaire } from '../../Actions/questionnaire.actions';
import { MainSectionWrapper, BottomSectionWrapper, ModalOverlay, Modal } from '../Components/';
import { checkPartner, useDeviceType } from '../../Util';
import EmergencyBtn from '../../Components/Atoms/EmergencyBtn';
import { useTranslation } from 'react-i18next';

const Quesitionnaire = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const gymPassUserId = query.get('gympass_user_id');
  const { t } = useTranslation();

  const dispatch = useDispatch();
  // const socialRequestPopupStatus = useSelector((store) => store.auth.socialRequestPopupStatus);
  const isSubmittingAnswer = useSelector((store) => store.questionnaire.isSubmittingAnswer);
  const fetchingQuiz = useSelector((store) => store.questionnaire.fetchingQuiz);
  const quizdata = useSelector((state) => state.questionnaire.quizdata);
  const patientProfile = useSelector((state) => state?.user?.profile);
  const isPartnerCheck = useSelector((state) => state?.isPartner?.isPartner);
  const subscription = useSelector((state) => state?.user?.subscription);
  const isMobile = useDeviceType();
  const loginInfo = localStorage.getItem('loginInfo');
  const isLoggedIn = !!loginInfo;
  const history = useHistory();
  //const [noAnswerError, setNoAnswerError] = useState(false);
  const [selectedAnswerIDs, setSelectedAnswerIDs] = useState([]);
  const [redirectNeeded, setRedirectNeeded] = useState(false);
  const [redirectInt, setRedirectInt] = useState(false);
  const [finalRedirect, setFinalRedirect] = useState(false);
  const [showHelpline, setShowHelpline] = useState(false);
  const [isAlcohol, setIsAlcohol] = useState(false);
  const [isChildProtection, setIsChildProtection] = useState(false);
  const checkIsPartner = useCallback(() => dispatch(checkPartner()), [dispatch]);
  const terminateQuestions = useCallback(() => dispatch(terminateQuestionnaire()), [dispatch]);
  const [answeredIds, setAnsweredIds] = useState([]);
  /**
   *   We will set a variable to handle the redirect pop up
   *    This variable will set to false if the user choose to continue( ie 'No' for the next question)
   */
  const [stopQuestionnaire, setStopQuestionnaire] = useState(false);

  //This determines whether the user is b2b, b2c or smb based on the value
  const { userType } = useParams();

  const handleChildQuestion = (_answers) => {
    if (quizdata.data?.question && quizdata.data?.question.toLowerCase().indexOf('child protection') > -1) {
      let _continue = true;
      _answers.forEach((answer) => {
        if (answer.id === selectedAnswerIDs[0]) {
          if (answer.answer.toLowerCase() === 'yes, at the moment') {
            setIsChildProtection(true);
            _continue = false;
          }
        }
      });
      return _continue;
    }
    return true;
  };

  const handleRedirectQuestion = (_answers) => {
    let _continue = true;
    if (stopQuestionnaire) {
      _answers.forEach((answer) => {
        if (answer.id === selectedAnswerIDs[0]) {
          if (answer.answer.toLowerCase() === 'yes') {
            setFinalRedirect(true);
            _continue = false;
          }
        }
      });
    }
    return _continue;
  };
  useEffect(() => {
    if (patientProfile?.quiz_group && subscription?.plan_status === 'active' && patientProfile['B2B'] !== 1) {
      history.push('/welcome/therapists');
    } else if (patientProfile?.quiz_group && subscription?.plan_status === 'expired' && patientProfile['B2B'] !== 1) {
      history.push('/welcome/plans');
    } else if (patientProfile?.quiz_group && patientProfile['B2B'] !== 1) {
      history.push('/welcome/start');
    } else if (patientProfile?.quiz_group && patientProfile['B2B'] === 1) {
      history.push('/moodbox');
    }
  }, [patientProfile, subscription, history]);

  const setSelectedAnswer = (id) => {
    let ids = [];
    if (quizdata.data.no_of_answer === 1) {
      ids = [id];
    } else {
      if (selectedAnswerIDs.includes(id)) {
        ids = selectedAnswerIDs.filter((answer) => answer !== id);
      } else if (selectedAnswerIDs.length < quizdata.data.no_of_answer) {
        ids = [...selectedAnswerIDs, id];
      } else {
        return false;
      }
    }
    setSelectedAnswerIDs(ids);
  };
  const handleNextQuestion = () => {
    if (!selectedAnswerIDs.length) {
      //setNoAnswerError(true);
      return;
    }
    setIsAlcohol(false);
    setAnsweredIds((array) => [...array, quizdata.data.id]);
    let _showHelper = false;
    if (quizdata.data?.question && quizdata.data?.question.toLowerCase().indexOf('alcohol') > -1) {
      setIsAlcohol(true);
      _showHelper = true;
    } else if (quizdata.data?.question && quizdata.data?.question.toLowerCase().indexOf('drug') > -1) {
      setIsAlcohol(false);
      _showHelper = true;
    }

    const _answers = quizdata.data?.answers;
    //This is to check whether the question contains child protection and show the help popup accourdingly
    const _continue = handleChildQuestion(_answers);
    if (!_continue) {
      return false;
    }

    if (_showHelper) {
      _answers.forEach((answer) => {
        if (answer.id === selectedAnswerIDs[0]) {
          if (answer.answer.toLowerCase() === 'daily') setShowHelpline(true);
        }
      });
    }

    // This will validate the
    const _continueQuestion = handleRedirectQuestion(_answers);
    if (!_continueQuestion) {
      return false;
    }

    dispatch(
      submitQuizAnswer(
        localStorage.getItem('quiz_journey'),
        quizdata.data.id,
        selectedAnswerIDs,
        gymPassUserId,
        history,
      ),
    ).then((res) => {
      if (gymPassUserId && !res) return;
      //setNoAnswerError(true);
      setSelectedAnswerIDs([]);
      dispatch(addProgress(7));
      setStopQuestionnaire(false);
      if (res.redirect_link) {
        setRedirectNeeded(true);
        setStopQuestionnaire(true);
      }
      if (res.translation_id === 173) {
        if (patientProfile && patientProfile['B2B'] === 1 && isLoggedIn) {
          history.push('/welcome/therapists');
          return;
        }
        history.push(userType ? `/welcome/${userType}/thankyou` : '/welcome/thankyou');
      }
    });
  };
  const closeAllModals = () => {
    setRedirectNeeded(false);
    setFinalRedirect(false);
    if (isChildProtection || stopQuestionnaire) terminateQuestions();
    history.push('/welcome');
  };
  // const handleFinish = () => {
  //   setRedirectNeeded(false);
  //   setRedirectInt(true);
  // }

  const handleRedirect = () => {
    if (!quizdata?.data.question) {
      setFinalRedirect(true);
    } else {
      setRedirectNeeded(false);
    }
  };

  useEffect(() => {
    if (!quizdata?.data?.question) dispatch(startquiz());
    return () => {
      dispatch(addProgress(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (quizdata?.data?.no_of_answer === 1 && selectedAnswerIDs.length) handleNextQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswerIDs]);

  useEffect(() => {
    if (quizdata?.data && quizdata?.data.question) setRedirectInt(false);
  }, [quizdata]);

  useEffect(() => {
    if (isPartnerCheck === '') {
      checkIsPartner();
    }
  }, [isPartnerCheck, checkIsPartner]);

  const goBack = () => {
    if (answeredIds.length === 0) {
      return history.push('/');
    }
    const newArray = [...answeredIds];
    newArray.pop();
    setAnsweredIds(newArray);
  };

  return (
    <WelcomeLayout noPadding noTopBar onBackButtonClick={goBack}>
      <MainSectionContainer>
        {!redirectInt && (
          <MainSectionWrapper isMobile={isMobile && !quizdata.data?.answers?.length > 6}>
            <QuestionWrapper>
              {(isSubmittingAnswer || fetchingQuiz) && <Loading />}
              <Question isMobile={isMobile}>
                <QuestionItem question={quizdata.data?.question} />
              </Question>
              {quizdata.data?.headline && <Headline isMobile={isMobile}>{quizdata.data?.headline}</Headline>}
              {quizdata.data?.green && <OptionNote isMobile={isMobile}>{quizdata.data?.green}</OptionNote>}
              <ActionButton question={quizdata.data?.question} />
              <AnswerWrapper>
                {quizdata.data?.answers?.map((answer) => (
                  <AnswerOption
                    className={`answerOption ${selectedAnswerIDs.includes(answer.id) ? 'active' : ''}`}
                    key={answer.id}
                    onClick={() => setSelectedAnswer(answer.id)}
                  >
                    {answer.answer}
                  </AnswerOption>
                ))}
              </AnswerWrapper>
            </QuestionWrapper>
          </MainSectionWrapper>
        )}
        {redirectInt && (
          <MainSectionWrapper>
            <Question isMobile={isMobile}>
              <QuestionItem question={t('question1')} />
            </Question>
            <AnswerWrapper>
              <AnswerOption className="answerOption" onClick={() => setFinalRedirect(true)}>
                {t('yes')}
              </AnswerOption>
              <AnswerOption className="answerOption" onClick={() => setRedirectInt(false)}>
                {t('no')}
              </AnswerOption>
            </AnswerWrapper>
          </MainSectionWrapper>
        )}
      </MainSectionContainer>
      <section>
        <BottomSectionWrapper>
          {/* {noAnswerError && <div>Please select an answer</div>} */}
          <EmergencyBtn />
          {quizdata?.data?.no_of_answer !== 1 && (
            <Button
              variant="secondary"
              style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
              onClick={handleNextQuestion}
            >
              Next <ChevronIcon direction="right" width="13" height="13" />
            </Button>
          )}
        </BottomSectionWrapper>
      </section>
      {redirectNeeded && (
        <ModalOverlay>
          <Modal>
            <FlexRow>
              <CloseButton onClick={handleRedirect}>&times;</CloseButton>
            </FlexRow>
            <TextBlock type={'primary'}>{t('text_Block1')}</TextBlock>
            <TextBlock type={'secondary'}>{t('text_Block2')}</TextBlock>
            <TextBlock type={'secondary'}>{t('text_Block3')}</TextBlock>
            <TextBlock type={'emphasized'}>{t('text_Block4')}</TextBlock>
            <AnswerOption>
              <a href={quizdata.redirect_link} target="_blank" rel="noopener noreferrer">
                {t('getHelp')}
              </a>
            </AnswerOption>
            <TextBlock type={'secondary'}>{t('text_Block5')}</TextBlock>
            <BottomSectionWrapper>
              <Button
                variant="secondary"
                style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
                onClick={handleRedirect}
              >
                {t('okay')} <ChevronIcon direction="right" width="13" height="13" />
              </Button>
            </BottomSectionWrapper>
          </Modal>
        </ModalOverlay>
      )}
      {finalRedirect && (
        <ModalOverlay>
          <Modal>
            <FlexRow>
              <CloseButton onClick={closeAllModals}>&times;</CloseButton>
            </FlexRow>
            <TextBlock type={'primary'}>{t('text_Block6')}</TextBlock>
            <TextBlock type={'secondary'}>{t('text_Block7')}</TextBlock>
            <TextBlock type={'emphasized'}>{t('text_Block8')}</TextBlock>
            <TextBlock type={'secondary'}>{t('text_Block9')}</TextBlock>
            {/* <TextBlock type={'secondary'}>Now, let's finish your setup.</TextBlock> */}
            <BottomSectionWrapper>
              <Button
                variant="secondary"
                style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
                onClick={closeAllModals}
              >
                {t('okay')} <ChevronIcon direction="right" width="13" height="13" />
              </Button>
            </BottomSectionWrapper>
          </Modal>
        </ModalOverlay>
      )}
      {isChildProtection && (
        <ModalOverlay>
          <Modal>
            <FlexRow>
              <CloseButton onClick={closeAllModals}>&times;</CloseButton>
            </FlexRow>
            <TextBlock type={'primary'}>{t('text_Block10')}</TextBlock>
            <TextBlock type={'secondary'}>{t('text_Block11')}</TextBlock>
            <TextBlock type={'emphasized'}>{t('text_Block12')}</TextBlock>
            <TextBlock type={'secondary'}>{t('text_Block13')}</TextBlock>
            {/* <TextBlock type={'secondary'}>Now, let's finish your setup.</TextBlock> */}
            <BottomSectionWrapper>
              <Button
                variant="secondary"
                style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
                onClick={closeAllModals}
              >
                {t('okay')} <ChevronIcon direction="right" width="13" height="13" />
              </Button>
            </BottomSectionWrapper>
          </Modal>
        </ModalOverlay>
      )}
      <HelplineModal
        show={showHelpline}
        type={isAlcohol ? 'alcohol' : 'drug'}
        closeModal={() => setShowHelpline(!showHelpline)}
      />
    </WelcomeLayout>
  );
};

const QuestionItem = ({ question }) => {
  const brExp = /<br\s*\/?>/i;
  const lines = question?.split(brExp).map((line, i, arr) => (
    <span key={i}>
      {line.trim()}
      {arr[i + 1] && <br />}
    </span>
  ));

  return <>{lines}</>;
};

const ActionButton = ({ question }) => {
  const { t } = useTranslation();
  let questionType = '';
  if (question && question.toLowerCase().indexOf('alcohol') > -1) questionType = 'alcohol';
  if (question && question.toLowerCase().indexOf('child') > -1) questionType = 'child';
  switch (questionType) {
    case 'child':
      return (
        <ExternalLink
          href="https://resolution.org.uk/looking-for-help/parents-children-the-law/care-proceedings-and-adoption/child-protection-plan/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('actionButton1')}
        </ExternalLink>
      );
    case 'alcohol':
      return (
        <ExternalLink
          href="https://www.drinkaware.co.uk/facts/alcoholic-drinks-and-units/what-is-an-alcohol-unit"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('actionButton2')}
        </ExternalLink>
      );
    default:
      return <></>;
  }
};

const HelplineModal = ({ show, closeModal, type }) => {
  const { t } = useTranslation();
  const helplineContent = {
    alcohol: {
      title: t('helpLineContent1'),
      content: t('helpLineContent2'),
      contactTel: '0800 9177 650',
      url: 'https://www.alcoholics-anonymous.org.uk',
    },
    drug: {
      title: t('helpLineContent3'),
      content: t('helpLineContent4'),
      contactTel: '0300 123 6600',
      url: 'https://www.talktofrank.com',
    },
  };
  return (
    <>
      {show && (
        <ModalOverlay>
          <Modal>
            <FlexRow>
              <CloseButton onClick={closeModal}>&times;</CloseButton>
            </FlexRow>
            <TextBlock type={'primary'}>{helplineContent[type].title}</TextBlock>
            <TextBlock type={'secondary'}>{helplineContent[type].content}</TextBlock>
            <AnswerOption>
              <a href={`tel:${helplineContent[type].contactTel.replace(/\s/g, '-')}`}>
                {helplineContent[type].contactTel}
              </a>
            </AnswerOption>
            <AnswerOption>
              <a href={helplineContent[type].url} target="_blank" rel="noopener noreferrer">
                {t('getSupport')}
              </a>
            </AnswerOption>
            <TextBlock type={'secondary'}>{t('continueTherapistJourney')}</TextBlock>
            <BottomSectionWrapper>
              <Button
                variant="secondary"
                style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
                onClick={closeModal}
              >
                {t('okay')} <ChevronIcon direction="right" width="13" height="13" />
              </Button>
            </BottomSectionWrapper>
          </Modal>
        </ModalOverlay>
      )}
    </>
  );
};

const MainSectionContainer = styled.section`
  height: inherit;
  display: grid;
`;
const QuestionWrapper = styled.div`
  max-width: 850px;
`;
const Question = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? '20px' : 'x-large')};
  color: #491f69;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
`;

const Headline = styled.div`
  color: ${({ theme }) => theme.greyMedium};
  font-size: ${({ isMobile }) => (isMobile ? '14px' : '16px')};
  margin-bottom: 20px;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
`;
const OptionNote = styled.div`
  color: ${({ theme }) => theme.cyan};
  margin-bottom: 20px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  font-size: ${({ isMobile }) => (isMobile ? '15px' : '16px')};
`;
const ExternalLink = styled.a`
  // width:45%;
  border: none;
  border-radius: 8px;
  background: rgba(68, 33, 101, 10%);
  color: #8888;
  padding: 8px 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  @media screen and (max-width: 415px) {
    width: 90%;
  }
  text-decoration: none;
`;
const AnswerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  margin-top: 20px;
`;
const AnswerOption = styled.div`
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  font-size: 14px;
  padding: 5px 8px;
  background-color: #c9fff1;
  color: ${({ theme }) => theme.greyDark};
  border: 2px solid transparent;
  font-family: 'Montserrat', sans-serif;
  ${({ disable }) =>
    disable &&
    css`
      background-color: ${({ theme }) => theme.greyMedium};
      pointer-events: none;
    `}

  ${({ theme }) => theme.md`
    font-size: 15px;
  `}

  &.active {
    color: ${({ theme }) => theme.white};
    background-color: #33c8a0;
  }
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row-reverse;
`;
const CloseButton = styled.div`
  cursor: pointer;
`;
export default memo(Quesitionnaire);
