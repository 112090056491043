import React from 'react'
import styled from 'styled-components'

const OnlineIcon = ({
  width = '10px',
  height = '10px',
  radius = '5px',
  right = '-1px',
  top = '0px',
  backgroundColor = '',
}) => {
  return (
    <StatusIcon
      {...{ width, height, right, top, radius, backgroundColor }}
    ></StatusIcon>
  )
}
const StatusIcon = styled.div`
  content: '';
  height: ${props => props.height};
  width: ${props => props.width};
  border: 1px solid ${({ theme }) => theme.white};
  background-color: ${props => props.backgroundColor};
  border-radius: ${props => props.radius};
  position: absolute;
  right: ${props => props.right};
  top: ${props => props.top};
  margin-top: 9px;
`
export default OnlineIcon
