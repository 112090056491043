import React, { memo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { PageHeading } from '../../Components/Molecules';

import { ReactComponent as JournalIcon } from '../../Assets/Icons/Journal.svg';

const Sidebar = () => {
  const { chatRoomId } = useParams();
  return (
    <div>
      <PageHeading icon={JournalIcon} name={'Journal'} />
      <p>
        <Link to={`/t/journal/${chatRoomId}`}>All Journal Entries</Link>
      </p>
    </div>
  );
};

export default memo(Sidebar);
