import moment from 'moment';

export const convertToDate = (schedule) => {
  return {
    ...schedule,
    slots: schedule.slots.map((slot) => ({
      ...slot,
      schedule_time: moment(slot.schedule_time, 'YYYY-MM-DD HH:mm:ss').utc().toDate(),
      schedule_end_time: moment(slot.schedule_end_time, 'YYYY-MM-DD HH:mm:ss').utc().toDate(),
    })),
  };
};

export const convertToMultipleSlots = (schedules) => {
  const availabilityEvents = [];
  if (schedules && schedules.length) {
    schedules.forEach((schedule) => {
      schedule.slots.forEach((slot) => {
        availabilityEvents.push({
          start: moment(`${schedule.schedule_date} ${slot.schedule_time}`, 'YYYY-MM-DD HH:mm:ss').toDate(),
          end: moment(`${schedule.schedule_date} ${slot.schedule_end_time}`, 'YYYY-MM-DD HH:mm:ss').toDate(),
          slot,
        });
      });
    });
  }

  if (availabilityEvents.length) return availabilityEvents;
  else return [];
};

export const getCalendarEvents = (schedules, isAvailabilityEditing = false, availabilityWithBooked = true) => {
  const availabilityEvents = [];
  const bookedEvents = [];

  schedules.map(convertToDate).forEach((schedule) => {
    const slotGrouped = [];
    const sortedSlots = schedule.slots.sort((a, b) => a.schedule_time - b.schedule_time);
    sortedSlots.forEach((slot, index) => {
      if (slot.status === 'booked')
        bookedEvents.push({
          start: slot.schedule_time,
          end: slot.schedule_end_time,
          slot,
        });
      if (isAvailabilityEditing) {
        availabilityEvents.push({
          start: slot.schedule_time,
          end: slot.schedule_end_time,
          slot,
        });
      } else {
        if (availabilityWithBooked) {
          if (index === 0) slotGrouped.push(slot);
          if (schedule.slots.length > index + 1) {
            if (slot.schedule_end_time.getTime() === schedule.slots[index + 1].schedule_time.getTime()) {
              slotGrouped[slotGrouped.length - 1].schedule_end_time = schedule.slots[index + 1].schedule_end_time;
            } else slotGrouped.push(schedule.slots[index + 1]);
          }
        } else if (slot.status === 'available') {
          if (slotGrouped.length === 0) slotGrouped.push(slot);
          if (schedule.slots.length > index + 1) {
            if (slot.schedule_end_time.getTime() === schedule.slots[index + 1].schedule_time.getTime()) {
              slotGrouped[slotGrouped.length - 1].schedule_end_time = schedule.slots[index + 1].schedule_end_time;
            } else slotGrouped.push(schedule.slots[index + 1]);
          }
        }
      }
    });

    if (!isAvailabilityEditing) {
      slotGrouped.forEach((slot) =>
        availabilityEvents.push({
          start: slot.schedule_time,
          end: slot.schedule_end_time,
          slot,
        }),
      );
    }
  });

  return [availabilityEvents, bookedEvents];
};

export const getPatientCalendarEvents = (schedules, type = 'available', patientId) => {
  const availabilityEvents = [];
  const bookedEvents = [];

  schedules.map(convertToDate).forEach((schedule) => {
    let currScheduleAvailableSlots = schedule.slots.filter((elem) => elem.status === type) || [];
    schedule.slots = currScheduleAvailableSlots;
    const slotGrouped = [];
    const sortedSlots = schedule.slots.sort((a, b) => a.schedule_time - b.schedule_time);
    sortedSlots.forEach((slot, index) => {
      if (slot.status === 'booked' && slot.patient_id === patientId)
        bookedEvents.push({
          start: slot.schedule_time,
          end: slot.schedule_end_time,
          slot,
        });
      if (index === 0) slotGrouped.push(slot);
      if (schedule?.slots?.length > 1) {
        if (slot?.schedule_end_time?.getTime() === schedule?.slots?.[index + 1]?.schedule_time?.getTime()) {
          slotGrouped[slotGrouped.length - 1].schedule_end_time = schedule.slots[index + 1].schedule_end_time;
        } else {
          if (schedule?.slots[index + 1]) {
            slotGrouped.push(schedule.slots[index + 1]);
          }
        }
      }
    });

    slotGrouped.forEach((slot) =>
      availabilityEvents.push({
        start: slot?.schedule_time,
        end: slot?.schedule_end_time,
        slot,
      }),
    );
  });
  return [availabilityEvents, bookedEvents];
};