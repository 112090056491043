import {
  GET_CHAT_ROOMS,
  GET_MESSAGES,
  ADD_NEW_MESSAGE,
  GET_CHAT_RESOURCES,
  SET_CHAT_ROOM_ERROR,
  CREATE_VIDEO_ROOM,
  SET_VIDEO_ROOM,
  LAUNCH_VIDEO_ROOM,
  LEAVE_VIDEO_ROOM,
  DESTROY_VIDEO_ROOM,
  GET_CHATROOM_SCHEDULE_LIST,
  SET_VIDEO_ROOM_ERROR,
  GET_JOURNALS,
  ADD_JOURNAL,
  UPDATE_JOURNAL,
  DELETE_JOURNAL,
  GET_THERAPY_JOURNEY,
  CLEAR_CHAT_ROOM,
  LOGOUT,
  GET_NOTES,
  CAN_SUBMIT_JOURNEY_FEEDBACK,
  SUBMIT_JOURNEY_FEEDBACK,
  UPDATE_FLAG,
  GET_UNREAD_MESSAGES,
  ADD_UNREAD_MESSAGES,
  SWITCH_THERAPIST,
  SHARE_JOURNAL_WITH_THERAPIST,
  SET_POSTS,
  GET_CATEGORIES,
  SET_PAGE,
  SET_HAS_MORE,
  SET_SELECTED_CATEGORIES,
  SET_VIDEO_POSTS,
} from '../Constants/actionTypes';

const initialState = {
  messages: null,
  unreadMessages: null,
  rooms: null,
  resources: null,
  chatRoomID: null,
  error: null,
  videoRoom: {
    active: false,
    name: null,
    error: null,
  },
  notes: null,
  companyName: null,
  scheduleList: [],
  journals: null,
  templatesStages: null,
  therapyJourney: {
    data: null,
    submitFeedback: false,
    submitFinalFeedback: false,
  },
  wellBeing: {
    posts: null,
    categories: null,
    selectedCategories: [],
    page: 1,
    hasMore: true,
  },
};

export default function chatroom(state = initialState, action) {
  switch (action.type) {
    case GET_MESSAGES: {
      if (JSON.stringify(state.messages?.pagination) !== JSON.stringify(action.payload.pagination)) {
        return {
          ...state,
          messages: {
            data: action.payload?.data?.messages,
            pagination: action.payload?.pagination,
          },
        };
      }
      return state;
    }
    case ADD_NEW_MESSAGE: {
      if (action?.payload) {
        let data = state.messages?.data || [];
        data.push(action.payload)
        let messages = {
          data: data,
          pagination: state.messages?.pagination,
        }
        return {
          ...state,
          messages: messages,
        };
      }
      return state;
    }
    case GET_CHAT_ROOMS: {
      if (action.payload.role === 'patient' && action.payload.rooms && action.payload.rooms.length) {
        return {
          ...state,
          rooms: action.payload.rooms,
          chatRoomID: action.payload.rooms[0].chatroom_id,
          status: action.payload.status,
        };
      }

      const doesRoomExist = action.payload.rooms.findIndex((room) => room.chatroom_id === action.payload.roomID);

      return {
        ...state,
        rooms: action.payload.rooms,
        chatRoomID: doesRoomExist >= 0 ? action.payload.roomID : 0,
        status: action.payload.status,
      };
    }
    case GET_CHAT_RESOURCES:
      return {
        ...state,
        resources: action.payload,
      };
    case SET_CHAT_ROOM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_VIDEO_ROOM_ERROR: {
      return {
        ...state,
        videoRoom: {
          ...initialState.videoRoom,
          error: action.payload,
        },
      };
    }
    case CREATE_VIDEO_ROOM:
      return {
        ...state,
        videoRoom: {
          ...state.videoRoom,
          ...action.payload,
        },
      };
    case SET_VIDEO_ROOM:
      return {
        ...state,
        videoRoom: {
          ...state.videoRoom,
          ...action.payload,
        },
      };
    case LAUNCH_VIDEO_ROOM:
      return {
        ...state,
        videoRoom: {
          ...state.videoRoom,
          active: true,
        },
      };
    case LEAVE_VIDEO_ROOM:
      return {
        ...state,
        videoRoom: {
          ...state.videoRoom,
          active: false,
        },
      };
    case DESTROY_VIDEO_ROOM:
      return {
        ...state,
        videoRoom: initialState.videoRoom,
      };
    case GET_CHATROOM_SCHEDULE_LIST: {
      const myBookedEvents = [];

      action.payload.forEach((day) =>
        day.slots.forEach((event) => {
          if (event.patient_id && event.status === 'booked')
            myBookedEvents.push({ ...event, schedule_date: day.schedule_date });
        }),
      );

      return {
        ...state,
        scheduleList: action.payload,
        myBookedEvents,
      };
    }
    case GET_JOURNALS:
      return {
        ...state,
        journals: action.payload,
      };
    case ADD_JOURNAL:
      return {
        ...state,
        journals: {
          ...state.journals,
          data: [action.payload, ...state.journals.data],
        },
      };
    case UPDATE_JOURNAL: {
      const newJournals = [...state.journals.data];
      const index = newJournals.findIndex((j) => j.journal_id === action.payload.journal_id);

      if (index !== -1) newJournals[index] = { ...newJournals[index], ...action.payload };

      return {
        ...state,
        journals: {
          ...state.journals,
          data: newJournals,
        },
      };
    }
    case DELETE_JOURNAL: {
      const newJournals = [...state.journals.data];
      const index = newJournals.findIndex((j) => j.journal_id === action.payload);

      if (index !== -1) newJournals.splice(index, 1);

      return {
        ...state,
        journals: {
          ...state.journals,
          data: newJournals,
        },
      };
    }
    case GET_THERAPY_JOURNEY:
      return {
        ...state,
        therapyJourney: {
          ...state.therapyJourney,
          data: action.payload,
        },
      };
    case CAN_SUBMIT_JOURNEY_FEEDBACK:
      return {
        ...state,
        therapyJourney: {
          ...state.therapyJourney,
          submitFeedback: action.payload?.submit_feedback,
          submitFinalFeedback: action.payload?.submit_final_feedback,
        },
      };
    case SUBMIT_JOURNEY_FEEDBACK:
      return {
        ...state,
        therapyJourney: {
          ...state.therapyJourney,
          submitFeedback: false,
          submitFinalFeedback: false,
        },
      };
    case CLEAR_CHAT_ROOM:
      return {
        ...initialState,
        rooms: [...state.rooms],
      };
    case GET_NOTES:
      return {
        ...state,
        notes: action.payload.notes,
        companyName: action.payload.company_name,
      };
    case GET_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: {
          ...action.payload,
          messages: action.payload?.messages || [],
          unread_message_count: action.payload?.unread_message_count || 0,
        },
      };
    case ADD_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: {
          messages: [...state?.unreadMessages?.messages, action?.payload],
          unread_message_count: state?.unreadMessages?.unread_message_count + 1,
        },
      };
    case UPDATE_FLAG: {
      const newRooms = [...state.rooms];
      const index = newRooms.findIndex((room) => room.chatroom_id === action.payload.chatroom_id);

      if (index > -1) newRooms[index].flag = action.payload.flag;

      return {
        ...state,
        rooms: newRooms,
      };
    }
    case SWITCH_THERAPIST: {
      if (state.rooms.length === 1) {
        return {
          ...state,
          rooms: [{ ...state.rooms[0], chatroom_status: 'pending', therapist_id: 0, therapist_full_name: '' }],
        };
      }

      return {
        ...state,
      };
    }
    case SHARE_JOURNAL_WITH_THERAPIST: {
      const newJournals = [...state.journals.data];
      const index = newJournals.findIndex((journal) => journal.journal_id === action.payload.id);
      if (index > -1) newJournals[index].shared_with_therapist = action.payload.status;

      return {
        ...state,
        journals: {
          ...state.journals,
          data: newJournals,
        },
      };
    }
    case SET_POSTS:
      return {
        ...state,
        wellBeing: {
          ...state.wellBeing,
          posts: action.payload,
        },
      };
    case SET_VIDEO_POSTS:
      return {
        ...state,
        wellBeing: {
          ...state.wellBeing,
          videoPosts: action.payload,
        },
      };
    case GET_CATEGORIES:
      return {
        ...state,
        wellBeing: {
          ...state.wellBeing,
          categories: action.payload,
        },
      };
    case SET_PAGE:
      return {
        ...state,
        wellBeing: {
          ...state.wellBeing,
          page: action.payload,
        },
      };
    case SET_HAS_MORE:
      return {
        ...state,
        wellBeing: {
          ...state.wellBeing,
          hasMore: action.payload,
        },
      };
    case SET_SELECTED_CATEGORIES:
      return {
        ...state,
        wellBeing: {
          ...state.wellBeing,
          selectedCategories: action.payload,
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
