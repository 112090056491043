export const aboutMeLowerLimit = 20;
export const aboutMeUpperLimit = 200;
export const certificationBody = [
  { value: 'ABPP', name: 'ABPP' },
  { value: 'ACP', name: 'ACP' },
  { value: 'APA', name: 'APA' },
  { value: 'APCP', name: 'APCP' },
  { value: 'BABCP', name: 'BABCP' },
  { value: 'BACP', name: 'BACP' },
  { value: 'BPS', name: 'BPS' },
  { value: 'CCC', name: 'CCC' },
  { value: 'CCPA', name: 'CCPA' },
  { value: 'CPA', name: 'CPA' },
  { value: 'HCPC', name: 'HCPC' },
  { value: 'IACP', name: 'IACP' },
  { value: 'IAHIP', name: 'IAHIP' },
  { value: 'IAOTH', name: 'IAOTH' },
  { value: 'NAPCP', name: 'NAPCP' },
  { value: 'NASP', name: 'NASP' },
  { value: 'NCSP', name: 'NCSP' },
  { value: 'PSI', name: 'PSI' },
  { value: 'UKCP', name: 'UKCP' },
  { value: 'Other', name: 'Other' },
];
export const localTimezones = [
  'Europe/London', 'Europe/Guernsey', 'Europe/Jersey',
  'Europe/Gibraltar', 'GB', 'Etc/GMT',
  'Etc/GMT0', 'Etc/GMT+0', 'Etc/Greenwich',
  'Etc/UTC', 'Europe/Isle_of_Man',
];
