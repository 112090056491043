import React, { useEffect, useRef, memo, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Bubble from './Bubble';
import MoodChecker from './MoodChecker';
import { Loading } from '../../Atoms';
import { B2BEmployeeSurvey } from '../../Organisms';
import AnnouncementBubble from './AnnouncementBubble';
import { getAnnouncements } from '../../../Actions/chatroom.actions';

const formatTime = (time) => {
  if (time) {
    return `${moment.utc(time, 'YYYY-MM-DD HH:mm:ss').local().format('MMMM Do')} at ${
      moment.utc(time, 'YYYY-MM-DD HH:mm:ss').local().format('A') === 'AM'
        ? moment.utc(time, 'YYYY-MM-DD HH:mm:ss').local().format('HH:mm A')
        : moment.utc(time, 'YYYY-MM-DD HH:mm:ss').local().format('HH:mm')
    }`;
  }
  return '';
};
const emptyMessage = (roomStatus, availability = null, t) => {
  return [
    {
      message_id: 0,
      read_status: 0,
      sent_at: '',
      sent_by: t('supportRoom'),
      sent_by_role: t('therapist'),
      type: 'text',
      message:
        roomStatus === 'waiting' || roomStatus === 'pending'
          ? t('windowText1')
          : availability
          ? `${t('windowText2')} ${formatTime(availability)} ${t('windowText3')}`
          : `${t('windowText4')}`,
    },
  ];
};

const Window = ({ ChatData, getMoreMessages = () => null, pagination, roomStatus, messageLength }) => {
  const { t } = useTranslation();
  const firstAutoScrollInit = useRef(false);
  const messagesEndRef = useRef(null);
  const windowRef = useRef(null);
  const prevPaginationData = useRef(null);
  const windowHeight = useRef(0);
  const prevWindowHeight = useRef(0);
  const [avaiability, setAvailability] = useState(null);
  const patientId = useSelector((store) => store.user?.profile?.id);
  const isPartnerCheck = useSelector((store) => store.isPartner?.isPartner);
  const dispatch = useDispatch();
  const profile = useSelector((store) => store.user?.profile);

  const scrollToBottom = useCallback(() => {
    messagesEndRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    window.visualViewport.addEventListener('resize', scrollToBottom);

    return () => window.visualViewport.removeEventListener('resize', scrollToBottom);
  }, [scrollToBottom]);

  useEffect(() => {
    dispatch(getAnnouncements()).then(scrollToBottom);

    const timer = setInterval(() => {
      dispatch(getAnnouncements()).then(scrollToBottom);
    }, 1000 * 600);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const userRole = useSelector((state) => state.user.role);
  const myBookedEvents = useSelector((state) => state.user?.scheduleList);

  const debouncedScrollEvent = debounce(({ target: { scrollTop } }) => {
    if (scrollTop === 0) {
      getMoreMessages().then(() => {
        setWindowHeight();
        if (windowRef.current?.scrollTop)
          windowRef.current.scrollTop = (prevWindowHeight.current - windowHeight.current) * -1;
      });
    }
  }, 200);

  const onScrollChange = (e) => {
    e.persist();
    debouncedScrollEvent(e);
  };

  const setWindowHeight = () => {
    const { current } = windowRef;
    prevWindowHeight.current = windowHeight.current;
    windowHeight.current = current?.scrollHeight - current?.clientHeight;
  };

  useEffect(() => {
    if (!firstAutoScrollInit.current && ChatData?.length) {
      scrollToBottom();
      firstAutoScrollInit.current = true;
      setWindowHeight();
    }

    if (prevPaginationData.current?.total_messages !== pagination?.total_messages && firstAutoScrollInit.current) {
      scrollToBottom();
      setWindowHeight();
    }

    prevPaginationData.current = pagination;
  }, [ChatData, pagination, scrollToBottom]);
  
  useEffect(() => {
      scrollToBottom();
  }, [messageLength]);

  useEffect(() => {
    if (myBookedEvents && myBookedEvents.length) {
      const _availability = myBookedEvents.reduce((a, c) => {
        if (c.slots.length) {
          c.slots.forEach((slot) => {
            if (slot.status === 'booked' && c && slot.patient_id === patientId) {
              a[c.schedule_date] = slot.schedule_time;
            }
          });
        }
        return a;
      }, {});
      if (Object.keys(_availability).length) {
        const _firstElement = `${Object.keys(_availability)[0]} ${_availability[Object.keys(_availability)[0]]}`;
        setAvailability(_firstElement);
      }
    }
  }, [myBookedEvents, patientId]);

  var REFERENCE = moment();
  var TODAY = REFERENCE.clone().startOf('day');
  var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

  let initialDate = useMemo(() => (ChatData ? moment(ChatData[0].sent_at).format('MMM DD YYYY') : ''), [ChatData]);
  function isToday(momentDate) {
    return momentDate.isSame(TODAY, 'd');
  }

  function isYesterday(momentDate) {
    return momentDate.isSame(YESTERDAY, 'd');
  }

  if (isToday(moment(initialDate))) {
    initialDate = t('Today');
  } else if (isYesterday(moment(initialDate))) {
    initialDate = t('Yesterday');
  }

  const getBubbles = (messages) => {
    // const allDates = messages.map((item) => {
    //   return item.sent_at.slice(0, 10);
    // });
    // const uniqueDates = [...new Set(allDates)];
    // console.log(uniqueDates, 'uniqueDates');

    // const finalArray = uniqueDates.map((date) => {
    //   let section = messages.map((item) => {
    //     return item.sent_at.includes(date) ? item : null;
    //   });
    //   const filteredSections = section.filter(function (el) {
    //     return el != null;
    //   });
    //   return filteredSections;
    // });
    // console.log('finalArray: ', finalArray);

    return messages.map((item, index) => {
      const prevIndex = index - 1;
      const currentMessage = item;
      const previousMessage = messages[prevIndex >= 0 ? prevIndex : 0];

      return (
        <Bubble
          key={item.message_id}
          item={item}
          currentMessage={currentMessage}
          previousMessage={previousMessage}
          isSender={item.sent_by_role === userRole}
        />
      );
    });
  };

  return (
    <>
      <ConversationWindow ref={windowRef} onScroll={onScrollChange}>
        {ChatData === undefined ? (
          <Loading />
        ) : (
          <>
            {(initialDate !== "") ? <DateWrapper>{initialDate}</DateWrapper> : null}
            {(isPartnerCheck || profile?.['B2B'] === 1) && <B2BEmployeeSurvey />}
            {ChatData ? getBubbles(ChatData) : getBubbles(emptyMessage(roomStatus, avaiability, t))}
            <MoodChecker />
            <AnnouncementBubble scrollToBottom={scrollToBottom} />
            <Spacer>&nbsp;</Spacer>
            <div ref={messagesEndRef} />
          </>
        )}
      </ConversationWindow>
    </>
  );
};

const DateWrapper = styled.div`
  align-self: center;
  background-color: white;
  padding: 2px 10px;
  position: sticky;
  top: 0px;
  border-radius: 12px;
  z-index: 99;
  width: 115px;
  text-align: center;
  border: 0.1px solid #00000026;
  ${'' /* box-shadow: 0px 4px 27px rgba(73, 31, 105, 0.14); */}
`;

const ConversationWindow = styled.div`
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
  padding: 5px 16px;

  ${({ theme }) => theme.sm`
    padding: ${({ theme }) => theme.spacing};
  `}
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
`;

const Spacer = styled.div`
  height: 64px;
`;

export default memo(Window);
