import React, { memo, useEffect, useState } from "react";
import { socket } from "../../../socket";

const Typing = (props) => {
    const { message, roomId, color = '#000' } = props;
    const [isTyping, setIsTyping] = useState(false)
    useEffect(() => {
        let id;
        socket.on("typing", (data) => {
            if (+data?.roomName === roomId) {
                setIsTyping(prev => {
                    clearTimeout(id);
                    id = setTimeout(() => {
                        setIsTyping(false)
                    }, 1500)
                    return true;
                });
            }
        });
    }, [roomId])

    return (
        <p
            style={{
                fontSize: 12,
                color: color,
                padding: 0,
                margin: 0,
                lineHeight: '12px',
                height: 12,
                marginBottom: 10
            }}
        >{isTyping ? message : ''}</p>
    )
}
export default memo(Typing);