import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import Globe from '../../Assets/globe.svg';
import GlobeBlue from '../../Assets/globeBlue.svg';
import { ReactComponent as ChangeLang } from '../../Assets/changeLang.svg';
import CloseIcon from '../../Assets/Icons/ClosePurple.svg';
import { useTranslation } from 'react-i18next';
import { api, useDeviceType } from '../../Util';
import { useSelector } from 'react-redux';

import UK from '../../Assets/UK.svg';
import Serbia from '../../Assets/Serbia.svg';
import Greece from '../../Assets/Greece.svg';
import UAE from '../../Assets/UAE.svg';
import Russia from '../../Assets/russia.png';
import Israel from '../../Assets/Israel.png';
import { languageMapping } from '../../i18nextConf';
import { genericLanguageMapping, isBluehrsolutionsPartner, isExuslabsPartner } from '../../App';

const LanguageSwitch = ({ showText, showBlueGlobe }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const isMobile = useDeviceType();
  const selectedLanguage = useSelector((store) => store.language.selectedLanguage);

  const profile = useSelector((store) => store.user?.profile);

  const handleStateChange = ({ isOpen }) => setIsOpen(isOpen);

  const switchLanguage = (value) => {
    if (value) {
      localStorage.setItem('lang', value);
      let path = window.location.pathname?.split('/')?.[1];
      const saveDefaultLang = async () => {
        await api.patient.patinetLangaugeSet(genericLanguageMapping[value || 'en']);
        window.location.pathname = window.location.pathname.replace(path, `${value}`);
        i18n.changeLanguage(value);
      };
      if (profile) {
        saveDefaultLang();
      } else {
        window.location.pathname = window.location.pathname.replace(path, `${value}`);
        i18n.changeLanguage(value);
      }
    } else {
      localStorage.setItem('lang', '');
    }
  };

  const renderLanguages = () => {
    if (isExuslabsPartner) {
      return (
        <ul>
          <li>
            <StyledLinkButton
              onClick={() => {
                switchLanguage('en');
              }}
            >
              <Image src={UK} alt="" />
              {languageMapping[selectedLanguage]?.[0]}
            </StyledLinkButton>
          </li>
        </ul>
      );
    }

    if (isBluehrsolutionsPartner) {
      return (
        <ul>
          <li>
            <StyledLinkButton
              onClick={() => {
                switchLanguage('en');
              }}
            >
              <Image src={UK} alt="" />
              {languageMapping[selectedLanguage]?.[0]}
            </StyledLinkButton>
            <StyledLinkButton
              onClick={() => {
                switchLanguage('ar');
              }}
            >
              <Image src={UAE} alt="" />
              {languageMapping[selectedLanguage]?.[3]}

              <Image src={Russia} alt="" />
              {languageMapping[selectedLanguage]?.[4]}

              <Image src={Israel} alt="" />
              {languageMapping[selectedLanguage]?.[5]}
            </StyledLinkButton>
          </li>
        </ul>
      );
    }

    return (
      <ul>
        <li>
          <StyledLinkButton
            onClick={() => {
              switchLanguage('en');
            }}
          >
            <Image src={UK} alt="" />
            {languageMapping[selectedLanguage]?.[0]}
          </StyledLinkButton>
          <StyledLinkButton
            onClick={() => {
              switchLanguage('sr');
            }}
          >
            <Image src={Serbia} alt="" />
            {languageMapping[selectedLanguage]?.[1]}
          </StyledLinkButton>
          <StyledLinkButton
            onClick={() => {
              switchLanguage('el');
            }}
          >
            <Image src={Greece} alt="" />
            {languageMapping[selectedLanguage]?.[2]}
          </StyledLinkButton>
          <StyledLinkButton
            onClick={() => {
              switchLanguage('ar');
            }}
          >
            <Image src={UAE} alt="" style={{ marginBottom: -14 }} />
            {languageMapping[selectedLanguage]?.[3]}
          </StyledLinkButton>
          <StyledLinkButton
            onClick={() => {
              switchLanguage('rus');
            }}
          >
            <Image src={Russia} alt="" style={{ marginBottom: -1 }} />
            {languageMapping[selectedLanguage]?.[4]}
          </StyledLinkButton>
          <StyledLinkButton
            onClick={() => {
              switchLanguage('heb');
            }}
          >
            <Image src={Israel} alt="" style={{ marginBottom: -6 }} />
            {languageMapping[selectedLanguage]?.[5]}
          </StyledLinkButton>
        </li>
      </ul>
    );
  };

  return (
    <div style={{ marginTop: '0px' }} isMobile={isMobile}>
      <Menu
        width={isMobile ? 250 : 300}
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        customBurgerIcon={
          showText ? (
            <>
              <Icon as={ChangeLang} />
            </>
          ) : (
            <img style={{ marginTop: 8 }} src={showBlueGlobe ? GlobeBlue : Globe} alt="" />
          )
        }
        customCrossIcon={<img src={CloseIcon} alt="" />}
        styles={NavStyles(showText)}
        pageWrapId={'mainContent'}
        outerContainerId={'siteWrapper'}
        disableAutoFocus
      >
        <MenuWrapper>
          <StyledHeading>{t('selectLang')}</StyledHeading>
          {renderLanguages()}
        </MenuWrapper>
      </Menu>
    </div>
  );
};

const MenuWrapper = styled.div`
  text-align: left;
`;

const Icon = styled.svg`
  width: 10px;
  height: 10px;
  path {
    fill: white;
  }
`;

const Image = styled.img`
  margin: 0px 8px -10px 0px;
  width: 30px;
`;

const NavStyles = (showText) => ({
  bmBurgerButton: {
    position: 'relative',
    width: showText ? '180px' : '18px',
    top: '0px',
    zIndex: 999,
  },
  bmCrossButton: {
    width: '18px',
    top: '26px',
    right: '16px',
  },

  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    right: 0,
  },
  bmMenu: {
    background: '#ffffff',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#491F69',
    padding: '64px 32px 32px',
  },
  bmItem: {
    display: 'block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const StyledLink = styled(Link)`
  display: block;
  font-size: 17px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  text-decoration: none;
`;
StyledLink.displayName = 'StyledLink';

const StyledLinkButton = styled.div`
  display: block;
  font-size: 17px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  text-decoration: none;
  cursor: pointer;
`;

const StyledHeading = styled.h3`
  margin-top: -40px;
  margin-bottom: 40px;
`;
StyledHeading.displayName = 'StyledHeading';

export default LanguageSwitch;
