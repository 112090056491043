import React from 'react';
import styled from 'styled-components';

import { Checkbox } from '../../Components/Atoms';

const CheckboxGroup = ({ options, values, otherInputOnChange }) => {
  const newOptions = options ? [...options] : [];
  newOptions.length &&
    newOptions.push(
      newOptions.splice(
        newOptions.findIndex((tool) => tool.id === 15),
        1,
      )[0],
    );

  return (
    <Wrapper role="group" aria-labelledby="checkbox-group">
      {newOptions &&
        newOptions.map((item) => (
          <CheckboxWrapper key={item.id}>
            <Checkbox
              label={item.name}
              name="checked"
              value={item.id}
              checked={values.checked.includes(item.id.toString())}
            />
            {values.checked.includes('15') && item.id.toString() === '15' && (
              <StyledInput onChange={otherInputOnChange} type="text" />
            )}
          </CheckboxWrapper>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  margin: ${({ theme }) => theme.spacingXl} 0;
  gap: ${({ theme }) => theme.spacing} ${({ theme }) => theme.spacingLg};

  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  `};
`;

const CheckboxWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  position: absolute;
`;

export default CheckboxGroup;
