import React, { memo, useEffect, useMemo, useState } from 'react';
import Styled from 'styled-components';
import { WelcomeLayout } from '../Layout';
import { useWindowSize } from '../Hooks';
import { TextBlock, Button, Loading, ChevronIcon } from '../Components/Atoms';
import AdamImage from '../Assets/SignUp/quotes/panagiotis_karagiannis.jpg';
import EmergencyBtn from '../Components/Atoms/EmergencyBtn';
import { BottomSectionWrapper, MainSectionWrapper } from '../Welcome/Components';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const Teams = () => {
  const { width } = useWindowSize();
  const teamsContext = useSelector((store) => store.teams.teamsContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const loginInfo = localStorage.getItem('loginInfo');
  const isLoggedIn = loginInfo ? JSON.parse(loginInfo).loggedIn : false;

  useEffect(() => {
    if (!isLoggedIn) return;

    history.push('/chatroom');
  }, [isLoggedIn]);

  const isMobile = useMemo(() => width < 768, [width]);

  const therapistMeta = {
    image: AdamImage,
    name: 'Panagiotis Karagiannis',
    description: 'Certified Therapist',
    content: `Hello, my name is Panagiotis Karagiannis. I'm one of the counsellors on SupportRoom, your online therapy platform.`,
  };

  const checkIsB2b = async () => {
    try {
      const email = teamsContext?.loginHint;
      // const email = 'eugencebanalex@gmail.com';
      setLoading(true);
      const response = await axios.get(`/is_b2b?email=${email}`);
      if (response?.data?.is_b2b === 1) {
        history.push('/welcome/signin');
      } else {
        alert(
          'SupportRoom requires an active subscription, if you would like to organise an overview of how SupportRoom can help cope your mental health challenges, please send an email to info@supportroom.com',
        );
      }
    } catch (_) {
      alert(
        'SupportRoom requires an active subscription, if you would like to organise an overview of how SupportRoom can help cope your mental health challenges, please send an email to info@supportroom.com',
      );
    } finally {
      setLoading(false);
    }
  };

  const renderButton = () => {
    return (
      <ButtonGuestWrapper isMobile={isMobile}>
        <Button
          variant="secondary"
          style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
          onClick={checkIsB2b}
        >
          Let's go
          <ChevronIcon direction="right" width="13" height="13" />
        </Button>
      </ButtonGuestWrapper>
    );
  };

  return (
    <WelcomeLayout noPadding noTopBar>
      {(!teamsContext || loading) && <Loading />}
      <section>
        <MainSectionWrapper isMobile={isMobile} noPadding>
          <WelcomeContainer isMobile={isMobile}>
            <TextBlock
              type={'primary'}
              padding={'0px'}
              style={{
                color: 'rgb(51, 200, 160)',
                marginBottom: 5,
                // textAlign: isMobile ? 'left' : '',
                fontWeight: isMobile ? '600' : 'initial',
                fontSize: `${isMobile ? '18px' : '25px'}`,
              }}
            >
              Take your first step to <span style={{ color: '#491f69' }}>happiness</span> today
            </TextBlock>
            {/* <CarouselWithDot /> */}
            <Description className="main-description">
              {!isMobile && (
                <TextBlock
                  type={'secondary'}
                  style={{ fontSize: isMobile && '13.5px', textAlign: isMobile ? 'left' : 'center' }}
                >
                  Therapy is a start of a journey and we're honoured that you've chosen us to start that path.
                </TextBlock>
              )}
            </Description>
            <ImageWrapper isMobile={isMobile}>
              <TherapistImage isMobile={isMobile}>
                <BlendColor />
                <Image isMobile={isMobile} src={therapistMeta.image} />
              </TherapistImage>
              <TherapistDescription>
                <TherapistName>{therapistMeta.name}</TherapistName>
                <TherapistCertification>{therapistMeta.description}</TherapistCertification>
              </TherapistDescription>
            </ImageWrapper>
            <TherapistComment isMobile={isMobile}>
              <TextBlock type={'secondary'} style={{ fontSize: isMobile && '14px' }}>
                <span className="first-quote"></span>
                {!isMobile && 'Hello, my name is Panagiotis Karagiannis. '} I'm one of the counsellors on SupportRoom,
                your online therapy platform. <span className="quote">&rdquo;</span>
              </TextBlock>

              <TextBlock type={'secondary'} style={{ fontSize: isMobile && '14px' }}>
                <span className="first-quote"></span>
                SupportRoom requires an active subscription, if you would like to organise an overview of how
                SupportRoom can help cope your mental health challenges, please send an email to{' '}
                <a href="mailto:info@supportroom.com">info@supportroom.com</a>
              </TextBlock>
            </TherapistComment>
          </WelcomeContainer>
          {renderButton()}
        </MainSectionWrapper>
      </section>
      <section>
        <BottomSectionWrapper>
          <EmergencyBtn />
        </BottomSectionWrapper>
      </section>
    </WelcomeLayout>
  );
};

const WelcomeContainer = Styled.div`
  max-width:800px;
  display:flex;
  flex-direction:column;
  gap:${({ isMobile }) => (isMobile ? '0' : '1em')};
  margin-bottom: 0px;
`;

const ButtonGuestWrapper = Styled.div`
  position:${({ isMobile }) => isMobile && 'absolute'};
  bottom:0;
  z-index:99;
`;

const Description = Styled.div`
   &.main-description{
     div{
       &:first-child{
         line-height:1.5em;
         margin-bottom:0;
       }
       &:nth-child(2n-2){
         margin:0;
       }
     }
  }
`;
const ImageWrapper = Styled.div`
  overflow: hidden;
  margin-bottom: ${({ isMobile }) => (isMobile ? '5px' : '16px')};
  display:flex;
  align-items:center;
  justify-content: center;

  flex-wrap:wrap;
`;

const TherapistDescription = Styled.div`
    width: 100%;
    margin-top: 10px
`;

const TherapistName = Styled.p`
  font-weight: bold;
  font-size:medium;
  margin-bottom:0!important;
`;

const TherapistCertification = Styled.p`
  margin:0!important;
`;

const Image = Styled.img`
  width: ${({ isMobile }) => (isMobile ? '110px' : '130px')};
  border-radius: 50%;
  box-shadow: 0px 1px 2px rgb(0, 0, 0, 25%);
  border: .5px solid ${({ theme }) => theme.primary}
  margin-bottom: 20px
`;

const TherapistComment = Styled.div`
  margin-top:15px!important;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  font-height: 14px;
  line-height: 20px;

  div{
      max-width: ${({ isMobile }) => (isMobile ? '100%' : '550px')};
      // font-size:14px;
      text-align:left;
      border-radius:8px;
      margin-bottom:15px!important;
      background: ${({ theme }) => theme.primaryAlpha};
      padding:15px;
      .first-quote{
        position:relative;
        &::before{
            content: "“";
            font-size: 5em;
            font-weight: bold;
            position: absolute;
            top: -5px;
            left: -18px;
            opacity: .6;
            z-index:99;
        }
      }
      .quote{
        font-size:20px;
        font-weight:bold;
      }
    }
`;

const TherapistImage = Styled.div`
  position:relative;
  overflow: hidden;
  border-radius:50%;
  width: ${({ isMobile }) => (isMobile ? '100px' : '130px')};
  height: ${({ isMobile }) => (isMobile ? '100px' : '130px')};
`;
const BlendColor = Styled.div`
    background: #BBC8F4;
    height: 150px;
    width: 150px;
    left: 0;
    mix-blend-mode: color;
    position: absolute;
    top: 0;
`;

export default memo(Teams);
