import React, { memo, useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Loading, ModalComponent } from '../../Atoms';
import { api } from '../../../Util';
import { useTranslation } from 'react-i18next';
import { getProfile } from '../../../Actions/patient.actions';

const B2BEmployeeSurvey = () => {
  const { t } = useTranslation();
  const [employeeFeedback, setEmployeeFeedback] = useState([]);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const profile = useSelector((store) => store.user.profile);

  const satisfactionOnChange = (e) => {
    const updatedFeedback = [...employeeFeedback];
    let currIndex = updatedFeedback.indexOf(parseInt(e.target.name));
    if (currIndex === -1) {
      updatedFeedback.push(parseInt(e.target.name));
    } else {
      updatedFeedback.splice(currIndex, 1);
    }
    setSubmitDisabled(false);
    setEmployeeFeedback(updatedFeedback);
  };

  const submit = useCallback(async () => {
    try {
      setLoading(true);
      setSubmitDisabled(true);
      await api.patient.submitEmployeeFeedback({ priority_issue_id: employeeFeedback });
      dispatch(getProfile()).then(() => {
        setLoading(false);
        setSubmitDisabled(false);
      });
    } catch (e) {
      setSubmitDisabled(false);
      setLoading(false);
    }
  }, [dispatch, employeeFeedback]);

  if (!profile?.priority_issues || profile?.show_priority_issues !== 1) return;
  return (
    <>
      {loading && <Loading styles={{ position: 'fixed', backgroundColor: 'rgba(255,255,255,0.2)' }} />}
      <ModalComponent open={true} closeModal={() => null} showCloseButton={false}>
        <div style={{ textAlign: 'center' }}>
          <Wrapper>
            <TherapyFeedbackWrapper>
              <SubmitFeedbackWrapper>
                <h2>
                {t("surveyQues")}
                </h2>
                <Formik
                  initialValues={{
                    checked: employeeFeedback,
                  }}
                  onSubmit={submit}
                >
                  {() => (
                    <Form onChange={satisfactionOnChange}>
                      {profile.priority_issues.map((checkbox) => (
                        <CheckboxWrapper key={checkbox.title}>
                          <Checkbox
                            checked={employeeFeedback.includes(checkbox.id)}
                            label={checkbox.title}
                            name={checkbox.id}
                            //   onChange={(e)=>{console.log(e)}}
                          />
                        </CheckboxWrapper>
                      ))}
                      <ButtonWrapper>
                        <Button
                          disabled={!employeeFeedback.length || isSubmitDisabled}
                          style={{ width: '100%' }}
                          variant="secondary"
                          type="submit"
                        >
                          {t("submit")}
                        </Button>
                      </ButtonWrapper>
                    </Form>
                  )}
                </Formik>
              </SubmitFeedbackWrapper>
            </TherapyFeedbackWrapper>
          </Wrapper>
        </div>
      </ModalComponent>
    </>
  );
};

const Wrapper = styled.div`
  text-align: center;
  margin: auto;
  max-width: 700px;
  width: 100%;
`;

const TherapyFeedbackWrapper = styled.div`
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  form {
    text-align: left;
  }
`;

const SubmitFeedbackWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 0;

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  label {
    font-weight: 400;
  }
`;

const CheckboxWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing};
`;

const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacingMd};
`;

export default memo(B2BEmployeeSurvey);
