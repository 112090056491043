import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { getAnalytics, getMoodQuestions } from '../../Actions/patient.actions';

import { DefaultLayout } from '../../Layout';
import { Button, DatePicker } from '../../Components/Atoms';
import { ButtonGroup, PageHeading } from '../../Components/Molecules';
import MetricChart from './Components/MetricChart';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChartIcon } from '../../Assets/Icons/Analytics.svg';

const timePeriods = [
  {
    label: 'dailyLabel',
    value: 'daily',
    explanation: 'Weekly wellbeing meter',
    title: 'dailyTitle',
  },
  {
    label: 'weeklyLabel',
    value: 'day',
    explanation: 'Weekly wellbeing meter',
    title: 'weeklyTitle',
  },
  {
    label: 'monthlyLabel',
    value: 'month',
    explanation: 'Monthly wellbeing meter',
    title: 'monthlyTitle', //Please find your mood entries for the last 12 months
  },
];
const Analytics = () => {
  const [timeFrame, setTimeFrame] = useState({
    from: moment().subtract(6, 'days').toDate(),
    to: moment().toDate(),
    period: '',
  });
  const [timePeriod, setTimePeriod] = useState(timePeriods[0].value);
  const analytics = useSelector((store) => store.user.analytics);
  const [chartData, setChartData] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getDateRange = useCallback(() => {
    const dateToRequest = moment(timeFrame.from);
    const datesRange = [];
    while (dateToRequest.isBetween(timeFrame.from, timeFrame.to, null, '[]')) {
      datesRange.push(dateToRequest.format('YYYY-MM-DD'));
      if (timePeriod === 'month') dateToRequest.add(1, 'month');
      else dateToRequest.add(1, 'day');
    }
    return datesRange;
  }, [timeFrame, timePeriod]);

  const handleSetTimeframe = (type, date) => {
    let fromMoment = moment(moment(timeFrame.from).format('YYYY-MM-DD'));
    let toMoment = moment(moment(timeFrame.to).format('YYYY-MM-DD'));

    if (type === 'from') fromMoment = moment(moment(date).format('YYYY-MM-DD'));
    else toMoment = moment(moment(date).format('YYYY-MM-DD'));

    const duration = moment.duration(toMoment.diff(fromMoment));
    const daysDuration = duration.asDays();
    const fromDayToToday = moment.duration(moment(moment().format('YYYY-MM-DD')).diff(fromMoment));
    const fromDayToTodayDuration = fromDayToToday.asDays();

    setTimePeriod('daily');
    if (daysDuration < 0) {
      if (!fromDayToTodayDuration) {
        setTimeFrame((state) => ({
          ...state,
          from: moment().subtract(13, 'days').toDate(),
          to: moment().toDate(),
          period: 'week',
        }));
      } else if (fromDayToTodayDuration < 3) {
        setTimeFrame((state) => ({
          ...state,
          from: moment().subtract(2, 'days').toDate(),
          to: moment().toDate(),
          period: 'week',
        }));
      } else if (fromDayToTodayDuration < 14) {
        setTimeFrame((state) => ({
          ...state,
          from: fromMoment.toDate(),
          to: moment().toDate(),
          period: 'week',
        }));
      } else {
        setTimeFrame((state) => ({
          ...state,
          from: fromMoment.toDate(),
          to: fromMoment.add(13, 'days').toDate(),
          period: 'week',
        }));
      }
    } else if (daysDuration < 3) {
      let fromVal, toVal;
      if (type === 'to') {
        toVal = toMoment.toDate();
        fromVal = toMoment.subtract(2, 'days').toDate();
      } else if (fromDayToTodayDuration < 3) {
        fromVal = moment().subtract(2, 'days').toDate();
        toVal = moment().toDate();
      } else {
        fromVal = fromMoment.toDate();
        toVal = fromMoment.add(2, 'days').toDate();
      }
      setTimeFrame((state) => ({
        ...state,
        from: fromVal,
        to: toVal,
        period: 'week',
      }));
    } else if (daysDuration > 14) {
      if (type === 'from') {
        setTimeFrame((state) => ({
          ...state,
          from: fromMoment.toDate(),
          to: fromMoment.add(13, 'days').toDate(),
          period: 'week',
        }));
      } else {
        setTimeFrame((state) => ({
          ...state,
          to: toMoment.toDate(),
          from: toMoment.subtract(13, 'days').toDate(),
          period: 'week',
        }));
      }
    } else {
      setTimeFrame((timeFrame) => ({ ...timeFrame, [type]: date, period: 'week' }));
    }
  };

  useEffect(() => {
    const startDate = moment(timeFrame.from).format('YYYY-MM-DD');
    const endDate = moment(timeFrame.to).format('YYYY-MM-DD');
    dispatch(getMoodQuestions()).then(() => dispatch(getAnalytics(startDate, endDate, timeFrame.period)));
  }, [dispatch, timeFrame]);

  const setPeriod = (value) => {
    setTimePeriod(value);
    if (value === 'day') {
      setTimeFrame({
        from: moment().subtract(13, 'days').toDate(),
        to: moment().toDate(),
        period: 'week',
      });
    } else if (value === 'month') {
      setTimeFrame((state) => ({
        ...state,
        from: moment().subtract(1, 'years').toDate(),
        to: moment().toDate(),
        period: 'monthly',
      }));
    } else if (value === 'daily') {
      setTimeFrame({
        from: moment().subtract(6, 'days').toDate(),
        to: moment().toDate(),
        period: 'week',
      });
    }
  };

  useEffect(() => {
    if (analytics) {
      const dateRange = getDateRange();
      const chartData = analytics.reduce((chartData, data, index) => {
        chartData[data.tag] = analytics[index].stats.map((value, i) => ({
          x: dateRange[i],
          y: value,
        }));
        return chartData;
      }, {});
      setChartData(chartData);
    }
  }, [analytics, getDateRange]);

  return (
    <DefaultLayout>
      <ContentWrapper>
        <PageHeading icon={ChartIcon} name={t('wellbeingMeter')} />
        <p>{t('wellbeingMeterHeading')}</p>
        <ControllersWrapper>
          <div>
            <DatePicker
              name="from"
              onChange={(date) => handleSetTimeframe('from', date)}
              clearIcon={null}
              format="dd MMM yyyy"
              value={timeFrame.from}
              maxDate={new Date()}
              disabled={timePeriod === 'month' || timePeriod === 'day'}
            />
            <span>-</span>
            <DatePicker
              name="to"
              onChange={(date) => handleSetTimeframe('to', date)}
              clearIcon={null}
              format="dd MMM yyyy"
              value={timeFrame.to}
              maxDate={new Date()}
              minDate={timeFrame.from}
              disabled={timePeriod === 'month' || timePeriod === 'day'}
            />
          </div>
          <ButtonGroup value={timePeriod}>
            {timePeriods.map(({ label, value, title }) => (
              <Button key={value} name={value} onClick={() => setPeriod(value)} title={title}>
                {t(label)}
              </Button>
            ))}
          </ButtonGroup>
        </ControllersWrapper>
        {timePeriods.map(({ value, title }) => {
          return value === timePeriod && <p style={{ paddingLeft: '8px', paddingBottom: '10px' }}>{t(title)}</p>;
        })}
        <MetricsContainer>
          {chartData &&
            Object.keys(chartData).map((key, index) => (
              <MetricChart
                key={key}
                index={index}
                heading={key}
                timePeriod={timePeriod === 'daily' ? 'day' : timePeriod}
                chartData={chartData[key]}
              />
            ))}
        </MetricsContainer>
      </ContentWrapper>
    </DefaultLayout>
  );
};

const ContentWrapper = styled.div`
  max-width: 1050px;
  margin: 0 auto;
`;

const ControllersWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingMd};
  display: grid;
  row-gap: ${({ theme }) => theme.spacingMd};

  ${({ theme }) => theme.md`
    grid-template-columns: 1fr auto;
    align-items: center;
  `};
`;

const MetricsContainer = styled.div`
  display: grid;
  row-gap: ${({ theme }) => theme.spacingMd};
  column-gap: 30px;

  ${({ theme }) => theme.md`
    grid-template-columns: repeat(2, 1fr);
    // column-gap: ${theme.spacingMd};
    row-gap: ${({ theme }) => theme.spacingLg};
  `};

  ${({ theme }) => theme.lg`
    // column-gap: ${theme.spacingXl};
  `};
`;
MetricsContainer.displayName = 'MetricsContainer';

export default Analytics;
