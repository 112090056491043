import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import GlobalStyle from '../globalStyles';
import { Sidebar } from '../Therapist/Dashboard/Components';
import { Toolbar } from '../Company/Dashboard/Components';
import { useWindowSize } from '../Hooks';
import api from '../Util/api';
import { CompanyDashboardLinks } from '../Data/Menus';
import { useTranslation } from 'react-i18next';
const CompanyLayout = (props) => {
  const viewportWidth = useWindowSize().width;
  const { t } = useTranslation();
  const getUser = useCallback(() => {
    api.company.getProfile();
  }, []);

  useEffect(() => {
    getUser();
    return () => {};
  }, [getUser]);

  return (
    <>
      <GlobalStyle />
      <Toolbar links={CompanyDashboardLinks(t)} viewportWidth={viewportWidth} />
      <DashboardWrapper className={props.hideSidebar ? 'no-sidebar' : null}>
        {!props.hideSidebar && viewportWidth > 1170 && <Sidebar links={CompanyDashboardLinks(t)} />}
        <DashboardMain>
          <DashboardContent>{props.children}</DashboardContent>
        </DashboardMain>
      </DashboardWrapper>
    </>
  );
};

const DashboardWrapper = styled.section`
  display: grid;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});

  ${({ theme }) => theme.md`
    grid-template-columns: 1fr;
  `}
  ${({ theme }) => theme.lg`
    grid-template-columns: 320px 1fr;
  `}

  &.no-sidebar {
    height: auto;
    grid-template-columns: 1fr;
  }
`;
const DashboardMain = styled.main`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const DashboardContent = styled.div`
  padding: ${({ theme }) => theme.spacingLg};

  .no-sidebar & {
    padding: 0;
  }
`;

export default CompanyLayout;
