export const CompanyEmployeeData = (t) => [
  {
    id: 0,
    name: t("MrANonymous"),
    therapyType: t("CBT"),
  },
  {
    id: 1,
    name: t("MrUnKnown"),
    therapyType: t("Depression"),
  },
  {
    id: 2,
    name: t("Dave"),
    therapyType: t("Anxiety"),
  },
  {
    id: 3,
    name: t("Batman"),
    therapyType: t("OCD"),
  },
  {
    id: 4,
    name: t("JamesBond"),
    therapyType: t("Phobias"),
  },
  {
    id: 5,
    name: t("Obama"),
    therapyType: t("Anxiety"),
  },
  {
    id: 6,
    name: t("LadyGaga"),
    therapyType: t("Depression"),
  },
];
