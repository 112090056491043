import React from "react";
import styled from "styled-components";
const QuestionnairePercentBar = (props) => {
    return (
        <PercentBar percent={props.percent}></PercentBar>
    )
}
const PercentBar = styled.div`
    height: 10px;
    width: ${props => props.percent}%;
    transition:width .5s ease-in;
    background-color: #33c8a0;
    margin-right: auto;
`;
export default QuestionnairePercentBar;