import { ERRORS } from '../Constants/actionTypes';

export default function errors(state = null, action) {
  switch (action.type) {
    case ERRORS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}
