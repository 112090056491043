import {
  GET_MOOD_BOX,
  GET_THERAPIST_SCHEDULE_LIST,
  LOGIN,
  LOGOUT,
  MAP_STORAGE_TO_REDUX,
  GET_PUBLIC_THERAPIST_PROFILE,
  GET_CONCERNING_ISSUES,
  GET_SUB_CONCERNING_ISSUES,
  ACTIVE_CHAT_ROOMS,
  GET_NOTIFICATIONS,
  GET_ANNOUNCEMENTS,
  GET_PROFILE,
  UPDATE_PROFILE,
  GET_PATIENT_ANALYTICS,
  GET_SUBSCRIPTION_STATUS,
  CANCEL_SUBSCRIPTION,
  SAVE_MOOD,
  CLOSE_MOOD_BOX_THX_MESSAGE,
  GET_PATIENT_PROFILE,
  GET_PATIENT_STATS,
  GET_QUESTIONNAIRE_RESULT,
  GET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATIONS_SETTINGS,
  GET_MORE_NOTIFICATIONS,
  UPDATE_PROFILE_2FA,
  UPDATE_PROFILE_ONLINE_STATUS,
  UPDATE_EMAIL,
  REDEEM_VOUCHER,
  CLEAR_PATIENT_STATS,
  GET_PUBLIC_THERAPIST_BEGIN,
  SHOW_ANNOUNCEMENT,
  HIDE_ANNOUNCEMENT,
} from '../Constants/actionTypes';

const initialState = {
  role: null,
  scheduleList: null,
  moodBox: null,
  therapistProfile: {},
  concerningIssues: null,
  subConcerningIssues: null,
  chatRooms: null,
  notifications: null,
  announcements: null,
  profile: null,
  analytics: null,
  subscription: null,
  patientProfile: null,
  patientStats: null,
  questionnaireResult: null,
  notificationSettings: null,
  showAnnouncement: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        role: action.payload?.role,
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        profile: { ...state.profile, email: action.payload },
      };
    case UPDATE_PROFILE_2FA: {
      return {
        ...state,
        profile: { ...state.profile, '2fa_active': action.payload },
      };
    }
    case UPDATE_PROFILE_ONLINE_STATUS: {
      return {
        ...state,
        profile: {
          ...state.profile, user_online_status: action.payload },
      };
    }
    case MAP_STORAGE_TO_REDUX:
      return {
        ...state,
        role: action.payload?.role,
      };
    case GET_THERAPIST_SCHEDULE_LIST:
      return {
        ...state,
        scheduleList: action.payload,
      };
    case GET_MOOD_BOX:
      return {
        ...state,
        moodBox: action.payload,
      };
    case GET_PUBLIC_THERAPIST_BEGIN:
      return {
        ...state,
        therapistProfile: {},
      };
    case GET_PUBLIC_THERAPIST_PROFILE:
      return {
        ...state,
        therapistProfile: action.payload,
      };
    case GET_CONCERNING_ISSUES:
      return {
        ...state,
        concerningIssues: action.payload,
      };
    case GET_SUB_CONCERNING_ISSUES:
      return {
        ...state,
        subConcerningIssues: { ...state.subConcerningIssues, ...action.payload },
      };
    case ACTIVE_CHAT_ROOMS:
      return {
        ...state,
        chatRooms: action.payload,
      };

    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload,
      };

    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case GET_MORE_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          pagination: action.payload,
        },
      };
    case GET_PATIENT_ANALYTICS:
      return {
        ...state,
        analytics: action.payload,
      };
    case GET_SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscription: action.payload,
      };
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          cancelled: 1,
        },
      };
    case SAVE_MOOD:
      return {
        ...state,
        profile: {
          ...state.profile,
          is_save_today_mood: 1,
          showThxMood: true,
        },
      };

    case CLOSE_MOOD_BOX_THX_MESSAGE:
      return {
        ...state,
        profile: {
          ...state.profile,
          showThxMood: false,
        },
      };

    case SHOW_ANNOUNCEMENT:
      return {
        ...state,
        showAnnouncement: true,
      };
    case HIDE_ANNOUNCEMENT:
      return {
        ...state,
        showAnnouncement: false,
      };
    case GET_PATIENT_PROFILE:
      return {
        ...state,
        patientProfile: action.payload,
      };
    case GET_PATIENT_STATS:
      return {
        ...state,
        patientStats: action.payload,
      };
    case CLEAR_PATIENT_STATS:
      return {
        ...state,
        patientStats: null,
      };
    case GET_QUESTIONNAIRE_RESULT:
      return {
        ...state,
        questionnaireResult: action.payload,
      };
    case GET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: action.payload,
      };
    case UPDATE_NOTIFICATIONS_SETTINGS: {
      const newNotificationSettings = [...(state?.notificationSettings || [])];
      const index =
        newNotificationSettings &&
        newNotificationSettings.findIndex((notification) => notification.setting_id === action.payload.id);

      if (index !== -1) newNotificationSettings[index].status = action.payload.status;
      return {
        ...state,
        notificationSettings: newNotificationSettings,
      };
    }
    case REDEEM_VOUCHER:
      return {
        ...state,
        profile: {
          ...state.profile,
          payment_type: 'voucher',
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
