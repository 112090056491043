import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Form, Formik, Field, ErrorMessage } from 'formik';

import api from '../../../Util/api';

import { Alert, Button, FormGroup, Label, Loading } from '../../Atoms';
import { useTranslation } from 'react-i18next';

const ContactSupportForm = () => {
  const { t } = useTranslation();
  const [responseMessage, setResponseMessage] = useState({ message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("nameIsRequired")),
    email: Yup.string().email(t("emailIsInvalid")).required(t("emailIsRequired")),
    subject: Yup.string().required(t("emailSubjectisRequired")),
    message: Yup.string().required(t("emailMessageIsRequired")),
  });

  const isHubSpotChatEnabled = document.getElementById('hubspot-messages-iframe-container');

  useEffect(() => {
    document.body.classList.add('showHubSpot');
    return () => {
      document.body.classList.remove('showHubSpot');
    };
  }, [isHubSpotChatEnabled]);

  const contactSupport = async (values, { resetForm }) => {
    resetForm();
    setLoading(true);
    try {
      const response = (await api.others.contactSupport(values)).data;
      submitToHubspot(values);
      setResponseMessage({ message: response.message, type: 'primary' });
    } catch (err) {
      setResponseMessage({ message: t("errorOccurredMessage"), type: 'danger' });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const submitToHubspot = (data) => {
    var xhr = new XMLHttpRequest();
    var url = 'https://api.hsforms.com/submissions/v3/integration/submit/7714785/3de6b76d-afc5-4ee1-8397-51f03b7b6a4a'

    // Example request JSON:
    var data = {
      "submittedAt": new Date(),
      "fields": [
        {
          "name": "email",
          "value": data.email
        },
        {
          "name": "full_name",
          "value": data.name
        },
        {
          "name": "message",
          "value": data.message
        },
        {
          "name": "subject",
          "value": data.subject
        }
      ],
    }

    var final_data = JSON.stringify(data)

    xhr.open('POST', url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        console.log(xhr.responseText); // Returns a 200 response if the submission is successful.
      } else if (xhr.readyState == 4 && xhr.status == 400) {
        console.log(xhr.responseText); // Returns a 400 error the submission is rejected.          
      } else if (xhr.readyState == 4 && xhr.status == 403) {
        console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
      } else if (xhr.readyState == 4 && xhr.status == 404) {
        console.log(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
      }
    }


    // Sends the request 

    xhr.send(final_data)
  }

  return (
    <>
      {!responseMessage.message ? (
        <Formik
          initialValues={{
            name: '',
            email: '',
            subject: '',
            message: '',
          }}
          onSubmit={contactSupport}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <FormGroup>
                <Label htmlFor="name">{t("name")}</Label>
                <Field id="name" name="name" className={errors.name && touched.name ? ' is-invalid' : ''} />
                <ErrorMessage name="name" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">{t("email_Address")}</Label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className={errors.email && touched.email ? ' is-invalid' : ''}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="subject">{t("subject")}</Label>
                <Field
                  type="text"
                  id="subject"
                  name="subject"
                  className={errors.subject && touched.subject ? ' is-invalid' : ''}
                />
                <ErrorMessage name="subject" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="message">{t("Message")}</Label>
                <TextareaWrapper>
                  <Field
                    rows={5}
                    id="message"
                    name="message"
                    as={StyledTextarea}
                    className={errors.message && touched.message ? ' is-invalid' : ''}
                    placeholder={t("messagePlaceholder")}
                  />
                </TextareaWrapper>
                <ErrorMessage name="message" component="div" className="invalid-feedback" />
              </FormGroup>
              <Button type="submit">{t("send")}</Button>
            </Form>
          )}
        </Formik>
      ) : (
        <Alert className={responseMessage.type}>{responseMessage.message}</Alert>
      )}
      {loading && <Loading />}
    </>
  );
};

const TextareaWrapper = styled.div`
  textarea {
    ::placeholder {
      color: #999999;
      font-size: 16px;
    }
  }
`;

const StyledTextarea = styled.textarea`
  resize: none;
`;
StyledTextarea.displayName = 'StyledTextarea';

export default ContactSupportForm;
