import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';
// import { useSelector } from 'react-redux';
// import moment from 'moment';

const Tooltip = ({ children, id, text, place, offset = {} }) => {
  // const registerDate = useSelector((store) => store.user?.profile?.registration_complete);
  // const registerDateT = useSelector((store) => store.user?.profile?.register_date);

  // const dueDate = moment(registerDate || registerDateT);
  // const diff = moment().diff(dueDate, 'hours');

  // if (diff > 24) return children;

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          'data-for': id,
          'data-tip': text,
        }),
      )}
      <ReactTooltip id={id} place={place} effect="solid" offset={offset} multiline={true}>
        {text}
      </ReactTooltip>
    </>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  offsetRight: PropTypes.bool,
};

export default memo(Tooltip);
