import React from 'react';
import styled from 'styled-components';
import logo from '../../../Assets/Icon@1X.png';
import PropTypes from 'prop-types';
const Logo = (props) => {
  return <Image {...props} src={logo} />;
};
const Image = styled.img``;
Logo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
Logo.defaultProps = {
  height: '100px',
  width: '100px',
};
export default Logo;
