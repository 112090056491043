import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from './Components/Atoms';
import { api } from './Util';
import config from './Config';
import { useTranslation } from 'react-i18next';

function ErrorFallback({ error = {}, resetErrorBoundary = () => null }) {
  const { t } = useTranslation();
  useEffect(() => {
    // call api for error tracking
    api.others.clientError({ text: JSON.stringify(error.stack) });
  }, [error?.message]);

  return (
    <Container role="alert">
      <Wrapper>
        <img src={config.iconLogo} alt="" style={{ width: '200px' }} />
        <SubWrapper>
          <Text>{t("something_went_wrong")}</Text>
          <SubText>{t("fixedAtTheEarliest")} :)</SubText>
        </SubWrapper>

        <Button style={{ width: '200px', padding: '20px' }} onClick={resetErrorBoundary}>
        {t("restartSession")}
        </Button>
      </Wrapper>
    </Container>
  );
}

export default ErrorFallback;

const Container = styled.div`
  background-color: #faf3ff;
  height: 100vh;
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 600;

  ${({ theme }) => theme.md`
  font-size: 20px;
  `}
`;

const SubText = styled.div`
  font-size: 12px;
  font-weight: 500;

  ${({ theme }) => theme.md`
  font-size: 14px;
  `}
`;

const SubWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const Wrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  height: 55%;
  padding: 50px;
  padding-bottom: 70px;
  border-radius: 20px;
  margin: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  ${({ theme }) => theme.md`
    width: 45%;
    height: 55%;
  padding-bottom: 100px;
  `}
`;
