import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Heading } from '../../Components/Atoms';
import { useTranslation } from 'react-i18next';
import Loading from '../../Components/Atoms/Loading/Loading';
import { getActiveChatRooms } from '../../Actions/chatroom.actions';
import { AuthLayout } from '../../Layout';
import { Profile } from '../Chatroom/Components';

const TherapistAssignment = () => {
  const { t } = useTranslation();
  const profile = useSelector((store) => store.user.profile);
  const rooms = useSelector((store) => store.chatroom.rooms);

  const dispatch = useDispatch();

  const therapistMatch = useMemo(() => {
    return !!(rooms?.length && rooms[0].chatroom_status !== 'waiting' && rooms[0].chatroom_status !== 'pending');
  }, [rooms]);

  useEffect(() => {
    // if (!profile) dispatch(getProfile());
    if (rooms === null) dispatch(getActiveChatRooms());
  }, [rooms, dispatch]);

  if (!profile || !rooms) return Loading;

  return (
    <AuthLayout>
      <Wrapper>
        <Heading>{t("hello")} {profile.first_name}, {t("welcomeToSupportRoom")}</Heading>
        <Heading level={3}>{t("therapistAssignmentHeading1")}</Heading>
        {therapistMatch ? (
          <p>{t("therapistMatched")}</p>
        ) : (
          <p>{t("findingTherapist")}</p>
        )}
        {therapistMatch && (
          <ProfileWrapper>
            <Profile showBio />
          </ProfileWrapper>
        )}
        <Button as={Link} to="/chatroom" className="secondary">
        {t("continue")}
        </Button>
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
  margin: 20px 0;
`;

export default TherapistAssignment;
