import React from "react";
import styled from "styled-components";
import Microphone from "../../../Assets/Icons/Microphone.svg";
import PlusCircle from "../../../Assets/Icons/PlusCircle.svg";

const Form = props => {
  return (
    <ChatForm>
      <ActionButton>
        <img src={PlusCircle} alt="" />
      </ActionButton>
      <input type="text" placeholder="Message..." />
      <DictateButton>
        <img src={Microphone} alt="" />
      </DictateButton>
    </ChatForm>
  );
};

/* --------------------------------- Styles --------------------------------- */

const ChatForm = styled.div`
  margin-top: ${({ theme }) => theme.spacing};
  border-top: 1px solid ${({ theme }) => theme.primaryMedium};
  padding: ${({ theme }) => theme.spacing} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ActionButton = styled.div`
  width: 40px;
  height: 40px;
  margin-right: ${({ theme }) => theme.spacing};
`;
const DictateButton = styled.div`
  width: 40px;
  height: 40px;
  margin-left: ${({ theme }) => theme.spacing};
`;

export default Form;
