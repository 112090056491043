import React, { useState, memo, useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components';

import api from '../../../Util/api';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionStatus } from '../../../Actions/patient.actions';
import { PaymentPlan, VoucherPlan } from '../../Molecules';
import notify from '../../../Util/notify';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentPlans = ({ plans, isPartnerState }) => {
  const { t } = useTranslation();
  const subscription = useSelector((store) => store.user.subscription);
  const paymentType = useSelector((store) => store.user?.profile?.payment_type);

  const dispatch = useDispatch();

  const handleClick = useCallback(
    async (planId) => {
      const stripe = await stripePromise;

      if (
        planId &&
        (!subscription ||
          subscription.length === 0 ||
          subscription?.plan_status === 'expired' ||
          subscription?.plan_status === 'unsubscribed')
      ) {
        // Call your backend to create the Checkout Session
        return api.payment
          .purchasePlan(
            planId,
            `${window.location.origin}/checkout/success/`,
            `${window.location.origin}/checkout/error`,
          )
          .then((session) => {
            // When the customer clicks on the button, redirect them to Checkout.
            stripe
              .redirectToCheckout({
                sessionId: session.data?.data?.checkout_session_id,
              })
              .then((result) => {
                dispatch(getSubscriptionStatus());
                if (result.error) {
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                }
              });
          })
          .catch((err) => {
            notify('Payment failed!', err.response?.data?.message, 'warning');
          });
      } else {
        return api.payment
          .switchPlan(planId)
          .then((res) => {
            if (res.data.status === 'success')
              notify('Success!', t("subscriptionPlanUpdated"), 'success');

            dispatch(getSubscriptionStatus());
          })
          .catch((err) => {
            notify('Switch failed!', err.response?.data?.message, 'warning');
          });
      }
    },
    [subscription, dispatch],
  );

  return (
    <PlansWrapper>
      {paymentType !== 'voucher' &&
        plans?.map((plan) => (
          <PaymentPlan key={plan.package_id} plan={plan} subscription={subscription} onSubmit={handleClick} />
        ))}
      {!isPartnerState && <VoucherPlan />}
    </PlansWrapper>
  );
};

const PlansWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;

  justify-content: space-between;
  margin-bottom: 40px;

  @media screen and (max-width: 4000px) {
    justify-content: center;
    margin-bottom: 0;
    padding: 20px 0;
  }
`;

export default memo(PaymentPlans);
