import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import api from '../Util/api';
import { useParams } from "react-router-dom";
import { NeutralLayout } from '../Layout';
import { Heading, FormGroup, Button } from '../Components/Atoms';

const ForgottenPassword = () => {
  const [showResponse, setShowResponse] = useState(false);
  const { t } = useTranslation();
  let { role } = useParams();
  const handleOnSubmit = async (values, { resetForm, setSubmitting, setFieldError }) => {
    try {
      await api.auth.forgotPassword(values);
      resetForm();
      setShowResponse(true);
    } catch (err) {
      setFieldError('email', err.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("emailIsInvalid")).required(t("emailIsRequired")),
  });

  return (
    <NeutralLayout>
      <Wrapper>
        {showResponse ? (
          <>
            <Heading>{t("thankYou1")}</Heading>
            <p className="lead">{t("emailSentWithPasswordResetCode")}</p>
          </>
        ) : (
          <>
            <Heading>{t("forgottenPassword")}</Heading>
            <p className="lead">{t("emailLinkToResetYourPassword")}</p>
            <Formik
              initialValues={{
                email: '',
                role: role
              }}
              validationSchema={validationSchema}
              onSubmit={handleOnSubmit}
            >
              {({ errors, touched }) => (
                <StyledForm>
                  <FormGroup>
                    <Field
                      id="email"
                      name="email"
                      placeholder={t("emailAddress")}
                      type="email"
                      className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <Button type="submit">{t("submit")}</Button>
                </StyledForm>
              )}
            </Formik>
          </>
        )}
        <br />
        <br />
        <Button as={Link} to="/welcome/signin" className="secondary">
        {t("returnToLogin")}
        </Button>
      </Wrapper>
    </NeutralLayout>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledForm = styled(Form)`
  display: grid;
  column-gap: 4px;
  row-gap: ${({ theme }) => theme.spacing};

  > * {
    margin: 0;
  }

  ${({ theme }) => theme.md`
    grid-template-columns: 1fr auto;
    align-items: flex-start;
    
    button {
      height: 44px;
    }
  `}
`;
StyledForm.displayName = 'StyledForm';

export default ForgottenPassword;
