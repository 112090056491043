import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getFaqCategories, getFaqCategoriesList } from '../../../Actions/chatroom.actions';

import { FaqSidebar } from '../../../Components/Organisms';
import { useTranslation } from 'react-i18next';

const Faq = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const role = useSelector((store) => store.auth.role);

  const dispatch = useDispatch();

  const toggleMenu = (bool) => {
    setIsOpen(bool);
  };

  const loadFaq = useCallback(async () => {
    try {
      const categories = (await dispatch(getFaqCategoriesList(role))).data;
      const filteredCategories = categories.filter(category => category.hasOwnProperty('faq'));
      setCategories(filteredCategories);
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, role]);

  useEffect(() => {
    if (isOpen) loadFaq();
  }, [isOpen, loadFaq]);

  return (
    <FaqSidebar
      categories={categories}
      isOpen={isOpen}
      onToggleMenu={toggleMenu}
      customBurgerIcon={
        <StyledButton id="faq-button" onClick={()=>toggleMenu(true)}>
          {t("faq")}
        </StyledButton>
      }
    />
  );
};

const StyledButton = styled.button`
  padding: 0;
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.secondary};
  background-color: transparent;
  border: none;
  text-decoration: underline;
  -webkit-appearance: none;
`;
StyledButton.displayName = 'StyledButton';

export default Faq;
