import * as React from "react";
const GreenDot = (props) => (
  <svg
    width={10}
    height={9}
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.88849 4.5C9.88849 6.98528 7.87377 9 5.38849 9C2.90321 9 0.888489 6.98528 0.888489 4.5C0.888489 2.01472 2.90321 0 5.38849 0C7.87377 0 9.88849 2.01472 9.88849 4.5Z"
      fill="#00C8A0"
    />
  </svg>
);
export default GreenDot;
