import React, { memo, useCallback, useState } from 'react';
// import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, ChevronIcon, TextBlock, Logo } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import { MainSectionWrapper, BottomSectionWrapper } from '../Components/';
import { loadStripe } from '@stripe/stripe-js';
import { api, useDeviceType } from '../../Util';
import EmergencyBtn from '../../Components/Atoms/EmergencyBtn';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Activate = () => {
  // const socialRequestPopupStatus = useSelector((store) => store.auth.socialRequestPopupStatus);
  // const loginInfo = localStorage.getItem('loginInfo');
  // const isLoggedIn = !!loginInfo;
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const planId = query.get('plan');
  const [isLoading, setIsloading] = useState(false);
  const isMobile = useDeviceType();
  const { t } = useTranslation();

  if (!planId) history.goBack();
  // if (isLoggedIn && !socialRequestPopupStatus) history.push('/chatroom');

  /**
   *  Plan activation stripe call
   */
  const handlePayment = useCallback(async () => {
    const stripe = await stripePromise;
    setIsloading(true);
    if (planId) {
      // Call your backend to create the Checkout Session
      return api.payment
        .purchasePlan(planId, `${window.location.origin}/welcome/setup`, `${window.location.origin}/checkout/error`)
        .then((session) => {
          // When the customer clicks on the button, redirect them to Checkout.
          stripe
            .redirectToCheckout({
              sessionId: session.data?.data?.checkout_session_id,
            })
            .then((result) => {
              if (result.error) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
              }
            });
        });
    }
  }, [planId]);

  return (
    <WelcomeLayout noPadding noTopBar>
      <section>
        <MainSectionWrapper>
          <Logo />

          {/* <TextBlock type={'primary'}>Our clients recieve <span style={{ color: '#33c8a0' }}>one week of unlimited messaging therapy</span> with their first subscription.</TextBlock> */}
          <TextBlock type={'primary'}> {t('paymentDetails')}</TextBlock>
          <TextBlock type={'secondary'} paddingHorizontal={isMobile ? '5px' : '50px'}>
            {t('testBlockPayment')}
          </TextBlock>

          <Button
            variant="secondary"
            style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
            onClick={handlePayment}
            disabled={isLoading}
          >
            {t('proceed')}
            <ChevronIcon direction="right" width="13" height="13" />
          </Button>

          {/* <TextBlock type={'primary'}>Enter your payment details to activate your free week</TextBlock>
            <TextBlock type={'secondary'} paddingHorizontal={'50px'}>
              You'll be asked to enter your payment details however our first week is completely free and involves unlimited messaging therapy access with a counsellor. After the first week, the subscription will automatically default to whatever option you selected.
            </TextBlock>
            <TextBlock type={'emphasized'} paddingHorizontal={'50px'}>
              If you don't wish to continue beyond the free week, please cancel before the one week trial is over
            </TextBlock> */}
          {/* <TextBlock type={'secondary'} paddingHorizontal={'50px'}>
              After 7 days, we’ll be in touch to discuss the various plans available to you and find one to suit.
            </TextBlock> */}
        </MainSectionWrapper>
      </section>
      <section>
        <BottomSectionWrapper>
          <EmergencyBtn />
          {/* <Button
            variant="secondary"
            style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
            onClick={handlePayment}
            disabled={isLoading}
          >
            Activate free week
            <ChevronIcon direction="right" width="13" height="13" />
          </Button> */}
        </BottomSectionWrapper>
      </section>
    </WelcomeLayout>
  );
};

export default memo(Activate);
