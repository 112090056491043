import React from 'react';
import { AuthLayout } from '../../Layout';
import { CompanySignupForm } from '../../Components/Organisms';
import Redirect from '../../Util/Redirect';

const Signup = (props) => {
  const LoggedIn = localStorage.getItem('loginInfo') ? true : false;

  return (
    <AuthLayout>
      {LoggedIn ? (
        <Redirect {...props} url="/t/signup/step-2" />
      ) : (
        <>
          <CompanySignupForm userType="company" heading="Company Sign Up" />
        </>
      )}
    </AuthLayout>
  );
};

export default Signup;
