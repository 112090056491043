import * as React from "react";
const VideoAppointment = (props) => (
  <svg
    width={27}
    height={27}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 9.33333H26M6.55556 1V3.77778M20.4444 1V3.77778M5.16667 13.5H7.94444M12.1111 13.5H14.8889M19.0556 13.5H21.8333M5.16667 17.6667H7.94444M12.1111 17.6667H14.8889M19.0556 17.6667H21.8333M5.16667 21.8333H7.94444M12.1111 21.8333H14.8889M19.0556 21.8333H21.8333M5.44444 26H21.5556C23.1113 26 23.8892 26 24.4833 25.6972C25.006 25.431 25.431 25.006 25.6972 24.4833C26 23.8892 26 23.1113 26 21.5556V8.22222C26 6.66651 26 5.88867 25.6972 5.29447C25.431 4.77179 25.006 4.34685 24.4833 4.08054C23.8892 3.77778 23.1113 3.77778 21.5556 3.77778H5.44444C3.88875 3.77778 3.11089 3.77778 2.51669 4.08054C1.99401 4.34685 1.56907 4.77179 1.30276 5.29447C1 5.88867 1 6.66651 1 8.22222V21.5556C1 23.1113 1 23.8892 1.30276 24.4833C1.56907 25.006 1.99401 25.431 2.51669 25.6972C3.11089 26 3.88874 26 5.44444 26Z"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export default VideoAppointment;
