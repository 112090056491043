import React from 'react';
import { NotificationsPage } from '../../Components/Organisms';

const Notifications = () => {
  return (
    <NotificationsPage role={'therapist'} />
  );
};

export default Notifications;
