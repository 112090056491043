import React from 'react';
import { useTranslation } from 'react-i18next';

const DepartmentsAffected = () => {
  const { t } = useTranslation();
  const data = [
    {
      id: 0,
      dept: t("sales"),
      score: '65%',
    },
    {
      id: 1,
      dept: t("customerServices"),
      score: '15%',
    },
    {
      id: 2,
      dept: t("management"),
      score: '10%',
    },
    {
      id: 3,
      dept: t("marketing"),
      score: '5%',
    },
    {
      id: 4,
      dept: t("design"),
      score: '5%',
    },
  ];
  return (
    <>
      <table>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.dept}</td>
              <td>{item.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default DepartmentsAffected;
