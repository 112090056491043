import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { TimeSeriesWidget } from '../../Components/Organisms';
import { Select } from '../../Components/Atoms';

import { getStats } from '../../Actions/chatroom.actions';
import { getCommission, getScheduleList } from '../../Actions/therapist.actions';

const selectOptions = [
  { value: 'completed-sessions', label: 'Completed Sessions' },
  { value: 'upcoming-sessions', label: 'Upcoming Sessions' },
  // TODO Add the options below when it's possible to get this data
  // { value: 'completed-cases', label: 'Completed Cases' },
  { value: 'missed-sessions', label: 'Missed Sessions' },
  // { value: 'time-spent', label: 'Time Spent' },
  { value: 'messages-sent', label: 'Messages Sent' },
  // { value: 'earnings', label: 'Earnings' },
];

const Activity = () => {
  const [dataToView, setDataToView] = useState(selectOptions[0]);
  const [timeFrame, setTimeFrame] = useState({
    from: moment().startOf('isoWeek').toDate(),
    to: moment().endOf('isoWeek').toDate(),
  });
  const [chartData, setChartData] = useState({ data: [], label: '' });
  const dispatch = useDispatch();

  const getDateRange = useCallback(() => {
    const dateToRequest = moment(timeFrame.from);
    const datesRange = [];
    while (dateToRequest.isBetween(timeFrame.from, timeFrame.to, null, '[]')) {
      datesRange.push(dateToRequest.format('YYYY-MM-DD'));
      dateToRequest.add(1, 'day');
    }
    return datesRange;
  }, [timeFrame]);

  const getFromStats = useCallback(
    async (label, field) => {
      const responseData = [];

      for (const date of getDateRange()) {
        const response = (await dispatch(getStats(date))).data;
        responseData.push({ date, value: Array.isArray(response) ? {} : response });
      }

      const data = responseData.reduce((data, { date, value }) => {
        data.push({ x: date, y: value?.[field] || 0 });
        return data;
      }, []);

      return { data, label };
    },
    [dispatch, getDateRange],
  );

  const getCompletedSessions = useCallback(async () => {
    return await getFromStats('Completed Sessions', 'video_sessions');
  }, [getFromStats]);

  const getMissedSessions = useCallback(async () => {
    return await getFromStats('Missed Sessions', 'missed_sessions');
  }, [getFromStats]);

  const getMessages = useCallback(async () => {
    return await getFromStats('Messages Sent', 'total_messages');
  }, [getFromStats]);

  const getUpcomingSessions = useCallback(async () => {
    const dateRange = getDateRange();
    const response = (await dispatch(getScheduleList('booked'))).data;
    const data = response
      .filter(({ schedule_date }) => dateRange.includes(schedule_date))
      .reduce((data, { schedule_date, slots }) => {
        data.push({ x: schedule_date, y: slots.length });
        return data;
      }, []);

    return {
      data,
      label: 'Upcoming Sessions',
    };
  }, [dispatch, getDateRange]);

  const getEarnings = useCallback(async () => {
    const response = (await dispatch(getCommission())).data || [];
    const data = response
      .filter(({ date }) => moment(date).isBetween(timeFrame.from, timeFrame.to))
      .map(({ date, commission }) => ({ x: new Date(date?.split(' ')[0]), y: +commission }));

    return { data, label: 'Commission' };
  }, [dispatch, timeFrame]);

  const handleDataChange = (data) => setDataToView(data);

  const handleTimeFrameChange = useCallback((type, date) => {
    setTimeFrame((prevState) => ({ ...prevState, [type]: date }));
  }, []);

  const handleTimePeriodChange = useCallback((timePeriod) => {
    if (timePeriod === 'day') {
      setTimeFrame({
        from: moment().startOf('isoWeek').toDate(),
        to: moment().endOf('isoWeek').toDate(),
      });
    } else if (timePeriod === 'month') {
      setTimeFrame({
        from: moment().startOf('year').toDate(),
        to: moment().endOf('year').toDate(),
      });
    }
  }, []);

  const getDataToView = useCallback(async () => {
    if (timeFrame.to.getTime() < timeFrame.from.getTime()) return;

    let response;
    let data = [];
    let label = '';

    switch (dataToView.value) {
      case 'completed-sessions':
        response = await getCompletedSessions();
        break;
      case 'upcoming-sessions':
        response = await getUpcomingSessions();
        break;
      case 'missed-sessions':
        response = await getMissedSessions();
        break;
      case 'messages-sent':
        response = await getMessages();
        break;
      case 'earnings':
        response = await getEarnings();
        break;
      default:
        response = { data, label };
        break;
    }

    label = response.label;
    data = response.data;
    setChartData({ data, label });
  }, [timeFrame, dataToView, getCompletedSessions, getUpcomingSessions, getMissedSessions, getMessages, getEarnings]);

  useEffect(() => {
    getDataToView();
  }, [getDataToView]);

  return (
    <TimeSeriesWidget
      chartData={chartData}
      heading={'Activity'}
      timeFrame={timeFrame}
      optionSelects={
        <Select onChange={handleDataChange} border="greyLight" options={selectOptions} value={dataToView} />
      }
      onTimeFrameChange={handleTimeFrameChange}
      onTimePeriodChange={handleTimePeriodChange}
    />
  );
};

export default Activity;
