import React from 'react';
import { Star } from '../../Atoms';

const StarRating = ({ rating, total }) => {
  const StarCount = total;

  let starArray = [];
  for (let i = 0; i < StarCount; ++i) {
    starArray.push(<Star key={i} on={i < rating} />);
  }

  return <div>{starArray}</div>;
};

export default StarRating;
