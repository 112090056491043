import React, { createContext, useContext, useReducer } from "react";

const StoreContext = createContext();

const initialState = {
  loggedIn: false,
  access_token: "",
  expires_at: "",
  role: "",
  patient: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "login":
      return {
        loggedIn: action.status,
        access_token: action.token,
        expires_at: action.expiry,
        role: action.role,
      };
    case "patientData":
      return {
        patient: action.patientData,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
