import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Loading } from '../../Components/Atoms';
import { Header, TherapyTools } from '../../Components/Organisms';
import { api } from '../../Util';
import { therapyToolsLinks } from '../Chatroom/Components/Sidebar';
import { ReactComponent as whiteArrowIcon } from './whiteArrow.svg';
import { ReactComponent as purpleArrowIcon } from './purpleArrow.svg';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const DiscountCategories = () => {
  const [category, setcategory] = useState([]);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);
      const response = await api.patient.viewDiscountCategories();
      setLoading(false);
      setcategory(response?.data?.data);
    };
    getCategories();
  }, []);

  const moveTo = (link) => history.push(link);
  return (
    <>
      {loading && <Loading styles={{ position: 'fixed', backgroundColor: 'rgba(255,255,255,0.2)' }} />}
      <Header />
      <Container>
        <Wrapper>
          <SubWrapper>
            <div>
              <TherapyTools
                heading="Therapy Tools"
                links={therapyToolsLinks}
                isChatRoomActive={true}
                setModalState={() => null}
              />
            </div>
          </SubWrapper>
          <DiscountWrapper>
            <HeadingWrapper>
              <HeadingSpan>
                <BackIcon as={purpleArrowIcon} onClick={() => moveTo('/discounts')} />
                <Pathwrapper style={{ color: '#491f69', cursor: 'pointer' }} onClick={() => moveTo('/discounts')}>
                  {t('discounts')}
                </Pathwrapper>
                <Pathwrapper
                  style={{ color: '#491f69', cursor: 'pointer' }}
                  onClick={() => {
                    moveTo('/discounts/categories');
                  }}
                >
                  <Gap>/</Gap>
                  {t('categories')}
                </Pathwrapper>
              </HeadingSpan>
            </HeadingWrapper>

            <StyledContainer>
              {category?.length
                ? category.map((element) => (
                    <CategoryCard
                      style={{
                        backgroundImage: `linear-gradient(180deg, transparent, rgba(73, 31, 105, 1)), url(${element.image})`,
                      }}
                      onClick={() => {
                        moveTo(`/discounts/categories/${element.id}`);
                      }}
                    >
                      <CategorySection>
                        <Title style={{ fontSize: '17px', color: 'white' }}> {element.name}</Title>

                        <Icon as={whiteArrowIcon} style={{ color: 'white' }} />
                      </CategorySection>
                    </CategoryCard>
                  ))
                : null}
            </StyledContainer>
          </DiscountWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default DiscountCategories;

const HeadingSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;

const Gap = styled.div`
  margin-right: 5px;
`;

const StyledContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  ${({ theme }) => theme.md`
    align-items: flex-start;
    justify-content: flex-start;
  `}
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const BackIcon = styled.svg`
  margin-right: 10px;
  cursor: pointer;
`;

const Pathwrapper = styled.div`
  color: #491f69;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  display: flex;
`;

const CategorySection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.svg`
  transform: scaleX(-1);
`;

const SubWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.md`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 300px;
  align-items: flex-start;
  `}
`;

const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const CategoryCard = styled.div`
  height: 120px;
  width: 199px;
  border-radius: 40px;
  background-color: white;
  border: 1px solid #dad9da;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-self: center;
  justify-content: flex-end;
  text-align: left;
  cursor: pointer;
  background-size: cover;
`;

const Wrapper = styled.div`
  background-color: white;
  margin-top: 30px;
  display: flex;
`;

const Title = styled.div`
  font-size: 17px;
  color: #491f69;
  font-weight: 500;
  margin-left: 10px;
`;
