import {
  QUESTION_PROGRESS,
  STARTQUIZ_SUCCESS,
  SUBMIT_QUIZ_ANSWER_SUCCESS,
  SUBMITTING_ANSWER,
  FETCHING_QUIZ_DATA,
  QUESTIONNAIRE_BACK_SUCCESS,
  QUESTIONNAIRE_BACK_BEGIN,
  QUIZ_COMPLETED,
  TERMINATE_QUESTIONNAIRE
} from '../Constants/actionTypes';

const initialState = {
  fetchingQuiz: false,
  quizdata: {
    data: {
      question: '',
      answers: [],
    },
    message: '',
    quiz_journey: '',
    status: '',
    redirect_link: ''
  },
  quizStatus: 'onGoing', // Status will be updated with complete when the quiz completes. Based on the status, some routes are handled.
  percentCompletion: 0,
  isSubmittingAnswer: false
};

export default function questionnaire(state = initialState, action) {
  switch (action.type) {
    case STARTQUIZ_SUCCESS:
      return {
        ...state,
        quizdata: action.payload,
        fetchingQuiz: false
      };
    case FETCHING_QUIZ_DATA:
      return {
        ...state,
        fetchingQuiz: action.payload,
      };
    case SUBMIT_QUIZ_ANSWER_SUCCESS:
      return {
        ...state,
        quizdata: action.payload,
        isSubmittingAnswer: false
      };
    case QUESTION_PROGRESS:
      return {
        ...state,
        percentCompletion: action.payload === 0 ? 0 : (state.percentCompletion > 90) ? 100 : (action.payload === -7 && state.percentCompletion === 0) ? 80 : state.percentCompletion + action.payload
      }
    case SUBMITTING_ANSWER:
      return {
        ...state,
        isSubmittingAnswer: true
      }
    case QUESTIONNAIRE_BACK_BEGIN:
      return {
        ...state,
        isSubmittingAnswer: true
      }
    case QUESTIONNAIRE_BACK_SUCCESS:
      return {
        ...state,
        quizdata: action.payload ? action.payload : state.quizdata,
        isSubmittingAnswer: false
      }
    case QUIZ_COMPLETED:
      return {
        ...state,
        quizStatus: 'completed'
      }
    case TERMINATE_QUESTIONNAIRE:
      return {
        ...state,
        quizdata: initialState.quizdata
      }
    default:
      return state;
  }
}
