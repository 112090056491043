import React, { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { login } from '../../Actions/auth.actions';

import { passwordValidation } from '../../Constants/validations';
import { useTranslation } from 'react-i18next';
import { MainSignUp } from './signUpComponent';
import { StyledFormGroup, FieldWrapper, SubmitButtonWrapper, SignUpButton, LoadingDots } from './signUpForm';
import { Alert, Heading } from '../../Components/Atoms';
import FacebookInitComponent from './facebookInitComponent';
import GoogleInitComponent from './googleInitComponent';
import { socialLogin } from '../../Actions/patient.actions';
import ModalComponent from '../../Components/Atoms/Modal/Modal';
import { Store } from 'react-notifications-component';
import { api } from '../../Util';
import { genericLanguageMapping } from '../../App';

const LoginComponent = ({ isEnterprise }) => {
  const role = 'patient';
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [smsCodeError, setSmsCodeError] = useState(null);
  const teamsContext = useSelector((store) => store.teams.teamsContext);
  const selectedLanguage = useSelector((store) => store.language.selectedLanguage);

  const [loginData, setLoginData] = useState(null);

  const smsCodeModalState = useSelector((store) => store.auth.smsCodeRequestModalStatus);

  const dispatch = useDispatch();
  const history = useHistory();

  const validationSchema = useMemo(() => {
    const validations = {
      email: Yup.string().email(t('emailIsInvalid')).required(t('emailIsRequired')),
      password: passwordValidation(t),
    };

    return Yup.object().shape(validations);
  }, []);

  const initialValues = useMemo(() => {
    return {
      email: '',
      password: '',
    };
  }, []);

  const logIn = async (values, { setSubmitting }) => {
    setLoading(true);

    setLoginData(values);
    try {
      const response = await dispatch(login('patient', values, history, '/chatroom', false));
      // If TFA, not enabled
      if (!response?.data?.status_2fa) {
        await api.patient.patinetLangaugeSet(genericLanguageMapping[selectedLanguage]);
      }
    } catch (err) {
      let loginErrorCodes = [401, 403, 404, 500]
      let message = err.response?.data?.message
      if (loginErrorCodes.includes(err?.response?.status)) {
        message = err.response?.data?.message !== "Unauthorized" &&
          err?.response?.status === 401
          ? err.response?.data?.message
          : t('emailOrPasswordNotRecognised')
      }
      setError({ message });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const socialSignUp = useCallback(
    (data, type) => {
      if (type === 'google' && data) {
        const { googleId, email, name } = data;
        if (googleId && email) {
          const data = { email, social_id: googleId, social_type: 'google', mobile: '00000000000', first_name: name };
          dispatch(socialLogin(data, history)).catch((err) =>
            Store.addNotification({
              title: t('somethingWentWrong'),
              message: err.response?.data?.message,
              type: 'danger',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            }),
          );
        }
      } else if (type === 'facebook' && data) {
        const { id, email, name } = data;
        if (id && email) {
          const data = { email, social_id: id, social_type: 'facebook', mobile: '00000000000', first_name: name };
          dispatch(socialLogin(data, history)).catch((err) =>
            Store.addNotification({
              title: t('somethingWentWrong'),
              message: err.response?.data?.message,
              type: 'danger',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            }),
          );
        }
      }
    },
    [dispatch, history],
  );

  const closeModal = () => {};
  const loginWithSmsCode = async (values) => {
    setLoading(true);

    try {
      await dispatch(login('patient', { ...loginData, code_2fa: values.code }, history, '/chatroom', false)).then(
        (res) => {
          if (res.status === 'error') {
            setSmsCodeError(res.message);
          } else {
            api.patient.patinetLangaugeSet(genericLanguageMapping[selectedLanguage]);
          }
        },
      );
    } catch (err) {
      setSmsCodeError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <ModalComponent
        open={smsCodeModalState}
        closeModal={closeModal}
        showCloseButton={false}
        closeOnDocumentClick={true}
      >
        <div>
          <Heading level={3}>{t('inputSMSCodeReceived')}</Heading>
          <Formik
            initialValues={{ code: '' }}
            validationSchema={Yup.object().shape({ code: Yup.string() })}
            enableReinitialize
            onSubmit={loginWithSmsCode}
          >
            {({ errors, touched }) => (
              <Form>
                <StyledFormGroup>
                  <FieldWrapper>
                    <label htmlFor="email">{t('smsCode')}</label>
                    <Field
                      id="code"
                      name="code"
                      type="text"
                      className={'code' + (errors.code && touched.code ? ' is-invalid' : '')}
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </FieldWrapper>
                </StyledFormGroup>
                {smsCodeError && <Alert style={{ marginBottom: 20 }}>{smsCodeError}</Alert>}
                <StyledSubmitButtonWrapper>
                  <SignUpButton type="submit" disabled={loading}>
                    {loading && (
                      <LoadingDots>
                        <div />
                        <div />
                        <div />
                        <div />
                      </LoadingDots>
                    )}
                    <span style={{ opacity: loading ? 0 : 1 }}>{t('login')}</span>
                  </SignUpButton>
                </StyledSubmitButtonWrapper>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
      <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={logIn}>
        {({ errors, touched }) => (
          <Form>
            <StyledFormGroup>
              <FieldWrapper>
                <label htmlFor="email">{t('emailAddressImportant')}</label>
                <Field
                  id="email"
                  name="email"
                  type="text"
                  className={'email' + (errors.email && touched.email ? ' is-invalid' : '')}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FieldWrapper>
            </StyledFormGroup>
            <StyledFormGroup>
              <FieldWrapper>
                <label htmlFor="password">{t('enterPasswordImportant')}</label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                />
                <ErrorMessage name="password" component="div" className="invalid-feedback" />
              </FieldWrapper>
            </StyledFormGroup>
            {error?.message && (
              <StyledAlert className="danger" addMargin>
                {error?.message}
              </StyledAlert>
            )}
            <StyledSubmitButtonWrapper>
              <SignUpButton type="submit" disabled={loading}>
                {loading && (
                  <LoadingDots>
                    <div />
                    <div />
                    <div />
                    <div />
                  </LoadingDots>
                )}
                <span style={{ opacity: loading ? 0 : 1 }}>{t('login')}</span>
              </SignUpButton>
            </StyledSubmitButtonWrapper>
            {!teamsContext && (
              <>
                <StyledHr />

                <MainSignUp>
                  {window.location.host !== 'gympass.supportroom.com' && (
                    <GoogleInitComponent buttonText={t('loginWithGoogle')} initAuth={socialSignUp} />
                  )}
                  {/* <FacebookInitComponent buttonText="Login with Facebook" initAuth={socialSignUp} /> */}
                </MainSignUp>
              </>
            )}
            Don't have an account? <Link to="/">Create New </Link>
            <br />
            <ForgotPasswordWrapper>
              <p>{t('forgotYourPassword')}</p>
              <Link to={`/forgotten-password/${role}`}>{t('clickHere')}</Link>
              {/*<span style={{ fontWeight: 600 }}>Click Here</span>*/}
            </ForgotPasswordWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

const StyledAlert = styled(Alert)`
  border-color: #d2d2d2 !important;
  background: #ffffff !important;
  display: inline-flex;
  border-radius: 2px;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const ForgotPasswordWrapper = styled.div`
  color: black;
  margin-top: 20px;

  p {
    margin-bottom: 0;
  }

  a {
    color: black;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledHr = styled.hr`
  width: 100%;
  max-width: 336px;
  min-width: 260px;
  border-color: #e8e8e8;
  margin: 30px 0;
  border-style: solid;
`;

export const StyledSubmitButtonWrapper = styled(SubmitButtonWrapper)`
  width: 100%;
  display: inline-block;
  margin-top: 0;
`;

export default memo(LoginComponent);
