import supportroomNameLogo from '../Assets/support-room-logo-light-final.png'
import supportroomIconLogo from '../Assets/supportroom-logo.svg'
import nixyNameLogo from '../Assets/nixy_sidebar-2.png'

import srTC from '../Assets/terms_conditions.docx';
import nixyTC from '../Assets/nixy_terms_conditions.docx';

const hostname = window.location.hostname?.toLowerCase();

const supportroomConfig = {
    hostname: hostname,
    nameLogo: supportroomNameLogo,
    iconLogo: supportroomIconLogo,
    terms: srTC,
    sourcecompany:'supportroom'
}

const nixyConfig = {
    hostname: hostname,
    nameLogo: nixyNameLogo,
    iconLogo: nixyNameLogo,
    terms: nixyTC,
    sourcecompany:'nixy'
}

export const isNixy = hostname.includes('nixy')

const config = isNixy
    ? nixyConfig
    : supportroomConfig

export default config;