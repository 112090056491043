import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Heading } from '../../../Components/Atoms';
import api from '../../../Util/api';
import { useTranslation } from 'react-i18next';

const ManageEmployees = (props) => {
  const { t } = useTranslation();
  let employeeList = [];

  const getEmployees = () => {
    api.company.getEmployeesList();
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const handleViewClick = () => {
    console.log('View Clicked');
  };
  const handleEditClick = () => {
    console.log('Edit Clicked');
  };
  const handleDeleteClick = () => {
    console.log('Delete Clicked');
  };

  props.data.map((e) => {
    employeeList.push(
      <tr key={e.id}>
        <td>{e.name}</td>
        <td>{e.therapyType}</td>
        <td>
          <ButtonWrapper>
            <Button disabled variant="link small" onClick={() => handleViewClick()}>
            {t("view")}
            </Button>
            <Button disabled variant="link small" onClick={() => handleEditClick()}>
            {t("edit")}
            </Button>
            <Button disabled variant="link small" onClick={() => handleDeleteClick()}>
            {t("delete")}
            </Button>
          </ButtonWrapper>
        </td>
      </tr>,
    );
    return employeeList;
  });
  return (
    <>
      <Heading level={2}>{t("manageEmployees")}</Heading>
      <table>
        <thead>
          <tr>
            <th>{t("Name")}</th>
            <th>{t("therapyType")}</th>
            <th>{t("actions")}</th>
          </tr>
        </thead>
        <tbody>{employeeList}</tbody>
      </table>
    </>
  );
};

const ButtonWrapper = styled.div`
  button {
    margin: 0 10px;
  }
`;

export default ManageEmployees;
