import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import Popup from 'reactjs-popup';
import { Heading, Tooltip } from '../../../src/Components/Atoms';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { cancelAppointments, getFullScheduleList } from '../../../src/Actions/chatroom.actions';
import { useWindowSize } from '../../../src/Hooks';
import { getSubscriptionStatus } from '../../../src/Actions/patient.actions';
import { useParams } from 'react-router';
import { scheduleAppointment } from '../../Actions/therapist.actions';

export const isTimeGreaterThanNow = (localScheduleDateTime) => {
  const diff = moment?.duration(moment(localScheduleDateTime).diff(moment()))?.asMinutes()?.toFixed(0);
  return Number(diff) + 31 > 0;
};

const TherapistAvailability = ({
  heading,
  slots,
  isAllowedForBooking,
  therapistProfile,
  canBookAfterCancel,
  bookedAppointmentID,
  onBookSuccess,
}) => {
  const { t } = useTranslation();
  const windowWidth = useWindowSize().width;
  const [showSuccessBook, setShowSuccessBook] = useState(false);
  const [nextAvailable, setNextAvailable] = useState('');

  const dispatch = useDispatch();
  const isB2b = useSelector((store) => store.user?.profile?.['B2B'] !== 0);
  const { chatRoomId, patientId } = useParams();

  const bookEvent = useCallback(
    (slotId, data) =>
      dispatch(
        scheduleAppointment({ chatroom_id: chatRoomId, slot_id: slotId, appointment_id: bookedAppointmentID }),
      ).then(() => {
        // onBookSuccess();
      }),
    [chatRoomId, dispatch],
  );

  const slotOnClick = async (e, data) => {
    await bookEvent(e.target.getAttribute('name'), data);
    setShowSuccessBook(true);
    window.location.reload();
  };

  return (
    <div className="availability-component">
      <div style={{ position: 'relative' }}></div>
      {heading && <AvailabilityHeading level={4}>{heading}:</AvailabilityHeading>}
      <AvailabilityWrapper>
        <AvailabilityViewport style={{ width: `${slots.length * 90}px` }}>
          {slots.map((date) => {
            const isDateHavingAppointments = date.slots.findIndex(
              (slot) => slot.status === 'booked' && slot.appointment_type === 'video' && slot.patient_id === patientId,
            );
            return (
              <Popup
                modal={windowWidth < 768}
                key={date.schedule_date}
                className="availability-component"
                trigger={
                  <Slot isDateHavingAppointments={isDateHavingAppointments > -1}>
                    {moment(date.schedule_date).format('Do MMM')}
                  </Slot>
                }
                position="right center"
                closeOnDocumentClick={!showSuccessBook}
              >
                {(close) => (
                  <PopupWrapper>
                    {windowWidth < 768 && <PopupClose onClick={close}>&times;</PopupClose>}
                    <Heading level={5} align="center">
                      {t('selectTimeSlot')}
                    </Heading>
                    <HoursWrapper>
                      {date.slots.map((slot) => {
                        console.log('dxssfc', slot);
                        let timeSlot = (
                          <SlotWrapper key={slot.schedule_id}>
                            {/* {slot.status === 'booked' &&
                              patientId !== slot.patient_id &&
                              slot.appointment_type !== 'message' && (
                                <RemoveSchedule onClick={() => removeSchedule({ slot, date })}>x</RemoveSchedule>
                              )} */}
                            {isTimeGreaterThanNow(slot?.schedule_time) && slot.status !== 'booked' && (
                              <TimeSlot
                                allowedToBok
                                // allowedToBok={
                                //   canBookAfterCancel ? false : !isAllowedForBooking && slot.status !== 'booked'
                                // }
                                // booked={slot.status === 'booked' && patientId === slot.patient_id}
                                isVideo={slot.appointment_type === 'video'}
                                name={slot.schedule_id}
                                onClick={(e) => slotOnClick(e, { slot, date })}
                              >
                                {moment(slot.schedule_time).format('HH:mm')}
                              </TimeSlot>
                            )}
                          </SlotWrapper>
                        );

                        return canBookAfterCancel ? (
                          <Tooltip
                            id="slotBookingDisabled"
                            text={`${t('canBookAfterCancelTooltip1')} ${
                              isB2b ? t('you') : t('canBookAfterCancelTooltip3')
                            } ${t('canBookAfterCancelTooltip4')}`}
                            place="top"
                          >
                            {timeSlot}
                          </Tooltip>
                        ) : (
                          timeSlot
                        );
                      })}
                    </HoursWrapper>
                    {/* <HistoryWrapper>
                      <div style={{ display: 'flex', marginRight: 10 }}>
                        <Dot isVideo={true} />
                        <span>{t('videoAppointment')}</span>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <Dot isVideo={false} />
                        <span>{t('messageAppointment')}</span>
                      </div>
                    </HistoryWrapper> */}
                  </PopupWrapper>
                )}
              </Popup>
            );
          })}
        </AvailabilityViewport>
      </AvailabilityWrapper>
      {showSuccessBook && (
        <Popup open closeOnDocumentClick={false}>
          <PopupClose onClick={() => setShowSuccessBook(false)}>&times;</PopupClose>
          <Heading level={3}>Your appointment has been rescheduled successfully!</Heading>
        </Popup>
      )}
    </div>
  );
};

const AvailabilityWrapper = styled.div`
  overflow-x: auto;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
    background: #bfbfbf;
  }
`;
const AvailabilityViewport = styled.div`
  overflow: hidden;
`;
const AvailabilityHeading = styled(Heading)`
  display: none;

  ${({ theme }) => theme.md`
    display: block;
  `};
`;
const Slot = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 80px;
  height: 25px;
  color: white;
  background: #1a5549;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #00c8a0;
  }

  ${({ isDateHavingAppointments }) =>
    isDateHavingAppointments &&
    css`
      background: #8fcf7f;

      &:hover {
        background: #67a059;
      }
    `}
`;

const PopupWrapper = styled.div``;

const PopupClose = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  box-shadow: 0 2px 7px 1px #8c8c8c;
  cursor: pointer;

  &:hover {
    background: #e7e7e7;
  }
`;

const HoursWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TimeSlot = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 50px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a5549;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #00c8a0;
  }

  ${({ booked, isVideo }) =>
    booked && isVideo
      ? css`
          opacity: 0.8;
          pointer-events: none;
          background: #8fcf7f !important;
          color: black;
        `
      : booked &&
        !isVideo &&
        css`
          opacity: 0.8;
          pointer-events: none;
          background: #068ec4 !important;
          color: black;
        `}

  ${({ allowedToBok }) =>
    !allowedToBok &&
    css`
      background: #cbc3c3;
      pointer-events: none;
    `}
`;

const SlotWrapper = styled.div`
  position: relative;
`;

const RemoveSchedule = styled.div`
  position: absolute;
  right: 4px;
  top: -5px;
  background: ${({ theme }) => theme.statusDanger};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  color: white;
`;

const HistoryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 10px;
  }
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ isVideo }) => (isVideo ? '#8fcf7f' : '#068ec4')};
  border-radius: 50%;
  margin-right: 10px;
`;

const mapStateToProps = (store) => ({
  therapistProfile: store.user.therapistProfile,
});

export default connect(mapStateToProps)(TherapistAvailability);
