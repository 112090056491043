import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button, Heading, ModalComponent, Widget } from '../../Components/Atoms';
import Loading from '../../Components/Atoms/Loading/Loading';
import PersonalInfo from './Components/PersonalInfo';
import Expertise from './Components/Expertise';
import Qualifications from './Components/Qualifications';
import BankDetails from './Components/BankDetails';
import { ChangePasswordForm } from '../../Components/Organisms';
import ProfessionalType from './Components/ProfessionalType';

const Edit = () => {
  const profile = useSelector((store) => store.user.profile);
  const isPasswordExpired = useSelector((store) => store.auth?.password_expired);
  const isTherapist = useSelector((store) => store.auth?.role) === 'therapist';

  const [modalOpenFlag, setModalOpenFlag] = useState(isPasswordExpired);

  return (
    <>
      {modalOpenFlag && (
        <ModalComponent open={modalOpenFlag} closeModal={() => setModalOpenFlag(false)}>
          <div style={{ textAlign: 'center' }}>
            <Heading level={4}>Your password has expired. Please change the password and login again</Heading>
            <ButtonsWrapper>
              <Button inline className="small warning" onClick={() => setModalOpenFlag(false)}>
                OK
              </Button>
            </ButtonsWrapper>
          </div>
        </ModalComponent>
      )}
      <Heading text="Edit Account Details" />
      {!profile ? (
        <Loading />
      ) : (
        <Widget>
          <Tabs defaultIndex={isTherapist && isPasswordExpired ? 4 : 0}>
            <TabList>
              <Tab>Personal Info</Tab>
              <Tab>Expertise</Tab>
              <Tab>Qualifications</Tab>
              <Tab>Bank Details</Tab>
              <Tab>Change Password</Tab>
              <Tab>Professional Type</Tab>
            </TabList>
            <TabPanel>
              <PersonalInfo profile={profile} />
            </TabPanel>
            <TabPanel>
              <Expertise />
            </TabPanel>
            <TabPanel>
              <Qualifications profile={profile} />
            </TabPanel>
            <TabPanel>
              <BankDetails />
            </TabPanel>
            <TabPanel>
              <ChangePasswordForm />
            </TabPanel>
            <TabPanel>
              <ProfessionalType />
            </TabPanel>
          </Tabs>
        </Widget>
      )}
    </>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing} 0;

  button.warning {
    margin: 0 ${({ theme }) => theme.spacing};
    padding: 8px 12px;
  }
`;

export default Edit;
