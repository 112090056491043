import * as React from "react";
const SmallCross = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 23.4375C6.45937 23.4375 1.5625 18.5391 1.5625 12.5C1.5625 6.46094 6.45937 1.5625 12.5 1.5625C18.5406 1.5625 23.4375 6.46094 23.4375 12.5C23.4375 18.5391 18.5406 23.4375 12.5 23.4375ZM12.5 0C5.59609 0 0 5.59375 0 12.5C0 19.4062 5.59609 25 12.5 25C19.4039 25 25 19.4062 25 12.5C25 5.59375 19.4039 0 12.5 0ZM16.9664 8.03127C16.6586 7.72659 16.1609 7.72659 15.8531 8.03127L12.4953 11.3906L9.18593 8.0781C8.88047 7.77341 8.38515 7.77341 8.08125 8.0781C7.77578 8.38279 7.77578 8.88282 8.08125 9.18751L11.3906 12.4922L8.05783 15.8281C7.7508 16.1328 7.7508 16.6328 8.05783 16.9453C8.36565 17.25 8.86405 17.25 9.17187 16.9453L12.5047 13.6094L15.8141 16.9219C16.1195 17.2266 16.6148 17.2266 16.9195 16.9219C17.225 16.6172 17.225 16.1172 16.9195 15.8125L13.6094 12.5078L16.9664 9.14841C17.2734 8.83591 17.2734 8.34377 16.9664 8.03127Z"
      fill="#442165"
    />
  </svg>
);
export default SmallCross;
