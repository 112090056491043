import {
  Login,
  Logout,
  Signup,
  SignupConfirm,
  SignupStep2,
  SignupStep3,
  Dashboard,
  EmployeeSignup,
  Account,
} from '../Company';

export default [
  { path: '/c/login', component: Login },
  { path: '/c/logout', component: Logout },
  { path: '/c/signup/complete', component: SignupConfirm },
  { path: '/c/signup', component: Signup },
  { path: '/c/signup/step-2', component: SignupStep2, isPrivate: true },
  { path: '/c/signup/step-3', component: SignupStep3, isPrivate: true },
  { path: '/c/employee-signup', component: EmployeeSignup },
  { path: '/c/dashboard/:id*', component: Dashboard, isPrivate: true },
  { path: '/c/dashboard', component: Dashboard, isPrivate: true },
  { path: '/c/account', component: Account, isPrivate: true },
  { path: '/c/account/:id*', component: Account, isPrivate: true },
];
