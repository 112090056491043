import React from 'react';
import styled from 'styled-components';
import { DefaultLayout } from '../../Layout';
import { Heading } from '../../Components/Atoms';
import Trash from '../../Assets/Icons/Trash.svg';
import { useTranslation } from 'react-i18next';

const SavedMessages = () => {
  const { t } = useTranslation();
  return (
    <>
      <DefaultLayout>
        <Wrapper>
          <Heading>{t("savedMessages")}</Heading>
          <table>
            <thead>
              <tr>
                <th>{t("date")}</th>
                <th>{t("message1")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("20062020")}</td>
                <td>{t("savedMessage")}</td>
                <td>
                  <DeleteButton onClick={() => console.log('Delete')}>
                    <img src={Trash} alt="" />
                  </DeleteButton>
                </td>
              </tr>
            </tbody>
          </table>
        </Wrapper>
      </DefaultLayout>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  margin: auto;
`;

const DeleteButton = styled.button`
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
`;

export default SavedMessages;
