import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getNotes } from '../../Actions/chatroom.actions';
import { Heading, Widget } from '../../Components/Atoms';
import { DashboardLayout } from '../../Layout';
import Intake from '../TherapyJourney/Intake';
import { useHistory } from 'react-router';

const Note = () => {
  const { chatRoomId } = useParams();

  const notes = useSelector((store) => store.chatroom.notes);
  const history = useHistory();

  const dispatch = useDispatch();

  const goToNote = (noteId) => history.push(`/t/note/${chatRoomId}/${noteId}`);

  useEffect(() => {
    dispatch(getNotes(chatRoomId));
  }, [dispatch, chatRoomId]);

  return (
    <DashboardLayout>
      <Heading>Patient Notes</Heading>
      <StyledWidget>{<Intake notes={notes} goToNote={goToNote} />}</StyledWidget>
    </DashboardLayout>
  );
};

const StyledWidget = styled(Widget)`
  margin-bottom: ${({ theme }) => theme.spacingMd};
`;

export default memo(Note);
