import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { switchTherapist } from '../../../Actions/chatroom.actions';

import { Button, Heading } from '../../../Components/Atoms';
import ModalComponent from '../../../Components/Atoms/Modal/Modal';
import Tooltip from '../../../Components/Atoms/Tooltip/Tooltip';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

// const exceedsswitchTherapist = false;

const ChangeTherapist = ({ chatroomID, exceedsswitchTherapist, closeMenu = () => null, isSideBar }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [exceedSwitchPopup, setExceedSwitchPopup] = useState(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const dispatch = useDispatch();

  const toggleModal = () => {
    if (exceedsswitchTherapist) {
      return setExceedSwitchPopup(true);
    }
    setIsOpen(!isOpen);
    closeMenu();
  };

  const handleSwitch = async () => {
    setSubmitDisabled(true);

    try {
      await dispatch(switchTherapist({ chatroom_id: chatroomID })).then(() =>
        history.push('/welcome/therapists?switch=true'),
      );
    } catch (err) {
      console.error(err);
      // } finally {
      //   toggleModal();
    }
  };

  return (
    <>
      {!isSideBar && (
        <Tooltip id="changeTherapist" text={t('changeProfessionalText')} place="top">
          <StyledButton onClick={toggleModal}>{t('changeProfessional')}</StyledButton>
        </Tooltip>
      )}

      {isSideBar && <StyledLink onClick={toggleModal}>{t('changeProfessional')}</StyledLink>}
      {isOpen && (
        <ModalComponent open closeModal={toggleModal}>
          <Heading level={3}>{t('changeProfessional')}</Heading>
          <p>{t('changeProfessionalModalText')}</p>
          <ModalActions>
            <Button disabled={isSubmitDisabled} onClick={handleSwitch}>
              {t('yes')}
            </Button>
          </ModalActions>
        </ModalComponent>
      )}
      {exceedSwitchPopup && (
        <ModalComponent open closeModal={() => setExceedSwitchPopup(false)}>
          <Heading level={3}>{t('changeProfessionalExceededText')}</Heading>
        </ModalComponent>
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.primary};
  font-weight: ${({ theme }) => theme.fontMedium};
  background-color: ${({ theme }) => theme.primaryLight};
`;
StyledButton.displayName = 'StyledButton';

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
ModalActions.displayName = 'ModalActions';

ChangeTherapist.propTypes = {
  chatroomID: PropTypes.number.isRequired,
};

const StyledLink = styled.p`
  display: block;
  font-size: 17px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  text-decoration: none;
`;

StyledLink.displayName = 'StyledLink';

export default ChangeTherapist;
