import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import DailyIframe from '@daily-co/daily-js';

import { destroyVideoRoom, leaveVideoRoom, reduceVideoSession } from '../../../Actions/chatroom.actions';

import config from './config.js';
import moment from 'moment';

const Room = ({ videoRoom: { name } }) => {

  let callFrame
  let callStartTime;

  const dispatch = useDispatch();
  const handleLeaveChat = useCallback(() => {
    dispatch(leaveVideoRoom());
    dispatch(destroyVideoRoom(name.slice(3)));

  }, [dispatch]);

  const onEndCall = (startTime) => {
    let diff = moment().diff(moment(startTime), 'minutes')
    let sessions = diff / 30;
    const payload = {
      chatroom_id: +name.slice(3),
      sessions: +sessions.toFixed(2)
    }
    dispatch(reduceVideoSession(payload))
  };


  const createFrameAndJoinRoom = useCallback(() => {
    callFrame = DailyIframe.createFrame(document.getElementById('frame'), {
      showLeaveButton: true,
      showFullscreenButton: true,
      iframeStyle: {
        position: 'absolute',
        border: '0',
        width: '100%',
        height: '56.25%', // 16:9 ratio
        top: '0',
        left: '0',
        padding: '1em',
      },
    });

    console.log('Joining ' + name);
    console.log('https://' + config.DAILY_SUBDOMAIN + '.daily.co/' + name);
    callStartTime = new Date();

    callFrame.join({
      url: 'https://' + config.DAILY_SUBDOMAIN + '.daily.co/' + name,
    });
    callFrame.on('left-meeting', () => {
      onEndCall(callStartTime);
      handleLeaveChat();
    });
  }, [name, handleLeaveChat]);


  useEffect(() => {
    name && createFrameAndJoinRoom();
  }, [name, createFrameAndJoinRoom]);

  useEffect(() => {

    window.addEventListener('beforeunload', onReload, true);
    return () => {
      window.removeEventListener("beforeunload", onReload, true)
    }
  }, []);

  const onReload = useCallback((event) => {
    var e = event || window.event;
    if (e) {
      e.returnValue = "Leave call";
    }
    if (callFrame?._participants?.local?.joined_at) {
      onEndCall(callFrame?._participants?.local?.joined_at);
    }
  }, [])

  return <div id="frame" />;
};

const mapStateToProps = (store) => ({
  chatRoomID: store.chatroom.chatRoomID,
  videoRoom: store.chatroom.videoRoom,
});

export default connect(mapStateToProps)(Room);
