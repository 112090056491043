import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { TherapistMenuLinks, TherapistDashboardLinks, NixyTherapistDashboardLinks } from '../../../Data/Menus';
import { useOnClickOutside } from '../../../Hooks';
import { useTranslation } from 'react-i18next';
import { Button, Container, Heading, ModalComponent, NotificationIndicator } from '../../../Components/Atoms';
import { TherapistMenu, DashboardNav } from '../../../Components/Molecules';
import TherapistMiniProfile from './TherapistMiniProfile';

import { ReactComponent as MessagesIcon } from '../../../Assets/Icons/Messages.svg';
import { ReactComponent as NotificationIcon } from '../../../Assets/Icons/Notification.svg';
import Tooltip from '../../../Components/Atoms/Tooltip/Tooltip';
import config, { isNixy } from '../../../Config';

const Toolbar = ({ unreadMessages, viewportWidth, notifications, profile }) => {
  const [showProfileActions, setShowProfileActions] = useState(false);
  const [signOutState, setSignOutState] = useState(false);
  const profileRef = useRef();
  const history = useHistory();
  const { t } = useTranslation();
  const handleProfileClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.currentTarget.classList.toggle('active');
      setShowProfileActions(!showProfileActions);
    },
    [showProfileActions],
  );

  useOnClickOutside(profileRef, () => {
    setShowProfileActions(false);
    profileRef.current.classList.remove('active');
  });

  const closeSignOutModal = () => setSignOutState(false);
  const openModal = () => {
    setSignOutState(true);
  };

  const signOut = () => {
    closeSignOutModal();
    history.push('/t/logout');
  };

  const goToEditProfilePage = () => history.push('/t/account/edit');

  return (
    <>
      {signOutState && (
        <ModalComponent open={signOutState} closeModal={closeSignOutModal}>
          <div style={{ textAlign: 'center' }}>
            {t('signOutheading')}
            <Heading level={3}></Heading>
            <ButtonsWrapper>
              <Button inline className="small success" onClick={signOut}>
                {t('signOut')}
              </Button>
              <Button inline className="small warning" onClick={closeSignOutModal}>
                {t('cancel')}
              </Button>
            </ButtonsWrapper>
          </div>
        </ModalComponent>
      )}

      {viewportWidth > 1170 ? (
        <DashboardToolbar>
          <DashboardNavHeader>
            <LogoLink to="/t/dashboard">
              <img src={config.nameLogo} alt="" height={36} />
            </LogoLink>
          </DashboardNavHeader>

          {profile?.profile_status && profile?.profile_status !== 'Approved' && (
            <ToolbarItem>
              <Incomplete onClick={goToEditProfilePage}>{t('profileIncomplete')}</Incomplete>
            </ToolbarItem>
          )}

          <ToolbarItem>
            <Tooltip id="Messages" text="Patient messages" place="bottom">
              <StyledButtonLink to="/t/dashboard/patients">
                <MessagesIcon />
                <NotificationIndicator count={unreadMessages?.unread_message_count || 0} />
              </StyledButtonLink>
            </Tooltip>
          </ToolbarItem>

          <ToolbarItem>
            <Tooltip id="notifications" text="View your notifications" place="bottom">
              <StyledButtonLink to="/t/dashboard/notifications">
                <NotificationIcon />
                <NotificationIndicator count={notifications?.total_unread_count} />
              </StyledButtonLink>
            </Tooltip>
          </ToolbarItem>

          {profile && (
            <Tooltip id="statusProfile" text="Turn your status on or off" place="bottom">
              <ToolbarItem className="profile" ref={profileRef} onClick={(e) => handleProfileClick(e)}>
                <TherapistMiniProfile {...profile} />
                {showProfileActions && (
                  <ActionsPanel>
                    <ul>
                      {TherapistMenuLinks(t).map((item) => (
                        <li key={item.id}>
                          <Link to={item.url}>{item.title}</Link>
                        </li>
                      ))}
                      <li>
                        <SignOut onClick={openModal}>{t('signOut')}</SignOut>
                      </li>
                    </ul>
                  </ActionsPanel>
                )}
              </ToolbarItem>
            </Tooltip>
          )}
        </DashboardToolbar>
      ) : (
        <StyledHeader role="banner">
          <Container>
            <HeaderContent>
                <DashboardNav links={isNixy ? NixyTherapistDashboardLinks(t) : TherapistDashboardLinks(t)} />
              <AltLogoLink to="/t/dashboard">
                <img src={config.nameLogo} alt="" height={36} />
              </AltLogoLink>
              {profile && <TherapistMenu {...profile} />}
            </HeaderContent>
          </Container>
        </StyledHeader>
      )}
    </>
  );
};

const DashboardToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
  min-height: 80px;
  border-bottom: 1px solid #ebebeb;
`;

const DashboardNavHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing};
  border-bottom: 1px solid #96fbe7;
  background-color: ${({ theme }) => theme.primary};
  width: 320px;
  height: 80px;
  margin-right: auto;
`;
const LogoLink = styled(Link)`
  display: block;
  width: 180px;
  line-height: 1;
`;

const ToolbarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => theme.spacing} ${({ theme }) => theme.spacingLg};
  border-left: 1px solid #ebebeb;
  position: relative;

  &.active {
    background-color: ${({ theme }) => theme.primaryLight};
  }

  &.profile {
    padding-right: 70px;
    cursor: pointer;

    &.active::after {
      right: ${({ theme }) => theme.spacingLg};
      transform: rotate(-135deg) translate(45%);
    }

    &::after {
      width: 12px;
      height: 12px;
      right: 28px;
      top: 50%;
      display: block;
      content: '';
      position: absolute;
      border-style: solid;
      border-color: ${({ theme }) => theme.primary};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) translate(-100%);
    }
  }
`;

const StyledButtonLink = styled(Link)`
  border: 0;
  background: none;
  padding: ${({ theme }) => theme.spacingSm};
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.primaryMedium};
  }
`;
StyledButtonLink.displayName = 'StyledButtonLink';

const ActionsPanel = styled.div`
  position: absolute;
  background: white;
  top: 79px;
  right: -1px;
  padding: ${({ theme }) => theme.spacing};
  width: calc(100% + 2px);
  border: 1px solid #ebebeb;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 4px 27px rgba(73, 31, 105, 0.14);
  z-index: 1000;

  li {
    margin: ${({ theme }) => theme.spacingSm} 0;
  }
  a {
    font-weight: ${({ theme }) => theme.fontBold};
    font-size: 17px;
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }
`;

const StyledHeader = styled.header`
  height: ${({ theme }) => theme.headerHeight};
`;
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;
const AltLogoLink = styled(Link)`
  width: 160px;
  ${({ theme }) => theme.md`
    width: 220px;
  `}
`;

const SignOut = styled.div`
  font-weight: 700;
  font-size: 17px;
  color: #1a5549;
  text-decoration: none;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing} 0;

  button {
    margin: 0 ${({ theme }) => theme.spacing};
  }
`;

const Incomplete = styled.div`
  padding: 5px;
  background: #f3a84a;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #cf8f3f;
  }
`;

const mapStateToProps = (store) => ({
  notifications: store.user.notifications,
  unreadMessages: store.chatroom.unreadMessages,
  profile: store.user.profile,
});

export default connect(mapStateToProps)(Toolbar);
