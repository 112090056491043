import * as React from "react";
const TooltipArrow = (props) => (
  <svg
    width={17}
    height={22}
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.33573 9.83638C0.642017 10.3132 0.642019 11.3374 1.33573 11.8143L14.3203 20.7388C15.1165 21.2861 16.2 20.716 16.2 19.7499V10.8253V1.90075C16.2 0.934601 15.1165 0.364565 14.3203 0.911819L1.33573 9.83638Z"
      fill="#FAF3FF"
      stroke="#A4A4A4"
      strokeWidth={0.4}
    />
  </svg>
);
export default TooltipArrow;
