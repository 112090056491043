import React, { useState, memo } from 'react';
import styled from 'styled-components';
// import Popup from 'reactjs-popup';

import UploadFile from './UploadFile';

import { ReactComponent as Microphone } from '../../../Assets/Icons/Microphone.svg';
import { ReactComponent as ThreeBlackDots } from '../../../Assets/Icons/ThreeBlackDots.svg';
import RecordAudio from './RecordAudio';

import { Popover } from 'antd';
import '../../../Styles/antd-animation.css';
import VdoRecording from '../../VdoRecording/VdoRecording';

const ChatToolMobilePopup = ({ chatRoomID, pageLimit }) => {
  const [recordMode, setRecordMode] = useState(false);
  const [opUpState, setPopUpState] = useState(false);

  const onRecordMode = () => {
    setRecordMode(true);
  };

  const closeAll = () => {
    setRecordMode(false);
    setPopUpState(false);
  };

  return (
    <DictateButton className="tooltipBoundary">
      <Popover
        visible={opUpState}
        content={
          <>
            {recordMode && <CloseButton onClick={closeAll}>X</CloseButton>}
            {!recordMode && (
              <ToolsWrapper>
                <CloseButton onClick={() => setPopUpState(false)}>X</CloseButton>
                <UploadFile chatRoomID={chatRoomID} pageLimit={pageLimit} />
                <StyledMicrophone onClick={onRecordMode} />
                <VdoRecording  style={{marginBottom: "10px"}} chatRoomID={chatRoomID} pageLimit={pageLimit} />
              </ToolsWrapper>
            )}
            {recordMode && <RecordAudio isDesktop={false} chatRoomID={chatRoomID} pageLimit={pageLimit} />}
          </>
        }
        title="Attach"
        trigger="click"
        placement="topLeft"
        onVisibleChange={(e) => e && setPopUpState(e)}
      >
        <ThreeBlackDots />
      </Popover>
      {/*<StyledPopup*/}
      {/*  open={opUpState}*/}
      {/*  closeOnDocumentClick={false}*/}
      {/*  trigger={<ThreeBlackDots />}*/}
      {/*  position="top left"*/}
      {/*  recordMode={recordMode}*/}
      {/*  onOpen={() => setPopUpState(true)}*/}
      {/*>*/}
      {/*  {recordMode && <CloseButton onClick={() => setRecordMode(false)}>X</CloseButton>}*/}
      {/*  {!recordMode && (*/}
      {/*    <ToolsWrapper>*/}
      {/*      <CloseButton onClick={() => setPopUpState(false)}>X</CloseButton>*/}
      {/*      <UploadFile chatRoomID={chatRoomID} pageLimit={pageLimit} />*/}
      {/*      <StyledMicrophone onClick={onRecordMode} />*/}
      {/*    </ToolsWrapper>*/}
      {/*  )}*/}
      {/*  {recordMode && <RecordAudio isDesktop={false} chatRoomID={chatRoomID} pageLimit={pageLimit.current} />}*/}
      {/*</StyledPopup>*/}
    </DictateButton>
  );
};

const ToolsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
`;

const StyledMicrophone = styled(Microphone)`
  margin-bottom: 10px;
  margin-right: 20px;
`;

const DictateButton = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.primary};
    height: 50px;
  }
`;

// const StyledPopup = styled(Popup)`
//   &-content {
//     width: 140px !important;
//     padding: 15px 30px 15px 15px !important;
//
//     ${({ recordMode }) =>
//       recordMode &&
//       css`
//         width: 280px !important;
//       `}
//   }
// `;

export default memo(ChatToolMobilePopup);
