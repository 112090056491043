import React from "react";

const Star = (props) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      >
      <path
        fill={props.on ? "#FFC107" : "#BEBEBE"}
        d="M20.9203 10.04L21.0337 10.2814L21.2974 10.3216L30.8887 11.7842C30.889 11.7842 30.8892 11.7842 30.8894 11.7843C31.2639 11.8431 31.5555 12.2189 31.4912 12.6564L31.4911 12.6564L31.4904 12.6617C31.467 12.8336 31.3902 12.9884 31.2765 13.1055C31.2764 13.1056 31.2762 13.1058 31.276 13.106L24.3363 20.205L24.1596 20.3857L24.2004 20.6352L25.8401 30.6607L25.8402 30.6611C25.9125 31.101 25.6281 31.4839 25.2585 31.5508L25.2577 31.5509C25.11 31.5779 24.956 31.553 24.8243 31.4794L24.822 31.4782L16.2416 26.7449L16.0001 26.6117L15.7586 26.7449L7.17816 31.4782L7.17737 31.4786C7.06217 31.5424 6.93422 31.5702 6.80749 31.5607C6.68069 31.5512 6.55666 31.5045 6.44972 31.423L6.14666 31.8207L6.44971 31.423C6.34251 31.3413 6.25628 31.2274 6.20387 31.0919C6.15141 30.9564 6.13589 30.807 6.1599 30.6614L6.16 30.6607L7.79977 20.6352L7.84057 20.3857L7.66386 20.205L0.723217 13.1051L0.723185 13.105C0.585575 12.9643 0.502739 12.767 0.500067 12.5553C0.497398 12.3439 0.574986 12.1444 0.708967 11.9995C0.820535 11.8803 0.960949 11.8067 1.10854 11.7846L1.1098 11.7844L10.7028 10.3216L10.9664 10.2814L11.0799 10.04L15.3671 0.920743C15.3673 0.920375 15.3674 0.920008 15.3676 0.919641C15.5518 0.534584 15.9752 0.403143 16.3066 0.574811L16.3072 0.575116C16.4442 0.645822 16.5599 0.764838 16.6317 0.917949L16.6319 0.918373L20.9203 10.04Z"
        stroke="white"
      />
    </svg>
  );
};

export default Star;
