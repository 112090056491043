import React, { useEffect, memo, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { getNotificationSettings, updateNotificationSettings } from '../../Actions/patient.actions';

import { Heading } from '../../Components/Atoms';
import Switch from '../../Components/Atoms/Form/Switch';
import Email from '../../Assets/Icons/NotificationsEmail.svg';
import Push from '../../Assets/Icons/NotificationsPush.svg';
import Whatsapp from '../../Assets/Icons/whatsapp.svg';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const { t } = useTranslation();

  const notifications = useSelector((store) => store.user.notificationSettings);

  const dispatch = useDispatch();

  const handleToggle = useCallback(
    (id, type) => {
      const notificationIndex = notifications.findIndex((not) => not.setting_id === id);
      const notification = notifications[notificationIndex];
      const status = notification[type] === 1 ? 0 : 1;

      dispatch(updateNotificationSettings(id, status, type));
    },
    [notifications, dispatch],
  );

  useEffect(() => {
    dispatch(getNotificationSettings());
  }, [dispatch]);

  const settings = useMemo(() => {
    return (
      notifications &&
      notifications.map((notification) => {
        return (
          <NotificationGroup key={notification.setting_id}>
            <h4>{notification.name[0].toUpperCase() + notification.name.slice(1)}</h4>
            <Row>
              <Icon>
                <img src={Email} alt="" />
              </Icon>
              <Type>{t("emailNotifications")}</Type>
              <Switch isOn={notification.email} handleToggle={() => handleToggle(notification.setting_id, 'email')} />
            </Row>
            <Row>
              <Icon>
                <img src={Push} alt="" />
              </Icon>
              <Type>{t("smsNotifications")}</Type>
              <Switch isOn={notification.sms} handleToggle={() => handleToggle(notification.setting_id, 'sms')} />
            </Row>
            <Row>
              <Icon>
                <img src={Whatsapp} alt="" />
              </Icon>
              <Type>{t("whatsapp")}</Type>
              <Switch
                isOn={notification.whatsapp}
                handleToggle={() => handleToggle(notification.setting_id, 'whatsapp')}
              />
            </Row>
          </NotificationGroup>
        );
      })
    );
  }, [notifications, handleToggle]);

  return (
    <>
      <Heading text={t("notifications")} />
      {settings}
    </>
  );
};

const NotificationGroup = styled.div`
  border: 1px solid ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.spacing};
  margin-bottom: ${({ theme }) => theme.spacing};
  border-radius: 8px;

  max-width: 450px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacingSm};
`;

const Icon = styled.div`
  width: 30px;
  img {
    width: 20px;
    height: 20px;
  }
`;

const Type = styled.div`
  width: 60%;
`;

export default memo(Notifications);
