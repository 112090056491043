import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { launchVideoRoom, getScheduleList } from '../../../Actions/chatroom.actions';
import { makeAppointment } from '../../../Actions/patient.actions';

import { TherapyTools } from '../../../Components/Organisms';
import { Profile, Availability } from '../Components';
import { Button, ModalComponent } from '../../../Components/Atoms';
import ChangeTherapist from '../../Account/Components/ChangeTherapist';
import SubscriptionBadge from './subscriptionBadge';
import MissedSessionButton from './MissedSessionButton';
import UrgentAlert from '../../../Components/Molecules/Chatroom/UrgentAlert';

import Video from '../../../Assets/Icons/Video.svg';

import { ReactComponent as IconMood } from '../../../Assets/Icons/Mood.svg';
import { ReactComponent as IconAnalytics } from '../../../Assets/Icons/Analytics.svg';
import { ReactComponent as IconPlay } from '../../../Assets/Icons/Play.svg';
import { ReactComponent as IconJournal } from '../../../Assets/Icons/Journal.svg';
import { ReactComponent as IconCalendar } from '../../../Assets/Icons/Calendar.svg';
import { useWindowSize } from '../../../Hooks';
import Tooltip from '../../../Components/Atoms/Tooltip/Tooltip';
import ReactPlayer from 'react-player/vimeo';
import { simplifySchedule } from '../../../Util/helper';
import { useTranslation } from 'react-i18next';

const therapyToolsLinks = [
  { id: 1, path: '/moodbox', icon: IconMood, label: 'moodBox', description: 'moodBoxDescription' },
  {
    id: 2,
    path: '/analytics',
    icon: IconAnalytics,
    label: 'wellbeingMeter',
    description: 'wellbeingMeterDescription',
  },
  {
    id: 3,
    path: '/well-being',
    icon: IconPlay,
    label: 'resourceCenter',
    description: 'resourceCentreDescription',
  },
  { id: 4, path: '/journal', icon: IconJournal, label: 'journal', description: 'journalDescription' },
  {
    id: 5,
    path: '/calendar',
    icon: IconCalendar,
    label: 'videoAppointments',
    description: 'videoAppointmentsDescription',
  },
  {
    id: 6,
    path: '/additional-support',
    icon: IconJournal,
    label: 'additionalSupport',
    description: 'additionalSupportDescription',
  },
  // {
  //   id: 7,
  //   path: '/employee-benefits',
  //   icon: IconJournal,
  //   label: 'employeeBenefits',
  //   description: 'employeeBenefitsDescription',
  // },
  // {
  //   id: 8,
  //   path: '/discounts',
  //   icon: IconJournal,
  //   label: 'discounts',
  //   description: 'discounts',
  // },
];

export { therapyToolsLinks };

const Sidebar = ({
  chatroomAvailable,
  videoRoomName,
  chatRoomID,
  schedules,
  rooms,
  subscription,
  myBookedEvents,
  setTherapistAvailability,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const startVideoRoom = () => dispatch(launchVideoRoom());
  const isB2b = useSelector((store) => store.user?.profile?.['B2B'] !== 0);
  const isAllowedSwitchTherapist = useSelector((store) => store.user?.profile?.switch_therapist);
  const [modalState, setModalState] = useState(false);
  const { width } = useWindowSize();
  const bookEvent = useCallback(
    (slotId) =>
      dispatch(makeAppointment({ chatroom_id: chatRoomID, slot_id: slotId, appointment_type: 'video' })).then(() =>
        dispatch(getScheduleList(chatRoomID)),
      ),
    [chatRoomID, dispatch],
  );

  const isChatRoomActive = useMemo(() => {
    return !!(rooms && rooms.length && rooms[0].chatroom_status === 'approved');
  }, [rooms]);

  const isMobile = useMemo(() => width < 768, [width]);

  const futureBookedEventsCount = useMemo(() => {
    if (myBookedEvents) {
      const currentDate = moment();
      return myBookedEvents.filter(
        (slot) =>
          slot.appointment_type === 'video' &&
          currentDate.isBefore(moment(`${slot.schedule_date} ${slot.schedule_time}`, 'YYYY-MM-DD HH:mm:ss')),
      ).length;
    }

    return null;
  }, [myBookedEvents]);

  const isAllowedForBooking = useMemo(() => {
    const remainingVideoCalls = subscription?.remaining_video_calls;
    return remainingVideoCalls || futureBookedEventsCount;
  }, [subscription, futureBookedEventsCount]);

  useEffect(() => {
    const showVideo = localStorage.getItem('showTutorialVideo');
    if (showVideo && showVideo === 'true') setModalState(true);
  }, []);

  return (
    <>
      {chatroomAvailable ? (
        <SidebarWrapper isApproved={rooms[0].chatroom_status !== 'approved'}>
          {isB2b && (
            <ModalComponent open={modalState} closeModal={() => setModalState(false)} closeOnDocumentClick={false}>
              <div>
                <ReactPlayer
                  width="100%"
                  url="https://vimeo.com/638532810/7c9cc2b4b3"
                  playing
                  controls
                  onPlay={() => localStorage.setItem('showTutorialVideo', 'false')}
                />
              </div>
            </ModalComponent>
          )}
          {isChatRoomActive && (
            <>
              <ProfileWrapper>
                <Profile />
                <div>
                  {isMobile && <UrgentAlert mobile />}
                  {videoRoomName ? (
                    <StyledButton onClick={startVideoRoom}>
                      <img src={Video} alt="" />
                    </StyledButton>
                  ) : (
                    <MissedSessionButton />
                  )}
                </div>
              </ProfileWrapper>
              <div style={{ marginTop: '16px' }}>
                {isB2b && !isAllowedForBooking ? null : (
                  <Availability
                    isAllowedForBooking={isAllowedForBooking}
                    heading={t('therapistVideoCallAvailability')}
                    slots={schedules}
                    setTherapistAvailability={setTherapistAvailability}
                    bookEvent={bookEvent}
                    canBookAfterCancel={futureBookedEventsCount && !subscription?.remaining_video_calls}
                  />
                )}
              </div>
            </>
          )}
          <Resources withSlots={schedules.length > 0} isChatRoomActive={isChatRoomActive}>
            <SubscriptionBadge subscription={subscription} rooms={rooms} />

            {subscription && subscription.length !== 0 ? (
              <ResourceSection>
                <TherapyTools
                  heading={t('therapyTools')}
                  links={therapyToolsLinks}
                  isChatRoomActive={isChatRoomActive}
                  setModalState={setModalState}
                />
              </ResourceSection>
            ) : (
              ''
            )}
          </Resources>
        </SidebarWrapper>
      ) : (
        <SidebarWrapper>{t('accountPending')}</SidebarWrapper>
      )}

      <SidebarFooter>
        <Tooltip id="emergency" text={t('emergencyText')} place="top">
          <Button as={Link} variant="danger" to="/help">
            {t('emergency?Btn')}
          </Button>
        </Tooltip>
        {isAllowedSwitchTherapist && isChatRoomActive && (subscription?.is_paid || isB2b) && (
          <ChangeTherapist chatroomID={chatRoomID} exceedsswitchTherapist={subscription?.exceedsswitchTherapist} />
        )}
      </SidebarFooter>
    </>
  );
};

const mapStateToProps = (store) => ({
  videoRoomName: store.chatroom?.videoRoom?.name,
  chatRoomID: store.chatroom?.chatRoomID,
  rooms: store.chatroom?.rooms,
  myBookedEvents: store.chatroom?.myBookedEvents,
  schedules: simplifySchedule(store.chatroom?.scheduleList),
  subscription: store.user.subscription,
});

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0;

  ${({ isApproved }) =>
    isApproved &&
    css`
      display: none;
    `}

  ${({ theme }) => theme.md`
    //padding-right: ${theme.spacing};
    //border-right: 1px solid ${({ theme }) => theme.primaryMedium};
    display: initial
  `}
`;

const Resources = styled.div`
  display: none;

  ${({ theme, withSlots }) => theme.md`
      display: block;
      overflow: auto;
      height: calc(100vh - ${withSlots ? '420px' : '360px'});
      margin-top: ${({ theme }) => theme.spacing}
   `}

  ${({ isChatRoomActive }) =>
    !isChatRoomActive &&
    css`
      height: calc(100vh - 185px) !important;
    `}
`;
const ResourceSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingSm};
`;
const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledButton = styled.button`
  border: 0;
  background: none;
  padding: 0;
  margin: ${({ theme }) => theme.spacing};
  cursor: pointer;
`;

const SidebarFooter = styled.div`
  display: none;
  margin-top: 20px;
  position: absolute;
  bottom: 28px;
  ${({ theme }) => theme.md`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `};
`;
SidebarFooter.displayName = 'SidebarFooter';

export default connect(mapStateToProps)(Sidebar);
