import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import useJourneyInitData from '../../../Hooks/useJourneyInitData';

import {
  addTherapyJourney,
  getNotifications,
  getAnnouncements,
  handleChatRoomRequest,
  handleMarkRead,
  handleAnnouncementMarkRead,
  getMoreNotifications,
} from '../../../Actions/chatroom.actions';

import { Button, Heading, Widget } from '../../Atoms';
import { SwitchTherapistPopup } from '../../Molecules';
import NotificationsList from './NotificationsList';
import AnnouncementsList from './AnnouncementsList';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const initialForwardOptions = {
  chatroomID: null,
  notificationID: null,
  open: false,
};

const NotificationsPage = ({ role }) => {
  const registerDate = useSelector((store) => store.user?.profile?.register_date);
  const announcements = useSelector((store) => store.user.announcements);
  const notifications = useSelector((store) => store.user.notifications);
  const journeyInitData = useJourneyInitData();
  const [forwardOptions, setForwardOptions] = useState(initialForwardOptions);
  const notificationsPaginationRef = useRef({ from: 1, to: 50 });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleRequest = useCallback(
    (id, status, notificationId) => {
      dispatch(handleChatRoomRequest(id, status, notificationId)).then(() => {
        if (status === 'approved' && journeyInitData)
          dispatch(
            addTherapyJourney({
              ...journeyInitData,
              chatroom_id: id,
            }),
          );
        dispatch(getNotifications(notificationsPaginationRef.current));
      });
    },
    [dispatch, journeyInitData],
  );

  const markAsRead = useCallback(
    (id) => {
      dispatch(handleMarkRead(id)).then(() => dispatch(getNotifications(notificationsPaginationRef.current)));
    },
    [dispatch],
  );

  const announcementMarkAsRead = useCallback(
    (id) => {
      dispatch(handleAnnouncementMarkRead(id)).then(() => dispatch(getAnnouncements()));
    },
    [dispatch],
  );

  const openForwardModal = useCallback((chatroomID, notificationID) => {
    setForwardOptions({ chatroomID, notificationID, open: true });
  }, []);

  const closeForwardModal = useCallback(() => setForwardOptions(initialForwardOptions), []);

  const loadMoreNotifications = useCallback(() => {
    if (notifications?.pagination) {
      const data = { ...(notifications?.pagination || {}), to: parseInt(notifications?.pagination.to) + 50 };
      dispatch(getMoreNotifications(data));
      dispatch(getNotifications(data));
    }
  }, [notifications, dispatch]);

  useEffect(() => {
    notificationsPaginationRef.current = notifications?.pagination;
  }, [notifications, dispatch]);

  useEffect(() => {
    dispatch(getNotifications(notificationsPaginationRef.current));
    // dispatch(getAnnouncements());
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(getMoreNotifications({ from: 1, to: 50 }));
  }, [dispatch]);

  const handleAnnouncementList = () => {
    if (registerDate) {
      let momentRegisterDate = moment(registerDate);
      let updatedAnnouncements = announcements?.data?.filter((announcement) => {
        let momentAnnouncmentStartDate = moment.utc(announcement?.sent_at);
        return momentAnnouncmentStartDate.isSameOrAfter(momentRegisterDate);
      });
      return updatedAnnouncements;
    }

    return [];
  };

  return (
    <>
      <Widget>
        <Heading>{t('announcements')}</Heading>
        <AnnouncementsList announcements={handleAnnouncementList()} announcementMarkAsRead={announcementMarkAsRead} />
      </Widget>
      <Widget>
        <Heading>{t('notifications')}</Heading>
        <div style={{ overflowX: 'auto', textAlign: 'center' }}>
          <NotificationsList
            notifications={notifications?.data}
            handleRequest={handleRequest}
            handleSwitch={openForwardModal}
            markAsRead={markAsRead}
          />
          {notifications?.total_notification_count > notificationsPaginationRef.current?.to && (
            <Button style={{ marginTop: 20 }} className="" onClick={loadMoreNotifications}>
              {t('loadMoreNotification')}
            </Button>
          )}
        </div>
      </Widget>
      {/* Disabling this, as this feature is not needed anymore. */}
      {/* {role === 'therapist' && forwardOptions.open ? (
        <SwitchTherapistPopup
          chatroomID={forwardOptions.chatroomID}
          notificationID={forwardOptions.notificationID}
          closeForwardModal={closeForwardModal}
          markAsRead={markAsRead}
        />
      ) : null} */}
    </>
  );
};

NotificationsPage.propTypes = {
  role: PropTypes.oneOf(['patient', 'therapist']).isRequired,
};

export default NotificationsPage;
