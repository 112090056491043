import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Sad } from '../../../Assets/Icons/emoji-frown.svg';
import { ReactComponent as Neutral } from '../../../Assets/Icons/emoji-neutral.svg';
import { ReactComponent as Happy } from '../../../Assets/Icons/emoji-laughing.svg';

const MoodIndicator = props => {
  const icon =
    props.mood === 'sad'
      ? <StyledSad />
      : props.mood === 'neutral'
      ? <StyledNeutral />
      : props.mood === 'happy'
        ? <StyledHappy />
        : null;

  return icon;
};

const StyledSad = styled(Sad)`
  fill: ${({ theme }) => theme.primary};
`;

const StyledNeutral = styled(Neutral)`
  fill: ${({ theme }) => theme.primary};
`;

const StyledHappy = styled(Happy)`
  fill: ${({ theme }) => theme.primary};
`;

export default MoodIndicator;
