import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import { aboutMeLowerLimit, aboutMeUpperLimit } from '../../../Constants/other';
// import { phoneRegExp } from '../../../Constants/regExp';

// import api from '../../../Util/api';
import { updateProfile } from '../../../Actions/company.actions';

import { Alert, Button, FormGroup, Label } from '../../../Components/Atoms';
import { ProfilePhotoInput } from '../../../Components/Molecules';
// import tc from '../../../Assets/terms_conditions.docx';

const validFormats = ['image/jpeg', 'image/png'];

const PersonalInfo = ({ profile }) => {
  // const [countries, setCountries] = useState([]);
  // const [cities, setCities] = useState([]);
  const [responseMessage, setResponseMessage] = useState({ type: '', message: '' });
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const getCountries = useCallback(async () => {
  //   setCountries((await api.others.getCountriesList()).data.data);
  // }, []);

  // const getCities = async (countyID) => {
  //   setCities((await api.others.getCitiesList(countyID)).data.data);
  // };

  // const handleCountryChange = async (value, setFieldValue) => {
  //   const { id } = JSON.parse(value);
  //   setFieldValue('country', value);
  //   await getCities(id);
  // };

  const handleOnSubmit = async ({ country, city, profile_image, ...values }, { setSubmitting }) => {
    // const countryData = country ? JSON.parse(country) : {};
    // const cityData = city ? JSON.parse(city) : {};
    const data = new FormData();
    Object.keys(values).forEach((key) => data.append(key, values[key]));
    // if (countryData.name) data.append('country', countryData.name);
    // if (countryData.id) data.append('country_id', countryData.id);
    // if (cityData.name) data.append('city', cityData.name);
    // if (cityData.id) data.append('city_id', cityData.id);
    if (profile_image) data.append('profile_image', profile_image, profile_image.name);
    try {
      const { message } = await dispatch(updateProfile(data));
      setResponseMessage({ type: 'primary', message });
    } catch (err) {
      console.error(err);
      setResponseMessage({ type: 'danger', message: t("somethingWentWrongTryAgainLater") });
    } finally {
      setSubmitting(false);
    }
  };

  const checkFileSize = () => {
    const file = fileInputRef.current.files[0];
    let valid = true;
    if (file) {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        valid = false;
      }
    }
    return valid;
  };

  const checkFileExt = () => {
    const file = fileInputRef.current.files[0];
    let valid = true;

    if (file) {
      if (!validFormats.includes(file.type)) {
        valid = false;
      }
    }
    return valid;
  };

  // useEffect(() => {
  //   getCountries();
  // }, [getCountries]);

  // useEffect(() => {
  //   if (profile.country_id) getCities(profile.country_id);
  // }, [profile]);

  const validationSchema = Yup.object().shape({
    profile_image: Yup.mixed()
      .test('fileSize', 'File Size too Big', checkFileSize)
      .test('fileExt', 'File must be JPEG or PNG format', checkFileExt),
    company_name: Yup.string().required(t("firstNameIsRequired")),
    // last_name: Yup.string().required('Last Name is required'),
    // mobile: Yup.string()
    //   .min(10, 'Mobile Number should be min 10 digits long')
    //   .matches(phoneRegExp, 'Mobile number allows only numeric values')
    //   .required('Mobile Number is required'),
    // nickname: Yup.string(),
    // anonymous: Yup.boolean(),
    // date_of_birth: Yup.string(),
    // about_me: Yup.string()
    //   .min(aboutMeLowerLimit, `Please enter at least ${aboutMeLowerLimit} characters`)
    //   .max(aboutMeUpperLimit, 'About Me text is too long'),
    // exp_summary: Yup.string(),
    // experience: Yup.number(),
    // address: Yup.string(),
    post_code: Yup.string(),
    // country: Yup.string(),
    // city: Yup.string(),
    // trial_account: Yup.number(),
    // terms_and_condition: Yup.number(),
  });

  return (
    <Formik
      initialValues={{
        profile_image: null,
        company_name: profile.company_name,
        // last_name: profile.last_name,
        // mobile: profile.mobile,
        // date_of_birth: profile.date_of_birth,
        // about_me: profile.about_me,
        // exp_summary: profile.exp_summary,
        // experience: profile.experience ? profile.experience : 0,
        // address: profile.address,
        post_code: profile.post_code,
        // country: JSON.stringify({ id: profile.country_id, name: profile.country }),
        // city: JSON.stringify({ id: profile.city_id, name: profile.city }),
        // trial_account: profile.trial_account,
        // terms_and_condition: profile.terms_and_condition,
      }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <StyledForm>
          <ProfilePhotoWrapper>
            <ProfilePhotoInput
              thumbnail={profile.profile_image}
              file={values.profile_image}
              id="profile_image"
              name="profile_image"
              ref={fileInputRef}
              className={'form-control' + (errors.profile_image && touched.profile_image ? ' is-invalid' : '')}
              onChange={({ currentTarget }) => setFieldValue('profile_image', currentTarget.files[0])}
            />
            <ErrorMessage name="profile_image" component="div" className="invalid-feedback" />
          </ProfilePhotoWrapper>

          <div>
            <StyledFormGroup>
              <Label htmlFor="first_name">{t("companyName")}</Label>
              <Field
                disabled
                id="company_name"
                name="company_name"
                type="text"
                className={'form-control' + (errors.first_name && touched.first_name ? ' is-invalid' : '')}
              />
              <ErrorMessage name="company_name" component="div" className="invalid-feedback" />
            </StyledFormGroup>

            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="last_name">Last Name</Label>*/}
            {/*  <Field*/}
            {/*    id="last_name"*/}
            {/*    name="last_name"*/}
            {/*    type="text"*/}
            {/*    className={'form-control' + (errors.last_name && touched.last_name ? ' is-invalid' : '')}*/}
            {/*  />*/}
            {/*  <ErrorMessage name="last_name" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}

            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="mobile">Phone Number (Mobile)</Label>*/}
            {/*  <Field*/}
            {/*    id="mobile"*/}
            {/*    name="mobile"*/}
            {/*    type="text"*/}
            {/*    className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')}*/}
            {/*  />*/}
            {/*  <ErrorMessage name="mobile" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}

            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="date_of_birth">Date Of Birth</Label>*/}
            {/*  <Field*/}
            {/*    id="date_of_birth"*/}
            {/*    name="date_of_birth"*/}
            {/*    type="date"*/}
            {/*    className={'form-control' + (errors.date_of_birth && touched.date_of_birth ? ' is-invalid' : '')}*/}
            {/*  />*/}
            {/*  <ErrorMessage name="date_of_birth" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}

            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="about_me">About Me</Label>*/}
            {/*  <Field*/}
            {/*    id="about_me"*/}
            {/*    name="about_me"*/}
            {/*    as="textarea"*/}
            {/*    className={'form-control' + (errors.about_me && touched.about_me ? ' is-invalid' : '')}*/}
            {/*  />*/}
            {/*  <ErrorMessage name="about_me" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}

            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="exp_summary">Experience Summary</Label>*/}
            {/*  <Field*/}
            {/*    id="exp_summary"*/}
            {/*    name="exp_summary"*/}
            {/*    type="text"*/}
            {/*    className={'form-control' + (errors.exp_summary && touched.exp_summary ? ' is-invalid' : '')}*/}
            {/*  />*/}
            {/*  <ErrorMessage name="exp_summary" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}

            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="experience">Years of Experience</Label>*/}
            {/*  <Field*/}
            {/*    id="experience"*/}
            {/*    name="experience"*/}
            {/*    type="number"*/}
            {/*    className={'form-control' + (errors.experience && touched.experience ? ' is-invalid' : '')}*/}
            {/*  />*/}
            {/*  <ErrorMessage name="experience" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}

            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="address">Address Line 1</Label>*/}
            {/*  <Field*/}
            {/*    id="address"*/}
            {/*    name="address"*/}
            {/*    type="text"*/}
            {/*    className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}*/}
            {/*  />*/}
            {/*  <ErrorMessage name="address" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}

            <StyledFormGroup>
              <Label htmlFor="post_code">{t("postCode")}</Label>
              <Field
                id="post_code"
                name="post_code"
                type="text"
                className={'form-control' + (errors.post_code && touched.post_code ? ' is-invalid' : '')}
              />
              <ErrorMessage name="post_code" component="div" className="invalid-feedback" />
            </StyledFormGroup>

            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="country">Country</Label>*/}
            {/*  <Field*/}
            {/*    as="select"*/}
            {/*    id="country"*/}
            {/*    name="country"*/}
            {/*    className={'form-control' + (errors.country && touched.country ? ' is-invalid' : '')}*/}
            {/*    onChange={(e) => handleCountryChange(e.target.value, setFieldValue)}*/}
            {/*  >*/}
            {/*    <option disabled value={JSON.stringify({ id: '', name: '' })} label="Country" />*/}
            {/*    {countries.map(({ id, name }) => (*/}
            {/*      <option key={id} value={JSON.stringify({ id, name })}>*/}
            {/*        {name}*/}
            {/*      </option>*/}
            {/*    ))}*/}
            {/*  </Field>*/}
            {/*  <ErrorMessage name="country" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}

            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="city">City</Label>*/}
            {/*  <Field*/}
            {/*    as="select"*/}
            {/*    id="city"*/}
            {/*    name="city"*/}
            {/*    disabled={!cities.length}*/}
            {/*    className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')}*/}
            {/*  >*/}
            {/*    <option disabled value={JSON.stringify({ id: '', name: '' })} label="City" />*/}
            {/*    {cities.map(({ id, name }) => (*/}
            {/*      <option key={id} value={JSON.stringify({ id, name })}>*/}
            {/*        {name}*/}
            {/*      </option>*/}
            {/*    ))}*/}
            {/*  </Field>*/}
            {/*  <ErrorMessage name="city" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}

            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="trial_account">20 minute trial offered to patients</Label>*/}
            {/*  <Field*/}
            {/*    as="select"*/}
            {/*    id="trial_account"*/}
            {/*    name="trial_account"*/}
            {/*    type="text"*/}
            {/*    className={'form-control' + (errors.trial_account && touched.trial_account ? ' is-invalid' : '')}*/}
            {/*  >*/}
            {/*    <option value={1} label="Yes" />*/}
            {/*    <option value={0} label="No" />*/}
            {/*  </Field>*/}
            {/*  <ErrorMessage name="trial_account" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}
            {/*<StyledFormGroup>*/}
            {/*  <Label htmlFor="trial_account">*/}
            {/*    Terms and Conditions{' '}*/}
            {/*    <a href={tc} download>*/}
            {/*      Download*/}
            {/*    </a>*/}
            {/*  </Label>*/}
            {/*  <Field*/}
            {/*    as="select"*/}
            {/*    id="terms_and_condition"*/}
            {/*    name="terms_and_condition"*/}
            {/*    type="text"*/}
            {/*    className={*/}
            {/*      'form-control' + (errors.terms_and_condition && touched.terms_and_condition ? ' is-invalid' : '')*/}
            {/*    }*/}
            {/*  >*/}
            {/*    <option value={1} label="Agree" />*/}
            {/*    <option value={0} label="Not agree" />*/}
            {/*  </Field>*/}
            {/*  <ErrorMessage name="terms_and_condition" component="div" className="invalid-feedback" />*/}
            {/*</StyledFormGroup>*/}
          </div>

          {responseMessage.type && (
            <StyledAlert className={responseMessage.type}>{responseMessage.message}</StyledAlert>
          )}

          <Button type="submit">{t("save")}</Button>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  ${({ theme }) => theme.lg`
    display: grid;
    grid-template-columns: 200px auto;
    column-gap: ${theme.spacingLg};
  `};
`;
StyledForm.displayName = 'StyledForm';

const ProfilePhotoWrapper = styled.div`
  text-align: center;
`;
ProfilePhotoWrapper.displayName = 'ProfilePhotoWrapper';

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: ${({ theme }) => theme.spacing};
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};
`;
StyledFormGroup.displayName = 'StyledFormGroup';

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing};
  grid-column: 1 / 2 span;
`;
StyledAlert.displayName = 'StyledAlert';

PersonalInfo.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default PersonalInfo;
