import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Heading } from '../../Atoms';

const PageHeading = (props) => {
  return (
    <StyledWrapper>
      <props.icon />
      <Heading>{props.name}</Heading>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingMd};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.greyLight};
  
  svg {
    margin-right: ${({ theme }) => theme.spacingMd};
    fill: ${({ theme }) => theme.primary};
  }
`;
StyledWrapper.displayName = 'StyledWrapper';

PageHeading.propTypes = {
  icon: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default PageHeading;
