import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { TherapyTools } from '../../../Components/Organisms';
import Faq from './Faq';

import { ReactComponent as IconMood } from '../../../Assets/Icons/Mood.svg';
import { ReactComponent as IconJournal } from '../../../Assets/Icons/Journal.svg';

import { useDispatch, useSelector } from 'react-redux';
import { Button, ModalComponent } from '../../../Components/Atoms';
import { getNotes } from '../../../Actions/chatroom.actions';
import { Link } from 'react-router-dom';
import { Collapse } from 'antd';
import ConcerningIssues from './ConcerningIssues';

const Sidebar = ({ chatroomAvailable }) => {
  const [showConcerningIssueModal, setShowConcerningIssueModal] = useState(false);
  const rooms = useSelector((store) => store.chatroom.rooms);
  const chatRoomID = useSelector((store) => store.chatroom?.chatRoomID);
  const dispatch = useDispatch();

  const patientId = useMemo(() => {
    return rooms.find(({ chatroom_id }) => chatroom_id === chatRoomID)?.patient_id;
  }, [rooms, chatRoomID]);

  const therapyToolsLinks = useMemo(
    () => [
      { id: 0, path: `/t/patient-profile/${chatRoomID}/${patientId}`, icon: IconMood, label: 'Patient Details' },
      { id: 2, path: `/t/journal/${chatRoomID}`, icon: IconJournal, label: 'Journal' },
    ],
    [chatRoomID, patientId],
  );

  useEffect(() => {
    dispatch(getNotes(chatRoomID));
  }, [chatRoomID, dispatch]);

  return chatroomAvailable ? (
    <ChatMeta>
      <Resources>
        <ResourceSection>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <StyledLink to={`/t/note/${chatRoomID}`}>Notes</StyledLink>
            <StyledButton onClick={() => setShowConcerningIssueModal(true)}>Add Concerning issues</StyledButton>
          </div>
        </ResourceSection>

        <ModalComponent
          open={showConcerningIssueModal}
          closeOnDocumentClick
          closeModal={() => setShowConcerningIssueModal(false)}
          contentStyle={{ maxHeight: '60vh' }}
        >
          <div
            style={{
              overflow: 'scroll',
              maxHeight: '56vh',
              paddingRight: 25,
            }}
          >
            <ConcerningIssues setShowConcerningIssueModal={setShowConcerningIssueModal} />
          </div>
        </ModalComponent>
        <ResourceSection>
          <TherapyTools heading="Therapy Tools" links={therapyToolsLinks} isPatient={false} />
        </ResourceSection>
      </Resources>
      <SidebarFooter>
        <EmergencyLink href="/">Suggestions</EmergencyLink>
        <Faq />
      </SidebarFooter>
    </ChatMeta>
  ) : (
    <ChatMeta>Account Pending...</ChatMeta>
  );
};

const NotesWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing};
  display: flex;
  justify-content: space-between;
`;
NotesWrapper.displayName = 'NotesWrapper';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.primaryMedium};
  font-weight: ${({ theme }) => theme.fontBold};
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.primaryMedium};
  font-weight: ${({ theme }) => theme.fontBold};
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
SubmitButtonWrapper.displayName = 'SubmitButtonWrapper';

const ChatMeta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing};

  ${({ theme }) => theme.md`
    border-left: 1px solid ${({ theme }) => theme.primaryMedium};
  `}
`;
const Resources = styled.div`
  display: block;
  overflow: auto;
  height: calc(100vh - 285px);
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing};
`;
const ResourceSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingLg};
`;

const SidebarFooter = styled.div`
  margin-top: ${({ theme }) => theme.spacing};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
SidebarFooter.displayName = 'SidebarFooter';

const EmergencyLink = styled.a`
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.secondary};
`;
EmergencyLink.displayName = 'EmergencyLink';

export default Sidebar;
