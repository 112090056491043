import React from 'react';
import styled from 'styled-components';
import { WelcomeLayout } from '../../Layout';
import { Heading } from '../../Components/Atoms';
import {
  SaudiArabiaContactsData,
  CanadaContactsData,
  GreeceContactsData,
  SouthAfricaContactsData,
  UKIContactsData,
  USContactsData,
  SerbiaContactsData,
} from '../../Data/UrgentHelpData';
import { BottomSectionWrapper, MainSectionWrapper } from '../Components/';
import { useTranslation } from 'react-i18next';

const Help = () => {
  const { t } = useTranslation();
  let ukiContacts = [];
  let southAfricaContacts = [];
  let usContacts = [];
  let greeceContacts = [];
  let saudiArabiaContacts = [];
  let canadaContacts = [];
  let serbiaContacts = [];

  SerbiaContactsData.map((item) => {
    serbiaContacts.push(
      <HelpItem key={item.id}>
        <HelpCompany>{item.company}</HelpCompany>
        <HelpContact>
          {item.url ? (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.contact}
            </a>
          ) : (
            item.contact
          )}
        </HelpContact>
      </HelpItem>,
    );
    return ukiContacts;
  });

  UKIContactsData.map((item) => {
    ukiContacts.push(
      <HelpItem key={item.id}>
        <HelpCompany>{item.company}</HelpCompany>
        <HelpContact>
          {item.url ? (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.contact}
            </a>
          ) : (
            item.contact
          )}
        </HelpContact>
      </HelpItem>,
    );
    return ukiContacts;
  });

  SouthAfricaContactsData.map((item) => {
    southAfricaContacts.push(
      <HelpItem key={item.id}>
        <HelpCompany>{item.company}</HelpCompany>
        <HelpContact>
          {item.url ? (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.contact}
            </a>
          ) : (
            item.contact
          )}
        </HelpContact>
      </HelpItem>,
    );
    return southAfricaContacts;
  });

  USContactsData.map((item) => {
    usContacts.push(
      <HelpItem key={item.id}>
        <HelpCompany>{item.company}</HelpCompany>
        <HelpContact>
          {item.url ? (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.contact}
            </a>
          ) : (
            item.contact
          )}
        </HelpContact>
      </HelpItem>,
    );
    return usContacts;
  });

  GreeceContactsData.map((item) => {
    greeceContacts.push(
      <HelpItem key={item.id}>
        <HelpCompany>{item.company}</HelpCompany>
        <HelpContact>
          {item.url ? (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.contact}
            </a>
          ) : (
            item.contact
          )}
        </HelpContact>
      </HelpItem>,
    );
    return greeceContacts;
  });

  SaudiArabiaContactsData.map((item) => {
    saudiArabiaContacts.push(
      <HelpItem key={item.id}>
        <HelpCompany>{item.company}</HelpCompany>
        <HelpContact>
          {item.url ? (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.contact}
            </a>
          ) : (
            item.contact
          )}
        </HelpContact>
      </HelpItem>,
    );
    return saudiArabiaContacts;
  });

  CanadaContactsData.map((item) => {
    canadaContacts.push(
      <HelpItem key={item.id}>
        <HelpCompany>{item.company}</HelpCompany>
        <HelpContact>
          {item.url ? (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.contact}
            </a>
          ) : (
            item.contact
          )}
        </HelpContact>
      </HelpItem>,
    );
    return canadaContacts;
  });

  return (
    <>
      <WelcomeLayout noPadding noTopBar>
        <MainSectionWrapper>
          <Wrapper>
            <Heading text={t('urgentHelp')} />
            <p>{t('helpText1')}</p>
            <div style={{ marginTop: 40 }}>
              <Heading level={2} text={t('UK&Ireland')} />
              {ukiContacts}
            </div>
            <div style={{ marginTop: 40 }}>
              <Heading level={2} text={t('southAfrica')} />
              {southAfricaContacts}
            </div>

            <div style={{ marginTop: 40 }}>
              <Heading level={2} text={t('usa')} />
              {usContacts}
            </div>
            <div style={{ marginTop: 40 }}>
              <Heading level={2} text={t('greece')} />
              {greeceContacts}
            </div>
            <div style={{ marginTop: 40 }}>
              <Heading level={2} text={t('Serbia')} />
              {serbiaContacts}
            </div>
            <div style={{ marginTop: 40 }}>
              <Heading level={2} text={t('saudiArabia')} />
              {saudiArabiaContacts}
            </div>
            <div style={{ marginTop: 40 }}>
              <Heading level={2} text={t('canada')} />
              {canadaContacts}
            </div>
          </Wrapper>
        </MainSectionWrapper>
        <BottomSectionWrapper />
      </WelcomeLayout>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  margin: auto;
`;
const HelpItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.greyVeryLight};
  padding: ${({ theme }) => theme.spacing} 0;

  ${({ theme }) => theme.md`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;
const HelpCompany = styled.p`
  font-weight: ${({ theme }) => theme.fontMedium};
  ${({ theme }) => theme.md`
      margin: 0;
      `}
`;

const HelpContact = styled.p`
  margin: 0;
  a {
    font-weight: ${({ theme }) => theme.fontMedium};
  }
`;
export default Help;
