import React from 'react';
import DatePickerComponent from 'react-date-picker';
import styled from 'styled-components';

const DatePicker = (props) => {
  return (
    <StyledDatePicker
      {...props}
    />
  );
};

const StyledDatePicker = styled(DatePickerComponent)`
  font-weight: ${({ theme }) => theme.fontMedium};
  
  &.react-date-picker--disabled {
    .react-date-picker__inputGroup__leadingZero {
      opacity: 0.5;
    }
  }

  .react-date-picker__wrapper {
    border: 1px solid rgba(0, 0, 0, 0.21);
    border-radius: 4px;
  }
  
  .react-date-picker__inputGroup {
    display: flex;
    padding: ${({ theme }) => theme.spacingSm} 0 ${({ theme }) => theme.spacingSm} 10px;
  }
  
  .react-date-picker__inputGroup__input,
  .react-date-picker__inputGroup__leadingZero {
    color: ${({ theme }) => theme.black};
  }
  
  .react-date-picker__inputGroup__leadingZero {
    padding-top: 1px;
  }
  
  .react-date-picker__inputGroup__month {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
`;
StyledDatePicker.displayName = 'StyledDatePicker';

export default DatePicker;
