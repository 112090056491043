import { createSelector } from 'reselect';

//----------- State Selectors -----------//

const getTrackerData = (store) => store.chatroom.therapyJourney.data;

//----------- Selectors Logic -----------//

// Returning active Stage and SubStage from TherapyTracker API response
export const getActiveStage = createSelector([getTrackerData], (data) => {
  const activeState = [];
  data &&
    data.forEach((stage, stageIndex) => {
      if (stage.active) {
        activeState.push(stageIndex);
        if (stage?.sub_stages?.length) {
          stage.sub_stages.forEach((subStage, subStageIndex) => {
            if (subStage.active) activeState.push(subStageIndex);
          });
        }
      }
    });

  // If there is an error with subStage activeId we by default mark as active index 0
  if (activeState.length === 1) activeState.push(0);

  return activeState;
});
