import React from 'react';
import { Heading, Widget } from '../../Components/Atoms';
import { InviteEmployees, ManageEmployees } from './Components';
import {CompanyEmployeeData} from '../../Data/CompanyEmployeeData';
import { useTranslation } from 'react-i18next';

const Employees = () => {
  const { t } = useTranslation();
  return (
    <>
      <Heading>{t("employees")}</Heading>
      <Widget>
        <InviteEmployees />
        <hr />
        <ManageEmployees data={CompanyEmployeeData(t)}></ManageEmployees>
      </Widget>
    </>
  );
};

export default Employees;
