import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AuthLayout } from '../../Layout';
import { Loading } from '../../Components/Atoms';
import { TherapistSpecialLayout } from '../Signup';
import config from '../../Config';
import { Logo } from '../../Patient/Signup';

import AdminLoginForm from '../../Components/Organisms/LoginForm/AdminLoginForm';

const AdminLogin = (props) => {
  const loginInfo = localStorage.getItem('loginInfo');
  const isLoggedIn = !!loginInfo;
  const userRole = loginInfo ? JSON.parse(loginInfo).role : '';
  const history = useHistory();
  const therapistProfile = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (userRole === 'patient') {
      history.push('/chatroom');
    } else if (therapistProfile) {
      if (!therapistProfile.license_no) history.push('/t/signup/step-5');
      else history.push('/t/dashboard');
    }
  }, [userRole, history, therapistProfile]);

  return (
    <TherapistSpecialLayout>
      <AuthLayout>
        <Wrapper>
          {isLoggedIn ? (
            <Loading />
          ) : (
            <>
                <Logo src={config.nameLogo} />
              <AdminLoginForm
                userType="therapist"
                redirect="/t/dashboard"
                heading="Admin Therapist Login"
                timeout
                {...props}
              />
            </>
          )}
        </Wrapper>
      </AuthLayout>
    </TherapistSpecialLayout>
  );
};

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;
export default AdminLogin;
