import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import { Bar } from 'react-chartjs-2';

const displayFormats = {
  day: 'ddd',
  week: 'MMM DD',
  month: 'MMM',
  year: 'YYYY',
};

const TimeSeriesBarChart = ({ data, label, timePeriod, maxValue, striped, stepSize, profile }) => {
  const reformattedData = useMemo(() => {
    const clonedData = cloneDeep(data);
    // Chart.js doesn't provide grouping by time yet, so need to do it manually
    if (Object.keys(displayFormats).includes(timePeriod) && timePeriod !== 'day') {
      const groupedData = clonedData.reduce((groupedData, item) => {
        let groupName = moment(item.x)[timePeriod]();
        const existingkeys = Object.keys(groupedData);
        if (existingkeys.includes(groupName.toString())) groupName = existingkeys.length;
        if (!groupedData[groupName]) groupedData[groupName] = [];
        groupedData[groupName].push({ ...item });
        return groupedData;
      }, {});

      return Object.keys(groupedData).map((key) => ({
        x: groupedData[key][0].x,
        y: Math.round(
          groupedData[key].reduce((total, item) => total + item.y, 0) / Object.values(groupedData[key]).length,
        ),
      }));
    }
    return clonedData;
  }, [data, timePeriod]);

  const chartData = useMemo(
    () => ({
      datasets: [
        {
          backgroundColor: '#00C8A0',
          barThickness: 7,
          label,
          data: reformattedData,
        },
      ],
    }),
    [reformattedData, label],
  );
  const chartOptions = useMemo(() => {
    return {
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              fontFamily: '"Roboto", sans-serif',
              fontColor: '#9D9D9D',
              fontSize: 15,
              padding: 10,
              max: reformattedData.length ? new Date(reformattedData[reformattedData.length - 1].x) : undefined,
              min: reformattedData.length ? new Date(reformattedData[0].x) : undefined,
            },
            offset: true,
            type: 'time',
            time: {
              unit: timePeriod,
              round: timePeriod,
              displayFormats: {
                [timePeriod]: displayFormats[timePeriod],
              },
              isoWeekday: true,
            },
          },
        ],
        yAxes:
          profile && profile['B2B']
            ? [{ display: false, ticks: { beginAtZero: true, stepSize, max: maxValue } }]
            : [
                {
                  gridLines: {
                    drawOnChartArea: striped || false,
                    drawBorder: false,
                    drawTicks: false,
                    color: 'rgba(157, 157, 157, 0.3)',
                    zeroLineColor: '#9D9D9D',
                  },
                  ticks: {
                    display: false,
                    beginAtZero: true,
                    fontFamily: '"Roboto", sans-serif',
                    fontColor: '#9D9D9D',
                    fontSize: 15,
                    padding: 10,
                    precision: 0,
                    stepSize,
                    max: maxValue,
                  },
                },
              ],
      },
      legend: {
        display: false,
      },
    };
  }, [timePeriod, maxValue, striped, stepSize, reformattedData, profile]);

  return <Bar data={chartData} options={chartOptions} />;
};

TimeSeriesBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  timePeriod: PropTypes.oneOf(Object.keys(displayFormats)).isRequired,
  maxValue: PropTypes.number,
  stepSize: PropTypes.number,
  striped: PropTypes.bool,
};

export default TimeSeriesBarChart;
