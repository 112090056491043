import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { Button, Heading, ModalComponent } from '../../../Components/Atoms';
import Switch from '../../../Components/Atoms/Form/Switch';
import AccountIcon from '../../../Assets/Icons/MyAccount.svg';
import CloseIcon from '../../../Assets/Icons/ClosePurple.svg';
import { TherapistMenuLinks } from '../../../Data/Menus';
import api from '../../../Util/api';
import { useTranslation } from 'react-i18next';

const TherapistMenu = ({ user_online_status }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(user_online_status);
  const [signOutState, setSignOutState] = useState(false);
  const history = useHistory();

  const handleStateChange = ({ isOpen }) => setIsOpen(isOpen);

  const closeMenu = () => setIsOpen(false);

  const handleToggle = () => {
    api.therapist
      .setOnlineStatus(!value ? 1 : 0)
      .then((response) => response.data)
      .then((result) => {
        setValue(!value ? 1 : 0);
      })
      .catch((error) => console.log('error', error));
  };

  const closeSignOutModal = () => setSignOutState(false);
  const openModal = () => {
    setIsOpen(false);
    setSignOutState(true);
  };

  const signOut = () => {
    closeSignOutModal();
    history.push('/t/logout');
  };

  return (
    <Menu
      isOpen={isOpen}
      onStateChange={handleStateChange}
      right
      customBurgerIcon={<img src={AccountIcon} alt="" />}
      customCrossIcon={<img src={CloseIcon} alt="" />}
      styles={NavStyles}
      pageWrapId={'mainContent'}
      outerContainerId={'siteWrapper'}
      disableAutoFocus
    >
      {signOutState && (
        <ModalComponent open={signOutState} closeModal={closeSignOutModal}>
          <div style={{ textAlign: 'center' }}>
            <Heading level={3}>{t("signOutheading")}</Heading>
            <ButtonsWrapper>
              <Button inline className="small success" onClick={signOut}>
              {t("signOut")}
              </Button>
              <Button inline className="small warning" onClick={closeSignOutModal}>
              {t("cancel")}
              </Button>
            </ButtonsWrapper>
          </div>
        </ModalComponent>
      )}
      <ul>
        <OnlineStatus>
          <OnlineStatusLabel>{t("Status")}</OnlineStatusLabel>
          <Switch isOn={value} handleToggle={handleToggle} />
        </OnlineStatus>
        {TherapistMenuLinks(t).map((item) => (
          <li key={item.id}>
            <StyledLink to={item.url} onClick={closeMenu}>
              {item.title}
            </StyledLink>
          </li>
        ))}
        <li>
          <SignOut onClick={openModal}>{t("signOut")}</SignOut>
        </li>
      </ul>
    </Menu>
  );
};

const NavStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '18px',
    top: '0',
    zIndex: 999,
  },
  bmCrossButton: {
    width: '18px',
    top: '26px',
    right: '16px',
  },

  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    right: 0,
  },
  bmMenu: {
    background: '#ffffff',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#491F69',
    padding: '64px 32px 32px',
  },
  bmItem: {
    display: 'block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

const StyledLink = styled(Link)`
  display: block;
  font-size: 17px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  text-decoration: none;
`;

const OnlineStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  text-decoration: none;
`;

const SignOut = styled.div`
  display: block;
  font-size: 17px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  text-decoration: none;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing} 0;

  button {
    margin: 0 ${({ theme }) => theme.spacing};
  }
`;

const OnlineStatusLabel = styled.span`
  margin-right: ${({ theme }) => theme.spacingSm};
`;

export default TherapistMenu;
