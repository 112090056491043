import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomRadio = (props) => {
    const { label, variant, id, ...other } = props;

    return (
        <RadioWrapper>
            <Radio variant={variant} type="radio" id={id} {...other} />
            <label htmlFor={id}>{label && label}</label>
        </RadioWrapper>
    );
};

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;
RadioWrapper.displayName = 'RadioWrapper';

const Radio = styled.input`
    &:checked,
    &:not(:checked) {
        position: absolute;
        left: -9999px;    
    }
    &:checked + label,
    &:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        // color: red;
    }
    &:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid ${({ theme, variant }) => theme[variant + 'Alpha30']};
        border-radius: 100%;
        background: ${({ theme, variant }) => theme[variant + 'Alpha40']};
        box-shadow: 0px 0px 3px 5px ${({ theme, variant }) => theme[variant + 'Alpha40']};
    }
    &:checked + label:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 2px solid ${({ theme, variant }) => theme[variant]};
        border-radius: 100%;
        background: ${({ theme, variant }) => theme[variant + 'Alpha30']};
        box-shadow: 0px 0px 3px 5px ${({ theme, variant }) => theme[variant + 'Alpha40']};
    }
    &:checked + label:after,
    &:not(:checked) + label:after {
        content: '';
        width: 10px;
        height: 10px;
        background: ${({ theme, variant }) => theme[variant]};
        position: absolute;
        top: 4px;
        left: 50%;
        margin-left: -10px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    &:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    &:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
`
CustomRadio.propTypes = {
    label: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    variant: PropTypes.string
};
CustomRadio.defaultProps = {
    label: '',
    id: '',
    variant: 'primary'
};

export default memo(CustomRadio);
