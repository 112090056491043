import React from "react";
import styled, { css } from "styled-components";

const Widget = props => {
  return (
    <WidgetWrapper className="widget" {...props}>
      {props.children}
    </WidgetWrapper>
  );
};

const WidgetWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 27px rgba(73, 31, 105, 0.14);
  border-radius: ${({ theme }) => theme.spacingSm};
  padding: ${({ theme }) => theme.spacingLg};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  width: 100%;
  /* height: 100%; */
  text-align: ${(props) => (props.align ? props.align : null)};
  color: ${({ theme }) => theme.primary};
  
  ${({ vertical }) => vertical && css`
    display: flex;
    flex-direction: column;
    justify-content: ${vertical};
  `};
`;
export default Widget;
