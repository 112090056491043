import axios from 'axios';
import { publicAxios } from './axiosConfig';
import config from '../Config';

let dailyCoConfigInstance = axios.create();
delete dailyCoConfigInstance.defaults.headers.common['language'];
delete dailyCoConfigInstance.defaults.headers.common['sourcecompany'];

const dailyCoConfig = (url, method, data) => ({
  method,
  url: `https://api.daily.co/v1${url}`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_DAILYCO_API}`,
  },
  data,
});

export default {
  auth: {
    invitation: (invitationPassword) =>
      publicAxios.get(`/company/company_url?invitation_password=${invitationPassword}`),
    login: (userType, data) => publicAxios.post(`${userType}/login`, data),
    adminLogin: (data) => publicAxios.post(`/admin/therapist-login`, data),
    signup: (userType, data) => publicAxios.post(`${userType}/signup?source_company=${config.sourcecompany}`, data),
    forgotPassword: (data) => publicAxios.post('/forgot_password', data),
    resetPassword: (data) => publicAxios.post('/reset_password', data),
    changePassword: (data) => axios.post('/change_password', data),
    verifyEmail: (data) => axios.post('/verify', data),
    updateEmail: (email) => axios.post('/request_update_email', email),
    verifyUpdatedEmail: (data) => axios.post('/verify_update_email', data),
    resendCode: (data) => axios.post('/resend_code', data),
    isPartner: (subdomain) => axios.post('/verify_partner', { subdomain }),
  },
  chatroom: {
    sendBulkMessage: (data) => axios.post(`/chatroom/sendbulkmessage/`, data),
    sendMessage: (chatroom_id, message) => axios.post('/chatroom/sendmessage', { chatroom_id, message }),
    sendFile: (chatroom_id, file) => axios.post(`/chatroom/sendmessage?chatroom_id=${chatroom_id}`, file),
    getMessageHistory: (chatroomID, pageLimit) =>
      axios.get(`/chatroom/messages?chatroom_id=${chatroomID}&page_limit=${pageLimit}`),
    activeChatRooms: () => axios.get('/chatroom/activechatrooms'),
    getUnreadMessages: () => axios.get('/chatroom/unreadmessages'),
    getStats: (date = null) => axios.get(`/chatroom/stats${date ? `?date=${date}` : ''}`),
    getChatResources: (chatroomID) => axios.get(`/chatroom/chatresources?chatroom_id=${chatroomID}`),
    getNotifications: (pagination) =>
      axios.get(
        `/chatroom/notifications${
          pagination ? `?from=${pagination.from ? pagination.from : 1}&to=${pagination.to}` : ''
        }`,
      ),
    getAnnouncements: () => axios.get('/announcements'),
    handleChatRoomRequest: (chatroom_id, status) => axios.post('/chatroom/request', { chatroom_id, status }),
    handleMarkRead: (id) => axios.post(`/chatroom/read_notification?notification_id=[${id}]`),
    handleAnnouncementMarkRead: (id) => axios.post(`/read_announcement?announcement_id=${id}`),
    getScheduleList: (id, start, end) =>
      axios.get(`chatroom/schedule?chatroom_id=${id}&start_date=${start}&end_date=${end}`),
    getFullScheduleList: (id, start, end) =>
      axios.get(`chatroom/schedule?chatroom_id=${id}&start_date=${start}&end_date=${end}`),
    getJournals: (page_no = 1, chatroom_id) =>
      axios.get(`/chatroom/journals?page_no=${page_no}${chatroom_id ? `&chatroom_id=${chatroom_id}` : ''}`),
    getJournal: (id) => axios.get(`/chatroom/journal?journal_id=${id}`),
    addJournal: (data) => axios.post('/chatroom/add_journal', data),
    updateJournal: (data) => axios.post('/chatroom/update_journal', data),
    deleteJournal: (id) => axios.post(`/chatroom/delete_journal?journal_id=${id}`),
    addTherapyJourney: (data) => axios.post('/chatroom/stages', data),
    getTherapyJourney: (id) => axios.get(`/chatroom/get_tracker?chatroom_id=${id}`),
    getTemplatesStages: (id) => axios.get(`/chatroom/stages?template_id=${id}`),
    getJourneyTemplates: () => axios.get('chatroom/templates'),
    getNotes: (chatroom_id) => axios.get(`/chatroom/notes?chatroom_id=${chatroom_id}`),
    addNotes: (chatroom_id, formData) =>
      axios.post('/chatroom/notes', {
        chatroom_id,
        notes: formData.notes,
        concerning_issues: formData.forms,
      }),
    canSubmitFeedback: (id) => axios.get(`/chatroom/can_submit_feedback?chatroom_id=${id}`),
    submitFeedback: (data) => axios.post('/chatroom/therapist_feedback', data),
    updateFlag: (data) => axios.post('/chatroom/update_flag', data),
    getFaqCategories: () => axios.get('/faq_category'),
    getFaqCategoriesList: (type) => axios.get(`/faq_category_list?type=${type}`),
    getFaqListing: (categoryID) => axios.get(`/faq?category_id=${categoryID}`),
    getQuestionnaireResult: (id) => axios.get(`chatroom/questionnaire?chatroom_id=${id}`),
    switchTherapist: ({ chatroom_id, forward_notes, shorthand_notes }) =>
      axios.post('/chatroom/switch', { chatroom_id, forward_notes, shorthand_notes }),
    shareJournal: (journalId, status) =>
      axios.post('/chatroom/share_journal', { journal_id: journalId, share_with_therapist: status }),
    readUnreadMessages: (messageIDs) => axios.post('/chatroom/readmessage', { message_id: JSON.stringify(messageIDs) }),
    reduceVideoSession: (data) => axios.post('/patient/end_callv2', data),
  },
  patient: {
    patinetLangaugeSet: (language) => axios.post(`/set_language`, { language }),
    benefitCategories: () => axios.get(`/other_benefit_categories`),
    categoryFaq: (id) => axios.get(`/category_faq/?category_id=${id}`),
    benefitMessage: (id, subject, message) => axios.post(`/send_benefit_message/?category_id=${id}&subject=${subject}&message=${message}`),

    discounts: () => axios.get('/patient/discount/discounts'),
    viewDiscountCategories: () => axios.get('/patient/discount/categories'),
    recordPageView: (id, discount_id) =>
      axios.post(`/patient/discount/record-page-view?category_id=${id}&discount_id=${discount_id}`),

    viewBenefits: () => axios.get('/company/benefits'),
    getProfile: () => axios.get('/patient/profile'),
    submitEmployeeFeedback: (data) => axios.post('/patient/save-priority_issue', data),
    getMoodQuestions: () => axios.get('/patient/mood_questions'),
    saveMoodQuestions: (data) => axios.post('/patient/save_moods', data),
    updateProfile: (data) => axios.post('/patient/profile', data),
    startQuiz: () => axios.post('/patient/startquiz'),
    submitQuizAnswer: (quizJourney, questionID, selectedAnswerIDs) =>
      axios.post(
        `/patient/startquiz?quiz_journey=${quizJourney}&question_id=${questionID}&answer_id=[${selectedAnswerIDs}]`,
      ),
    makeAppointment: (data) => axios.post('/patient/appointment', data),
    getAnalytics: (start_date = null, end_date = null, period) =>
      axios.get(
        `/patient/stats${
          start_date && end_date ? `?start_date=${start_date}&end_date=${end_date}` : ''
        }&type=${period}`,
      ),
    subscriptionStatus: () => axios.get('/patient/subscription_status'),
    endVideoCall: (chatRoomId) => axios.post(`/patient/end_call?chatroom_id=${chatRoomId}`),
    saveMood: (mood) => axios.post('/patient/mood', { mood }),
    referFriend: (data) => axios.post('/patient/refer_friend', data),
    getNotificationSettings: () => axios.get('/notification/settings'),
    updateNotificationSettings: (setting_id, status, type) =>
      axios.post(`/notification/update_setting?setting_id=${setting_id}&status=${status}&notify_type=${type}`),
    triggerUrgentAlert: () => axios.post('/patient/urgent_alert'),
    reportMissedSession: () => axios.post('/patient/missed_video_session'),
    questionnaireGoBack: (data) => axios.post('/patient/back_questionnaire', data),
    socialLogin: (data) => axios.post(`/patient/social_login`, data),
    redeemVoucher: (voucher) => axios.post('/patient/redeem-voucher-plan', { voucher }),
    matchedTherapists: (profession_id) => axios.post('/patient/matched_therapist', { profession_id }),
    getProfessionalTypes: () => axios.get('/profession_types'),
    getProfessionalType: (id) => axios.get(`/profession_types?type_id=${id}`),
  },
  therapist: {
    scheduleAppointment: (data) => axios.post('therapist/reschedule_appointment', data),
    completeAppointment: (data) => axios.post('therapist/complete_appointment', data),
    getProfile: () => axios.get('/therapist/profile'),
    fetchConcerningIssues: () => axios.get('/therapist/get-concerning-issues'),
    fetchSubConcerningIssues: (concerning_issue_id) =>
      axios.get(`/therapist/get-sub-concerning-issues?concerning_issue_id=${concerning_issue_id}`),
    getFeedbacks: (therapist_id) => axios.get(`/therapist/therapist_feedbacks?therapist_id=${therapist_id}`),
    getSpecialisms: () => axios.get('/therapist/specialism'),
    getTools: () => axios.get('/therapist/tools'),
    updateProfile: (data) => axios.post('/therapist/profile', data),
    updateSpecialism: (option) => axios.post(`/therapist/specialism?specialism_id=${option}`),
    deleteSpecialism: (specialism_id) => axios.post('/therapist/specialism/delete', { specialism_id }),
    updateTools: (option) => axios.post(`/therapist/tools?tool_id=${option}`),
    deleteTool: (tool_id) => axios.post('/therapist/tools/delete', { tool_id }),
    setOtherToolsOrSpecialisms: (option) => axios.post(`/therapist/others`, option),
    setOnlineStatus: (option) => axios.post(`/therapist/online_status?online_status=${option}`),
    getScheduleList: (status = null) => axios.get(`/therapist/schedule${status ? `?status=${status}` : ''}`),
    addSchedule: (data) => axios.post('/therapist/schedule', data),
    deleteScheduledSlot: (slot_id) => axios.post(`/therapist/schedule/delete?slot_id=${slot_id}`),
    getPublicTherapistProfile: (id) => axios.get(`/therapist/public_profile?therapist_id=${id}`),
    activeChatRooms: () => axios.get('/chatroom/activechatrooms'),
    getCommission: () => axios.get('/therapist/commission'),
    getPatientProfile: (id) => axios.get(`/therapist/patient_profile?patient_id=${id}`),
    getPatientStats: (id, start_date = null, end_date = null) =>
      axios.get(
        `patient/stats?patient_id=${id}${start_date ? `&start_date=${start_date}` : ''}${
          end_date ? `&end_date=${end_date}` : ''
        }`,
      ),
    getBankDetails: () => axios.get('/therapist/bank_details'),
    saveBankDetails: (data) => axios.post('/therapist/save_bank_details', data),
    getPatientMood: (patientId) => axios.get(`/patient/daily_mood?patient_id=${patientId}`),
    copySchedule: (data) => axios.post('/therapist/schedule/copy', data),
    getProfessionalTypes: () => axios.get('/profession_types'),
    getProfessionalType: (id) => axios.get(`/profession_types?type_id=${id}`),
  },
  company: {
    getProfile: () => axios.get('/company/profile'),
    updateProfile: (data) => axios.post('/company/profile', data),
    createUser: (data) => axios.post('/company/create_user', data),
    getAdminUser: () => axios.get('/company/users'),
    getStats: () => axios.get('/company/dashboard'),
    getAuthDetails: () => axios.get('/company/authentication_details'),
    subscriptionStatus: () => axios.post('/company/subscription_status'),
    getCompanyDepartments: (companyId) => axios.get(`/company_departments?company_unique_id=${companyId}`),
    confirmInvitation: (data) => axios.post('/company/confirm-invitation', data),
    getEmployeesList: () => axios.get('/company/employees'),
  },
  // If method is POST, we can pas body to request, else it'll be undefined and ignored
  videoRoomRequest: (url, method, data = undefined) => dailyCoConfigInstance(dailyCoConfig(url, method, data)),
  general: {
    getSpecialism: () => axios.get('/specialism'),
    getTools: () => axios.get('/tools'),
    cancelAppointments: (id) => axios.post('/appointment/cancel', { appointment_id: id }),
  },
  others: {
    getCountriesList: () => axios.get('/country'),
    getCitiesList: (countryID) => axios.get(`/city?country_id=${countryID}`),
    contactSupport: (data) => axios.post('/contactus', data),
    lastPageVisit: () => axios.get('/last_page_visit'),
    getLocation: async () => {
      const res = await fetch('http://ip-api.com/json/?fields=country,status');
      return await res.json();
    },
    toggleTwoFactor: (data) => axios.post('/two-factor', data),
    clientError: (data) => axios.post('/create_logs', data),
  },
  youtube: {
    getPlaylist: async (key, maxResult, playListId) => {
      const res = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?key=${key}&part=snippet&maxResults=${maxResult}&playlistId=${playListId}`,
      );
      return await res.json();
    },
  },
  payment: {
    getPlans: (currency, subdomain) => axios.post(`/payment/packages`, { currency, subdomain }),
    getPlan: (id) => axios.get(`/payment/plan?plan_id=${id}`),
    purchasePlan: (id, successUrl, cancelUrl) =>
      axios.post('/payment/purchase-plan', { plan_id: id, success_url: successUrl, cancel_url: cancelUrl }),
    switchPlan: (id) => axios.post('/payment/switch-plan', { plan_id: id }),
    purchaseVideoAddon: (videoQuantity, successUrl, cancelUrl) =>
      axios.post('/payment/purchase-video', {
        video_quantity: videoQuantity,
        success_url: successUrl,
        cancel_url: cancelUrl,
      }),
    cancelSubscription: () => axios.post('/payment/cancel-subscription'),
  },
  klaviyo: {
    subscribePatient: ({ email, first_name, last_name, mobile, referal, country }) =>
      axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache',
        },
        url:
          'https://manage.kmail-lists.com/ajax/subscriptions/subscribe?api_key=pk_642a485579e89ee0786930075f59f79404',
        data: new URLSearchParams({
          g: 'XmkAED',
          $fields: 'referal',
          email,
          first_name,
          last_name,
          phone_number: mobile,
          referal,
          country,
        }),
      }),
    subscribeTherapist: ({ email, first_name, last_name, mobile, referal, country }) =>
      axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache',
        },
        url:
          'https://manage.kmail-lists.com/ajax/subscriptions/subscribe?api_key=pk_642a485579e89ee0786930075f59f79404',
        data: new URLSearchParams({
          g: 'Rk5sja',
          $fields: 'referal',
          email,
          first_name,
          last_name,
          phone_number: mobile,
          referal,
          country,
        }),
      }),
  },
  welcome: {
    startQuiz: () => axios.post('/patient/startquiz'),
    submitQuizAnswer: (quizJourney, questionID, selectedAnswerIDs) =>
      axios.post(
        `/patient/startquiz?quiz_journey=${quizJourney}&question_id=${questionID}&answer_id=[${selectedAnswerIDs}]`,
      ),
    questionnaireGoBack: (quizJourney) => axios.post(`/patient/back_questionnaire?quiz_journey=${quizJourney}`),
    createChatroom: (therapist_id, profession_id) => axios.post(`/chatroom/create_chatroom`, { therapist_id, profession_id }),
    verifyPartner: (subdomain) => axios.post(`/verify_partner`, { subdomain }),
    getPackage: (payload) => axios.post(`/payment/packages`, payload),
  },
  gymPass: {
    signup: (data) => axios.post('/gympass/complete_signup', data),
    carouselItems: async () => {
      try {
        const res = await fetch('https://supportroom.com/wp-json/v2/slider-items/7');
        return await res.json();
      } catch (err) {
        return err;
      }
    },
  },
  teams: {
    getAuthCode: () => axios.get('/teams/authorization_link'),
    teamsToken: (code) => axios.get(`/teams/token?code=${code}`),
    createTeamsChat: (chatroom_id) => axios.post(`/teams/create_teams_chat?chatroom_id=${chatroom_id}`),
  },
};
