import React from 'react';
import Styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '..';
import { useTranslation } from 'react-i18next';

function EmergencyBtn() {
  const { t } = useTranslation();
  return (
    <StyledEmergencyBtn>
      <EmergencyContent>
        <Tooltip
          id="emergency"
          text={t("emergencyBtnTooltip")}
          place="top"
        >
          <Button as={Link} variant="danger" to="/help">
            {t("emergencyBtn")}?
          </Button>
        </Tooltip>
      </EmergencyContent>
    </StyledEmergencyBtn>
  );
}

const EmergencyContent = Styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
`;

const StyledEmergencyBtn = Styled.div`
  height: 0;
  display: none;
  margin-left: 50px;

  ${({ theme }) => theme.md`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `};
`;
StyledEmergencyBtn.displayName = 'StyledEmergencyBtn';
export default EmergencyBtn;
