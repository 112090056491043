import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addTherapyJourney, getActiveChatRooms, getTherapyJourney } from '../../../Actions/chatroom.actions';

import { Heading } from '../../Atoms';
// import TherapyFeedback from './therapyFeedback';
import { getActiveStage } from '../../../Selectors/therapyTracker.selector';
import { getConcernigIssues } from '../../../Actions/therapist.actions';

const TherapyTrackerTable = ({ chatRoomID, therapyJourney, role, activeStage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { roomID } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (!chatRoomID) {
      if (role === 'therapist' && roomID) {
        dispatch(getActiveChatRooms(role, parseInt(roomID))).then(() => {
          dispatch(getTherapyJourney(roomID, role));
          dispatch(getConcernigIssues());
        });
      } else if (role === 'patient') {
        dispatch(getActiveChatRooms(role)).then((res) => {
          dispatch(getTherapyJourney(res[0]?.chatroom_id, role));
        });
      }
    } else {
      dispatch(getTherapyJourney(chatRoomID, role));
      dispatch(getConcernigIssues());
    }
  }, [chatRoomID, role, roomID, dispatch, history]);

  const getStageStatus = useCallback((activeState, index) => {
    if (activeState[0] === index) return 'current';
    else if (activeState[0] > index) return 'complete';

    return '';
  }, []);

  const getSubStageStatus = useCallback((activeState, stageIndex, subStageIndex) => {
    if (activeState[0] === stageIndex) {
      if (activeState[1] === subStageIndex) return 'current';
      else if (activeState[1] > subStageIndex) return 'complete';
    } else if (activeState[0] > stageIndex) return 'completed-stage-step';

    return '';
  }, []);

  const viewNotes = useCallback(
    (stageId, subStageId) => history.push(`/t/therapy-journey/${chatRoomID}/${stageId}/${subStageId}`),
    [history, chatRoomID],
  );

  const markAsComplete = useCallback(
    ({ stageId, subStageId, isLastStage, isLastSubStage, nextStageId, nextSubStageId }) => {
      if (isLastStage && isLastSubStage) return;

      const getStageId = () => {
        if (!isLastSubStage) return stageId;
        else {
          if (isLastStage) return stageId;
          else return nextStageId;
        }
      };

      const getSubStageId = () => {
        if (!isLastStage) return nextSubStageId;
        else if (isLastStage && !isLastSubStage) return nextSubStageId;
      };

      dispatch(
        addTherapyJourney({
          stage_id: getStageId(),
          sub_stage_id: getSubStageId(),
          chatroom_id: chatRoomID,
          template_id: 1,
        }),
      );
    },
    [chatRoomID, dispatch],
  );

  const jumpToStage = useCallback(
    (stageId, subStageId) =>
      dispatch(
        addTherapyJourney({
          stage_id: stageId,
          sub_stage_id: subStageId,
          chatroom_id: chatRoomID,
          template_id: 1,
        }),
      ),
    [chatRoomID, dispatch],
  );

  const getNextSubStageId = useCallback((isLastSubStage, isLastStage, subStageIndex, stageIndex, stage, journey) => {
    if (!isLastSubStage) return stage.sub_stages[subStageIndex + 1].sub_stage_id;
    else {
      if (!isLastStage) return journey.data[stageIndex + 1]?.sub_stages[0]?.sub_stage_id;
      else return journey.data[stageIndex]?.sub_stages[0]?.sub_stage_id;
    }
  }, []);

  const journey = useMemo(() => {
    if (therapyJourney.data?.length) {
      // Build the StageComponent
      return therapyJourney.data.map((stage, stageIndex) => {
        const stageStatus = getStageStatus(activeStage, stageIndex);
        const isLastStage = therapyJourney.data.length === stageIndex + 1;
        const nextStageId = !isLastStage ? therapyJourney.data[stageIndex + 1].id : stage.id;

        return (
          <Stage key={stage.id} status={stageStatus}>
            <StepNumber>
              <Number status={stageStatus}>{stageIndex + 1}</Number>
              <Line />
            </StepNumber>
            <StepDetails>
              <StageHeading>
                <StyledHeading level={4} text={stage.stage_name} />
              </StageHeading>
              {stage?.sub_stages?.length &&
                stage.sub_stages.map((subStage, subStageIndex) => {
                  const status = getSubStageStatus(activeStage, stageIndex, subStageIndex);
                  const isLastSubStage = stage.sub_stages.length === subStageIndex + 1;
                  const nextSubStageId = getNextSubStageId(
                    isLastSubStage,
                    isLastStage,
                    subStageIndex,
                    stageIndex,
                    stage,
                    therapyJourney,
                  );
                  return (
                    <StageStep key={subStage.sub_stage_id} role={role} status={status}>
                      <span>{subStage.sub_stage_name}</span>
                      {isLastSubStage && isLastStage
                        ? null
                        : role === 'therapist' && (
                            <SubStageActions>
                              {status === 'current' ? (
                                <Action
                                  onClick={() =>
                                    markAsComplete({
                                      stageId: stage.id,
                                      subStageId: subStage.sub_stage_id,
                                      isLastStage,
                                      isLastSubStage,
                                      nextStageId,
                                      nextSubStageId,
                                    })
                                  }
                                >
                                  {t("markAsCompleted")}
                                </Action>
                              ) : null}
                              {status !== 'current' && (
                                <Action status={status} onClick={() => jumpToStage(stage.id, subStage.sub_stage_id)}>
                                {t("jumpTo")}
                                </Action>
                              )}
                              {subStage.notes?.length ? (
                                <Action status={status} onClick={() => viewNotes(stage.id, subStage.sub_stage_id)}>
                                {t("viewNotes")}
                                </Action>
                              ) : null}
                            </SubStageActions>
                          )}
                    </StageStep>
                  );
                })}
            </StepDetails>
          </Stage>
        );
      });
    }

    return <div>{t("noTherapyData")}</div>;
  }, [
    role,
    therapyJourney,
    activeStage,
    getStageStatus,
    getSubStageStatus,
    viewNotes,
    getNextSubStageId,
    jumpToStage,
    markAsComplete,
  ]);

  return (
    <>
      {/* {therapyJourney.submitFeedback || therapyJourney.submitFinalFeedback ? (
        <TherapyFeedbackWrapper>
          <TherapyFeedback isFinal={therapyJourney.submitFinalFeedback} chatRoomId={chatRoomID} />
        </TherapyFeedbackWrapper>
      ) : (
        <JourneyWrapper>{journey}</JourneyWrapper>
      )} */}
      <JourneyWrapper>{journey}</JourneyWrapper>
    </>
  );
};

// const TherapyFeedbackWrapper = styled.div`
//   height: calc(100% - 64px);
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// `;

const StageHeading = styled.div`
  background-color: #9179a4;
  padding: ${({ theme }) => theme.spacingSm};

  ${({ theme }) => theme.md`
    padding: ${({ theme }) => theme.spacingSm} ${({ theme }) => theme.spacingMd};
  `}
`;
StageHeading.displayName = 'StageHeading';

const Stage = styled.div`
  display: flex;

  ${({ status }) =>
    status === 'complete' &&
    css`
      ${StageHeading} {
        color: #00624e;
        background-color: #00c8a0;
        border-color: #9ef2e1;
      }
    `}

  &:first-of-type ${StageHeading} {
    border-radius: 8px 8px 0 0;
  }
`;
Stage.displayName = 'Stage';

const StepNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing};

  ${({ theme }) => theme.md`
    margin-right: ${({ theme }) => theme.spacingLg};
  `};
`;
StepNumber.displayName = 'StepNumber';

const Number = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.greyMedium};
  color: ${({ theme }) => theme.white};
  font-weight: ${({ theme }) => theme.fontBold};

  ${({ theme }) => theme.md`
    font-size: 25px;
    width: 50px;
    height: 50px;
  `}

  ${({ status }) =>
    status === 'complete' &&
    css`
      color: ${({ theme }) => theme.white};
      background-color: #00c8a0;
      border-color: #9ef2e1;
    `}

  ${({ status }) =>
    status === 'current' &&
    css`
      color: ${({ theme }) => theme.white};
      background-color: #9279a5;
      border-color: #9ef2e1;
    `}
`;
Number.displayName = 'Number';

const Line = styled.div`
  flex-grow: 1;
  width: 3px;
  background-color: ${({ theme }) => theme.greyMedium};
  margin: ${({ theme }) => theme.spacing} 0;

  ${({ theme }) => theme.md`
    width: 5px;
  `};
`;
Line.displayName = 'Line';

const StepDetails = styled.div`
  flex-grow: 1;
`;
StepDetails.displayName = 'StepDetails';

const StageStep = styled.div`
  font-weight: ${({ theme }) => theme.fontMedium};
  font-size: 13px;
  background-color: #faf3ff;
  border: 1px solid ${({ theme }) => theme.primaryAlpha10};
  border-top: 0;
  padding: ${({ theme }) => theme.spacingSm};

  ${({ role, theme }) =>
    role === 'therapist' &&
    css`
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: ${theme.spacingSm};

      > span:first-child {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        ${theme.md`
        display: inline;
        overflow: visible;
        text-overflow: initial;
      `};
      }
    `};

  ${({ theme }) => theme.md`
    padding: ${({ theme }) => theme.spacingSm} ${({ theme }) => theme.spacing};
    font-size: 17px;
  `}

  &:last-of-type {
    border-bottom: 0;
  }

  ${({ status }) =>
    status === 'complete' &&
    css`
      color: #00624e;
      background-color: #dbfff8;
      border-color: #9ef2e1;
    `}

  ${({ status }) =>
    status === 'current' &&
    css`
      color: #00624e;
      background-color: #f1e1fd;
      border-color: ${({ theme }) => theme.primaryAlpha10};
    `}

  ${({ status }) =>
    status === 'completed-stage-step' &&
    css`
      color: #00624e;
      background-color: #dbfff8;
      border-color: #9ef2e1;
    `}
`;
StageStep.displayName = 'StageStep';

const JourneyWrapper = styled.div`
  margin: 20px 0;

  ${Stage} {
    &:last-child {
      ${StepDetails} {
        ${StageStep} {
          &:last-child {
            border-radius: 0 0 8px 8px;
          }
        }
      }
    }
  }
`;

const Action = styled.span`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryMedium};

  ${({ status }) =>
    status === 'current' &&
    css`
      color: ${({ theme }) => theme.secondary};
    `}
`;

const SubStageActions = styled.div`
  display: flex;

  ${Action} {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.white};
  margin: 0;
`;
StyledHeading.displayName = 'StyledHeading';

const mapStateToProps = (store) => ({
  chatRoomID: store.chatroom.chatRoomID,
  therapyJourney: store.chatroom.therapyJourney,
  activeStage: getActiveStage(store),
  role: store.auth.role,
});

TherapyTrackerTable.propTypes = {
  therapyJourney: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        active: PropTypes.number,
        stage_name: PropTypes.string,
        sub_stages: PropTypes.arrayOf(
          PropTypes.shape({
            sub_stage_id: PropTypes.number,
            sub_stage_name: PropTypes.string,
            active: PropTypes.number,
            notes: PropTypes.arrayOf(PropTypes.shape({})),
          }),
        ),
      }),
    ),
  }),
};

export default connect(mapStateToProps)(TherapyTrackerTable);
