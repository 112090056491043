import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Heading, FormGroup, Button, HelpText } from '../../Components/Atoms';
import { AuthLayout } from '../../Layout';
import api from '../../Util/api';
import { useTranslation } from 'react-i18next';

const Step2 = () => {
  const { t } = useTranslation();
  const nextStep = () => history.push('/c/signup/step-3');
  const history = useHistory();

  const handleOnSubmit = (values, actions) => {
    api.company
      .updateProfile(values)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.error(error);
      });

    nextStep();
  };

  return (
    <>
      <AuthLayout>
        <Heading>{t("companyDetails")}</Heading>
        {/* <p>Your expertise will help us to match you with the most relevant patients. Please select all that apply.</p> */}
        <Wrapper>
          <Formik
            initialValues={{
              // no_of_empoyees: '',
              country_id: 186,
              address1: '',
              address2: '',
              post_code: '',
              invitation_password: '',
            }}
            onSubmit={handleOnSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <FormGroup>
                  <Field
                    id="no_of_empoyees"
                    name="no_of_empoyees"
                    placeholder={t("numberOfEmployees")}
                    type="number"
                    className={'form-control' + (errors.no_of_empoyees && touched.no_of_empoyees ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="no_of_empoyees" component="div" className="invalid-feedback" />
                </FormGroup>

                <FormGroup>
                  <Field
                    id="address1"
                    name="address1"
                    placeholder={t("addressLine1")}
                    type="text"
                    className={'form-control' + (errors.address1 && touched.address1 ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="address1" component="div" className="invalid-feedback" />
                </FormGroup>

                <FormGroup>
                  <Field
                    id="address2"
                    name="address2"
                    placeholder={t("addressLine2")}
                    type="text"
                    className={'form-control' + (errors.address2 && touched.address2 ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="address2" component="div" className="invalid-feedback" />
                </FormGroup>

                <FormGroup>
                  <Field
                    id="post_code"
                    name="post_code"
                    placeholder={t("postCode")}
                    type="text"
                    className={'form-control' + (errors.post_code && touched.post_code ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="post_code" component="div" className="invalid-feedback" />
                </FormGroup>

                <FormGroup>
                  <Field
                    id="invitation_password"
                    name="invitation_password"
                    placeholder={t("invitationPassword")}
                    type="text"
                    className={
                      'form-control' + (errors.invitation_password && touched.invitation_password ? ' is-invalid' : '')
                    }
                  />
                  <HelpText>
                  {t("step2Text")}
                  </HelpText>
                  <ErrorMessage name="invitation_password" component="div" className="invalid-feedback" />
                </FormGroup>

                <Button type="submit">{t("continue")}</Button>
              </Form>
            )}
          </Formik>
        </Wrapper>
      </AuthLayout>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`;

export default Step2;
