import React, { useMemo } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import Typing from '../../../Components/Molecules/Chatroom/Typing';

const PatientList = () => {
  const rooms = useSelector((store) => store.chatroom.rooms);
  const unreadMessages = useSelector((store) => store.chatroom.unreadMessages);
  const unreadMessageMap = useMemo(() => {
    const unreadMessageMap = new Map();
    if (rooms) rooms.forEach(({ chatroom_id }) => unreadMessageMap.set(chatroom_id, { count: 0, timestamp: 0 }));
    if (unreadMessages)
      unreadMessages.messages.forEach(({ chatroom_id, sent_at, read_status }) => {
        const data = unreadMessageMap.get(chatroom_id);
        if (data) {
          unreadMessageMap.set(chatroom_id, {
            count: read_status === 0 ? data.count + 1 : data.count,
            timestamp: data.timestamp < new Date(sent_at).getTime() ? new Date(sent_at).getTime() : data.timestamp,
          });
        }
      });
    return unreadMessageMap;
  }, [rooms, unreadMessages]);
  const sortByUnread = useMemo(() => {
    return [...unreadMessageMap.values()].some(({ count }) => count > 0);
  }, [unreadMessageMap]);
  const sortedChatRooms = useMemo(() => {
    if (!sortByUnread) return rooms;
    return cloneDeep(rooms).sort((a, b) => {
      return unreadMessageMap.get(b.chatroom_id).timestamp - unreadMessageMap.get(a.chatroom_id).timestamp;
    });
  }, [rooms, unreadMessageMap, sortByUnread]);

  return (
    sortedChatRooms.length && (
      <ListWrapper>
        {sortedChatRooms.map((patient) => (
          <ListItem key={patient.patient_id} to={`/t/chatroom/${patient.chatroom_id}`} exact={true}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>

              <span style={{ color: patient.payment_status !== 'paid' ? '#838383' : 'initial' }}>
                {patient.patient_full_name}
              </span>
              {unreadMessageMap.has(patient.chatroom_id) && unreadMessageMap.get(patient.chatroom_id).count ? (
                <MessageCount>{unreadMessageMap.get(patient.chatroom_id).count}</MessageCount>
              ) : null}
              {patient.payment_status !== 'paid' && <NotPaid>{patient.payment_status.toUpperCase()}</NotPaid>}
            </div>
            <Typing
              message={`${patient?.patient_full_name} is typing...`}
              roomId={patient?.chatroom_id}
              color={"#666"}
            />
          </ListItem>
        ))}
      </ListWrapper>
    )
  );
};

const NotPaid = styled.span`
  padding: 5px;
  background: #838383;
  color: white;
  font-size: 9px;
  border-radius: 5px;
`;

const ListWrapper = styled.div`
  border-right: ${(props) => (props.mobile ? '0' : `1px solid ${props.theme.primaryMedium}`)};
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  overflow: auto;
`;

const ListItem = styled(NavLink)`
  display: flex;
  flex-direction:column;
  height: 64px;
  padding: ${({ theme }) => theme.spacingMd} ${({ theme }) => theme.spacing};
  border-bottom: 1px solid ${({ theme }) => theme.greyVeryLight};
  margin: 0;
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fontBold};

  &:hover {
    background-color: ${({ theme }) => theme.greyVeryLight};
  }

  &.active {
    background-color: ${({ theme }) => theme.primaryLight};
  }
`;

const MessageCount = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  background-color: ${({ theme }) => theme.primaryMedium};
  color: ${({ theme }) => theme.white};
  border-radius: 100%;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontNormal};
`;
MessageCount.displayName = 'MessageCount';

export default PatientList;
