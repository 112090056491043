import React from 'react';
import styled from 'styled-components';
import { AuthLayout } from '../../Layout';
import { LoginForm } from '../../Components/Organisms';
import { Redirect } from '../../Util';
import { useHistory } from 'react-router-dom';

const Login = (props) => {
  const LoginInfo = localStorage.getItem('loginInfo');
  const LoggedIn = !!LoginInfo;
  const history = useHistory();

  if (LoginInfo && JSON.parse(LoginInfo).role !== 'company') {
    history.push('/login');
    return false;
  }

  return (
    <>
      <AuthLayout>
        <Wrapper>
          {LoggedIn ? (
            <Redirect url="/c/dashboard" />
          ) : (
            <>
              <LoginForm userType="company" redirect="/c/dashboard" heading="Company Login" {...props} />
            </>
          )}
        </Wrapper>
      </AuthLayout>
    </>
  );
};

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;
export default Login;
