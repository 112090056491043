import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
  deleteSpecialism,
  deleteTool,
  getSpecialism,
  getSpecialisms,
  getTherapistTools,
  getTools,
  updateSpecialism,
  updateTools,
} from '../../../Actions/therapist.actions';

import { Alert, Button, FormGroup, Label, Select } from '../../../Components/Atoms';

const Expertise = () => {
  const [specialisms, setSpecialisms] = useState([]);
  const [initialSpecialisms, setInitialSpecialisms] = useState([]);
  const [tools, setTools] = useState([]);
  const [initialTools, setInitialTools] = useState([]);
  const [responseMessage, setResponseMessage] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updateTherapyItems = async (data, initialData, updateHandler, deleteHandler) => {
    const newItems = data.filter((item) => !initialData.find((initial) => initial.id === item.id));
    const removedItems = initialData.filter((initial) => !data.find((item) => initial.id === item.id));
    for (const { id } of newItems) {
      await dispatch(updateHandler(id));
    }
    for (const { id } of removedItems) {
      await dispatch(deleteHandler(id));
    }
  };

  const handleOnSubmit = async ({ therapy_specialisms, therapy_tools }, { setSubmitting }) => {
    setLoading(true);
    try {
      await updateTherapyItems(therapy_specialisms, initialSpecialisms, updateSpecialism, deleteSpecialism);
      await updateTherapyItems(therapy_tools, initialTools, updateTools, deleteTool);
      setResponseMessage({ type: 'primary ', message: 'Therapist Profile Updated Successfully' });
    } catch (err) {
      console.error(err);
      setResponseMessage({ type: 'danger', message: 'Something went wrong. Try again later.' });
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const getTherapistData = useCallback(async () => {
    setSpecialisms((await dispatch(getSpecialism())).data);
    setTools((await dispatch(getTools())).data);
    const specialisms = (await dispatch(getSpecialisms()))?.data || [];
    const initialSpecialisms = specialisms.map(({ specialism_id, specialism_name }) => ({
      id: specialism_id,
      name: specialism_name,
    }));
    setInitialSpecialisms(initialSpecialisms);
    const tools = (await dispatch(getTherapistTools()))?.data || [];
    const initialTools = tools.map(({ tool_name, tool_id }) => ({ id: tool_id, name: tool_name }));
    setInitialTools(initialTools);
  }, [dispatch]);

  useEffect(() => {
    getTherapistData();
  }, [getTherapistData]);

  const validationSchema = Yup.object().shape({
    therapy_specialisms: Yup.array().min(1, 'Select at least 1 therapy specialism'),
    therapy_tools: Yup.array().min(1, 'Select at least 1 therapy tool'),
  });

  return (
    <Formik
      initialValues={{
        therapy_specialisms: initialSpecialisms,
        therapy_tools: initialTools,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <StyledFormGroup>
            <Label htmlFor="therapy_specialisms">Therapy Specialisms</Label>
            <Field
              as={Select}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={specialisms}
              id="therapy_specialisms"
              name="therapy_specialisms"
              placeholder=""
              isMulti
              onChange={(data) => setFieldValue('therapy_specialisms', data || [])}
            />
            <ErrorMessage name="therapy_specialisms" component="div" className="invalid-feedback" />
          </StyledFormGroup>

          <StyledFormGroup>
            <Label htmlFor="therapy_tools">Therapy Tools</Label>
            <Field
              as={Select}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={tools}
              id="therapy_tools"
              name="therapy_tools"
              placeholder=""
              isMulti
              onChange={(data) => setFieldValue('therapy_tools', data || [])}
            />
            <ErrorMessage name="therapy_tools" component="div" className="invalid-feedback" />
          </StyledFormGroup>

          {responseMessage.type && (
            <StyledAlert className={responseMessage.type}>{responseMessage.message}</StyledAlert>
          )}

          <Button type="submit" loading={loading} disabled={loading}>
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: ${({ theme }) => theme.spacing};
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};
`;
StyledFormGroup.displayName = 'StyledFormGroup';

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing};
`;
StyledAlert.displayName = 'StyledAlert';

export default Expertise;
