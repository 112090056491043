import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading } from '../../Atoms';
import { Link } from 'react-router-dom';
import { TherapyTrackerSidebar } from '../index';

import { ReactComponent as IconTracker } from '../../../Assets/Icons/Tracker.svg';
import { ReactComponent as IconUpgrade } from '../../../Assets/Icons/Upgrade.svg';
import { ReactComponent as NotificationIcon } from '../../../Assets/Icons/Notification.svg';
import { ReactComponent as IconPlay } from '../../../Assets/Icons/Play.svg';
// import VideoIcon from '../../../Assets/Icons/video-tutorial.png';
import Tooltip from '../../Atoms/Tooltip/Tooltip';
import { useSelector } from 'react-redux';
import TrainingVideo from '../TrainingVideo';
import { NavigateHelper } from '../../Molecules';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TherapyTools = ({ heading, links, isChatRoomActive, setModalState }) => {
  const { t } = useTranslation();
  const role = useSelector((store) => store.auth.role);
  const profile = useSelector((store) => store.user?.profile);
  const subscription = useSelector((store) => store.user.subscription) || {};
  const { remaining_video_calls = 0 } = subscription;
  const showVideoCallIcon = remaining_video_calls > 0;
  const [showVideo, setShowVideoFlag] = useState(false);
  const isB2b = useSelector((store) => store.user?.profile?.['B2B'] !== 0);
  const myBookedEvents = useSelector((store) => store.chatroom?.myBookedEvents);

  const futureBookedEventsCount = useMemo(() => {
    if (myBookedEvents) {
      const currentDate = moment();
      return myBookedEvents.filter(
        (slot) =>
          slot.appointment_type === 'video' &&
          currentDate.isBefore(moment(`${slot.schedule_date} ${slot.schedule_time}`, 'YYYY-MM-DD HH:mm:ss')),
      ).length;
    }

    return null;
  }, [myBookedEvents]);

  const isAllowedForBooking = useMemo(() => {
    return showVideoCallIcon || futureBookedEventsCount;
  }, [futureBookedEventsCount, showVideoCallIcon]);

  return (
    <>
      <Heading level={3}>{heading}:</Heading>
      <TherapyTrackerSidebar
        customBurgerIcon={
          <StyledLink to="#">
            <StyledIcon>
              <IconTracker />
            </StyledIcon>
            {t('therapyTracker')}
          </StyledLink>
        }
      />
      {links.map((link) => {
        return (
          <NavigateHelper
            key={link.id}
            link={link}
            isChatRoomActive={isChatRoomActive}
            showVideoCallIcon={isAllowedForBooking}
            profile={profile}
            isB2b={isB2b}
          />
        );
      })}
      {/*<StyledLink key={`tutorial`} onClick={() => setModalState(true)}>*/}
      {/*  <StyledIconUpgrade>*/}
      {/*    <img alt="Video" src={VideoIcon} style={{ width: '22px' }} />*/}
      {/*  </StyledIconUpgrade>*/}
      {/*  <span>Tutorial</span>*/}
      {/*</StyledLink>*/}
      {role === 'patient' && profile && !profile.B2B && (
        <StyledLink key={`video-ap-2`} to={'/account/subscription/manage'}>
          <StyledIconUpgrade>
            <IconUpgrade />
          </StyledIconUpgrade>
          <span>{t('upgradeSubscription')}</span>
        </StyledLink>
      )}
      <StyledLink
        key={`notifications`}
        to={role === 'therapist' ? '/t/dashboard/notifications-settings' : '/account/notifications'}
      >
        <StyledButtonLink
          style={role === 'therapist' ? { backgroundColor: '#DFFFF9' } : {}}
          to={role === 'therapist' ? '/t/dashboard/notifications-settings' : '/account/notifications'}
        >
          <Tooltip id="notificationSidebarIcon" text={t('notifications')} place="bottom" offset={{ bottom: 10 }}>
            <NotificationIcon />
          </Tooltip>
        </StyledButtonLink>
        <span>{t('notifications')}</span>
      </StyledLink>
      {role === 'patient' && isB2b && (
        <StyledLink key="trainingVideo" onClick={(e) => setShowVideoFlag(!showVideo)}>
          <StyledIconUpgrade>
            <IconPlay />
          </StyledIconUpgrade>
          <span>{t('trainingVideo')}</span>
          {showVideo && <TrainingVideo showVideo={showVideo} setShowVideoFlag={setShowVideoFlag} />}
        </StyledLink>
      )}
    </>
  );
};

const StyledLink = styled(Link)`
  display: flex;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.primary};
  margin-bottom: ${({ theme }) => theme.spacing};
  text-decoration: none;
  align-items: center;
`;
StyledLink.displayName = 'StyledLink';

const StyledIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryLight};
  width: 46px;
  height: 46px;
  border-radius: 100%;
  margin-right: ${({ theme }) => theme.spacing};

  svg {
    fill: ${({ theme }) => theme.primary};
  }
`;
StyledIcon.displayName = 'StyledIcon';

const StyledIconUpgrade = styled(StyledIcon)`
  svg {
    width: 20px;
  }
`;

const StyledButtonLink = styled(Link)`
  /* margin-right: ${({ theme }) => theme.spacingMd}; */
  width: 46px;
  height: 46px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  background: #faf3ff;

  svg {
    width: 18px;
    fill: ${({ theme }) => theme.primary};
  }
`;
StyledButtonLink.displayName = 'StyledButtonLink';

TherapyTools.propTypes = {
  heading: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isChatRoomActive: PropTypes.bool,
};

export default TherapyTools;
