import * as Yup from 'yup';
import { phoneRegExp } from './regExp';

export const passwordValidation = (t) => Yup.string()
  .min(6, t("passwordMustBeLeast6Characters"))
  .max(20, t("characterallowed"))
  .matches(/(.*\d.*)/, t("passwordContainoneNumericChar"))
  .matches(/(?=.*[A-Z])/, t("passwordContainOneUpperCaseChar"))
  .required(t("passwordIsRequired"));

export const confirmPassword = (t) => Yup.string()
  .oneOf([Yup.ref('password'), null], t("passwordsMustMatch"))
  .required(t("confirmPasswordIsRequired"));

export const mobilePhoneValidation = (t) => Yup.string()
  .min(10, t("mobileNumberMinimum10DigitsAllowed"))
  .matches(phoneRegExp, t("mobileNumberNumericValues"))
  .required(t("mobileNumberRequired"));
