import React from 'react';
import styled from 'styled-components';

const ModalOverlay = (props) => {
  return <Wrapper>{props.children}</Wrapper>;
};
const Wrapper = styled.div`
  background: rgb(73, 31, 105);
  background: linear-gradient(
    180deg,
    rgba(73, 31, 105, 0.9136029411764706) 0%,
    rgba(51, 200, 160, 0.9220063025210083) 100%
  );
  height: 100vh;
  width: 100%;
  max-width: ;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 50px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export default ModalOverlay;
