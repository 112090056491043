import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import TimeLeft from '../../../Assets/Icons/TimeLeft.svg';
import TimeLeftRed from '../../../Assets/Icons/TimeLeftRed.svg';

const VideoCallTimer = ({ slots }) => {

  const initialTime = slots * 30 * 60;

  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  useEffect(() => {
    if (slots <= 2 && initialTime > 0) {
      setIsTimerRunning(true);
    }
  }, [slots, initialTime]);

  useEffect(() => {
    if (isTimerRunning && timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isTimerRunning, timeRemaining]);

  const showTimer = timeRemaining <= 600; // show timer from 5 minutes

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      {showTimer && (
        <TimerButton isRed={timeRemaining <= 300} 
        title='Video call time remaining'
        >
          <img src={timeRemaining <= 300 ? TimeLeftRed : TimeLeft} alt="" /> {formatTime(timeRemaining)}
        </TimerButton>
      )}
    </div>
  );
};

const TimerButton = styled.div`
  color: ${({ theme, isRed }) => isRed ? '#f00' : theme.primaryMedium} !important;
  margin-right: ${({ theme }) => theme.spacingSm};
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  min-width: auto;
  background: transparent;
  border: 1px solid ${({ theme, isRed }) => isRed? '#f00': theme.primaryMedium} !important;
  border-radius: 5px;
  display: flex;
  gap:10px !important;

  img {
    vertical-align: middle;
    width: 24px;
    ${({ theme }) => theme.sm`
    width: 18px;
   `}
  }
`;
export default memo(VideoCallTimer);
