import React, { useMemo, useState } from 'react';
import VideoRecorder from './video-recorder';
import { Loading, ModalComponent } from '../Atoms';
import { useWindowSize } from '../../Hooks';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getMessages, sendFile } from '../../Actions/chatroom.actions';
const VideoRecorderWrapper = ({ setOpenModal, chatRoomID, pageLimit, onSendFile }) => {
  const { width } = useWindowSize();
  const isMobile = useMemo(() => width < 768, [width]);
  const [uploadingVdo, setUploadingVdo] = useState(false);
  const [recordedVideoBlob, setRecordedVideoBlob] = useState('');
  const dispatch = useDispatch();

  const onRecordingComplete = (fixedVideoBlob) => {
    setRecordedVideoBlob(fixedVideoBlob);
  };

  const handleVdoUpload = async (vdoFile) => {
    const date = moment().format('YYYY-MM-DD_HH-mm-ss');
    setUploadingVdo(true);
    const formData = new FormData();
    formData.append('file', vdoFile, `video-${date}.webm`);

    dispatch(sendFile(chatRoomID, formData))
      .then(() => {
        dispatch(getMessages(chatRoomID, pageLimit));
        onSendFile();
        setUploadingVdo(false);
      })
      .catch((err) => { })
      .finally(() => {
        setOpenModal(false);
      });
  };

  return (
    <ModalComponent
      isMobile={isMobile}
      contentStyle={{ width: isMobile ? '50%' : '60%', height: isMobile ? '40%' : '60%' }}
      open={true}
      closeModal={() => {
        setOpenModal(false);
      }}
    >
      {uploadingVdo && <Loading />}
      <VideoRecorder
        showReplayControls
        onRecordingComplete={onRecordingComplete}
        handleVdoUpload={() => handleVdoUpload(recordedVideoBlob)}
      />
    </ModalComponent>
  );
};

export default VideoRecorderWrapper;
