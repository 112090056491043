import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { triggerUrgentAlert } from '../../../Actions/patient.actions';
import { useTranslation } from 'react-i18next';
import { Button, Heading, ModalComponent } from '../../Atoms';

const UrgentAlert = ({ mobile }) => {
  const [isSent, setIsSent] = useState(() => {
    const urgentAlertSent = localStorage.getItem('urgentAlertSent');
    if (urgentAlertSent) return new Date().getTime() - new Date(urgentAlertSent).getTime() < 24 * 3600 * 1000;
    else return false;
  });
  const [popupShown, setPopupShown] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sendUrgentAlert = async () => {
    await dispatch(triggerUrgentAlert());
    localStorage.setItem('urgentAlertSent', new Date().toISOString());
    setIsSent(true);
    setPopupShown(false);
  };

  return (
    <>
      <Button
        onClick={() => setPopupShown(true)}
        variant="tiny warning"
        disabled={isSent}
        type="button"
      >
        {mobile ?  t("UrgentAlertMobile") : t("UrgentAlert")}
      </Button>
      {popupShown && (
        <ModalComponent open closeModal={() => setPopupShown(false)}>
          <Heading level={3}>{t("UrgentAlert")}</Heading>
          <p>{t("UrgentAlertModal")}</p>
          <ModalActions>
            <Button onClick={sendUrgentAlert}>{t("send")}</Button>
          </ModalActions>
        </ModalComponent>
      )}
    </>
  );
};

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
ModalActions.displayName = 'ModalActions';

UrgentAlert.propTypes = {
  mobile: PropTypes.bool,
};

export default UrgentAlert;
