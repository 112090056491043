import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { AuthLayout } from '../../Layout';
import { LoginForm } from '../../Components/Organisms';
import { Loading } from '../../Components/Atoms';
import { OtherSectionPrompt } from '../../Components/Molecules';
import { TherapistSpecialLayout } from '../Signup';
import config, { isNixy } from '../../Config';
import { Logo } from '../../Patient/Signup';

const Login = (props) => {
  const loginInfo = localStorage.getItem('loginInfo');
  const isLoggedIn = !!loginInfo;
  const userRole = loginInfo ? JSON.parse(loginInfo).role : '';
  const history = useHistory();
  const therapistProfile = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (userRole === 'patient') {
      history.push('/chatroom');
    } else if (therapistProfile) {
      if (!therapistProfile.license_no) history.push('/t/signup/step-5');
      else history.push('/t/dashboard');
    }
    if (isLoggedIn) {
      history.push('/t/dashboard');
    }
  }, [userRole, history, therapistProfile]);

  return (
    <TherapistSpecialLayout>
      <AuthLayout>
        <Wrapper>
          {isLoggedIn ? (
            <Loading />
          ) : (
            <>
              <Logo src={config.nameLogo} />
              <LoginForm userType="therapist" redirect="/t/dashboard" heading="Therapist Login" timeout {...props} />
              {!isNixy &&
                <OtherSectionPrompt>
                  <h5>
                    Are you a Patient? <Link to="/welcome/login">Login here</Link>
                  </h5>
                </OtherSectionPrompt>
              }
            </>
          )}
        </Wrapper>
      </AuthLayout>
    </TherapistSpecialLayout>
  );
};

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;
export default Login;