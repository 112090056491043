import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { DefaultLayout } from '../../Layout';
import { Heading } from '../../Components/Atoms';
import { getChatResources } from '../../Actions/chatroom.actions';
import { Link } from 'react-router-dom';
import FileIcon from '../../Assets/Icons/File.svg';
import { useTranslation } from 'react-i18next';

const SharedFiles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chatRoomID = 26;

  const [resources, setResources] = useState();

  const openFileLink = (item) => window.open(item[item.type].url);

  const getFileLink = (item) => {
    return (
      <>
        {['jpeg', 'jpg', 'png', 'gif'].includes(item.file.type) ? (
          <StyledLink onClick={() => openFileLink(item)}>
            <img src={item.file.url} alt={item.file.name} style={{ maxWidth: '100px', width: '100%' }} />
          </StyledLink>
        ) : (
          <StyledLink onClick={() => openFileLink(item)}>
            <img src={FileIcon} alt="" style={{ maxWidth: '16px', marginRight: '8px' }} />{' '}
            <span>{item?.file?.name}</span>
          </StyledLink>
        )}
      </>
    );
  };

  const getSharedFiles = useCallback(() => {
    dispatch(getChatResources(chatRoomID)).then((data) => {
      setResources(data.resources);
    });
  }, [chatRoomID, dispatch]);

  useEffect(() => {
    getSharedFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DefaultLayout>
        <Wrapper>
          <Heading>{t("sharedFiles")}</Heading>

          <table>
            <thead>
              <tr>
                <th>{t("file")}</th>
                <th>{t("sentBy")}</th>
                <th>{t("date")}</th>
                {/* <th>{t("role")}</th> */}
              </tr>
            </thead>
            <tbody>
              {resources &&
                resources.map((item) => (
                  <tr key={item.id}>
                    <td>{getFileLink(item)}</td>
                    <td>{item.sent_by}</td>
                    <td>{moment(item.sent_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                    {/* <td>{item.sent_by_role}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </Wrapper>
      </DefaultLayout>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  margin: auto;
`;
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-decoration: none;
  cursor: pointer;
  > * {
    flex-shrink: 0;
  }
`;
export default SharedFiles;
