import React from 'react';
import Login from '../../Patient/Login';

const SignIn = () => {

    return (
        <Login userLogin={true} registerEnterprise={false} />
    )
}

export default SignIn;