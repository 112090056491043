import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const EmployeeCount = () => {
  const { t } = useTranslation();
  const data = [
    { id: 0, label: t("sales"), data: 53, color: '#6FD9C4' },
    { id: 1, label: t("customerServices"), data: 110, color: '#C185BF' },
    { id: 2, label: t("management"), data: 25, color: '#6CC8CE' },
    { id: 3, label: t("marketing"), data: 150, color: '#D39262' },
    { id: 4, label: t("IT"), data: 88, color: '#CA828F' },
    { id: 5, label: t("design"), data: 12, color: '#9ECA82' },
  ];

  const chartElement = useRef(null);

  const chartData = {
    labels: data.map((x) => x.label),
    datasets: [
      {
        backgroundColor: data.map((y) => y.color),
        data: data.map((z) => z.data),
      },
    ],
  };

  const chartOptions = {
    legend: {
      display: false,
    },
  };

  return (
    <>
      <Bar ref={chartElement} data={chartData} options={chartOptions} />
    </>
  );
};

export default EmployeeCount;
