import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, ChevronIcon, TextBlock, Logo, Loading } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import { MainSectionWrapper, BottomSectionWrapper } from '../Components/';
import { useDeviceType } from '../../Util';
import EmergencyBtn from '../../Components/Atoms/EmergencyBtn';
import { useTranslation } from 'react-i18next';

const ThankYou = () => {
  const { t } = useTranslation();
  const socialRequestPopupStatus = useSelector((store) => store.auth.socialRequestPopupStatus);
  const isPartner = useSelector((store) => store?.isPartner?.isPartner);
  const quizStatus = useSelector((store) => store.questionnaire.quizStatus);
  const fetchingQuiz = useSelector((store) => store.questionnaire.fetchingQuiz);
  const loginInfo = localStorage.getItem('loginInfo');
  const isLoggedIn = !!loginInfo;
  const history = useHistory();
  const { userType } = useParams();
  const isMobile = useDeviceType();
  const isBootsPartner = window.location.host === 'boots.supportroom.com';

  if (isLoggedIn && !socialRequestPopupStatus) history.push('/chatroom');

  if (quizStatus !== 'completed') history.push(`/welcome/${userType}/questionnaire`);

  return (
    <WelcomeLayout noPadding noTopBar>
      <section>
        <MainSectionWrapper isMobile={isMobile}>
          {fetchingQuiz && <Loading />}
          <Logo />
          <TextBlock type={'primary'}>{t("thanksForPatience")}</TextBlock>
          <TextBlock type={'secondary'}>
            {isBootsPartner
              ? t("boots1")
              : t("boots2")}
          </TextBlock>
          {!fetchingQuiz && isPartner ? (
            <TextBlock type={'emphasized'}>
              {isBootsPartner
                ? t("boots3")
                : t("boots4")}
            </TextBlock>
          ) : !fetchingQuiz && !isPartner ? (
            <>
              <TextBlock type={'emphasized'}>
              {t("textBlock1")}
                {/* Now it’s time to set up your free account. Remember, your first week of unlimited messaging is free. */}
              </TextBlock>
              <TextBlock type={'secondary'}>
              {t("textBlock2")}
              </TextBlock>
            </>
          ) : (
            ''
          )}
          <ActionButton>
            <Button
              variant="secondary"
              style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
              onClick={() => history.push(userType ? `/welcome/${userType}/account` : `/welcome/account`)}
            >
              {t("setAccount")}
              <ChevronIcon direction="right" width="13" height="13" />
            </Button>
          </ActionButton>
        </MainSectionWrapper>
      </section>
      <section>
        <BottomSectionWrapper>
          <EmergencyBtn />
        </BottomSectionWrapper>
      </section>
    </WelcomeLayout>
  );
};

const ActionButton = styled.div`
  margin-top: 30px;
  @media (max-width: 640px) {
    position: absolute;
    bottom: -13px;
    z-index: 999;
  }
`;

export default memo(ThankYou);
