import React, { useState } from 'react';
import styled from 'styled-components';
import { DefaultLayout } from '../../Layout';
import { Button, FormGroup, Heading, Label, Input, Alert, Loading } from '../../Components/Atoms';
import * as Yup from 'yup';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import api from '../../Util/api';
import { useTranslation } from 'react-i18next';

const ReferFriend = () => {
  const { t } = useTranslation();
  const [responseMessage, setResponseMessage] = useState({ message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("friendNameIsRequired")),
    email: Yup.string().email(t("emailIsInvalid")).required(t("friendEmailIsRequired")),
    message: Yup.string().required(t("emailMessageIsRequired")),
  });

  const referFriend = async (values, { resetForm }) => {
    resetForm();
    setLoading(true);
    try {
      const response = (await api.patient.referFriend(values)).data;
      setResponseMessage({ message: response.message, type: 'primary' });
    } catch (err) {
      setResponseMessage({ message: t("errorOccurredMessage"), type: 'danger' });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DefaultLayout>
        <Wrapper>
          <Heading text={t("referAFriend")} />
          {!responseMessage.message ? (
            <Formik
              initialValues={{
                name: '',
                email: '',
                message: '',
              }}
              onSubmit={referFriend}
              validationSchema={validationSchema}
            >
              {({ errors, touched }) => (
                <Form>
                  <FormGroup>
                    <Label htmlFor="name">{t("friendsName")}</Label>
                    <Field
                      id="name"
                      name="name"
                      as={StyledInput}
                      className={errors.name && touched.name ? 'is-invalid' : ''}
                    />
                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">{t("friendsEmailAddress")}</Label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      as={StyledInput}
                      className={errors.email && touched.email ? 'is-invalid' : ''}
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="message">{t("Message")}</Label>
                    <Field
                      rows={15}
                      id="message"
                      name="message"
                      as={StyledTextarea}
                      className={errors.message && touched.message ? 'is-invalid' : ''}
                    />
                    <ErrorMessage name="message" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <Button type="submit" className="secondary">{t("send")}</Button>
                </Form>
              )}
            </Formik>
          ) : (
            <Alert className={responseMessage.type}>{responseMessage.message}</Alert>
          )}
          {loading && <Loading/>}
        </Wrapper>
      </DefaultLayout>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
`;
Wrapper.displayName = 'Wrapper';

const StyledInput = styled(Input)`
  border-color: transparent;
  background: ${({ theme }) => theme.greyVeryLight};
`;
StyledInput.displayName = 'StyledInput';

const StyledTextarea = styled.textarea`
  border-color: transparent;
  background: ${({ theme }) => theme.greyVeryLight};
`;
StyledTextarea.displayName = 'StyledTextarea';

export default ReferFriend;
