import React from 'react';
import styled from 'styled-components';
const Alert = (props) => {
  return <StyledAlert {...props}>{props.children}</StyledAlert>;
};

const StyledAlert = styled.div`
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing};
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.primary};
  background-color: ${({ theme }) => theme.primaryLight};
  margin: ${(props) => (props.addMargin ? '16px 0' : null)};

  &.primary {
    color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
    background: ${({ theme }) => theme.primaryLight};
  }

  &.secondary {
    color: ${({ theme }) => theme.secondary};
    border-color: ${({ theme }) => theme.secondary};
    background: ${({ theme }) => theme.secondaryLight};
  }

  &.danger {
    color: ${({ theme }) => theme.statusDanger};
    border-color: ${({ theme }) => theme.statusDanger};
    background: ${({ theme }) => theme.statusDangerLight};
  }

  &.warning {
    color: ${({ theme }) => theme.statusWarning};
    border-color: ${({ theme }) => theme.statusWarning};
    background: ${({ theme }) => theme.statusWarningLight};
  }
  &.info {
    color: ${({ theme }) => theme.statusInfo};
    border-color: ${({ theme }) => theme.statusInfo};
    background: ${({ theme }) => theme.statusInfoLight};
  }

  &.success {
    color: ${({ theme }) => theme.statusSuccess};
    border-color: ${({ theme }) => theme.statusSuccess};
    background: ${({ theme }) => theme.statusSuccessLight};
  }

  &.muted {
    color: ${({ theme }) => theme.greyMedium};
    border-color: ${({ theme }) => theme.greyMedium};
    background: ${({ theme }) => theme.greyLight};
  }
`;

export default Alert;
