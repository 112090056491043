import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Heading, Widget } from '../../../Components/Atoms';
import api from '../../../Util/api';
import { useTranslation } from 'react-i18next';

const StatsGrid = () => {
  const { t } = useTranslation();
  const [stats, setStats] = useState();

  const getTherapistStats = () => {
    api.company
      .getStats()
      .then((response) => response.data)
      .then((results) => {
        if (results.status === 'success') {
          setStats(results.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getTherapistStats();
  }, []);

  return (
    <Grid>
      <Widget align="center">
        <Heading level={5}>{t("activeEmployees")}</Heading>
        <Value>{stats ? stats.active_employees : '--'}</Value>
      </Widget>
      <Widget align="center">
        <Heading level={5}>{t("inactiveEmployees")}</Heading>
        <Value>{stats ? stats.deactive_employees : '--'}</Value>
      </Widget>
      <Widget align="center">
        <Heading level={5}>{t("videoSesssions")}</Heading>
        <Value>{stats ? stats.total_video_session : '--'}</Value>
      </Widget>
      <Widget align="center">
        <Heading level={5}>{t("totalMessagesSent")}</Heading>
        <Value>{stats ? stats.total_messages_sent : '--'}</Value>
      </Widget>
      <Widget align="center">
        <Heading level={5}>{t("completedJourneys")}</Heading>
        <Value>{stats ? stats.completed_journeys : '--'}</Value>
      </Widget>
      <Widget align="center">
        <Heading level={5}>{t("overallEmployeeMood")}</Heading>
        <Value>{stats ? stats.overall_employee_mood : '--'}</Value>
      </Widget>
      <Widget align="center">
        <Heading level={5}>{t("avgActivityTime")}</Heading>
        <Value>{stats ? stats.avg_activity_time : '--'}</Value>
      </Widget>
      <Widget align="center">
        <Heading level={5}>{t("missedVideoSessions")}</Heading>
        <Value>{stats ? stats.missed_video_sessions : '--'}</Value>
      </Widget>
    </Grid>
  );
};

const Grid = styled.div`
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: ${({ theme }) => theme.spacingMd};
  `}
  ${({ theme }) => theme.lg`
    grid-template-columns: repeat(4, minmax(0, 1fr));
  `}
`;

const Value = styled.div`
  font-size: 35px;
  font-weight: ${({ theme }) => theme.fontBold};
  margin: ${({ theme }) => theme.spacing};
  word-break: break-all;
`;
export default StatsGrid;
