import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tooltip } from '../../Atoms';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NavigateHelper = ({ link, isChatRoomActive, showVideoCallIcon, profile, isB2b }) => {
  const role = useSelector((store) => store.auth.role);
  const isPartnerCheck = useSelector((store) => store.isPartner?.isPartner);

  const { t } = useTranslation();
  if (link.path === '/moodbox' && ((profile && profile['B2B']) || profile?.moodbox_page_visited)) return null;

  const isDisabled = (!isChatRoomActive || !showVideoCallIcon) && role === 'patient';

  if (link.path === '/calendar' && isDisabled) {
    if (isB2b) {
      return null;
    }
    return (
      <StyledFakeLink key={link.id} to={link.path}>
        <StyledIcon>
          <link.icon />
        </StyledIcon>
        <Tooltip
          id={link.label}
          text={t('upgradeSubscriptionAccessVideoSessions')}
          place="right"
          offset={{ right: 10 }}
        >
          <span>{t(link.label)}</span>
        </Tooltip>
      </StyledFakeLink>
    );
  }

  if ((!isB2b || isPartnerCheck) && link.path === '/additional-support') {
    return null;
  }

  if ((!isB2b || isPartnerCheck) && link.path === '/employee-benefits') {
    return null;
  }

  return (
    <StyledLink key={link.id} to={link.path} disabled={!isChatRoomActive && role === 'patient'}>
      <StyledIcon>
        <link.icon />
      </StyledIcon>
      <Tooltip id={link.label} text={t(link.description)} place="right" offset={{ right: 10 }}>
        <span>{t(link.label)}</span>
      </Tooltip>
    </StyledLink>
  );
};

export default NavigateHelper;

const StyledLink = styled(Link)`
  display: flex;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.primary};
  margin-bottom: ${({ theme }) => theme.spacing};
  text-decoration: none;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;
StyledLink.displayName = 'StyledLink';

const StyledFakeLink = styled.span`
  cursor: not-allowed;
  display: flex;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontBold};
  color: #787676b0;
  margin-bottom: ${({ theme }) => theme.spacing};
  text-decoration: none;
  align-items: center;
`;
StyledLink.displayName = 'StyledFakeLink';

const StyledIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryLight};
  width: 46px;
  height: 46px;
  border-radius: 100%;
  margin-right: ${({ theme }) => theme.spacing};

  svg {
    fill: ${({ theme }) => theme.primary};
  }
`;
StyledIcon.displayName = 'StyledIcon';
