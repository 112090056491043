import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Formik, Form } from 'formik';

import { AuthLayout } from '../../Layout';
import { Button, Heading, ProgressBar } from '../../Components/Atoms';
import CheckboxGroup from './CheckboxGroup';
import api from '../../Util/api';

const Step3 = () => {
  const nextStep = () => history.push('/t/signup/step-4');
  const [specialisms, setSpecialisms] = useState();
  const history = useHistory();

  const getSpecialisms = () => {
    api.general
      .getSpecialism()
      .then((response) => {
        if (response.status === 200) {
          setSpecialisms(response.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getSpecialisms();
  }, []);

  const handleOnSubmit = (values) => {
    updateSpecialisms(values);
  };

  const updateSpecialisms = (values) => {
    values.checked.forEach((option) => {
      api.therapist
        .updateSpecialism(option)
        .then((response) => response.data)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => console.log('error', error));
    });
    nextStep();
  };

  return (
    <>
      <AuthLayout>
        <>
          <Heading>What issues do you have experience in working with?</Heading>
          <ProgressBar progress="30" style={{ margin: '20px auto', maxWidth: '500px' }} />
          <p>Your expertise will help us to match you with the most relevant patients. Please select all that apply.</p>
          <Formik
            initialValues={{
              checked: [],
            }}
            onSubmit={handleOnSubmit}
          >
            {({ values }) => (
              <Form>
                <CheckboxGroup values={values} options={specialisms} />

                <ButtonWrapper>
                  <Button variant="muted" onClick={nextStep}>
                    Skip
                  </Button>
                  <Button variant="primary" type="submit">
                    Continue
                  </Button>
                </ButtonWrapper>
              </Form>
            )}
          </Formik>
        </>
      </AuthLayout>
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacingLg};

  button {
    margin: 0 ${({ theme }) => theme.spacingLg};
  }
`;

export default Step3;
