import React, { memo } from 'react';
import { useHistory } from 'react-router';

import { PageHeading } from '../../Components/Molecules';

import { ReactComponent as JournalIcon } from '../../Assets/Icons/Journal.svg';
import { Button } from '../../Components/Atoms';
import Tooltip from '../../Components/Atoms/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const moveTo = (link) => history.push(link);
  return (
    <div>
      <PageHeading icon={JournalIcon} name={t("journal")}/>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Tooltip id="AllJournalEntries" text={t("allJournalEntriesTooltip")} place="right">
          <Button
            style={{ marginBottom: 10, marginRight: 10 }}
            className="secondary"
            onClick={() => moveTo('/journal')}
          >
           {t("allJournalEntries")}
          </Button>
        </Tooltip>

        {/* <Tooltip id="CreateaJournal" text="Create an entry" place="right"> */}
        <Button style={{ marginBottom: 10 }} className="secondary" onClick={() => moveTo('/journal/new')}>
        {t("createAnEntry")}
        </Button>
        {/* </Tooltip> */}
      </div>
    </div>
  );
};

export default memo(Sidebar);
