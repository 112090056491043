import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getRolePrefix } from '../../../Util';
import api from '../../../Util/api';
import { passwordValidation } from '../../../Constants/validations';

import { Heading, FormGroup, Button, Alert } from '../../Atoms';

const CompanySignupForm = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    showError: false,
    errorMessage: '',
  });
  const history = useHistory();

  const handleOnSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await api.auth.signup(props.userType, values);
      resetForm();
      history.push('/c/signup/complete');
    } catch (err) {
      console.error(err);
      setState({
        showError: true,
        errorMessage: err.response.data.message,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required(t("companyNameRequired")),
    email: Yup.string().email(t("emailIsInvalid")).required(t("emailIsRequired")),
    password: passwordValidation(t),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t("passwordsMustMatch"))
      .required(t("confirmPasswordIsRequired")),
  });

  return (
    <>
      <Wrapper>
        <Heading text={props.heading} />
        <p>
        {t("alreadyHaveAnAccount")}{' '}
          <Link to={`${getRolePrefix(props.userType)}/login`}>
            <b>{t("loginNow")}</b>
          </Link>
        </p>
        <Formik
          initialValues={{
            company_name: '',
            email: '',
            password: '',
            password_confirmation: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormGroup>
                <Field
                  id="company_name"
                  name="company_name"
                  placeholder={t("companyName")}
                  type="text"
                  className={'form-control' + (errors.company_name && touched.company_name ? ' is-invalid' : '')}
                />
                <ErrorMessage name="company_name" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field
                  id="email"
                  name="email"
                  placeholder={t("emailAddress")}
                  type="email"
                  className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field
                  id="password"
                  name="password"
                  placeholder= {t("choosePassword")}
                  type="password"
                  className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                />
                <ErrorMessage name="password" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Field
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder={t("confirm_Password")}
                  type="password"
                  className={
                    'form-control' +
                    (errors.password_confirmation && touched.password_confirmation ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage name="password_confirmation" component="div" className="invalid-feedback" />
              </FormGroup>

              <Button type="submit">{t("continue")}</Button>

              {state.showError && (
                <Alert className="danger" addMargin>
                {t("accountExists")}{' '}
                  <Link to={`${getRolePrefix(props.userType)}/login`}>
                    <b>{t("tryLoggingIn")}</b>
                  </Link>
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

CompanySignupForm.propTypes = {
  userType: PropTypes.string,
  heading: PropTypes.string,
};

CompanySignupForm.defaultProps = {
  userType: 'company',
  heading: 'Create your account',
};

export default CompanySignupForm;
