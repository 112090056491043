import { SET_TEAMS } from '../Constants/actionTypes';

const initialState = {
  teamsContext: null,
};

export default function teams(state = initialState, action) {
  switch (action.type) {
    case SET_TEAMS:
      return {
        teamsContext: action.payload,
      };
    default:
      return state;
  }
}
