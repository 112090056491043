import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Heading, FormGroup, Button, Alert, Input } from '../../Atoms';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { login } from '../../../Actions/auth.actions';
import { getRolePrefix } from '../../../Util';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Eye } from '../../../Assets/Icons/Eye.svg';
import { ReactComponent as EyeSlash } from '../../../Assets/Icons/EyeSlash.svg';

const LoginForm = (props) => {
  const role="therapist"
  const { t } = useTranslation();
  const [state, setState] = useState({
    showError: false,
    errorMessage: '',
    forgotLink: true,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnSubmit = async (values, { setSubmitting }) => {
    setSubmitLoading(true);
    try {
      await dispatch(login(props.userType, values, history, props.redirect, props.timeout));
      if (props.timeout) {
        setShowSuccessMessage(true);
        setState({
          showError: false,
          errorMessage: "",
          forgotLink: true
        });
      }
    } catch (err) {
      let loginErrorCodes = [401, 403, 404, 500]
      let message = err.response?.data?.message
      if (loginErrorCodes.includes(err?.response?.status)) {
        message = err.response?.data?.message !== "Unauthorized" &&
          err?.response?.status === 401
          ? err.response?.data?.message
          : t('emailAddressPasswordNotRecognised')
      }
      setState({
        showError: true,
        errorMessage: message,
        forgotLink: message === t('emailAddressPasswordNotRecognised')
      });
    } finally {
      setSubmitLoading(false);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("emailIsInvalid")).required(t("emailIsRequired")),
    password: Yup.string().min(6, t("passwordMustBeLeast6Characters")).required(t("passwordIsRequired")),
  });

  return (
    <>
      <Wrapper>
        <Heading text={props.heading} />
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormGroup>
                <Field
                  id="email"
                  name="email"
                  placeholder={t("emailAddress")}
                  type="email"
                  className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field
                  id="password"
                  name="password"
                  placeholder={t("choosePassword")}
                  as={Input}
                  icon={showPassword ? EyeSlash : Eye}
                  onIconClick={togglePassword}
                  type={showPassword ? 'text' : 'password'}
                  className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                />
                <ErrorMessage name="password" component="div" className="invalid-feedback" />
              </FormGroup>

              {showSuccessMessage && (
                <FormGroup>
                  <Alert className="secondary">{t("successfullyLoggedIn")}</Alert>
                </FormGroup>
              )}

              <p>
                {t("dontHaveAccount")}{' '}
                <Link to={`${getRolePrefix(props.userType)}/signup`}>
                  <b>{t("signUpNow")}</b>
                </Link>
              </p>
              <p>
                {t("forgottenYourPassword")}{' '}
                <Link to={`/forgotten-password/${role}`}>
                  <b>{t("resetPassword")}</b>
                </Link>
              </p>

              <Button loading={submitLoading} type="submit">
                {t("login")}
              </Button>
            </Form>
          )}
        </Formik>
        {state.showError && (
          <Alert className="danger" addMargin>
            {state.errorMessage} {state?.forgotLink ? <Link to={`/forgotten-password/${role}`}>{t("forgotPassword")}</Link> : null}
          </Alert>
        )}
      </Wrapper>
    </>
  );
};
LoginForm.propTypes = {
  userType: PropTypes.string,
  heading: PropTypes.string,
};
LoginForm.defaultProps = {
  userType: 'patient',
  heading: 'Login to your account',
};

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

export default LoginForm;
