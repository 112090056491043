import api from '../Util/api';
import axios from 'axios';
import {
  GET_MOOD_BOX,
  GET_PATIENT_ANALYTICS,
  GET_PROFILE,
  GET_SUBSCRIPTION_STATUS,
  CANCEL_SUBSCRIPTION,
  SAVE_MOOD,
  CLOSE_MOOD_BOX_THX_MESSAGE,
  GET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATIONS_SETTINGS,
  LOGIN,
  ERRORS,
  TOGGLE_POPUP_SOCIAL_INFORMATION_REQUEST,
  UPDATE_PROFILE,
  UPDATE_PROFILE_2FA,
  UPDATE_PROFILE_ONLINE_STATUS,
  UPDATE_EMAIL,
  REDEEM_VOUCHER,
} from '../Constants/actionTypes';
import { Store } from 'react-notifications-component';
import { getScheduleList } from './therapist.actions';

export const makeAppointment = (data) => () =>
  api.patient
    .makeAppointment(data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);

export const getMoodQuestions = () => (dispatch) =>
  api.patient
    .getMoodQuestions()
    .then((res) => {
      dispatch({
        type: GET_MOOD_BOX,
        payload: res?.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const saveMoodQuestions = (data) => () =>
  api.patient
    .saveMoodQuestions(data)
    .then((res) => res.data)
    .catch((err) => err);

export const getAnalytics =
  (startDate = null, endDate = null, period) =>
  (dispatch) =>
    api.patient
      .getAnalytics(startDate, endDate, period)
      .then((res) => {
        dispatch({
          type: GET_PATIENT_ANALYTICS,
          payload: res.data.data,
        });
        return res.data;
      })
      .catch((err) => err);

export const getProfile = () => (dispatch) =>
  api.patient
    .getProfile()
    .then((res) => {
      dispatch({
        type: GET_PROFILE,
        payload: res.data.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getSubscriptionStatus = () => (dispatch) =>
  api.patient
    .subscriptionStatus()
    .then((res) => {
      dispatch({
        type: GET_SUBSCRIPTION_STATUS,
        payload: { ...(res?.data?.data || {}), exceedsswitchTherapist: res?.data?.exceedsswitchTherapist },
      });
      return res.data;
    })
    .catch((err) => err);

export const cancelSubscription = () => (dispatch) =>
  api.payment
    .cancelSubscription()
    .then((res) => {
      dispatch({
        type: CANCEL_SUBSCRIPTION,
      });
      return res.data;
    })
    .catch((err) => err);

export const saveMood = (mood) => (dispatch) =>
  api.patient
    .saveMood(mood)
    .then((res) => {
      dispatch({
        type: SAVE_MOOD,
      });
      return res.data;
    })
    .catch((err) => err);

export const closeMoodBoxThxMessage = () => (dispatch) => dispatch({ type: CLOSE_MOOD_BOX_THX_MESSAGE });

export const getNotificationSettings = () => (dispatch) =>
  api.patient
    .getNotificationSettings()
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATION_SETTINGS,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const updateProfile = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.patient
      .updateProfile(data)
      .then((res) => {
        dispatch({ type: UPDATE_PROFILE, payload: res.data?.data });
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const deleteScheduledSlot = (id) => (dispatch) =>
  api.therapist
    .deleteScheduledSlot(id)
    .then((res) => {
      dispatch(getScheduleList());
      return res.data;
    })
    .catch((err) => err);

export const updateEmail = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.auth
      .updateEmail(data)
      .then((res) => {
        dispatch({ type: UPDATE_EMAIL, payload: data.email });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const verifyUpdatedEmail = (token) => () =>
  new Promise((resolve, reject) =>
    api.auth
      .verifyUpdatedEmail(token)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const updateNotificationSettings = (id, status, type) => (dispatch) =>
  api.patient
    .updateNotificationSettings(id, status, type)
    .then((res) => {
      dispatch({
        type: UPDATE_NOTIFICATIONS_SETTINGS,
        payload: { id, status },
      });
      dispatch(getNotificationSettings());
      return res.data;
    })
    .catch((err) => err);

export const triggerUrgentAlert = () => (dispatch) =>
  api.patient
    .triggerUrgentAlert()
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);

export const reportMissedSession = () => (dispatch) =>
  api.patient
    .reportMissedSession()
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);

export const socialLogin = (data, history) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.patient
      .socialLogin(data)
      .then((res) => {
        if (res.data.status === 'success') {
          localStorage.setItem(
            'loginInfo',
            JSON.stringify({
              loggedIn: true,
              access_token: res.data.access_token,
              refresh_token: res.data.refresh_token,
              expires_at: res.data.expires_at,
              role: res.data.role,
            }),
          );

          axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;

          if (res.data.firstUse) dispatch({ type: TOGGLE_POPUP_SOCIAL_INFORMATION_REQUEST });
          if (!res.data.firstUse) history.push('/chatroom');
          dispatch({
            type: LOGIN,
            payload: { ...(res?.data || {}), loggedIn: true },
          });
        }
        return resolve(res.data);
      })
      .catch((err) => {
        dispatch({
          type: ERRORS,
          payload: err,
        });
        return reject(err);
      }),
  );

export const toggleSocialInformationRequest = () => (dispatch) =>
  dispatch({ type: TOGGLE_POPUP_SOCIAL_INFORMATION_REQUEST });

export const update2FAProfile = (state) => (dispatch) => dispatch({ type: UPDATE_PROFILE_2FA, payload: state });
export const updateProfileOnlineStatus = (state) => (dispatch) => dispatch({ type: UPDATE_PROFILE_ONLINE_STATUS, payload: state });

export const redeemVoucher = (voucher) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.patient
      .redeemVoucher(voucher)
      .then((res) => {
        if (res.data.status === 'success') {
          dispatch(getSubscriptionStatus());
          dispatch({ type: REDEEM_VOUCHER });
        } else if (res.data.status === 'error') {
          Store.addNotification({
            title: 'Error',
            message: res.data.message,
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });

          return reject(res);
        }
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );
