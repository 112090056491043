import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Avatar } from '../../Atoms';
import { useTranslation } from 'react-i18next';

const validFormats = ['image/jpeg', 'image/png'];

const ProfilePhotoInput = React.forwardRef((props, ref) => {
  const { file, id, value, thumbnail, ...other } = props;
  const { t } = useTranslation();
  return (
    <>
      <Thumb thumbnail={thumbnail} file={file} />
      <CustomFileInput>
        <input
          id={id}
          type='file'
          ref={ref}
          {...other}
        />
        <label htmlFor={id}>{file ? `${t("changeImage")}` : t("uploadImage")}</label>
      </CustomFileInput>
    </>
  );
});

const Thumb = ({ file, thumbnail }) => {
  const [state, setState] = useState({
    thumb: undefined,
  });

  useEffect(() => {
    if (!file) {
      return;
    }

    let reader = new FileReader();
    reader.onloadend = () => {
      setState({ thumb: reader.result });
    };
    reader.readAsDataURL(file);
  }, [file]);

  if (!file || !validFormats.includes(file.type)) {
    return <Avatar src={thumbnail || undefined} width={160} />;
  }

  return <Avatar src={state.thumb} alt={file.name} width={160} />;
};

const CustomFileInput = styled.div`
  margin: ${({ theme }) => theme.spacingMd} 0;

  [type='file'] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;

    + label {
      display: inline-block;
      border-radius: 4px;
      border: 1px solid transparent;
      color: ${({ theme }) => theme.white};
      font-size: 15px;
      font-weight: 700;
      padding: ${({ theme }) => theme.spacingSm} ${({ theme }) => theme.spacing};
      text-decoration: none;
      min-width: 200px;
      background: ${({ theme }) => theme.secondary};
      cursor: pointer;
    }

    &:focus + label {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
`;

ProfilePhotoInput.propTypes = {
  file: PropTypes.any,
  id: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
};

export default ProfilePhotoInput;
