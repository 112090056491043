import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../../Components/Atoms';

const InvoicesTable = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Heading level={2}>{t("invoices")}</Heading>
      <table>
        <thead>
        <tr>
          <th>{t("date")}</th>
          <th>{t("amount")}</th>
        </tr>
        </thead>
        <tbody>
        {data.map(invoice => (
          <tr key={invoice.id}>
            <td>{moment(invoice.date).format('MMM Do YYYY')}</td>
            <td>£{invoice.amount.toLocaleString('en-GB')}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </>
  );
};

InvoicesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default InvoicesTable;
