import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { updateProfile } from '../../Actions/therapist.actions';

import { Button, Heading, ProgressBar } from '../../Components/Atoms';
import { AuthLayout } from '../../Layout';

const Step9 = () => {
  const profile = useSelector((store) => store.user?.profile);
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const selectCard = (value) => setSelectedOption(value);

  const submitAvailability = () => {
    dispatch(updateProfile({ out_of_hours: selectedOption ? 1 : 0 })).then(() => history.push('/t/signup/complete'));
  };

  useEffect(() => {
    if (profile) setSelectedOption(profile.out_of_hours);
  }, [profile]);

  if (!profile || selectedOption === null) return null;

  return (
    <AuthLayout>
      <Wrapper>
        <Heading align="center">Preferred availability</Heading>
        <ProgressBar progress="90" style={{ margin: '20px auto', maxWidth: 500 }} />

        <p>
          Please select your preferred availability, don't worry specific timings can be edited but we need to have a
          general understanding of your availability pattern so we can match clients appropriately:
        </p>

        <AvailabilityWrapper>
          <Card style={{ marginRight: 10 }} selected={!selectedOption} onClick={() => selectCard(false)}>
            {!selectedOption && <Selected />}
            <Title>I'll be available mostly on Peak hours and days</Title>
            <Hours>Mon - Fri - 9am - 5pm</Hours>
          </Card>
          <Card selected={selectedOption} onClick={() => selectCard(true)}>
            {selectedOption && <Selected />}
            <Title>I'll be available mostly Out of hours</Title>
            <Hours>Mon - Fri 5pm - 10pm & Sat or Sun for a few hours</Hours>
          </Card>
        </AvailabilityWrapper>

        <br />
        <br />
        <Button variant="primary" onClick={submitAvailability}>
          Continue
        </Button>
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  max-width: 100%;
  margin: auto;
`;
Wrapper.displayName = 'Wrapper';

const ButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.spacingXl} 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 32px;
  }
`;
ButtonWrapper.displayName = 'ButtonWrapper';

const AvailabilityWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Card = styled.div`
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;

  cursor: pointer;

  &:hover {
    background: #fafafa;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #00c8a0;
      color: white;

      &:hover {
        background: #00b692;
      }
    `}
`;

const Title = styled.div`
  margin-bottom: 10px;
`;
const Hours = styled.div`
  font-weight: 600;
`;

const Selected = styled.div`
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  top: 7px;
  left: 7px;
  background: white;
`;

export default Step9;
