import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';
import { TherapyTrackerTable } from '../index';
import CloseIcon from '../../../Assets/Icons/ClosePurple.svg';

const TherapyTrackerSidebar = ({ customBurgerIcon }) => {
  const [showTherapyTracker, setShowTherapyTracker] = useState(false);

  const handleStateChange = ({ isOpen }) => setShowTherapyTracker(isOpen);

  return (
    <Menu
      isOpen={showTherapyTracker}
      onStateChange={handleStateChange}
      customCrossIcon={<img src={CloseIcon} alt="Close menu" />}
      right
      customBurgerIcon={customBurgerIcon}
      styles={NavStyles}
      pageWrapId={'mainContent'}
      outerContainerId={'siteWrapper'}
      disableAutoFocus
    >
      <TherapyTrackerTable />
    </Menu>
  );
};

const NavStyles = {
  bmBurgerButton: {
    position: 'relative',
    zIndex: 1,
  },
  bmMenuWrap: {
    maxWidth: '900px',
    minWidth: '300px',
    width: '60%',
    position: 'fixed',
    height: '100%',
    top: 0,
    right: 0,
  },
  bmCrossButton: {
    width: '18px',
    top: '26px',
    right: '16px',
  },
  bmMenu: {
    background: '#ffffff',
    boxShadow: '49px 84px 74px 40px rgba(0, 0, 0, 0.25)',
  },
  bmItemList: {
    padding: '64px 32px 32px 22px',
    overflowY: 'auto',
  },
  bmOverlay: {
    background: 'transparent',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

TherapyTrackerSidebar.propTypes = {
  customBurgerIcon: PropTypes.element.isRequired,
};

export default TherapyTrackerSidebar;
