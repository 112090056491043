import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Container, Heading, Loading, Tooltip } from '../../Components/Atoms';
import { Header, TherapyTools } from '../../Components/Organisms';
import { api } from '../../Util';
import { therapyToolsLinks } from '../Chatroom/Components/Sidebar';
import { useTranslation } from 'react-i18next';

const EmployeeBenefits = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await api.patient.viewBenefits();
      setLoading(false);
      setList(response?.data?.data);
    };
    getData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Loading styles={{ position: 'fixed', backgroundColor: 'rgba(255,255,255,0.2)' }} />
      </div>
    );
  }
  return (
    <>
      <Header />
      <Container>
        <Wrapper>
          <SubWrapper>
            <div>
              <TherapyTools
                heading="Therapy Tools"
                links={therapyToolsLinks}
                isChatRoomActive={true}
                setModalState={() => null}
              />
            </div>
          </SubWrapper>

          <BenefitWrapper>
            <HeadingWrapper>
              <Heading style={{ fontSize: '25px' }}> {t('employeeBenefits')} </Heading>
            </HeadingWrapper>
            <StyledContainer>
              {list.length ? (
                list.map((item, index) => (
                  <StyledCard>
                    <TooltipWrapper id={item.benefit_title} text={item.benefit_title} place="top" multiline={true}>
                      <Title style={{ position: 'absolute', top: '10px' }}> {item.benefit_title}</Title>
                    </TooltipWrapper>
                    <CardContent>
                      <TooltipWrapper id={item.company_name} text={item.company_name} place="top" multiline={true}>
                        {item.company_name === '' ? null : (
                          <Title style={{ fontSize: '15px', marginTop:'10px' }}> {item.company_name}</Title>
                        )}
                      </TooltipWrapper>

                      {item.benefit_logo === '' ? null : (
                        <div style={{ height: '30%' }}>
                          <img
                            src={item.benefit_logo}
                            style={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'contain',
                              borderRadius: '50',
                            }}
                            alt=""
                          />
                        </div>
                      )}
                      <TooltipWrapper id={item.benefit_title+index} text={item.benefit_description} place="top" multiline={true}>
                        {item.benefit_description === '' ? null : (
                          <Description> {item.benefit_description}</Description>
                        )}
                      </TooltipWrapper>
                    </CardContent>
                    <ButtonWrapper>
                      <a href={item.benefit_link} target="_blank" rel="noreferrer">
                        <Button type="primary">{t('redeemReward')} </Button>
                      </a>
                    </ButtonWrapper>
                  </StyledCard>
                ))
              ) : (
                <Wrap>No Data Available</Wrap>
              )}
            </StyledContainer>
          </BenefitWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default EmployeeBenefits;

const StyledContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  width: 100%;

  ${({ theme }) => theme.md`
    align-items: flex-start;
    justify-content: flex-start;
  `}
`;

const HeadingWrapper = styled.div`
  margin: auto;
  ${({ theme }) => theme.md`
    margin: 0;
  `}
`;

const Wrap = styled.div`
  ${'' /* font-size: 18px */}
`;

const TooltipWrapper = styled(Tooltip)`
  width: 200px;
`;

const SubWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.md`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 300px;
  align-items: flex-start;
  `}
`;

const BenefitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 0px;
  flex: 1;
  align-items: flex-start;
  margin-bottom: 30px;

  ${({ theme }) => theme.md`
  margin-right: 15%;
  `}
`;

const StyledCard = styled.div`
  height: 300px;
  width: 245px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #dad9da;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-self: center;
  justify-content: center;
  text-align: left;
  position: relative;
`;
const CardContent = styled.div`
  height: 185px;
  width: 215px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-evenly;
  text-align: left;
`;

const Wrapper = styled.div`
  background-color: white;
  margin-top: 30px;
  display: flex;
`;

const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 212px;
  overflow: hidden;
`;

const Title = styled.div`
  font-size: 17px;
  color: #491f69;
  font-weight: 500;
  padding: 5px 0 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 212px;
  overflow: hidden;
  height: 50px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  position: absolute;
  bottom: 15px;
`;