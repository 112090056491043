import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Disconnected } from '../../../Assets/SignUp/Disconnected.svg';
const SVGWrapper = styled.div`
  max-height: 100%;
  max-width: 100%;
  margin-bottom: 140px;
`;

const DisconnectedView = () => (
  <SVGWrapper>
    <Disconnected />
  </SVGWrapper>
);

export default DisconnectedView;
