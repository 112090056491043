import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonGroup = (props) => {
  const { children, value, ...other } = props

  return (
    <ButtonWrapper {...other}>
      {children.map((child, i) => (
        React.cloneElement(child, {
          key: i,
          className: `small ${child.props.name === value ? 'primary' : ''}`,
        })
      ))}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  ${'' /* display: grid; */}
  grid-template-columns: repeat(auto-fit, minmax(50px, 65px));
  align-items: center;
  column-gap: 2px;
`;
ButtonWrapper.displayName = 'ButtonWrapper';

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  value: PropTypes.string,
};

export default ButtonGroup;
