import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  addTherapyJourney,
  getNotifications,
  handleChatRoomRequest,
  handleMarkRead,
} from '../../../Actions/chatroom.actions';
import useJourneyInitData from '../../../Hooks/useJourneyInitData';
// import { notificationSound } from '../../../Util/notificationSound';

import { Button, Heading, ModalComponent } from '../../Atoms';
import { SwitchTherapistPopup, NotificationActions } from '../../Molecules';
import { getActiveChatRooms } from '../../../Actions/therapist.actions';

const initialForwardOptions = {
  chatroomID: null,
  notificationID: null,
  open: false,
};

const NotificationPopup = ({ notificationsPath }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const journeyInitData = useJourneyInitData();
  const [forwardOptions, setForwardOptions] = useState(initialForwardOptions);
  const dispatch = useDispatch();
  const notifications = useSelector((store) => store.user.notifications);
  const videoRoomActive = useSelector((store) => store.chatroom.videoRoom.active);
  const prevUnreadCount = useRef(notifications?.total_unread_count || 0);
  const { pathname } = useLocation();

  const openForwardModal = useCallback((chatroomID, notificationID) => {
    setIsOpen(false);
    setForwardOptions({ chatroomID, notificationID, open: true });
  }, []);

  const closeForwardModal = () => setForwardOptions(initialForwardOptions);

  const handleCloseModal = () => setIsOpen(false);

  const handleRequest = useCallback(
    (id, status, notificationId) => {
      dispatch(handleChatRoomRequest(id, status, notificationId));
      if (status === 'approved') {
        setTimeout(() => dispatch(addTherapyJourney({ ...journeyInitData, chatroom_id: id })), 2000);
        dispatch(getActiveChatRooms());
      }

      dispatch(getNotifications());
      handleCloseModal();
    },
    [dispatch, journeyInitData],
  );

  const markAsRead = useCallback(
    async (id) => {
      await dispatch(handleMarkRead(id));
      handleCloseModal();
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      notifications?.total_unread_count &&
      prevUnreadCount.current !== notifications.total_unread_count &&
      !videoRoomActive &&
      pathname !== notificationsPath
    ) {
      setIsOpen(pathname !== notificationsPath);
      // notificationSound();
    }
    prevUnreadCount.current = notifications?.total_unread_count || 0;
  }, [notifications, notificationsPath, pathname, videoRoomActive]);

  const notificationBody = useMemo(() => {
    const actionNotification = notifications?.data?.find(({ type, read_status }) => {
      const isUrgent = type === 'patient_urgent_alert';
      const isRequest = type === 'chatroom_request';
      // const isSwitch = type === 'switch_therapist_by_patient';
      const isChatroomAccepted = type === 'chatroom_accepted';
      return (isUrgent || isRequest || isChatroomAccepted) && read_status !== 1;
    });
    if (actionNotification) {
      return (
        <>
          <p>{actionNotification.message}</p>
          {actionNotification.sent_by_role === "patient" && actionNotification.sent_by ?
            <>Patient : <Link style={{textDecoration:"underline"}} to={`/t/patient-profile/${actionNotification.chatroom_id}/${actionNotification.sent_by_user_id}`}>{actionNotification.sent_by}</Link></> : null}
          <ModalActions>
            <NotificationActions
              notification={actionNotification}
              handleRequest={handleRequest}
              handleSwitch={openForwardModal}
              markAsRead={markAsRead}
            />
          </ModalActions>
        </>
      );
    } else
      return (
        <ModalActions>
          <Button as={Link} to={notificationsPath} onClick={handleCloseModal}>
          {t("goToNotifications")}
          </Button>
        </ModalActions>
      );
  }, [notifications, notificationsPath, handleRequest, markAsRead, openForwardModal]);

  return !forwardOptions.open ? (
    <ModalComponent open={isOpen} closeModal={handleCloseModal}>
      <Heading level={3}>{t("newNotificationReceived")}</Heading>
      {notificationBody}
    </ModalComponent>
  ) : (
    null
    //Disabling the following code as this feature is not needed anymore.
    // <SwitchTherapistPopup
    //   chatroomID={forwardOptions.chatroomID}
    //   notificationID={forwardOptions.notificationID}
    //   markAsRead={markAsRead}
    //   closeForwardModal={closeForwardModal}
    // />
  );
};

const ModalActions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  gap: ${({ theme }) => theme.spacingSm};
  justify-content: center;

  ${({ theme }) => theme.md`
    justify-content: flex-end;
  `};
`;
ModalActions.displayName = 'ModalActions';

NotificationPopup.propTypes = {
  notificationsPath: PropTypes.string.isRequired,
};

export default NotificationPopup;
