import React from 'react';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../Assets/Icons/ClosePurple.svg';
import FaqListing from '../../../Patient/Chatroom/Components/FaqListing';

const FaqSidebar = ({ customBurgerIcon, categories, isOpen, onToggleMenu }) => {
  const { t } = useTranslation();
  return (
    <Menu
      isOpen={isOpen}
      onStateChange={({ isOpen})=>onToggleMenu(isOpen)}
      customCrossIcon={<CloseIcon />}
      customBurgerIcon={customBurgerIcon}
      styles={NavStyles}
      pageWrapId={'mainContent'}
      outerContainerId={'siteWrapper'}
      disableAutoFocus
      right
    >
      <div>
        {categories.length ? (
          categories.map(({ faq, name, id}) => 
          <FaqListing key={id} heading={name} faq={faq} />
          )
        ) : (
          <p>{t('noFaqs')}</p>
        )}
      </div>
    </Menu>
  );
};

const NavStyles = {
  bmBurgerButton: {
    position: 'relative',
  },
  bmMenuWrap: {
    width: '320px',
    position: 'fixed',
    height: '100%',
    top: 0,
    right: 0,
  },
  bmCrossButton: {
    width: '18px',
    top: '26px',
    right: '16px',
  },
  bmMenu: {
    background: '#ffffff',
    boxShadow: '49px 84px 74px 40px rgba(0, 0, 0, 0.25)',
  },
  bmItemList: {
    padding: '64px 32px 32px',
  },
  bmOverlay: {
    background: 'transparent',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

FaqSidebar.propTypes = {
  customBurgerIcon: PropTypes.element.isRequired,
  categories: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggleMenu: PropTypes.func.isRequired,
};

export default FaqSidebar;
