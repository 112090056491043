import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Store } from 'react-notifications-component';
import { Button, ChevronIcon, TextBlock, Logo, FormGroup, Input } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import { api, useDeviceType } from '../../Util';
import { MainSectionWrapper, ModalOverlay } from '../Components/';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const InviteSignup = (props) => {
  const selectedLanguage = useSelector((store) => store.language.selectedLanguage);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const isMobile = useDeviceType();

  const handleOnSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      setLoading(true);

      const res = await api.auth.invitation(values.invitationCode);
      if (res.data?.status === 'error') {
        Store.addNotification({
          title: t('verificationFailed'),
          message: (
            <div>
              <p>{res.data?.message || t('invalidInvitationCode')}</p>
            </div>
          ),
          type: 'danger',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 8000,
            onScreen: true,
          },
        });
      } else {
        Store.addNotification({
          title: t('verificationSuccess'),
          message: (
            <div>
              <p>{t('invitationCodeVerifiedSuccessfully')}</p>
            </div>
          ),
          type: 'success',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        if (res?.data?.data?.url) {
          window.location.href = `https://${res.data.data.url}/${selectedLanguage}/welcome/e/signup?company_unique_id=${res.data.data.company_unique_id}&invitation_password=${values.invitationCode}`;
        }

        resetForm();
      }
    } catch (err) {
      Store.addNotification({
        title: t('verificationFailed'),
        message: (
          <div>
            <p>{err.response.data?.message || t('invalidInvitationCode')}</p>
          </div>
        ),
        type: 'danger',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const validationSchema = useMemo(() => {
    const validations = {
      invitationCode: Yup.string().required(t('invitationCodeRequired')),
    };

    return Yup.object().shape(validations);
  }, []);

  const initialValues = useMemo(() => {
    return {
      invitationCode: new URLSearchParams(window.location.search).get('code') || '',
    };
  }, []);

  return (
    <WelcomeLayout noPadding noTopBar>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleOnSubmit}
      >
        {({ setFieldValue }) => (
          <Form noValidate>
            <MainSectionWrapper isMobile={isMobile}>
              <section>
                <Logo />
                <TextBlock type={'primary'}>{t('verifyInvitationCode')}</TextBlock>
                <StyledFormGroup>
                  <FieldWrapper>
                    <Field
                      id="invitationCode"
                      name="invitationCode"
                      as={Input}
                      type="text"
                      className={'form-control'}
                      maxLength="30"
                    />
                    <label htmlFor="invitationCode">{t('invitationCode')}</label>
                    <ErrorMessage name="invitationCode" component="div" className="invalid-feedback" />
                  </FieldWrapper>
                </StyledFormGroup>
              </section>
            </MainSectionWrapper>
            <SubmitButtonWrapper isMobile={isMobile}>
              <Button variant="secondary" type="submit">
                {t('verifyInvitation')}
                <ChevronIcon direction="right" width="13" height="13" />
              </Button>
            </SubmitButtonWrapper>
          </Form>
        )}
      </Formik>
      {loading && (
        <ModalOverlay>
          <LoadingLabel>{t('verifyingInvitationCode')}</LoadingLabel>
        </ModalOverlay>
      )}
    </WelcomeLayout>
  );
};

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: start;
  input {
    width: 100%;
    height: 50px;
    flex-grow: initial;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    &:focus {
      border: 2px solid #00c8a0;
    }
  }

  input {
    svg {
      path {
        fill: #797979 !important;
      }
    }
  }

  label {
    span {
      border-radius: 0;
      border-color: #c4c4c4;
      a {
        color: #33c8a0;
      }
    }

    &:hover {
      .checkmark {
        background: #f6f6f6;
      }
    }
  }

  input:checked ~ .checkmark {
    background-color: #00c8a0;
  }
`;
export const FieldWrapper = styled.div`
  width: 100%;
  margin-right: 5px;
  position: relative;
  input {
    margin-top: 5px;
  }

  select {
    margin-top: 5px;
  }

  label {
    font-size: 13px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.5);
  }
  .invalid-feedback {
    margin-left: 8px;
  }
  .react-tel-input {
    input.form-control {
      width: 100%;
      height: 50px;
      background: ${({ theme }) => theme.greyVeryLight};
    }
    .flag-dropdown {
      &.open {
        .country-list {
          overflow-x: hidden;
          padding-right: 6px;
          &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: ${({ theme }) => theme.greyVeryLight};
          }
          .search {
            .search-emoji {
              display: none;
            }
            .search-box {
              height: 45px;
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
`;
const LoadingLabel = styled.div`
  color: white;
  font-weight: 300;
`;

const SubmitButtonWrapper = styled.div`
  z-index: 999;
  button {
    font-weight: 600 !important;
    color: #fff !important;
    background: #33c8a0 !important;
    width: 300px;
    padding: 15px 0;
    margin-bottom: 40px;
    z-index: 9;
  }
`;
export default InviteSignup;
