import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import api from '../../../Util/api';
import { getRolePrefix } from '../../../Util';
import { mobilePhoneValidation, passwordValidation } from '../../../Constants/validations';

import { Heading, FormGroup, Button, Checkbox, Alert, Input } from '../../Atoms';

import { ReactComponent as Eye } from '../../../Assets/Icons/Eye.svg';
import { ReactComponent as EyeSlash } from '../../../Assets/Icons/EyeSlash.svg';
import tc from '../../../Assets/Patient T&Cs - SR.pdf';

const referalOptions = [
  { id: 1, name: 'Twitter' },
  { id: 2, name: 'Facebook' },
  { id: 3, name: 'Instagram' },
  { id: 4, name: 'Linkedin' },
  { id: 5, name: 'Blogs or Press release' },
  { id: 6, name: 'Search engines' },
  { id: 7, name: 'Friend' },
];

const SignupForm = (props) => {
  const { t } = useTranslation();
  const isEmployee = props.userType === 'employee';
  const [countriesList, setCountriesList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [responseMessage, setResponseMessage] = useState({ type: '', message: null });
  const history = useHistory();

  const getCountriesList = () => {
    api.others
      .getCountriesList()
      .then((response) => {
        if (response.status === 200 && response.data?.data?.length) {
          setCountriesList(response.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleOnSubmit = async (values, { resetForm, setSubmitting }) => {
    const userType = isEmployee ? 'patient' : props.userType;
    const referalData = values.referal ? JSON.parse(values.referal) : {};

    try {
      await api.klaviyo.subscribePatient({
        ...values,
        referal: referalData?.name,
        send_marketing_email: values.checked?.includes('marketing') ? 1 : 0,
      });
      await api.auth.signup(userType, {
        ...values,
        referal: referalData?.name,
        send_marketing_email: values.checked?.includes('marketing') ? 1 : 0,
      });
      resetForm();
      history.push('/signup/complete');
    } catch (err) {
      const errors = err.response.data.errors || (err.response.data.detail && { detail: [err.response.data.detail] });
      setResponseMessage({
        type: 'danger',
        message: Object.keys(errors).map((key) => (
          <>
            <span key={key}>{Object.values(errors[key]).join(' ')}</span>
            <br />
          </>
        )),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = useMemo(() => {
    const validations = {
      first_name: Yup.string().required(t("firstNameIsRequired")),
      last_name: Yup.string().required(t("lastNameIsRequired")),
      mobile: mobilePhoneValidation(t),
      email: Yup.string().email(t("emailIsInvalid")).required(t("emailIsRequired")),
      password: passwordValidation(t),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], t("passwordsMustMatch"))
        .required(t("confirmPasswordIsRequired")),
      country_id: Yup.string().required(t("countryIsRequired")),
      referal: Yup.string(),
    };

    if (!isEmployee) {
      validations.accept_terms = Yup.array().min(1, t("selectCheckboxText"));
    } else {
      validations.department_id = Yup.number().required(t("departmentIsRequired"));
    }

    return Yup.object().shape(validations);
  }, [isEmployee]);

  const initialValues = useMemo(() => {
    const initialValues = {
      first_name: '',
      last_name: '',
      mobile: '',
      email: '',
      password: '',
      password_confirmation: '',
      country_id: '',
      checked: [],
      marketing: 0,
      referal: JSON.stringify({ id: '', name: '' }),
    };

    if (!isEmployee) {
      initialValues.accept_terms = [];
    } else {
      initialValues.company_unique_id = props.employeeData.companyId;
      initialValues.department_id = '';
    }

    return initialValues;
  }, [props, isEmployee]);

  useEffect(() => {
    getCountriesList();
  }, []);

  const mobileOnChange = (phone, setFieldValue) => {
    setFieldValue('mobile', phone);
    setPhone(phone);
  };

  return (
    <>
      <Wrapper>
        <Heading text={props.heading} />
        <p>
        {t("alreadyHaveAnAccount")}{' '}
          <Link to={`${getRolePrefix(props.userType)}/login`}>
            <b>{t("loginNow")}</b>
          </Link>
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleOnSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <FormGroup>
                <Field
                  id="first_name"
                  name="first_name"
                  placeholder={t("firstName")}
                  type="text"
                  className={'form-control' + (errors.first_name && touched.first_name ? ' is-invalid' : '')}
                />
                <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Field
                  id="last_name"
                  name="last_name"
                  placeholder={t("lastName")}
                  type="text"
                  className={'form-control' + (errors.last_name && touched.last_name ? ' is-invalid' : '')}
                />
                <ErrorMessage name="last_name" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <PhoneInput
                  containerClass={errors.mobile && touched.mobile ? ' is-invalid' : ''}
                  value={phone}
                  onChange={(phone) => mobileOnChange(phone, setFieldValue)}
                  placeholder={t("mobileNumberPlaceholder")}
                  enableSearch
                />
                {/*<Field*/}
                {/*  id="mobile"*/}
                {/*  name="mobile"*/}
                {/*  placeholder="Mobile number"*/}
                {/*  type="tel"*/}
                {/*  className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')}*/}
                {/*/>*/}
                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Field
                  as="select"
                  id="country_id"
                  name="country_id"
                  className={'form-control' + (errors.country_id && touched.country_id ? ' is-invalid' : '')}
                >
                  <option disabled value="" label="Country" />
                  {countriesList &&
                    countriesList.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                </Field>
                <ErrorMessage name="country_id" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Field
                  id="email"
                  name="email"
                  placeholder={t("emailAddress")}
                  type="email"
                  className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Field
                  id="password"
                  name="password"
                  placeholder={t("choosePassword")}
                  as={Input}
                  icon={showPassword ? EyeSlash : Eye}
                  onIconClick={togglePassword}
                  type={showPassword ? 'text' : 'password'}
                  className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                />
                <ErrorMessage name="password" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Field
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder={t("confirm_Password")}
                  as={Input}
                  icon={showConfirmPassword ? EyeSlash : Eye}
                  onIconClick={toggleConfirmPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  className={
                    'form-control' +
                    (errors.password_confirmation && touched.password_confirmation ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage name="password_confirmation" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field as="select" id="referal" name="referal" type="text">
                  <option disabled value={JSON.stringify({ id: '', name: '' })} label={t("howYouHeardAboutUs")} />
                  {referalOptions.map(({ id, name }) => (
                    <option key={id} value={JSON.stringify({ id, name })}>
                      {name}
                    </option>
                  ))}
                </Field>
              </FormGroup>

              {isEmployee ? (
                <FormGroup>
                  <Field
                    as="select"
                    id="department_id"
                    name="department_id"
                    onChange={({ target: { value } }) => setFieldValue('department_id', +value)}
                    className={'form-control' + (errors.department_id && touched.department_id ? ' is-invalid' : '')}
                  >
                    <option disabled value="">
                    {t("department")}
                    </option>
                    {props.employeeData.departments.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="department_id" component="div" className="invalid-feedback" />
                </FormGroup>
              ) : null}

              {props.userType === 'patient' && (
                <CheckboxGroup role="group" aria-labelledby="checkbox-group">
                  <FormGroup>
                    <Field
                      as={Checkbox}
                      label={t("signUpFormLabel1")}
                      name="accept_terms"
                      value="consent"
                    />
                    <ErrorMessage name="accept_terms" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Checkbox
                      label={t("signUpFormLabel2")}
                      name="checked"
                      value="notifications"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Checkbox
                      label={t("signUpFormLabel3")}
                      name="checked"
                      value="marketing"
                    />
                  </FormGroup>
                </CheckboxGroup>
              )}

              {responseMessage.type ? (
                <StyledAlert className={responseMessage.type}>{responseMessage.message}</StyledAlert>
              ) : null}

              <div style={{ textAlign: 'left', fontSize: 16 }}>
                <span>
                {t("see")}{' '}
                  <TCLink href={tc} download>
                  {t("termsAndConditions")}
                  </TCLink>
                </span>
              </div>

              <Button style={{ marginTop: 20 }} type="submit">
              {t("continue")}
              </Button>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </>
  );
};

const TCLink = styled.a`
  color: ${({ theme }) => theme.primaryMedium};
  font-weight: ${({ theme }) => theme.fontMedium};
`;

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .react-tel-input {
    border: 2px solid #e9d9f5;
    border-radius: 7px;

    li {
      color: black;
      text-align: left;
    }

    .form-control {
      width: 100%;
      height: 40px;
    }

    input {
      &::placeholder {
        color: #bebebe;
      }
    }

    .search {
      display: flex;
      height: 40px;
      padding: 10px;

      input {
        height: 25px;
      }
    }
  }
`;

const CheckboxGroup = styled.div`
  text-align: left;
  margin-top: ${({ theme }) => theme.spacingLg};
`;

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing};
`;
StyledAlert.displayName = 'StyledAlert';

SignupForm.propTypes = {
  userType: PropTypes.string,
  heading: PropTypes.string,
  employeeData: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    departments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
  }),
};

SignupForm.defaultProps = {
  userType: 'patient',
  heading: 'Create your account',
  employeeData: null,
};

export default SignupForm;
