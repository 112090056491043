import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Slider from 'react-slick';

import api from '../../Util/api';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: false,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        autoplay: true,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 0,
        dots: true,
        autoplay: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        autoplay: true,
        initialSlide: 0,
      },
    },
  ],
};

const CarouselWithDot = () => {
  const [carouselItems, setCarouselItems] = useState([]);

  useEffect(() => {
    api.gymPass.carouselItems().then((res) => setCarouselItems(res));
  }, []);

  return (
    <CarouselWrapper>
      <Slider {...settings}>
        {carouselItems?.length &&
          carouselItems.map((item) => (
            <ItemWrapper key={item.ID}>
              <Text>{item.post_title}</Text>
              <Image image={item.image} />
            </ItemWrapper>
          ))}
      </Slider>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  margin-bottom: 70px;

  .slick-dots {
    bottom: -45px;
    button {
      &:before {
        color: #323232;
        font-size: 12px;
      }
    }

    .slick-active {
      button {
        &:before {
          color: #00c8a0;
        }
      }
    }
  }
`;

const ItemWrapper = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.div`
  width: 100%;
  height: 164px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: url(${({ image }) => image});
  filter: contrast(0.7);
  background-size: cover;
  border-radius: 10px;
`;

const Text = styled.h4`
  z-index: 10;
  position: absolute;
  font-size: 20px;
  color: white;
  max-width: 140px;
  line-height: 22px;
  text-align: center;

  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export default CarouselWithDot;
