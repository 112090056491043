import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Heading, ProgressBar } from '../../Components/Atoms';
import { AuthLayout } from '../../Layout';
import { TherapistQualificationsForm } from '../../Components/Organisms';

const Step5 = () => {
  const history = useHistory();
  const therapistProfile = useSelector((state) => state.user.profile);
  const initialValues = useMemo(() => {
    if (therapistProfile) {
      return {
        insruance_certificate: therapistProfile.insruance_certificate,
        certification: therapistProfile.certification,
        govt_identity: therapistProfile.govt_identity,
        work_with_children: !!therapistProfile.work_with_children,
        children_certificate: therapistProfile.children_certificate,
        qualification: therapistProfile.qualification,
        certification_body: therapistProfile.certification_body,
        license_no: therapistProfile.license_no,
      };
    } else return null;
  }, [therapistProfile]);

  const nextStep = () => history.push('/t/signup/step-6');

  return (
    <AuthLayout>
      <Wrapper>
        <Heading align="center">Qualifications</Heading>
        <ProgressBar progress="50" style={{ margin: '20px auto' }} />

        <p>In order to work as a therapist with SupportRoom we need the following information:</p>
        <br />
        <TherapistQualificationsForm onSubmitDone={nextStep} initialValues={initialValues} />
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin: auto;
  text-align: left;
`;
Wrapper.displayName = 'Wrapper';

const ButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.spacingXl} 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 32px;
  }
`;
ButtonWrapper.displayName = 'ButtonWrapper';

export default Step5;
