import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getJournals, updateJournal } from '../../Actions/chatroom.actions';

import { DefaultLayout } from '../../Layout';
import { Button, FormGroup, Heading, Label, Input, Tooltip } from '../../Components/Atoms';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';

const New = ({ journals }) => {
  const [{ title, content }, setData] = useState({ title: '', content: '' });
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const titleOnChange = (e) => setData({ title: e.target.value, content });
  const contentOnChange = (e) => setData({ title, content: e.target.value });

  const update = () => {
    dispatch(updateJournal({ journal_id: parseInt(id), title, content })).then(() => history.push('/journal'));
  };

  useEffect(() => {
    if(title.length === 0 || content.length === 0){
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [title, content]);

  useEffect(() => {
    const journalData =
      journals && journals.data && journals.data[journals.data.findIndex((j) => j.journal_id === parseInt(id))];
    if (journalData) setData({ title: journalData.title, content: journalData.content });
  }, [journals, id]);

  useEffect(() => {
    !journals && dispatch(getJournals());
  }, [journals, dispatch]);
  return (
    <DefaultLayout>
      <Wrapper>
        <Sidebar />
        <Window>
          <Heading text={t("newJournalEntry")} />
          <FormGroup>
            <Label htmlFor="journalTitle">{t("journalTitle")}</Label>
            <StyledInput
              type="text"
              id="journalTitle"
              name="journalTitle"
              maxLength={100}
              value={title}
              onChange={titleOnChange}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="journalContent">{t("journalContent")}</Label>
            <StyledTextarea
              rows={15}
              id="journalContent"
              name="journalContent"
              value={content}
              onChange={contentOnChange}
            />
          </FormGroup>
         
          <Tooltip id="journal" text={t("submitJournalEntry")} place="top">
          <div style={{ width: "100px" }}>
          <Button disabled={disabled}  className="secondary" onClick={update}>
          {t("update")}
          </Button>
          </div>
          </Tooltip>
        </Window>
      </Wrapper>
    </DefaultLayout>
  );
};

const Wrapper = styled.div`
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: ${theme.spacing};
  `};
`;

const Window = styled.div``;

const StyledInput = styled(Input)`
  border-color: transparent;
  background: ${({ theme }) => theme.greyVeryLight};
`;
const StyledTextarea = styled.textarea`
  border-color: transparent;
  background: ${({ theme }) => theme.greyVeryLight};
`;

const mapStateToProps = (store) => ({
  journals: store.chatroom.journals,
});

export default connect(mapStateToProps)(New);
