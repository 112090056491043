import React, { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

// import { ReactComponent as MoodNeutral } from '../../Assets/Icons/emoji-neutral.svg';
import { MoodsArray } from '../../Components/Molecules/Chatroom/MoodChecker';

import api from '../../Util/api';
import { useDispatch, useSelector } from 'react-redux';
import { getTherapyJourney } from '../../Actions/chatroom.actions';

const JourneyHeader = ({ patientProfile }) => {
  const { chatRoomId, patientId } = useParams();
  const [mood, setMood] = useState(null);

  const journey = useSelector((store) => store.chatroom?.therapyJourney?.data);
  const companyName = useSelector((store) => store.chatroom?.companyName)
  const activeJourneyStage = useMemo(() => {
    if (!journey) return null;

    return journey.findIndex((stage) => stage.active === 1);
  }, [journey]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (patientId)
      api.therapist.getPatientMood(patientId).then((res) => {
        const moods = res.data?.data;
        if (moods) {
          setMood(moods[moods.length - 1]);
        }
      });
  }, [patientId]);

  useEffect(() => {
    dispatch(getTherapyJourney(chatRoomId, 'therapist'));
  }, [dispatch, chatRoomId]);
  return (
    <HeaderWrapper>
      <HeaderItem>
        <b>Name:</b> {patientProfile?.full_name}
      </HeaderItem>
      {companyName && (
        <HeaderItem>
          <b>Company Name:</b> {companyName}
        </HeaderItem>
      )}
      {/*<HeaderItem>*/}
      {/*  <b>Gender:</b> Male*/}
      {/*</HeaderItem>*/}
      {/*<HeaderItem>*/}
      {/*  <b>Therapy:</b> CBT*/}
      {/*</HeaderItem>*/}
      <HeaderItem>
        <b>Mood:</b> {mood ? <span>{MoodsArray[mood.mood - 1].emoji}</span> : <span>No mood</span>}
      </HeaderItem>
      <HeaderItem>
        <b>Journey Stage:</b> {activeJourneyStage !== null ? activeJourneyStage + 1 : 'No stage progress'}
      </HeaderItem>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.md`
    display: flex;
    align-items: center;
    font-size: 18px;
  `};
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacingSm};

  ${({ theme }) => theme.md`
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.spacingMd};
  `};

  b {
    margin-right: ${({ theme }) => theme.spacingSm};
  }
`;

// const StyledMood = styled(MoodNeutral)`
//   fill: ${({ theme }) => theme.primary};
// `;

export default memo(JourneyHeader);
