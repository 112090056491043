import React from 'react';
import { Switch } from 'react-router-dom';
import Route from '../../Routes/Route';
import CompanyLayout from '../../Layout/CompanyLayout';
import Overview from './Overview';
import Employees from "./Employees";
import Billing from './Billing';
// import Notifications from "./Notifications";

const Dashboard = (props) => {
  return (
    <>
      <CompanyLayout>
        <Switch>
          {/* Private Routes */}
          <Route exact path="/c/dashboard" component={Overview} isPrivate />
          <Route
            exact
            path="/c/dashboard/employees"
            component={Employees}
            isPrivate
          />
          <Route
            exact
            path="/c/dashboard/billing"
            component={Billing}
            isPrivate
          />
          {/* <Route
            exact
            path="/t/dashboard/notifications"
            component={Notifications}
            isPrivate {...props}
          /> */}

          {/* Default Route */}
          <Route component={Overview} />
        </Switch>
      </CompanyLayout>
    </>
  );
};

export default Dashboard;
