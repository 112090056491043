import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import useWindowSize from '../../Util/useWindowSize';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from './arrow.svg';

const htmlDecode = (input) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Filter = ({ categories, selectedCategories, onClick, activeView, showFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const toggle = () => setIsOpen((state) => !state);

  return (
    <FilterWrapper
      isFilterOpen={isOpen}
      style={showFilter ? { background: '#faf3ff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)' } : {}}
    >
      {showFilter && (
        <>
          <h3 onClick={toggle}>{t("filter")}</h3>
          {width < 901 && <Icon as={Arrow} isFilterOpen={isOpen} onClick={toggle} />}
          <CheckBoxWrapper>
            <FilterItem key={0}>
              <input
                type={activeView === 'video' ? 'radio' : 'checkbox'}
                id={0}
                name="All"
                checked={
                  selectedCategories?.length === 0 || selectedCategories[0] === 0 || selectedCategories[0] === '0'
                }
                onChange={onClick}
              />
              <label htmlFor={0}>All</label>
            </FilterItem>
            {categories?.map((cat) => (
              <FilterItem key={cat.id || cat.cat_ID}>
                <input
                  type={activeView === 'video' ? 'radio' : 'checkbox'}
                  id={cat.id || cat.cat_ID}
                  name={cat.name}
                  checked={selectedCategories.includes(cat.id || cat.cat_ID)}
                  onChange={onClick}
                />
                <label htmlFor={cat.id || cat.cat_ID}>{htmlDecode(capitalizeFirstLetter(cat.name))}</label>
              </FilterItem>
            ))}
          </CheckBoxWrapper>
        </>
      )}
    </FilterWrapper>
  );
};

const Icon = styled.svg`
  position: absolute;
  right: 25px;
  top: 27px;
  cursor: pointer;

  ${({ isFilterOpen }) =>
    isFilterOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

export const FilterWrapper = styled.div`
  width: 20%;
  height: 400px;
  padding: 35px 25px;
  min-width: 250px;
  z-index: 10;

  h3 {
    color: ${({ theme }) => theme.primary};
  }

  @media screen and (max-width: 900px) {
    position: fixed;
    width: 93%;
    padding: 20px 25px;

    bottom: ${({ isFilterOpen }) => (!isFilterOpen ? '-340px' : '0px')};
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px 20px 0 0;
  }
`;

const FilterItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  align-items: center;

  input {
    width: 16px;
    height: 16px;
    display: inline;
    margin-right: 20px;
    border-color: black;

    &[type='checkbox'] {
      &:checked {
        color: red;
        &:before {
          color: red;
        }
      }
    }
  }

  label {
    font-size: 16px;
    color: ${({ theme }) => theme.primary};
    width: calc(100% - 36px);
  }
`;

const CheckBoxWrapper = styled.div`
  height: calc(100% - 50px);
  margin-top: 20px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
    background: #bfbfbf;
  }
`;

export default Filter;
