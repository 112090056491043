import React from "react";
import { Heading } from "../../Components/Atoms";
import { DefaultLayout } from "../../Layout";
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <DefaultLayout>
        <Heading text={t("Home")} />
        <p>
        {t("homeText1")}
        </p>
        <p>
        {t("homeText2")}
        </p>
        <p>
        {t("homeText3")}
        </p>
      </DefaultLayout>
    </>
  );
};

export default Home;
