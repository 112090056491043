import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../Routes/Route';
import { CompanyLayout } from '../../Layout';
import Details from './Details';
import Edit from './Edit';

const index = () => {
  return (
    <CompanyLayout>
      <Switch>
        {/* Private Routes */}
        <Route exact path="/c/account" component={Details} isPrivate />
        <Route exact path="/c/account/edit" component={Edit} isPrivate />

        {/* Default Route */}
        <Route component={Details} />
      </Switch>
    </CompanyLayout>
  );
};

export default index;
