import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useWindowSize } from '../../Hooks';
import { getActiveChatRooms, getChatResources, clearChatRoom, updateFlag } from '../../Actions/chatroom.actions';
import { getScheduleList } from '../../Actions/therapist.actions';

import { DashboardLayout } from '../../Layout';
import { Sidebar, PatientList } from './Components';
import { Button, Heading, Loading } from '../../Components/Atoms';
import ChatWindow from '../../Components/Molecules/Chatroom/ChatWindow';
import Header from '../../Components/Molecules/Chatroom/Header';
import ModalComponent from '../../Components/Atoms/Modal/Modal';

const Chatroom = ({ chatRoomID, chatRoomName, rooms }) => {
  const [loading, setLoading] = useState(true);
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  const [modalState, setModalState] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { roomID } = useParams();
  const viewportWidth = useWindowSize().width;

  const handleOnInfoClick = () => {
    setShowInfoPanel(!showInfoPanel);
  };
  const handleFlagPatient = () => {
    setModalState(true);
  };
  const closeModal = useCallback(() => {
    setModalState(false);
  }, []);

  const flagPatientAction = useCallback(
    (flag) => dispatch(updateFlag({ chatroom_id: chatRoomID, flag })).then(() => setModalState(false)),
    [chatRoomID, dispatch],
  );

  useEffect(() => {
    if (parseInt(roomID) !== chatRoomID && chatRoomID !== 0) {
      dispatch(getActiveChatRooms('therapist', parseInt(roomID))).then((response) => {
        if (response.length === 0) history.push('/questionnaire');
      });
      return;
    }

    dispatch(getScheduleList());
    dispatch(getChatResources(chatRoomID));
    setLoading(false);

    return () => {
      dispatch(clearChatRoom());
    };
  }, [chatRoomID, roomID, dispatch, history]);

  if (loading) return <Loading />;

  if (chatRoomID === 0)
    return (
      <DashboardLayout hideSidebar>
        <InvalidChatWindow>
          <Heading>Patient Support Room not found.</Heading>
          <p className="lead">Please try a different patient.</p>
          <Button as={Link} to="/t/dashboard/patients" className="secondary">
            View my patients
          </Button>
        </InvalidChatWindow>
      </DashboardLayout>
    );

  return (
    chatRoomID && (
      <DashboardLayout hideSidebar>
        {modalState && (
          <ModalComponent open closeModal={closeModal}>
            <div style={{ textAlign: 'center' }}>
              <Heading level={3}>How would you like to flag this patient?</Heading>
              <ButtonsWrapper>
                <Button inline className="small success" onClick={() => flagPatientAction('green')}>
                  Low Risk
                </Button>
                <Button inline className="small warning" onClick={() => flagPatientAction('amber')}>
                  Medium Risk
                </Button>
                <Button inline className="small danger" onClick={() => flagPatientAction('red')}>
                  High Risk
                </Button>
              </ButtonsWrapper>
            </div>
          </ModalComponent>
        )}
        <ChatWrapper id="chatWrapper">
          {viewportWidth > 768 && <PatientList />}
          <div>
            <Header
              viewportWidth={viewportWidth}
              chatroomAvailable={!!chatRoomID}
              chatRoomID={chatRoomID}
              handleFlagPatient={handleFlagPatient}
              handleOnInfoClick={handleOnInfoClick}
              chatRoomName={chatRoomName}
              rooms={rooms}
            />
            <ChatroomContainer className={showInfoPanel ? 'show-info' : null}>
              <ChatWindow />
              {showInfoPanel && <Sidebar chatroomAvailable={!!chatRoomID} />}
            </ChatroomContainer>
          </div>
        </ChatWrapper>
      </DashboardLayout>
    )
  );
};

const mapStateToProps = (store) => ({
  chatRoomID: store.chatroom.chatRoomID,
  chatRoomName: store.chatroom?.videoRoom?.name,
  rooms: store.chatroom?.rooms,
});

/* --------------------------------- Styles --------------------------------- */
const ChatWrapper = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  ${({ theme }) => theme.md`
    grid-template-columns: 320px 1fr;
  `};
`;

const ChatroomContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: calc(100vh - ${({ theme }) => theme.headerHeight} - 40px);

  ${({ theme }) => theme.sm`
      height: calc(100vh - ${({ theme }) => theme.headerHeight} - 60px);
   `}

  &.show-info {
    ${({ theme }) => theme.md`
    grid-template-columns: 1fr 320px;
  `};
  }
`;

const InvalidChatWindow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  position: relative;
  padding: ${({ theme }) => theme.spacing};
  margin-top: ${({ theme }) => theme.spacingXl};
`;

const ButtonsWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing} 0 0;
  display: grid;
  row-gap: ${({ theme }) => theme.spacing};

  ${({ theme }) => theme.md`
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
    justify-content: center;
    column-gap: ${({ theme }) => theme.spacing};
  `};
`;

export default connect(mapStateToProps)(Chatroom);
