import { createSelector } from 'reselect';
import moment from 'moment';

//----------- State Selectors -----------//

const getSchedulesDataUser = (store) => store.user?.scheduleList;

export const getToLocalSchedulesUser = createSelector([getSchedulesDataUser], (schedules) => {
  const newSchedules = [];
  const formatData = 'YYYY-MM-DD';
  const fullFormat = 'YYYY-MM-DD HH:mm:ss';

  if (schedules?.length) {
    schedules.forEach((schedule) => {
      newSchedules.push({
        schedule_date: moment.utc(schedule.schedule_date, formatData).local().format(formatData),
        slots: schedule.slots.map((slot) => ({
          ...slot,
          schedule_time: moment
            .utc(
              `${schedule.schedule_date} ${slot.schedule_time === '00:00:00' ? '00:00:01' : slot.schedule_time}`,
              fullFormat,
            )
            .local()
            .format(fullFormat),
          schedule_end_time: moment
            .utc(
              `${schedule.schedule_date} ${
                slot.schedule_end_time === '00:00:00' ? '23:59:59' : slot.schedule_end_time
              }`,
              fullFormat,
            )
            .local()
            .format(fullFormat),
        })),
      });
    });
  }

  return newSchedules;
});

const getSchedulesDataChatroom = (store) => store.chatroom?.scheduleList;

export const getToLocalSchedulesChatroom = createSelector([getSchedulesDataChatroom], (schedules) => {
  const newSchedules = [];
  const formatData = 'YYYY-MM-DD';
  const fullFormat = 'YYYY-MM-DD HH:mm:ss';

  if (schedules?.length) {
    schedules.forEach((schedule) => {
      newSchedules.push({
        schedule_date: moment.utc(schedule.schedule_date, formatData).local().format(formatData),
        slots: schedule.slots.map((slot) => ({
          ...slot,
          schedule_time: moment
            .utc(
              `${schedule.schedule_date} ${slot.schedule_time === '00:00:00' ? '00:00:01' : slot.schedule_time}`,
              fullFormat,
            )
            .local()
            .format(fullFormat),
          schedule_end_time: moment
            .utc(
              `${schedule.schedule_date} ${
                slot.schedule_end_time === '00:00:00' ? '23:59:59' : slot.schedule_end_time
              }`,
              fullFormat,
            )
            .local()
            .format(fullFormat),
        })),
      });
    });
  }

  return newSchedules;
});
