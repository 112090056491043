import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Avatar, Heading } from '../../../Components/Atoms';

const PatientProfile = () => {
  return (
    <>
      <ProfileWrapper>
        <Avatar width={90} />
        <ProfileDetails>
          <StyledHeading level={3} text="Patient Name" />
          <StyledLink to="#">View profile</StyledLink>
        </ProfileDetails>
      </ProfileWrapper>
    </>
  );
};

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileDetails = styled.div`
  margin-left: ${({ theme }) => theme.spacing};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 0;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.primary};
  font-weight: normal;
  text-decoration: underline;
`;

export default PatientProfile;
