import React from 'react';
import eye from '../../../Assets/Icons/Eye.svg';
import PropTypes from 'prop-types';

const Eye = (props) => {
  return <img {...props} src={eye} alt="eye"/>;
};
Eye.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
Eye.defaultProps = {
  height: '20px',
  width: '20px',
};
export default Eye;
