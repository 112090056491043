import React, { useState } from 'react';
import styled from 'styled-components';
import { Avatar } from '../../../Components/Atoms';
import Switch from '../../../Components/Atoms/Form/Switch';
import api from '../../../Util/api';
import { updateProfileOnlineStatus } from '../../../Actions/patient.actions';
import { useDispatch } from 'react-redux';

const TherapistMiniProfile = (props) => {
  const [value, setValue] = useState(props.user_online_status);
  const dispatch = useDispatch();

  const handleToggle = () => {
    api.therapist
      .setOnlineStatus(!value ? 1 : 0)
      .then((response) => response.data)
      .then((result) => {
        setValue(!value ? 1 : 0);
        dispatch(updateProfileOnlineStatus(!value ? 1 : 0));
      })
      .catch((error) => console.log('error', error));
  };
  return (
    <>
      <ProfileWrapper>
        <Avatar src={props.profile_image ? props.profile_image : undefined} width={50} />
        <Name>{`${props.first_name} ${props.last_name}`}</Name>
        <OnlineStatus>
          <OnlineStatusLabel>Status:</OnlineStatusLabel>
          <Switch isOn={value} handleToggle={handleToggle} />
        </OnlineStatus>
      </ProfileWrapper>
    </>
  );
};

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const OnlineStatus = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing};
`;

const OnlineStatusLabel = styled.span`
  margin-right: ${({ theme }) => theme.spacingSm};
`;
const Name = styled.div`
  margin-left: ${({ theme }) => theme.spacing};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export default TherapistMiniProfile;
