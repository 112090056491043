import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledHeading = styled.h1`
  color: ${({ theme }) => theme.primary};
  text-align: ${(props) => props.align};
  word-break: ${(props) => props.wordBreak || 'inherit'};
`;

const Heading = (props) => {
  return (
    <>
      <StyledHeading as={`h${props.level}`} {...props}>
        {props.text || props.children}
      </StyledHeading>
    </>
  );
};

Heading.propTypes = {
  level: PropTypes.number,
  align: PropTypes.string,
};
Heading.defaultProps = {
  level: 1,
  align: 'inherit',
};

export default memo(Heading);
