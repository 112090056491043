import React from 'react';
import styled from 'styled-components';

const WidgetGrid = (props) => {
  return <Grid {...props}>{props.children}</Grid>;
};
const Grid = styled.div`
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: ${({ theme }) => theme.spacingMd};
    grid-template-areas:
      "SC SC SC PA PA PA PA PA"
      "ST ST ST ST ST ST ST ST"
      "CA CA CA CA TS TS TS TS";
  `}
  ${({ theme }) => theme.lg`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: ${({ theme }) => theme.spacingMd};
    grid-template-areas:
      "SC SC SC SC PA PA PA PA PA PA PA PA"
      "ST ST ST ST ST ST ST ST ST ST ST ST"
      "CA CA CA CA CA CA TS TS TS TS TS TS";
  `}

  .Score {
    grid-area: SC;
  }
  .Patients {
    grid-area: PA;
  }
  .Calendar {
    grid-area: CA;
  }
  .Stats {
    grid-area: ST;
  }
  .Therapy-Satisfaction {
    grid-area: TS;
  }
  .widget {
    height: 100%;
  }
`;

export default WidgetGrid;
