import api from '../Util/api';
import { GET_PROFILE, UPDATE_PROFILE } from '../Constants/actionTypes';

export const getProfile = () => (dispatch) =>
  api.company
    .getProfile()
    .then((res) => {
      dispatch({
        type: GET_PROFILE,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const updateProfile = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .updateProfile(data)
      .then((res) => {
        dispatch({
          type: UPDATE_PROFILE,
          payload: res.data?.data,
        });
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );
