import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';

import { socialLogin, toggleSocialInformationRequest } from '../../Actions/patient.actions';

import SignupForm from './signUpForm';
import SocialSignupAdditionalInformation from './socialSignUpInformationForm';
import FacebookInitComponent from './facebookInitComponent';
import GoogleInitComponent from './googleInitComponent';

import { ReactComponent as EnvelopeIcon } from '../../Assets/SignUp/envelope.svg';
import { ReactComponent as BackIcon } from '../../Assets/SignUp/backVector.svg';
import ModalComponent from '../../Components/Atoms/Modal/Modal';
import { Heading } from '../../Components/Atoms';
import { useHistory } from 'react-router';

const SignUpComponent = ({ registerEnterprise = false, departments, isEnterprise }) => {
  const { t } = useTranslation();
  const [signUpEmailMode, setSignUpEmailMode] = useState(registerEnterprise);
  const [socialData, setSocialData] = useState(null);

  const modalState = useSelector((store) => store.auth.socialRequestPopupStatus);

  const dispatch = useDispatch();
  const history = useHistory();

  const toggleEmailMode = () => setSignUpEmailMode((state) => !state);

  const closeModal = () => dispatch(toggleSocialInformationRequest());

  const socialSignUp = useCallback(
    (data, type) => {
      setSocialData(data);
      if (type === 'google' && data) {
        const { googleId, email, name } = data;
        if (googleId) {
          const data = {
            email: email ? email : `${googleId}@example.com`,
            social_id: googleId,
            social_type: 'google',
            mobile: '00000000000',
            first_name: name,
          };
          dispatch(socialLogin(data, history))
            .then((response) => {
              console.log(response);
            })
            .catch((err) => {
              Store.addNotification({
                title: t('somethingWentWrong'),
                message: err.response?.data?.message,
                type: 'danger',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
            });
        }
      } else if (type === 'facebook' && data) {
        const { id, email, name } = data;
        if (id) {
          const data = {
            email: email ? email : `${id}@example.com`,
            social_id: id,
            social_type: 'facebook',
            mobile: '00000000000',
            first_name: name,
          };
          dispatch(socialLogin(data, history)).catch((err) => {
            Store.addNotification({
              title: t('somethingWentWrong'),
              message: err.response?.data?.message,
              type: 'danger',
              container: 'top-right',
              animationIn: ['animate__animated animate__fadeIn'],
              animationOut: ['animate__animated animate__fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          });
        }
      }
    },
    [dispatch, history],
  );

  if (!signUpEmailMode) {
    return (
      <MainSignUp>
        {modalState && (
          <ModalComponent
            open={modalState}
            closeModal={closeModal}
            showCloseButton={false}
            closeOnDocumentClick={false}
          >
            <div>
              <Heading level={3}>{t('needAdditionalInformation')}</Heading>
              <SocialSignupAdditionalInformation socialData={socialData} />
            </div>
          </ModalComponent>
        )}
        <GoogleInitComponent buttonText={t('signUpWithGoogle')} initAuth={socialSignUp} isEnterprise={isEnterprise} />
        <FacebookInitComponent buttonText={t('signUpWithFacebook')} initAuth={socialSignUp} />
        <SignUpButton onClick={toggleEmailMode}>
          <SocialIcon as={EnvelopeIcon} />
          <span>{t('signUpWithEmail')}</span>
        </SignUpButton>
        <TermsWrapper>
          <span>
            {t('continuingAbove')}{' '}
            <a
              href="https://mysupportroom.com/static/media/Patient%20T&Cs%20-%20SR.baad4c61.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('terms1')}
            </a>{' '}
            {t('and')}{' '}
            <a href="https://supportroom.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
              {t('privacyPolicy1')}
            </a>
            .
          </span>
        </TermsWrapper>
      </MainSignUp>
    );
  }

  return (
    <div>
      {!registerEnterprise && (
        <BackWrapper onClick={toggleEmailMode}>
          <StyledBackIcon as={BackIcon} />
          <span>{t('back')}</span>
        </BackWrapper>
      )}
      <SignUpEmailContent>
        <SignupForm registerEnterprise={registerEnterprise} departments={departments} isEnterprise={isEnterprise} />
      </SignUpEmailContent>
    </div>
  );
};

const TermsWrapper = styled.div`
  max-width: 200px;
  color: black;

  margin-top: 50px;

  a {
    color: black;
  }
`;

export const MainSignUp = styled.div`
  @media screen and (max-width: 455px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    ${TermsWrapper} {
      margin-top: 20px;
    }
  }
`;

export const SignUpButton = styled.div`
  position: relative;
  width: 260px;
  height: 42px;
  margin-bottom: 20px;

  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;

  font-size: 14px;
  font-weight: 500;
  color: #4e4e4e;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #fcfcfc;
  }

  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      background: #f3f3f3;
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
      &:hover {
        background: #f3f3f3;
      }
    `}
`;

export const SocialIcon = styled.svg`
  position: absolute;
  left: 15px;
  width: 20px;

  path {
    fill: #00c8a0;

    &:first-child {
      fill: #00c8a0 !important;
    }
  }
`;

const BackWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  span {
    margin-left: 10px;
    color: black;
    font-weight: 600;
  }

  cursor: pointer;
`;

const StyledBackIcon = styled.svg``;

const SignUpEmailContent = styled.div``;

SignUpComponent.propTypes = {
  registerEnterprise: PropTypes.bool,
};

export default memo(SignUpComponent);
