import React from "react";
import styled from "styled-components";
import { Container } from "../../Atoms";
import { useTranslation } from 'react-i18next';

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.primaryLight};
  margin-top: auto;
  height: ${({ theme }) => theme.footerHeight};
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing} 0;
`;

const Footer = () => {
  const { t } = useTranslation();
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <StyledFooter role="banner">
      <Container>
        <FooterContent>
        {t("copyright")} &copy; {currentYear} {t("supportRoom")}
        </FooterContent>
      </Container>
    </StyledFooter>
  );
};
export default Footer;
