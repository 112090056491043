import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const NotificationIndicator = ({ count, style = {} }) => {
  return count ? <StyledCount style={style}>{count}</StyledCount> : null;
};

const StyledCount = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  min-width: 15px;
  min-height: 15px;
  padding: 1px 5px;
  border-radius: 1em;
  background-color: ${({ theme }) => theme.statusDanger};
  color: ${({ theme }) => theme.white};
  font-size: 10px;
  font-weight: ${({ theme }) => theme.fontBold};
  line-height: normal;
`;
StyledCount.displayName = 'StyledCount';

NotificationIndicator.propTypes = {
  count: PropTypes.number,
};

export default NotificationIndicator;
