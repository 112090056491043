import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { addNotes, addTherapyJourney, getTherapyJourney } from '../../../Actions/chatroom.actions';
import { Button, Heading, Label } from '../../../Components/Atoms';
import * as Yup from 'yup';
import { getSubConcernigIssues } from '../../../Actions/therapist.actions';

const validationSchema = Yup.object().shape({
  subStage: Yup.string(),
  notes: Yup.string().required('Note text is required'),
  forms: Yup.array().of(
    Yup.object().shape({
      concerning_issue_id: Yup.string().required('Leading Issues is required'),
      sub_concerning_issue_id: Yup.string().required('Sub Leading Issues is required'),
    }),
  ),
});

const ConcerningIssues = ({ setShowConcerningIssueModal }) => {
  const [therapySubStages, setTherapySubStages] = useState([]);
  const [activeSubStage, setActiveSubStage] = useState(undefined);
  //   const [leading_issues, setLeadingIssues] = useState('');
  //   const [leading_issueErr, setLeadingIssueErr] = useState('');
  //   const [subLeadingIssues, setSubLeadingIssues] = useState('');
  //   const [subLeadingIssueErr, setSubLeadingIssueErr] = useState('');
  const therapyJourney = useSelector((store) => store.chatroom?.therapyJourney?.data);
  const concerningIssues = useSelector((store) => store.user?.concerningIssues);
  const subConcerningIssues = useSelector((store) => store.user?.subConcerningIssues);
  //   const rooms = useSelector((store) => store.chatroom.rooms);
  const chatRoomID = useSelector((store) => store.chatroom?.chatRoomID);
  const dispatch = useDispatch();

  const getTherapySubStages = useCallback(
    () =>
      therapyJourney?.length &&
      therapyJourney.forEach((stage) =>
        stage.sub_stages.forEach((subStage) => {
          if (subStage.active) setActiveSubStage(`${stage.id}-${subStage.sub_stage_id}`);
          setTherapySubStages((subStages) => [...subStages, { ...subStage, stageId: stage.id }]);
        }),
      ),
    [therapyJourney],
  );

  useEffect(() => {
    setTherapySubStages([]);
    getTherapySubStages();
  }, [getTherapySubStages]);

  const onSubmitNote = async (formData, { resetForm }) => {
    const sanitizedFormData = formData.forms.filter((form) => form.concerning_issue_id);
    formData.forms = sanitizedFormData;
    dispatch(addNotes(chatRoomID, formData)).then(() => dispatch(getTherapyJourney(chatRoomID)));
    resetForm();
    setShowConcerningIssueModal(false);
  };

  const journeyOnChange = useCallback(
    (idsArray) => {
      dispatch(
        addTherapyJourney({
          stage_id: parseInt(idsArray[0]),
          sub_stage_id: parseInt(idsArray[1]),
          chatroom_id: chatRoomID,
          template_id: 1,
        }),
      );
    },
    [dispatch, chatRoomID],
  );

  const formInitialValues = useMemo(() => {
    const initialValues = { concerning_issue_id: '', sub_concerning_issue_id: '' };
    return initialValues;
  }, []);

  return (
    <Formik
      initialValues={{ forms: [formInitialValues], notes: '' }}
      onSubmit={onSubmitNote}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, setFieldValue }) => {
        return (
          <Form>
            <FieldArray
              name="forms"
              render={(arrayHelpers) => (
                <div>
                  <Label htmlFor={`subStage`} style={{ marginTop: 20 }}>
                    Journey Stage
                  </Label>
                  <Field
                    as="select"
                    id={`subStage`}
                    name={`subStage`}
                    style={{ width: '100%', marginBottom: 15 }}
                    value={activeSubStage}
                    onChange={(e) => {
                      setActiveSubStage(e.target.value);
                      journeyOnChange(e.target.value.split('-'), e.target.value);
                    }}
                  >
                    {therapySubStages.map((subStage) => (
                      <option
                        key={subStage.sub_stage_id}
                        value={`${subStage.stageId}-${subStage.sub_stage_id}`}
                        label={subStage.sub_stage_name}
                      />
                    ))}
                  </Field>

                  {values?.forms?.map?.((formItem, i) => {
                    let index = values?.forms?.length - 1 - i;
                    let concerning_issue_id = values?.forms[values?.forms?.length - 1 - i]?.concerning_issue_id;

                    return (
                      <>
                        <div
                          style={{
                            border: '2.5px solid',
                            padding: '12px',
                            marginBottom: 15,
                          }}
                        >
                          <Heading style={{ fontSize: 18 }}>Concerning Issue {i + 1}</Heading>
                          <div key={index}>
                            <Label htmlFor={`forms.${index}.concerning_issue_id`}>Leading Issues</Label>
                            <Field
                              as="select"
                              id={`forms.${index}.concerning_issue_id`}
                              name={`forms.${index}.concerning_issue_id`}
                              className={
                                'form-control ' +
                                (errors.forms?.[index]?.concerning_issue_id &&
                                touched.forms?.[index]?.concerning_issue_id
                                  ? 'is-invalid'
                                  : '')
                              }
                              style={{ width: '100%', marginBottom: 15 }}
                              onChange={(e) => {
                                setFieldValue(`forms.${index}.concerning_issue_id`, e.target.value);
                                dispatch(getSubConcernigIssues(e.target.value));
                              }}
                            >
                              {<option key="placeholder" value={''} label={'Select Leading issue'} />}
                              {concerningIssues?.map((subStage) => (
                                <option key={subStage.id} value={subStage.id} label={subStage.title} />
                              ))}
                            </Field>
                            <ErrorMessage
                              name={`forms.${index}.concerning_issue_id`}
                              component="div"
                              className="invalid-feedback"
                            />

                            {/* {!!leading_issueErr && (
                              <div style={{ color: '#ac1c1c', margin: '2px 0 4px' }}>{leading_issueErr}</div>
                            )} */}
                            {subConcerningIssues?.[concerning_issue_id]?.length > 0 && (
                              <>
                                <Label htmlFor={`forms.${index}.sub_concerning_issue_id`}>Sub Leading Issues</Label>
                                <Field
                                  as="select"
                                  id={`forms.${index}.sub_concerning_issue_id`}
                                  name={`forms.${index}.sub_concerning_issue_id`}
                                  style={{ width: '100%', marginBottom: 15 }}
                                  className={
                                    'form-control ' +
                                    (errors.forms?.[index]?.sub_concerning_issue_id &&
                                    touched.forms?.[index]?.sub_concerning_issue_id
                                      ? 'is-invalid'
                                      : '')
                                  }
                                >
                                  {<option key="placeholder" value={''} label={'Select Sub Leading Issue'} />}
                                  {subConcerningIssues?.[concerning_issue_id]?.map((subStage) => (
                                    <option key={subStage.id} value={subStage.id} label={subStage.title} />
                                  ))}
                                </Field>
                                {/* {!!subLeadingIssueErr && (
                                  <div style={{ color: '#ac1c1c', margin: '2px 0 4px' }}>{subLeadingIssueErr}</div>
                                )} */}
                                {/* {subConcerningIssues?.[concerning_issue_id]?.length > 0 && <ErrorMessage name={`forms.${index}.sub_concerning_issue_id`} component="div" className="invalid-feedback" />} */}
                                <ErrorMessage
                                  name={`forms.${index}.sub_concerning_issue_id`}
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </>
                            )}
                          </div>
                          <SubmitButtonWrapper>
                            <Button
                              disabled={values?.forms?.length === 1}
                              onClick={() => arrayHelpers.remove(index)} // remove a form from the list of forms
                            >
                              DELETE
                            </Button>

                            {values?.forms?.length - 1 === i && (
                              <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => arrayHelpers.insert(index, formInitialValues)} // insert an empty string at a position

                                //  disabled={!inputField.valueEn || !inputField.valueEn.trimEnd().length > 1}
                              >
                                Add New
                              </Button>
                            )}
                          </SubmitButtonWrapper>
                        </div>
                      </>
                    );
                  })}

                  <Label htmlFor="notes">Patient Notes</Label>
                  <Field
                    name="notes"
                    placeholder="Type note..."
                    className={'form-control' + (errors.notes && touched.notes ? ' is-invalid' : '')}
                    component="textarea"
                    style={{ resize: 'none' }}
                    onChange={(e) => {
                      setFieldValue('notes', e.target.value);
                    }}
                  />
                  <ErrorMessage name="notes" component="div" className="invalid-feedback" />
                </div>
              )}
            />
            <SubmitButtonWrapper>
              <Button type="submit">Submit</Button>
            </SubmitButtonWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ConcerningIssues;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
SubmitButtonWrapper.displayName = 'SubmitButtonWrapper';
