import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useHistory } from 'react-router';

import * as Yup from 'yup';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Formik, Form, Field, ErrorMessage } from 'formik';

import { FormGroup, Alert, Input } from '../../Components/Atoms';

import api from '../../Util/api';
import { passwordValidation } from '../../Constants/validations';

import VectorDownIcon from '../../Assets/SignUp/vectorDown.svg';
import { ReactComponent as EyeSlash } from '../../Assets/Icons/EyeSlash.svg';
import { ReactComponent as Eye } from '../../Assets/Icons/Eye.svg';
import { useLocation } from 'react-router-dom';
import notify from '../../Util/notify';
import { useTranslation } from 'react-i18next';

const SocialSignupAdditionalInformation = () => {
  const [countriesList, setCountriesList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [responseMessage] = useState({ type: '', message: null });
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(null);
  const { t } = useTranslation();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const gymPassUserId = query.get('gympass_user_id');
  const questionnaireId = query.get('questionnaire_id');

  const history = useHistory();

  const getCountriesList = () => {
    api.others
      .getCountriesList()
      .then((response) => {
        if (response.status === 200 && response.data?.data?.length) {
          setCountriesList(response.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const togglePassword = () => setShowPassword(!showPassword);

  const toggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleOnSubmit = async ({
    first_name,
    last_name,
    mobile,
    country_id,
    email,
    password,
    password_confirmation,
  }) => {
    setLoading(true);

    try {
      const res = await api.gymPass.signup({
        first_name,
        last_name,
        mobile,
        country_id,
        email,
        password,
        password_confirmation,
        gympass_user_id: gymPassUserId,
        quiz_group: questionnaireId,
      });

      if (res.data.status === 'success') {
        notify('SignUp Succeeded', t("accountCreationDone"), 'success');
        history.push('/welcome/signin');
      }

      if (res.data?.status === 'error') {
        setLoading(false);
        notify('SignUp Error', res.data?.message, 'danger');
      }
    } catch (err) {
      setLoading(false);
      notify('SignUp Error', err.response?.data?.message, 'danger');
    }
  };

  const validationSchema = useMemo(() => {
    const validations = {
      first_name: Yup.string().trim().required(t("firstNameIsRequired")),
      last_name: Yup.string().trim().required(t("lastNameIsRequired")),
      mobile: Yup.string().required(t("mobileIsRequired")),
      email: Yup.string().email(t("emailIsInvalid")).required(t("emailIsRequired")),
      password: passwordValidation(t),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], t("passwordsMustMatch"))
        .required(t("confirmPasswordIsRequired")),
      country_id: Yup.string().required(t("countryIsRequired")),
    };

    return Yup.object().shape(validations);
  }, []);

  const initialValues = useMemo(() => {
    return {
      first_name: '',
      last_name: '',
      mobile: '',
      country_id: countriesList.find((country) => country.name === 'United Kingdom')?.id,
      password: '',
      password_confirmation: '',
    };
  }, [countriesList]);

  const mobileOnChange = (phone, setFieldValue) => {
    setFieldValue('mobile', phone);
    setPhone(phone);
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form noValidate>
            <StyledFormGroup>
              <FieldWrapper>
                <label htmlFor="first_name">{t("firstNameImportant")}</label>
                <Field
                  id="first_name"
                  name="first_name"
                  type="text"
                  className={'form-control' + (errors.first_name && touched.first_name ? ' is-invalid' : '')}
                />
                <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
              </FieldWrapper>
              <FieldWrapper>
                <label htmlFor="last_name">{t("lastNameImportant")}</label>
                <Field
                  id="last_name"
                  name="last_name"
                  type="text"
                  className={'form-control' + (errors.last_name && touched.last_name ? ' is-invalid' : '')}
                />
                <ErrorMessage name="last_name" component="div" className="invalid-feedback" />
              </FieldWrapper>
            </StyledFormGroup>
            <StyledFormGroup>
              <FieldWrapper style={{ width: '100%' }}>
                <label htmlFor="mobile">{t("mobile")}</label>
                <MobileWrapper>
                  <PhoneInput
                    id="mobile"
                    containerClass={errors.mobile && touched.mobile ? ' is-invalid' : ''}
                    value={phone}
                    onChange={(phone) => mobileOnChange(phone, setFieldValue)}
                    placeholder={t("choseCountryAndMobile")}
                    // country="gb"
                    isValid={(number, country) => {
                      if (number && !country) return t("countrySelectionIsMandatory");
                      return !(number && !country);
                    }}
                    enableSearch
                  />
                </MobileWrapper>
                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
              </FieldWrapper>
            </StyledFormGroup>
            <StyledFormGroup>
              <FieldWrapper>
                <label htmlFor="email">{t("email")}</label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FieldWrapper>
              <FieldWrapper>
                <label htmlFor="country_id">{t("country")}</label>
                <Field
                  as="select"
                  id="country_id"
                  name="country_id"
                  className={'form-control' + (errors.country_id && touched.country_id ? ' is-invalid' : '')}
                  required
                >
                  {!countriesList?.length && (
                    <option value="" hidden>
                    {t("unitedKingdom")}
                    </option>
                  )}
                  {countriesList &&
                    countriesList.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                </Field>
                <ErrorMessage name="country" component="div" className="invalid-feedback" />
              </FieldWrapper>
            </StyledFormGroup>
            <StyledFormGroup>
              <FieldWrapper>
                <label htmlFor="password">{t("selectPassword")}</label>
                <Field
                  id="password"
                  name="password"
                  as={Input}
                  icon={showPassword ? EyeSlash : Eye}
                  onIconClick={togglePassword}
                  type={showPassword ? 'text' : 'password'}
                  className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                />
                <ErrorMessage name="password" component="div" className="invalid-feedback" />
              </FieldWrapper>
              <FieldWrapper>
                <label htmlFor="last_name">{t("reEnterPassword")}</label>
                <Field
                  id="password_confirmation"
                  name="password_confirmation"
                  as={Input}
                  icon={showConfirmPassword ? EyeSlash : Eye}
                  onIconClick={toggleConfirmPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  className={
                    'form-control' +
                    (errors.password_confirmation && touched.password_confirmation ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage name="password_confirmation" component="div" className="invalid-feedback" />
              </FieldWrapper>
            </StyledFormGroup>

            {responseMessage.type ? (
              <StyledAlert className={responseMessage.type}>{responseMessage.message}</StyledAlert>
            ) : null}

            <SubmitButtonWrapper type="submit">
              {/*<div>Start Your Free Assessment</div>*/}
              <SignUpButton type="submit" disabled={loading}>
                {loading && (
                  <LoadingDots>
                    <div />
                    <div />
                    <div />
                    <div />
                  </LoadingDots>
                )}
                <span style={{ opacity: loading ? 0 : 1 }}>{t("signUpNow")}</span>
              </SignUpButton>
            </SubmitButtonWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const StyledAlert = styled(Alert)`
  border-color: #d2d2d2 !important;
  background: #ffffff !important;
  display: inline-flex;
  border-radius: 2px;
  color: red;
`;
StyledAlert.displayName = 'StyledAlert';

export const FieldWrapper = styled.div`
  width: 48%;

  input {
    margin-top: 5px;
  }

  select {
    margin-top: 5px;
  }

  label {
    font-size: 16px;
    font-weight: 600;
    color: black;
  }
`;

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: initial;
  justify-content: space-between;

  input,
  select {
    width: 100%;
    height: 40px;
    flex-grow: initial;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    font-size: 16px;

    &:focus {
      border: 2px solid #00c8a0;
    }
  }

  input {
    svg {
      path {
        fill: #797979 !important;
      }
    }
  }

  label {
    span {
      border-radius: 0;
      border-color: #c4c4c4;

      a {
        color: black;
      }
    }

    &:hover {
      .checkmark {
        background: #f6f6f6;
      }
    }
  }

  input:checked ~ .checkmark {
    background-color: #00c8a0;
  }

  select {
    padding: 0 8px;
    background: url(${VectorDownIcon}) no-repeat right;
    -webkit-appearance: none;
    background-position-x: calc(100% - 10px);

    &:required {
      &:invalid {
        color: #797979;
      }
    }
  }

  @media screen and (max-width: 455px) {
    flex-wrap: wrap;
    margin-bottom: 0;

    ${FieldWrapper} {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export const SubmitButtonWrapper = styled.div`
  width: 244px;
  font-size: 17px;
  font-weight: 600;
  color: black;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin: 40px auto 0 auto;
`;

const MobileWrapper = styled.div`
  margin-top: 5px;
  .is-invalid {
    input {
      border-color: #ac1c1c !important;
    }
    .flag-dropdown {
      border-color: #ac1c1c !important;
    }
  }
  .react-tel-input {
    .form-control {
      width: 100%;
      border-radius: 0;
      height: 40px;
    }
  }
  .selected-flag {
    border-radius: 0;
  }
  .flag-dropdown {
    border-radius: 0;
  }
  .country-list {
    padding: 0 10px;

    .search {
      display: flex;
      align-items: center;

      input {
        height: 30px;
        width: calc(100% - 30px);
      }
    }
  }
  li {
    width: 100% !important;
  }

  @media screen and (max-width: 400px) {
    .country-list {
      width: 250px;
    }
  }
`;

export const SignUpButton = styled.button`
  position: relative;
  display: inline-flex;
  background: #00c8a0;
  padding: 17px 65px;

  outline: none;

  justify-content: center;
  align-items: center;

  font-size: 18px;
  color: white;
  font-weight: 600;
  border: none;

  cursor: pointer;

  &:hover {
    background: #00b891;
  }
`;

export const LoadingDots = styled.div`
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 20px;

  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  div {
    position: absolute;
    top: 4px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }
`;

SocialSignupAdditionalInformation.propTypes = {
  userType: PropTypes.string,
  heading: PropTypes.string,
  employeeData: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    departments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
  }),
};

SocialSignupAdditionalInformation.defaultProps = {
  userType: 'patient',
  heading: 'Create your account',
  employeeData: null,
};

export default SocialSignupAdditionalInformation;
