import React, { memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { getNotes } from '../../Actions/chatroom.actions';
import { Heading, Widget } from '../../Components/Atoms';
import { DashboardLayout } from '../../Layout';

const Note = () => {
  const { chatRoomId, noteId } = useParams();

  const notes = useSelector((store) => store.chatroom.notes);
  const concerningIssues = useSelector((store) => store.user?.concerningIssues);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotes(chatRoomId));
  }, [dispatch, chatRoomId]);

  const note = useMemo(() => {
    if (!notes || !notes.length) return [];

    if (notes.length) {
      return notes.find((note) => note.id === parseInt(noteId));
    }
  }, [notes, noteId]);

  const matchingConcerningIssue = useMemo(() => {
    const matchedIssue = concerningIssues?.find((issue) => issue?.id === +note?.leading_issues);
    return matchedIssue?.title;
  }, [note, concerningIssues]);

  return (
    <DashboardLayout>
      <Heading>Patient Profile</Heading>
      <StyledWidget>
        <>
          <h3>Note Title</h3>
          <p className="lead">Date: {note && moment(note.created_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')}</p>
          {<p>Patient Notes: {note?.notes}</p>}
          {matchingConcerningIssue && <p>Leading Issue : {matchingConcerningIssue}</p>}
          {note?.subLeadingIssues && <p>Sub Leading Issue : {note?.subLeadingIssues}</p>}
        </>
      </StyledWidget>
    </DashboardLayout>
  );
};

const StyledWidget = styled(Widget)`
  margin-bottom: ${({ theme }) => theme.spacingMd};
`;

export default memo(Note);
