import { get } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { api } from '../../../Util';

const PatientTherapySatisfaction = () => {
  const chartElement = useRef(null);
  const therapistId = useSelector((store) => store.user?.profile?.id);
  const [feedbackdata, setDoughnutData] = useState({ 'Extremely Satisfied': 0, Satisfied: 0, Unsatisfied: 0 });

  const getTherapistFeedbacks = useCallback(() => {
    api.therapist
      .getFeedbacks(therapistId)
      .then((response) => {
        return response.data;
      })
      .then((feebacks) => {
        const feebacksArray = get(feebacks, 'data', []);
        const feedbacksData = feebacksArray.reduce(
          (acc, { rating }) => {
            if (rating < 2) {
              acc.Unsatisfied += 1;
            } else if (rating >= 2 && rating <= 4) {
              acc.Satisfied += 1;
            } else if (rating > 4) {
              acc['Extremely Satisfied'] += 1;
            }
            return acc;
          },
          { 'Extremely Satisfied': 0, Satisfied: 0, Unsatisfied: 0 },
        );
        if (feebacksArray.length) {
          Object.keys(feedbacksData).forEach((key) => {
            feedbacksData[key] = ((feedbacksData[key] / feebacksArray.length) * 100).toFixed(2);
          });
        }
        setDoughnutData(feedbacksData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [therapistId]);

  useEffect(() => {
    therapistId && getTherapistFeedbacks(therapistId);
  }, [therapistId, getTherapistFeedbacks]);

  const chartData = {
    labels: Object.keys(feedbackdata),
    datasets: [
      {
        backgroundColor: ['#00C8A0', '#1A5549', '#C4C4C4'],
        borderWidth: 3,
        data: Object.values(feedbackdata),
      },
    ],
  };

  const isValidSatisfactionData = Object.values(feedbackdata).some((val) => val !== 0);

  const chartOptions = {
    legend: {
      position: 'left',
    },
    rotation: -1 * Math.PI,
  };

  return (
    <>
      <h4>Patient Therapy Satisfaction</h4>
      {isValidSatisfactionData ? (
        <Doughnut ref={chartElement} data={chartData} options={chartOptions} />
      ) : (
        <h5 style={{ textAlign: 'center', margin: '30px' }}>No values input</h5>
      )}
    </>
  );
};

export default PatientTherapySatisfaction;
