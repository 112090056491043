import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StatsGrid, WidgetGrid } from './Components';
import { SatisfactionScore, Availability, PatientList } from './Widgets';
import PatientTherapySatisfaction from './Widgets/PatientTherapySatisfaction';
import { Heading, ModalComponent, Widget, Button } from '../../Components/Atoms';
import Tooltip from '../../Components/Atoms/Tooltip/Tooltip';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const Overview = (props) => {
  const profile = useSelector((store) => store.user?.profile);
  const history = useHistory();
  return (
    <>
      <ModalComponent open={profile && !profile?.availability}>
        <Heading level={3}>No availability found for next week</Heading>
        <p>
          We politely request that you update your availablity in the "my availablity" section of the calendar. As new
          patients now have the option to book an introductory chat with you on their first interaction. We hope this
          will improve the customer experience. You can repeat your availaiblity every week or even every four weeks by
          clicking on the "repeat availablity" button.{' '}
        </p>
        <Button onClick={() => history.push('/t/dashboard/calendar')}>Add availability</Button>
      </ModalComponent>
      {props.showCompletionAlert && (
        <Alert>
          Your profile is not complete. Please make sure all required information is completed.{' '}
          <a href="/t/signup">Update Profile</a>
        </Alert>
      )}

      <WidgetGrid>
        <div className="Score">
          <Widget align="center" vertical="center">
            <SatisfactionScore />
          </Widget>
        </div>
        <div className="Patients">
          <Widget>
            <h4>Recent Active Patients</h4>
            <div style={{ overflowX: 'auto' }}>
              <PatientList limit={4} segregatePatientStatus showActivePatients />
            </div>
            <ViewMoreLink to="/t/dashboard/patients">View all patients</ViewMoreLink>
          </Widget>
        </div>
        <div className="Calendar">
          <Tooltip
            id="MyAvailability"
            text="View your availability. Remember to keep your availability up to date"
            place="top"
          >
            <Widget>
              <h4>My Availability</h4>
              <Availability />
            </Widget>
          </Tooltip>
        </div>
        <div className="Stats">
          <StatsGrid />
        </div>
        <div className="Therapy-Satisfaction">
          <Tooltip
            id="PatientTherapySatisfaction"
            text="Patients have rated you based on their  therapy sessions"
            place="top"
          >
            <Widget>
              <PatientTherapySatisfaction />
            </Widget>
          </Tooltip>
        </div>
      </WidgetGrid>
    </>
  );
};

const Alert = styled(Widget)`
  background-color: #e06c00;
  text-align: center;
  color: white;
  font-size: 16px;
  padding: ${({ theme }) => theme.spacing};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  a {
    font-weight: ${({ theme }) => theme.fontBold};
    color: ${({ theme }) => theme.white};
  }
`;

const ViewMoreLink = styled(Link)`
  display: block;
  margin-top: ${({ theme }) => theme.spacingLg};
  text-align: right;
  color: ${({ theme }) => theme.primaryMedium};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export default Overview;
