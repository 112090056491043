import api from '../Util/api';
import {
  GET_MESSAGES,
  ADD_NEW_MESSAGE,
  GET_CHAT_ROOMS,
  GET_CHAT_RESOURCES,
  SET_CHAT_ROOM_ERROR,
  CREATE_VIDEO_ROOM,
  SET_VIDEO_ROOM,
  LAUNCH_VIDEO_ROOM,
  LEAVE_VIDEO_ROOM,
  DESTROY_VIDEO_ROOM,
  GET_CHATROOM_SCHEDULE_LIST,
  SET_VIDEO_ROOM_ERROR,
  GET_JOURNALS,
  ADD_JOURNAL,
  UPDATE_JOURNAL,
  DELETE_JOURNAL,
  GET_THERAPY_JOURNEY,
  GET_TEMPLATES_STAGES,
  GET_NOTIFICATIONS,
  GET_ANNOUNCEMENTS,
  CLEAR_CHAT_ROOM,
  GET_NOTES,
  ADD_NOTES,
  CAN_SUBMIT_JOURNEY_FEEDBACK,
  SUBMIT_JOURNEY_FEEDBACK,
  UPDATE_FLAG,
  GET_STATS,
  GET_FAQ_CATEGORIES_LIST,
  GET_FAQ_CATEGORIES,
  GET_FAQ_LISTING,
  SWITCH_THERAPIST,
  GET_UNREAD_MESSAGES,
  GET_MORE_NOTIFICATIONS,
  SHARE_JOURNAL_WITH_THERAPIST,
  GET_THERAPIST_SCHEDULE_LIST,
  HIDE_ANNOUNCEMENT,
} from '../Constants/actionTypes';
import moment from 'moment';

export const getMessages = (chatroomID, pageLimit) => (dispatch) =>
  api.chatroom
    .getMessageHistory(chatroomID, pageLimit)
    .then((res) => {
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const addNewMessages = (message) => {
  return {
    type: ADD_NEW_MESSAGE,
    payload: message,
  };
};

export const sendMessage = (chatroomID, messageText) => () =>
  api.chatroom
    .sendMessage(chatroomID, messageText)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);

export const sendFile = (chatroomID, file) => () =>
  api.chatroom
    .sendFile(chatroomID, file)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);

export const getActiveChatRooms =
  (role = 'patient', roomID = null) =>
  (dispatch) =>
    api.chatroom
      .activeChatRooms()
      .then((res) => {
        const filteredData = res.data?.data.filter((room) => room.therapist_id !== 0);
        dispatch({
          type: GET_CHAT_ROOMS,
          payload: { rooms: filteredData, role, roomID, status: res?.data?.status },
        });
        return res.data?.data;
      })
      .catch((err) => err);

export const getChatResources = (id) => (dispatch) =>
  api.chatroom
    .getChatResources(id)
    .then((res) => {
      dispatch({
        type: GET_CHAT_RESOURCES,
        payload: res.data?.data,
      });
      return res.data?.data;
    })
    .catch((err) => err);

export const setChatRoomError = (error) => (dispatch) =>
  dispatch({
    type: SET_CHAT_ROOM_ERROR,
    payload: error,
  });

export const createVideoRoom = (chatRoomID) => (dispatch) => {
  return api
    .videoRoomRequest('/rooms', 'POST', {
      name: `SR-${chatRoomID}`,
      properties: {
        exp: moment.utc().add(180, 'minutes').unix(),
      },
    })
    .then(function (response) {
      dispatch({
        type: CREATE_VIDEO_ROOM,
        payload: response.data,
      });
      return;
    })
    .catch(function (error) {
      console.error({ error });
    });
};

export const setVideoRoom = (data) => (dispatch) => {
  dispatch({
    type: SET_VIDEO_ROOM,
    payload: data,
  });
  return;
};

export const setVideoRoomError = (error) => (dispatch) =>
  dispatch({
    type: SET_VIDEO_ROOM_ERROR,
    payload: error,
  });

export const launchVideoRoom = () => (dispatch) => {
  dispatch({
    type: LAUNCH_VIDEO_ROOM,
  });
  return;
};

export const subtractVideoFromPatient = (chatRoomId) => () => api.patient.endVideoCall(chatRoomId);

export const reduceVideoSession = (data) => (dispatch) => {
  return api.chatroom
    .reduceVideoSession(data)
    .then((res) => {
      return res;
    })
    .catch((err) => err);
};

export const leaveVideoRoom = () => (dispatch) =>
  dispatch({
    type: LEAVE_VIDEO_ROOM,
  });

export const destroyVideoRoom = (roomId) => (dispatch) =>
  api.videoRoomRequest(`/rooms/SR-${roomId}`, 'DELETE').finally(() => {
    dispatch({
      type: DESTROY_VIDEO_ROOM,
    });
    return;
  });

export const getScheduleList = (id) => (dispatch) => {
  const start = moment().utc();
  const end = moment().utc();
  end.add(2, 'weeks');

  return api.chatroom
    .getScheduleList(id, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
    .then((res) => {
      dispatch({
        type: GET_CHATROOM_SCHEDULE_LIST,
        payload: res?.data?.data,
      });
      dispatch({
        type: GET_THERAPIST_SCHEDULE_LIST,
        payload: res?.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);
};

export const getFullScheduleList = async (id, startDays = 3, endDays = 3) => {
  const start = moment().subtract(startDays, 'd');
  const end = moment().add(endDays, 'd');

  return await api.chatroom
    .getFullScheduleList(id, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
    .then((res) => {
      return res?.data?.data;
    })
    .catch((err) => err);
};

export const cancelAppointments = (chatRoomId, appointmentId) => (dispatch) =>
  api.general
    .cancelAppointments(appointmentId)
    .then((res) => {
      dispatch(getScheduleList(chatRoomId));
      return res.data;
    })
    .catch((err) => err);

export const cancelAppointmentsAsTherapist = (appointmentId) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.general
      .cancelAppointments(appointmentId)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const getJournals = (pageNo, chatRoomId) => (dispatch) =>
  api.chatroom
    .getJournals(pageNo, chatRoomId)
    .then((res) => {
      dispatch({
        type: GET_JOURNALS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const addJournal = (data) => (dispatch) =>
  api.chatroom
    .addJournal(data)
    .then((res) => {
      dispatch({
        type: ADD_JOURNAL,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const updateJournal = (data) => (dispatch) =>
  api.chatroom
    .updateJournal(data)
    .then((res) => {
      dispatch({
        type: UPDATE_JOURNAL,
        payload: data,
      });
      return res.data;
    })
    .catch((err) => err);

export const deleteJournal = (id) => (dispatch) =>
  api.chatroom
    .deleteJournal(id)
    .then((res) => {
      dispatch({
        type: DELETE_JOURNAL,
        payload: id,
      });
      return res.data;
    })
    .catch((err) => err);

export const addTherapyJourney = (data) => (dispatch) =>
  api.chatroom
    .addTherapyJourney(data)
    .then((res) => {
      dispatch(getTherapyJourney(data.chatroom_id));
      return res.data;
    })
    .catch((err) => err);

export const getSubmitFeedback =
  (id, CB = () => null) =>
  (dispatch) =>
    api.chatroom
      .canSubmitFeedback(id)
      .then((res) => {
        dispatch({
          type: CAN_SUBMIT_JOURNEY_FEEDBACK,
          payload: res.data,
        });
        CB(false);
        return res.data;
      })
      .catch((err) => err);

export const submitFeedback = (data) => (dispatch) =>
  api.chatroom
    .submitFeedback(data)
    .then((res) => {
      dispatch({
        type: SUBMIT_JOURNEY_FEEDBACK,
      });
      return res.data;
    })
    .catch((err) => err);

export const getTherapyJourney = (id, role, CB) => (dispatch) =>
  api.chatroom
    .getTherapyJourney(id)
    .then((res) => {
      if (role === 'patient') dispatch(getSubmitFeedback(id, CB));
      dispatch({
        type: GET_THERAPY_JOURNEY,
        payload: res.data.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getTemplatesStages = (id) => (dispatch) =>
  api.chatroom
    .getTemplatesStages(id)
    .then((res) => {
      dispatch({
        type: GET_TEMPLATES_STAGES,
        payload: res?.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getJourneyTemplates = () => () =>
  api.chatroom
    .getJourneyTemplates()
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);

export const getAnnouncements = (pagination) => (dispatch) =>
  api.chatroom
    .getAnnouncements(pagination)
    .then((res) => {
      dispatch({
        type: GET_ANNOUNCEMENTS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getNotifications = (pagination) => (dispatch) =>
  api.chatroom
    .getNotifications(pagination)
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getMoreNotifications = (pagination) => ({
  type: GET_MORE_NOTIFICATIONS,
  payload: pagination,
});

export const handleMarkRead = (id) => () =>
  api.chatroom
    .handleMarkRead(id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);

export const handleAnnouncementMarkRead =
  (id, clearAnnouncement = true) =>
  (dispatch) =>
    api.chatroom
      .handleAnnouncementMarkRead(id)
      .then((res) => {
        // if announcement is rendered, mark it as read but clear it only on route change or reload
        if (clearAnnouncement) {
          dispatch({
            type: HIDE_ANNOUNCEMENT,
          });
        }
      })
      .catch((err) => err);

export const handleChatRoomRequest = (chatRoomId, status, notificationId) => (dispatch) =>
  api.chatroom
    .handleChatRoomRequest(chatRoomId, status)
    .then((res) => {
      dispatch(handleMarkRead(notificationId));
      return res.data;
    })
    .catch((err) => err);

export const clearChatRoom = () => (dispatch) => dispatch({ type: CLEAR_CHAT_ROOM });

export const getNotes = (chatroom_id) => (dispatch) =>
  api.chatroom
    .getNotes(chatroom_id)
    .then((res) => {
      dispatch({
        type: GET_NOTES,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const addNotes = (chatroom_id, formData) => (dispatch) =>
  api.chatroom
    .addNotes(chatroom_id, formData)
    .then((res) => {
      dispatch({
        type: ADD_NOTES,
        payload: res.data?.data,
      });
      dispatch(getNotes(chatroom_id));
      return res.data?.data;
    })
    .catch((err) => err);

export const updateFlag = (data) => (dispatch) =>
  api.chatroom
    .updateFlag(data)
    .then((res) => {
      dispatch({
        type: UPDATE_FLAG,
        payload: data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getStats =
  (date = null) =>
  (dispatch) =>
    api.chatroom
      .getStats(date)
      .then((res) => {
        dispatch({
          type: GET_STATS,
          payload: res.data?.data,
        });
        return res.data;
      })
      .catch((err) => err);

export const getFaqCategoriesList = (type) => (dispatch) =>
  api.chatroom
    .getFaqCategoriesList(type)
    .then((res) => {
      dispatch({
        type: GET_FAQ_CATEGORIES_LIST,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getFaqCategories = () => (dispatch) =>
  api.chatroom
    .getFaqCategories()
    .then((res) => {
      dispatch({
        type: GET_FAQ_CATEGORIES,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getFaqListing = (categoryID) => (dispatch) =>
  api.chatroom
    .getFaqListing(categoryID)
    .then((res) => {
      dispatch({
        type: GET_FAQ_LISTING,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const switchTherapist =
  ({ chatroom_id, forward_notes, shorthand_notes, notification_id }) =>
  (dispatch) =>
    api.chatroom
      .switchTherapist({ chatroom_id, forward_notes, shorthand_notes })
      .then((res) => {
        dispatch({
          type: SWITCH_THERAPIST,
          payload: res.data?.data,
        });
        if (notification_id) dispatch(handleMarkRead(notification_id));
        return res.data;
      })
      .catch((err) => err);

export const getUnreadMessages = () => (dispatch) =>
  api.chatroom
    .getUnreadMessages()
    .then((res) => {
      dispatch({
        type: GET_UNREAD_MESSAGES,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const shareJournalWithTherapist = (id, status) => (dispatch) =>
  api.chatroom
    .shareJournal(id, status)
    .then((res) => {
      dispatch({
        type: SHARE_JOURNAL_WITH_THERAPIST,
        payload: { id, status },
      });
      return res.data;
    })
    .catch((err) => err);

export const readUnreadMessages = (messageIDs) => (dispatch) =>
  api.chatroom
    .readUnreadMessages(messageIDs)
    .then((res) => {
      dispatch(getUnreadMessages());
      return res.data;
    })
    .catch((err) => err);
