import React, { useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const EmployeeTherapySatisfaction = () => {
  const { t } = useTranslation();
  const chartElement = useRef(null);

  const chartData = {
    labels: [t("extremelySatisfied"), t("satisfied"), t("unsatisfied")],
    datasets: [
      {
        backgroundColor: ['#00C8A0', '#1A5549', '#C4C4C4'],
        borderWidth: 3,
        data: [50, 35, 15],
      },
    ],
  };

  const chartOptions = {
    // legend: {
    //   position: 'bottom',
    // },
    rotation: -1 * Math.PI,
  };

  return (
    <>
      <h4>{t("employeeTherapySatisfaction")}</h4>
      <Doughnut ref={chartElement} data={chartData} options={chartOptions} />
    </>
  );
};

export default EmployeeTherapySatisfaction;
