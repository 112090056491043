import React, { memo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getSubscriptionStatus, redeemVoucher } from '../../../Actions/patient.actions';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Widget, Input } from '../../Atoms';

const VoucherPlan = () => {
  const { t } = useTranslation();
  const [voucher, setVoucher] = useState('');
  const [isUnlocked, setIsUnlocked] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const unlockAccess = () => setIsUnlocked((state) => !state);
  const onChange = (e) => setVoucher(e.target.value);
  const submitVoucher = () =>
    dispatch(redeemVoucher(voucher))
      .then(() => {
        setVoucher('');
        setIsUnlocked(false);
        dispatch(getSubscriptionStatus()).then(() => history.push('/'));
      })
      .catch(() => {
        setVoucher('');
        setIsUnlocked(false);
      });

  return (
    <PlanWrapper>
      <Heading level={2}>{t("businessPass")} </Heading>
      <Heading level={4} style={{ height: "auto", marginBottom:"20px" }}>
      {t("b2BPass")}
      </Heading>
      <div style={{ marginBottom: 40 }}>
        <span>{t("employerPurchase")}</span>
      </div>

      {isUnlocked && <Input placeholder={t("voucherCode")} value={voucher} onChange={onChange} />}

      <ButtonWrapper>
        {isUnlocked ? (
          <Button onClick={submitVoucher} variant="primary" disabled={!voucher}>
          {t("submitVoucher")}
          </Button>
        ) : (
          <Button onClick={unlockAccess} variant="primary">
          {t("unlockAccess")}
          </Button>
        )}
      </ButtonWrapper>
    </PlanWrapper>
  );
};

const PlanWrapper = styled(Widget)`
  position: relative;
  max-width: 300px;
  min-width: 300px;

  min-height: 360px;

  background: #d5e1e6;
  border: 1px solid #d5e1e6;

  margin: 20px;

  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 10px;

  &:hover {
    border: 1px solid #c6c6c6 !important;
  }

  @media screen and (max-width: 977px) {
    max-width: 300px !important;
  }

  @media screen and (max-width: 1097px) {
    max-width: 300px;
  }

  @media screen and (max-width: 1180px) {
    margin: 10px;
  }

  ${({ theme }) => theme.lg`
    justify-content: space-between;
    margin: 10px;
  `};

  &:nth-child(1) {
    background: #d5d7e8;
    border: 1px solid #d5d7e8;
  }

  &:nth-child(2) {
    background: #f3edf4;
    border: 1px solid #f3edf4;
  }

  &:nth-child(3) {
    background: #daebd9;
    border: 1px solid #daebd9;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;

  bottom: 33px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlanVariantsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing} 0 0;
  height: 300px;
`;
PlanVariantsWrapper.displayName = 'PlanVariantsWrapper';

const PlanVariant = styled.div`
  max-width: 400px;
  margin-bottom: ${({ theme }) => theme.spacing};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
PlanVariant.displayName = 'PlanVariant';

const DiscountValue = styled.span`
  color: ${({ theme }) => theme.secondary};
`;
DiscountValue.displayName = 'DiscountValue';

VoucherPlan.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    plans: PropTypes.array,
  }),
  disabled: PropTypes.bool,
  onSelectPlan: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default memo(VoucherPlan);
