import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CalendarComponent from '../../../Components/Atoms/Calendar/Calendar';
import { getCalendarEvents } from '../../../Util/calendar';
import { useHistory } from 'react-router';
import { getToLocalSchedulesUser } from '../../../Selectors/schedules.selector';

const Availability = ({ schedules }) => {
  const [booked, setBooked] = useState([]);
  const history = useHistory();

  const goToCalendar = () => history.push('/t/dashboard/calendar');

  // useEffect: 1. convert stringDates to Date() | 2.Sorting dates | 3.Set booked events
  useEffect(() => {
    if (schedules && schedules.length) {
      const [, bookedEvents] = getCalendarEvents(schedules, false);
      if (bookedEvents.length) setBooked(bookedEvents);
    }
  }, [schedules]);

  return (
    <CalendarComponent
      type="booked"
      events={booked}
      style={{ width: '100%', height: '280px' }}
      views={['month']}
      defaultView="month"
      schedules={schedules}
      onDrillDown={goToCalendar}
      onSelectEvent={goToCalendar}
      onlyMonth
    />
  );
};

const mapStateToProps = (store) => ({
  schedules: getToLocalSchedulesUser(store),
});

export default connect(mapStateToProps)(Availability);
