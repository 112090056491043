import React, { useCallback, memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import notify from '../../../Util/notify';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Radio, Widget } from '../../Atoms';

const PaymentPlan = ({ plan, subscription, onSubmit }) => {
  const [activePackage, setActivePackage] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [expandAll, setExpandAll] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (plan?.package_plans.length) {
      const defaultPackage = plan?.package_plans.find((p) => p.plan_interval === 'week');

      if (!defaultPackage) {
        setActivePackage(plan?.package_plans[0]);
        setSelectedVariant(plan?.package_plans[0]?.plan_id);
      } else {
        setActivePackage(defaultPackage);
        setSelectedVariant(defaultPackage?.plan_id);
      }
    }
  }, [plan]);

  const handleContinue = () => {
    if (subscription.cancelled && subscription.plan_status === 'active') {
      notify(t("subscriptionFailed"), t("isActiveSubscription"), 'warning');
    } else {
      onSubmit(activePackage.plan_id);
    }
  };

  const handleSelectVariant = useCallback(
    ({ target: { value } }) => {
      setSelectedVariant(+value);
      setActivePackage(plan.package_plans.find((p) => p.plan_id === +value));
    },
    [plan],
  );

  const expandOptions = () => setExpandAll((expanded) => !expanded);
  const cancel = (handleContinue, close) => {
    handleContinue();
    close();
  };

  if (!plan || !activePackage) return null;

  return (
    <PlanWrapper>
      <Heading level={2}>
        {Math.round(parseFloat(activePackage?.price))} {activePackage?.currency}/{activePackage?.plan_interval}
      </Heading>
      <Heading level={4}>{plan.package_name}</Heading>
      <br />
      <span style={{ height: 25, fontWeight: 500 }}>{activePackage.plan_name}</span>

      <div style={{ textAlign: 'right', marginTop: 20 }} onClick={expandOptions}>
        <MoreOptions>{expandAll ? t("close") : t("moreOptions")}</MoreOptions>
      </div>
      {expandAll && (
        <PlanVariantsWrapper>
          {plan.package_plans.map(({ plan_id, plan_name }) => {
            return (
              <PlanVariant key={plan_id}>
                <div>{plan_name}</div>

                <div>
                  <Radio
                    name={`plan-${plan_id}`}
                    id={plan_id}
                    onChange={handleSelectVariant}
                    checked={selectedVariant === plan_id}
                    value={plan_id}
                  />
                </div>
              </PlanVariant>
            );
          })}
        </PlanVariantsWrapper>
      )}

      <ButtonWrapper>
        {subscription?.is_paid ? (
          subscription?.plan_id === selectedVariant ? (
            <Button onClick={handleContinue} variant="primary" disabled={subscription?.plan_id === selectedVariant}>
            {t("currentPlan")}
            </Button>
          ) : (
            // : 'Change to selected plan'
            <>
              <StyledPopup
                trigger={
                  <Button variant="primary" disabled={subscription?.plan_id === selectedVariant}>
                    {t("changeSelectedPlan")}
                  </Button>
                }
                modal
              >
                {(close) => (
                  <>
                    <Heading level={3}>{t("changeSubscription")}</Heading>
                    <p>
                    {t("changeSubsPara1")}
                    </p>
                    <p>{t("changeSubsPara2")}</p>
                    <ModalActions>
                      <Button onClick={close}>{t("cancel")}</Button>
                      <Button onClick={() => cancel(handleContinue, close)} variant={'danger ghost'}>
                        {t("changeSubscription")}
                      </Button>
                    </ModalActions>
                  </>
                )}
              </StyledPopup>
            </>
          )
        ) : (
          <Button onClick={handleContinue} variant="primary" disabled={subscription?.plan_id === selectedVariant}>
          {t("buy")}
          </Button>
        )}
      </ButtonWrapper>
    </PlanWrapper>
  );
};

// const Discount = styled.div`
//   position: absolute;
//   right: 0;
//   top: 10px;
//   background: ${({ theme }) => theme.statusWarning};
//   padding: 4px 10px;
//   border-radius: 5px 0 0 5px;
//   font-weight: 600;
// `;
//
// const PackageStatus = styled.div`
//   background: ${({ theme }) => theme.statusWarning};
//   font-weight: 600;
//   border-radius: 5px;
//   padding: 4px 10px;
//   display: inline-flex;
//   margin-bottom: 20px;
// `;

const PlanWrapper = styled(Widget)`
  position: relative;
  max-width: 300px;
  min-width: 300px;
  padding: 32px 32px 75px 32px;

  min-height: 360px;

  margin: 20px;

  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 10px;

  &:hover {
    border: 1px solid #c6c6c6 !important;
  }

  @media screen and (max-width: 977px) {
    max-width: 300px !important;
  }

  @media screen and (max-width: 1097px) {
    max-width: 300px;
  }

  @media screen and (max-width: 1180px) {
    margin: 10px;
  }

  ${({ theme }) => theme.lg`
    justify-content: space-between;
    margin: 10px;
  `};

  &:nth-child(1) {
    background: #d5d7e8;
    border: 1px solid #d5d7e8;
  }

  &:nth-child(2) {
    background: #f3edf4;
    border: 1px solid #f3edf4;
  }

  &:nth-child(3) {
    background: #daebd9;
    border: 1px solid #daebd9;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 33px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlanVariantsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing} 0 0;
`;
PlanVariantsWrapper.displayName = 'PlanVariantsWrapper';

const PlanVariant = styled.div`
  max-width: 400px;
  margin-bottom: ${({ theme }) => theme.spacing};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
PlanVariant.displayName = 'PlanVariant';

const DiscountValue = styled.span`
  color: ${({ theme }) => theme.secondary};
`;
DiscountValue.displayName = 'DiscountValue';

const MoreOptions = styled.span`
  text-decoration: underline;
  color: #00c8a0;
  cursor: pointer;
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: auto !important;
    margin: auto ${({ theme }) => theme.spacing} !important;

    ${({ theme }) => theme.md`
      width: 50% !important;
      margin: auto !important;
    `};
  }
`;
const ModalActions = styled.div`
  margin-top: ${({ theme }) => theme.spacing};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  gap: ${({ theme }) => theme.spacingSm};
  justify-content: center;
  text-align: center;

  ${({ theme }) => theme.md`
    justify-content: flex-end;
  `};
`;
ModalActions.displayName = 'ModalActions';

// const PlanError = styled.div`
//   position: absolute;
//   bottom: 8px;
//   color: red;
//   text-align: center;
//
//   right: 50%;
//   transform: translateX(50%);
//   width: 100%;
// `;

PaymentPlan.propTypes = {
  plan: PropTypes.shape({
    package_id: PropTypes.number,
    package_name: PropTypes.string,
    package_plans: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
        plan_interval: PropTypes.string,
        plan_name: PropTypes.string,
        price: PropTypes.string,
        text: PropTypes.string,
        video_addon_price: PropTypes.string,
        no_of_videos: PropTypes.number,
        package_id: PropTypes.number,
        plan_id: PropTypes.number,
      }),
    ),
  }),
  subscription: PropTypes.shape({
    is_paid: PropTypes.number,
    plan_status: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default memo(PaymentPlan);
