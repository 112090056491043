import React, { memo } from 'react';
import styled from 'styled-components';

const Label = (props) => {
  return <StyledLabel {...props}>{props.children}</StyledLabel>;
};

const StyledLabel = styled.label`
  display: block;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacingSm};
  font-size: 17px;
  font-weight: ${({ theme }) => theme.fontBold};
  text-align: left;
`;
export default memo(Label);
