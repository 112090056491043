import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Heading } from '../../Components/Atoms';
import { ContactSupportForm } from '../../Components/Organisms';
import { DashboardLayout } from '../../Layout';

const Contact = () => {
  useEffect(() => {
    if (window.HubSpotConversations && !window.HubSpotConversations?.widget?.status()?.loaded) {
      window.HubSpotConversations.resetAndReloadWidget();
    }
  }, []);
  return (
    <DashboardLayout>
      <Wrapper>
        <Heading>Contact Support</Heading>
        <ContactSupportForm />
      </Wrapper>
    </DashboardLayout>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
`;
Wrapper.displayName = 'Wrapper';

export default Contact;
