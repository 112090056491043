import React, { memo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import DOMPurify from 'dompurify';

import 'react-lazy-load-image-component/src/effects/blur.css';

const PostCard = ({ data, activeView }) => {
  const { id, title, _embedded: embedded, excerpt, ID } = data;
  const role = useSelector((store) => store.user?.role);
  const history = useHistory();
  const goTo = () =>
    history.push(
      `${role === 'therapist' ? '/t' : ''}/well-being/${activeView === 'blog' ? id : ID}${
        activeView === 'blog' ? '' : '?is_video=true'
      }`,
    );

  return (
    <PostCardStyled>
      <PostImage
        width="100%"
        height="100%"
        alt={activeView === 'blog' ? embedded['wp:featuredmedia']?.[0]?.alt_text : data?.post_title}
        src={
          activeView === 'blog'
            ? embedded['wp:featuredmedia']?.[0]?.source_url
            : data?.post_meta?.image?.length
            ? data?.post_meta?.image?.[0]
            : ''
        }
        effect="blur"
        onClick={goTo}
      />
      <PostTextWrapper>
        <PostHeader
          onClick={goTo}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(activeView === 'blog' ? title.rendered : data?.post_title),
          }}
        />
        {activeView === 'blog' ? (
          <PostText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(excerpt.rendered) }} />
        ) : (
          <PostText>{data.post_content}</PostText>
        )}
      </PostTextWrapper>
    </PostCardStyled>
  );
};

export default memo(PostCard);

const PostHeader = styled.h6`
  font-size: 16px;
  color: black;
  font-weight: 900;
  line-height: 18px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  cursor: pointer;
`;

const PostCardStyled = styled.div`
  width: 250px;
  min-width: 250px;
  height: 280px;
  border-radius: 10px;
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #c4c4c4;
  margin-bottom: 40px;
  padding-bottom: 25px;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    ${PostHeader} {
      color: #491f69;
    }
  }

  .lazy-load-image-background {
    height: 60% !important;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const PostImage = styled(LazyLoadImage)`
  object-fit: cover;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
`;

const PostTextWrapper = styled.div`
  width: 100%;
  height: 25%;
  padding: 25px;
`;

const PostText = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: black;
  font-weight: 400;
  line-height: 18px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
