import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Store } from 'react-notifications-component';
import { Button, ChevronIcon, TextBlock, Logo, FormGroup, Checkbox, Input } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import { confirmPassword, passwordValidation } from '../../Constants/validations';
import tc from '../../Assets/Patient T&Cs - SR.pdf';
import { api, checkPartner, useDeviceType } from '../../Util';
import { MainSectionWrapper, ModalOverlay, Modal, BottomSectionWrapper } from '../Components/';
import { ReactComponent as Eye } from '../../Assets/Icons/Eye.svg';
import { ReactComponent as EyeSlash } from '../../Assets/Icons/EyeSlash.svg';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import EmergencyBtn from '../../Components/Atoms/EmergencyBtn';
import { useTranslation } from 'react-i18next';

const referalOptions = [
  { id: 1, label: 'Twitter', value: 'Twitter' },
  { id: 2, label: 'Facebook', value: 'Facebook' },
  { id: 3, label: 'Instagram', value: 'Instagram' },
  { id: 4, label: 'Linkedin', value: 'Linkedin' },
  { id: 5, label: 'Blogs or Press release', value: 'Blogs or Press release' },
  { id: 6, label: 'Search engines', value: 'Search engines' },
  { id: 7, label: 'Friend', value: 'Friend' },
  { id: 8, label: 'Gympass.com', value: 'Gympass.com' },
  { id: 9, label: 'Boots.com', value: 'Boots.com' },
  { id: 10, label: 'In the news with Boots ', value: 'Boots PR' },
  { id: 11, label: 'Boots Facebook', value: 'Boots FB ad' },
  { id: 12, label: 'Boots Instagram', value: 'Boots IG ad' },
  { id: 13, label: 'Press release', value: 'Press release' },
];

// Override the default select style
const customStyles = {
  control: (base) => ({
    ...base,
    height: 50,
    minHeight: 50,
  }),
  valueContainer: (base) => ({
    ...base,
    height: 50,
    minHeight: 50,
  }),
};

const SignUp = (props) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { userType } = useParams();
  const query = new URLSearchParams(search);
  const socialRequestPopupStatus = useSelector((store) => store.auth.socialRequestPopupStatus);
  const isQuestionCompleted = useSelector((store) => store.questionnaire.quizStatus);
  const isPartnerCheck = useSelector((store) => store?.isPartner.isPartner);
  const companyId = query.get('company_unique_id');
  const loginInfo = localStorage.getItem('loginInfo');
  const isLoggedIn = !!loginInfo;
  const [signingUp, setSigningUp] = useState(false);
  const [verification, setVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [email, setEmail] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [mobile, setMobile] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [countriesList, setCountries] = useState([]);
  const [isVerifying, setIsVerifying] = useState(false);

  const [localValues, setLocalValues] = useState({});

  // const passowrdField = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const verifyPartner = useCallback(() => dispatch(checkPartner()), [dispatch]);
  const [isEnterprise, setIsEnterprise] = useState('');
  const isMobile = useDeviceType();

  const invitationCode = query.get('invitation_password');
  const redirectToQuestionnare = useCallback(() => history.push('/'), [history]);

  if (isLoggedIn && !socialRequestPopupStatus) history.push('/chatroom');

  const handleOnSubmit = async (values, { resetForm, setSubmitting }) => {
    if (isEnterprise) {
      values.password_confirmation = values.confirmPassword;
    } else {
      values.password_confirmation = values.password;
    }

    setSigningUp(true);
    try {
      let payload = {
        ...values,
        company_unique_id: companyId,
        send_marketing_email: values.checked?.includes('marketing') ? 1 : 0,
      };
      if (isPartnerCheck) {
        payload = {
          ...payload,
          partner_domain: window.location.host,
        };
      }

      if (!payload?.department_id) {
        delete payload.department_id;
      }
      const res = await api.auth.signup('patient', payload);

      if (res.data?.status === 'error') {
        Store.addNotification({
          title: t('signupFailed'),
          message: (
            <div>
              <p>{res.data?.message}</p>
            </div>
          ),
          type: 'danger',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 8000,
            onScreen: true,
          },
        });
      } else {
        setEmail(values.email);
        Store.addNotification({
          title: t('signupSuccess'),
          message: (
            <div>
              <p>{t('signupSuccessfulPleaseVerifyLogin')}</p>
            </div>
          ),
          type: 'success',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        if (userType === 'e') {
          history.push('/welcome/signin');
          return false;
        }
        setVerification(true);
        resetForm();
        //history.push('/welcome/finish');
      }
    } catch (err) {
      Store.addNotification({
        title: t('signupFailed'),
        message: (
          <div>
            <p>{err.response.data?.message || t('signUpError')}</p>
          </div>
        ),
        type: 'danger',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } finally {
      setSigningUp(false);
      setSubmitting(false);
    }
  };

  const handleVerifyCode = () => {
    setIsVerifying(true);
    api.auth.verifyEmail({ verify_token: verificationCode }).then((res) => {
      if (res.data.status === 'success') {
        setIsVerifying(false);
        Store.addNotification({
          title: t('verificationSuccess'),
          message: (
            <div>
              <p>{res.data.message}</p>
            </div>
          ),
          type: 'success',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        history.push('/welcome/signin');
      } else {
        setIsVerifying(false);
        Store.addNotification({
          title: t('verificationFailed'),
          message: (
            <div>
              <p>{t('invalidVerificationCode')}</p>
            </div>
          ),
          type: 'danger',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      }
    });
  };

  const validationSchema = useMemo(() => {
    const validations = {
      first_name: Yup.string().required(t('firstNameIsRequired')),
      last_name: Yup.string().required(t('lastNameIsRequired')),
      mobile: Yup.string().required(t('mobileIsRequired')),
      country_id: Yup.string().required(t('countryIsRequired')),
      email: Yup.string().email(t('emailIsInvalid')).required(t('emailIsRequired')),
      referal: Yup.string().required(t('referal')),
      password: passwordValidation(t),
    };

    if (isEnterprise) {
      validations.confirmPassword = confirmPassword(t);
    }

    if (!props.isEnterprise && !props.registerEnterprise) {
      validations.accept_terms = Yup.array().min(1, t('selectCheckboxText'));
    }

    if (isEnterprise && companyId && invitationCode) {
      validations.department_id = Yup.string().required(t('pleaseChooseDepartment'));
    }

    return Yup.object().shape(validations);
  }, [props.isEnterprise, props.registerEnterprise, isEnterprise, companyId, invitationCode]);

  const initialValues = useMemo(() => {
    return {
      first_name: '',
      last_name: '',
      mobile: '',
      email: '',
      password: '',
      confirmPassword: '',
      password_confirmation: '',
      country_id: '',
      checked: [],
      marketing: 0,
      referal: '',
      accept_terms: [],
      department_id: '',
      quiz_group: isEnterprise ? '' : localStorage.getItem('quiz_journey'),
    };
  }, [isEnterprise]);

  const getVerificationCode = (notify_type) => {
    setIsVerifying(true);
    api.auth
      .resendCode({ email, notify_type })
      .then((res) => {
        setIsVerifying(false);
        Store.addNotification({
          title: t('verificationCodeSentSuccessfully'),
          message: (
            <div>
              <p>{`${t('pleaseCheck')} ${notify_type === 'whatsapp' ? 'WhatsApp' : 'SMS'} ${t(
                'forTheVerificationCode',
              )}`}</p>
            </div>
          ),
          type: 'success',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
      .catch((err) => {
        setIsVerifying(false);
        Store.addNotification({
          title: t('errorInSendingVerCode'),
          message: (
            <div>
              <p>{err.response?.data?.errors?.email[0] || err.response?.data?.message}</p>
            </div>
          ),
          type: 'danger',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  };
  const togglePassword = () => {
    passwordType === 'password' ? setPasswordType('text') : setPasswordType('password');
  };

  const mobileOnChange = (phone, setFieldValue) => {
    setFieldValue('mobile', phone);
    setMobile(phone);
  };

  // need to reconsider this
  const handleSelectChange = (e, field, setFieldValue) => {
    setFieldValue(field, e.value);
  };

  //setting the local values for hubspot tracking
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'checked' || name === 'accept_terms') {
      setLocalValues({
        ...localValues,
        [name]: value,
        [name]: checked,
      });
    } else {
      setLocalValues({
        ...localValues,
        [name]: value,
      });
    }
  };
  // Check for company ID, if not redirect to questionnare page
  useEffect(() => {
    if (userType === 'e' && !companyId) redirectToQuestionnare();
    if (companyId && invitationCode) setIsEnterprise(companyId && invitationCode);
  }, [companyId, redirectToQuestionnare, userType, isEnterprise, invitationCode]);

  useEffect(() => {
    if (isEnterprise && userType === 'e') {
      api.company.confirmInvitation({ invitation_password: invitationCode, company_unique_id: companyId }).catch(() => {
        Store.addNotification({
          title: t('error1'),
          message: (
            <div>
              <p>{t('invalidB2BEnterpriseCode')}</p>
            </div>
          ),
          type: 'danger',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 8000,
            onScreen: true,
          },
        });
        redirectToQuestionnare();
      });
    }

    if (isQuestionCompleted !== 'completed' && userType !== 'e' && !companyId && !invitationCode) history.push('/');

    api.others
      .getCountriesList()
      .then((response) => {
        if (!!response.data.data.length) {
          const _countries = response.data.data.map((_country) => ({ value: _country.id, label: _country.name }));
          setCountries(_countries);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (!userType) {
      history.push('/');
    }
  }, [invitationCode, companyId, redirectToQuestionnare, isEnterprise, userType, history, isQuestionCompleted]);

  useEffect(() => {
    if (!companyId && !invitationCode && userType !== 'e') setIsEnterprise(false);
    if (isEnterprise && userType === 'e') {
      localStorage.removeItem('quiz_journey');
      api.company
        .getCompanyDepartments(companyId)
        .then((response) => {
          if (response.status === 200) {
            const _department = response.data?.data.map((item) => ({ value: item.id, label: item.name }));
            setDepartments(_department || []);
          }
        })
        .catch((err) => {
          console.error(err.response.data.message);
        });
    }
  }, [companyId, isEnterprise, userType, invitationCode]);

  useEffect(() => {
    if (isPartnerCheck === '') {
      verifyPartner();
    }
  }, [isPartnerCheck, verifyPartner]);

  return (
    <WelcomeLayout noPadding noTopBar>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleOnSubmit}
      >
        {({ setFieldValue }) => (
          <Form noValidate>
            <MainSectionWrapper isMobile={isMobile}>
              <section style={{ marginBottom: 40 }}>
                <Logo />
                <TextBlock type={'primary'}>{t('CreateYourAccount')}</TextBlock>
                <StyledFormGroup>
                  <FieldWrapper onChange={handleChange}>
                    <Field
                      id="first_name"
                      name="first_name"
                      as={Input}
                      type="text"
                      className={'form-control'}
                      maxLength="30"
                    />
                    <label htmlFor="first_name">{t('firstNameImportant')}</label>
                    <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
                  </FieldWrapper>
                  <FieldWrapper onChange={handleChange}>
                    <Field
                      id="last_name"
                      name="last_name"
                      as={Input}
                      type="text"
                      className={'form-control'}
                      maxLength="30"
                    />
                    <label htmlFor="last_name">{t('lastNameImportant')}</label>
                    <ErrorMessage name="last_name" component="div" className="invalid-feedback" />
                  </FieldWrapper>
                </StyledFormGroup>
                <StyledFormGroup>
                  <FieldWrapper onChange={handleChange}>
                    <Field id="email" name="email" type="text" as={Input} className={'form-control'} />
                    <label htmlFor="email">{t('emailAddressImportant')}</label>
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </FieldWrapper>
                </StyledFormGroup>
                <StyledFormGroup>
                  <FieldWrapper onChange={handleChange}>
                    <PhoneInput
                      id="mobile"
                      // containerClass={errors.mobile && touched.mobile ? ' is-invalid' : ''}
                      value={mobile}
                      onChange={(phone) => mobileOnChange(phone, setFieldValue)}
                      placeholder={t('choseCountryAndMobile')}
                      country="gb"
                      isValid={(number, country) => {
                        if (number && !country) return t('countrySelectionIsMandatory');
                        return !(number && !country);
                      }}
                      enableSearch
                    />
                    {/* <Field id="mobile" name="mobile" type="text" as={Input} className={'form-control'} /> */}
                    <label htmlFor="mobile">{t('mobileNumberImportant')}</label>
                    <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                  </FieldWrapper>
                </StyledFormGroup>
                <StyledFormGroup>
                  <FieldWrapper onChange={handleChange}>
                    <StyledSelect
                      id="country_id"
                      name="country_id"
                      className="department-selector-container"
                      classNamePrefix="department-selector"
                      options={countriesList}
                      placeholder={t('select')}
                      // defaultValue={department}
                      isSearchable={true}
                      onChange={(e) => {
                        const obj = { target: { name: 'country', value: e.label } };
                        handleSelectChange(e, 'country_id', setFieldValue);
                        handleChange(obj);
                      }}
                      styles={customStyles}
                    />
                    <label htmlFor="department">{t('country')}</label>
                    <ErrorMessage name="country_id" component="div" className="invalid-feedback" />
                  </FieldWrapper>
                  <FieldWrapper style={{ display: 'none' }}>
                    <input
                      id="selected_country"
                      name="selected_country"
                      value={localValues.country}
                      type="text"
                      className={'form-control'}
                    />
                  </FieldWrapper>
                </StyledFormGroup>
                <StyledFormGroup>
                  <FieldWrapper onChange={handleChange}>
                    <Field
                      id="password"
                      name="password"
                      type={passwordType}
                      className={'form-control'}
                      onIconClick={togglePassword}
                      // onFocus={() => passowrdField?.current?.scrollIntoView()}
                      as={Input}
                      icon={passwordType === 'password' ? Eye : EyeSlash}
                    />
                    <label htmlFor="password">{t('passwordImportant')}</label>
                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                  </FieldWrapper>
                </StyledFormGroup>
                {isEnterprise && (
                  <StyledFormGroup>
                    <FieldWrapper onChange={handleChange}>
                      <Field
                        id="confirmPassword"
                        name="confirmPassword"
                        type={passwordType}
                        className={'form-control'}
                        onIconClick={togglePassword}
                        // onFocus={() => passowrdField?.current?.scrollIntoView()}
                        as={Input}
                        icon={passwordType === 'password' ? Eye : EyeSlash}
                      />
                      <label htmlFor="confirmPassword">{t('confirmPassword')}</label>
                      <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                    </FieldWrapper>
                  </StyledFormGroup>
                )}
                <div style={{ height: 0 }} />
                {isEnterprise && (
                  <StyledFormGroup>
                    <FieldWrapper onChange={handleChange}>
                      <StyledSelect
                        id="department_id"
                        name="department_id"
                        className="department-selector-container"
                        classNamePrefix="department-selector"
                        options={departments}
                        placeholder={t('select')}
                        // defaultValue={department}
                        isSearchable={true}
                        onChange={(e) => handleSelectChange(e, 'department_id', setFieldValue)}
                      />
                      <label htmlFor="department_id">{t('department')}</label>
                      <ErrorMessage name="department_id" component="div" className="invalid-feedback" />
                    </FieldWrapper>
                  </StyledFormGroup>
                )}
                <StyledFormGroup>
                  <FieldWrapper>
                    <StyledSelect
                      id="referal"
                      name="referal"
                      className="department-selector-container"
                      classNamePrefix="department-selector"
                      options={referalOptions}
                      // defaultValue={department}
                      isSearchable={true}
                      placeholder={t('select')}
                      onChange={(e) => {
                        const obj = { target: { name: 'referal', value: e.value } };
                        handleSelectChange(e, 'referal', setFieldValue);
                        handleChange(obj);
                      }}
                    />
                    <label htmlFor="referal">{t('howDidYouHearAboutUs')}</label>
                    <ErrorMessage name="referal" component="div" className="invalid-feedback" />
                  </FieldWrapper>
                  <FieldWrapper style={{ display: 'none' }}>
                    <input
                      id="how_did_you_hear_about_us"
                      name="how_did_you_hear_about_us"
                      value={localValues.referal}
                      type="text"
                      className={'form-control'}
                    />
                  </FieldWrapper>
                </StyledFormGroup>
                <CheckboxGroup role="group" aria-labelledby="checkbox-group">
                  <StyledFormGroup>
                    <FieldWrapper style={{ width: '100%' }} onChange={handleChange}>
                      <Field
                        as={Checkbox}
                        label={
                          <span style={{ fontWeight: 400 }}>
                            {t('agree')}{' '}
                            <a href={tc} target="_blank" rel="noopener noreferrer">
                              {t('terms')} &amp; {t('conditions')}
                            </a>{' '}
                            {t('and')}{' '}
                            <a href="https://supportroom.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                              {t('privacy_Policy')}
                            </a>
                            *
                          </span>
                        }
                        name="accept_terms"
                        value="consent"
                        style={{ fontWeight: 400 }}
                      />
                      <ErrorMessage name="accept_terms" component="div" className="invalid-feedback" />
                    </FieldWrapper>
                    <FieldWrapper style={{ display: 'none' }}>
                      <input
                        id="termsAndConditionAccepted"
                        name="terms_and_condition_accepted"
                        value={localValues.accept_terms ? 'Yes' : 'No'}
                        type="text"
                        className={'form-control'}
                        maxLength="30"
                      />
                    </FieldWrapper>
                  </StyledFormGroup>
                  <StyledFormGroup>
                    <FieldWrapper style={{ width: '100%' }} onChange={handleChange}>
                      <Checkbox
                        label={<span style={{ fontWeight: 400 }}>{t('marketingText')}</span>}
                        name="checked"
                        value="marketing"
                        style={{ height: '5px' }}
                      />
                    </FieldWrapper>
                    <FieldWrapper style={{ display: 'none' }}>
                      <input
                        id="ismarketingchecked"
                        name="ismarketingchecked"
                        value={localValues.checked ? 'Yes' : 'No'}
                        type="text"
                        className={'form-control'}
                        maxLength="30"
                      />
                    </FieldWrapper>
                  </StyledFormGroup>
                </CheckboxGroup>
              </section>
            </MainSectionWrapper>
            {isMobile && (
              <SubmitButtonWrapper isMobile={isMobile}>
                <Button variant="secondary" type="submit">
                  {t('createAccount')}
                  <ChevronIcon direction="right" width="13" height="13" />
                </Button>
              </SubmitButtonWrapper>
            )}
            {!isMobile && (
              <BottomSectionWrapper>
                <Button
                  variant="secondary"
                  type="submit"
                  style={{
                    fontWeight: 600,
                    color: 'white',
                    background: '#33c8a0',
                    width: '300px',
                    padding: '15px 0',
                    marginBottom: '40px',
                  }}
                >
                  {t('createAccount')}
                  <ChevronIcon direction="right" width="13" height="13" />
                </Button>
                <EmergencyBtn />
              </BottomSectionWrapper>
            )}
          </Form>
        )}
      </Formik>
      {signingUp && (
        <ModalOverlay>
          <LoadingLabel>{t('verificationText1')}</LoadingLabel>
        </ModalOverlay>
      )}
      {verification && (
        <ModalOverlay>
          <Modal>
            <BoldPrimaryText>{t('verificationText2')}</BoldPrimaryText>
            <VerificationFieldWrapper>
              <label htmlFor="verify_token">{t('verificationText3')}</label>
              <input
                onChange={(e) => setVerificationCode(e.target.value)}
                id="verify_token"
                name="verify_token"
                type={window.screen.width > 1024 ? 'text' : 'number'}
                pattern="[0-9]*"
                className={'form-control'}
              />
            </VerificationFieldWrapper>
            <Button
              type="button"
              onClick={handleVerifyCode}
              variant="secondary"
              disabled={!verificationCode || isVerifying}
              style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginTop: '20px' }}
            >
              {t('verify')} <ChevronIcon direction="right" width="13" height="13" />
            </Button>
            <NormalText>{t('verificationText4')}</NormalText>
            <ActionButtonWrapper>
              <AnswerOption
                onClick={(e) => getVerificationCode('sms')}
                disabled={isVerifying}
                className={isVerifying ? 'disable' : ''}
              >
                {t('verificationText5')}
              </AnswerOption>
              <AnswerOption
                onClick={(e) => getVerificationCode('whatsapp')}
                disabled={isVerifying}
                className={isVerifying ? 'disable' : ''}
              >
                {t('verificationText6')}
              </AnswerOption>
            </ActionButtonWrapper>
            <span style={{ fontSize: '12px' }}>
              {t('verificationText7')}
              <br /> {t('verificationText8')}
            </span>
            <BottomSectionWrapper />
          </Modal>
        </ModalOverlay>
      )}
    </WelcomeLayout>
  );
};

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: start;
  input {
    width: 100%;
    height: 50px;
    flex-grow: initial;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    &:focus {
      border: 2px solid #00c8a0;
    }
  }
  input {
    svg {
      path {
        fill: #797979 !important;
      }
    }
  }
  label {
    span {
      border-radius: 0;
      border-color: #c4c4c4;
      a {
        color: #33c8a0;
      }
    }
    &:hover {
      .checkmark {
        background: #f6f6f6;
      }
    }
  }
  input:checked ~ .checkmark {
    background-color: #00c8a0;
  }
`;
export const FieldWrapper = styled.div`
  width: 100%;
  margin-right: 5px;
  position: relative;
  input {
    margin-top: 5px;
  }
  select {
    margin-top: 5px;
  }
  label {
    font-size: 13px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.5);
  }
  .invalid-feedback {
    margin-left: 8px;
  }
  .react-tel-input {
    input.form-control {
      width: 100%;
      height: 50px;
      background: ${({ theme }) => theme.greyVeryLight};
    }
    .flag-dropdown {
      &.open {
        .country-list {
          overflow-x: hidden;
          padding-right: 6px;
          &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: ${({ theme }) => theme.greyVeryLight};
          }
          .search {
            .search-emoji {
              display: none;
            }
            .search-box {
              height: 45px;
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
`;
const CheckboxGroup = styled.div`
  text-align: left;
  margin-top: ${({ theme }) => theme.spacingLg};
  @media screen and (max-width: 415px) {
    margin-top: 20px;
  }
`;
const LoadingLabel = styled.div`
  color: white;
  font-weight: 300;
`;
const BoldPrimaryText = styled.div`
  font-size: x-large;
  color: #491f69;
  margin-bottom: 20px;
  font-weight: bold;
  padding: 10px;
`;
const NormalText = styled.div`
  font-size: medium;
  color: gray;
  margin-bottom: 20px;
  padding: 10px;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AnswerOption = styled.button`
  width: 80%;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  font-size: 14px;
  padding: 5px 15px;
  background-color: #c9fff1;
  color: ${({ theme }) => theme.greyDark};
  border: 2px solid transparent;
  ${({ disable }) =>
    disable &&
    css`
      background-color: ${({ theme }) => theme.greyMedium};
      pointer-events: none;
    `}
  ${({ theme }) => theme.md`
    font-size: 15px;
  `}
  &.active {
    color: ${({ theme }) => theme.white};
    background-color: #33c8a0;
  }
`;
const VerificationFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-top: 5px;
  .department-selector__control {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.07);
    color: gray;
    height: 50px;
  }
  .department-selector__control--menu-is-open,
  .department-selector__control--is-focused {
    border: 2px solid #00c8a0 !important;
  }
  .department-selector__indicator-separator {
    display: none;
  }
  .department-selector__input {
    input {
      height: auto;
      margin-top: -5px;
    }
  }
  .department-selector__value-container {
    padding: 0 8px 0 8px;
    height: 50px;
    .department-selector__single-value {
      color: gray;
      font-weight: 400;
      font-size: 15px;
    }
  }
  .department-selector__indicators {
    width: 20px;
    .department-selector__dropdown-indicator {
      padding: 0;
      svg {
        width: 17px;
      }
    }
  }
`;

const SubmitButtonWrapper = styled.div`
  z-index: 999;
  button {
    font-weight: 600 !important;
    color: #fff !important;
    background: #33c8a0 !important;
    width: 300px;
    padding: 15px 0;
    margin-bottom: 40px;
    z-index: 9;
  }
`;
export default SignUp;
