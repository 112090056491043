import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { changePassword } from '../../../Actions/auth.actions';

import { Alert, Button, FormGroup, Label } from '../../../Components/Atoms';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const [responseMessage, setResponseMessage] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isPasswordExpired = useSelector((store) => store.auth?.password_expired);
  const isTherapist = useSelector((store) => store.auth?.role) === 'therapist';
  const history = useHistory();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    try {
      const { message } = await dispatch(changePassword(values));
      setResponseMessage({ type: 'primary', message });
    } catch (err) {
      console.error(err);
      setResponseMessage({ type: 'danger', message: t("errorMessage2") });
    } finally {
      setSubmitting(false);
      setLoading(false);
      resetForm();
      isTherapist && isPasswordExpired && history.push('/t/logout');
    }
  };

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required(t("oldPasswordIsRequired") ),
    new_password: Yup.string().test('passwords-match', 'New password must not be the same as old password', function (value) {
      const { old_password } = this.parent;
      return value !== old_password;
    }).min(6, t("passwordMustBeLeast6Characters"))
    .max(20, t("characterallowed"))
    .matches(/(.*\d.*)/, t("passwordContainoneNumericChar"))
    .matches(/(?=.*[A-Z])/, t("passwordContainOneUpperCaseChar"))
    .required(t("passwordIsRequired")),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], t("passwordsMustMatch") )
      .required(t("confirmPasswordIsRequired") ),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        old_password: '',
        new_password: '',
        confirm_password: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <FormGroup>
            <Label>{t("oldPassword")}</Label>
            <Field
              id="old_password"
              name="old_password"
              type="password"
              className={'form-control' + (errors.old_password && touched.old_password ? ' is-invalid' : '')}
            />
            <ErrorMessage name="old_password" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <Label>{t("newPassword")}</Label>
            <Field
              id="new_password"
              name="new_password"
              type="password"
              className={'form-control' + (errors.new_password && touched.new_password ? ' is-invalid' : '')}
            />
            <ErrorMessage name="new_password" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <Label>{t("confirmNewPassword")}</Label>
            <Field
              id="confirm_password"
              name="confirm_password"
              type="password"
              className={'form-control' + (errors.confirm_password && touched.confirm_password ? ' is-invalid' : '')}
            />
            <ErrorMessage name="confirm_password" component="div" className="invalid-feedback" />
          </FormGroup>

          {responseMessage.type && (
            <StyledAlert className={responseMessage.type}>{responseMessage.message}</StyledAlert>
          )}

          <Button type={'submit'} loading={loading} disabled={loading}>
          {t("change")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing};
`;
StyledAlert.displayName = 'StyledAlert';

export default ChangePasswordForm;
