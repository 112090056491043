import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getJourneyTemplates, getTemplatesStages } from '../Actions/chatroom.actions';

const useJourneyInitData = () => {
  const [journeyInitData, setJourneyInitData] = useState(null);
  const dispatch = useDispatch();

  const getJourneyData = useCallback(async () => {
    const journeyTemplates = (await dispatch(getJourneyTemplates()))?.data || [];
    if (!journeyTemplates.length) return;
    const stages = (await dispatch(getTemplatesStages(journeyTemplates[0].id)))?.data || [];
    if (!stages.length) return;
    setJourneyInitData({
      stage_id: stages[0].id,
      sub_stage_id: stages[0].sub_stages[0].sub_stage_id,
      template_id: journeyTemplates[0].id,
    });
  }, [dispatch]);

  useEffect(() => {
    getJourneyData();
  }, [getJourneyData]);

  return journeyInitData;
};

export default useJourneyInitData;
