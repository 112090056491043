import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AuthLayout } from '../../Layout';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Actions/auth.actions';
import { useTranslation } from 'react-i18next';

const Logout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  localStorage.removeItem('loginInfo');
  const teamsContext = useSelector((store) => store.teams.teamsContext);

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <AuthLayout>
      <Wrapper>
        <h1>{t("youHaveBeenLoggedOut")}</h1>

        {/* <Link to="/login">Return to Login</Link> */}
        <Link to={teamsContext ? '/teams' : '/'}>{t("returnToHome")}</Link>
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;
export default Logout;
