import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useWindowSize } from '../../../Hooks';

import { Container, NotificationIndicator, Tooltip } from '../../Atoms';
import { Nav, PatientMenu } from '../../Molecules';

import { ReactComponent as IconChat } from '../../../Assets/Icons/Chat.svg';

import config from '../../../Config';
import { ReactComponent as NotificationIcon } from '../../../Assets/Icons/Notification.svg';
import UrgentAlert from '../../Molecules/Chatroom/UrgentAlert';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

const Header = ({ noTopBar }) => {
  const subscription = useSelector((store) => store.user.subscription);
  const profile = useSelector((store) => store.user?.profile);
  const rooms = useSelector((store) => store.chatroom.rooms);
  const notifications = useSelector((store) => store.user.notifications);
  const { width } = useWindowSize();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useMemo(() => width < 768, [width]);
  const isRoomActive = useMemo(() => {
    return (rooms && rooms[0]?.chatroom_status) === 'approved';
  }, [rooms]);

  return (
    <StyledHeader role="banner">
      <Container>
        <HeaderContent>
          {noTopBar ? null : (
            <HeaderLeft>
              {subscription?.plan_status === 'active' || (profile && profile['B2B']) ? <Nav /> : ''}
              {(subscription?.plan_status === 'active' || (profile && profile['B2B'])) &&
              location.pathname !== '/chatroom' ? (
                <ChatIcon as={IconChat} onClick={() => history.push('/')} />
              ) : null}
            </HeaderLeft>
          )}
          <LogoLink to="/chatroom">
            <img src={config.iconLogo} alt="" />
          </LogoLink>
          <HeaderRight>
            {!isMobile && isRoomActive ? (
              <UrgentAlertWrapper>
                <UrgentAlert />
              </UrgentAlertWrapper>
            ) : null}
            {noTopBar ? null : (
              <>
                {subscription?.plan_status === 'active' || (profile && profile['B2B']) ? (
                  <StyledButtonLink to={'/notifications'}>
                    <Tooltip id="notificationIcon" text={t("viewNotifications/Announcements")} place="bottom" offset={{ bottom: 10 }}>
                      <NotificationIcon />
                    </Tooltip>
                    <NotificationIndicator count={notifications?.total_unread_count || 0} />
                  </StyledButtonLink>
                ) : null}
                <PatientMenu />
              </>
            )}
          </HeaderRight>
        </HeaderContent>
      </Container>
    </StyledHeader>
  );
};

const ChatIcon = styled.svg`
  margin-left: 10px;
  cursor: pointer;
  width: 22px;
  path {
    fill: #491f69;
  }
`;

const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.primaryLight};
  height: ${({ theme }) => theme.headerHeight};
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const LogoLink = styled(Link)`
  width: 160px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;

  ${({ theme }) => theme.md`
    width: 220px;
  `}
`;

const StyledButtonLink = styled(Link)`
  margin-right: ${({ theme }) => theme.spacingMd};

  svg {
    width: 18px;
    fill: ${({ theme }) => theme.primary};
  }
`;
StyledButtonLink.displayName = 'StyledButtonLink';

const UrgentAlertWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacingMd};
  transform: translateY(-2px);

  button {
    height: 23px;
  }
`;

export default Header;
