import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { debounce } from 'lodash';

import { useWindowSize } from '../../Hooks';

import { ReactComponent as ArrowIcon } from './arrow.svg';
import { getProfile, saveMoodQuestions } from '../../Actions/patient.actions';
import ModalComponent from '../../Components/Atoms/Modal/Modal';
import { useTranslation } from 'react-i18next';

const MOBILE_BRAKE_WIDTH = 500;

const EnterpriseMoodBox = ({ moodBox, profile }) => {
  const { t } = useTranslation();
  const [welcomeStatus, setWelcomeStatus] = useState(true);
  const [scores, setScores] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const history = useHistory();
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  const letsGo = () => setWelcomeStatus(false);

  const selectAnswer = debounce((index) => {
    setSelectedAnswer(index);
    insertAnswerInScores(index);

    if (scores?.length > currentStep) {
      setSelectedAnswer(undefined);
      setTimeout(() => setCurrentStep((step) => ++step), 300);
    } else if (scores?.length === currentStep) {
      dispatch(saveMoodQuestions({ data: scores })).then(() => {
        dispatch(getProfile()).then(() => history.push('/'));
      });
    }
  }, 300);

  const insertAnswerInScores = (index) => {
    if (index !== undefined) {
      const newScores = [...scores];
      newScores[currentStep - 1].score = index;

      setScores(newScores);
    }
  };

  const goForward = () => {
    setSelectedAnswer(undefined);
    setCurrentStep((step) => ++step);
  };

  const goBack = () => {
    setSelectedAnswer(undefined);
    setCurrentStep((step) => --step);
  };

  useEffect(() => {
    if (profile && profile['B2B'] && profile.register_date && profile.moodbox_style === 'b2b') {
      if (moment(profile.register_date).isSame(new Date(), 'day')) {
        setShowWelcomeModal(true);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (moodBox?.length) {
      const scores = moodBox.map((mood) => ({
        question_id: mood.id,
        score: mood.score ? mood.score : undefined,
      }));

      setScores(scores);
    }
  }, [moodBox]);

  const currentQuestion = useMemo(() => (moodBox?.length ? moodBox[currentStep - 1] : null), [moodBox, currentStep]);

  if (welcomeStatus) {
    return (
      <Wrapper>
        <BigHeader>{t('bigHeader')}</BigHeader>
        <AfterHeader>{t('afterHeader')}</AfterHeader>
        <StyledSimpleText primary>{t('StyledSimpleText')}</StyledSimpleText>
        {width >= MOBILE_BRAKE_WIDTH && <DesktopButton onClick={letsGo}>{t('letsGo')}</DesktopButton>}
        <StyledSimpleText>{t('StyledSimpleText2')}</StyledSimpleText>
        {width < MOBILE_BRAKE_WIDTH && <MobileButton onClick={letsGo}>{t('letsGo')}</MobileButton>}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ModalComponent open={showWelcomeModal}>
        <p>{t('modalCompPara1')}</p>
        <p>{t('modalCompPara2')}</p>
        <p>{t('modalCompPara3')}</p>
      </ModalComponent>
      <Question>{currentQuestion?.question}</Question>
      <AnswersWrapper>
        {currentQuestion?.answers?.map(({ title }, index) => (
          <Answer
            key={title}
            onClick={() => selectAnswer(index + 1)}
            selected={selectedAnswer === index + 1 || (scores && scores[currentStep - 1]?.score === index + 1)}
          >
            <QuestionCount>{index + 1}</QuestionCount>
            <Text>{title}</Text>
          </Answer>
        ))}
      </AnswersWrapper>
      <NavigationWrapper>
        <Navigation disabled={currentStep === 1} onClick={goBack}>
          <Icon as={ArrowIcon} />
        </Navigation>
        <QuestionStep>
          {currentStep} OF {moodBox?.length}
        </QuestionStep>
        <Navigation
          right
          onClick={goForward}
          disabled={
            !(
              (selectedAnswer !== undefined || (scores && scores[currentStep - 1]?.score !== undefined)) &&
              currentStep !== scores?.length
            )
          }
        >
          <Icon as={ArrowIcon} />
        </Navigation>
      </NavigationWrapper>
    </Wrapper>
  );
};

const BigHeader = styled.h2`
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;

  color: #00c8a0;

  @media screen and (min-width: ${MOBILE_BRAKE_WIDTH}px) {
    font-size: 48px;
    line-height: 48px;
  }
`;

const AfterHeader = styled.h3`
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;

  margin-top: 24px;

  @media screen and (min-width: ${MOBILE_BRAKE_WIDTH}px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const StyledSimpleText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: black;

  margin: 24px 0;

  @media screen and (min-width: ${MOBILE_BRAKE_WIDTH}px) {
    ${({ primary }) =>
      primary &&
      css`
        font-size: 24px;
        line-height: 28px;
      `}
  }
`;

const Wrapper = styled.div`
  padding: 30px;

  @media screen and (min-width: ${MOBILE_BRAKE_WIDTH}px) {
    padding: 50px 30px;
  }
`;

const MobileButton = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  background: #00c8a0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
  color: white;
`;

const DesktopButton = styled.div`
  height: 62px;
  width: 262px;
  border-radius: 0;
  background: #00c8a0;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
  color: white;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Question = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: left;

  color: #00c8a0;

  @media screen and (min-width: ${MOBILE_BRAKE_WIDTH}px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const AnswersWrapper = styled.div`
  max-width: 356px;
  margin: 30px auto;
`;

const QuestionCount = styled.div`
  width: 48px;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-right: 1px solid white;
  margin-left: 4px;

  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;

  color: #00c8a0;

  user-select: none;
`;

const Text = styled.div`
  margin-left: 20px;
  margin-right: 20px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: black;

  user-select: none;

  @media screen and (min-width: ${MOBILE_BRAKE_WIDTH}px) {
    font-size: 14px;
    line-height: 32px;
  }
`;

const Answer = styled.div`
  width: 100%;
  height: 48px;
  background: #f0f1f6;
  border-radius: 100px;

  display: flex;
  align-items: center;

  margin-bottom: 10px;

  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: #eaebef;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #00c8a0;

      ${QuestionCount} {
        color: white;
      }

      ${Text} {
        color: white;
        font-weight: bold;
      }

      &:hover {
        background: #01b894;
      }
    `};

  @media screen and (min-width: ${MOBILE_BRAKE_WIDTH}px) {
    height: 56px;
  }
`;

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 30px;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  @media screen and (min-width: ${MOBILE_BRAKE_WIDTH}px) {
    position: initial;
    margin: 50px 0 30px 0;
  }
`;

const Icon = styled.svg``;

const Navigation = styled.div`
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  &:hover {
    background: #f0f1f6;

    ${Icon} {
      width: 20px;
      height: 20px;
      path {
        fill: #00c8a0;
      }
    }
  }

  ${({ right }) =>
    right &&
    css`
      ${Icon} {
        transform: rotate(180deg);
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      ${Icon} {
        path {
          fill: grey;
        }
      }
    `};
`;

const QuestionStep = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: black;
`;

export default EnterpriseMoodBox;
