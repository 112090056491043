import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getActiveChatRooms, getScheduleList } from '../../Actions/therapist.actions';

import Route from '../../Routes/Route';
import DashboardLayout from '../../Layout/DashboardLayout';
import Overview from './Overview';
import Worksheets from './Worksheets';
import Patients from './Patients';
import Activity from './Activity';
import Notifications from './Notifications';
import Calendar from './Calendar';
import Tasks from './Tasks';
import Resources from './Resources';
import NotificationsSettings from '../Account/Notifications';
import { isNixy } from '../../Config';

const Dashboard = () => {
  const profile = useSelector((store) => store.user?.profile);
  const isPasswordExpired = useSelector((store) => store.auth?.password_expired);
  const isTherapist = useSelector((store) => store.auth?.role) === 'therapist';

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getScheduleList());
    dispatch(getActiveChatRooms());
  }, [dispatch]);

  useEffect(() => {
    isTherapist && isPasswordExpired && history.push('/t/account/edit');
  }, [isPasswordExpired, history, isTherapist]);

  useEffect(() => {
    const firstLogin = localStorage.getItem('firstLoginTherapist');
    if (profile && !firstLogin && profile.profile_status === 'Profile incomplete') history.push('/t/signup/step-2');
  }, [profile, history]);

  return (
    <DashboardLayout hideBottomSpacing={true}>
      <Switch>
        {/* Private Routes */}
        <Route exact path="/t/dashboard" component={Overview} isPrivate />
        <Route exact path="/t/dashboard/worksheets/:category" component={Worksheets} isPrivate />
        <Route exact path="/t/dashboard/patients" component={Patients} isPrivate />
        <Route exact path="/t/dashboard/activity" component={Activity} isPrivate />
        <Route exact path="/t/dashboard/notifications" component={Notifications} isPrivate />
        <Route exact path="/t/dashboard/calendar" component={Calendar} isPrivate />
        <Route exact path="/t/dashboard/tasks" component={Tasks} isPrivate />
        {isNixy ? null : <Route exact path="/t/dashboard/resources/:category" component={Resources} isPrivate />}
        <Route exact path="/t/dashboard/notifications-settings" component={NotificationsSettings} isPrivate />

        {/* Default Route */}
        <Route component={Overview} />
      </Switch>
    </DashboardLayout>
  );
};

export default Dashboard;
