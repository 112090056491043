import React, { memo } from 'react';

import { TherapyTrackerTable } from '../../Components/Organisms';
import { DefaultLayout } from '../../Layout';
import { PageHeading } from '../../Components/Molecules';

import { ReactComponent as TrackerIcon } from '../../Assets/Icons/Tracker.svg';

const TherapyTracker = () => (
  <DefaultLayout>
    <PageHeading icon={TrackerIcon} name={'Wellness Tracker'} />
    <TherapyTrackerTable />
  </DefaultLayout>
);

export default memo(TherapyTracker);
