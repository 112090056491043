import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import api from '../../../Util/api';

const ProgressBar = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    api.chatroom
      .getTherapyJourney(props.roomID)
      .then((response) => response.data)
      .then((result) => {
        const numOfStages = result.data.length;
        // TODO: Is this the correct way to set this progress bar length?
        setValue(Math.ceil(100 / numOfStages));
      })
      .catch((error) => console.log('error', error));
  }, [props.roomID]);

  return props.progress ? (
    <ProgressOuter {...props}>
      <ProgressInner style={{ width: `${props.progress}%` }} />
    </ProgressOuter>
  ) : (
    <ProgressOuter {...props}>
      <ProgressInner style={{ width: `${value}%` }} />
    </ProgressOuter>
  );
};
const ProgressOuter = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.greyLight};
`;
const ProgressInner = styled.div`
  height: 8px;
  background-color: ${({ theme }) => theme.primaryMedium};
`;

export default memo(ProgressBar);
