import React from 'react';
import NixyLogo from '../Assets/nixy_sidebar-2.png';
import SRLogo from '../Assets/SRSideLogo.png';
import { isNixy } from '.';

const OrganisationSidebar = () => {

  return (
    <>
      <div style={{ width: '20%' }}>
        <div style={{ margin: 15, marginRight: 15, marginLeft: 10 }}>
          <a href='https://mysupportroom.com/' target='_blank' style={{ pointerEvents: isNixy ? 'all' : 'none' }}>
            <img
              src={SRLogo}
              style={{
                width: 40,
                height: undefined,
                aspectRatio: 1,
                borderRadius: 10,
                cursor: 'pointer',
                border: isNixy ? "" : '3px solid #fff',
              }}
              alt="sr"
            />
          </a>
        </div>
        <div style={{ margin: 15, marginRight: 15, marginLeft: 10 }}>
          <a href='https://nixysupport.com/' target='_blank' style={{ pointerEvents: isNixy ? 'none' : 'all' }}>
            <img
              src={NixyLogo}
              style={{
                width: 40,
                height: undefined,
                aspectRatio: 1,
                borderRadius: 10,
                cursor: 'pointer',
                border: isNixy ? '3px solid #fff' : '',
              }}
              alt="nixy"
            />
          </a>
        </div>
      </div>
      <div
        style={{
          height: '100%',
          width: 1,
          backgroundColor: '#909090',
          marginLeft: 10,
        }}
      />
    </>
  );
};

export default OrganisationSidebar;