import React from 'react';
import { Heading } from '../../Components/Atoms';
import { Widget } from '../../Components/Atoms';
import { PatientList } from './Widgets';

const Patients = () => {
  return (
    <>
      <Heading>Active Patients</Heading>
      <Widget>
        <div style={{ overflowX: 'auto' }}>
          <PatientList showUnreadMessages segregatePatientStatus showActivePatients />
        </div>
      </Widget>

      <Heading>Inactive Patients</Heading>

      <Widget>
        <div style={{ overflowX: 'auto' }}>
          <PatientList showUnreadMessages segregatePatientStatus showActivePatients={false} />
        </div>
      </Widget>
    </>
  );
};

export default Patients;
