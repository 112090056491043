import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Container, Heading, Loading, Tooltip } from '../../Components/Atoms';
import { Header, TherapyTools } from '../../Components/Organisms';
import { api } from '../../Util';
import { therapyToolsLinks } from '../Chatroom/Components/Sidebar';
import { ReactComponent as whiteArrowIcon } from './whiteArrow.svg';
import { ReactComponent as purpleArrowIcon } from './purpleArrow.svg';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import config from '../../Config';

function extractContent(s) {
  var span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

const Discounts = () => {
  const [list, setList] = useState([]);
  const [category, setcategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await api.patient.discounts();
      setLoading(false);
      setList(response?.data?.data);
    };
    getData();
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);
      const response = await api.patient.viewDiscountCategories();
      setLoading(false);
      setcategory(response?.data?.data);
    };
    getCategories();
  }, []);

  const moveTo = (link) => history.push(link);

  const recordPageView = async (id, discount_id) => {
    await api.patient.recordPageView(id, discount_id);
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Loading styles={{ position: 'fixed', backgroundColor: 'rgba(255,255,255,255)' }} />
      </div>
    );
  }

  return (
    <>
      <Header />
      <Container>
        <Wrapper>
          <SubWrapper>
            <div>
              <TherapyTools
                heading="Therapy Tools"
                links={therapyToolsLinks}
                isChatRoomActive={true}
                setModalState={() => null}
              />
            </div>
          </SubWrapper>
          <DiscountWrapper>
            <React.Fragment>
              <HeadingWrapper>
                <Heading style={{ fontSize: '25px', marginTop: '0px' }}> {t('discounts')} </Heading>
              </HeadingWrapper>

              <TopCardContainer>
                {list?.length ? (
                  <TopCard>
                    <TopCardDataWrapper>
                      <Tooltip
                        id={list[0].description}
                        text={extractContent(list[0].description)}
                        place="top"
                        multiline={true}
                      >
                        <TopCardTitle style={{ fontSize: '20px', color: 'white' }}>
                          {<div dangerouslySetInnerHTML={{ __html: list[0].description }} />}
                        </TopCardTitle>
                      </Tooltip>
                      <TopCardSection>
                        <div>
                          <Tooltip
                            id={list[0].instructions}
                            text={extractContent(list[0].instructions)}
                            place="top"
                            multiline={true}
                          >
                            <TopCardTitle style={{ fontSize: '18px', color: 'white' }}>
                              {<div dangerouslySetInnerHTML={{ __html: list[0].instructions }} />}
                            </TopCardTitle>
                          </Tooltip>
                        </div>
                        <div>
                          <Tooltip id={list[0].title} text={extractContent(list[0].title)} place="top" multiline={true}>
                            <TopCardTitle style={{ color: 'white' }}> {list[0].title}</TopCardTitle>
                          </Tooltip>
                        </div>
                      </TopCardSection>
                      <TopButtonWrapper>
                        <a href={list[0].coupon} target="_blank" rel="noreferrer">
                          <Button
                            style={{ color: '#491f69', backgroundColor: 'white' }}
                            onClick={(id, discount_id) => {
                              recordPageView(list[0].category_id, list[0].id);
                            }}
                            type="primary"
                          >
                            {t('redeemNow')}
                          </Button>
                        </a>
                      </TopButtonWrapper>
                    </TopCardDataWrapper>

                    <TopImageWrapper>
                      <TopLogoWrapper
                        style={{
                          backgroundImage: `url(${list[0].logo})`,
                        }}
                      >
                        <TopCardLogoImage src={list[0].logo} alt="logo" fetchpriority="high" />
                      </TopLogoWrapper>
                      <TopHeaderWrapper
                        style={{
                          backgroundImage: `url(${list[0].header_image})`,
                        }}
                      >
                        <TopCardHeaderImage
                          src={list[0].header_image}
                          alt="header"
                          loading="eager"
                          placeholderSrc={config.iconLogo}
                          fetchpriority="high"
                        />
                      </TopHeaderWrapper>
                    </TopImageWrapper>
                  </TopCard>
                ) : null}
              </TopCardContainer>
            </React.Fragment>

            {/*----------------------------------------------------------- Top Categories--------------------------------------------------------- */}

            <CategoryHeadingWrapper>
              <Heading style={{ fontSize: '25px' }}> {t('topCategories')}</Heading>

              <CategorySection>
                <BrowseCategories onClick={() => moveTo('/discounts/categories')}>
                  {t('browseByCategories')}
                </BrowseCategories>
                <BrowseCategoriesIcon as={purpleArrowIcon} style={{ marginBottom: '5px' }} />
              </CategorySection>
            </CategoryHeadingWrapper>

            <CategoriesStyledContainer>
              {category?.length
                ? category.slice(0, 4).map((element) => (
                    <CategoryCard
                      style={{
                        backgroundImage: `linear-gradient(180deg, transparent, rgba(73, 31, 105, 1)), url(${element.image})`,
                      }}
                      id={element.id}
                      onClick={() => {
                        moveTo(`/discounts/categories/${element.id}`);
                      }}
                    >
                      <CategorySection>
                        <Title style={{ fontSize: '17px', color: 'white' }}> {element.name}</Title>

                        <Icon as={whiteArrowIcon} style={{ color: 'white' }} />
                      </CategorySection>
                    </CategoryCard>
                  ))
                : null}
            </CategoriesStyledContainer>
            {/* -------------------------------------------------------------Top Discounts---------------------------------------------------------- */}
            <React.Fragment>
              <HeadingWrapper>
                <Heading style={{ fontSize: '25px' }}> {t('topDiscounts')} </Heading>
              </HeadingWrapper>

              <StyledContainer>
                {list?.length
                  ? list.map((item, index) => (
                      <StyledCard>
                        <DiscountLogoWrapper
                          style={{
                            backgroundImage: `url(${item.logo})`,
                          }}
                        >
                          <DiscountLogo src={item.logo} alt="logo" fetchpriority="high" />
                        </DiscountLogoWrapper>
                        <DiscountHeaderWrapper
                          style={{
                            backgroundImage: `url(${item.header_image})`,
                            visibility: 2,
                          }}
                        >
                          <DiscountHeader
                            src={item.header_image}
                            alt="header"
                            placeholderSrc={config.iconLogo}
                            loading="eager"
                            fetchpriority="high"
                          />
                        </DiscountHeaderWrapper>

                        <Tooltip
                          id={item.description + index}
                          text={extractContent(item.description)}
                          place="top"
                          multiline={true}
                        >
                          <Title style={{ fontSize: '15px', marginTop: '30px' }}>
                            {<div dangerouslySetInnerHTML={{ __html: item.description }} />}
                          </Title>
                        </Tooltip>
                        <Section>
                          <Tooltip
                            id={item.instructions + index}
                            text={extractContent(item.instructions)}
                            place="top"
                            multiline={true}
                          >
                            <Title style={{ fontSize: '15px' }}>
                              {<div dangerouslySetInnerHTML={{ __html: item.instructions }} />}
                            </Title>
                          </Tooltip>
                          <Tooltip
                            id={item.title + index}
                            text={extractContent(item.title)}
                            place="top"
                            multiline={true}
                          >
                            <Title> {item.title}</Title>
                          </Tooltip>

                          <ButtonWrapper>
                            <a href={item.coupon} target="_blank" rel="noreferrer">
                              <Button
                                type="primary"
                                onClick={(id) => {
                                  recordPageView(item.category_id, item.id);
                                }}
                              >
                                {t('redeemNow')}
                              </Button>
                            </a>
                          </ButtonWrapper>
                        </Section>
                      </StyledCard>
                    ))
                  : null}
              </StyledContainer>
            </React.Fragment>
          </DiscountWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default Discounts;

const StyledContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;

  ${({ theme }) => theme.md`
    align-items: flex-start;
    justify-content: flex-start;
  `}
`;

const DiscountLogo = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
  position: absolute;
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 110px;
  z-index: 99;
  backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const DiscountHeader = styled(LazyLoadImage)`
  height: 135px;
  width: 240px;
  backdrop-filter: blur(7px);
  object-fit: contain;
  position: relative;
  border-radius: 20px;
  border: 1px solid #00000021;
`;

const CategoriesStyledContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  ${({ theme }) => theme.md`
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: none;
    flex-flow: none;
    flex-wrap: wrap;
    width: auto;
  `}
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;

  ${({ theme }) => theme.md`
    justify-content: space-between;
  `}

  ${({ theme }) => theme.lg`
    justify-content: space-between;
  `}
`;

const CategoryHeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 15px auto;

  ${({ theme }) => theme.md`
    justify-content: space-between;
    flex-direction: row;
  `}
`;

const CategorySection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.svg`
  transform: scaleX(-1);
`;

const SubWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.md`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 300px;
  align-items: flex-start;
  `}
`;

const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  margin-right: 0px;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 30px;

  ${({ theme }) => theme.md`
  margin-right: 10%;
  `}
`;

const BrowseCategories = styled.h3`
  color: #491f69;
  cursor: pointer;

  ${({ theme }) => theme.md`
  display: block;
  `}
`;

const BrowseCategoriesIcon = styled.svg`
  transform: scaleX(-1);

  ${({ theme }) => theme.md`
    display: block;
  `}
`;

const StyledCard = styled.div`
  height: 350px;
  width: 270px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #dad9da;
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-self: center;
  justify-content: space-between;
  text-align: left;
`;

const CategoryCard = styled.div`
  height: 120px;
  width: 199px;
  border-radius: 40px;
  background-color: white;
  border: 1px solid #dad9da;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-self: center;
  justify-content: flex-end;
  text-align: left;
  cursor: pointer;
  background-size: cover;
`;

const Wrapper = styled.div`
  background-color: white;
  margin-top: 30px;
  display: flex;
  gap: 20px;

  ${({ theme }) => theme.lg`
  gap:0px;
  `}
`;

const Title = styled.div`
  font-size: 17px;
  color: #491f69;
  font-weight: 500;
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 230px;
  overflow: hidden;
`;

const TopCardTitle = styled.div`
  font-size: 17px;
  color: #491f69;
  font-weight: 500;
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 310px;

  overflow: hidden;

  ${({ theme }) => theme.md`
  width: 150px;
  `}

  ${({ theme }) => theme.lg`
  width: 210px;
  `}
`;

const TopImageWrapper = styled.div`
  margin-left: 0;

  ${({ theme }) => theme.md`
  margin-left:30px;
  `}
`;

const TopCardLogoImage = styled.img`
  height: 60px;
  width: 60px;
  position: absolute;
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 150px;
  z-index: 99;
  object-fit: contain;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  backdrop-filter: blur(7px);

  ${({ theme }) => theme.md`
    width: 60px;
    height: 60px;
    ${'' /* margin-left: -40px;
    margin-top: 115px; */}
    margin-left: -25px;
    margin-top: 100px;
  `}

  ${({ theme }) => theme.lg`
    width: 100px;
    height: 100px;
    margin-left: -45px;
    margin-top: 145px;
  `}
`;

const TopCardHeaderImage = styled(LazyLoadImage)`
  height: 180px;
  width: 320px;
  border-radius: 20px;
  object-fit: contain;
  backdrop-filter: blur(7px);

  ${({ theme }) => theme.md`
  ${'' /* width: 400px;
    height: 225px; */}
    height: 180px;
    width: 320px;
    ${'' /* width: 368px;
    height: 207px; */}
  `}

  ${({ theme }) => theme.lg`

    width: 480px;
    height: 270px;
  `}
`;

const TopLogoWrapper = styled.div`
  border-radius: 10px;
`;

const TopHeaderWrapper = styled.div`
  border-radius: 20px;
`;

const DiscountLogoWrapper = styled.div`
  border-radius: 10px;
`;

const DiscountHeaderWrapper = styled.div`
  border-radius: 20px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

const TopCardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

const TopCardContainer = styled.div`
  width: auto;
  margin: auto;

  ${({ theme }) => theme.md`
  width: 100%;
  height: auto;
  `}
`;

const TopCardDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: 15px;
  height: 100%;
`;

const TopCard = styled.div`
  height: auto;
  width: auto;
  border-radius: 20px;
  border: 1px solid #dad9da;
  display: flex;
  padding: 20px;
  align-items: center;
  background-color: #491f69;
  justify-content: space-between;
  text-align: left;
  flex-direction: column-reverse;
  gap: 40px;

  ${({ theme }) => theme.md`
  flex-direction: row;
  height: auto;
  width: 100%;
  gap:20px;
  `}

  ${({ theme }) => theme.lg`
  gap:20px;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;
