import React from 'react';
import { DashboardLayout } from '../../Layout';
import Details from './Details';
import Edit from './Edit';
import { Switch } from 'react-router-dom';
import Route from '../../Routes/Route';

const index = () => {
  return (
    <>
      <DashboardLayout>
        <Switch>
          {/* Private Routes */}
          <Route exact path="/t/account" component={Details} isPrivate />
          <Route exact path="/t/account/edit" component={Edit} isPrivate />

          {/* Default Route */}
          <Route component={Details} />
        </Switch>
      </DashboardLayout>
    </>
  );
};

export default index;
