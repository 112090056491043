import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';

import { getProfile, update2FAProfile } from '../../../Actions/patient.actions';

import api from '../../../Util/api';
import { mobilePhoneValidation } from '../../../Constants/validations';

import { Alert } from '../../../Components/Atoms';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, FormGroup, Heading, HelpText, Label, ModalComponent } from '../../../Components/Atoms';
import { useDispatch } from 'react-redux';

const PersonalInfo = ({ userInfo }) => {
  const { t } = useTranslation();
  const isIntrasoft = window.location.host === 'intrasoft.supportroom.com';
  const history = useHistory();
  const [mobile, setMobile] = useState(userInfo.mobile);
  // const [twoFactor, setTwoFactor] = useState({ status: false });
  const [twoFactorModal, setTwoFactorModal] = useState(false);
  const [smsCode, setSmsCode] = useState({
    status: 'disabled',
    code: null,
  });
  const [smsError, setSmsError] = useState(null);
  const [password, setPassword] = useState('');
  const [verificationMethod, setVerificationMethod] = useState('whatsapp');

  const dispatch = useDispatch();
  const handleOnSubmit = ({ anonymous, ...values }) => {
    const formData = {
      ...values,
      anonymous: +anonymous,
    };
    api.patient
      .updateProfile(formData)
      .then(function (response) {
        dispatch(getProfile()).then(() => {
          history.push('/account');
        });
        console.log('response', response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t('firstNameIsRequired')),
    last_name: Yup.string().required(t('lastNameIsRequired')),
    mobile: mobilePhoneValidation(t),
    nickname: Yup.string(),
    anonymous: Yup.boolean(),
    date_of_birth: Yup.date(),
  });

  const mobileOnChange = (phone, setFieldValue) => {
    setMobile(phone);
    setFieldValue('mobile', phone);
  };

  const toggleTwoFactor = () => setTwoFactorModal(true);

  const activateTwoFactor = () => null;

  const get2FASmsCode = () => {
    api.others.toggleTwoFactor({ status: 1, password, notification_type: verificationMethod }).then((res) => {
      if (res.data.status === 'success') {
        setSmsCode((state) => ({ ...state, status: 'waiting' }));
      } else if (res.data.status === 'error') {
        setSmsError(res.data.message);
      }
    });
  };

  const activate2FA = () => {
    api.others.toggleTwoFactor({ status: 1, password, code_2fa: smsCode.code }).then((res) => {
      if (res.data.status === 'success') {
        setSmsCode({ status: 'disabled', code: null });
        setPassword('');
        setTwoFactorModal(false);
        dispatch(update2FAProfile(true));
      } else if (res.data.status === 'error') {
        setSmsError(res.data.message);
      }
    });
  };

  const deactivate2FA = () =>
    api.others.toggleTwoFactor({ status: 0, password }).then((res) => {
      if (res.data.status === 'success') {
        dispatch(update2FAProfile(false));
        setTwoFactorModal(false);
        setPassword('');
      } else if (res.data.status === 'error') {
        setSmsError(res.data.message);
      }
    });

  const passwordOnChange = (e) => {
    setPassword(e.target.value);
  };

  const handleVerificationMethod = (value) => {
    setVerificationMethod(value);
  };

  const codeOnChange = (e) => {
    e.persist();
    setSmsCode((state) => ({ ...state, code: e.target.value }));
  };

  return (
    <Wrapper>
      {twoFactorModal && (
        <ModalComponent open closeModal={() => setTwoFactorModal(false)} closeOnDocumentClick={false}>
          <Heading level={3}>{t('TWOFACTORAUTHENTICATION')}</Heading>
          <Wrapper>
            <Formik
              initialValues={{
                password: '',
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string(),
              })}
              onSubmit={activateTwoFactor}
            >
              {({ errors, touched }) => (
                <Form>
                  {!userInfo['2fa_active'] && smsCode.status === 'disabled' && (
                    <>
                      <FormGroup>
                        <Label htmlFor={'password'}>{t('password')}</Label>
                        <Field
                          value={password}
                          id="password"
                          name="password"
                          placeholder={t('accountPassword')}
                          type="password"
                          className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                          onChange={passwordOnChange}
                        />
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                      </FormGroup>
                      <FormGroup>
                        <Label>Select Verification Method</Label>
                        <Field
                          as="select"
                          id="notification_type"
                          name="notification_type"
                          onChange={(e) => handleVerificationMethod(e.target.value)}
                          style={{ display: 'block' }}
                        >
                          <option key="whatsapp" value="whatsapp">
                            Whatsapp
                          </option>
                          <option key="email" value="email">
                            Email
                          </option>
                          <option key="sms" value="sms">
                            SMS
                          </option>
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Button type="button" onClick={get2FASmsCode} disabled={!password}>
                          {t('getSMSCode')}
                        </Button>
                      </FormGroup>
                    </>
                  )}
                  {!userInfo['2fa_active'] && smsCode.status === 'waiting' && (
                    <>
                      <FormGroup>
                        <Label htmlFor={'sms_code'}>{t('SMSCode')}</Label>
                        <Field
                          id="sms_code"
                          name="sms_code"
                          placeholder={t('SMSCode')}
                          type="text"
                          className={'form-control' + (errors.sms_code && touched.sms_code ? ' is-invalid' : '')}
                          onChange={codeOnChange}
                        />
                        <ErrorMessage name="sms_code" component="div" className="invalid-feedback" />
                      </FormGroup>
                      <FormGroup>
                        <Button type="button" onClick={activate2FA} disabled={!smsCode.code}>
                          {t('Activate2FA')}
                        </Button>
                      </FormGroup>
                    </>
                  )}
                  {userInfo['2fa_active'] && (
                    <>
                      <FormGroup>
                        <FormGroup>
                          <Label htmlFor={'password'}>{t('password')}</Label>
                          <Field
                            value={password}
                            id="password"
                            name="password"
                            placeholder={t('accountPassword')}
                            type="password"
                            className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                            onChange={passwordOnChange}
                          />
                          <ErrorMessage name="password" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <Button type="button" onClick={deactivate2FA} disabled={!password}>
                          {t('Deactivate2FA')}
                        </Button>
                      </FormGroup>
                    </>
                  )}
                  {smsError && <Alert>{smsError}</Alert>}
                </Form>
              )}
            </Formik>
          </Wrapper>
        </ModalComponent>
      )}
      <Formik
        initialValues={{
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          mobile: userInfo.mobile,
          nickname: userInfo.nickname,
          anonymous: !!userInfo.anonymous,
          date_of_birth: userInfo.date_of_birth,
          new_password: '',
          two_factor: false,
        }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <FormGroup>
              <Label htmlFor={'first_name'}>{t('first_Name')}</Label>
              <Field
                id="first_name"
                name="first_name"
                placeholder={t('firstName')}
                type="text"
                className={'form-control' + (errors.first_name && touched.first_name ? ' is-invalid' : '')}
              />
              <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
            </FormGroup>
            <FormGroup>
              <Label htmlFor={'last_name'}>{t('last_Name')}</Label>
              <Field
                id="last_name"
                name="last_name"
                placeholder={t('lastName')}
                type="text"
                className={'form-control' + (errors.last_name && touched.last_name ? ' is-invalid' : '')}
              />
              <ErrorMessage name="last_name" component="div" className="invalid-feedback" />
            </FormGroup>
            <FormGroup>
              <Label htmlFor={'nickname'}>{t('nick_name')}</Label>
              <Field
                id="nickname"
                name="nickname"
                placeholder={t('nickname')}
                type="text"
                className={'form-control' + (errors.nickname && touched.nickname ? ' is-invalid' : '')}
              />
              <ErrorMessage name="nickname" component="div" className="invalid-feedback" />
            </FormGroup>
            <FormGroup>
              <Label htmlFor={'mobile'}>{t('mobileNumber')}</Label>
              <PhoneInput
                containerClass={errors.mobile && touched.mobile ? ' is-invalid' : ''}
                value={mobile}
                onChange={(phone) => mobileOnChange(phone, setFieldValue)}
                placeholder={t('mobileNumberPlaceholder')}
                enableSearch
              />
              <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
            </FormGroup>
            <FormGroup>
              <Label htmlFor={'date_of_birth'}>{t('date_Of_Birth')}</Label>
              <Field
                id="date_of_birth"
                name="date_of_birth"
                placeholder={t('dateOfBirth')}
                type="date"
                className={'form-control' + (errors.date_of_birth && touched.date_of_birth ? ' is-invalid' : '')}
              />
              <ErrorMessage name="date_of_birth" component="div" className="invalid-feedback" />
            </FormGroup>
            {!isIntrasoft ? (
              <div>
                {!userInfo['social_account'] && (
                  <FormGroup>
                    <Label htmlFor={'two-factor'}>{t('TwoFactorAuthentication')}</Label>
                    <Button type="button" style={{ width: 150 }} onClick={toggleTwoFactor}>
                      {userInfo['2fa_active'] ? t('Deactivate2FA') : t('Activate2FA')}
                    </Button>
                    <HelpText>{t('helpText')}</HelpText>
                  </FormGroup>
                )}
              </div>
            ) : null}

            <FormGroup>
              <Checkbox label={t('anonymous')} name="anonymous" />
              <HelpText>{t('helpText2')}</HelpText>
            </FormGroup>

            <Button type="submit">{t('save')}</Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .react-tel-input {
    li {
      color: black;
      text-align: left;
    }

    .form-control {
      width: 100%;
      height: 40px;
    }

    input {
      &::placeholder {
        color: #bebebe;
      }
    }

    .search {
      display: flex;
      height: 40px;
      padding: 10px;

      input {
        height: 25px;
      }
    }
  }
`;

PersonalInfo.propTypes = {
  userInfo: PropTypes.object.isRequired,
};

export default PersonalInfo;
