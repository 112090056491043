import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';

import { Button, ChevronIcon } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';

import MaleUser from '../../Assets/Mask Group 3@2x.png';
import FemaleUser from '../../Assets/Mask Group 4@2x.png';
import { MainSectionWrapper, BottomSectionWrapper } from '../Components/';
import { useDeviceType } from '../../Util';
import EmergencyBtn from '../../Components/Atoms/EmergencyBtn';
import { useTranslation } from 'react-i18next';

const General = () => {
  const socialRequestPopupStatus = useSelector((store) => store.auth.socialRequestPopupStatus);
  const paymentStatus = useSelector((store) => store.user?.profile?.payment_status);
  const paientProfile = useSelector((store) => store.user?.profile);
  const loginInfo = localStorage.getItem('loginInfo');
  const isLoggedIn = !!loginInfo;
  const history = useHistory();
  const isMobile = useDeviceType();
  const { t } = useTranslation();

  const [value, setValue] = useState(0);

  const onChange = (value) => {
    setValue(value);
  };

  if (isLoggedIn && !socialRequestPopupStatus && !paymentStatus === 'unpaid' && paientProfile['B2B'] !== 1)
    history.push('/chatroom');

  const nextPage = () => {
    if (paientProfile['B2B'] === 1) {
      history.push('/welcome/therapists');
    } else {
      history.push('/welcome/plans');
    }
  };

  return (
    <WelcomeLayout noPadding noTopBar>
      <section>
        <MainSectionWrapper isMobile={isMobile} noPadding>
          <CarouselWrapper>
            <Carousel
              selectedItem={value}
              onChange={onChange}
              autoPlay={true}
              infiniteLoop={true}
              interval={6500}
              showThumbs={false}
            >
              <section className="carousel-section" style={{ flex: 1, paddingBottom: 30 }}>
                <MainText>
                {t("weAllNeedTo")} <span style={{ color: '#33c8a0', fontWeight: 'bold' }}>{t("talk")}</span> {t("generalText1")}
                </MainText>
                <ImageContainer>
                  <img src={FemaleUser} alt="user" style={{ position: 'relative' }} />
                  <div className="first-circle" />
                  <div className="second-circle" />
                  <div className="third-circle" />
                  <div className="fourth-circle" />
                </ImageContainer>
                <SubText>
                {t("generalText3")}{' '}
                  <span style={{ color: '#33c8a0', fontWeight: 'bold' }}>{t("generalText4")}</span> {t("or")}{' '}
                  <span style={{ color: '#33c8a0', fontWeight: 'bold' }}>{t("generalText5")}</span> {t("generalText6")}
                </SubText>
              </section>

              <section className="carousel-section" style={{ flex: 1, paddingBottom: 30 }}>
                <MainText>
                  {/* We all need to <span style={{ color: '#33c8a0', fontWeight: 'bold' }}>track</span> what’s going on in
                  our lives but lack the tools */}
                  {t("generalText2")}
                </MainText>
                <ImageContainer>
                  <img src={MaleUser} alt="user" style={{ position: 'relative' }} />
                  <div className="first-circle" />
                  <div className="second-circle" />
                  <div className="third-circle" />
                  <div className="fourth-circle" />
                </ImageContainer>
                <SubText>
                {t("generalText7")}{' '}
                  <span style={{ color: '#33c8a0', fontWeight: 'bold' }}>{t("therapyTracker")}</span> {t("and")}{' '}
                  <span style={{ color: '#33c8a0', fontWeight: 'bold' }}>{t("journal")}</span>{t("generalText8")}
                  {/* As well as our online therapists, SupportRoom gives you a{' '}
                  <span style={{ color: '#33c8a0', fontWeight: 'bold' }}>Therapy Tracker</span>, to allow you to gauge
                  your moods on a daily basis, as well as a{' '}
                  <span style={{ color: '#33c8a0', fontWeight: 'bold' }}>Journal</span> to track what’s happening in
                  your therapy as well as your life. */}
                </SubText>
              </section>
            </Carousel>
          </CarouselWrapper>
          <BottomSection>
            <Button
              variant="secondary"
              style={{ fontWeight: 600, color: 'white', background: '#33c8a0' }}
              onClick={nextPage}
            >
              {t("next")}
              <ChevronIcon direction="right" width="13" height="13" />
            </Button>
          </BottomSection>
        </MainSectionWrapper>
      </section>

      <section>
        <BottomSectionWrapper>
          <EmergencyBtn />
        </BottomSectionWrapper>
      </section>
    </WelcomeLayout>
  );
};
const MainText = styled.div`
  @media screen and (min-width: 977px) {
    font-size: x-large;
    margin-bottom: 10px;
  }
  font-size: 18px;
  color: #491f69;
  font-weight: bold;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
`;
const SubText = styled.div`
  @media screen and (min-width: 977px) {
    font-size: medium;
    margin-bottom: 15px;
  }

  font-size: 16px;
  color: gray;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'Montserrat', sans-serif;
`;
const ImageContainer = styled.div`
  height: 230px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -20px;
  margin-bottom: 40px;
  @media screen and (max-width: 480px) {
    margin-top: -40px;
    margin-bottom: 20px;
  }
  img {
    width: 200px !important;
    @media screen and (max-width: 480px) {
      width: 130px !important;
    }
  }
  .first-circle {
    width: 60px;
    height: 60px;
    background: #e2f2ec;
    border-radius: 50%;
    opacity: 0.54;
    position: absolute;
    top: 12px;
    @media screen and (max-width: 480px) {
      top: 46px;
    }
    right: 6.5rem;
  }
  .second-circle {
    width: 11px;
    height: 11px;
    background: #e4e4e4;
    border-radius: 50%;
    position: absolute;
    right: 4rem;
    @media screen and (max-width: 480px) {
      right: 6rem;
    }
  }
  .third-circle {
    width: 56px;
    height: 56px;
    background: #cba8ec;
    border-radius: 50%;
    opacity: 0.5;
    position: absolute;
    top: 12rem;
    right: 12.2rem;
    @media screen and (max-width: 480px) {
      top: 10rem;
      right: 10.2rem;
    }
  }
  .fourth-circle {
    width: 11px;
    height: 11px;
    background: #e4e4e4;
    border-radius: 50%;
    position: absolute;
    right: 17.5rem;
    top: 11rem;
    @media screen and (max-width: 480px) {
      right: 14.5rem;
      top: 11rem;
    }
  }
`;

const CarouselWrapper = styled.div`
  @media screen and (max-width: 977px) {
    width: 600px;
    height: 500px;
  }
  @media screen and (max-width: 640px) {
    width: 450px;
    // height:53vh;
  }
  @media screen and (max-width: 480px) {
    width: 350px;
    height: 100%;
  }
  margin: 0 auto;
  max-width: 924px;
  overflow: hidden;
  height: 500px;
  .carousel {
    overflow: initial !important;
  }

  .control-arrow,
  .carousel-status {
    display: none;
  }

  .control-dots {
    // right: -100px;
    bottom: -70px !important;
    @media screen and (max-width: 480px) {
      bottom: 0 !important;
    }
    li {
      width: 10px !important;
      height: 10px !important;
      // background: #c4c4c4 !important;
      background: #491f6987 !important;
      box-shadow: none !important;
      outline: none;
    }

    .selected {
      background: ${({ theme }) => theme.primary} !important;
    }
  }
  .carousel-section {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 10;
  margin-top: 40px;

  @media (max-width: 640px) {
    position: absolute;
    bottom: 30px;
    z-index: 999;
  }
`;
export default memo(General);
