import React, { useState } from 'react';

import UK from '../../Assets/UK.svg';
import Serbia from '../../Assets/Serbia.svg';
import Greece from '../../Assets/Greece.svg';
import UAE from '../../Assets/UAE.svg';
import Russia from '../../Assets/russia.png';
import Israel from '../../Assets/Israel.png';
// import { ReactComponent as UK } from '../../Assets/UK.svg';
// import { ReactComponent as Serbia } from '../../Assets/Serbia.svg';
// import { ReactComponent as Greece } from '../../Assets/Greece.svg';
// import { ReactComponent as UAE } from '../../Assets/UAE.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { api, useDeviceType } from '../../Util';
import { genericLanguageMapping } from '../../App';

const SwitchLanguage = ({ showWhitetext, positionBox }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const { i18n } = useTranslation();
  const profile = useSelector((store) => store.user?.profile);
  const isMobile = useDeviceType();
  const selectedLanguage = useSelector((store) => store.language.selectedLanguage);

  const switchLang = (value) => {
    if (value) {
      localStorage.setItem('lang', value);
      let path = window.location.pathname?.split('/')?.[1];
      const saveDefaultLang = async () => {
        await api.patient.patinetLangaugeSet(genericLanguageMapping[value || 'en']);
        window.location.pathname = window.location.pathname.replace(path, `${value}`);
        i18n.changeLanguage(value);
      };
      if (profile) {
        saveDefaultLang();
      } else {
        window.location.pathname = window.location.pathname.replace(path, `${value}`);
        i18n.changeLanguage(value);
      }
    } else {
      localStorage.setItem('lang', '');
    }
  };
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (country, icon, code) => () => {
    switchLang(code);
    setSelectedCountry(country);
    setSelectedIcon(icon);
    setIsOpen(false);
  };

  const options = [
    {
      label: 'English',
      key: '1',
      code: 'en',
      country: 'UK',
      icon: UK,
    },
    {
      label: 'Serbian',
      key: '2',
      code: 'sr',
      country: 'SRB',
      icon: Serbia,
    },
    {
      label: 'Greek',
      key: '3',
      code: 'el',
      country: 'GR',
      icon: Greece,
    },
    {
      label: 'Arabic',
      key: '4',
      code: 'ar',
      country: 'UAE',
      icon: UAE,
    },
    {
      label: 'Russian',
      key: '5',
      code: 'RU',
      country: 'Russia',
      icon: Russia,
    },
    {
      label: 'Hebrew',
      key: '6',
      code: 'IL',
      country: 'Israel',
      icon: Israel,
    },
  ];

  return (
    <div>
      <DropDownContainer>
        {/* <DropDownHeader showWhitetext={showWhitetext} onClick={toggling}> */}
        <DropDownHeader showWhitetext={showWhitetext}>
          <Image src={options.find((option) => option.code === selectedLanguage)?.icon || options[0].icon} alt="" />

          <span style={{ marginTop: 5, position: 'absolute' }}>
            {options.find((option) => option.code === selectedLanguage)?.country}
          </span>
        </DropDownHeader>
        {isOpen && (
          <React.Fragment>
            {/* <Arrow  isMobile={isMobile} positionBox={positionBox}></Arrow> */}
            <ArrowShadow isMobile={isMobile} positionBox={positionBox}></ArrowShadow>
          </React.Fragment>
        )}
        {isOpen && (
          <DropDownListContainer isMobile={isMobile} positionBox={positionBox}>
            <DropDownList>
              {options.map((option) => (
                <ListItem onClick={onOptionClicked(option.country, option.icon, option.code)} key={Math.random()}>
                  <Image src={option.icon} alt="" />
                  {option.label}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </div>
  );
};

export default SwitchLanguage;

// const Arrow = styled('div')`
//   width: 0;
//   height: 0;
//   border-left: 8px solid transparent;
//   border-right: 8px solid transparent;
//   border-bottom: 15px solid white;
//   position: absolute;
//   top: ${({ positionBox }) => (positionBox ? '180px' : '60px')};
//   filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
//   right: ${({ isMobile }) => (isMobile ? '80px' : '80px')};
//   right: ${({ positionBox }) => (positionBox ? '230px' : '80px')};

//   @media screen and (max-width: 400px) {
//     top: ${({ positionBox }) => (positionBox ? '180px' : '35px')};
//     right: ${({ positionBox }) => (positionBox ? '230px' : '25px')};
//   }
// `;

const ArrowShadow = styled('div')`
  width: 0px;
  height: 0px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
  font-size: 0px;
  line-height: 0px;
  position: relative;
  top: 2px;
  right: 80px;
  left: 0px;
  z-index: 200;
`;

const DropDownContainer = styled('div')`
  width: 70px;
  ${'' /* margin: 0 auto; */}
`;

const Image = styled.img`
  margin: 0px 8px 0px 0px;
  width: 30px;
`;

const DropDownHeader = styled('div')`
  font-weight: 500;
  font-size: 15px;
  color: ${({ showWhitetext }) => (showWhitetext ? '#491f69' : 'white')};
  position: relative;
`;

const DropDownListContainer = styled('div')`
  position: absolute;
  top: ${({ positionBox }) => (positionBox ? '195px' : '67px')};
  width: 110px;
  right: ${({ isMobile }) => (isMobile ? '5px' : '5px')};
  right: ${({ positionBox }) => (positionBox ? '150px' : '5px')};
  border-radius: 5px;

  @media screen and (max-width: 400px) {
    top: ${({ positionBox }) => (positionBox ? '195px' : '45px')};
  }
`;

const DropDownList = styled('ul')`
  margin: 0;
  padding: 1px 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background: white;
  box-sizing: border-box;
  border-radius: 5px;
  color: #491f69;
  font-size: 15px;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0.8em;
`;
