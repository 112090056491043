import React, { memo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';

import GymPassCarousel from './gympass-carousel';
import Popup from 'reactjs-popup';

import { Heading } from '../../Components/Atoms';
import GymPassForm from './gymPassFrom';

import headerImage from '../../Assets/GymPass/header.png';
import MobilePng from '../../Assets/GymPass/mobile.png';
import config from '../../Config';

import { ReactComponent as WatchIcon } from '../../Assets/GymPass/watch.svg';
import { ReactComponent as MobileIcon } from '../../Assets/GymPass/mobile.svg';
import { ReactComponent as ShieldIcon } from '../../Assets/GymPass/shield.svg';
import { ReactComponent as ProfileIcon } from '../../Assets/GymPass/profile.svg';
import { ReactComponent as GraphIcon } from '../../Assets/GymPass/graph.svg';
import { ReactComponent as MessageIcon } from '../../Assets/GymPass/message.svg';
import { ReactComponent as Facebook } from '../../Assets/SignUp/Social/facebook.svg';
import { ReactComponent as Twitter } from '../../Assets/SignUp/Social/twiter.svg';
import { ReactComponent as Instagram } from '../../Assets/SignUp/Social/instagram.svg';
import { ReactComponent as LinkedIn } from '../../Assets/SignUp/Social/link.svg';
import { ReactComponent as GymPassLogo } from '../../Assets/GymPass/gympass-logo.svg';
import { ReactComponent as CheckIcon } from '../../Assets/GymPass/check.svg';
import { ReactComponent as LaptopImg } from '../../Assets/GymPass/laptop.svg';
import { ReactComponent as InfoGraph } from '../../Assets/GymPass/info-graphic.svg';
import { useTranslation } from 'react-i18next';
import { Logo } from '../Signup/index';

const Login = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const questionnaireId = query.get('questionnaire_id');
  const gymPassUserId = query.get('gympass_user_id');

  const [isModalOpen, setIsModalOpen] = useState(questionnaireId ? true : false);
  const history = useHistory();

  const openModal = () => {
    if (!questionnaireId) {
      let search = '';
      if (gymPassUserId) {
        search = `?gympass_user_id=${gymPassUserId}`;
      }
      return history.push({ pathname: '/welcome/c/questionnaire/', search });
    }
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  return (
    <GymPassPage isModalOpen={isModalOpen}>
      <Popup
        modal
        open={isModalOpen}
        onClose={closeModal}
        closeOnDocumentClick={true}
        contentStyle={isModalOpen ? { overflow: 'auto', height: '100%', maxHeight: '550px' } : {}}
      >
        <div>
          <Heading style={{ color: 'black' }} level={2}>
            Please provide more information
          </Heading>
          <GymPassForm />
        </div>
      </Popup>

      <Helmet>
        <title>Gympass</title>
        <meta
          name="description"
          content="Log into MySupportRoom. Access your tailored therapy and counselling services in our secure online portal today."
        />
      </Helmet>
      <Header>
        <ContentWrapper>
          <HeaderLogos>
            <Logo src={config.nameLogo} onClick={() => history.push('/')} />
            <Icon as={GymPassLogo} />
          </HeaderLogos>
          <HeaderContent>
            <Left>
              <BigH2>{t("stopCoping")}</BigH2>
              <BigH2 style={{ marginBottom: 10 }}>{t("startLiving")}</BigH2>
              <p>{t("traditionalTherapyMakesYouWait")}</p>
              <p style={{ marginBottom: 40 }}>{t("supportRoomOnlineTherapy")}</p>
              <CustomButton onClick={openModal}>{t("startYourJourney")}</CustomButton>
            </Left>
            {/*<Right>Video</Right>*/}
          </HeaderContent>
        </ContentWrapper>
      </Header>
      <Section1 id="section-id-1">
        <ContentWrapper>
          <RLWrapper>
            <Left>
              <h2>{t("helpMessage")}</h2>
              <MidH2 style={{ marginBottom: 20 }}>{t("convenientConfidentialAlwaysOn")}</MidH2>
              <p>
              {t("gymPassText")}
              </p>
              <div style={{ marginBottom: 40 }}>
                <p>{t("OfferSupportFor")}</p>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%' }}>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("individuals")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("couples")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("students")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("lgbt")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("depression")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("trauma")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("eatingDisorders")}</span>
                    </CheckItem>
                  </div>
                  <div style={{ width: '50%' }}>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("sleepingAnxiety")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("anger")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("grief")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("addictions")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("selfHarm")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("ocd")}</span>
                    </CheckItem>
                    <CheckItem>
                      <Icon as={CheckIcon} /> <span>{t("panicAttacks")}</span>
                    </CheckItem>
                  </div>
                </div>
              </div>
              <CustomButton onClick={openModal}>{t("getStarted")}</CustomButton>
            </Left>
            <Right>
              <Icon id="img-laptop" as={LaptopImg} />
            </Right>
          </RLWrapper>
        </ContentWrapper>
      </Section1>
      <Section2 id="section-id-2">
        <GraphWrapper>
          <ContentWrapper>
            <div style={{ textAlign: 'center' }}>
              <h2 style={{ marginBottom: 10 }}>{t("howSupportRoomWorks")}</h2>
              <MidH2>{t("traditionalTherapyMakesYouWait")}</MidH2>
              <MidH2 style={{ marginBottom: 50 }}>{t("supportRoomWaitingForYou")}</MidH2>
            </div>
            <GraphSvgImage as={InfoGraph} />
            <div style={{ width: '100%', textAlign: 'center', marginTop: 50 }}>
              <CustomButton onClick={openModal}>{t("startYourJourney")}</CustomButton>
            </div>
          </ContentWrapper>
        </GraphWrapper>
      </Section2>
      <Section3 id="section-id-3">
        <ContentWrapper>
          <div style={{ display: 'flex' }}>
            <Left style={{ textAlign: 'right' }}>
              <img id="mobile-img" style={{ maxWidth: '287px', marginRight: 80 }} src={MobilePng} alt="Mobile" />
            </Left>
            <Right>
              <h2 style={{ marginBottom: 40 }}>{t("benefitsOfSupportRoom")}</h2>
              <div style={{ marginBottom: 40 }}>
                <CheckItem>
                  <Icon as={WatchIcon} /> <span>{t("supportWhenYouNeed")}</span>
                </CheckItem>
                <CheckItem>
                  <Icon as={MobileIcon} />
                  <span>{t("checkItem1")}</span>
                </CheckItem>
                <CheckItem>
                  <Icon as={ShieldIcon} /> <span>{t("checkItem2")} </span>
                </CheckItem>
                <CheckItem>
                  <Icon as={ProfileIcon} /> <span>{t("checkItem3")}</span>
                </CheckItem>
                <CheckItem>
                  <Icon as={GraphIcon} /> <span>{t("checkItem4")}</span>
                </CheckItem>
                <CheckItem>
                  <Icon as={MessageIcon} /> <span>{t("ongoingSupport")}</span>
                </CheckItem>
              </div>
              <CustomButton onClick={openModal}>{t("startYourJourney")}</CustomButton>
            </Right>
          </div>
        </ContentWrapper>
      </Section3>
      <Section4 id="section-id-4">
        <ContentWrapper>
          <h2>{t("supportRoomVirtualServices")}</h2>
          <MidH2>{t("contentWrapper1")}</MidH2>
          <p style={{ marginTop: 20 }}>
          {t("contentWrapper2")}{' '}
          </p>
          <GymPassCarousel />
          <div style={{ width: '100%', textAlign: 'center' }}>
            <CustomButton onClick={openModal}>{t("findOutMore")}</CustomButton>
          </div>
        </ContentWrapper>
      </Section4>
      <Footer>
        <Logo src={config.nameLogo} />
        <LinksWrapper>
          <a href="https://supportroom.com/about-us/" target="_blank" rel="noopener noreferrer">
          {t("aboutUs")}
          </a>
          <a href="https://supportroom.com/faqs/" target="_blank" rel="noopener noreferrer">
          {t("faq")}
          </a>
          <Link to="/terms-and-conditions/">{t("terms")}</Link>
          <Link to="/privacy-policy">{t("privacyPolicy")}</Link>
          {/* <a href="https://supportroom.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
            Terms
          </a>
          <a href="https://supportroom.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a> */}
          <a href="https://supportroom.com/emergency-resources/" target="_blank" rel="noopener noreferrer">
          {t("emergency")}
          </a>
        </LinksWrapper>
        <SocialWrapper>
          <a href="https://facebook.com/mysupportroom" target="_blank" rel="noopener noreferrer">
            <SocialIcon as={Facebook} />
          </a>
          <a href="https://twitter.com/mysupportroom" target="_blank" rel="noopener noreferrer">
            <SocialIcon as={Twitter} />
          </a>
          <a href="https://instagram.com/mysupportroom/" target="_blank" rel="noopener noreferrer">
            <SocialIcon as={Instagram} />
          </a>
          <a href="https://linkedin.com/company/supportroom/" target="_blank" rel="noopener noreferrer">
            <SocialIcon as={LinkedIn} />
          </a>
        </SocialWrapper>
      </Footer>
    </GymPassPage>
  );
};

const GymPassPage = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  //mix-blend-mode: normal;

  section {
    padding: 80px 0;
  }

  p {
    color: black;
  }

  ${({ isModalOpen }) =>
    isModalOpen &&
    css`
      position: fixed;
      width: 100%;
    `}

  @media screen and (max-width: 600px) {
    section {
      padding: 30px 0;
    }
  }
`;

const CustomButton = styled.div`
  display: inline-block;
  background: #00c8a0;
  border-radius: 10px;

  padding: 10px 20px;
  color: white;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    background: #00ad8b;
  }
`;

const Icon = styled.svg``;

const SwitchWrapper = styled.div`
  color: white;
  font-size: 16px;
  text-align: right;
`;

const Header = styled.div`
  width: 100%;
  height: 600px;
  padding: 40px;

  background-image: url(${headerImage});
  background-size: cover;

  ${Logo} {
    width: 200px;
  }

  @media screen and (max-width: 500px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 15px;
    height: 400px;

    ${Logo} {
      display: block;
      width: 170px;
      margin: 0 0 3px 0;
    }

    ${SwitchWrapper} {
      display: flex;
      div {
        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 435px) {
    height: 400px;
  }

  @media screen and (max-width: 435px) {
    ${Logo} {
      width: 150px;
    }
  }

  @media screen and (max-width: 340px) {
    ${Logo} {
      width: 140px;
    }
  }
`;

const HeaderLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Icon} {
    width: 130px;
  }

  @media screen and (max-width: 500px) {
    display: block;
    ${Icon} {
      width: 110px;
    }
  }
`;

const BigH2 = styled.h2`
  color: #00c8a0;
  font-size: 48px;
  line-height: 48px;
  font-weight: 900;
  margin: 0;
`;

const MidH2 = styled.h2`
  color: #00c8a0;
  font-size: 40px;
  line-height: 40px;
  font-weight: 900;
  margin: 0;
`;

const CheckItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    margin-right: 10px;
    width: 22px;
  }
  span {
    color: black;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  margin-top: 120px;

  p {
    color: white;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
  }
`;

const Left = styled.div`
  width: 50%;
  min-width: 435px;
`;

const Right = styled.div`
  width: 50%;
  min-width: 435px;
`;

const GraphWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: rgba(0, 200, 160, 0.05);
  padding: 80px 0;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const GraphSvgImage = styled.svg`
  width: 100%;

  max-width: 1400px;
  min-width: 700px;
`;

const Section1 = styled.section`
  padding-bottom: 0 !important;
  @media screen and (max-width: 950px) {
    ${Right} {
      width: 30%;
      min-width: auto;
    }
    ${Left} {
      width: 70%;
    }
  }
  @media screen and (max-width: 682px) {
    ${Right} {
      display: none;
    }
    ${Left} {
      width: 100%;
    }
  }
`;
const Section2 = styled.section`
  padding-bottom: 0 !important;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const Section3 = styled.section`
  padding-top: 0;

  @media screen and (max-width: 800px) {
    ${Right} {
      width: 65%;
    }
    ${Left} {
      width: 45%;
      min-width: auto;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 600px) {
    ${Left} {
      display: none;
    }
  }
`;
const Section4 = styled.section`
  background: rgba(0, 200, 160, 0.05);
`;

const RLWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  max-width: 1110px;
  margin: 0 auto;

  overflow: hidden;

  padding: 0 30px;

  @media screen and (max-width: 500px) {
    padding: 20px;

    ${HeaderContent} {
      margin-top: 40px;
    }

    ${BigH2} {
      font-size: 34px;
      line-height: 34px;
    }

    ${MidH2} {
      font-size: 28px;
      line-height: 28px;
    }

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }

    ${Left}, ${Right} {
      width: 100%;
      min-width: auto;
    }

    #img-laptop,
    #mobile-img {
      display: none;
    }
  }
`;

const LinksWrapper = styled.div`
  a {
    font-size: 12px;
    color: white;
    text-decoration: none;
    margin-right: 20px;
    opacity: 0.8;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

const SocialIcon = styled.svg`
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const SocialWrapper = styled.div`
  ${SocialIcon} {
    margin-right: 15px;
    height: 16px;
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 85px;
  background: #491f69;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 32px;

  ${Logo} {
    width: 165px;
  }

  @media screen and (max-width: 720px) {
    height: 100px;
    padding: 10px 32px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    ${Logo} {
      width: 130px;
      margin: 0;
      display: block;
    }

    ${LinksWrapper} {
      width: 100%;
      display: flex;
      justify-content: center;

      a {
        margin-right: 20px;
      }
    }
  }

  @media screen and (max-width: 420px) {
    ${LinksWrapper} {
      a {
        margin-right: 10px;
      }
    }
  }

  @media screen and (max-width: 370px) {
    ${LinksWrapper} {
      a {
        margin-right: 3px;
      }
    }
  }
`;

export default memo(Login);
