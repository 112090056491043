import React, { memo } from 'react';
import styled from 'styled-components';

const FormGroup = (props) => {
  return (
    <StyledFormGroup className={props.inline ? 'inline' : ''} {...props}>
      {props.children}
    </StyledFormGroup>
  );
};

const StyledFormGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.inline ? `row` : `column`)};
  flex-wrap: ${(props) => (props.inline ? `wrap` : null)};
  margin-bottom: ${({ theme }) => theme.spacing};

  &.inline {
    > * {
      margin: 0 4px;
    }
  }
`;

export default memo(FormGroup);
