import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { TherapistTheme } from '../Styles/theme';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getProfile as getPatientProfile, getSubscriptionStatus } from '../Actions/patient.actions';
import { getProfile as getTherapistProfile, updateProfile } from '../Actions/therapist.actions';

import isSubscriptionEnded from '../Util/isSubscriptionEnded';
import { api, checkPartner } from '../Util';
import { Checkbox, FormGroup, Heading } from '../Components/Atoms';
import ModalComponent from '../Components/Atoms/Modal/Modal';
import { Field, Form, Formik } from 'formik';
import config from '../Config';
import * as Yup from 'yup';

export default function RouteWrapper({ component: Component, role, isPrivate, ...rest }) {
  const LoginInfo = localStorage.getItem('loginInfo');
  const LoggedIn = !!LoginInfo;
  const userRole = LoginInfo && JSON.parse(LoginInfo).role;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const profile = useSelector((store) => store.user?.profile);
  const subscription = useSelector((store) => store.user?.subscription);
  const socialRequestPopupStatus = useSelector((store) => store.auth.socialRequestPopupStatus);

  const [termsPopUp, setTermsPopUp] = useState(true);

  const redirectRoutes = [
    '/account/subscription/manage',
    '/questionnaire',
    '/contact',
    '/logout',
    '/welcome/plans',
    '/welcome/general',
    '/welcome/start',
    '/welcome/activate',
    '/welcome/addpayment',
    '/help',
  ];

  useEffect(() => {
    if (LoggedIn && !profile) {
      if (role === 'patient') {
        dispatch(getSubscriptionStatus());
        dispatch(getPatientProfile());
        dispatch(checkPartner());
      } else if (role === 'therapist') dispatch(getTherapistProfile());
    }
  }, [dispatch, role, LoggedIn, profile]);

  if (isPrivate && !LoggedIn) {
    return <Redirect to="/" />;
  }

  if (isPrivate && role !== undefined && role !== userRole && userRole !== 'therapist') {
    return <Redirect to="/" />;
  }

  if (
    isPrivate &&
    role !== undefined &&
    role !== userRole &&
    userRole === 'therapist'
    //  &&
    // window.location.pathname !== '/teams'
  ) {
    return <Redirect to="/t/dashboard" />;
  }

  // Subscription checking for Redirection to payment page (ManageSubscription)
  if (
    role === 'patient' &&
    profile &&
    subscription &&
    isSubscriptionEnded(profile.first_message, subscription) &&
    !redirectRoutes.includes(location.pathname) &&
    profile['B2B'] !== 1 &&
    !socialRequestPopupStatus
  ) {
    // return <Redirect to="/account/subscription/manage" />;
    return <Redirect to="/welcome/start" />;
  }

  if (role === 'therapist' && profile) api.others.lastPageVisit();

  const validationSchema = Yup.object().shape({
    accept_terms: Yup.array().min(1, t('checkBoxSelect')),
  });

  const handleOnSubmit = async ({ target: { checked } }) => {
    if (checked) {
      await dispatch(updateProfile({ terms_and_condition: 1 }));
      setTermsPopUp(false);
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        role === 'therapist' || role === 'company' ? (
          <ThemeProvider theme={TherapistTheme}>
            {profile && profile?.terms_and_condition === 0 && (
              <ModalComponent open={termsPopUp}>
                <Heading level={3}>{t('TermsAndConditions')}</Heading>
                <p>{t('acceptance')}</p>
                <Formik
                  initialValues={{
                    accept_terms: [],
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleOnSubmit}
                >
                  {() => (
                    <Form>
                      <CheckboxGroup role="group" aria-labelledby="checkbox-group">
                        <FormGroup>
                          <Field
                            onClick={handleOnSubmit}
                            as={Checkbox}
                            label={
                              <div>
                                <span>
                                  {t('yesIAgree')}{' '}
                                  <TCLink href={config.terms} download>
                                    {t('termsAndConditions')}
                                  </TCLink>
                                </span>
                              </div>
                            }
                            name="accept_terms"
                            value="consent"
                          />
                        </FormGroup>
                      </CheckboxGroup>
                    </Form>
                  )}
                </Formik>
              </ModalComponent>
            )}
            <Component {...props} />
          </ThemeProvider>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

const CheckboxGroup = styled.div`
  text-align: left;
  margin: ${({ theme }) => theme.spacingLg} 0;
`;

const TCLink = styled.a`
  color: ${({ theme }) => theme.primaryMedium};
  font-weight: ${({ theme }) => theme.fontMedium};
`;

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.func]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
