import React from 'react';
import styled from 'styled-components';
import { useDeviceType } from '../../../Util'
const TextBlock = (props) => {
  const isMobile = useDeviceType();
  return (
    <>
      {props.type === 'primary' && <BoldPrimaryText isMobile={isMobile} {...props}>{props.children}</BoldPrimaryText>}
      {props.type === 'secondary' && <NormalText isMobile={isMobile} {...props}>{props.children}</NormalText>}
      {props.type === 'emphasized' && <EmphasizedText isMobile={isMobile} {...props}>{props.children}</EmphasizedText>}
      {props.type === 'medium' && <MediumText isMobile={isMobile} {...props}>{props.children}</MediumText>}
    </>
  );
};
const BoldPrimaryText = styled.div`
  font-size:${({ isMobile }) => isMobile ? '18px' : 'x-large'};
  color: #491f69;
  margin-bottom:${({ isMobile }) => isMobile ? '10px' : ' 20px'};
  font-weight: bold;
  padding: ${(props) => (props.padding ? props.padding : '10px')};
  font-family: 'Montserrat', sans-serif;
`;
const NormalText = styled.div`
  font-size:${({ isMobile }) => isMobile ? '14px' : 'medium'};
  color: gray;
  margin-bottom:${({ isMobile }) => isMobile ? '15px' : ' 20px'};
  padding: ${(props) => (props.padding ? props.padding : '10px')};
  padding-left: ${(props) => (props.paddingHorizontal ? props.paddingHorizontal : '10px')};
  padding-right: ${(props) => (props.paddingHorizontal ? props.paddingHorizontal : '10px')};
  font-family: 'Montserrat', sans-serif;
`;
const EmphasizedText = styled.div`
  color: #33c8a0;
  font-weight: bold;
  margin-bottom:${({ isMobile }) => isMobile ? '15px' : ' 20px'};
  padding: ${(props) => (props.padding ? props.padding : '10px')};
  padding-left: ${(props) => (props.paddingHorizontal ? props.paddingHorizontal : '10px')};
  padding-right: ${(props) => (props.paddingHorizontal ? props.paddingHorizontal : '10px')};
  font-family: 'Montserrat', sans-serif;
`;
const MediumText = styled.div`
  font-size:${({ isMobile }) => isMobile ? '15px' : 'large'};
  color: gray;
  margin-bottom:${({ isMobile }) => isMobile ? '15px' : ' 20px'};
  padding: ${(props) => (props.padding ? props.padding : '10px')};
  padding-left: ${(props) => (props.paddingHorizontal ? props.paddingHorizontal : '10px')};
  padding-right: ${(props) => (props.paddingHorizontal ? props.paddingHorizontal : '10px')};
  font-family: 'Montserrat', sans-serif;
`;
export default TextBlock;
