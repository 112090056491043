import React, { useCallback, useMemo, memo } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import { cancelAppointmentsAsTherapist } from '../../../Actions/chatroom.actions';

import { Button } from '..';

import { ReactComponent as Video } from '../../../Assets/Icons/Video.svg';
import { ReactComponent as Message } from '../../../Assets/Icons/Messages.svg';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './bigCalendar.scss';
import { getScheduleList } from '../../../Actions/therapist.actions';

const CalendarComponent = ({
  events = [],
  onSelectSlot,
  chatRoomID,
  onSelectEvent,
  type,
  editState = false,
  deleteSlot,
  role,
  schedules = [],
  onlyMonth = false,
  chatRooms,
  onRangeChange = () => {},
  // if getSchedule is no there by default act on therapist calendar
  getSchedule = getScheduleList,
  ...rest
}) => {
  const dispatch = useDispatch();
  const loc = useMemo(() => momentLocalizer(moment), []);

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => {
      let style = {
        backgroundColor: event.slot.status === 'available' ? '#00af8c' : '#1A5549',
        cursor: 'default',
      };

      return {
        className: `big-calendar-component__global-event --big-calendar-component-event-type-${type}`,
        style,
      };
    },
    [type],
  );

  const canCancelAppointment = useCallback((data) => {
    const momentCurrentTime = moment();
    const diff = moment.duration(moment.utc(data.date).local().diff(moment(momentCurrentTime)));

    return diff.asHours() >= process.env.REACT_APP_APPOINTMENT_WITHIN;
  }, []);

  const removeSchedule = useCallback(
    (e, data) => {
      e.preventDefault();
      e.stopPropagation();

      if (canCancelAppointment(data))
        dispatch(cancelAppointmentsAsTherapist(data.slot.appointment_id)).then(() => dispatch(getSchedule(chatRoomID)));
    },
    [dispatch, canCancelAppointment, chatRoomID],
  );

  const CalendarEvent = useCallback(
    (props) => {
      const userName = chatRooms?.length
        ? chatRooms.find((room) => room.patient_id === props?.event?.slot?.patient_id)?.patient_full_name
        : null;

      const appointmentType = props?.event?.slot.appointment_type;

      return (
        <StyledEvent type={appointmentType}>
          {type === 'booked' && canCancelAppointment({ date: props.event.start }) ? (
            <CancelAppointment onClick={(e) => removeSchedule(e, { slot: props.event.slot, date: props.event.start })}>
              X
            </CancelAppointment>
          ) : null}
          <AppointmentType>
            {appointmentType === 'message' ? <Icon as={Message} /> : <Icon as={Video} />}
          </AppointmentType>
          <div className="big-calendar-component__global-event_time-title">
            <span>{moment(props.event.start).format('HH:mm')}</span>
            <span> to </span>
            <span>{moment(props.event.end).format('HH:mm')}</span>
          </div>
          {role === 'therapist' && userName && (
            <div className="big-calendar-component__global-event_patient-name">{userName}</div>
          )}
          {editState && props?.event?.slot?.status === 'available' && (
            <DeleteSlot onClick={() => deleteSlot(props?.event?.slot?.schedule_id)}>&times;</DeleteSlot>
          )}
        </StyledEvent>
      );
    },
    [editState, deleteSlot, role, chatRooms, removeSchedule, type, canCancelAppointment],
  );

  const ColoredDateCellWrapper = ({ children, value }) => {
    const momentDate = moment(value).format('YYYY-MM-DD');
    const eventExist = schedules?.find((date) => date?.schedule_date === momentDate);
    return React.cloneElement(children, {
      style: {
        ...children.style,
        backgroundColor: eventExist ? '#a0f5e4' : 'initial',
      },
    });
  };

  return (
    <Calendar
      className="big-calendar-component"
      localizer={loc}
      events={events}
      startAccessor="start"
      endAccessor="end"
      onSelectSlot={onSelectSlot}
      onSelectEvent={onSelectEvent}
      onRangeChange={onRangeChange}
      eventPropGetter={eventPropGetter}
      components={{
        event: onlyMonth ? null : CalendarEvent,
        dateCellWrapper: ColoredDateCellWrapper,
      }}
      defaultView={window.screen.width > 700 ? 'week' : 'day'}
      views={window.screen.width > 700 ? ['week'] : ['day']}
      {...rest}
    />
  );
};

const CancelAppointment = styled.div`
  position: absolute;
  width: 14px;
  height: 130%;

  background: #db3a3a;
  right: -4px;
  top: -4px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;

  cursor: pointer;

  &:hover {
    background: #b83333;
  }
`;

const AppointmentType = styled.div`
  position: absolute;
  top: -1px;
  right: 20px;
  color: black;
  font-weight: 600;
  font-size: 10px;
`;

const StyledEvent = styled.div`
  position: relative;
  font-size: 14px;
`;

const Icon = styled.svg`
  width: 10px;
  height: 10px;
  path {
    fill: white;
  }
`;

const DeleteSlot = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 20px;
  padding: 0 0 3px 0;
  font-size: 16px;
  min-width: unset;
`;

const mapStateToProps = (store) => ({
  role: store.auth.role,
  chatRooms: store.user.chatRooms,
});

export default memo(connect(mapStateToProps)(CalendarComponent));
