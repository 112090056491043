import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Heading, FormGroup, Button } from '../../Components/Atoms';
import { AuthLayout } from '../../Layout';
import api from '../../Util/api';
import { useTranslation } from 'react-i18next';

const Step3 = () => {
  const { t } = useTranslation();
  const nextStep = () => history.push('/c/signup/complete');
  const history = useHistory();

  const handleOnSubmit = (values, actions) => {
    api.company
      .createUser(values)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

    nextStep();
  };

  return (
    <>
      <AuthLayout>
        <Heading>{t("yourDetails")}</Heading>
        {/* <p>Your expertise will help us to match you with the most relevant patients. Please select all that apply.</p> */}
        <Wrapper>
          <Formik
            initialValues={{
              email: '',
              first_name: '',
              last_name: '',
              mobile: '',
            }}
            onSubmit={handleOnSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <FormGroup>
                  <Field
                    id="email"
                    name="email"
                    placeholder={t("Email")}
                    type="email"
                    className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="email" component="div" className="invalid-feedback" />
                </FormGroup>

                <FormGroup>
                  <Field
                    id="first_name"
                    name="first_name"
                    placeholder={t("firstName")}
                    type="text"
                    className={'form-control' + (errors.first_name && touched.first_name ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
                </FormGroup>

                <FormGroup>
                  <Field
                    id="last_name"
                    name="last_name"
                    placeholder={t("lastName")}
                    type="text"
                    className={'form-control' + (errors.last_name && touched.last_name ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="last_name" component="div" className="invalid-feedback" />
                </FormGroup>

                <FormGroup>
                  <Field
                    id="mobile"
                    name="mobile"
                    placeholder={t("phone")}
                    type="text"
                    className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                </FormGroup>

                <Button type="submit">{t("continue")}</Button>
              </Form>
            )}
          </Formik>
        </Wrapper>
      </AuthLayout>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`;

export default Step3;
