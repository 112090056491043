import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import GlobalStyle from '../globalStyles';
import { Container } from '../Components/Atoms';
import { Header, NotificationPopup } from '../Components/Organisms';
import { getNotifications } from '../Actions/chatroom.actions';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${(props) => props.theme.white};
`;
const StyledMain = styled.main`
  overflow: auto;
`;
const DefaultLayout = (props) => {
  const notificationsTimer = useRef(0);
  const dispatch = useDispatch();
  const notificationPagination = useSelector((store) => store.user?.notifications?.pagination);
  const subscription = useSelector((store) => store.user?.subscription);
  const notificationsPaginationRef = useRef({ from: 1, to: 50 });

  useEffect(() => {
    notificationsPaginationRef.current = notificationPagination;
  }, [notificationPagination]);

  useEffect(() => {
    const checkNotifications = async () => {
      await dispatch(getNotifications(notificationsPaginationRef.current));
      notificationsTimer.current = setTimeout(checkNotifications, 10000);
    };
    checkNotifications();
    return () => clearTimeout(notificationsTimer.current);
  }, [dispatch]);

  return (
    <>
      <GlobalStyle />
      <StyledWrapper
        style={{
          paddingBottom: props.noPadding ? null : '32px',
        }}
      >
        {subscription?.plan_status === 'active' && <NotificationPopup notificationsPath={'/notifications'} />}
        <div style={props.disableHeader ? { pointerEvents: 'none', opacity: 0.5 } : {}}>
          <Header noTopBar={props.noTopBar} />
        </div>
        <StyledMain id="mainContent">
          <Container>{props.children}</Container>
        </StyledMain>
      </StyledWrapper>
    </>
  );
};

export default memo(DefaultLayout);
