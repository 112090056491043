import {
  QUESTIONNAIRE_BACK_BEGIN,
  QUESTIONNAIRE_BACK_SUCCESS,
  QUESTION_PROGRESS,
  STARTQUIZ_SUCCESS,
  SUBMIT_QUIZ_ANSWER_SUCCESS,
  SUBMITTING_ANSWER,
  FETCHING_QUIZ_DATA,
  QUIZ_COMPLETED,
  TERMINATE_QUESTIONNAIRE,
} from '../Constants/actionTypes';
import api from '../Util/api';

export const startquiz = (data) => (dispatch) => {
  dispatch({ type: FETCHING_QUIZ_DATA, payload: true });
  return api.welcome
    .startQuiz()
    .then((res) => {
      if (res.data.status === 'success') {
        localStorage.setItem('quiz_journey', res.data.quiz_journey);
        dispatch({
          type: STARTQUIZ_SUCCESS,
          payload: res.data,
        });
      }
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};
export const submitQuizAnswer = (quizJourney, questionID, selectedAnswerIDs, gymPassUserId, history) => (dispatch) => {
  dispatch({ type: SUBMITTING_ANSWER });
  return api.welcome
    .submitQuizAnswer(quizJourney, questionID, selectedAnswerIDs)
    .then((res) => {
      if (res.data.status === 'success') {
        dispatch({
          type: SUBMIT_QUIZ_ANSWER_SUCCESS,
          payload: res.data,
        });
        if (res.data.translation_id === 173) {
          if (gymPassUserId) {
            let search = `?questionnaire_id=${quizJourney}`;
            search += `&gympass_user_id=${gymPassUserId}`;
            return history.push({ pathname: '/gympass/', search });
          }
          dispatch({ type: QUIZ_COMPLETED });
        }
      }
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};
export const questionnaireGoBack = (quizJourney) => (dispatch) => {
  dispatch({ type: QUESTIONNAIRE_BACK_BEGIN });
  return api.welcome
    .questionnaireGoBack(quizJourney)
    .then((res) => {
      if (res.data.status === 'success' && res.data.data?.id) {
        dispatch({
          type: QUESTIONNAIRE_BACK_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: QUESTIONNAIRE_BACK_SUCCESS,
        });
      }
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
};
export const addProgress = (percent) => (dispatch) => {
  dispatch({
    type: QUESTION_PROGRESS,
    payload: percent,
  });
};

/**
 *  This action is dispatched when the user choose an option which is to be terminated from from end. No need to pass this ans to backend,
 *
 */
export const terminateQuestionnaire = () => (dispatch) => {
  dispatch({
    type: TERMINATE_QUESTIONNAIRE,
  });
};
