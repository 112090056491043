const initialState = {
  isPartner: '',
  tagLine: '',
};

export default function isPartner(state = initialState, action) {
  switch (action.type) {
    case 'VERIFY_PARTNER_URL':
      return {
        ...state,
        isPartner: action.payload,
      };
    case 'PARTNER_TAGLINE':
      return {
        ...state,
        tagLine: action.payload,
      };
    default:
      return state;
  }
}
