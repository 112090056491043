import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { reportMissedSession } from '../../../Actions/patient.actions';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../Components/Atoms';
import { getToLocalSchedulesChatroom } from '../../../Selectors/schedules.selector';

const MAX_DELAY_MINUTES = -5;
const now = moment();
const currentDate = now.format('YYYY-MM-DD');

const MissedSessionButton = () => {
  const { t } = useTranslation();
  const [missedSession, setMissedSession] = useState(localStorage.getItem('missedSession'));
  const dispatch = useDispatch();
  const chatRoomID = useSelector((store) => store.chatroom?.chatRoomID);
  const schedules = useSelector((store) => getToLocalSchedulesChatroom(store));

  const todaySchedules = useMemo(() => {
    return schedules?.filter(({ schedule_date }) => schedule_date === currentDate) || [];
  }, [schedules]);

  const currentSlot = useMemo(() => {
    return todaySchedules[0]?.slots.find(({ schedule_time, schedule_end_time, chatroom_id }) => {
      const startTime = moment(`${currentDate} ${schedule_time}`, 'YYYY-MM-DD HH:mm:ss');
      const endTime = moment(`${currentDate} ${schedule_end_time}`, 'YYYY-MM-DD HH:mm:ss');
      return now.isBetween(startTime, endTime) && chatroom_id === chatRoomID;
    });
  }, [todaySchedules, chatRoomID]);

  const showButton = useMemo(() => {
    if (!currentSlot || !missedSession) return false;

    const diff = moment.duration(moment(`${currentDate} ${currentSlot.schedule_time}`).diff(now));
    const therapistDelay = +diff.asMinutes().toFixed(0);
    let sessionNotReported = false;
    if (missedSession) {
      const [date, endDate] = missedSession.split(' ');
      sessionNotReported = +moment.duration(now.diff(moment(`${date} ${endDate}`))).asMinutes().toFixed(0) > 0;
    }
    return MAX_DELAY_MINUTES > therapistDelay && sessionNotReported;
  }, [currentSlot, missedSession]);

  const handleOnClick = async () => {
    await dispatch(reportMissedSession());
    localStorage.setItem('missedSession', `${currentDate} ${currentSlot.schedule_end_time}`);
    setMissedSession(localStorage.getItem('missedSession'));
  };

  return showButton ? (
    <Button variant="small primary" onClick={handleOnClick}>{t("reportMissedCall")}</Button>
  ) : null;
};

export default MissedSessionButton;
