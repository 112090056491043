import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { DefaultLayout } from '../../Layout';
import { Avatar, Button, Heading, Tooltip } from '../../Components/Atoms';
import CalendarComponent from '../../Components/Atoms/Calendar/Calendar';
import { getPublicTherapistProfile } from '../../Actions/therapist.actions';
import { getActiveChatRooms, getScheduleList } from '../../Actions/chatroom.actions';
import { getPatientCalendarEvents } from '../../Util/calendar';
import { getToLocalSchedulesChatroom } from '../../Selectors/schedules.selector';
import { useTranslation } from 'react-i18next';
import darkGreen from '../../Assets/darkGreenSquare.svg';
import lightGreen from '../../Assets/lightGreenSquare.svg';
import OnlineIcon from '../../Components/Molecules/Chatroom/OnlineIcon';

const TherapistProfile = ({ chatRoom, therapistProfile, scheduleList, chatRoomID }) => {
  const [availability, setAvailability] = useState([]);
  const [booked, setBooked] = useState([]);
  const [data, setData] = useState([availability, booked]);
  const patientId = useSelector((store) => store.user?.profile?.id);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const therapistId = useMemo(() => {
    if (chatRoom.rooms && chatRoom?.rooms?.length) {
      return chatRoom.rooms[0].therapist_id;
    } else {
      dispatch(getActiveChatRooms()).then((response) => {
        if (response.length === 0) history.push('/questionnaire');
      });
    }

    return undefined;
  }, [chatRoom, dispatch, history]);

  useEffect(() => {
    if (therapistId) dispatch(getPublicTherapistProfile(therapistId));
  }, [therapistId, dispatch]);

  useEffect(() => {
    dispatch(getScheduleList(chatRoomID));
  }, [dispatch, chatRoomID]);

  useEffect(() => {
    if (scheduleList && scheduleList.length) {
      const [, bookedEvents] = getPatientCalendarEvents(scheduleList, 'booked', patientId);
      if (bookedEvents.length) setBooked(bookedEvents);
    }
  }, [scheduleList]);

  useEffect(() => {
    if (scheduleList && scheduleList.length) {
      const [availability] = getPatientCalendarEvents(scheduleList);
      if (availability.length) setAvailability(availability);
    }
  }, [scheduleList]);

  useEffect(() => {
    setData([...availability, ...booked]);
  }, [availability, booked]);

  const resourceMap = [
    { resourceId: 1, resourceTitle: 'Board room' },
    { resourceId: 2, resourceTitle: 'Training room' },
    { resourceId: 3, resourceTitle: 'Meeting room 1' },
    { resourceId: 4, resourceTitle: 'Meeting room 2' },
  ];

  return (
    <DefaultLayout>
      <StyledHeading text={t('therapistProfile')} />

      <ProfileWrapper>
        <Tooltip
          id="therapistOnline"
          text={therapistProfile?.is_online ? t('therapistIsOnline') : t('therapistIsOffline')}
          place="top"
        >
          <TherapistAvatar>
            <Avatar
              width={200}
              borderColor={therapistProfile?.is_online ? '#2dae95' : '#aeadad'}
              borderWidth="5px"
              src={therapistProfile?.profile_image}
            />
          </TherapistAvatar>
        </Tooltip>
        <TherapistName>
          <Heading level={2} text={therapistProfile?.full_name} />
        </TherapistName>
        <TherapistActions>
          <Button as={Link} to="/chatroom" variant="secondary">
            {t('beginChat')}
          </Button>
          {/* <Button>Leave Review</Button> */}
        </TherapistActions>
        <TherapistDetails>
          {therapistProfile?.exp_summary && (
            <ProfileSection>
              <Heading level={3} text={t('experienceSummary')} />
              <p>{therapistProfile.exp_summary}</p>
            </ProfileSection>
          )}

          {therapistProfile?.about_me && (
            <ProfileSection>
              <Heading level={3} text={t('aboutMe')} />
              {therapistProfile?.about_me.split('\n').map((item, i) => {
                return <p key={i}>{item}</p>;
              })}
            </ProfileSection>
          )}

          {/*Availability*/}
          <ProfileSection>
            <Wrapper>
              <Heading level={3} text={t('availability')} />
              <Legend>
                <Box>
                  <img src={lightGreen} alt="lightGreen" /> <div>{t('availableAppointment')}</div>
                </Box>
                <Box>
                  <img src={darkGreen} alt="darkGreen" />
                  <div>{t('bookedAppointment')}</div>
                </Box>
              </Legend>
            </Wrapper>
            <CalendarComponent
              events={data}
              type="availability"
              selectable={false}
              style={{ height: '700px' }}
              defaultView="week"
              views={false}
              showMultiDayTimes={true}
            />
          </ProfileSection>

          {/*Credentials*/}
          {/*<ProfileSection>*/}
          {/*  <Heading level={3} text="Credentials" />*/}
          {/*  <p>No Credentials</p>*/}
          {/*</ProfileSection>*/}

          {/*Therapy Specialisms*/}
          {therapistProfile?.specialisms?.length && (
            <ProfileSection>
              <Heading level={3} text={t('therapySpecialisms')} />
              <ListWrapper>
                {therapistProfile?.specialisms.map((item) => (
                  <ListItem key={item.id}>{item.specialism_name}</ListItem>
                ))}
              </ListWrapper>
            </ProfileSection>
          )}

          {therapistProfile?.tools && (
            <ProfileSection>
              <Heading level={3} text={t('therapyTools')} />
              <ListWrapper>
                {therapistProfile.tools.map((item) => (
                  <ListItem key={item.id}>{item.tool_name}</ListItem>
                ))}
              </ListWrapper>
            </ProfileSection>
          )}
        </TherapistDetails>
      </ProfileWrapper>
    </DefaultLayout>
  );
};

/* --------------------------------- Styles --------------------------------- */

const StyledHeading = styled(Heading)`
  text-align: center;

  ${({ theme }) => theme.md`
    text-align: left;
    margin-left: calc(30% + ${({ theme }) => theme.spacing});
  `}
`;

const ProfileWrapper = styled.section`
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: "avatar name" "avatar details" "actions details";
    grid-gap: ${({ theme }) => theme.spacing};
  `}
`;

const TherapistAvatar = styled.div`
  grid-area: avatar;
  text-align: center;
  position: relative;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  color: ${({ theme }) => theme.primary};
  font-weight: bold;
  padding: 5px;
  border: 1px solid #80808057;
  gap: 5px;
  border-radius: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

const Box = styled.div`
  display: flex;
  gap: 8px;
`;

const TherapistName = styled.div`
  grid-area: name;
  text-align: center;

  ${({ theme }) => theme.md`
    text-align: left;
  `}
`;

const TherapistActions = styled.div`
  grid-area: actions;
  text-align: center;
`;

const TherapistDetails = styled.div`
  grid-area: details;
`;
const ProfileSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingMd};
`;
const ListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
const ListItem = styled.li`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacingSm};
  margin-bottom: ${({ theme }) => theme.spacingSm};
  background: ${({ theme }) => theme.primaryLight};
  border-radius: 5em;
  padding: 4px ${({ theme }) => theme.spacing};
`;

const mapStateToProps = (store) => ({
  chatRoom: store.chatroom,
  therapistProfile: store.user.therapistProfile,
  scheduleList: getToLocalSchedulesChatroom(store),
  chatRoomID: store.chatroom.chatRoomID,
});

export default connect(mapStateToProps)(TherapistProfile);
