import React from 'react';
import styled from 'styled-components';
const EmphasizedText = (props) => {
    return (
        <StyledSpan>{props.text}</StyledSpan>
    )
}
const StyledSpan = styled.span`
    color: #33c8a0;
    font-weight: bold;
    margin-bottom: 20px;
`;
export default EmphasizedText;