import React from 'react';
import styled from 'styled-components';

const Modal = (props) => {
  return <Wrapper>{props.children}</Wrapper>;
};
const Wrapper = styled.div`
  background-color: white;
  padding: 30px 30px 100px 30px;
  border-radius: 6px;
  position: relative;
  max-width: 400px;
`;
export default Modal;
