import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';

import { localTimezones } from '../../Constants/other';
import { cancelSubscription } from '../../Actions/patient.actions';
import api from '../../Util/api';

import { Button, Heading, Loading, Widget } from '../../Components/Atoms';
import { PaymentPlans, VideoPlan } from '../../Components/Organisms';
import { isPartner } from '../../Actions/auth.actions';
import { useTranslation } from 'react-i18next';

const ManageSubscription = () => {
  const subscription = useSelector((store) => store.user.subscription);
  const patientProfile = useSelector((store) => store.user?.profile);
  const paymentType = useSelector((store) => store.user?.profile?.payment_type);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [plans, setPlans] = useState([]);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [isPartnerState, setIsPartnerState] = useState(false);
  const [isLocal] = useState(localTimezones.includes(Intl.DateTimeFormat().resolvedOptions().timeZone));
  const [isLoading, setIsLoading] = useState(false);

  if (patientProfile && patientProfile['B2B'] === 1) history.push('/chatroom');

  const isSubscriptionCancelable = useMemo(() => {
    if (!subscription) return true;
    const allPlans = plans?.map((plan) => plan.package_plans.map((packagePlan) => packagePlan.plan_id))?.flat();
    return allPlans?.includes(subscription.plan_id);
  }, [subscription, plans]);

  const cancel = (close) => {
    setIsLoading(true);
    dispatch(cancelSubscription()).then(() => {
      if (close) {
        close();
        setIsLoading(false);
      }
    });
  };

  const getPackages = useCallback(
    (currency, counter = 0) => {
      const host = window.location.host;
      dispatch(isPartner(host)).then((res) => {
        const partner = res.data?.data?.is_partner;
        setIsPartnerState(!!partner);
        return api.payment
          .getPlans(currency, partner ? host : undefined)
          .then((res) => {
            if (!res.data?.data?.packages.length && counter !== 1) {
              setTimeout(() => {
                getPackages(currency === 'GBP' ? 'USD' : 'GBP', 1);
              }, 1000);
              return false;
            }
            setPlans(res.data?.data?.packages || []);
            setLoadingPlans(false);
            // setActiveCurrency(currency);
          })
          .catch((err) => console.error(err));
      });
    },
    [dispatch],
  );

  useEffect(() => {
    api.others
      .getLocation()
      .then((res) => {
        if (res.status === 'success') {
          const local = res.country === 'United Kingdom' || localTimezones.includes(res.timezone);
          return getPackages(local ? 'GBP' : 'USD');
        }
      })
      .catch(() => {
        if (isLocal) {
          getPackages('GBP');
        } else getPackages('USD');
      });
  }, [getPackages, isLocal]);

  return (
    <>
      {loadingPlans ? <Loading /> : <PaymentPlans plans={plans} isPartnerState={isPartnerState} />}
      <div>
        <div style={{ textAlign: 'left', marginBottom: 20 }}>
          <Heading>{t("budgetPlan")}</Heading>
          <Heading>{t("stopCopingStartLiving")}</Heading>
        </div>
      </div>
      <OtherInfoWrapper>
        {subscription && subscription.is_paid && <VideoPlan />}
        {subscription && subscription.is_paid && (
          <ActivePlan>
            <Heading level={2}>{t("currentPlan")}</Heading>
            <Widget>
              {subscription && !Array.isArray(subscription) ? (
                <>
                  <Heading level={4}>{subscription.plan_name}</Heading>
                  <ActivePlanInfo>
                    <p>
                      <b>{t("videosRemaining")}</b> {subscription?.remaining_video_calls}
                    </p>
                    <p>
                      <b>{t("expiresOn")}</b> {subscription?.expiry_date}
                    </p>
                    <p>{t("chooseAnotherSubs")}</p>
                  </ActivePlanInfo>
                  {paymentType !== 'voucher' && (
                    <CancelWrapper>
                      {isSubscriptionCancelable ? (
                        !subscription.cancelled ? (
                          <Button
                            variant={'danger ghost'}
                            onClick={() => cancel()}
                            loading={isLoading}
                            disbled={isLoading}
                          >
                            {t("cancelSubscription")}
                          </Button>
                        ) : (
                          <div style={{ background: 'red', padding: 5, borderRadius: 5, color: 'white' }}>
                          {t("yourSubscriptionIsCanceled")}
                          </div>
                        )
                      ) : (
                        <StyledPopup
                          disabled={subscription.cancelled}
                          trigger={
                            subscription.cancelled ? (
                              <div style={{ background: 'red', padding: 5, borderRadius: 5, color: 'white' }}>
                                {t("yourSubscriptionIsCanceled")}
                              </div>
                            ) : (
                              <Button variant={'danger ghost'} loading={isLoading} disabled={isLoading}>
                                {t("cancelSubscription")}
                              </Button>
                            )
                          }
                          modal
                        >
                          {(close) => (
                            <>
                              <Heading level={3}>{t("cancelSubscription")}</Heading>
                              <p>{t("subscriptionCancelPara1")}</p>
                              <p>{t("subscriptionCancelPara2")}</p>
                              <p>{t("subscriptionCancelPara3")}</p>
                              <ModalActions>
                                <Button onClick={close}>{t("hide")}</Button>
                                <Button
                                  onClick={() => cancel(close)}
                                  loading={isLoading}
                                  disabled={isLoading}
                                  variant={'danger ghost'}
                                >
                                  {t("cancelSubscription")}
                                </Button>
                              </ModalActions>
                            </>
                          )}
                        </StyledPopup>
                      )}
                    </CancelWrapper>
                  )}
                </>
              ) : (
                <Heading level={4}>{t("noActivePlan")}</Heading>
              )}
            </Widget>
          </ActivePlan>
        )}
      </OtherInfoWrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;

  ${({ theme }) => theme.md`
    grid-template-columns: 1fr 1fr;
    column-gap: ${theme.spacingLg};
  `};
`;
Wrapper.displayName = 'Wrapper';

const ActivePlan = styled.div`
  grid-row: 1;
  width: 100%;

  ${({ theme }) => theme.lg`
    width: 47%
  `};

  ${({ theme }) => theme.md`
    grid-row: auto;
  `};
`;
ActivePlan.displayName = 'ActivePlan';

const ActivePlanInfo = styled.div`
  padding: ${({ theme }) => theme.spacing} ${({ theme }) => theme.spacing} 0;
`;
ActivePlanInfo.displayName = 'ActivePlanInfo';

const CancelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
CancelWrapper.displayName = 'CancelWrapper';

const BoldSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontBold};
`;
BoldSpan.displayName = 'BoldSpan';

const OtherInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: auto !important;
    margin: auto ${({ theme }) => theme.spacing} !important;

    ${({ theme }) => theme.md`
      width: 50% !important;
      margin: auto !important;
    `};
  }
`;

const ModalActions = styled.div`
  margin-top: ${({ theme }) => theme.spacing};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  gap: ${({ theme }) => theme.spacingSm};
  justify-content: center;
  text-align: center;

  ${({ theme }) => theme.md`
    justify-content: flex-end;
  `};
`;
ModalActions.displayName = 'ModalActions';

// const SwitchCurrencyWrapper = styled.div`
//   display: flex;
//
//   div {
//     border: 1px solid gray;
//     padding: 3px 8px;
//     border-radius: 3px;
//
//     &:first-child {
//       margin-right: 5px;
//       ${({ currency }) =>
//         currency === 'GBP' &&
//         css`
//           background: #eaeaea;
//           font-weight: 600;
//         `}
//     }
//     &:nth-child(2) {
//       ${({ currency }) =>
//         currency === 'USD' &&
//         css`
//           background: #eaeaea;
//           font-weight: 600;
//         `}
//     }
//
//     cursor: pointer;
//   }
// `;

export default ManageSubscription;
