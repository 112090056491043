import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Faq from '../../Chatroom/Components/Faq';
import Tooltip from '../../../Components/Atoms/Tooltip/Tooltip';
import OrganisationSidebar from '../../../Config/OrganisationSidebar';
// import { Button } from 'react-bootstrap';
const Sidebar = ({ links }) => {
  const [subMenuState, setSubMenuState] = useState({});
  const location = useLocation();

  const profile = useSelector((store) => store?.user?.profile);
  const isApproved = useMemo(() => profile?.profile_status === 'Approved', [profile]);

  const toggleSubmenu = (title) => {
    setSubMenuState((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  const checkSubRouteActive = (link) => {
    return !!link.children.find(({ url }) => url === location.pathname);
  };

  useEffect(() => {
    setSubMenuState((prevState) => {
      return links.reduce((subMenuState, { title, children }) => {
        if (children) {
          subMenuState[title] = !!children.find(({ url }) => url === location.pathname) || prevState[title];
        }
        return subMenuState;
      }, {});
    });
  }, [location.pathname, links]);

  return (
    <DashSidebar style={{ flexDirection: 'row' }}>
      {profile?.company_access && profile?.company_access?.length > 1
        ? <OrganisationSidebar />
        : null
      }
      <div style={{ width: '80%', float: 'inline-end' }}>
        <DashNav>
          {links.map((item) => {
            if (item.id === 5 && profile && !isApproved) return null;
            return (
              <Tooltip id={`link-${item.id}`} text={item.description} place="right">
                <DashItem key={item.id}>
                  {item.children ? (
                    <>
                      <DashToggle
                        className={`${subMenuState[item.title] && 'opened'} ${checkSubRouteActive(item) && 'active'}`}
                        onClick={() => toggleSubmenu(item.title)}
                      >
                        {item.title}
                      </DashToggle>
                      {subMenuState[item.title] && (
                        <DashSubNav>
                          {item.children.map((child) => (
                            <DashSubItem key={child.url}>
                              <DashSubLink exact={child.exact} to={child.url}>
                                {child.title}
                              </DashSubLink>
                            </DashSubItem>
                          ))}
                        </DashSubNav>
                      )}
                    </>
                  ) : (
                    <DashLink exact={item.exact} to={item.url}>
                      {item.title}
                    </DashLink>
                  )}
                </DashItem>
              </Tooltip>
            );
          })}
          <FaqSidebar>
            <Faq />
          </FaqSidebar>
        </DashNav>
      </div>
    </DashSidebar>
  );
};

const FaqSidebar = styled.div`
  button {
    outline: none;
  }
  #faq-button {
    text-decoration: none;
    text-align: left;
    display: block;
    font-size: 16px;
    color: #dffff9;
    font-weight: 500;
    padding: 16px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
  }
`;

const StyledLink = styled(NavLink)`
  display: block;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryLight};
  font-weight: ${({ theme }) => theme.fontMedium};
  text-decoration: none;
`;
StyledLink.displayName = 'StyledLink';

const DashSidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.primary};
`;

const DashNav = styled.ul`
  width: 100%;
  padding: ${({ theme }) => theme.spacing};
`;

const DashItem = styled.li`
  display: block;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing};
`;

const DashLink = styled(StyledLink)`
  padding: ${({ theme }) => theme.spacing};
  border-radius: 8px;

  &.active {
    background-color: #96fbe7;
    color: ${({ theme }) => theme.primary};
  }
`;

const DashToggle = styled.div`
  padding: ${({ theme }) => theme.spacing};
  border-radius: 8px;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryLight};
  font-weight: ${({ theme }) => theme.fontMedium};
  cursor: pointer;
  position: relative;

  &.opened {
    &::after {
      right: 15px;
      transform: rotate(-135deg) translate(45%);
    }
  }

  &.active {
    background-color: #96fbe7;
    color: ${({ theme }) => theme.primary};

    &::after {
      border-color: ${({ theme }) => theme.primary};
    }
  }

  &::after {
    width: 12px;
    height: 12px;
    right: 10px;
    top: 50%;
    display: block;
    content: '';
    position: absolute;
    border-style: solid;
    border-color: ${({ theme }) => theme.primaryLight};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-100%);
  }
`;
DashToggle.displayName = 'DashToggle';

const DashSubNav = styled.ul`
  padding: ${({ theme }) => theme.spacingMd} 0 0 ${({ theme }) => theme.spacingLg};
`;
DashSubNav.displayName = 'DashSubNav';

const DashSubItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing};
`;
DashSubItem.displayName = 'DashSubItem';

const DashSubLink = styled(StyledLink)`
  &.active {
    color: ${({ theme }) => theme.primaryMedium};
  }
`;
DashSubLink.displayName = 'DashSubLink';

export default Sidebar;
