import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../../Components/Atoms';

const SubscriptionBadge = ({ subscription, rooms }) => {
  const { t } = useTranslation();
  const isB2b = useSelector((store) => store.user?.profile?.['B2B']);

  if (!subscription) return null;

  if (rooms?.length && rooms[0].chatroom_status !== 'approved') {
    return (
      <SubscriptionWrapper>
        <h6>{t("therapistJoiningSoon")}</h6>
      </SubscriptionWrapper>
    );
  }

  if (subscription && subscription.remaining_video_calls === 0 && !isB2b) {
    return (
      <SubscriptionWrapper>
        <Heading level={6}>{t("noVideoSessionsAvailable")}</Heading>
        {window.location.host !== 'gympass.supportroom.com' && (
          <StyledLink to="/account/subscription/manage">{t("manageSubscription")}</StyledLink>
        )}
      </SubscriptionWrapper>
    );
  }

  return null;
};

const SubscriptionWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryLight};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing};
  margin-bottom: ${({ theme }) => theme.spacing};
  &.warning {
    color: ${({ theme }) => theme.statusWarning};
    border-color: ${({ theme }) => theme.statusWarning};
    background: ${({ theme }) => theme.statusWarningLight};
  }
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontMedium};
`;

export default SubscriptionBadge;
