import React from 'react';
import { Button, Heading } from '../../Components/Atoms';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthLayout } from "../../Layout";
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <AuthLayout>
      <ConfirmationWrapper>
        <Heading align="center" text={t("paymentConfirmation")} />
        <p>{t("somethingWentWrong")}</p>
        <p>{t("paymentNotConfirmed")}</p>
        <Button as={Link} to="/checkout" className="secondary">
        {t("goBack")}
        </Button>
      </ConfirmationWrapper>
    </AuthLayout>
  );
};

const ConfirmationWrapper = styled.div`
  text-align: center;
`;

export default ErrorPage;
