import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Heading, Widget } from '../../../Components/Atoms';
import { getStats } from '../../../Actions/chatroom.actions';
import Tooltip from '../../../Components/Atoms/Tooltip/Tooltip';

const StatsGrid = () => {
  const [stats, setStats] = useState({});
  const chatRooms = useSelector((store) => store.user.chatRooms);
  const dispatch = useDispatch();
  const formattedResponseTime = useMemo(() => {
    const duration = moment.duration(stats.avg_response_time, stats.response_time_unit);
    const [hours, minutes, seconds] = [duration.hours(), duration.minutes(), duration.seconds()];
    return `${hours > 0 ? `${hours}h ` : ''}${hours > 0 || minutes > 0 ? `${minutes}m ` : ''}${seconds}s`;
  }, [stats.avg_response_time, stats.response_time_unit]);

  const getTherapistStats = useCallback(async () => {
    try {
      setStats((await dispatch(getStats()))?.data || {});
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  useEffect(() => {
    getTherapistStats();
  }, [getTherapistStats]);

  return (
    <Grid>
      <Tooltip id="Sessions" text="View the numbers of sessions completed" place="top">
        <Widget align="center">
          <Heading level={5}>Video Sessions Completed</Heading>
          <Value>{stats.completed_sessions || '--'}</Value>
        </Widget>
      </Tooltip>
      <Tooltip id="Journeys" text="The number of completed patient journeys" place="top">
        <Widget align="center">
          <Heading level={5}>Journeys Completed</Heading>
          <Value>{stats.completed_journeys || '--'}</Value>
        </Widget>
      </Tooltip>
      <Tooltip id="Missed" text="Number of patient missed video sessions" place="top">
        <Widget align="center">
          <Heading level={5}>Missed Sessions</Heading>
          <Value>{stats.missed_sessions}</Value>
        </Widget>
      </Tooltip>
      <Tooltip id="Total" text="Total number of words sent in your patients messages" place="top">
        <Widget align="center">
          <Heading level={5}>Total Words Sent</Heading>
          <Value>{stats.total_words ? stats.total_words : '--'}</Value>
        </Widget>
      </Tooltip>
      <Tooltip id="Active" text="Total number of active patients you are supporting" place="top">
        <Widget align="center">
          <Heading level={5}>Number of Active Patients</Heading>
          <Value>{stats?.total_active_patients || 0}</Value>
        </Widget>
      </Tooltip>
      <Tooltip id="Number" text="Total number of inactive clients you are supporting" place="top">
        <Widget align="center">
          <Heading level={5}>Number of Inactive Patients</Heading>
          <Value>{stats?.total_inactive_patients || 0}</Value>
        </Widget>
      </Tooltip>
      <Tooltip id="MessagesSent" text="Total number of messages sent to your clients" place="top">
        <Widget align="center">
          <Heading level={5}>Total Messages Sent</Heading>
          <Value>{stats.total_messages_sent ? stats.total_messages_sent : '--'}</Value>
        </Widget>
      </Tooltip>
      <Tooltip id="AverageMessages" text="Average number of messages sent to your clients per day" place="top">
        <Widget align="center">
          <Heading level={5}>Average Messages Sent (per day)</Heading>
          <Value>{stats.average_sent_per_day ? (+stats.average_sent_per_day).toFixed(0) : '--'}</Value>
        </Widget>
      </Tooltip>
      <Tooltip id="AverageResponseTime" text="Average response time to your clients per day" place="top">
        <Widget align="center">
          <Heading level={5}>Average Response Time</Heading>
          <Value>{stats.avg_response_time ? formattedResponseTime : '--'}</Value>
        </Widget>
      </Tooltip>
    </Grid>
  );
};

const Grid = styled.div`
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: ${({ theme }) => theme.spacingMd};
  `}
  ${({ theme }) => theme.lg`
    grid-template-columns: repeat(4, minmax(0, 1fr));
  `}
`;

const Value = styled.div`
  font-size: 35px;
  font-weight: ${({ theme }) => theme.fontBold};
  margin: ${({ theme }) => theme.spacing};
  word-break: break-all;
`;
export default StatsGrid;
