import React from 'react';
import { NotificationsPage } from '../../Components/Organisms';
import { DefaultLayout } from '../../Layout';

const Notifications = () => {
  return (
    <DefaultLayout>
      <NotificationsPage role={'patient'} />
    </DefaultLayout>
  );
};

export default Notifications;
