import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
  getProfessionalType,
  getProfessionalTypes,
  getProfile,
  updateProfile,
} from '../../../Actions/therapist.actions';

import { Alert, Button, FormGroup, Label, Select } from '../../../Components/Atoms';

const ProfessionalType = () => {
  const [professionType, setProfessionType] = useState([]);
  const [initialProfession, setInitialProfession] = useState([]);

  const [responseMessage, setResponseMessage] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleOnSubmit = async ({ professional_type }, { setSubmitting }) => {
    setLoading(true);
    try {
      const { message } = await dispatch(
        updateProfile({
          profession_type_ids: professional_type.map((item) => item.id)
        })
      )
      setResponseMessage({ type: 'primary', message });
    } catch (err) {
      console.error(err);
      setResponseMessage({ type: 'danger', message: 'Something went wrong. Try again later.' });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const getTherapistData = useCallback(async () => {
    let speciality = (await dispatch(getProfessionalTypes()))?.data;
    setProfessionType(speciality.map((item) => item.sub_type)?.flat());
  }, [dispatch]);

  useEffect(() => {
    getTherapistData();
  }, [getTherapistData]);

  useEffect(() => {
    async function fetchData() {
      try {
        const profileData = await dispatch(getProfile())
        setInitialProfession(profileData?.data?.professions);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [getTherapistData]);

  const validationSchema = Yup.object().shape({
    professional_type: Yup.array().min(1, 'Select at least 1 speciality'),
  });

  return (
    <Formik
      initialValues={{
        professional_type: initialProfession,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <StyledFormGroup>
            <Label htmlFor="professional_type">Choose Speciality</Label>
            <Field
              as={Select}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={professionType}
              id="professional_type"
              name="professional_type"
              placeholder=""
              isMulti
              onChange={(data) => {
                setFieldValue('professional_type', data || []);
              }}
            />
            <ErrorMessage name="professional_type" component="div" className="invalid-feedback" />
          </StyledFormGroup>

          {responseMessage.type && (
            <StyledAlert className={responseMessage.type}>{responseMessage.message}</StyledAlert>
          )}

          <Button type="submit" loading={loading} disabled={loading}>
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: ${({ theme }) => theme.spacing};
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};
`;
StyledFormGroup.displayName = 'StyledFormGroup';

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing};
`;
StyledAlert.displayName = 'StyledAlert';

export default ProfessionalType;
