import React from 'react';
import Loading from '../Components/Atoms/Loading/Loading';
import { useHistory } from 'react-router';

const Redirect = (props) => {
  const history = useHistory();
  setTimeout(() => {
    history.push(props.url);
    // window.location = props.url;
  }, 500);
  return <Loading />;
};

export default Redirect;
