import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AuthLayout } from '../../Layout';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../../Util/api';

import { Alert, Button, Heading } from '../../Components/Atoms';

const SignupConfirm = () => {
  const [companyInfo, setCompanyInfo] = useState(undefined);
  const { t } = useTranslation();
  const getCompany = async () => {
    try {
      setCompanyInfo((await api.company.getProfile()).data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <AuthLayout>
      <Wrapper>
        {companyInfo ? (
          <>
            <Heading>{t("Welcome")} {companyInfo.company_name}</Heading>
            <p>{t("viewCompanyDashboard")}</p>
            <Button as={Link} to="/c/dashboard">
            {t("viewMyDashboard")}
            </Button>
          </>
        ) : (
          <>
            <Heading>{t("welcomeToSupportRoom")}</Heading>
            <Alert className="info">{t("checkEmail")}</Alert>
            <br />
            <Button as={Link} to="/c/login" className="secondary">
            {t("login")}
            </Button>
          </>
        )}
        {/*<Heading level={3}>Hello {userInfo.first_name}</Heading>*/}
        {/*/!* {userInfo.profile_status === 'Approved' ? ( *!/*/}
        {/*<>*/}
        {/*  <p>You can now view your company Dashboard</p>*/}
        {/*  <br />*/}
        {/*  <br />*/}
        {/*</>*/}
        {/*/!* ) : (*/}
        {/*  <>*/}
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
`;

export default SignupConfirm;
