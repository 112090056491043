import moment from 'moment';

export const addLocalDateTime = (schedule) => {
  return {
    ...schedule,
    slots: schedule.slots.map((slot) => {
      let stillUtc = moment.utc(`${schedule.schedule_date} ${slot.schedule_time}`).toDate();
      let local = moment(stillUtc).local();
      return {
        ...slot,
        local_schedule_date_time: local,
      };
    }),
  };
};

export const simplifySchedule = (currSchedule) => {
  // converted Date to local date but time still stays as UTC time
  // Ideal way would have been converting time to local time as well
  // that would take a lot of time to implement as well as testing
  // hence using this hack
  // if you are working on this & have got some time, do it the ideal way :)

  const dates = {};
  const updatedSchedule = currSchedule?.map(addLocalDateTime);
  updatedSchedule?.forEach(({ slots }) => {
    slots?.map((slot) => {
      let date = moment(slot.local_schedule_date_time).format('YYYY-MM-DD');
      if (dates[date]) {
        dates[date].push(slot);
      } else {
        dates[date] = [slot];
      }
    });
  });

  let formattedSchedule = [];
  for (const [key, value] of Object.entries(dates)) {
    formattedSchedule.push({ schedule_date: key, slots: value });
  }

  return formattedSchedule;
};

// Tech Debt : to update all schedule logic to have common functions

export const getSchedules = (schedules) => {
  const newSchedules = [];
  const formatData = 'YYYY-MM-DD';
  const fullFormat = 'YYYY-MM-DD HH:mm:ss';

  if (schedules?.length) {
    schedules.forEach((schedule) => {
      newSchedules.push({
        schedule_date: moment.utc(schedule.schedule_date, formatData).local().format(formatData),
        slots: schedule.slots.map((slot) => ({
          ...slot,
          schedule_time: moment
            .utc(
              `${schedule.schedule_date} ${slot.schedule_time === '00:00:00' ? '00:00:01' : slot.schedule_time}`,
              fullFormat,
            )
            .local()
            .format(fullFormat),
          schedule_end_time: moment
            .utc(
              `${schedule.schedule_date} ${
                slot.schedule_end_time === '00:00:00' ? '23:59:59' : slot.schedule_end_time
              }`,
              fullFormat,
            )
            .local()
            .format(fullFormat),
        })),
      });
    });
  }

  return newSchedules;
};
