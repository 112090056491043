import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StarRating } from '../../../Components/Molecules';
import api from '../../../Util/api';
import Tooltip from '../../../Components/Atoms/Tooltip/Tooltip';

const SatisfactionScore = () => {
  const [feedbackScore, setFeedbackScore] = useState(undefined);

  const getUser = () => {
    api.therapist
      .getProfile()
      .then((response) => response.data)
      .then((user) => {
        setFeedbackScore(parseFloat(user.data?.feedbacks_average || 0).toFixed(2));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <Tooltip id="AllJournalEntries" text="Patients have rated you based on their  therapy sessions" place="right">
        <h2>Satisfaction Score</h2>
      </Tooltip>
      <Rating>{feedbackScore}</Rating>
      <StarRating rating={feedbackScore} total={5} />
    </>
  );
};

const Rating = styled.div`
  text-align: center;
  font-size: 52px;
  font-weight: ${({ theme }) => theme.fontBold};
  margin: ${({ theme }) => theme.spacing};
`;
export default SatisfactionScore;
