import { SET_LANGUAGE } from '../Constants/actionTypes';

const initialState = {
  selectedLanguage: '',
};

export default function language(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      console.log('SET_LANGUAGE', action.payload);
      return {
        selectedLanguage: action.payload,
      };
    default:
      return state;
  }
}
