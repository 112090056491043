import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import YouTube from 'react-youtube';

import api from '../../../Util/api';
import { Heading } from '../../../Components/Atoms';

const YOUTUBE_API_KEY = 'AIzaSyBszFC4PJ2syC69RLx2sbSPFIsvm4m9TrE';
const LIST_ID = 'PLle_2bhIoagg9XR60itka_SQNgZiaPDpI';
const MAX_RESULT = 20;

const Videos = (props) => {
  const [playlist, setPlaylist] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  const videoViewportWidth = useMemo(() => playlist.length * 170 + 'px', [playlist]);

  useEffect(() => {
    api.youtube.getPlaylist(YOUTUBE_API_KEY, MAX_RESULT, LIST_ID).then((list) => {
      if (list.items.length) setPlaylist(list.items);
    });
  }, []);

  const onVideoSelect = useCallback((videoId) => setSelectedVideoId(videoId), []);
  const closeVideo = useCallback(() => setSelectedVideoId(null), []);

  return (
    <>
      <Heading level={3}>{props.heading}:</Heading>
      {selectedVideoId && (
        <YoutubeWrapper>
          <CloseButton onClick={closeVideo}>&times;</CloseButton>
          <YouTube videoId={selectedVideoId} />
        </YoutubeWrapper>
      )}
      <VideosWrapper>
        <VideosViewport style={{ width: videoViewportWidth }}>
          {playlist.map((video) => (
            <Thumbnails
              key={video.id}
              src={video?.snippet?.thumbnails?.medium.url}
              onClick={() => onVideoSelect(video?.snippet?.resourceId?.videoId)}
            />
          ))}
        </VideosViewport>
      </VideosWrapper>
    </>
  );
};

const Thumbnails = styled.img`
  width: 160px;
  background: grey;
  margin-right: 10px;
  color: white;

  cursor: pointer;
`;

const VideosWrapper = styled.div`
  overflow-x: scroll;
  padding-bottom: 20px;
  max-width: 300px;
`;
const VideosViewport = styled.div`
  overflow: hidden;
`;

const YoutubeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  background: rgb(60 60 60 / 93%);
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background: white;
  border: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    background: #e7e7e7;
  }

  cursor: pointer;
`;

export default Videos;
