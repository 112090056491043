import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Heading } from '../../../Components/Atoms';
import { TimeSeriesBarChart } from '../../../Components/Molecules';
import { useSelector } from 'react-redux';

import Tooltip from '../../../Components/Atoms/Tooltip/Tooltip';
import { ReactComponent as InfoIcon } from '../../../Assets/Icons/info.svg';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

const MetricChart = ({ heading, timePeriod, chartData, index }) => {
  const profile = useSelector((store) => store.user.profile);
  const moodBox = useSelector((store) => store.user?.moodBox) || [];
  const { t } = useTranslation();
  const moodBoxQuestions = moodBox?.find((q) => q.question === heading) || {};
  const quesions = get(moodBoxQuestions, 'answers', []);

  return (
    <div>
      <Heading level={4} align={'center'} style={{ height: 25 }}>
        {heading}{' '}
        {index === 0 && profile?.['B2B'] ? (
          <Tooltip
            id="notificationIcon1"
            text={
              <div>
                <span>{t("metricChartText1")}</span>
                <br />
                <span>{t("metricChartText2")}</span>
              </div>
            }
            place="top"
            offset={{ bottom: 10 }}
          >
            <InfoIcon />
          </Tooltip>
        ) : null}
      </Heading>
      <ChartWrapper style={(profile && profile['B2B']) || !quesions?.length ? { padding: 24 } : {}}>
        {profile && profile['B2B'] ? (
          <ArrowWrapper>
            <Arrow />
            <span>{t("positive")}</span>
          </ArrowWrapper>
        ) : null}
        <div style={{ position: 'relative' }}>
          {profile && profile['B2B'] ? null : (
            <Labels>
              {quesions?.map((val, i) => (
                <LabelYAxis title={val.title}>{val.title}</LabelYAxis>
              ))}
              <LabelYAxis style={{ height: 19 }}></LabelYAxis>
            </Labels>
          )}
          <TimeSeriesBarChart
            timePeriod={timePeriod}
            data={chartData}
            label={'Value'}
            maxValue={5}
            stepSize={1}
            profile={profile}
          />
        </div>
      </ChartWrapper>
    </div>
  );
};

const LabelYAxis = styled.div`
  white-space: nowrap;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const Labels = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;
  height: calc(100% - 30px);
  background: white;
  left: -45px;
`;

const ChartWrapper = styled.div`
  position: relative;
  padding: 24px 0 24px 60px;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 0 27px #ebebeb;
`;
ChartWrapper.displayName = 'ChartWrapper';

const ArrowWrapper = styled.div`
  position: absolute;
  width: 1px;
  height: 75%;
  background: #a3a3a4;

  left: 30px;

  span {
    position: absolute;
    bottom: 45%;
    left: -40px;
    transform: rotate(-90deg);
    color: #a3a3a4;
  }
`;

const Arrow = styled.div`
  position: absolute;
  left: -4.5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #a3a3a4;
`;

MetricChart.propTypes = {
  heading: PropTypes.string.isRequired,
  timePeriod: PropTypes.string.isRequired,
  chartData: PropTypes.array.isRequired,
};

export default MetricChart;
