import { combineReducers } from 'redux';

import auth from './auth.reducer';
import errors from './errors.reducer';
import user from './user.reducer';
import chatroom from './chatroom.reducer';
import questionnaire from './questionnaire.reducer';
import isPartner from './isPartner.reducer';
import teams from './teams.reducer';
import language from './language.reducer';

export default combineReducers({ auth, errors, user, chatroom, questionnaire, isPartner, teams, language });
