import React from 'react';
import { AuthLayout } from '../Layout';
import { useTranslation } from 'react-i18next';

const Privacypolicy = () => {
  const { t } = useTranslation();
  return (
    <div style={{ height: '100vh' }}>
      <AuthLayout showLanguageChangeIcon>
        <section id="content" style={{ width: '100%' }}>
          <div id="post-12921" className="post-12921 page type-page status-publish has-post-thumbnail hentry">
            <div className="post-content">
              <h2
                data-fontsize={28}
                data-lineheight="33.6px"
                className="fusion-responsive-typography-calculated"
                style={{ fontsize: 28, lineHeight: '1.2' }}
              >
                {t('privacyPolicyContent')}
              </h2>
              {/* <h4
            dataFontsize={16}
            dataLineheight="21.76px"
            className="fusion-responsive-typography-calculated"
            style={{ fontsize: 16, lineHeight: '1.36', minfontsize: 16 }}
          /> */}
              <p>{t('privacyPolicyContent1')}</p>
              <p>
                {t('privacyPolicyContent2')}
                <br />
                <b>{t('privacyPolicyContent3')}</b>
                <br />
                {t('privacyPolicyContent4')}
                <br />
                <b>{t('privacyPolicyContent5')}</b>
                <br />
                {t('privacyPolicyContent6')}
              </p>
              <ul>
                <li>{t('privacyPolicyContent7')}</li>
                <li>{t('privacyPolicyContent8')}</li>
                <li>{t('privacyPolicyContent9')}</li>
                <li>{t('privacyPolicyContent10')}</li>
                <li>{t('privacyPolicyContent11')}</li>
                <li>{t('privacyPolicyContent12')}</li>
                <li>{t('privacyPolicyContent13')}</li>
                <li>{t('privacyPolicyContent14')}</li>
              </ul>
              <p>
                <b>{t('privacyPolicyContent15')}</b>
                <br />
                {t('privacyPolicyContent16')}
              </p>
              <ul>
                <li>
                  <strong>{t('privacyPolicyContent17')}</strong> {t('privacyPolicyContent18')}
                </li>
                <li>
                  <strong>{t('privacyPolicyContent19')}</strong> {t('privacyPolicyContent20')}
                </li>
                <li>
                  <strong>{t('privacyPolicyContent21')}</strong> {t('privacyPolicyContent22')}
                </li>
                <li>
                  <strong>{t('privacyPolicyContent23')}</strong> {t('privacyPolicyContent24')}
                </li>
                <li>
                  <strong>{t('privacyPolicyContent25')}</strong> {t('privacyPolicyContent26')}
                </li>
                <li>
                  <strong>{t('privacyPolicyContent27')}</strong> {t('privacyPolicyContent28')}
                </li>
                <li>
                  <strong>{t('privacyPolicyContent29')}</strong> {t('privacyPolicyContent30')}
                </li>
                <li>
                  <strong>{t('privacyPolicyContent31')}</strong> {t('privacyPolicyContent32')}
                </li>
              </ul>
              <p>{t('privacyPolicyContent33')}</p>
              <p>{t('privacyPolicyContent34')}</p>
              <p>{t('privacyPolicyContent35')}</p>
              <p>{t('privacyPolicyContent36')}</p>
              <p>
                {t('privacyPolicyContent37')} <b>{t('privacyPolicyContent38')}</b> {t('privacyPolicyContent39')}
                <br />
                <b>{t('privacyPolicyContent40')}</b>
                <br />
                {t('privacyPolicyContent41')}
              </p>
              <ul>
                <li>{t('privacyPolicyContent42')}</li>
                <li>{t('privacyPolicyContent43')}</li>
                <li>{t('privacyPolicyContent44')}</li>
                <li>{t('privacyPolicyContent45')}</li>
                <li>{t('privacyPolicyContent46')}</li>
                <li>{t('privacyPolicyContent47')}</li>
              </ul>
              <p>
                <b>{t('privacyPolicyContent48')}</b>
                <br />
                {t('privacyPolicyContent49')}{' '}
                <a href="https://supportroom.com/cookie-policy/" target="_blank" rel="noreferrer noopener">
                  {t('privacyPolicyContent145')}
                </a>{' '}
                {t('privacyPolicyContent50')}
              </p>
              <p>
                <b>{t('privacyPolicyContent51')}</b>
                <br />
                {t('privacyPolicyContent52')}
                <br />
                {t('privacyPolicyContent53')}
              </p>
              <ul>
                <li>{t('privacyPolicyContent54')}</li>
                <li>{t('privacyPolicyContent55')}</li>
                <li>{t('privacyPolicyContent56')}</li>
                <li>{t('privacyPolicyContent57')}</li>
                <li>{t('privacyPolicyContent58')}</li>
                <li>{t('privacyPolicyContent59')}</li>
              </ul>
              <p>
                <b>{t('privacyPolicyContent60')}</b>
                <br />
                {t('privacyPolicyContent61')}
              </p>
              <ul>
                <li>{t('privacyPolicyContent62')}</li>
                <li>{t('privacyPolicyContent63')}</li>
                <li>{t('privacyPolicyContent64')}</li>
                <li>{t('privacyPolicyContent65')}</li>
              </ul>
              <p>
                <b>{t('privacyPolicyContent66')}</b>
              </p>
              <p>{t('privacyPolicyContent67')}</p>
              <p>{t('privacyPolicyContent68')}</p>
              <table id="eael-data-table-5d96b9f">
                <thead>
                  <tr>
                    <th id colSpan>
                      {t('privacyPolicyContent69')}
                    </th>
                    <th id colSpan>
                      {t('privacyPolicyContent70')}
                    </th>
                    <th id colSpan>
                      {t('privacyPolicyContent71')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent72')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent73')}
                      <br />
                      {t('privacyPolicyContent74')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent75')}
                    </td>
                  </tr>
                  <tr>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent76')}
                      <br />
                      {t('privacyPolicyContent77')}
                      <br />
                      {t('privacyPolicyContent78')}
                      <br />
                      {t('privacyPolicyContent79')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent73')}
                      <br />
                      {t('privacyPolicyContent74')}
                      <br />
                      {t('privacyPolicyContent80')}
                      <br />
                      {t('privacyPolicyContent81')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent82')}
                      <br />
                      {t('privacyPolicyContent83')}
                    </td>
                  </tr>
                  <tr>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent84')}
                      <br />
                      {t('privacyPolicyContent85')}
                      <br />
                      {t('privacyPolicyContent86')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent73')}
                      <br />
                      {t('privacyPolicyContent74')}
                      <br />
                      {t('privacyPolicyContent87')}
                      <br />
                      {t('privacyPolicyContent88')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent89')}
                      <br />
                      {t('privacyPolicyContent90')}
                      <br />
                      {t('privacyPolicyContent91')}
                    </td>
                  </tr>
                  <tr>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent92')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent73')}
                      <br />
                      {t('privacyPolicyContent74')}
                      <br />
                      {t('privacyPolicyContent87')}
                      <br />
                      {t('privacyPolicyContent93')}
                      <br />
                      {t('privacyPolicyContent81')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent89')}
                      <br />
                      {t('privacyPolicyContent94')}
                    </td>
                  </tr>
                  <tr>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent95')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent73')}
                      <br />
                      {t('privacyPolicyContent74')}
                      <br />
                      {t('privacyPolicyContent96')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent97')}
                      <br />
                      {t('privacyPolicyContent98')}
                    </td>
                  </tr>
                  <tr>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent99')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent73')}
                      <br />
                      {t('privacyPolicyContent74')}
                      <br />
                      {t('privacyPolicyContent87')}
                      <br />
                      {t('privacyPolicyContent93')}
                      <br />
                      {t('privacyPolicyContent81')}
                      <br />
                      {t('privacyPolicyContent96')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent100')}
                    </td>
                  </tr>
                  <tr>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent101')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent96')}
                      <br />
                      {t('privacyPolicyContent93')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent102')}
                    </td>
                  </tr>
                  <tr>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent103')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent73')}
                      <br />
                      {t('privacyPolicyContent74')}
                      <br />
                      {t('privacyPolicyContent96')}
                      <br />
                      {t('privacyPolicyContent93')}
                      <br />
                      {t('privacyPolicyContent87')}
                      <br />
                      {t('privacyPolicyContent81')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent104')}
                    </td>
                  </tr>
                  <tr>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent105')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent73')}
                      <br />
                      {t('privacyPolicyContent74')}
                    </td>
                    <td id colSpan rowSpan>
                      {t('privacyPolicyContent106')}
                      <br />
                      {t('privacyPolicyContent107')}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <b>{t('privacyPolicyContent108')}</b>
              </p>
              <p>{t('privacyPolicyContent109')}</p>
              <p>
                {t('privacyPolicyContent110')}
                <br />
                {t('privacyPolicyContent111')}
                <br />
                <b>{t('privacyPolicyContent112')}</b>
              </p>
              <p>{t('privacyPolicyContent113')}</p>
              <ul>
                <li>{t('privacyPolicyContent114')}</li>
                <li>{t('privacyPolicyContent115')}</li>
              </ul>
              <p>
                <b>{t('privacyPolicyContent116')}</b>
                <br />
                {t('privacyPolicyContent117')}
              </p>
              <p>{t('privacyPolicyContent118')}</p>
              <ul>
                <li>{t('privacyPolicyContent119')}</li>
                <li>{t('privacyPolicyContent120')}</li>
                <li>{t('privacyPolicyContent121')}</li>
                <li>{t('privacyPolicyContent122')}</li>
                <li>{t('privacyPolicyContent123')}</li>
                <li>{t('privacyPolicyContent124')}</li>
              </ul>
              <p>
                {t('privacyPolicyContent125')}
                <br />
                <b>{t('privacyPolicyContent126')}</b>
                <br />
                {t('privacyPolicyContent127')}
              </p>
              <p>{t('privacyPolicyContent128')}</p>
              <p>
                {t('privacyPolicyContent129')}
                <br />
                <b>{t('privacyPolicyContent130')}</b>
              </p>
              <p>{t('privacyPolicyContent131')}</p>
              <p>{t('privacyPolicyContent132')}</p>
              <p>{t('privacyPolicyContent133')}</p>
              <p>{t('privacyPolicyContent134')}</p>
              <p>
                {t('privacyPolicyContent135')}
                <br />
                <b>{t('privacyPolicyContent136')}</b>
              </p>
              <p>{t('privacyPolicyContent137')}</p>
              <p>{t('privacyPolicyContent138')}</p>
              <p>{t('privacyPolicyContent139')}</p>
              <p>{t('privacyPolicyContent140')}</p>
              <p>
                {t('privacyPolicyContent141')}
                <br />
                <b>{t('privacyPolicyContent142')}</b>
              </p>
              <p>{t('privacyPolicyContent143')}</p>
              <p>
                {t('privacyPolicyContent144')}{' '}
                <a href="https://supportroom.com/cookie-policy/">{t('privacyPolicyContent145')}</a>.
                <br />
                <b>{t('privacyPolicyContent146')}</b>
              </p>
              <p>{t('privacyPolicyContent147')}</p>
              <ul>
                <li>{t('privacyPolicyContent148')}</li>
                <li>{t('privacyPolicyContent149')}</li>
                <li>{t('privacyPolicyContent150')}</li>
                <li>{t('privacyPolicyContent151')}</li>
                <li>{t('privacyPolicyContent152')}</li>
                <li>{t('privacyPolicyContent153')}</li>
                <li>{t('privacyPolicyContent154')}</li>
                <li>{t('privacyPolicyContent155')}</li>
                <li>{t('privacyPolicyContent156')}</li>
                <li>{t('privacyPolicyContent157')}</li>
                <li>{t('privacyPolicyContent158')}</li>
                <li>{t('privacyPolicyContent159')}</li>
              </ul>
              <p>
                <b>{t('privacyPolicyContent160')}</b>
              </p>
              <p>
                <b>{t('privacyPolicyContent161')}</b>
                <br />
                {t('privacyPolicyContent162')}
              </p>
              <p>
                <b>{t('privacyPolicyContent163')}</b>
                <br />
                {t('privacyPolicyContent164')}
              </p>
              <p>{t('privacyPolicyContent165')}</p>
              <p>{t('privacyPolicyContent166')}</p>
              <p>
                <b>{t('privacyPolicyContent167')}</b>
                <br />
                {t('privacyPolicyContent168')}
              </p>
              <p>{t('privacyPolicyContent169')}</p>
              <p>{t('privacyPolicyContent170')}</p>
              <p>{t('privacyPolicyContent171')}</p>
              <p>
                <b>{t('privacyPolicyContent172')}</b>
                <br />
                {t('privacyPolicyContent173')}
              </p>
              <p>{t('privacyPolicyContent174')}</p>
              <p>
                <b>{t('privacyPolicyContent175')}</b>
                <br />
                {t('privacyPolicyContent176')}
              </p>
              <p>{t('privacyPolicyContent177')}</p>
              <p>
                {t('privacyPolicyContent178')}
                <br />
                <b>{t('privacyPolicyContent179')}</b>
              </p>
              <p>
                <b>{t('privacyPolicyContent180')}</b>
                <br />
                {t('privacyPolicyContent181')}
                <br />
                {t('privacyPolicyContent182')}
              </p>
              <p>{t('privacyPolicyContent183')}</p>
              <p>
                <b>{t('privacyPolicyContent184')}</b>
                <br />
                {t('privacyPolicyContent185')}
              </p>
              <p>
                <b>{t('privacyPolicyContent186')}</b>
                <br />
                {t('privacyPolicyContent187')}
                <br />
                {t('privacyPolicyContent188')}
                <br />
                {t('privacyPolicyContent189')}
              </p>
              <p>{t('privacyPolicyContent190')}</p>
            </div>
          </div>
        </section>
      </AuthLayout>
    </div>
  );
};

export default Privacypolicy;
