import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../Util/api';

import { NeutralLayout } from '../Layout';
import { Heading, Button, Loading } from '../Components/Atoms';

const VerifyEmail = ({ location: { search }, history }) => {
  const query = new URLSearchParams(search);
  const pathName = window.location.pathname;
  const verifyToken = query.get('id');
  const [isLoading, setLoading] = useState(true);
  const [responseMessage, setResponseMessage] = useState('');
  const { t } = useTranslation();

  const checkVerifyToken = useCallback(async () => {
    if (pathName === '/update-email') {
      try {
        const { message } = (await api.auth.verifyUpdatedEmail({ verify_token: verifyToken })).data;
        setResponseMessage(message);
      } catch (err) {
        console.error(err);
        // history.push('/');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const { message } = (await api.auth.verifyEmail({ verify_token: verifyToken })).data;
        setResponseMessage(message);
      } catch (err) {
        console.error(err);
        history.push('/');
      } finally {
        setLoading(false);
      }
    }
  }, [history, verifyToken, pathName]);

  useEffect(() => {
    checkVerifyToken();
  }, [checkVerifyToken]);

  return (
    <NeutralLayout>
      {isLoading ? (
        <Loading />
      ) : (
        <Wrapper>
          <Heading>{t("emailVerification")}</Heading>
          <p className="lead">{responseMessage}</p>
          <Button as={Link} to="/" className="secondary">
          {t("login")}
          </Button>
        </Wrapper>
      )}
    </NeutralLayout>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

export default VerifyEmail;
