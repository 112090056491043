import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSpring, animated } from 'react-spring';

import { isPartner } from '../../Actions/auth.actions';

import { Logo } from '../Signup/index';
import SignUpComponent from './signUpComponent';
import LoginComponent from './loginComponent';
import CarouselWithDot from './carousel';
import { Button } from '../../Components/Atoms';

import OnlineTherapyImg from '../../Assets/SignUp/onlineTherapy.png';
import CertifiedImg from '../../Assets/SignUp/certified.png';

import signupAndLoginImage from '../../Assets/SignUp/signupAndLoginImage.png';
import config from '../../Config';

import { ReactComponent as GraphSvg } from '../../Assets/SignUp/howItWorks.svg';
import { ReactComponent as GraphSvgSmall } from '../../Assets/SignUp/howItWorksSmall.svg';
import { ReactComponent as Facebook } from '../../Assets/SignUp/Social/facebook.svg';
import { ReactComponent as Twitter } from '../../Assets/SignUp/Social/twiter.svg';
import { ReactComponent as Instagram } from '../../Assets/SignUp/Social/instagram.svg';
import { ReactComponent as LinkedIn } from '../../Assets/SignUp/Social/link.svg';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../../Components/LangSwitcher/LanguageSwitch';
import { useDeviceType } from '../../Util';
import { blueHostLanguages, genericLanguageMapping, isBluehrsolutionsPartner, isExuslabsPartner } from '../../App';

const Login = ({ registerEnterprise = false, departments, userLogin = false }) => {
  const [isEnterprise, setIsEnterprise] = useState(true);
  const [loading, setLoading] = useState(true);
  const selectedLanguage = useSelector((store) => store.language.selectedLanguage);

  const socialRequestPopupStatus = useSelector((store) => store.auth.socialRequestPopupStatus);
  const teamsContext = useSelector((store) => store.teams.teamsContext);

  const LoginInfo = localStorage.getItem('loginInfo');
  const LoggedIn = !!LoginInfo;
  const isMobile = useDeviceType();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (LoggedIn && !socialRequestPopupStatus) history.push('/chatroom');

  useEffect(() => {
    if (
      window.location.host === 'mysupportroom.com' ||
      window.location.host === 'alpha.supportroom.com' ||
      window.location.host === 'localhost:3000' ||
      window.location.host === 'new.supportroom.com'
    ) {
      setIsEnterprise(false);
      setLoading(false);
    } else {
      const urlSplit = window.location.host;
      if (urlSplit || window.location.host === 'localhost:3000') {
        dispatch(isPartner(urlSplit)).then((res) => {
          setLoading(false);
          if (res.data?.data?.is_partner === 1) setIsEnterprise(false);
        });
      }
    }
  }, [dispatch]);

  const setlanguageCode = () => {
    try {
      let lang = localStorage.getItem('lang');
      if (lang) {
        return lang;
      }
      if (isExuslabsPartner) {
        return 'en';
      }
      let urlPath = window.location.pathname.split('/')[1];
      if (Object.keys(genericLanguageMapping).includes(urlPath) && urlPath !== 'en') {
        localStorage.setItem('lang', urlPath);
        return urlPath;
      }
      let browserLang = (navigator.language || navigator.userLanguage)?.slice(0, 2);
      if (genericLanguageMapping[browserLang]) {
        if (isBluehrsolutionsPartner) {
          if (blueHostLanguages[browserLang]) {
            return browserLang;
          } else {
            return 'en';
          }
        }
        return browserLang;
      }
      return 'en';
    } catch (error) {
      return 'en';
    }
  };

  const props = useSpring({ to: { opacity: loading ? 0 : 1 }, from: { opacity: 0 }, delay: 300 });

  return (
    <div>
      <Helmet>
        <title>{t('mySupportRoom')}</title>
        <meta
          name="description"
          content="Log into MySupportRoom. Access your tailored therapy and counselling services in our secure online portal today."
        />
      </Helmet>
      <Header>
        <Logo src={config.nameLogo} onClick={() => history.push(teamsContext ? '/teams' : '/')} />
        <FlexBox>
          {!isMobile ? (
            <Language>
              <LanguageSwitch />
            </Language>
          ) : null}
          {(!isEnterprise || !registerEnterprise) && !teamsContext && (
            <SwitchWrapper>
              <Button
                variant="secondary"
                style={{ fontWeight: 600, color: 'white', background: '#33c8a0' }}
                onClick={() => history.push('/t/login')}
              >
                {t('areYouATherapist')}
              </Button>
            </SwitchWrapper>
          )}
          {isMobile ? (
            <Language>
              <LanguageSwitch />
            </Language>
          ) : null}
        </FlexBox>
      </Header>

      <section>
        <MainSectionWrapper>
          <ImageWrapper>
            <img src={signupAndLoginImage} alt="" />
          </ImageWrapper>
          <SignAndLogWrapper>
            <div style={{ maxWidth: 540 }}>
              <ActionsHeader>
                <SubHeader>{t('subHeader')}</SubHeader>
                <MainHeader>{t('mainHeader')}</MainHeader>
              </ActionsHeader>
              <animated.div style={props}>
                <StyledTabs>
                  <TabList>
                    {(!isEnterprise || registerEnterprise) && !userLogin && <StyledTab>{t('signUp')}</StyledTab>}
                    {!registerEnterprise && <StyledTab>{t('login')}</StyledTab>}
                  </TabList>

                  {(!isEnterprise || registerEnterprise) && !userLogin && (
                    <TabPanel>
                      <SignUpComponent
                        registerEnterprise={registerEnterprise}
                        departments={departments}
                        isEnterprise={isEnterprise}
                      />
                    </TabPanel>
                  )}
                  {!registerEnterprise && (
                    <TabPanel>
                      <LoginComponent isEnterprise={isEnterprise} />
                    </TabPanel>
                  )}
                </StyledTabs>
              </animated.div>
            </div>
          </SignAndLogWrapper>
        </MainSectionWrapper>
      </section>
      <section>
        {selectedLanguage === 'en' && (
          <React.Fragment>
            <GraphWrapper>
              <GraphSvgImage viewBox="140 0 1200 400" as={GraphSvg} />
            </GraphWrapper>
            <GraphWrapperSmall>
              <GraphSvgImageSmall viewBox="60 0 250 1050" as={GraphSvgSmall} />
            </GraphWrapperSmall>
          </React.Fragment>
        )}
      </section>
      {selectedLanguage === 'en' || (
        <Wrap>
          <CarouselWithDot />
        </Wrap>
      )}

      {selectedLanguage === 'en' && (
        <section>
          <CarouselWithDot />
        </section>
      )}
      <section>
        <CertificationsWrapper>
          <PngImage src={OnlineTherapyImg} alt="OnlineTherapy" />
          <PngImage src={CertifiedImg} alt="CertifiedHIPA" />
        </CertificationsWrapper>
      </section>
      {!teamsContext && (
        <Footer>
          <Logo src={config.nameLogo} />
          <LinksWrapper>
            <a href="https://supportroom.com/about-us/" target="_blank" rel="noopener noreferrer">
              {t('aboutUs')}
            </a>
            <a href="https://supportroom.com/faqs/" target="_blank" rel="noopener noreferrer">
              {t('faq')}
            </a>
            <Link to="/terms-and-conditions/">{t('terms')}</Link>
            <Link to="/privacy-policy">{t('privacyPolicy')}</Link>
            {/* <a href="https://supportroom.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
            Terms
          </a>
          <a href="https://supportroom.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a> */}
            <a href="https://supportroom.com/emergency-resources/" target="_blank" rel="noopener noreferrer">
              {t('emergency')}
            </a>
          </LinksWrapper>
          <SocialWrapper>
            <a href="https://facebook.com/mysupportroom" target="_blank" rel="noopener noreferrer">
              <SocialIcon as={Facebook} />
            </a>
            <a href="https://twitter.com/mysupportroom" target="_blank" rel="noopener noreferrer">
              <SocialIcon as={Twitter} />
            </a>
            <a href="https://instagram.com/mysupportroom/" target="_blank" rel="noopener noreferrer">
              <SocialIcon as={Instagram} />
            </a>
            <a href="https://linkedin.com/company/supportroom/" target="_blank" rel="noopener noreferrer">
              <SocialIcon as={LinkedIn} />
            </a>
          </SocialWrapper>
        </Footer>
      )}
    </div>
  );
};

const SwitchWrapper = styled.div`
  color: white;
  font-size: 16px;
  text-align: right;
`;

const Wrap = styled.section`
  background: rgba(0, 200, 160, 0.05);
`;

const Language = styled.div`
  @media screen and (max-width: 400px) {
    position: absolute;
    right: 40px;
    top: 35px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;

  @media screen and (max-width: 400px) {
    gap: 10px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 85px;
  background: #491f69;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 32px;

  ${Logo} {
    width: 200px;
  }

  @media screen and (max-width: 435px) {
    ${Logo} {
      width: 150px;
    }
  }

  @media screen and (max-width: 400px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 32px;

    ${Logo} {
      display: block;
      width: 170px;
      margin: 0 0 3px 0;
    }

    ${SwitchWrapper} {
      display: flex;
      div {
        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }
`;

// const ClickHereStyled = styled(Link)`
//   font-weight: 700;
//   cursor: pointer;
//   color: white;
//   text-decoration: none;
// `;

const MainSectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  padding: 0 30px;

  margin: 65px 0;

  img {
    width: 100%;
    max-width: 480px;
    height: fit-content;
  }

  @media screen and (max-width: 455px) {
    margin: 32px 0;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  height: 500px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const SignAndLogWrapper = styled.div`
  width: 50%;
  text-align: left;

  margin-left: 30px;

  @media screen and (max-width: 1150px) {
    min-width: 600px;
    margin-left: 20px;
  }

  @media screen and (max-width: 620px) {
    width: 100%;
    min-width: initial;
    margin: 0 auto 0 auto;
  }
`;

const SubHeader = styled.div`
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;

  color: #491f69;
`;
const MainHeader = styled.div`
  font-weight: 900;
  font-size: 48px;
  line-height: 48px;

  color: #00c8a0;
`;

const ActionsHeader = styled.div`
  width: 100%;

  @media screen and (max-width: 450px) {
    ${MainHeader} {
      font-size: 35px;
      line-height: 40px;
    }
    ${SubHeader} {
      font-size: 20px;
      line-height: 25px;
    }
  }

  @media screen and (max-width: 390px) {
    ${MainHeader} {
      font-size: 25px;
      line-height: 30px;
    }
    ${SubHeader} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  margin-top: 45px;

  .react-tabs__tab--selected {
    border-bottom: 5px solid #00c8a0;
    font-weight: 600;
    opacity: 1;

    &:hover {
      border-bottom: 5px solid #00c8a0;
    }
  }

  @media screen and (max-width: 455px) {
    li {
      width: 50%;
    }
  }
`;

const StyledTab = styled(Tab)`
  width: 168px;
  text-align: center;
  color: #000000;
  opacity: 0.5;

  font-size: 16px;

  border-bottom: 1px solid #9d9d9d;

  &:hover {
    border-bottom: 5px solid #00c8a0;
  }

  cursor: pointer;
`;

const GraphWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 200, 160, 0.05);

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const GraphSvgImage = styled.svg`
  width: 100%;
  height: 100%;

  max-width: 1400px;
  min-width: 700px;
`;

const GraphWrapperSmall = styled(GraphWrapper)`
  display: none;
  justify-content: center;

  background: rgba(0, 200, 160, 0.05);

  @media screen and (max-width: 800px) {
    display: flex !important;
  }
`;

const GraphSvgImageSmall = styled(GraphSvgImage)`
  max-width: 450px;
  min-width: 250px;
`;

const CertificationsWrapper = styled.div`
  display: flex;
  width: 210px;

  margin: 40px auto 40px auto;

  align-items: center;
  justify-content: space-between;
`;

const PngImage = styled.img``;

const LinksWrapper = styled.div`
  a {
    font-size: 12px;
    color: white;
    text-decoration: none;
    margin-right: 20px;
    opacity: 0.8;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

const SocialIcon = styled.svg`
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const SocialWrapper = styled.div`
  ${SocialIcon} {
    margin-right: 15px;
    height: 16px;
  }
`;

const Footer = styled(Header)`
  ${Logo} {
    width: 165px;
  }

  @media screen and (max-width: 720px) {
    height: 100px;
    padding: 10px 32px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    ${Logo} {
      width: 130px;
      margin: 0;
      display: block;
    }

    ${LinksWrapper} {
      width: 100%;
      display: flex;
      justify-content: center;

      a {
        margin-right: 20px;
      }
    }
  }

  @media screen and (max-width: 420px) {
    ${LinksWrapper} {
      a {
        margin-right: 10px;
      }
    }
  }

  @media screen and (max-width: 370px) {
    ${LinksWrapper} {
      a {
        margin-right: 3px;
      }
    }
  }
`;

Login.propTypes = {
  registerEnterprise: PropTypes.bool,
};

export default memo(Login);
