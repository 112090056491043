import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, ChevronIcon, TextBlock, Logo, FormGroup } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import api from '../../Util/api';
import { MainSectionWrapper, BottomSectionWrapper } from '../Components/';
import { useTranslation } from 'react-i18next';

const Verify = (props) => {
  const { t } = useTranslation();
  const handleOnSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      api.auth.verifyEmail(values).then((res) => {
        if (res.data.status === 'success') {
          //Success -> redirect to profile
        }
      });
    } catch (err) {
    } finally {
    }
  };

  const validationSchema = useMemo(() => {
    const validations = {
      verification_code: Yup.string().required(t("verificationCodeIsRequired")),
    };

    return Yup.object().shape(validations);
  }, []);

  const initialValues = useMemo(() => {
    return {
      verification_code: '',
    };
  }, []);

  const handleResend = () => {
    //Handle resend code
  };

  return (
    <WelcomeLayout noPadding noTopBar>
      <section>
        <MainSectionWrapper>
          <Logo />
          <TextBlock type={'primary'}>{t("validateYourEmailCode")}</TextBlock>
          <TextBlock type={'secondary'}>
          {t("verify1")}
          </TextBlock>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleOnSubmit}
          >
            <Form noValidate>
              <StyledFormGroup>
                <FieldWrapper>
                  <Field id="verification_code" name="verification_code" type="text" className={'form-control'} />
                  <ErrorMessage name="verification_code" component="div" className="invalid-feedback" />
                </FieldWrapper>
              </StyledFormGroup>
              <TextBlock type={'emphasized'} style={{ textDecoration: 'underline' }} onClick={handleResend}>
              {t("cantFindCode")}
              </TextBlock>
              <BottomSectionWrapper>
                <Button
                  variant="secondary"
                  style={{
                    fontWeight: 600,
                    color: 'white',
                    background: '#33c8a0',
                    width: '300px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    marginBottom: '40px'
                  }}
                  type="submit"
                >
                  {t("validateCode")}
                  <ChevronIcon direction="right" width="13" height="13" />
                </Button>
              </BottomSectionWrapper>
            </Form>
          </Formik>
        </MainSectionWrapper>
      </section>
    </WelcomeLayout>
  );
};

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  input,
  select {
    width: 100%;
    height: 50px;
    flex-grow: initial;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    font-size: 16px;
    background: ${({ theme }) => theme.greyVeryLight};
    border-radius: 5px;
    &:focus {
      border: 2px solid #00c8a0;
    }
  }

  input {
    svg {
      path {
        fill: #797979 !important;
      }
    }
  }

  label {
    span {
      border-radius: 0;
      border-color: #c4c4c4;

      a {
        color: black;
      }
    }

    &:hover {
      .checkmark {
        background: #f6f6f6;
      }
    }
  }

  input:checked ~ .checkmark {
    background-color: #00c8a0;
  }
`;
export const FieldWrapper = styled.div`
  width: 100%;
  margin-right: 5px;
  position: relative;
  input {
    margin-top: 5px;
  }

  select {
    margin-top: 5px;
  }

  label {
    font-size: 13px;
    color: gray;
  }
`;
export default Verify;
