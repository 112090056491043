import React from "react";
import { Heading } from "../../../Components/Atoms";
import styled from "styled-components";

const VideosWrapper = styled.div`
  overflow-x: scroll;
  padding-bottom: 20px;
  max-width: 300px;
`;
const VideosViewport = styled.div`
  overflow: hidden;
`;
const Video = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 160px;
  height: 90px;
  background: grey;
  margin-right: 10px;
  color: white;
`;

const Videos = props => {
  const videoCount = props.videoList.length;
  const videoViewportWidth = videoCount * 170 + "px";

  let videosArray = [];
  for (let i = 0; i < videoCount; ++i) {
    videosArray.push(<Video key={i}>{i + 1}</Video>);
  }

  return (
    <>
      <Heading level={3}>
        {props.heading} ({props.videoList.length}):
      </Heading>
      <VideosWrapper>
        <VideosViewport style={{ width: videoViewportWidth }}>
          {videosArray}
        </VideosViewport>
      </VideosWrapper>
    </>
  );
};

export default Videos;
