import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const ChevronIcon = (props) => {
  return (
    <StyledSvg {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56858 0.183617C8.62678 0.241674 8.67296 0.310644 8.70447 0.386575C8.73598 0.462507 8.7522 0.543908 8.7522 0.626117C8.7522 0.708326 8.73598 0.789728 8.70447 0.865659C8.67296 0.941591 8.62678 1.01056 8.56858 1.06862L1.50983 8.12612L8.56858 15.1836C8.62669 15.2417 8.67279 15.3107 8.70423 15.3866C8.73568 15.4626 8.75187 15.5439 8.75187 15.6261C8.75187 15.7083 8.73568 15.7897 8.70423 15.8656C8.67279 15.9415 8.62669 16.0105 8.56858 16.0686C8.51047 16.1267 8.44148 16.1728 8.36556 16.2043C8.28963 16.2357 8.20826 16.2519 8.12608 16.2519C8.0439 16.2519 7.96252 16.2357 7.8866 16.2043C7.81068 16.1728 7.74169 16.1267 7.68358 16.0686L0.183581 8.56862C0.125376 8.51056 0.0791985 8.44159 0.04769 8.36566C0.0161825 8.28973 -3.65984e-05 8.20833 -3.65948e-05 8.12612C-3.65912e-05 8.04391 0.0161826 7.96251 0.04769 7.88658C0.0791985 7.81064 0.125376 7.74167 0.183581 7.68362L7.68358 0.183617C7.74164 0.125413 7.81061 0.0792346 7.88654 0.0477266C7.96247 0.0162185 8.04387 -3.09619e-08 8.12608 -2.73684e-08C8.20829 -2.3775e-08 8.28969 0.0162186 8.36562 0.0477266C8.44155 0.0792347 8.51052 0.125413 8.56858 0.183617Z"
        fill={props.color}
      />
    </StyledSvg>
  );
};
const StyledSvg = styled.svg(
  (props) => css`
    transform: rotate(0);
    ${props.direction === 'down' &&
    css`
      transform: rotate(270deg);
    `}
    ${props.direction === 'right' &&
    css`
      transform: rotate(180deg);
    `}
    ${props.direction === 'up' &&
    css`
      transform: rotate(90deg);
    `}
    ${props.direction === 'left' &&
    css`
      transform: rotate(0);
    `}
  `,
);
ChevronIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  direction: PropTypes.string,
};
ChevronIcon.defaultProps = {
  color: 'white',
  height: '20',
  width: '20',
  viewBox: '0 0 20 20',
  fill: 'none',
};
export default ChevronIcon;
