import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { getCalendarEvents } from '../../Util/calendar';
import { getActiveChatRooms, getScheduleList } from '../../Actions/chatroom.actions';
import { getToLocalSchedulesChatroom } from '../../Selectors/schedules.selector';

import { DefaultLayout } from '../../Layout';
import CalendarComponent from '../../Components/Atoms/Calendar/Calendar';
import { PageHeading } from '../../Components/Molecules';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from '../../Assets/Icons/Calendar.svg';

const PatientCalendar = ({ scheduleList, chatRoomID, profileId }) => {
  const [bookedEvents, setBookedEvents] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getActiveChatRooms()).then((response) => {
      if (response.length === 0) history.push('/questionnaire');
    });
  }, [dispatch, history]);

  useEffect(() => {
    dispatch(getScheduleList(chatRoomID));
  }, [dispatch, chatRoomID]);

  useEffect(() => {
    if (scheduleList && scheduleList.length >= 0 && profileId) {
      const [, booked] = getCalendarEvents(scheduleList, false);
      if (booked.length >= 0) setBookedEvents(booked.filter((event) => event.slot.patient_id === profileId));
    }
  }, [scheduleList, profileId]);

  return (
    <DefaultLayout>
      <PageHeading icon={CalendarIcon} name={t('videoAppointments')} />

      <p>{t('calenderText')}</p>

      <CalendarComponent
        events={bookedEvents}
        chatRoomID={chatRoomID}
        type="booked"
        // default library settings
        selectable={false}
        defaultView="week"
        views={false}
        getSchedule={getScheduleList}
      />
    </DefaultLayout>
  );
};

const StyledCalendar = styled(CalendarComponent)`
  height: 1000px;
  margin-top: ${({ theme }) => theme.spacingLg};
`;
StyledCalendar.displayName = 'StyledCalendar';

const mapStateToProps = (store) => ({
  scheduleList: getToLocalSchedulesChatroom(store),
  chatRoomID: store.chatroom.chatRoomID,
  profileId: store.user?.profile?.id,
});

export default connect(mapStateToProps)(PatientCalendar);
