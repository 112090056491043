import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Heading, Widget } from '../../Components/Atoms';
import InvoicesTable from './Components/InvoicesTable';
import { useTranslation } from 'react-i18next';

const Billing = () => {
  const { t } = useTranslation();
  const invoices = [
    { id: 1, date: '2020-10-10', amount: 1001.22 },
    { id: 2, date: '2020-11-12', amount: 1101.01 },
    { id: 3, date: '2020-12-11', amount: 1201.39 },
  ];

  return (
    <>
      <Heading>{t("billing")}</Heading>
      <Widget>
        <InvoicesTable data={invoices} />
        <StyledLink to={'/c/dashboard/billing'}>{t("viewAll")}</StyledLink>
      </Widget>
    </>
  );
};

const StyledLink = styled(Link)`
  margin-top: ${({ theme }) => theme.spacingMd};
  display: inline-block;
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fontMedium};
  color: ${({ theme }) => theme.primaryMedium};
`;
StyledLink.displayName = 'StyledLink';

export default Billing;
