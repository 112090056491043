import React, { memo } from 'react';
import styled from 'styled-components';
import { Button, Heading } from '../../Components/Atoms';
import { Widget } from '../../Components/Atoms';
import Loading from '../../Components/Atoms/Loading/Loading';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DetailItem = memo(({ label, value }) => {
  const { t } = useTranslation();
  function NewlineText(props) {
    const text = props.text.toString();
    return text.split('\n').map((str, index) => <p key={`str-${index}`}>{str}</p>);
  }

  return (
    <DetailItemWrapper>
      <Heading level={4} text={label} />
      <ValueWrapper>{value ? <NewlineText text={value} /> : <p>{t("notSet")}</p>}</ValueWrapper>
    </DetailItemWrapper>
  );
});

const Details = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const profile = useSelector((store) => store.user?.profile);

  return (
    <>
      <Heading text={t("myAccount")} />
      <Widget>
        {!profile ? (
          <Loading />
        ) : (
          <>
            <DetailsWrapper>
              <DetailItem label="Company name" value={profile.company_name} />
              {/*<DetailItem label="Last Name" value={profile.last_name} />*/}
              <DetailItem label="Email" value={profile.email} />
              <DetailItem label="Country" value={profile.country} />
              <DetailItem label="City" value={profile.city} />
              {/*<DetailItem label="About Me" value={profile.about_me} />*/}
              {/*<DetailItem label="Experience Summary" value={profile.exp_summary} />*/}
              {/*<DetailItem label="Years of Experience" value={profile.experience} />*/}
              {/*<DetailItem label="Date of Birth" value={profile.date_of_birth} />*/}
              <DetailItem label="Address" value={profile.address} />
              <DetailItem label="Post Code" value={profile.post_code} />
            </DetailsWrapper>
            <Button
              onClick={() => {
                history.push('/c/account/edit');
              }}
            >
              {t("edit")}
            </Button>
          </>
        )}
      </Widget>
    </>
  );
};

const DetailsWrapper = styled.ul``;

const DetailItemWrapper = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing};
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};
`;

const ValueWrapper = styled.div`
  width: 100%;
`;

export default Details;
