import React, { useState, memo, useEffect } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { useDispatch } from 'react-redux';
import { useTimer } from 'react-timer-hook';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getMessages, sendFile } from '../../../Actions/chatroom.actions';

import { Heading, Button } from '../../../Components/Atoms';

import useRecorder from '../../../Util/useRecorder';

import { ReactComponent as Microphone } from '../../../Assets/Icons/Microphone.svg';

function RecordVoiceTimer({ expiryTimestamp, stopRecording }) {
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => stopRecording(),
  });

  return (
    <TimerStyle style={{ textAlign: 'center' }}>
      <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:<span>{seconds < 10 ? `0${seconds}` : seconds}</span>
    </TimerStyle>
  );
}

const TimerStyle = styled.span`
  font-size: 18px;
`;

const RecordAudio = ({ chatRoomID, pageLimit, isDesktop = true, onSendFile = () => { } }) => {
  const { t } = useTranslation();
  const [recordState, setRecordState] = useState(false);
  const [expiryTimestamp, setExpiryTimestamp] = useState(false);
  const [uploadingVoice, setUploadingVoice] = useState(false);

  const { audioFile, audioURL, isRecording, startRecording, stopRecording, deleteFile } = useRecorder();
  const dispatch = useDispatch();

  const startRecordingAction = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 180);
    setExpiryTimestamp(time);

    startRecording();
    setRecordState(true);
  };

  const stopRecordingAction = () => {
    setRecordState(false);
    stopRecording();
  };

  const handleUpload = async () => {
    const date = moment().format('YYYY-MM-DD_HH-mm-ss');

    setUploadingVoice(true);
    const formData = new FormData();

    formData.append('voice-blob', audioFile, `audio-${date}.mp3`);

    dispatch(sendFile(chatRoomID, formData)).then(() => {
      dispatch(getMessages(chatRoomID, pageLimit));
      onSendFile();
      setUploadingVoice(false);
      deleteFile();
      setRecordState(false);
    });
  };

  useEffect(() => {
    if (!isRecording) {
      setRecordState(false);
    }
  }, [isRecording, recordState]);

  const RecordingAnimation = (
    <svg xmlns="http://www.w3.org/2000/svg" width="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <g transform="rotate(180 50 50)">
        <rect x="9.5" y="35" width="6" height="40" fill="#1c1c1c">
          <animate
            attributeName="height"
            calcMode="spline"
            values="20;30;4;20"
            dur="0.5714285714285714s"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
            repeatCount="indefinite"
            begin="-0.24489795918367346s"
          />
        </rect>
        <rect x="22" y="35" width="6" height="40" fill="#6e6e6e">
          <animate
            attributeName="height"
            calcMode="spline"
            values="20;30;4;20"
            dur="0.5714285714285714s"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
            repeatCount="indefinite"
            begin="-0.4897959183673469s"
          />
        </rect>
        <rect x="34.5" y="35" width="6" height="40" fill="#b8babd">
          <animate
            attributeName="height"
            calcMode="spline"
            values="20;30;4;20"
            dur="0.5714285714285714s"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
            repeatCount="indefinite"
            begin="-0.16326530612244897s"
          />
        </rect>
        <rect x="47" y="35" width="6" height="40" fill="#a3a3ac">
          <animate
            attributeName="height"
            calcMode="spline"
            values="20;30;4;20"
            dur="0.5714285714285714s"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
            repeatCount="indefinite"
            begin="-0.40816326530612246s"
          />
        </rect>
        <rect x="59.5" y="35" width="6" height="40" fill="#727a7e">
          <animate
            attributeName="height"
            calcMode="spline"
            values="20;30;4;20"
            dur="0.5714285714285714s"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
            repeatCount="indefinite"
            begin="-0.32653061224489793s"
          />
        </rect>
        <rect x="72" y="35" width="6" height="40" fill="#1c1c1c">
          <animate
            attributeName="height"
            calcMode="spline"
            values="20;30;4;20"
            dur="0.5714285714285714s"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
            repeatCount="indefinite"
            begin="-0.08163265306122448s"
          />
        </rect>
        <rect x="84.5" y="35" width="6" height="40" fill="#6e6e6e">
          <animate
            attributeName="height"
            calcMode="spline"
            values="20;30;4;20"
            dur="0.5714285714285714s"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
            repeatCount="indefinite"
            begin="0s"
          />
        </rect>
      </g>
    </svg>
  );

  if (!isDesktop)
    return (
      <>
        {!audioFile && !recordState && (
          <Heading align="center" level={5}>
            {t("recordAudioMessage")}
          </Heading>
        )}
        {recordState && (
          <div style={{ textAlign: 'center', fontSize: 18 }}>
            <span style={{ fontSize: '18px' }}>Time left: </span>
            <RecordVoiceTimer expiryTimestamp={expiryTimestamp} stopRecording={stopRecordingAction} /> /{' '}
            <RecordingHeading>{t("recording")}</RecordingHeading>
          </div>
        )}
        <ButtonWrapper>
          {recordState ? (
            <Button onClick={stopRecordingAction} type="button">
              {t("stopRecording")}
            </Button>
          ) : (
            <>
              <Button className="danger" onClick={startRecordingAction}>
                {t("record")}
              </Button>
              {audioFile && (
                <Button
                  disabled={uploadingVoice}
                  className="success"
                  style={{ marginLeft: '8px' }}
                  onClick={handleUpload}
                >
                  {t("send")}
                </Button>
              )}
            </>
          )}
        </ButtonWrapper>
        {audioFile && !recordState && <AudioPlayer src={audioURL} controls />}
      </>
    );

  return (
    <DictateButton>
      <StyledPopup trigger={recordState ? RecordingAnimation : <Microphone />} position="top right">
        {!audioFile && !recordState && (
          <Heading align="center" level={5}>
            {t("recordAudioMessage")}
          </Heading>
        )}
        {recordState && (
          <div style={{ textAlign: 'center', fontSize: 20 }}>
            <span>{t("timeLeft")}</span>
            <RecordVoiceTimer expiryTimestamp={expiryTimestamp} stopRecording={stopRecordingAction} /> /{' '}
            <RecordingHeading>{t("recording")}</RecordingHeading>
          </div>
        )}
        <ButtonWrapper>
          {recordState ? (
            <Button onClick={stopRecordingAction} type="button">
              {t("stopRecording")}
            </Button>
          ) : (
            <>
              <Button className="danger" onClick={startRecordingAction}>
                {t("recordMessage")}
              </Button>
              {audioFile && (
                <Button
                  disabled={uploadingVoice}
                  className="success"
                  style={{ marginLeft: '8px' }}
                  onClick={handleUpload}
                >
                  {t("send")}
                </Button>
              )}
            </>
          )}
        </ButtonWrapper>
        {audioURL && !recordState && <AudioPlayer src={audioURL} controls />}
      </StyledPopup>
    </DictateButton>
  );
};

const RecordingHeading = styled.span`
  font-size: 16px;
`;

const DictateButton = styled.div`
  width: 40px;
  height: 40px;
  margin-top: 5px;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.primary};
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: 300px !important;
    padding: ${({ theme }) => theme.spacing};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AudioPlayer = styled.audio`
  width: 100%;
  height: 24px;
  padding-top: 5px;
  border-radius: 16px;
  margin-top: ${({ theme }) => theme.spacingSm};
`;

export default memo(RecordAudio);
