import React, { memo } from 'react';
// import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ChevronIcon, TextBlock, Logo } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import { BottomSectionWrapper, MainSectionWrapper } from '../Components/';
import { useDeviceType } from '../../Util';
import styled from 'styled-components';
import EmergencyBtn from '../../Components/Atoms/EmergencyBtn';
import { useTranslation } from 'react-i18next';

const Setup = () => {
  // const socialRequestPopupStatus = useSelector((store) => store.auth.socialRequestPopupStatus);
  // const loginInfo = localStorage.getItem('loginInfo');
  // const isLoggedIn = !!loginInfo;
  const history = useHistory();
  const isMobile = useDeviceType();
  const { t } = useTranslation();
  // if (isLoggedIn && !socialRequestPopupStatus) history.push('/chatroom');

  return (
    <WelcomeLayout noPadding noTopBar>
      <section>
        <MainSectionWrapper isMobile={isMobile}>
          <Logo height={isMobile ? 75 : 100} width={isMobile ? 75 : 100} />
          <TextBlock type={'primary'}>
          {t("setUpText1")}
          </TextBlock>
          <TextBlock type={'secondary'} paddingHorizontal={'50px'}>
          {t("setUpText2")}
          </TextBlock>
          <TextBlock type={'secondary'} paddingHorizontal={'50px'}>
          {t("setUpText3")}
          </TextBlock>
          <TextBlock type={'emphasized'} paddingHorizontal={'50px'}>
          {t("setUpText4")}
          </TextBlock>
          <ButtonWrapper isMobile={isMobile}>
            <Button
              variant="secondary"
              style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
              onClick={() => history.push('/welcome/therapists')}
            >
              {t("setUpText5")}
              <ChevronIcon direction="right" width="13" height="13" />
            </Button>
          </ButtonWrapper>
        </MainSectionWrapper>
      </section>
      <section>
        <BottomSectionWrapper>
          <EmergencyBtn />
        </BottomSectionWrapper>
      </section>
    </WelcomeLayout>
  );
};

const ButtonWrapper = styled.div`
  position: ${({ isMobile }) => (isMobile ? 'absolute' : 'inherit')};
  bottom: 0;
  z-index: 99;
`;
export default memo(Setup);
