import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Heading } from '../../Components/Atoms';
import { AuthLayout } from "../../Layout";

const SignupConfirm = () => {
  const { t } = useTranslation();
  return (
    <AuthLayout>
      <Wrapper>
        <Heading>{t("welcomeToSupportRoom")}</Heading>
        <p className="lead">{t("therapistAssignmentHeading1")}</p>
        <Alert className="info" addMargin>
        {t("verifyEmail")}
        </Alert>
        <Button as={Link} to="/login" className="secondary">
        {t("login")}
        </Button>
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  text-align: center;
`;

export default SignupConfirm;