import React, { memo, useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { submitFeedback } from '../../../Actions/chatroom.actions';

import { Button, Checkbox } from '../../Atoms';

const satisfactionCheckboxData = [
  { label: 'Extremely Unsatisfied', value: 1 },
  { label: 'Very Unsatisfied', value: 2 },
  { label: 'Satisfied', value: 3 },
  { label: 'Very Satisfied', value: 4 },
  { label: 'Extremely Satisfied', value: 5 },
];

const TherapyFeedback = ({ chatRoomId, isFinal }) => {
  const { t } = useTranslation();
  const [satisfaction, setSatisfaction] = useState([]);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const dispatch = useDispatch();

  const satisfactionOnChange = (e) => {
    setSubmitDisabled(false);
    setSatisfaction([parseInt(e.target.name)]);
  };

  const submit = useCallback(() => {
    setSubmitDisabled(true);
    dispatch(submitFeedback({ rating: satisfaction[0], chatroom_id: chatRoomId, comment: 'comment' }));
  }, [chatRoomId, dispatch, satisfaction]);

  return (
    <SubmitFeedbackWrapper>
      {isFinal ? (
        <h2>{t("therapyFeedback1")}</h2>
      ) : (
        <h2>{t("therapyFeedback2")}</h2>
      )}
      <Formik
        initialValues={{
          checked: satisfaction,
        }}
        onSubmit={submit}
      >
        {() => (
          <Form onChange={satisfactionOnChange}>
            {satisfactionCheckboxData.map((checkbox) => (
              <CheckboxWrapper key={checkbox.value}>
                <Checkbox
                  checked={satisfaction.includes(checkbox.value)}
                  label={checkbox.label}
                  name={checkbox.value}
                />
              </CheckboxWrapper>
            ))}
            <ButtonWrapper>
              <Button
                disabled={!satisfaction.length || isSubmitDisabled}
                style={{ width: '100%' }}
                variant="secondary"
                type="submit"
              >
                {t("submit")}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </SubmitFeedbackWrapper>
  );
};

const SubmitFeedbackWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 0;

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  label {
    font-weight: 400;
  }
`;

const CheckboxWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing};
`;

const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacingMd};
`;

export default memo(TherapyFeedback);
