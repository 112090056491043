import React from "react";
import { Heading } from "../../../Components/Atoms";
import styled from "styled-components";

const AvailabilityWrapper = styled.div`
  overflow-x: scroll;
  padding-bottom: 20px;
  max-width: 300px;
`;
const AvailabilityViewport = styled.div`
  overflow: hidden;
`;
const Slot = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;
  width: 110px;
  height: 33px;

  background: #d2bbe2;
  border-radius: 5px;
`;

const Availability = props => {

  const count = props.slots.length;
  const viewportWidth = count * 120 + "px";

  return (
    <>
      <Heading level={3}>
        {props.heading}:
      </Heading>
      <p>Please select a date you would like for your next session.</p>
      <AvailabilityWrapper>
        <AvailabilityViewport style={{ width: viewportWidth }}>
          {props.slots.map(slot => (
            <Slot key={slot.id}>{slot.date}</Slot>
          ))}
        </AvailabilityViewport>
      </AvailabilityWrapper>
    </>
  );
};

export default Availability;
