import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Heading, FormGroup, Button, Alert, Input } from '../../Atoms';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { adminLogin } from '../../../Actions/auth.actions';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Eye } from '../../../Assets/Icons/Eye.svg';
import { ReactComponent as EyeSlash } from '../../../Assets/Icons/EyeSlash.svg';

const AdminLoginForm = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    showError: false,
    errorMessage: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const loginCode = query.get('login_code');

  const dispatch = useDispatch();
  const history = useHistory();

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnSubmit = async (values, { setSubmitting }) => {
    setSubmitLoading(true);
    try {
      await dispatch(adminLogin(values, history, props.redirect, props.timeout));
      if (props.timeout) setShowSuccessMessage(true);
    } catch (err) {
      setSubmitLoading(false);
      setState({
        showError: true,
        errorMessage: err.response.data.message,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    admin_password: Yup.string().required(t("adminPasswordRequired")),
    login_code: Yup.string().required(t("loginCodeRequired")),
  });

  return (
    <>
      <Wrapper>
        <Heading text={props.heading} />
        <Formik
          initialValues={{
            admin_password: '',
            login_code: loginCode,
          }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormGroup>
                <Field
                  id="admin_password"
                  name="admin_password"
                  placeholder={t("adminPassword")}
                  as={Input}
                  onIconClick={togglePassword}
                  icon={showPassword ? EyeSlash : Eye}
                  type={showPassword ? 'text' : 'password'}
                  className={'form-control' + (errors.admin_password && touched.admin_password ? ' is-invalid' : '')}
                />
                <ErrorMessage name="admin_password" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field
                  id="login_code"
                  name="login_code"
                  placeholder={t("loginCode")}
                  type="password"
                  className={'form-control' + (errors.login_code && touched.login_code ? ' is-invalid' : '')}
                />
                <ErrorMessage name="login_code" component="div" className="invalid-feedback" />
              </FormGroup>

              {showSuccessMessage && (
                <FormGroup>
                  <Alert className="secondary">{t("successfullyLoggedIn")}</Alert>
                </FormGroup>
              )}

              <Button loading={submitLoading} type="submit">
              {t("login")}
              </Button>
            </Form>
          )}
        </Formik>
        {state.showError && (
          <Alert className="danger" addMargin>
          {t("emailAddressPasswordNotRecognised")} <Link to="/forgotten-password">{t("forgotPassword")}</Link>
          </Alert>
        )}
      </Wrapper>
    </>
  );
};
AdminLoginForm.propTypes = {
  userType: PropTypes.string,
  heading: PropTypes.string,
};
AdminLoginForm.defaultProps = {
  userType: 'patient',
  heading: 'Login to your account',
};

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

export default AdminLoginForm;
