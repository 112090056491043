import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Heading, Alert } from '../../../Components/Atoms';
import api from '../../../Util/api';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const InviteEmployees = () => {
  const { t } = useTranslation();
  const profile = useSelector((store) => store.user.profile);

  const getCompanyName = () => {
    api.company.getAuthDetails();
  };

  useEffect(() => {
    getCompanyName();
  }, []);

  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const invitationLink = `https://supportroom.com/c/employee-signup?company_unique_id=${profile?.company_unique_id}&invitation_password=${profile?.invitation_password}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(invitationLink);
    setShowCopySuccess(true);

    setTimeout(() => setShowCopySuccess(false), 4000);
  };

  return (
    <>
      <Heading level={2}>{t("inviteEmployees")}</Heading>
      <p>{t("uniqueEmployeeInvitationLink")}</p>
      <p>
      {t("copyTheLink")}
      </p>

      <LinkWrapper>
        <StyledInviteLink>{invitationLink}</StyledInviteLink>
        <Button className="primary small" onClick={copyToClipboard} style={{}}>
        {t("copyCode")}
        </Button>
      </LinkWrapper>

      {showCopySuccess && <Alert className="info">{t("inviteLinkCopied")}</Alert>}
    </>
  );
};

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.primaryAlpha10};
  margin: ${({ theme }) => theme.spacing} 0;
  padding: ${({ theme }) => theme.spacing};
  border-radius: ${({ theme }) => theme.spacingSm};
  gap: ${({ theme }) => theme.spacingLg};
`;
const StyledInviteLink = styled.p`
  color: ${({ theme }) => theme.secondary};
  font-weight: ${({ theme }) => theme.fontMedium};
  margin: 0;
  word-break: break-all;
`;
export default InviteEmployees;
