import React from 'react';
import styled from 'styled-components';
import { Widget } from '../../Components/Atoms';
import {
  DepartmentsAffected,
  EmployeeCount,
  EmployeeIssues,
  StatsGrid,
  EmployeeTherapySatisfaction,
} from './Components';
import { useTranslation } from 'react-i18next';

const Overview = (props) => {
  const { t } = useTranslation();
  return (
    <>
      {props.showCompletionAlert && (
        <Alert>
        {t("profileNotCompletedText")}{' '}
          <a href="/c/signup">{t("updateProfile")}</a>
        </Alert>
      )}

      <WidgetGrid>
        <div className="Satisfaction">
          <Widget>
            <EmployeeTherapySatisfaction />
          </Widget>
        </div>
        <div className="Issues">
          <Widget>
            <h4>{t("issuesFacingEmployees")}</h4>
            <EmployeeIssues />
          </Widget>
        </div>
        <div className="Stats">
          <StatsGrid />
        </div>
        <div className="EmployeeCount">
          <Widget>
            <h4>{t("employeeCountByDepartment")}</h4>
            <EmployeeCount />
          </Widget>
        </div>
        <div className="Billing">
          <Widget>
            <h4>{t("billing")}</h4>
          </Widget>
        </div>
        <div className="DepartmentsAffected">
          <Widget>
            <h4>{t("departmentsAffected")}</h4>
            <DepartmentsAffected />
          </Widget>
        </div>
      </WidgetGrid>
    </>
  );
};

const Alert = styled(Widget)`
  background-color: #e06c00;
  text-align: center;
  color: white;
  font-size: 16px;
  padding: ${({ theme }) => theme.spacing};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  a {
    font-weight: ${({ theme }) => theme.fontBold};
    color: ${({ theme }) => theme.white};
  }
`;

const WidgetGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: ${({ theme }) => theme.spacingMd};
  grid-template-areas: 'ST' 'EC' 'BI' 'DA' 'SA' 'IS';

  ${({ theme }) => theme.lg`
    gap: ${({ theme }) => theme.spacingMd};
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      "SA SA SA SA SA IS IS IS IS IS IS IS"
      "ST ST ST ST ST ST ST ST ST ST ST ST"
      "EC EC EC EC EC EC EC EC EC EC EC EC"
      "BI BI BI BI BI BI DA DA DA DA DA DA";
  `}

  .Stats {
    grid-area: ST;
  }
  .EmployeeCount {
    grid-area: EC;
  }
  .Billing {
    grid-area: BI;
  }
  .DepartmentsAffected {
    grid-area: DA;
  }
  .Satisfaction {
    grid-area: SA;
  }
  .Issues {
    grid-area: IS;
  }
`;

export default Overview;
