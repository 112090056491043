const env = process.env;
let rooms = {};

const roomsVars = Object.keys(env).filter((key) => key.startsWith('REACT_APP_ROOM_'));
roomsVars.forEach(function (key) {
  const [rn, k] = key.split('REACT_APP_ROOM_').pop().split('_');
  rooms[rn] = rooms[rn] || { name: rn }; // initialize in case empty
  rooms[rn][k] = env[key];
});

export default {
  ASSET_PATH: '.',
  DAILY_SUBDOMAIN: 'mysupportroom',
  COMPANY_NAME: 'Daily.co',
  COMPANY_URL: 'https://daily.co',
  rooms: ['local-testing'],
};
