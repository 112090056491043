import React, { useEffect, useMemo, memo, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { getActiveChatRooms, getTherapyJourney } from '../../Actions/chatroom.actions';
import { getPatientProfile } from '../../Actions/therapist.actions';

import { DashboardLayout } from '../../Layout';
import { Widget } from '../../Components/Atoms';
import JourneyHeader from './JourneyHeader';
import Intake from './Intake';
import Note from './Note';

const TherapyJourney = () => {
  const tracker = useSelector((store) => store.chatroom.therapyJourney?.data);
  const patientProfile = useSelector((store) => store.user.patientProfile);

  const { chatRoomId, stageId, subStageId, noteId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  if (!stageId || !subStageId) history.goBack();

  const goBackToNotes = useCallback(() => history.goBack(), [history]);

  const notes = useMemo(() => {
    if (!tracker || !tracker.length) return null;
    else if (tracker.length) {
      const stageIndex = tracker.findIndex((stage) => stage.id === parseInt(stageId));

      if (stageIndex > -1 && tracker[stageIndex].sub_stages?.length) {
        const subStageIndex = tracker[stageIndex].sub_stages.findIndex(
          (subStage) => subStage.sub_stage_id === parseInt(subStageId),
        );

        if (subStageIndex > -1) {
          if (tracker[stageIndex].sub_stages[subStageIndex].notes.length) {
            return [...tracker[stageIndex].sub_stages[subStageIndex].notes];
          }
        }
      }

      return null;
    }
  }, [tracker, stageId, subStageId]);

  const note = useMemo(() => {
    if (!noteId || !notes || !notes.length) return null;
    else if (notes?.length) {
      const noteIndex = notes.findIndex((note) => note.id === parseInt(noteId));
      if (noteIndex > -1) return notes[noteIndex];
      return null;
    }
  }, [noteId, notes]);

  const goToNote = (noteId) => history.push(`/t/therapy-journey/${chatRoomId}/${stageId}/${subStageId}/${noteId}`);

  useEffect(() => {
    dispatch(getActiveChatRooms('therapist', parseInt(chatRoomId))).then((res) => {
      dispatch(getTherapyJourney(chatRoomId));
      if (res.length) {
        const roomIndex = res.findIndex((room) => room.chatroom_id === parseInt(chatRoomId));
        if (roomIndex > -1) dispatch(getPatientProfile(res[roomIndex].patient_id));
      }
    });
  }, [chatRoomId, dispatch]);

  return (
    <DashboardLayout>
      <h1>Therapy Journey</h1>
      <Widget>
        {patientProfile && <JourneyHeader patientProfile={patientProfile} />}
        <hr />
        {subStageId && !noteId ? <Intake notes={notes} goToNote={goToNote} /> : noteId && <Note note={note} />}
      </Widget>
      {noteId && <BackButton onClick={goBackToNotes}>Back</BackButton>}
    </DashboardLayout>
  );
};

const BackButton = styled.div`
  margin-top: 30px;
`;

export default memo(TherapyJourney);
