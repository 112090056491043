import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { DashboardLayout } from '../../Layout';
import { Heading } from '../../Components/Atoms';
import api from '../../Util/api';
import { TherapistProfile } from '../../Components/Organisms';
import { Widget } from '../../Components/Atoms';

const Profile = ({ profile }) => {
  const [specialisms, setSpecialisms] = useState(undefined);
  const [tools, setTools] = useState(undefined);

  const getSpecialisms = () => {
    api.therapist
      .getSpecialisms()
      .then((response) => response.data)
      .then((specialisms) => {
        setSpecialisms(specialisms.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getTools = () => {
    api.therapist
      .getTools()
      .then((response) => response.data)
      .then((tools) => {
        setTools(tools.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getSpecialisms();
    getTools();
  }, []);

  return (
    <DashboardLayout>
      <Heading>My Profile</Heading>
      <Widget>
        <TherapistProfile user={profile} specialisms={specialisms} tools={tools} />
      </Widget>
    </DashboardLayout>
  );
};

const mapStateToProps = (store) => ({
  profile: store.user.profile,
});

export default connect(mapStateToProps)(Profile);
