import * as React from "react";
const Cross = (props) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.2067 8.5L16.4302 3.30556C17.1899 2.55 17.1899 1.41667 16.4302 0.661111C16.1453 0.283333 15.6704 0 15.1955 0C14.7207 0 14.2458 0.188889 13.8659 0.566667L8.54749 5.85556L3.32402 0.661111C2.56425 -0.0944446 1.32961 -0.0944446 0.664804 0.661111C0.284916 0.944444 0 1.41667 0 1.98333C0 2.55 0.189944 2.92778 0.569832 3.30556L5.7933 8.5L0.569832 13.6944C0.284916 14.0722 0 14.5444 0 15.1111C0 15.5833 0.189944 16.0556 0.569832 16.4333C0.949721 16.8111 1.42458 17 1.89944 17C2.3743 17 2.84916 16.8111 3.22905 16.4333L8.45251 11.2389L13.676 16.4333C14.4358 17.1889 15.6704 17.1889 16.3352 16.4333C17.095 15.6778 17.095 14.45 16.3352 13.7889L11.2067 8.5Z"
      fill="#442165"
    />
  </svg>
);
export default Cross;
