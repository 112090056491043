import React, { memo, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import Popup from 'reactjs-popup';

import { useWindowSize } from '../../Hooks';
import { getJournals, deleteJournal, shareJournalWithTherapist } from '../../Actions/chatroom.actions';

import { Button, Heading } from '../../Components/Atoms';
import { DefaultLayout } from '../../Layout';
import Sidebar from './Sidebar';
import Switch from '../../Components/Atoms/Form/Switch';

import { ReactComponent as ShareIcon } from '../../Assets/Icons/Share.svg';
import Tooltip from '../../Components/Atoms/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

const JournalList = memo(({ journals, editJournal, deleteJournal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const viewportWidth = useWindowSize().width;

  // const createJournal = () => history.push('/journal/new');

  const goToPage = (page) => dispatch(getJournals(page));

  const shareWithTherapist = useCallback(
    (journal) => {
      dispatch(shareJournalWithTherapist(journal.journal_id, journal.shared_with_therapist === 1 ? 0 : 1));
    },
    [dispatch],
  );

  const isMobile = useMemo(() => viewportWidth < 768, [viewportWidth]);

  const tableRows = useMemo(
    () =>
      journals && journals.data
        ? journals.data.map((journal) => {
            const date = moment.utc(journal.created_at).local().format('YYYY/MM/DD HH:mm');
            return (
              <tr key={journal.journal_id}>
                <td style={{ fontSize: 14 }} width="20%">
                  {date}
                </td>
                <td width="80%" style={{ fontWeight: 700, wordBreak: 'break-all' }}>
                  <Link to={`/journal/${journal.journal_id}`}>{journal.title}</Link>
                </td>
                <td>
                  <Popup
                    modal={isMobile}
                    trigger={
                      <ShareButton shared={journal?.shared_with_therapist}>
                        <Tooltip id="uplaodFilesssss" text={t('shareJournalTooltip')} place="top">
                          <ShareIcon />
                        </Tooltip>
                      </ShareButton>
                    }
                    position={'left center'}
                    closeOnDocumentClick
                  >
                    {(close) => (
                      <SharePopup>
                        {isMobile && <PopupClose onClick={close}>&times;</PopupClose>}
                        <span>{t('shareJournalPopUp')}</span>
                        <Switch
                          title={t('shareJournalPopUp')}
                          isOn={journal?.shared_with_therapist}
                          handleToggle={() => shareWithTherapist(journal)}
                        />
                      </SharePopup>
                    )}
                  </Popup>
                </td>
                <td>
                  <StyledButton onClick={() => editJournal(journal.journal_id)}>
                    <Tooltip id="2332323" text={t('editJournalTooltip')} place="top">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75"
                          stroke="#00C8A0"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.875 1.87517C14.1734 1.5768 14.578 1.40918 15 1.40918C15.422 1.40918 15.8266 1.5768 16.125 1.87517C16.4234 2.17354 16.591 2.57821 16.591 3.00017C16.591 3.42213 16.4234 3.8268 16.125 4.12517L9 11.2502L6 12.0002L6.75 9.00017L13.875 1.87517Z"
                          stroke="#00C8A0"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Tooltip>
                  </StyledButton>
                </td>
                <td>
                  <Popup
                    modal
                    trigger={
                      <StyledButton>
                        <Tooltip id="delete222" text={t('deleteJournalTooltip')} place="top">
                          <svg
                            width="14"
                            height="18"
                            viewBox="0 0 14 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
                              fill="#00C8A0"
                            />
                          </svg>
                        </Tooltip>
                      </StyledButton>
                    }
                  >
                    {(close) => (
                      <div>
                        <PopupClose onClick={close}>&times;</PopupClose>
                        <Heading level={3}>{t('deleteJournal')}</Heading>
                        <p>{t('deleteJournalPara')}</p>
                        <Button onClick={() => deleteJournal(journal.journal_id)}>{t('delete')}</Button>
                      </div>
                    )}
                  </Popup>
                </td>
              </tr>
            );
          })
        : [],
    [journals, editJournal, deleteJournal, shareWithTherapist, isMobile],
  );

  const getPages = () => {
    if (journals) {
      const {
        pagination: { total_journals, page_no },
      } = journals;

      const numberOfPages = (total_journals / 10) + ((total_journals % 10) && 1)
      const pagesComponent = [];

      for (let page = 1; page <= numberOfPages; page++) {
        pagesComponent.push(
          <PageNumber key={`page-${page}`}
            activePage={page === parseInt(page_no)}
            onClick={() => {
              if (page === parseInt(page_no)) { }
              else { goToPage(page) }
            }}>
            {page}
          </PageNumber>,
        );
      }

      return pagesComponent;
    }

    return null;
  };

  return (
    <>
      <Header>
        <Heading text={t('journalEntries')} />
        {/*<SearchWrapper>*/}
        {/*  <Label htmlFor="journalSearch">Search by date:</Label>*/}
        {/*  <Input type="text" id="journalSearch" name="journalSearch" />*/}
        {/*</SearchWrapper>*/}
      </Header>
      <p>
        {t('journalDescription1')}
        <span role="img" aria-label="emoji">
          🙂
        </span>
        {t('journalDescription2')}
      </p>
      <table>
        <tbody>{tableRows}</tbody>
      </table>
      <br />
      <br />
      <TableActionsWrapper>
        {/*<Button className="secondary" onClick={createJournal}>*/}
        {/*  Create a Journal*/}
        {/*</Button>*/}
        <div className='pagination'>{getPages()}</div>
      </TableActionsWrapper>
    </>
  );
});

const JournalEntry = memo((props) => (
  <>
    <Heading text={props.currentEntry.title} wordBreak="break-all" />
    <div dangerouslySetInnerHTML={{ __html: props.currentEntry.content }} />
  </>
));

const JournalNotFound = memo(() => {
  const { t } = useTranslation();
  return (
    <>
      <Heading text={t('noEntryFound')} />
      <p>{t('journal1')}</p>
      <p>
        <Link to="/journal">{t('journal2')}</Link>
      </p>
    </>
  );
});

const Journal = ({ journals }) => {
  let currentEntry = {};

  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const editJournalAction = (id) => history.push(`/journal/edit/${id}`);
  const deleteJournalAction = (id) => dispatch(deleteJournal(id));

  useEffect(() => {
    dispatch(getJournals());
  }, [dispatch]);

  journals &&
    journals.data &&
    journals.data.forEach((entry) => {
      if (entry.journal_id === parseInt(id)) {
        currentEntry = entry;
      }
      return false;
    });

  return (
    <DefaultLayout>
      <Wrapper>
        <Sidebar />
        <Window>
          {id === undefined ? (
            <JournalList journals={journals} editJournal={editJournalAction} deleteJournal={deleteJournalAction} />
          ) : currentEntry.journal_id ? (
            <JournalEntry currentEntry={currentEntry} />
          ) : (
            <JournalNotFound />
          )}
        </Window>
      </Wrapper>
    </DefaultLayout>
  );
};

/* --------------------------------- Styles --------------------------------- */
const Wrapper = styled.div`
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: ${({ theme }) => theme.spacing};
  `}
`;

const Window = styled.div``;
const Header = styled.div`
  ${({ theme }) => theme.md`
  display: flex;
  align-items: center;
  justify-content: space-between;
`}
`;
// const SearchWrapper = styled.div`
//   display: flex;
//   align-items: center;
//
//   label {
//     font-size: 18px;
//     font-weight: ${({ theme }) => theme.fontBold};
//   }
//   input {
//     width: 200px;
//     margin-left: ${({ theme }) => theme.spacing};
//   }
// `;
const StyledButton = styled.button`
  border: 0;
  background: none;
  padding: 3px 5px;
  cursor: pointer;
`;

const TableActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pagination{
    display: flex;
  }
`;

const PageNumber = styled.div`
  height:20px;
  width:20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:3px;
  border: 1px solid ${({ activePage, theme }) => (activePage ? theme.secondaryMedium : 'black')};
  margin: 0 5px;
  color: ${({ activePage, theme }) => (activePage ? theme.secondaryMedium : 'black')};
  font-weight: ${({ activePage }) => activePage && '600'};
  cursor: pointer;
`;

const ShareButton = styled.div`
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.secondaryMedium};
  }
`;

const SharePopup = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacingSm};
`;

const PopupClose = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  box-shadow: 0 2px 7px 1px #8c8c8c;
  cursor: pointer;

  &:hover {
    background: #e7e7e7;
  }
`;

const mapStateToProps = (store) => ({
  journals: store.chatroom.journals,
});

Journal.propTypes = {
  journals: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        journal_id: PropTypes.number,
        title: PropTypes.string,
        content: PropTypes.string,
        created_at: PropTypes.string,
      }),
    ),
  }),
};

export default connect(mapStateToProps)(Journal);
