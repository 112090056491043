import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Container, Loading, Tooltip } from '../../Components/Atoms';
import { TherapyTools, Header } from '../../Components/Organisms';
import { api } from '../../Util';
import { therapyToolsLinks } from '../Chatroom/Components/Sidebar';
import { ReactComponent as purpleArrowIcon } from './purpleArrow.svg';
import { useHistory, useParams } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import config from '../../Config';

function extractContent(s) {
  var span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

const DiscountCategoriesSection = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [category, setcategory] = useState([]);
  const { id } = useParams();

  const recordPageView = async (id, discount_id) => {
    await api.patient.recordPageView(id, discount_id);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await api.patient.discounts();
      setLoading(false);
      setList(response?.data?.data);
    };
    getData();
  }, []);

  const moveTo = (link) => history.push(link);

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);
      const response = await api.patient.viewDiscountCategories();
      setLoading(false);
      setcategory(response?.data?.data);
    };
    getCategories();
  }, []);

  return (
    <>
      {loading && <Loading styles={{ position: 'fixed', backgroundColor: 'rgba(255,255,255,0.2)' }} />}
      <Header />
      <Container>
        <Wrapper>
          <SubWrapper>
            <div>
              <TherapyTools
                heading="Therapy Tools"
                links={therapyToolsLinks}
                isChatRoomActive={true}
                setModalState={() => null}
              />
            </div>
          </SubWrapper>
          <DiscountWrapper>
            <HeadingWrapper>
              <HeadingSpan>
                <BackIcon as={purpleArrowIcon} onClick={() => moveTo('/discounts')} />
                <Pathwrapper style={{ color: '#491f69', cursor: 'pointer' }} onClick={() => moveTo('/discounts')}>
                  {t('discounts')}
                </Pathwrapper>
                <Pathwrapper
                  style={{ color: '#491f69', cursor: 'pointer' }}
                  onClick={() => {
                    moveTo('/discounts/categories');
                  }}
                >
                  <Gap>/</Gap>
                  {t('categories')}
                </Pathwrapper>

                {category?.length
                  ? category.map((item, index) => {
                      if (item.id === +id) {
                        return (
                          <Pathwrapper>
                            <Gap>/</Gap>
                            {item.name}
                          </Pathwrapper>
                        );
                      }
                      return null;
                    })
                  : null}
              </HeadingSpan>
            </HeadingWrapper>
            <StyledContainer>
              {list?.length
                ? list.map((item, index) => {
                    if (item.category_id === +id) {
                      return (
                        <StyledCard key={index}>
                          <DiscountLogoWrapper
                            style={{
                              backgroundImage: `url(${item.logo})`,
                            }}
                          >
                            <DiscountLogo
                              src={item.logo}
                              alt="logo"
                              fetchpriority="high"
                            />
                          </DiscountLogoWrapper>
                          <DiscountHeaderWrapper
                            style={{
                              backgroundImage: `url(${item.header_image})`,
                              visibility: 2,
                            }}
                          >
                            <DiscountHeader
                              src={item.header_image}
                              alt="header"
                              loading="eager"
                              placeholderSrc={config.iconLogo}
                              fetchpriority="high"
                            />
                          </DiscountHeaderWrapper>

                          <Tooltip
                            id={item.description + index}
                            text={extractContent(item.description)}
                            place="top"
                            multiline={true}
                          >
                            <Title style={{ fontSize: '15px', marginTop: '30px' }}>
                              {<div dangerouslySetInnerHTML={{ __html: item.description }} />}
                            </Title>
                          </Tooltip>
                          <Section>
                            <Tooltip
                              id={item.instructions + index}
                              text={extractContent(item.instructions)}
                              place="top"
                              multiline={true}
                            >
                              <Title style={{ fontSize: '15px' }}>
                                {<div dangerouslySetInnerHTML={{ __html: item.instructions }} />}
                              </Title>
                            </Tooltip>
                            <Tooltip
                              id={item.title + index}
                              text={extractContent(item.title)}
                              place="top"
                              multiline={true}
                            >
                              <Title> {item.title}</Title>
                            </Tooltip>

                            <ButtonWrapper>
                              <a href={item.coupon} target="_blank" rel="noreferrer">
                                <Button
                                  type="primary"
                                  onClick={(id) => {
                                    recordPageView(item.category_id, item.id);
                                  }}
                                >
                                  {t('redeemNow')}
                                </Button>
                              </a>
                            </ButtonWrapper>
                          </Section>
                        </StyledCard>
                      );
                    }
                    return null;
                  })
                : null}
            </StyledContainer>
          </DiscountWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default DiscountCategoriesSection;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const DiscountLogo = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
  position: absolute;
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 110px;
  z-index: 99;
  backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const DiscountHeader = styled(LazyLoadImage)`
  height: 135px;
  width: 240px;
  backdrop-filter: blur(7px);
  object-fit: contain;
  position: relative;
  border-radius: 20px;
  border: 1px solid #00000021;
`;

const DiscountLogoWrapper = styled.div`
  border-radius: 10px;
`;

const DiscountHeaderWrapper = styled.div`
  border-radius: 20px;
`;

const Gap = styled.div`
  margin-right: 5px;
`;

const HeadingSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
`;

const BackIcon = styled.svg`
  margin-right: 10px;
  cursor: pointer;
`;

const Pathwrapper = styled.div`
  color: #491f69;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  display: flex;
`;

const StyledContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 20px;

  ${({ theme }) => theme.md`
    align-items: flex-start;
    justify-content: flex-start;
  `}
`;

const SubWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.md`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 300px;
  align-items: flex-start;
  `}
`;

const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  margin-right: 0px;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;

  ${({ theme }) => theme.md`
  margin-right: 10%;
  align-items: flex-start;
  `}
`;

const StyledCard = styled.div`
  height: 350px;
  width: 270px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #dad9da;
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-self: center;
  justify-content: space-between;
  text-align: left;
`;

const Wrapper = styled.div`
  background-color: white;
  margin-top: 30px;
  display: flex;
`;

const Title = styled.div`
  font-size: 17px;
  color: #491f69;
  font-weight: 500;
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 230px;
  overflow: hidden;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;
