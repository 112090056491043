import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Heading, Widget } from '../../Components/Atoms';
import { Link } from 'react-router-dom';

// Test data object
const data = [
  {
    id: 1,
    name: 'First task',
    due_date: moment().add(1, 'day').format('YYYY-MM-DD'), // Always tomorrow
  },
  {
    id: 2,
    name: 'Second task',
    due_date: moment().subtract(1, 'day').format('YYYY-MM-DD'), // Always yesterday
  },
  {
    id: 3,
    name: 'Third task',
    due_date: moment().format('YYYY-MM-DD'), // Always today
  },
  {
    id: 4,
    name: 'Fourth task',
    due_date: moment().subtract(30, 'day').format('YYYY-MM-DD'), // Always a month ago
  },
  {
    id: 5,
    name: 'Fifth task',
    due_date: moment().add(30, 'day').format('YYYY-MM-DD'), // Always in a month
  },
];

const Tasks = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    setTasks(data.map(({ id, name, due_date}) => {
      const dueDate = moment(due_date);
      const diff = moment().diff(dueDate, 'days');
      const date = diff >= 2 || diff <= -2 ? dueDate.format('YYYY-MM-DD') : dueDate.calendar().split(' ')[0];

      return { id, name, date };
    }));
  }, []);

  return (
    <Widget>
      <StyledHeader>
        <Heading level={4}>Task Manager</Heading>
        <Heading level={4} className="date">Today: {moment().format('YYYY-MM-DD')}</Heading>
      </StyledHeader>
      <table className="striped">
        <thead>
        <tr>
          <th>Task</th>
          <th>Due Date</th>
        </tr>
        </thead>
        <tbody>
        {tasks.map(({ id, name, date }) => (
          <tr key={id}>
            <td>{name}</td>
            <td>{date}</td>
          </tr>
        ))}
        </tbody>
      </table>
      <StyledLink to={'/t/dashboard/tasks'}>+ Add Task</StyledLink>
    </Widget>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .date {
    display: none;
  }
  
  ${({ theme }) => theme.md`
    justify-content: space-between;
    
    .date {
      display: block;
    }
  `}
`;
StyledHeader.displayName = 'StyledHeader';

const StyledLink = styled(Link)`
  margin-top: ${({ theme }) => theme.spacingMd};
  display: inline-block;
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fontMedium};
  color: ${({ theme }) => theme.primaryMedium};
`;
StyledLink.displayName = 'StyledLink';

export default Tasks;
