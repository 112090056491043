import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useGoogleLogin } from '@react-oauth/google';
import { Store } from 'react-notifications-component';
import { SignUpButton, SocialIcon } from './signUpComponent';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GoogleIcon } from '../../Assets/SignUp/google.svg';
import axios from "axios";

const GoogleInitComponent = ({ buttonText, initAuth }) => {
  const { t } = useTranslation();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { data } = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo?access_token=' + tokenResponse.access_token);
        let payload = {
          googleId: data?.id,
          email: data?.email,
          name: data?.name
        }
        initAuth(payload, 'google')
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        handleError(error)
      }
    },
    onError: (w) => {
      console.log("Login Failed", w)
      handleError(w)
    }
  });

  const handleError = (data) => {
    Store.addNotification({
      title: t('GoogleSignupDisabled'),
      message: (
        <div>
          <p>{t('GoogleSignUpDisabledInIncognitoMode')}</p>
        </div>
      ),
      type: 'danger',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 8000,
        onScreen: true,
      },
    });
  }

  return (
    <div>
      <SignUpButton onClick={login}>
        <SocialIcon as={GoogleIcon} />
        <span>{buttonText}</span>
      </SignUpButton>
    </div>
  );
};

GoogleInitComponent.propTypes = {
  buttonText: PropTypes.string.isRequired,
};

export default memo(GoogleInitComponent);
