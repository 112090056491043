import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router';

import { getMoodQuestions, getProfile, saveMoodQuestions } from '../../Actions/patient.actions';

import { DefaultLayout } from '../../Layout';
import { Button, Loading } from '../../Components/Atoms';
import { PageHeading } from '../../Components/Molecules';
import EnterpriseMoodBox from './enterpriseMoodbox';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MoodIcon } from '../../Assets/Icons/Mood.svg';
// import Tooltip from '../../Components/Atoms/Tooltip/Tooltip';
import TherapyFeedback from '../../Components/Organisms/TherapyTrackerTable/therapyFeedback';
import { getTherapyJourney, getActiveChatRooms } from '../../Actions/chatroom.actions';

// const RANGE_MAX = 5;

const MoodBox = ({ moodBox, therapyJourney, chatRoomID, profile, chatroomRooms, status }) => {
  const [moodScores, setScores] = useState(null);
  const [moodBoxError, showError] = useState('');
  const [onSaveLoading, setOnSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTherapyLoading, setIsTherapyLoading] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (values, actions) => {
      const keys = Object.keys(values);
      const isInValidForm = keys.some((key) => !values[key].score);

      if (isInValidForm) {
        return showError(t('moodBoxQuestions'));
      }

      showError('');
      actions.setSubmitting(false);
      setOnSaveLoading(true);
      dispatch(saveMoodQuestions({ data: moodScores })).then(() => {
        dispatch(getProfile()).then(() => history.push(profile && profile['B2B'] ? '/chatroom' : '/analytics'));
      });
    },
    [moodScores, dispatch, profile, history],
  );

  const handleChange = useCallback(
    (name, val) => {
      const newMoodScores = [...moodScores];
      newMoodScores[parseInt(name)].score = parseInt(val);
      showError('');
      setScores(newMoodScores);
    },
    [moodScores],
  );

  // const rangeScale = () => {
  //   const range = [...Array(RANGE_MAX + 1).keys()];
  //
  //   return (
  //     <SliderScale>
  //       {range.map((num) => (
  //         <ScaleValue key={num}>{num === 0 || num === RANGE_MAX ? num : null}</ScaleValue>
  //       ))}
  //     </SliderScale>
  //   );
  // };

  useEffect(() => {
    if (moodBox?.length) {
      const scores = moodBox.map((mood) => ({
        question_id: mood.id,
        score: mood.score ? mood.score : 0,
      }));
      setScores(scores);
    }
    setIsLoading(false);
  }, [moodBox]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getMoodQuestions());
  }, [dispatch]);

  useEffect(() => {
    if (chatroomRooms?.length) {
      fetchTherapyJourney();
    }
  }, [dispatch, chatroomRooms]);

  useEffect(() => {
    if (profile?.moodbox_page_visited) {
      history.push('/chatroom');
    }
    if (status !== 'success') {
      return;
    }
    if (profile?.id && (chatroomRooms === null || chatroomRooms?.length === 0)) {
      history.push('/welcome/therapists');
    }
  }, [profile, chatroomRooms, history]);

  useEffect(() => {
    if (status !== 'success') {
      dispatch(getActiveChatRooms());
    }
  }, [status]);

  const fetchTherapyJourney = () => {
    dispatch(getTherapyJourney(chatroomRooms[0]?.chatroom_id, 'patient', setIsTherapyLoading))
      .then((res) => {
        // setIsTherapyLoading(false);
      })
      .catch((err) => {
        setIsTherapyLoading(false);
      });
  };

  const sliderOptions = useCallback(
    (q, index) => {
      const optionsRev = [...(q?.answers || [])].reverse();
      return (
        <InputWrapper key={q.id}>
          <StyledLabel htmlFor={q.id}>
            {index + 1}. {q.question}
          </StyledLabel>
          {/* <Tooltip id={`circle-${index}`} text="Select between 1-5" place="left">
          <StyledInput
            id={q.id}
            name={index}
            type="range"
            min={0}
            max={RANGE_MAX}
            step="1"
            value={moodScores[index].score}
            onChange={handleChange}
          />
        </Tooltip> */}
          <MoodOptions>
            {optionsRev?.map((val, ind) => (
              <Button
                key={(index + val?.title).toString()}
                type="button"
                style={{
                  margin: window.screen.width > 767 ? 0 : '10px',
                  background: moodScores[index].score === ind + 1 ? '#491F69' : '#faf3ff',
                  color: moodScores[index].score === ind + 1 ? '#fff' : '#491F69',
                  fontSize: '16px',
                  border: '1px solid #9d9d9d',
                }}
                onClick={() => handleChange(index, ind + 1)}
              >
                {val?.title}
              </Button>
            ))}
          </MoodOptions>
          {/* {rangeScale(q.tags)} */}
        </InputWrapper>
      );
    },
    [handleChange, moodScores],
  );

  if (isLoading || isTherapyLoading) {
    return <Loading />;
  }

  if ((!moodBox || moodBox.length === 0) && !isLoading) {
    return (
      <DefaultLayout>
        <Wrapper>
          <PageHeading icon={MoodIcon} name={t('noMoodQuestions')} />
        </Wrapper>
      </DefaultLayout>
    );
  }

  if (profile && profile['B2B']) {
    return (
      <DefaultLayout>
        <Wrapper>{!isLoading && <EnterpriseMoodBox moodBox={moodBox} profile={profile} />}</Wrapper>
      </DefaultLayout>
    );
  }

  return therapyJourney?.submitFeedback || therapyJourney?.submitFinalFeedback ? (
    <DefaultLayout>
      <Wrapper>
        <TherapyFeedbackWrapper>
          <TherapyFeedback isFinal={therapyJourney.submitFinalFeedback} chatRoomId={chatRoomID} />
        </TherapyFeedbackWrapper>
      </Wrapper>
    </DefaultLayout>
  ) : (
    (!isLoading || !isTherapyLoading) && therapyJourney?.data?.length && (
      <DefaultLayout>
        <Wrapper>
          <Fragment>
            <PageHeading icon={MoodIcon} name={t('moodBox')} />
            <p className="lead">
              <b>{t('pleaseNote')}:</b> {t('providedResponse')}
            </p>
            <br />
            <br />
            <Formik
              enableReinitialize
              initialValues={{
                ...moodScores,
              }}
              onSubmit={handleOnSubmit}
            >
              <Form>
                {moodBox && moodScores && moodBox.map((q, index) => sliderOptions(q, index))}
                {moodBoxError && <div style={{ color: '#e00000', paddingBottom: 20 }}>{moodBoxError}</div>}
                <Button type="submit" className="secondary" disabled={onSaveLoading} loading={onSaveLoading}>
                  {t('save')}
                </Button>
              </Form>
            </Formik>
          </Fragment>
        </Wrapper>
      </DefaultLayout>
    )
  );
};

const MoodOptions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const TherapyFeedbackWrapper = styled.div`
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  form {
    text-align: left;
  }
`;

const mapStateToProps = (store) => {
  // console.log(store)
  return {
    moodBox: store?.user?.moodBox,
    therapyJourney: store?.chatroom?.therapyJourney,
    chatRoomID: store?.chatroom?.chatRoomID,
    profile: store?.user?.profile,
    chatroomRooms: store?.chatroom?.rooms,
    status: store?.chatroom?.status,
  };
};

/* --------------------------------- Styles --------------------------------- */

const Wrapper = styled.div`
  text-align: center;
  margin: auto;
  max-width: 700px;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-bottom: 30px;
  text-align: left;
`;
const StyledLabel = styled.label`
  display: block;
  font-weight: ${({ theme }) => theme.fontMedium};
  font-size: 17px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacingLg};
  ${({ theme }) => theme.md`
    font-size: 20px;
  `}
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;
// const StyledInput = styled(Field)`
//   height: auto;
// `;
// const SliderScale = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding: 0 12px;
// `;
//
// const ScaleValue = styled.div`
//   width: 1px;
//   height: 7px;
//   line-height: 40px;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   background: gray;
//   font-weight: ${({ theme }) => theme.fontMedium};
//   color: ${({ theme }) => theme.black};
//   font-size: 17px;
// `;

export default connect(mapStateToProps)(MoodBox);
