const commonAccountRoutes = (t) => [
  {
    id: 1,
    title: t("referAfriend"),
    url: '/referfriend',
  },
  {
    id: 2,
    title: t("savedMessages"),
    url: '/saved-messages',
  },
  {
    id: 3,
    title: t("sharedFiles"),
    url: '/shared-files',
  },
];

export const PatientMenuLinks = (t) => [
  {
    id: 0,
    title: t("account"),
    url: '/account',
    subNav: [
      {
        id: 0,
        title: t("accountDetails"),
        url: '/account',
      },
      {
        id: 1,
        title: t("manageSubscription"),
        url: '/account/subscription/manage',
      },
      {
        id: 2,
        title: t("notifications"),
        url: '/account/notifications',
      },
      {
        id: 3,
        title: t("contactSupport"),
        url: '/contact',
      },
    ],
  },
  ...commonAccountRoutes(t),
];

export const GympassPatientMenuLinks = (t) => [
  {
    id: 0,
    title: t("account"),
    url: '/account',
    subNav: [
      {
        id: 0,
        title: t("accountDetails"),
        url: '/account',
      },

      {
        id: 2,
        title: t("notifications"),
        url: '/account/notifications',
      },
      {
        id: 3,
        title: t("contactSupport"),
        url: '/contact',
      },
    ],
  },
  ...commonAccountRoutes(t),
];

export const TherapistMenuLinks = (t) => [
  {
    id: 0,
    title: t("viewprofile"),
    url: '/t/profile',
  },
  {
    id: 1,
    title: t("myAccount"),
    url: '/t/account',
  },
  {
    id: 4,
    title: t("notifications"),
    url: '/t/dashboard/notifications-settings',
  },
  {
    id: 3,
    title: t("contactSupport"),
    url: '/t/contact',
  },
  // {
  //   id: 2,
  //   title: 'Settings',
  //   url: '/',
  // },
  // {
  //   id: 4,
  //   title: 'Sign Out',
  //   url: '/t/logout',
  // },
];

export const TherapistDashboardLinks = (t) => [
  {
    id: 0,
    title: t("dashboard"),
    url: '/t/dashboard',
    exact: true,
    description: t("viewYourDashboard"),
  },
  // {
  //   id: 15,
  //   title: 'Connect to Teams',
  //   url: '/t/teams',
  //   exact: true,
  //   description: 'Connect to Teams',
  // },
  {
    id: 1,
    title: t("worksheets"),
    description: t("downloadWorksheets"),
    children: [
      {
        title: t("angerAddiction"),
        url: '/t/dashboard/worksheets/anger-and-addiction',
      },
      {
        title: t("depressionAnxiety"),
        url: '/t/dashboard/worksheets/depression-and-anxiety',
      },
      {
        title: t("goalsReviews"),
        url: '/t/dashboard/worksheets/goals-and-reviews',
      },
      {
        title: t("miscellaneous"),
        url: '/t/dashboard/worksheets/miscellaneous',
      },
    ],
  },
  {
    id: 2,
    title: t("patients"),
    url: '/t/dashboard/patients',
    description: t("manageAndSupportPatients"),
  },
  {
    id: 3,
    title: t("activity"),
    url: '/t/dashboard/activity',
    description: t("trackYourActivity"),
  },
  {
    id: 4,
    title: t("calendar"),
    url: '/t/dashboard/calendar',
    description: t("calendarData"),
  },
  {
    id: 5,
    title: t("resources"),
    description: t("downloadResources"),
    children: [
      {
        title: t("starterPack"),
        url: '/t/dashboard/resources/starter-pack',
      },
      {
        title:t("guidelinesStartingTherapy"),
        url: '/t/dashboard/resources/guidelines-for-starting-therapy',
      },
      {
        title: t("TherapistTermsConditions"),
        url: '/t/dashboard/resources/terms-and-conditions',
      },
    ],
  },
  {
    id: 7,
    title: t("therapistTrainingVideo"),
    url: '/t/dashboard/resources/training-video',
  },
  {
    id: 6,
    title: t("wellbeingresourceCentre"),
    url: '/t/well-being',
  },
];

export const NixyTherapistDashboardLinks = (t) => [
  {
    id: 0,
    title: t("dashboard"),
    url: '/t/dashboard',
    exact: true,
    description: t("viewYourDashboard"),
  },
  {
    id: 1,
    title: t("worksheets"),
    description: t("downloadWorksheets"),
    children: [
      {
        title: t("angerAddiction"),
        url: '/t/dashboard/worksheets/anger-and-addiction',
      },
      {
        title: t("depressionAnxiety"),
        url: '/t/dashboard/worksheets/depression-and-anxiety',
      },
      {
        title: t("goalsReviews"),
        url: '/t/dashboard/worksheets/goals-and-reviews',
      },
      {
        title: t("miscellaneous"),
        url: '/t/dashboard/worksheets/miscellaneous',
      },
    ],
  },
  {
    id: 2,
    title: t("patients"),
    url: '/t/dashboard/patients',
    description: t("manageAndSupportPatients"),
  },
  {
    id: 3,
    title: t("activity"),
    url: '/t/dashboard/activity',
    description: t("trackYourActivity"),
  },
  {
    id: 4,
    title: t("calendar"),
    url: '/t/dashboard/calendar',
    description: t("calendarData"),
  }
];

export const CompanyDashboardLinks = (t) =>[
  {
    id: 0,
    title: t("dashboard"),
    url: '/c/dashboard',
    exact: true,
  },
  {
    id: 1,
    title: t("employees"),
    url: '/c/dashboard/employees',
  },
  {
    id: 2,
    title: t("billing"),
    url: '/c/dashboard/billing',
  },
  // {
  //   id: 2,
  //   title: 'Analytics',
  //   url: '/c/dashboard/Analytics',
  // },
  // {
  //   id: 3,
  //   title: 'Settings',
  //   url: '/c/settings',
  // },
];

export const CompanyMenuLinks = (t) => [
  {
    id: 0,
    title: t("viewAccount"),
    url: '/c/account',
  },
  // {
  //   id: 1,
  //   title: 'My Account',
  //   url: '/t/account',
  // },
  // {
  //   id: 2,
  //   title: 'Settings',
  //   url: '/',
  // },
  // {
  //   id: 3,
  //   title: 'Sign Out',
  //   url: '/c/logout',
  // },
];
