export const podcastPlayLists = [
  {
    name: 'Mindfulness',
    id: '2Z5aHxWfQdmlaYUuKj8L6Y',
  },
  {
    name: 'Addiction',
    id: '15v4edcnDWOo0BECFm7tCH',
  },
  {
    name: 'Depression',
    id: '244eOBehHtNV86NcXbtmi0',
  },
  {
    name: 'Positivity',
    id: '5JSQb2oRZWK7iCS7HHtB3U',
  },
  {
    name: 'Bipolar Disorder',
    id: '2XVm1rfQOCqhvdAyTSbTdu',
  },
  {
    name: 'Grief',
    id: '7zr0uqSRMdsZvkswbhsdG8',
  },
  {
    name: 'PTSD',
    id: '3iHyeiwXwh7vOJ1roxJRQa',
  },
  {
    name: 'Eating Disorders and Body Image',
    id: '00B07FBs770MDBfxXZSnf6',
  },
  {
    name: 'General Mental Health',
    id: '7H9pjDbOX42EFj6cdYQ3XI',
  },
  {
    name: 'Work/Stress',
    id: '0ZwwU5jmFwC7KQqm4xA4Ys',
  },
  {
    name: 'Mental Health Professionals',
    id: '3fAUqnxme9KKth5e3NVOP4',
  },
  {
    name: 'Wellness',
    id: '3BOKodxRm3ZeeNNSqzM39C',
  },
  {
    name: 'Anxiety',
    id: '0B6aHYS2XspoGwdF4fnmeW',
  },
];

export const calmMusicPlayLists = [
  {
    name: 'Calm and Relaxing Music',
    id: '5jbhwZM0Kp27Rgp0CxMAyb',
  },
  {
    name: 'Yoga and Meditation',
    id: '37i9dQZF1DX9uKNf5jGX6m',
  },
  {
    name: 'Mindfull Meditation',
    id: '3mVZ8ycmwgkwcZfVS03r8N',
  },
];

export const recipesPlayLists = [
  {
    name: 'Healthy Recipes',
    id: '5SieqSBEu51KgbOJ6ArOXF',
  },
  {
    name: 'Comforting Cooking',
    id: '2GznLe2PhWzsrV6Hxj9I4t',
  },
];
