import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import api from '../../Util/api';
import { aboutMeLowerLimit, aboutMeUpperLimit } from '../../Constants/other';

import { AuthLayout } from '../../Layout';
import { Button, Heading, FormGroup, Label, HelpText, ProgressBar, Checkbox } from '../../Components/Atoms';

const Step6 = () => {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const therapistProfile = useSelector((state) => state.user.profile);
  const initialValues = useMemo(
    () => ({
      about_me: therapistProfile?.about_me || '',
      exp_summary: therapistProfile?.exp_summary || '',
      city: JSON.stringify({ id: therapistProfile?.city_id || '', name: therapistProfile?.city || '' }),
      country: JSON.stringify({ id: therapistProfile?.country_id || '', name: therapistProfile?.country || '' }),
      address: therapistProfile?.address || '',
      post_code: therapistProfile?.post_code || '',
      trial_account: 0,
    }),
    [therapistProfile],
  );

  const history = useHistory();

  const nextStep = () => history.push('/t/signup/step-7');

  const getCountries = useCallback(async () => {
    setCountries((await api.others.getCountriesList()).data.data);
  }, []);

  const getCities = async (countyID) => {
    setCities((await api.others.getCitiesList(countyID)).data.data);
  };

  const handleOnSubmit = async ({ country, city, ...values }, { setSubmitting }) => {
    const countryData = country ? JSON.parse(country) : {};
    const cityData = city ? JSON.parse(city) : {};
    const data = new FormData();
    Object.keys(values).forEach((key) => data.append(key, values[key]));
    if (countryData.name) data.append('country', countryData.name);
    if (countryData.id) data.append('country_id', countryData.id);
    if (cityData.name) data.append('city', cityData.name);
    if (cityData.id) data.append('city_id', cityData.id);

    try {
      await api.therapist.updateProfile(data);
      nextStep();
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCountryChange = async (value, setFieldValue) => {
    const { id } = JSON.parse(value);
    setFieldValue('country', value);
    await getCities(id);
  };

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (therapistProfile && therapistProfile.country_id) {
      getCities(therapistProfile.country_id);
    }
  }, [therapistProfile]);

  const validationSchema = Yup.object().shape({
    about_me: Yup.string()
      .min(aboutMeLowerLimit, `Please enter at least ${aboutMeLowerLimit} characters`)
      .max(aboutMeUpperLimit, 'About Me text is too long'),
  });

  return (
    <AuthLayout>
      <Wrapper>
        <Heading>Bio</Heading>
        <ProgressBar progress="60" style={{ margin: '20px auto' }} />
        <p>
          Your summary and bio will be viewable for clients that are matched with you. We therefore recommend you try to
          include the following information:
        </p>
        <p>1. High level overview about your experience, specialist areas and modalities. </p>
        <p>
          2. Why the topic of Mental health is important to you or Why you decided to become a Mental Health therapist
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
          enableReinitialize
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <FormGroup>
                <Label>Experience Summary</Label>
                <Field
                  type="text"
                  id="exp_summary"
                  name="exp_summary"
                  className={'form-control' + (errors.exp_summary && touched.exp_summary ? ' is-invalid' : '')}
                />
                <ErrorMessage name="exp_summary" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Label>About Me</Label>
                <Field
                  as="textarea"
                  id="about_me"
                  name="about_me"
                  placeholder="Write one or two paragraphs giving a brief summary of your experience and qualifications and perhaps why you opted to become a therapist."
                  className={errors.about_me && touched.about_me ? ' is-invalid' : ''}
                />
                <ErrorMessage name="about_me" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Label>Address Line 1</Label>
                <Field
                  type="text"
                  id="address"
                  name="address"
                  className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}
                />
                <ErrorMessage name="address" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Label>Post Code</Label>
                <PostcodeField
                  type="text"
                  id="post_code"
                  name="post_code"
                  className={'form-control' + (errors.post_code && touched.post_code ? ' is-invalid' : '')}
                />
                <ErrorMessage name="post_code" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field
                  as="select"
                  id="country"
                  name="country"
                  className={'form-control' + (errors.country && touched.country ? ' is-invalid' : '')}
                  onChange={(e) => handleCountryChange(e.target.value, setFieldValue)}
                >
                  <option disabled value={JSON.stringify({ id: '', name: '' })} label="Country" />
                  {countries.map(({ id, name }) => (
                    <option key={id} value={JSON.stringify({ id, name })}>
                      {name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="country" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field
                  as="select"
                  id="city"
                  name="city"
                  type="text"
                  className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')}
                  disabled={!cities.length}
                >
                  <option disabled value={JSON.stringify({ id: '', name: '' })} label="City" />
                  {cities.map(({ id, name }) => (
                    <option key={id} value={JSON.stringify({ id, name })}>
                      {name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="country" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <CheckboxWrapper>
                  <Checkbox
                    label="I agree to offer 20 minutes of free access to messaging therapy for each potential client assigned to me."
                    name="trial_account"
                    onChange={() => setFieldValue('trial_account', values.trial_account === 0 ? 1 : 0)}
                  />
                </CheckboxWrapper>
                <HelpText style={{ padding: '8px 16px' }}>
                  Offering a period of 20 minutes of free access to your counsel will greatly increase the probability
                  of being matched with a patient. If you <b>do not</b> wish to offer any free access please uncheck
                  this box.
                </HelpText>
              </FormGroup>

              <ButtonWrapper>
                <Button variant="muted" onClick={nextStep}>
                  Skip
                </Button>
                <Button variant="primary" type="submit">
                  Continue
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </AuthLayout>
  );
};

const CheckboxWrapper = styled.div`
  background: ${({ theme }) => theme.primaryAlpha20};
  padding: ${({ theme }) => theme.spacing};
  border-radius: ${({ theme }) => theme.spacingSm};

  label {
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 32px;
  }
`;
const Wrapper = styled.div`
  max-width: 500px;
  margin: auto;
`;
const PostcodeField = styled(Field)`
  max-width: 150px;
`;

export default Step6;
