import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import moment from 'moment';

import { addSchedule, copySchedule, deleteScheduledSlot, getProfile } from '../../Actions/therapist.actions';

import CalendarComponent from '../../Components/Atoms/Calendar/Calendar';
import ModalComponent from '../../Components/Atoms/Modal/Modal';
import Button from '../../Components/Atoms/Button/Button';
import Select from '../../Components/Atoms/Select/Select';

import { getCalendarEvents } from '../../Util/calendar';

import { useHistory } from 'react-router';
import { getToLocalSchedulesUser } from '../../Selectors/schedules.selector';
import Tooltip from '../../Components/Atoms/Tooltip/Tooltip';
import notify from '../../Util/notify';

const DashboardCalendar = ({ schedules, chatRooms }) => {
  const [isAvailabilityEditing, setIsAvailabilityEditing] = useState(false);
  const [booked, setBooked] = useState([]);
  const [availability, setAvailability] = useState([]);

  const [calendarData, setCalendarDate] = useState(new Date());

  // States for create Therapist availability
  const [modalState, setModalState] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  // States for copy functionalities
  const [copyOptions, setCopyOptions] = useState({
    start_date: moment().startOf('week').format('YYYY-MM-DD'),
    end_date: moment().endOf('week').format('YYYY-MM-DD'),
    period: 'week',
    repeat: 1,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const navigateToDate = useCallback((date) => setCalendarDate(date), []);

  const onSelectSlot = useCallback((e) => {
    setModalState(true);
    setSelectedSchedule(e);
  }, []);

  const closeModal = useCallback(() => {
    setModalState(false);
    setSelectedSchedule(null);
  }, []);

  const createAvailability = useCallback(() => {
    const momentDateStart = moment(selectedSchedule.start).utc();
    const momentDateEnd = moment(selectedSchedule.end).utc();

    dispatch(
      addSchedule({
        start_date: momentDateStart.format('YYYY-MM-DD'),
        start_time: momentDateStart.format('HH:mm'),
        end_time: momentDateEnd.format('HH:mm'),
      }),
    ).then(() => {
      dispatch(getProfile());
      setModalState(false);
    });
  }, [selectedSchedule, dispatch]);

  const deleteSlot = (id) => dispatch(deleteScheduledSlot(id));

  const toggleEdit = useCallback(() => {
    setIsAvailabilityEditing(!isAvailabilityEditing);
  }, [isAvailabilityEditing]);

  const onSelectEvent = (e) => {
    const roomId = chatRooms?.length
      ? chatRooms.find((room) => room.patient_id === e?.slot?.patient_id)?.chatroom_id
      : null;

    roomId && history.push(`/t/chatroom/${roomId}`);
  };

  // useEffect: 1. convert stringDates to Date() | 2.Sorting dates | 3.Grouping slots | 4. Pass to calendar
  useEffect(() => {
    if (schedules && schedules.length >= 0) {
      const [availabilityEvents, bookedEvents] = getCalendarEvents(schedules, isAvailabilityEditing);

      if (availabilityEvents.length >= 0) setAvailability(availabilityEvents);
      if (bookedEvents.length >= 0) setBooked(bookedEvents);
    }
  }, [schedules, isAvailabilityEditing]);

  const onRangeChange = (e) => {
    const firstDayOfWeek = e[0];
    const lastDayOfWeek = e[e.length - 1];
    const format = 'YYYY-MM-DD';

    setCopyOptions((state) => ({
      ...state,
      start_date: moment(firstDayOfWeek).format(format),
      end_date: moment(lastDayOfWeek).format(format),
    }));
  };

  const periodOnChange = ({ value }) => {
    if (value === 'month') setCopyOptions((state) => ({ ...state, period: 'week', repeat: 4 }));
    else setCopyOptions((state) => ({ ...state, period: value, repeat: 1 }));
  };

  const copyAvailability = () => {
    const { start_date, end_date, period, repeat } = copyOptions;
    dispatch(copySchedule({ start_date, end_date, period, repeat })).then((res) => {
      if (res?.status === 'success') {
        dispatch(getProfile());
        notify('Copy was done', `Repeat for ${repeat} ${period}(s) was set!`, 'success');
      } else notify('Something went wrong', res?.message, 'danger');
    });
  };

  return (
    <>
      {modalState && (
        <ModalComponent open={modalState} closeModal={closeModal}>
          <TimeRow>
            <TimeWrapper>
              <h4>Start:</h4>
              <p>{moment(selectedSchedule?.start).format('YYYY-MM-DD HH:mm:ss')}</p>
            </TimeWrapper>
            <TimeWrapper>
              <h4>End:</h4>
              <p>{moment(selectedSchedule?.end).format('YYYY-MM-DD HH:mm:ss')}</p>
            </TimeWrapper>
          </TimeRow>
          <Button onClick={createAvailability}>Create availability</Button>
        </ModalComponent>
      )}
      <StyledTabs>
        <TabList>
          <Tooltip id="BookedEvents" text="View your therapy booked video sessions" place="top">
            <Tab>My Booked events</Tab>
          </Tooltip>
          <Tooltip
            id="AvailabilityEvents"
            text="View & set your availability. Remember to keep your availability up to date"
            place="top"
          >
            <Tab>My Availability</Tab>
          </Tooltip>
        </TabList>

        <TabPanel>
          <CalendarComponent
            date={calendarData}
            events={booked}
            type="booked"
            showMultiDayTimes={true}
            onSelectEvent={onSelectEvent}
            onNavigate={navigateToDate}
            // default library settings
            selectable={true}
            style={{ height: '1800px' }}
          />
        </TabPanel>
        <TabPanel>
          <StyledButton className="info small" onClick={toggleEdit}>
            {isAvailabilityEditing ? 'Finish Editing' : 'Edit Availability'}
          </StyledButton>
          <CopyWrapper>
            <Select
              styles={{
                control: (base) => ({ ...base, width: 192.56, height: 35 })
              }}
              menuPlacement="auto"
              menuPosition="fixed"
              options={[
                { label: 'For next Week', value: 'week' },
                { label: 'For next 4 weeks', value: 'month' },
              ]}
              placeholder="Copy Schedules"
              onChange={periodOnChange}
            />
            <Button style={{ marginLeft: 10 }} onClick={copyAvailability}>
              Repeat
            </Button>
          </CopyWrapper>
          <CalendarComponent
            date={calendarData}
            events={availability}
            type="availability"
            showMultiDayTimes={true}
            onSelectSlot={onSelectSlot}
            onNavigate={navigateToDate}
            onRangeChange={onRangeChange}
            editState={isAvailabilityEditing}
            deleteSlot={deleteSlot}
            // default library settings
            selectable={true}
            style={{ height: '1800px' }}
          />
        </TabPanel>
      </StyledTabs>
    </>
  );
};

const mapStateToProps = (store) => ({
  schedules: getToLocalSchedulesUser(store),
  chatRooms: store.user.chatRooms,
});

const CopyWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 700px) {
    margin-top: 57px;
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 0;
  top: -10px;
`;

const StyledTabs = styled(Tabs)`
  position: relative;

  @media screen and (max-width: 700px) {
    ${StyledButton} {
      top: 50px;
    }
    .rbc-toolbar {
      margin-top: 30px;
    }
  }
`;

const TimeRow = styled.div`
  display: flex;
  align-items: center;
`;
const TimeWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacingLg};
`;

export default connect(mapStateToProps)(DashboardCalendar);
