import React, { memo } from 'react';

const Note = ({ note }) => {
  return (
    <>
      <h3>Therapy Journey &raquo; In-Take &raquo; Note Title</h3>
      <br />
      <br />
      <h3>Note Title</h3>
      <p className="lead">Date:</p>
      <p>{note?.note}</p>
    </>
  );
};

export default memo(Note);
