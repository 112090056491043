import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './storeNew';
import { GoogleOAuthProvider } from "@react-oauth/google";

import './i18nextConf';

import './Util/axiosConfig';

import './Styles/index.scss';
import 'reactjs-popup/dist/index.css';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

import LogRocket from 'logrocket';
LogRocket.init('ylmno5/supportroom');

// Browser Notification and Audio - CONFIG
// export const notificationPermissionResult = 'Notification' in window ? Notification?.permission : null;
const GOOGLE_CLIENT_ID = '1021337303053-tnrluksl1836u5q7uuquis5r1g2vrkol.apps.googleusercontent.com';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();