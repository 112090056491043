import React from 'react';
import { Heading } from '../../Components/Atoms';
import { PaymentPlans } from '../../Components/Organisms';
import {PaymentPlansData} from '../../Data/PaymentPlansData';
import { useTranslation } from 'react-i18next';

const SubscriptionPlan = (props) => {
  const { t } = useTranslation();
  const [singlePlan] = PaymentPlansData(t);
  return (
    <>
      <Heading text={t("yourSubscriptionPlan")} />
      <PaymentPlans
        data={[singlePlan]}
        buttonText={t("changePlan")}
        onClick={() => {
          props.history.push('/account/subscription/manage');
        }}
      />
    </>
  );
};

export default SubscriptionPlan;
