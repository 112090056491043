import axios from 'axios';
import api from '../Util/api';
import { LOGIN, LOGOUT, ERRORS, MAP_STORAGE_TO_REDUX, TOGGLE_SMS_CODE_REQUEST } from '../Constants/actionTypes';

export const login = (userType, data, history, url, timeout = false) => (dispatch) => {
  return api.auth
    .login(userType, data)
    .then((res) => {
      if (res.data?.data?.status_2fa === 'code_sent') {
        dispatch(toggleSmsCodeRequestModal());
      } else {
        if (res.data.status === 'success') {
          localStorage.setItem(
            'loginInfo',
            JSON.stringify({
              loggedIn: true,
              access_token: res.data.access_token,
              refresh_token: res.data.refresh_token,
              expires_at: res.data.expires_at,
              role: res.data.role,
            }),
          );

          axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;

          const setupLogin = () => {
            dispatch({
              type: LOGIN,
              payload: { ...(res?.data || {}), loggedIn: true },
            });

            history.push(url);
          };

          if (!timeout) setupLogin();
          else setTimeout(() => setupLogin(), 3000);
        }
      }
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};

export const adminLogin = (data, history, url, timeout = false) => (dispatch) => {
  return api.auth
    .adminLogin(data)
    .then((res) => {
      if (res.data.status === 'success') {
        localStorage.setItem(
          'loginInfo',
          JSON.stringify({
            loggedIn: true,
            access_token: res.data.access_token,
            refresh_token: res.data.refresh_token,
            expires_at: res.data.expires_at,
            role: res.data.role,
          }),
        );

        axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;

        const setupLogin = () => {
          dispatch({
            type: LOGIN,
            payload: { ...(res?.data || {}), loggedIn: true },
          });

          history.push(url);
        };

        if (!timeout) setupLogin();
        else setTimeout(() => setupLogin(), 3000);
      }
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  axios.defaults.headers.common.Authorization = 'Bearer ';

  localStorage.removeItem('loginInfo');
};

export const unauthorizedLogout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  axios.defaults.headers.common.Authorization = 'Bearer ';

  window.location.reload();

  localStorage.removeItem('loginInfo');
};

export const mapStorageToRedux = () => (dispatch) => {
  const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
  dispatch({
    type: MAP_STORAGE_TO_REDUX,
    payload: { ...loginInfo },
  });
};

export const changePassword = (data) => (dispatch) =>
  api.auth
    .changePassword(data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });

export const toggleSmsCodeRequestModal = () => (dispatch) => dispatch({ type: TOGGLE_SMS_CODE_REQUEST });

export const isPartner = (subdomain) => () => api.auth.isPartner(subdomain);
