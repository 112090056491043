import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Heading } from '../../Components/Atoms';
import { Widget } from '../../Components/Atoms';
import Loading from '../../Components/Atoms/Loading/Loading';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { getProfile as getTherapistProfile, getSpecialisms, getTherapistTools } from '../../Actions/therapist.actions';

const DetailItem = memo((props) => {
  function NewlineText(props) {
    const text = props.text.toString();
    return text.split('\n').map((str, index) => <p key={`str-${index}`}>{str}</p>);
  }

  return (
    <DetailItemWrapper>
      <Heading level={4} text={props.label} />
      <ValueWrapper>{props.value ? <NewlineText text={props.value} /> : <p>[Not set...]</p>}</ValueWrapper>
    </DetailItemWrapper>
  );
});

const Details = ({ profile }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [specialisms, setSpecialisms] = useState([]);
  const [tools, setTools] = useState([]);

  const getTherapistData = useCallback(async () => {
    const specialisms = (await dispatch(getSpecialisms()))?.data || [];
    const tools = (await dispatch(getTherapistTools()))?.data || [];
    setSpecialisms(specialisms.map(({ specialism_name }) => specialism_name).join(', '));
    setTools(tools.map(({ tool_name }) => tool_name).join(', '));
  }, [dispatch]);

  useEffect(() => {
    getTherapistData();
    dispatch(getTherapistProfile());
  }, [dispatch, getTherapistData]);

  return (
    <>
      <Heading text="My Account" />
      <Widget>
        {!profile ? (
          <Loading />
        ) : (
          <>
            <DetailsWrapper>
              <DetailItem label="First Name" value={profile.first_name} />
              <DetailItem label="Last Name" value={profile.last_name} />
              <DetailItem label="Email" value={profile.email} />
              <DetailItem label="Mobile" value={profile.mobile} />
              <DetailItem label="About Me" value={profile.about_me} />
              <DetailItem label="Therapy Specialisms" value={specialisms} />
              <DetailItem label="Therapy Tools" value={tools} />
              <DetailItem label="Experience Summary" value={profile.exp_summary} />
              <DetailItem label="Years of Experience" value={profile.experience} />
              <DetailItem label="Date of Birth" value={profile.date_of_birth} />
              <DetailItem label="Address" value={profile.address} />
              <DetailItem label="Post Code" value={profile.post_code} />
              <DetailItem label="20 minute trial offered to patients" value={profile?.trial_account ? 'Yes' : 'No'} />
              <DetailItem
                label="Preferred Availability"
                value={
                  profile?.out_of_hours ? 'Available mostly out of hours' : 'Available mostly on Peak hours and days'
                }
              />
            </DetailsWrapper>
            <Button
              onClick={() => {
                history.push('/t/account/edit');
              }}
            >
              Edit
            </Button>
          </>
        )}
      </Widget>
    </>
  );
};

const DetailsWrapper = styled.ul``;

const DetailItemWrapper = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing};
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};
`;

const ValueWrapper = styled.div`
  width: 100%;
`;

const mapStateToProps = (store) => ({
  profile: store.user.profile,
});

export default connect(mapStateToProps)(Details);
