import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import YouTube from 'react-youtube';

import { Button } from '../../Components/Atoms';

import useWindowSize from '../../Util/useWindowSize';

import { DashboardLayout, DefaultLayout } from '../../Layout';

import { SET_PAGE, SET_POSTS } from '../../Constants/actionTypes';

import {
  ContentWrapper,
  getCategories,
  getVideoCategories,
  getPosts,
  getVideoPosts,
  MainWrapper,
  WellBeingPage,
  WellBeingWrapper,
} from './index';
import { FilterWrapper } from './filter';

import { ReactComponent as BackIcon } from './back-icon.svg';
import notify from '../../Util/notify';
import { axiosPost } from '../../Util/axiosConfig';

const WellBeingPost = () => {
  const [patientLink, setPatientLink] = useState('');
  const role = useSelector((store) => store.user?.role);
  const posts = useSelector((store) => store.chatroom.wellBeing.posts);
  const videoPosts = useSelector((store) => store.chatroom.wellBeing.videoPosts);
  const categories = useSelector((store) => store.chatroom.wellBeing.categories);

  const { width } = useWindowSize();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { t } = useTranslation();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const isVideo = useMemo(() => query.get('is_video'), [query]);

  const post = useMemo(
    () =>
      isVideo === 'true'
        ? videoPosts?.find((post) => post.ID === parseInt(id))
        : posts?.find((post) => post.id === parseInt(id)),
    [posts, videoPosts, isVideo, id],
  );

  const categoryList = useMemo(() => {
    if (post) {
      return post.categories?.map((id, index) => {
        const category = categories?.find((category) => category.id === id);
        return category ? (
          <span
            key={id}
            style={{ marginRight: 10 }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(`${category?.name}${post.categories.length !== index + 1 ? ',' : ''}`),
            }}
          />
        ) : null;
      });
    }
    return null;
  }, [post, categories]);

  useEffect(() => {
    if (isVideo === 'true') {
      if ((!videoPosts || !videoPosts?.find((post) => post.ID === parseInt(id))) && id) dispatch(getVideoPosts(0));
    } else {
      if ((!post || !posts?.find((post) => post.id === parseInt(id))) && id) {
        dispatch(getPosts(1, [])).then((res) => {
          if (!res.data.find((post) => post.id === parseInt(id))) {
            axiosPost.get(`/posts/${id}?_embed`).then((resPost) => {
              dispatch({ type: SET_POSTS, payload: [...res.data, resPost.data] });
              dispatch({ type: SET_PAGE, payload: 2 });
            });
          } else dispatch({ type: SET_PAGE, payload: 2 });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, id, dispatch]);

  const getLinkToClipBoard = () => {
    const link = `${window.location.origin}${window.location.pathname.slice(5)}${window.location.search}`;
    navigator.clipboard.writeText(link);
    notify(t('copy'), t('linkCopiedToClipboard'), 'info');
    setPatientLink(link);
  };

  useEffect(() => {
    if (!categories) {
      if (isVideo === 'true') getVideoCategories();
      else dispatch(getCategories());
    }
  }, [categories, isVideo, dispatch]);

  if (!post || !width) return null;

  const Layout = role === 'therapist' ? DashboardLayout : DefaultLayout;

  return (
    <Layout>
      <WellBeingPagePost>
        <WellBeingWrapper>
          <BackButton
            onClick={() =>
              history.push(`${role === 'therapist' ? '/t' : ''}/well-being${isVideo ? '?is_video=true' : ''}`)
            }
          >
            <Icon as={BackIcon} />
            {t('backToLibrary')}
          </BackButton>
          {role === 'therapist' && (
            <Button style={{ marginTop: 20 }} onClick={getLinkToClipBoard}>
              {t('getLinkForPatient')}
            </Button>
          )}
          <p style={{ color: '#00c8a0', fontWeight: 600, marginTop: 10 }}>{patientLink}</p>
          {isVideo ? (
            <MainWrapper>
              <ContentWrapperPost style={{ width: '100%', maxWidth: 1200 }}>
                <Category>{categoryList}</Category>
                <h1>{post.post_title}</h1>
                <p>{post.post_content}</p>
                <YouTube
                  id="youtube-player"
                  videoId={post.post_meta.youtube[0]?.split('/watch?v=')[1]}
                  opts={{ height: '500px' }}
                />
              </ContentWrapperPost>
            </MainWrapper>
          ) : (
            <MainWrapper>
              {width > 900 && (
                <RelatedWrapper>
                  {/*<h3>Related Blogs</h3>*/}
                  {/*<RelatedPost>*/}
                  {/*  <RelatedImage />*/}
                  {/*  <RelatedText>Work Life Balance When Working From Home</RelatedText>*/}
                  {/*</RelatedPost>*/}
                  {post?.better_featured_image?.source_url && (
                    <img
                      style={{ width: '100%' }}
                      src={post?.better_featured_image?.source_url}
                      alt={post?.better_featured_image?.alt_text}
                    />
                  )}
                </RelatedWrapper>
              )}
              <ContentWrapperPost>
                <Category>{categoryList}</Category>
                <h1 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.title?.rendered) }} />
                <PostText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content?.rendered) }} />
              </ContentWrapperPost>
            </MainWrapper>
          )}
        </WellBeingWrapper>
      </WellBeingPagePost>
    </Layout>
  );
};

const Icon = styled.svg``;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${Icon} {
    margin-right: 10px;
    width: 20px;
  }

  @media screen and (max-width: 450px) {
    font-size: 20px;
    ${Icon} {
      width: 20px !important;
    }
  }
`;

const RelatedWrapper = styled(FilterWrapper)`
  width: 30%;
  padding: 0;
  box-shadow: none;
  max-width: 300px;
`;

const WellBeingPagePost = styled(WellBeingPage)`
  margin-top: 10px;
  color: black;

  @media screen and (max-width: 450px) {
    margin-top: 0;
  }
`;

// const RelatedText = styled.div`
//   width: 75%;
//   font-size: 12px;
//   font-weight: bold;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 18px;
// `;

// const RelatedPost = styled.div`
//   width: 100%;
//   display: flex;
//   height: 79px;
//   margin-top: 25px;
//   border: 1px solid #c4c4c4;
//   border-radius: 5px;
//   cursor: pointer;
//
//   &:hover {
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
//     ${RelatedText} {
//       color: #491f69;
//     }
//   }
// `;
// const RelatedImage = styled.div`
//   width: 25%;
//   background: #c4c4c4;
// `;

const ContentWrapperPost = styled(ContentWrapper)`
  width: 70%;
  padding-left: 100px;
  max-width: 700px;

  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 37px;
    color: #00c8a0;
  }

  #youtube-player {
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    width: 85%;
    padding: 0;
    margin: 0 auto;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }
`;

const Category = styled.div`
  color: #491f69;
  font-weight: bold;
  margin-bottom: 20px;
`;

const PostText = styled.div`
  h2 {
    font-size: 24px;
  }
`;

export default WellBeingPost;
