import React, { useEffect } from 'react';
import styled from 'styled-components';

import { DefaultLayout } from '../../Layout';
import { Heading } from '../../Components/Atoms';
import { ContactSupportForm } from '../../Components/Organisms';
import { useTranslation } from 'react-i18next';

const ContactSupport = () => {
  const { t } = useTranslation();
  useEffect(() => {
    if (window.HubSpotConversations && !window.HubSpotConversations?.widget?.status()?.loaded) {
      window.HubSpotConversations.resetAndReloadWidget();
    }
  }, []);
  return (
    <DefaultLayout>
      <Wrapper>
        <Heading text={t("contactSupport")} />
        <ContactSupportForm />
      </Wrapper>
    </DefaultLayout>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
`;
Wrapper.displayName = 'Wrapper';

export default ContactSupport;
