import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import api from '../../Util/api';

import { AuthLayout } from '../../Layout';
import { Heading, Button, ProgressBar } from '../../Components/Atoms';
import { ProfilePhotoInput } from '../../Components/Molecules';

const validFormats = ['image/jpeg', 'image/png'];

const Step2 = () => {
  const nextStep = () => history.push('/t/signup/step-3');

  const fileInputRef = useRef();
  const history = useHistory();

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    formData.append('profile_image', values.file, values.file.name);

    await api.therapist.updateProfile(formData);
    nextStep();
  };

  const checkFileSize = () => {
    const file = fileInputRef.current.files[0];
    let valid = true;
    if (file) {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        valid = false;
      }
    }
    return valid;
  };

  const checkFileExt = () => {
    const file = fileInputRef.current.files[0];
    let valid = true;

    if (file) {
      if (!validFormats.includes(file.type)) {
        valid = false;
      }
    }
    return valid;
  };

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required('File is required')
      .test('fileSize', 'File Size too Big', checkFileSize)
      .test('fileExt', 'File must be JPEG or PNG format', checkFileExt),
  });

  useEffect(() => {
    localStorage.setItem('firstLoginTherapist', 'true');
  }, []);

  return (
    <>
      <AuthLayout>
        <>
          <Heading>Profile Photo</Heading>
          <p>
            We’d like to add a photo of you to your profile. This helps patients recognise and warm to their therapists
            as they use the platform
          </p>
          <ProgressBar progress="15" style={{ margin: '20px auto', maxWidth: '500px' }} />
          <p>
            For best results, please use a square image.
            <br />
            Files must be in JPG or PNG format.
          </p>
          <br />
          <Formik
            initialValues={{ file: null }}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            render={({ values, handleOnSubmit, setFieldValue, errors, touched }) => {
              return (
                <Form>
                  <ProfilePhotoInput
                    id="file"
                    name="file"
                    file={values.file}
                    ref={fileInputRef}
                    className={'form-control' + (errors.file && touched.file ? ' is-invalid' : '')}
                    onChange={({ currentTarget }) => setFieldValue('file', currentTarget.files[0])}
                  />
                  <ErrorMessage name="file" component="div" className="invalid-feedback" />
                  <ButtonWrapper>
                    <Button variant="muted" onClick={nextStep}>
                      Skip
                    </Button>
                    <Button variant="primary" onClick={handleOnSubmit}>
                      Continue
                    </Button>
                  </ButtonWrapper>
                </Form>
              );
            }}
          />
        </>
      </AuthLayout>
    </>
  );
};

const ButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.spacingXl} 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 32px;
  }
`;

export default Step2;
