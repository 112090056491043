import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Atoms';

const NotificationActions = ({ notification, handleRequest, handleSwitch, markAsRead }) => {
  const { type, chatroom_id, id } = notification;
  const { t } = useTranslation();
  return type === 'patient_urgent_alert' ? (
    <Button as={Link} to={`/t/chatroom/${chatroom_id}`} onClick={() => markAsRead(id)}>
      {t("openChatroom")}
    </Button>
  ) : type === 'chatroom_request' ? (
    <>
      <Button onClick={() => handleRequest(chatroom_id, 'approved', id)}>{t("accept")}</Button>
      <Button className="danger" onClick={() => handleRequest(chatroom_id, 'rejected', id)}>
      {t("decline")}
      </Button>
    </>
  )
  // : type === 'switch_therapist_by_patient' ? (
  //   <Button onClick={() => handleSwitch(chatroom_id, id)}>{t("forwardNotes")}</Button>
  // )
 : type === 'chatroom_accepted' ? (
    <Button as={Link} to={'/chatroom'} onClick={() => markAsRead(id)}>
      {t("openChatroom")}
    </Button>
  ) : null;
};

NotificationActions.propTypes = {
  notification: PropTypes.object.isRequired,
  handleRequest: PropTypes.func.isRequired,
  handleSwitch: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
};

export default NotificationActions;
