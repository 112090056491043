import React from 'react';
import styled from 'styled-components';
import { AuthLayout } from '../../Layout';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Logout = () => {
  const { t } = useTranslation();
  localStorage.removeItem('loginInfo');
  const teamsContext = useSelector((store) => store.teams.teamsContext);

  return (
    <>
      <AuthLayout>
        <Wrapper>
          <h1>{t("you_Have_Been_Logged_Out")}</h1>
          <Link to={teamsContext ? '/teams' : '/c/login'}>{t("returnToLogin")}</Link>
        </Wrapper>
      </AuthLayout>
    </>
  );
};

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;
export default Logout;
