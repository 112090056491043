import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import GlobalStyle from '../globalStyles';

import { getNotifications, getUnreadMessages } from '../Actions/chatroom.actions';
import { NixyTherapistDashboardLinks, TherapistDashboardLinks } from '../Data/Menus';
import { useTranslation } from 'react-i18next';
import { Sidebar, Toolbar } from '../Therapist/Dashboard/Components';
import { useWindowSize } from '../Hooks';
import { NotificationPopup } from '../Components/Organisms';
import { isNixy } from '../Config';

const DashboardLayout = (props) => {
  const viewportWidth = useWindowSize().width;
  const unreadMessagesTimer = useRef(0);
  const notificationsTimer = useRef(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const notificationPagination = useSelector((store) => store.user?.notifications?.pagination);
  const notificationsPaginationRef = useRef({ from: 1, to: 50 });

  useEffect(() => {
    notificationsPaginationRef.current = notificationPagination;
  }, [notificationPagination]);

  useEffect(() => {
    const checkUnreadMessages = async () => {
      await dispatch(getUnreadMessages());
      unreadMessagesTimer.current = setTimeout(checkUnreadMessages, 60000);
    };
    const checkNotifications = async () => {
      await dispatch(getNotifications(notificationsPaginationRef.current));
      notificationsTimer.current = setTimeout(checkNotifications, 10000);
    };
    checkUnreadMessages();
    checkNotifications();
    return () => {
      clearTimeout(unreadMessagesTimer.current);
      clearTimeout(notificationsTimer.current);
    };
  }, [dispatch]);

  return (
    <>
      <GlobalStyle />
      <NotificationPopup notificationsPath={'/t/dashboard/notifications'} />
      <Toolbar links={TherapistDashboardLinks(t)} viewportWidth={viewportWidth} />
      <DashboardWrapper className={props.hideSidebar ? 'no-sidebar' : null}>
        {!props.hideSidebar && viewportWidth > 1170 && <Sidebar links={isNixy ? NixyTherapistDashboardLinks(t) : TherapistDashboardLinks(t)} />}
        <DashboardMain style={props.hideBottomSpacing ? { height: '100%' } : {}}>
          <DashboardContent>{props.children}</DashboardContent>
        </DashboardMain>
      </DashboardWrapper>
    </>
  );
};

const DashboardWrapper = styled.section`
  display: grid;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});

  ${({ theme }) => theme.md`
    grid-template-columns: 1fr;
    `}
  ${({ theme }) => theme.lg`
    grid-template-columns: 320px 1fr;
    `}
    &.no-sidebar {
    height: auto;
    grid-template-columns: 1fr;
  }
`;
const DashboardMain = styled.main`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
`;

const DashboardContent = styled.div`
  padding: ${({ theme }) => theme.spacingLg};
  .no-sidebar & {
    padding: 0;
  }
`;

export default DashboardLayout;
