import React, { memo } from 'react';
import styled from 'styled-components';

const Input = React.forwardRef((props, ref) => {
  const { icon, onIconClick, ...other } = props;

  return (
    <InputWrapper>
      <StyledInput {...other} ref={ref} />
      {icon && (
        <StyledIcon onClick={onIconClick}>
          <props.icon />
        </StyledIcon>
      )}
    </InputWrapper>
  );
});

const InputWrapper = styled.div`
  position: relative;
`;
InputWrapper.displayName = 'InputWrapper';

const StyledInput = styled.input`
  display: block;
  background: $white;
  border: ${(props) => props.border || '2px solid ' + props.theme.primaryMedium};
  color: ${({ theme }) => theme.primary};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 40px 0 8px;
  margin: 0;
  font-size: 20px;
  height: ${(props) => (props.type === 'checkbox' || props.type === 'radio' ? '30px' : '46px')};
  width: ${(props) => (props.type === 'checkbox' || props.type === 'radio' ? '30px' : '100%')};
  flex-shrink: 0;
  flex-grow: 1;
  &[type='password'] {
    padding: 0 38px 0 ${({ theme }) => theme.spacingSm};
  }
  &:focus {
    border: 2px solid ${(props) => props.theme.primary};
    outline: 0;
  }
`;
StyledInput.displayName = 'StyledInput';

const StyledIcon = styled.span`
  width: 20px;
  height: 20px;
  top: 50%;
  right: 12px;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.primary};
  }
`;
StyledIcon.displayName = 'StyledIcon';

export default memo(Input);
