import React from 'react';
import styled from 'styled-components';

const MainSectionWrapper = (props) => {
  return (
    <Wrapper isMobile={props?.isMobile} noPadding={props?.noPadding}>
      {props.children}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
`;
export default MainSectionWrapper;
