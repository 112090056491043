import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux';

import { Heading, Widget } from '../../Components/Atoms';
import Loading from '../../Components/Atoms/Loading/Loading';
import PersonalInfo from './Components/PersonalInfo';
import { useTranslation } from 'react-i18next';
// import Expertise from './Components/Expertise';
// import Qualifications from './Components/Qualifications';
// import BankDetails from './Components/BankDetails';
// import { ChangePasswordForm } from '../../Components/Organisms';

const Edit = () => {
  const { t } = useTranslation();
  const profile = useSelector((store) => store.user.profile);

  return (
    <>
      <Heading text={t("editAccountDetails")} />
      {!profile ? (
        <Loading />
      ) : (
        <Widget>
          <Tabs>
            <TabList>
              <Tab>{t("personalInfo")}</Tab>
              {/*<Tab>Expertise</Tab>*/}
              {/*<Tab>Qualifications</Tab>*/}
              {/*<Tab>Bank Details</Tab>*/}
              <Tab>{t("changePassword")}</Tab>
            </TabList>
            <TabPanel>
              <PersonalInfo profile={profile} />
            </TabPanel>
            {/*<TabPanel>*/}
            {/*  <Expertise />*/}
            {/*</TabPanel>*/}
            {/*<TabPanel>*/}
            {/*  <Qualifications profile={profile} />*/}
            {/*</TabPanel>*/}
            {/*<TabPanel>*/}
            {/*  <BankDetails />*/}
            {/*</TabPanel>*/}
            {/*<TabPanel>*/}
            {/*  <ChangePasswordForm />*/}
            {/*</TabPanel>*/}
          </Tabs>
        </Widget>
      )}
    </>
  );
};

export default Edit;
