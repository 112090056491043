import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getAnnouncements, handleAnnouncementMarkRead } from '../../../Actions/chatroom.actions';
import { SHOW_ANNOUNCEMENT } from '../../../Constants/actionTypes';
import { Button } from '../../Atoms';
import { useTranslation } from 'react-i18next';

const AnnouncementBubble = ({ scrollToBottom }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const registerDate = useSelector((store) => store.user?.profile?.register_date);

  const handleAnnouncementList = (announcement) => {
    if (registerDate) {
      let momentRegisterDate = moment(registerDate);
      let momentAnnouncmentStartDate = moment.utc(announcement?.sent_at);
      return momentAnnouncmentStartDate.isSameOrAfter(momentRegisterDate) && !announcement.is_read;
    }

    return [];
  };
  const announcements = useSelector((store) => store.user.announcements?.data?.filter(handleAnnouncementList));
  const announcement = announcements?.[announcements?.length - 1];
  const history = useHistory();

  useEffect(() => {
    if (!announcements?.length || !user?.profile?.is_save_today_mood || user?.profile?.showThxMood) return;
    const timer = setTimeout(() => {
      dispatch({
        type: SHOW_ANNOUNCEMENT,
      });
      dispatch(handleAnnouncementMarkRead(announcement.id, false));
      scrollToBottom();
    }, 30000);

    return () => {
      clearTimeout(timer);
    };
  }, [announcements?.length, user?.profile?.is_save_today_mood, user?.profile?.showThxMood, dispatch]);

  const announcementMarkAsRead = () => {
    dispatch(handleAnnouncementMarkRead(announcement.id)).then(() => dispatch(getAnnouncements()));
  };

  if (
    !announcements?.length ||
    !user?.profile?.is_save_today_mood ||
    user?.profile?.showThxMood ||
    !user.showAnnouncement ||
    announcement.read_status
  )
    return null;

  return (
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: announcement?.announcement }} />
      <Button style={{ marginRight: 15, marginBottom: 10 }} onClick={() => history.push('/notifications')}>
      {t("viewAllAnnouncements")}
      </Button>
      <Button style={{ marginBottom: 10 }} onClick={announcementMarkAsRead}>
      {t("close")}
      </Button>
    </Wrapper>
  );
};

export default AnnouncementBubble;

const Wrapper = styled.div`
  width: 100%;
  // margin: ${({ theme }) => theme.spacingXl} auto;
  margin-top: 12px;
  padding: 10px;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.primaryAlpha20};
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  text-align: center;

  ${({ theme }) => theme.sm`
      width: 100%;
      padding: ${({ theme }) => theme.spacingLg};
   `}
`;
