import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { Heading, Widget } from '../../Components/Atoms';

const Worksheets = () => {
  const [pdfList, setPdfList] = useState([]);
  const { category } = useParams();

  useEffect(() => {
    const requireFiles = require.context('../../../public/worksheets', true, /\w+\.pdf$/);
    const pdfList = requireFiles
      .keys()
      .filter((path) => path.includes(category))
      .map((path) => {
        const [, , category, , fileName, extension] = path.match(/^(\.\/)(.*)(\/)(.*)(\.pdf)$/);
        return {
          file: `${category}/${fileName}${extension}`,
          title: fileName
            .split('-')
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(' '),
        };
      });
    setPdfList(pdfList);
  }, [category]);

  return (
    <Widget>
      <Heading level={4}>Worksheets</Heading>
      <table className="striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {pdfList.map(({ file, title }) => (
            <tr key={file}>
              <td>{title}</td>
              <td>
                <StyledLink href={`/worksheets/${file}`} download>
                  Download
                </StyledLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Widget>
  );
};

const StyledLink = styled.a`
  color: ${({ theme }) => theme.primaryMedium};
  font-weight: ${({ theme }) => theme.fontMedium};
`;
StyledLink.displayName = 'StyledLink';

export default Worksheets;
