import React, { useState, memo, useCallback, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import styled from 'styled-components';

import api from '../../../Util/api';

import { Heading, Label, Widget } from '../../Atoms';
import Button from '../../Atoms/Button/Button';
import { getSubscriptionStatus } from '../../../Actions/patient.actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const validationSchema = Yup.object().shape({
  videoAmount: Yup.number(),
});

const VideoPlan = () => {
  const { t } = useTranslation();
  const [videoAmount, setVideoAmount] = useState(1);
  const videoAmountOnChange = useCallback((amount) => setVideoAmount(amount), []);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const videoSelectOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i <= 10; i++) {
      options.push(
        <option key={i} value={i}>
          {i} {t("session")}{i > 1 ? t("s") : null}
        </option>,
      );
    }

    return options;
  }, []);

  const buyVideoPackage = async () => {
    setLoading(true);
    const stripe = await stripePromise;

    if (videoAmount) {
      api.payment
        .purchaseVideoAddon(
          videoAmount,
          `${window.location.origin}/checkout/success/`,
          `${window.location.origin}/checkout/error`,
        )
        .then((session) => {
          // When the customer clicks on the button, redirect them to Checkout.
          stripe
            .redirectToCheckout({
              sessionId: session.data?.data?.checkout_session_id,
            })
            .then((result) => {
              dispatch(getSubscriptionStatus());
              if (result.error) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
              }
            });
        });
    }
  };

  return (
    <VideoPlanWrapper>
      <Heading level={2}>{t("additionalVideoSessions")}</Heading>
      <Widget>
        <Formik
          initialValues={{
            videoAmount: 1,
          }}
          validationSchema={validationSchema}
          onSubmit={null}
        >
          {() => (
            <>
              <Label htmlFor="subStage">{t("purchaseAdditionalSessions")}</Label>
              <Field
                as="select"
                id="subStage"
                name="subStage"
                style={{ width: '100%', marginBottom: 15 }}
                onChange={(e) => videoAmountOnChange(parseInt(e.target.value))}
                value={videoAmount}
              >
                {videoSelectOptions}
              </Field>
              <Button onClick={buyVideoPackage} disabled={loading}>
              {t("buyVideoPackage")}
              </Button>
            </>
          )}
        </Formik>
      </Widget>
    </VideoPlanWrapper>
  );
};

const VideoPlanWrapper = styled.div`
  width: 100%;
  min-width: 288px;

  ${({ theme }) => theme.lg`
    width: 47%
  `};
`;

export default memo(VideoPlan);
