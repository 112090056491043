import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Container, ChevronIcon, Button, NotificationIndicator, Tooltip } from '../Components/Atoms';
import { PatientMenu } from '../Components/Molecules';
import { Logo } from '../Patient/Signup/index';
import GlobalStyle from '../globalStyles';
import { useHistory, useLocation, Link } from 'react-router-dom';
import QuestionnairePercentBar from '../Components/Organisms/QuestionnairePercentBar/QuestionnairePercentBar';
import { useSelector, useDispatch } from 'react-redux';
import { questionnaireGoBack, addProgress } from '../Actions/questionnaire.actions';
import { ReactComponent as NotificationIcon } from '../Assets/Icons/Notification.svg';
import { useDeviceType } from '../Util';
import { isPartner, logout } from '../Actions/auth.actions';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../Components/LangSwitcher/LanguageSwitch';
// import Tooltip from '../Atoms/Tooltip/Tooltip';
import config from '../Config';

const WelcomeLayout = (props) => {
  const [partnerLogo, setPartnerLogo] = useState('');
  const history = useHistory();
  const percentCompletion = useSelector((state) => state?.questionnaire?.percentCompletion);
  const subscription = useSelector((store) => store?.user?.subscription);
  const notifications = useSelector((store) => store?.user?.notifications);
  const profile = useSelector((store) => store.user?.profile);
  const teamsContext = useSelector((store) => store.teams.teamsContext);

  const { t } = useTranslation();

  const [backButtonVisible, setBackButtonVisible] = useState(false);
  const [, setIsQuestionnaire] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useDeviceType();
  //These routes will have display:flex property to the mainContainer element
  const flexLayoutRoutes = [
    '/welcome/c/questionnaire',
    '/welcome/questionnaire',
    '/welcome/start',
    '/welcome/activate',
  ];
  const backButtonRoutes = useMemo(() => ['/', '/welcome', '/welcome/start', '/teams'], []);
  const handleGoBack = () => {
    const quizJourneyId = localStorage.getItem('quiz_journey');
    if ((location.pathname.includes('/questionnaire') || location.pathname.includes('/thankyou')) && quizJourneyId) {
      props.onBackButtonClick && props.onBackButtonClick();
      dispatch(questionnaireGoBack(quizJourneyId));
      dispatch(addProgress(-7));
      if (location.pathname.includes('/thankyou')) {
        setTimeout(() => history.goBack(), 800);
      }
    } else {
      history.goBack();
    }
  };
  useEffect(() => {
    if (backButtonRoutes.includes(location.pathname)) {
      setBackButtonVisible(false);
    } else {
      setBackButtonVisible(true);
    }
    location.pathname === '/welcome/questionnaire' ? setIsQuestionnaire(true) : setIsQuestionnaire(false);
  }, [location.pathname, backButtonRoutes]);

  useEffect(() => {
    dispatch(isPartner(window.location.host)).then((res) => {
      if (res.data.status === 'success' && res.data?.data?.is_partner && res.data?.data?.partner_logo) {
        setPartnerLogo(res.data.data.partner_logo);
      }
    });
  }, [dispatch]);

  return (
    <>
      <GlobalStyle />
      <StyledWrapper
        id="siteWrapper"
        {...props}
        style={{
          paddingBottom: props.noPadding ? null : '32px',
        }}
      >
        <Header isMobile={isMobile && location.pathname === '/'}>
          <FlexBox>
            {backButtonVisible && !props.hideBackButton && (
              <BackButton onClick={handleGoBack}>
                <ChevronIcon direction="left" />
              </BackButton>
            )}
            <WithPartnerLogo>
              <Logo src={config.nameLogo} onClick={() => history.push(teamsContext ? '/teams' : '/')} />
              {partnerLogo && (
                <Logo
                  id="partner-logo"
                  style={{ width: 100, marginLeft: 10 }}
                  src={partnerLogo}
                  onClick={() => history.push('/')}
                />
              )}
            </WithPartnerLogo>
          </FlexBox>
          <FlexBox>
            <Language>
              <LanguageSwitch />
            </Language>

            {(location.pathname === '/' || location.pathname === '/welcome') && (
              <Button
                variant="secondary"
                style={{
                  fontWeight: 600,
                  color: 'white',
                  background: '#33c8a0',
                  padding: '5px 4px',
                  minWidth: '80px',
                }}
                onClick={() => history.push('/welcome/signin')}
              >
                {t('signINBtn')}
              </Button>
            )}
          </FlexBox>
          {location.pathname === '/welcome/questionnaire' && teamsContext && (
            <Button
              variant="secondary"
              style={{
                fontWeight: 600,
                color: 'white',
                background: '#33c8a0',
                position: 'absolute',
                right: '20px',
                padding: '5px 4px',
                minWidth: '80px',
              }}
              onClick={() => {
                dispatch(logout());
                history.push('/teams');
              }}
            >
              {t('logout')}
            </Button>
          )}

          {/*
              Right side content
              Notification and menu
           */}
          <HeaderRight>
            {props.noTopBar ? null : (
              <>
                {subscription?.plan_status === 'active' || (profile && profile['B2B']) ? (
                  <StyledButtonLink to={'/notifications'}>
                    <Tooltip
                      id="notificationIcon"
                      text="View notifications/ announcements"
                      place="bottom"
                      offset={{ bottom: 10 }}
                    >
                      <NotificationIcon />
                    </Tooltip>
                    <NotificationIndicator count={notifications?.total_unread_count || 0} />
                  </StyledButtonLink>
                ) : null}
                <PatientMenu />
              </>
            )}
          </HeaderRight>
        </Header>
        {location.pathname.includes('/questionnaire') && <QuestionnairePercentBar percent={percentCompletion} />}
        <StyledMain id="mainContent">
          <MainContainer isFlex={flexLayoutRoutes.includes(location.pathname) && !isMobile}>
            {props.children}
          </MainContainer>
        </StyledMain>
      </StyledWrapper>
    </>
  );
};
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  text-align: center;
  align-items: center;
`;

const Language = styled.div``;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;

  @media screen and (max-width: 400px) {
    gap: 10px;
  }
`;

const StyledMain = styled.main`
  width: 100%;
  max-width: 960px;
  //margin: 0 auto;
  padding-top: 20px;
  flex: 1;
  height: calc(100vh - 185px);
  overflow: auto;
  display: flex;
  overflow-x: hidden;
  @media (min-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    max-height: calc(100vh - 185px);
  }
`;
const SwitchWrapper = styled.div`
  color: white;
  font-size: 16px;
  text-align: right;
`;

const WithPartnerLogo = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 435px) {
    #partner-logo {
      display: none;
    }
  }
`;
const Header = styled.div`
  width: 100%;
  height: 85px;
  background: #491f69;

  display: flex;
  align-items: center;
  justify-content: space-between; !important;

  padding: 32px;

  ${Logo} {
    width: 200px;
  }

  @media screen and (max-width: 435px) {
    ${Logo} {
      width: 150px;
    }
  }

  @media screen and (max-width: 400px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 32px;

    ${Logo} {
      display: block;
      width: 170px;
      margin: 0 0 3px 0;
    }

    ${SwitchWrapper} {
      display: flex;
      div {
        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }
`;
const MainContainer = styled(Container)`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  ${({ isFlex }) => isFlex && `display:flex`}
`;

const BackButton = styled.div`
  color: white;
  left: 20px;
  cursor: pointer;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 30px;
  svg {
    width: 18px;
    fill: ${({ theme }) => theme.white};
  }
  img {
    filter: brightness(10) sepia(1) hue-rotate(-70deg) saturate(5);
  }
`;

const StyledButtonLink = styled(Link)`
  margin-right: ${({ theme }) => theme.spacingMd};
`;
StyledButtonLink.displayName = 'StyledButtonLink';

export default WelcomeLayout;
