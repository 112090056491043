import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Button } from '../../Atoms';
import { getMessages, sendFile } from '../../../Actions/chatroom.actions';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusCircle } from '../../../Assets/Icons/PlusCircle.svg';
import Tooltip from '../../Atoms/Tooltip/Tooltip';

const UploadFile = ({ chatRoomID, pageLimit, onSendFile }) => {
  const { t } = useTranslation();
  const [image, setImage] = useState({ preview: '', file: '' });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setImage({
        preview: URL.createObjectURL(file),
        file: file,
      });
    }
    e.target.value = '';
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', image.file, image.file.name);

    dispatch(sendFile(chatRoomID, formData)).then(() => {
      setImage({ preview: '', file: '' });
      onSendFile()
      dispatch(getMessages(chatRoomID, pageLimit));
    });
  };

  const cancelUpload = () => setImage({ preview: '', file: '' });

  return (
    <div className="upload-file-component" style={{ position: 'relative' }}>
      <div>
        <label htmlFor="upload-button">
          <Tooltip id="uplaodFile" text={t("sendAttachment")} place="top">
            <ActionButton />
          </Tooltip>
          {/*{image.preview ? (*/}
          {/*  <img src={image.preview} alt="dummy" width="300" height="300" />*/}
          {/*) : (*/}
          {/*  <>*/}
          {/*    <ActionButton>*/}
          {/*      <img src={PlusCircle} alt="" />*/}
          {/*    </ActionButton>*/}
          {/*  </>*/}
          {/*)}*/}
        </label>
        <input type="file" id="upload-button" style={{ display: 'none' }} onChange={handleChange} />
      </div>
      {image.file && (
        <SendFileWrapper>
          <SendFileButton className="primary">
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{image.file.name}</div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
              <SendUpload onClick={handleUpload}>{t("sendFile")}</SendUpload>
              <CancelUpload onClick={cancelUpload}>{t("cancel")}</CancelUpload>
            </div>
          </SendFileButton>
        </SendFileWrapper>
      )}
    </div>
  );
};

UploadFile.propTypes = {
  chatRoomID: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
};

const ActionButton = styled(PlusCircle)`
  width: 40px;
  height: 40px;
  margin-right: ${({ theme }) => theme.spacing};
  fill: ${({ theme }) => theme.primary};
  cursor: pointer;
`;

const SendFileWrapper = styled.div`
  position: absolute;
  left: -17px;
  top: -75px;
  z-index: 1;
  max-width: 290px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.sm`
    max-width: 500px;
    left: 0px;
    top: -60px;
  `};
`;

const SendUpload = styled.span`
  padding: 4px 7px;

  background: green;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 5px;
`;

const CancelUpload = styled.span`
  padding: 4px 7px;

  background: red;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 10px;
`;

const SendFileButton = styled(Button)`
  position: relative;
  width: 100%;
`;

export default memo(UploadFile);
