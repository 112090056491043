import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Radio = (props) => {
  const { label, id, ...other } = props;

  return (
    <RadioWrapper>
      <StyledInput {...other} id={id} type={'radio'} />
      {label && <label htmlFor={id}>{label}</label>}
    </RadioWrapper>
  );
};

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;
RadioWrapper.displayName = 'RadioWrapper';

const StyledInput = styled.input`
  width: 18px;
  height: 18px;
  margin-right: ${({ theme }) => theme.spacingSm};
  background-color: ${({ theme }) => theme.white};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  &:checked {
    background-color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  }
`;

Radio.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default memo(Radio);
