import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

import MenuIcon from '../../../Assets/Icons/Menu.svg';
import CloseIcon from '../../../Assets/Icons/CloseWhite.svg';
import OrganisationSidebar from '../../../Config/OrganisationSidebar';

const DashboardNav = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subMenuState, setSubMenuState] = useState({});
  const location = useLocation();

  const handleMenuStateChange = ({ isOpen }) => setIsOpen(isOpen);

  const closeMenu = () => setIsOpen(false);

  const toggleSubmenu = (title) => {
    setSubMenuState((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  const checkSubRouteActive = (link) => {
    return !!link.children.find(({ url }) => url === location.pathname);
  };

  useEffect(() => {
    setSubMenuState((prevState) => {
      return links.reduce((subMenuState, { title, children }) => {
        if (children) {
          subMenuState[title] = !!children.find(({ url }) => url === location.pathname) || prevState[title];
        }
        return subMenuState;
      }, {});
    });
  }, [location.pathname, links]);

  return (
    <Menu
      isOpen={isOpen}
      onStateChange={handleMenuStateChange}
      customBurgerIcon={<img src={MenuIcon} alt="" />}
      customCrossIcon={<img src={CloseIcon} alt="" />}
      styles={NavStyles}
      pageWrapId={'mainContent'}
      outerContainerId={'siteWrapper'}
      disableAutoFocus
    >
      <OrganisationSidebar />
      <div style={{ width: '80%', float: 'inline-end', marginLeft: 10 }}>
        {links.map((item) => (
          <div key={item.id}>
            {item.children ? (
              <>
                <StyledToggle
                  className={`${subMenuState[item.title] && 'opened'} ${checkSubRouteActive(item) && 'active'}`}
                  onClick={() => toggleSubmenu(item.title)}
                >
                  {item.title}
                </StyledToggle>
                {subMenuState[item.title] && (
                  <SubNav>
                    {item.children.map((child) => (
                      <SubNavItem>
                        <SubNavLink key={child.url} to={child.url} onClick={closeMenu}>
                          {child.title}
                        </SubNavLink>
                      </SubNavItem>
                    ))}
                  </SubNav>
                )}
              </>
            ) : (
              <StyledNavLink exact={item.exact} key={item.id} to={item.url} onClick={closeMenu}>
                {item.title}
              </StyledNavLink>
            )}
          </div>
        ))}
      </div>
    </Menu>
  );
};

const NavStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '18px',
    top: '0',
  },
  bmCrossButton: {
    width: '18px',
    top: '26px',
    right: '16px',
  },

  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    left: 0,
  },
  bmMenu: {
    background: '#1A5549',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#ffffff',
    padding: '64px 32px 0 0',
    flexDirection: 'row',
    display: 'flex',
  },
  bmItem: {
    display: 'block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

const StyledLink = styled(NavLink)`
  display: block;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryLight};
  font-weight: ${({ theme }) => theme.fontMedium};
  text-decoration: none;
`;
StyledLink.displayName = 'StyledLink';

const StyledNavLink = styled(StyledLink)`
  padding: ${({ theme }) => theme.spacing};
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.spacing};

  &.active {
    background-color: #96fbe7;
    color: ${({ theme }) => theme.primary};
  }
`;
StyledNavLink.displayName = 'StyledNavLink';

const StyledToggle = styled.div`
  padding: ${({ theme }) => theme.spacing};
  margin-bottom: ${({ theme }) => theme.spacing};
  border-radius: 8px;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryLight};
  font-weight: ${({ theme }) => theme.fontMedium};
  cursor: pointer;
  position: relative;

  &.opened {
    &::after {
      right: 15px;
      transform: rotate(-135deg) translate(45%);
    }
  }

  &.active {
    background-color: #96fbe7;
    color: ${({ theme }) => theme.primary};

    &::after {
      border-color: ${({ theme }) => theme.primary};
    }
  }

  &::after {
    width: 12px;
    height: 12px;
    right: 10px;
    top: 50%;
    display: block;
    content: '';
    position: absolute;
    border-style: solid;
    border-color: ${({ theme }) => theme.primaryLight};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-100%);
  }
`;
StyledToggle.displayName = 'StyledToggle';

const SubNav = styled.ul`
  margin-left: ${({ theme }) => theme.spacing};
  padding-left: ${({ theme }) => theme.spacingSm};
`;
SubNav.displayName = 'SubNav';

const SubNavItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing};
`;
SubNavItem.displayName = 'SubNavItem';

const SubNavLink = styled(StyledLink)`
  &.active {
    color: ${({ theme }) => theme.primaryMedium};
  }
`;
SubNavLink.displayName = 'SubNavLink';

export default DashboardNav;
