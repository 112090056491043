import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getAnnouncements } from '../../../Actions/chatroom.actions';
import { Button } from '../../Atoms';
import { useTranslation } from 'react-i18next';

const AnnouncementsList = ({ announcements, announcementMarkAsRead }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getAnnouncements());
  }, []);

  const getAnnouncementActions = useCallback(
    ({ id, is_read }) => {
      if (is_read) return null;
      return (
        <Button className="" onClick={() => announcementMarkAsRead(id)}>
          {t('markAsRead')}
        </Button>
      );
    },
    [announcementMarkAsRead],
  );

  const getAnnouncementsBody = useCallback(() => {
    if (announcements?.length) {
      return announcements.map((item, index) => {
        return (
          <tr key={item.id} style={{ position: 'relative' }}>
            <Td>
              {!item.is_read && <NotificationDot />}
              {index + 1}
            </Td>
            <Td>{<div dangerouslySetInnerHTML={{ __html: item.announcement }} />}</Td>

            <TdActions>
              <ButtonWrapper>{getAnnouncementActions(item)}</ButtonWrapper>
            </TdActions>
          </tr>
        );
      });
    }

    return (
      <tr>
        <Td colSpan="5">{t('noAnnouncements')}</Td>
      </tr>
    );
  }, [announcements, getAnnouncementActions]);

  return (
    <Wrapper>
      <table className="table" width="100%">
        <tbody>{getAnnouncementsBody()}</tbody>
      </table>
    </Wrapper>
  );
};

export default AnnouncementsList;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  button {
    margin: 0 10px;
  }
`;

const Wrapper = styled.div`
  overflow-x: auto;
  text-align: center;
`;

const NotificationDot = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
`;

const Td = styled.td`
  position: relative !important;
  padding: 16px 20px !important;
  border-collapse: collapse;
  border-bottom: 1px solid #e5e5e5;
  text-align: left;
`;

const TdActions = styled(Td)`
  width: 270px;
`;
