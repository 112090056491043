import React from "react";
import { DefaultLayout } from "../../Layout";
import Details from "./Details";
import ManageSubscription from "./ManageSubscription";
import Notifications from "./Notifications";
import SubscriptionPlan from "./SubscriptionPlan";
import Edit from './Edit';
import { Switch } from "react-router-dom";
import Route from "../../Routes/Route";

const index = () => {
  return (
    <>
      <DefaultLayout>
        <Switch>
          {/* Private Routes */}
          <Route exact path="/account" component={Details} isPrivate />
          <Route exact path="/account/subscription" component={SubscriptionPlan} isPrivate />
          <Route exact path="/account/subscription/manage" component={ManageSubscription} isPrivate />
          <Route exact path="/account/notifications" component={Notifications} isPrivate />
          <Route exact path="/account/edit" component={Edit} isPrivate />

          {/* Default Route */}
          <Route component={Details} />
        </Switch>
      </DefaultLayout>
    </>
  );
};

export default index;
