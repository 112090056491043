import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getRolePrefix } from '../../../Util';
import api from '../../../Util/api';
import { mobilePhoneValidation, passwordValidation } from '../../../Constants/validations';

import { Heading, FormGroup, Button, Alert, Input, Checkbox } from '../../Atoms';

import { ReactComponent as Eye } from '../../../Assets/Icons/Eye.svg';
import { ReactComponent as EyeSlash } from '../../../Assets/Icons/EyeSlash.svg';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import config from '../../../Config';

const referalOptions = [
  { id: 1, name: 'Twitter' },
  { id: 2, name: 'Facebook' },
  { id: 3, name: 'Instagram' },
  { id: 4, name: 'Linkedin' },
  { id: 5, name: 'Blogs or Press release' },
  { id: 6, name: 'Search engines' },
  { id: 7, name: 'Friend' },
];

const therapistType = [
  { label: 'Therapist', value: 'therapist' },
  { label: 'Coach', value: 'coach' },
  { label: 'Mentor', value: 'mentor' },
];

const TherapistSignupForm = (props) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [responseMessage, setResponseMessage] = useState({ type: '', message: null });
  const [phone, setPhone] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const history = useHistory();

  const getCountriesList = () => {
    api.others
      .getCountriesList()
      .then((response) => {
        if (response.status === 200) {
          setCountries(response.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleOnSubmit = async (
    { country, referal, trial_account, ...values },
    { setSubmitting, resetForm },
  ) => {
    const countryData = country ? JSON.parse(country) : {};
    const referalData = referal ? JSON.parse(referal) : {};
    // const therapistType = therapist_type.map(({ value }) => value);

    const formData = {
      ...values,
      country: countryData.name,
      country_id: countryData.id,
      trial_account: trial_account ? 1 : 0,
      referal: referalData?.name,
      // therapist_type: therapistType.join(','),
    };

    try {
      await api.klaviyo.subscribeTherapist(formData);
      await api.auth.signup(props.userType, formData);

      setSubmitting(false);
      resetForm();
      history.push('/t/signup/complete');
    } catch (err) {
      const errors = err.response.data.errors || (err.response.data.detail && { detail: [err.response.data.detail] });
      console.error(err);
      setResponseMessage({
        type: 'danger',
        message: Object.keys(errors).map((key) => (
          <>
            <span key={key}>{Object.values(errors[key]).join(' ')}</span>
            <br />
          </>
        )),
      });
    }
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t("firstNameIsRequired")),
    last_name: Yup.string().required(t("lastNameIsRequired")),
    mobile: mobilePhoneValidation(t),
    email: Yup.string().email(t("emailIsInvalid")).required(t("emailIsRequired")),
    password: passwordValidation(t),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t("passwordsMustMatch"))
      .required(t("confirmPasswordIsRequired")),
    country: Yup.string().required(t("countryIsRequired")),
    referal: Yup.string(),
    // therapist_type: Yup.array().required(t("professionalTypeRsequired")),

    accept_terms: Yup.array().min(1, t("checkBoxSelect")),
  });

  useEffect(() => {
    getCountriesList();
  }, []);

  const mobileOnChange = (phone, setFieldValue) => {
    setFieldValue('mobile', phone);
    setPhone(phone);
  };

  const handleSelectChange = (selected, field, setFieldValue) => {
    setFieldValue(field, selected || []);
  };

  return (
    <>
      <Wrapper>
        <Heading text={props.heading} />
        <p>
        {t("alreadyHaveAnAccount")}{' '}
          <Link to={`${getRolePrefix(props.userType)}/login`}>
            <b>{t("loginNow")}</b>
          </Link>
        </p>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            mobile: '',
            email: '',
            password: '',
            password_confirmation: '',
            country: JSON.stringify({ id: '', name: '' }),
            trial_account: false,
            referal: JSON.stringify({ id: '', name: '' }),
            // therapist_type: [],
            accept_terms: [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <FormGroup>
                <Field
                  id="first_name"
                  name="first_name"
                  placeholder={t("firstName")}
                  type="text"
                  className={'form-control' + (errors.first_name && touched.first_name ? ' is-invalid' : '')}
                />
                <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field
                  id="last_name"
                  name="last_name"
                  placeholder={t("lastName")}
                  type="text"
                  className={'form-control' + (errors.last_name && touched.last_name ? ' is-invalid' : '')}
                />
                <ErrorMessage name="last_name" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <PhoneInput
                  containerClass={errors.mobile && touched.mobile ? ' is-invalid' : ''}
                  value={phone}
                  onChange={(phone) => mobileOnChange(phone, setFieldValue)}
                  placeholder={t("mobileNumberPlaceholder")}
                  enableSearch
                />
                {/*<Field*/}
                {/*  id="mobile"*/}
                {/*  name="mobile"*/}
                {/*  placeholder="Mobile"*/}
                {/*  type="tel"*/}
                {/*  className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')}*/}
                {/*/>*/}
                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field
                  as="select"
                  id="country"
                  name="country"
                  type="text"
                  className={'form-control' + (errors.country && touched.country ? ' is-invalid' : '')}
                >
                  <option disabled value={JSON.stringify({ id: '', name: '' })} label="Country" />
                  {countries.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="country" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field
                  id="email"
                  name="email"
                  placeholder={t("emailAddress")}
                  type="email"
                  className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field
                  id="password"
                  name="password"
                  placeholder={t("choosePassword")}
                  as={Input}
                  icon={showPassword ? EyeSlash : Eye}
                  onIconClick={togglePassword}
                  type={showPassword ? 'text' : 'password'}
                  className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                />
                <ErrorMessage name="password" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Field
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder={t("confirm_Password")}
                  as={Input}
                  icon={showConfirmPassword ? EyeSlash : Eye}
                  onIconClick={toggleConfirmPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  className={
                    'form-control' +
                    (errors.password_confirmation && touched.password_confirmation ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage name="password_confirmation" component="div" className="invalid-feedback" />
              </FormGroup>

              <FormGroup>
                <Field as="select" id="referal" name="referal" type="text">
                  <option disabled value={JSON.stringify({ id: '', name: '' })} label="How did you hear about us" />
                  {referalOptions.map(({ id, name }) => (
                    <option key={id} value={JSON.stringify({ id, name })}>
                      {name}
                    </option>
                  ))}
                </Field>
              </FormGroup>
              <StyledFormGroup>
                {/* <FieldWrapper>
                  <StyledSelect
                    placeholder={t("professionalType")}
                    id="therapist_type"
                    name="therapist_type"
                    className="department-selector-container"
                    classNamePrefix="department-selector"
                    options={therapistType}
                    // defaultValue={department}
                    isMulti
                    isSearchable={true}
                    onChange={(e) => handleSelectChange(e, 'therapist_type', setFieldValue)}
                  />
                  <ErrorMessage name="therapist_type" component="div" className="invalid-feedback" />
                </FieldWrapper> */}
              </StyledFormGroup>
              <CheckboxGroup role="group" aria-labelledby="checkbox-group">
                <FormGroup>
                  <Field
                    as={Checkbox}
                    label={
                      <div>
                        <span>
                        {t("yesIAgree")}{' '}
                          <TCLink href={config.terms} download>
                          {t("termsAndConditions")}
                          </TCLink>
                        </span>
                      </div>
                    }
                    name="accept_terms"
                    value="consent"
                  />
                  <ErrorMessage name="accept_terms" component="div" className="invalid-feedback" />
                </FormGroup>
              </CheckboxGroup>

              <Button type="submit">{t("continue")}</Button>

              {responseMessage.type ? (
                <StyledAlert className={responseMessage.type}>{responseMessage.message}</StyledAlert>
              ) : null}
            </Form>
          )}
        </Formik>
      </Wrapper>
    </>
  );
};

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: start;
  input {
    width: 100%;
    height: 50px;
    flex-grow: initial;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    &:focus {
      border: 2px solid #00c8a0;
    }
  }

  input {
    svg {
      path {
        fill: #797979 !important;
      }
    }
  }

  label {
    span {
      border-radius: 0;
      border-color: #c4c4c4;
      a {
        color: #33c8a0;
      }
    }

    &:hover {
      .checkmark {
        background: #f6f6f6;
      }
    }
  }

  input:checked ~ .checkmark {
    background-color: #00c8a0;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-top: 5px;

  .department-selector__control {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.07);
    color: gray;
    height: 50px;
  }
  .department-selector__control--menu-is-open,
  .department-selector__control--is-focused {
    border: 2px solid #00c8a0 !important;
  }

  .department-selector__indicator-separator {
    display: none;
  }
  .department-selector__input {
    input {
      height: auto;
      margin-top: -5px;
    }
  }
  .department-selector__value-container {
    padding: 0 8px 0 8px;
    height: 50px;

    .department-selector__single-value {
      color: gray;
      font-weight: 400;
      font-size: 15px;
    }
  }
  .department-selector__indicators {
    width: 20px;
    .department-selector__dropdown-indicator {
      padding: 0;

      svg {
        width: 17px;
      }
    }
  }
`;

export const FieldWrapper = styled.div`
  width: 100%;
  margin-right: 5px;
  position: relative;
  .department-selector-container {
    background-color: white;
    width: 101%;
    border-radius: 5px;
    .department-selector__control,
    .css-yk16xz-control {
      justify-content: flex-start;
      border-color: #00c8a0;
      border-width: 2px;
      height: 45px;

      .department-selector__value-container {
        height: 45px;

        .department-selector__multi-value {
          background-color: #00c79f;
          margin-bottom: 5px;
          .department-selector__multi-value__label {
            font-size: 17px;
          }
        }

        .department-selector__placeholder {
          font-size: 20px;
          font-weight: 400;
          color: #22483d;
        }
      }

      .department-selector__value-container--has-value {
        flex: 0.9;
      }

      .department-selector__indicators {
        width: 20px;
        .css-tlfecz-indicatorContainer {
          color: #37625c;
        }
      }
    }
    .department-selector__menu {
      background-color: white;
      color: black;
      font-size: 18px;
    }
  }
  input {
    margin-top: 5px;
  }

  select {
    margin-top: 5px;
  }

  label {
    font-size: 13px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.5);
  }
  .invalid-feedback {
    margin-left: 8px;
  }
  .react-tel-input {
    input.form-control {
      width: 100%;
      height: 50px;
      background: ${({ theme }) => theme.greyVeryLight};
    }
    .flag-dropdown {
      &.open {
        .country-list {
          overflow-x: hidden;
          padding-right: 6px;
          &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: ${({ theme }) => theme.greyVeryLight};
          }
          .search {
            .search-emoji {
              display: none;
            }
            .search-box {
              height: 45px;
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing};
`;
StyledAlert.displayName = 'StyledAlert';

const TCLink = styled.a`
  color: ${({ theme }) => theme.primaryMedium};
  font-weight: ${({ theme }) => theme.fontMedium};
`;

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .react-tel-input {
    border: 2px solid #00c8a0;
    border-radius: 7px;

    li {
      color: black;
      text-align: left;
    }

    .form-control {
      width: 100%;
      height: 40px;
      font-size: 18px;
    }

    input {
      &::placeholder {
        color: #bebebe;
      }
    }

    .search {
      display: flex;
      height: 40px;
      padding: 10px;

      input {
        height: 25px;
      }
    }
  }
`;

const CheckboxGroup = styled.div`
  text-align: left;
  margin: ${({ theme }) => theme.spacingLg} 0;
`;

TherapistSignupForm.propTypes = {
  userType: PropTypes.string,
  heading: PropTypes.string,
};

TherapistSignupForm.defaultProps = {
  userType: 'therapist',
  heading: 'Create your account',
};

export default TherapistSignupForm;
