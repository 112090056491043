import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tooltip } from '../../Atoms';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SidebarNavigateHelper = ({ link, showVideoCallIcon, isB2b, closeMenu }) => {
  const { t } = useTranslation();

  const { isPartnerCheck, profile } = useSelector((store) => {
    return {
      isPartnerCheck: store.isPartner?.isPartner,
      profile: store?.user?.profile,
    };
  });

  if (link.path === '/moodbox' && profile?.moodbox_page_visited) return null;

  const isDisabled = !showVideoCallIcon;
  if (link.path === '/calendar' && isDisabled) {
    if (isB2b) {
      return null;
    }
    return (
      <StyledFakeLink key={link.id} to={link.path}>
        <StyledIcon>
          <link.icon />
        </StyledIcon>
        <Tooltip
          id={link.title + 'calendar'}
          text={t('upgradeSubscriptionAccessVideoSessions')}
          place="right"
          offset={{ right: 10 }}
        >
          <span>{t(link.title)}</span>
        </Tooltip>
      </StyledFakeLink>
    );
  }

  if ((!isB2b || isPartnerCheck) && link.path === '/additional-support') {
    return null;
  }

  if ((!isB2b || isPartnerCheck) && link.path === '/employee-benefits') {
    return null;
  }

  return (
    <StyledLink key={link.path} to={link.path} onClick={closeMenu}>
      <StyledIcon>
        <link.icon />
      </StyledIcon>
      {t(link.title)}
    </StyledLink>
  );
};

export default SidebarNavigateHelper;

const StyledLink = styled(Link)`
  display: flex !important;
  align-items: center;
  font-size: 17px;
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.white};
  margin-bottom: 25px;
  text-decoration: none;
`;
StyledLink.displayName = 'StyledLink';

const StyledFakeLink = styled.span`
  cursor: not-allowed;
  display: flex !important;
  align-items: center;
  font-size: 17px;
  font-weight: ${({ theme }) => theme.fontBold};
  color: #787676b0;
  margin-bottom: 25px;
  text-decoration: none;
`;
StyledLink.displayName = 'StyledFakeLink';

const StyledIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryLight};
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 100%;
  margin-right: ${({ theme }) => theme.spacing};

  svg {
    fill: ${({ theme }) => theme.primary};
    height: 20px;
    width: 20px;
  }
`;
StyledIcon.displayName = 'StyledIcon';
