import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  position: relative;
  display: ${(props) => (props.block ? 'block' : 'inline-block')};
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 15px;
  font-weight: ${({ theme }) => theme.fontBold};
  font-family: 'Roboto', sans-serif;
  padding: ${({ theme }) => theme.spacingSm} ${({ theme }) => theme.spacing};
  text-decoration: none;
  min-width: ${(props) => (props.block ? '100%' : '100px')};
  background: ${({ theme }) => theme.greyLight};
  color: ${({ theme }) => theme.greyDark};
  line-height: 1;
  white-space: pre;
  vertical-align: middle;
  cursor: pointer;
  .icon {
    vertical-align: middle;
    margin-right: 4px;
  }

  &.small {
    font-size: 12px;
    padding: ${({ theme }) => theme.spacingSm} ${({ theme }) => theme.spacingSm};
    min-width: auto;
  }

  &.tiny {
    font-size: 12px;
    padding: 4px;
    min-width: auto;
  }

  &.primary {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
  }

  &.secondary {
    background: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.white};
  }

  &.danger {
    background: ${({ theme }) => theme.statusDanger};
    color: ${({ theme }) => theme.white};
  }

  &.warning {
    background: ${({ theme }) => theme.statusWarning};
    color: ${({ theme }) => theme.white};
  }

  &.success {
    background: ${({ theme }) => theme.statusSuccess};
    color: ${({ theme }) => theme.white};
  }
  &.info {
    background: ${({ theme }) => theme.statusInfo};
    color: ${({ theme }) => theme.white};
  }

  &.muted {
    background: ${({ theme }) => theme.greyMedium};
    color: ${({ theme }) => theme.white};
  }

  &.ghost {
    border: 1px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
    background: transparent;
  }

  &.ghost.danger {
    border: 1px solid ${({ theme }) => theme.statusDanger};
    color: ${({ theme }) => theme.statusDanger};
  }

  &.link {
    background: none;
    padding: 0;
    border: 0;
    text-decoration: underline;
    min-width: auto;
    font-weight: ${({ theme }) => theme.fontNormal} !important;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  &.textButton {
    background: none;
    padding: 0;
    border: 0;
    min-width: auto;
    font-weight: bold !important;
  }

  ${({ loading }) =>
    loading &&
    css`
      span {
        opacity: 0.2;
      }
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const Loading = styled.svg`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 25px;
  height: 25px;
`;

const Button = React.forwardRef((props, ref) => (
  <StyledButton ref={ref} className={props.variant} {...props}>
    {props.loading && (
      <Loading
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100px"
        height="100px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          r="32"
          strokeWidth="8"
          stroke="#491f69"
          strokeDasharray="50.26548245743669 50.26548245743669"
          fill="none"
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="1s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 50;360 50 50"
          />
        </circle>
        <circle
          cx="50"
          cy="50"
          r="23"
          strokeWidth="8"
          stroke="#00c8a0"
          strokeDasharray="36.12831551628262 36.12831551628262"
          strokeDashoffset="36.12831551628262"
          fill="none"
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="1s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 50;-360 50 50"
          />
        </circle>
      </Loading>
    )}
    <span>{props.children}</span>
  </StyledButton>
));

Button.propTypes = {
  variant: PropTypes.string,
  block: PropTypes.bool
};
Button.defaultProps = {
  variant: 'primary',
};

export default memo(Button);
