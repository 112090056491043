import React from 'react';
import styled from 'styled-components';

const BottomSectionWrapper = (props) => {
  return (
    <WrapperOverlay>
      <Wrapper>{props.children}</Wrapper>
    </WrapperOverlay>
  );
};
const WrapperOverlay = styled.div`
  height: 90px;
  width: 100%;
  background: linear-gradient(360deg, #fff, #fff, #fff, rgba(255, 255, 255, 0));
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 6px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding-bottom: 20px;
  padding-top: -10px;
  height: 50px;
  border-radius: 50% 50% 6px 6px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(51, 200, 160, 0.3981967787114846) 100%);
  -webkit-box-shadow: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(51, 200, 160, 0.3981967787114846) 100%);
`;

export default BottomSectionWrapper;
