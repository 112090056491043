import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Button } from '../../Atoms';
import { NotificationActions } from '../../Molecules';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotificationsList = ({ notifications, handleRequest, handleSwitch, markAsRead }) => {
  const { t } = useTranslation();
  const getNotificationActions = useCallback(
    ({ type, chatroom_id, id, read_status }) => {
      if (read_status) return null;

      switch (type) {
        case 'chatroom_request':
        // case 'switch_therapist_by_patient':
        case 'patient_urgent_alert':
          return (
            <NotificationActions
              handleSwitch={handleSwitch}
              markAsRead={markAsRead}
              handleRequest={handleRequest}
              notification={{ type, chatroom_id, id }}
            />
          );
        default:
          return (
            <Button className="" onClick={() => markAsRead(id)}>
              {t('markAsRead')}
            </Button>
          );
      }
    },
    [handleRequest, handleSwitch, markAsRead],
  );

  const getNotificationsBody = useCallback(() => {
    if (notifications?.length) {
      return notifications.map((n, index) => {
        let nDate = new Date(n.created_at).toLocaleDateString('en-gb', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });

        return (
          <tr key={n.id} style={{ position: 'relative' }}>
            <Td>
              {!n.read_status && <NotificationDot />}
              {index + 1}
            </Td>
            <Td>{nDate}</Td>
            <Td>{n.message}</Td>
            {n.sent_by_role === 'patient' && n.sent_by_user_id !== '' ? (
              <Td>
                <Link to={`/t/patient-profile/${n.chatroom_id}/${n.sent_by_user_id}`}>{n.sent_by}</Link>
              </Td>
            ) : (
              <Td>{n.sent_by}</Td>
            )}
            <TdActions>
              <ButtonWrapper>{getNotificationActions(n)}</ButtonWrapper>
            </TdActions>
          </tr>
        );
      });
    }

    return (
      <tr>
        <Td colSpan="5">{t('noNotifications')}</Td>
      </tr>
    );
  }, [notifications, getNotificationActions]);

  return (
    <table className="table" width="100%">
      <thead>
        <tr>
          <Th>{t('Nr')}</Th>
          <Th>{t('date')}</Th>
          <Th>{t('notification')}</Th>
          <Th>{t('sender')}</Th>
          <Th>{t('actions')}</Th>
        </tr>
      </thead>
      <tbody>{getNotificationsBody()}</tbody>
    </table>
  );
};

const ButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  button {
    margin: 0 10px;
  }
`;

const NotificationDot = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
`;

const Td = styled.td`
  position: relative !important;
  padding: 16px 20px !important;
  border-collapse: collapse;
  border-bottom: 1px solid #e5e5e5;
  text-align: left;
`;

const Th = styled.th`
  padding: 24px 20px !important;
  border-collapse: collapse;
  border-bottom: 1px solid #e5e5e5;
  text-align: left;
`;

const TdActions = styled(Td)`
  width: 270px;
`;

export default NotificationsList;
