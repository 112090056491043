import React from "react";
import { TherapyTrackerTable } from "../../Components/Organisms";
import { DashboardLayout } from '../../Layout';

const TherapyTracker = () => {
  return (
    <>
      <DashboardLayout>
        <h1>TherapyTracker</h1>

        <TherapyTrackerTable />
      </DashboardLayout>
    </>
  );
};

export default TherapyTracker;
