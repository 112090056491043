import {
  LOGIN,
  LOGOUT,
  MAP_STORAGE_TO_REDUX,
  TOGGLE_POPUP_SOCIAL_INFORMATION_REQUEST,
  TOGGLE_SMS_CODE_REQUEST,
} from '../Constants/actionTypes';

const initialState = {
  loggedIn: false,
  access_token: '',
  expires_at: '',
  role: '',
  socialRequestPopupStatus: false,
  smsCodeRequestModalStatus: false,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case MAP_STORAGE_TO_REDUX:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return initialState;
    case TOGGLE_POPUP_SOCIAL_INFORMATION_REQUEST:
      return {
        ...state,
        socialRequestPopupStatus: !state.socialRequestPopupStatus,
      };
    case TOGGLE_SMS_CODE_REQUEST:
      return {
        ...state,
        smsCodeRequestModalStatus: !state.smsCodeRequestModalStatus,
      };
    default:
      return state;
  }
}
