import React from "react";
import styled from "styled-components";

const HelpText = props => {
  return (
    <>
      <StyledText {...props}>{props.children}</StyledText>
    </>
  );
};

const StyledText = styled.small`
  display: block;
  width: 100%;
  margin-top: 4px;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontBold};
  text-align: left;
`;
export default HelpText;
