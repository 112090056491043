// GLOBAL
export const ERRORS = 'ERRORS';

// AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const MAP_STORAGE_TO_REDUX = 'MAP_STORAGE_TO_REDUX';
export const TOGGLE_POPUP_SOCIAL_INFORMATION_REQUEST = 'TOGGLE_POPUP_SOCIAL_INFORMATION_REQUEST';
export const TOGGLE_SMS_CODE_REQUEST = 'TOGGLE_SMS_CODE_REQUEST';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';

// CHATROOM
export const GET_MESSAGES = 'GET_MESSAGES';
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';
export const GET_UNREAD_MESSAGES = 'GET_UNREAD_MESSAGES';
export const ADD_UNREAD_MESSAGES = 'ADD_UNREAD_MESSAGES';
export const GET_CHAT_ROOMS = 'GET_CHAT_ROOMS';
export const GET_CHAT_RESOURCES = 'GET_CHAT_RESOURCES';
export const SET_CHAT_ROOM_ERROR = 'SET_CHAT_ROOM_ERROR';
export const CREATE_VIDEO_ROOM = 'CREATE_VIDEO_ROOM';
export const SET_VIDEO_ROOM = 'SET_VIDEO_ROOM';
export const SET_VIDEO_ROOM_ERROR = 'SET_VIDEO_ROOM_ERROR';
export const LAUNCH_VIDEO_ROOM = 'LAUNCH_VIDEO_ROOM';
export const LEAVE_VIDEO_ROOM = 'LEAVE_VIDEO_ROOM';
export const DESTROY_VIDEO_ROOM = 'DESTROY_VIDEO_ROOM';
export const GET_CHATROOM_SCHEDULE_LIST = 'GET_CHATROOM_SCHEDULE_LIST';
export const GET_JOURNALS = 'GET_JOURNALS';
export const GET_JOURNAL = 'GET_JOURNAL';
export const ADD_JOURNAL = 'ADD_JOURNAL';
export const UPDATE_JOURNAL = 'UPDATE_JOURNAL';
export const DELETE_JOURNAL = 'DELETE_JOURNAL';
export const GET_THERAPY_JOURNEY = 'GET_THERAPY_JOURNEY';
export const GET_TEMPLATES_STAGES = 'GET_TEMPLATES_STAGES';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const NOTIFICATION_MARK_AS_READ = 'NOTIFICATION_MARK_AS_READ';
export const HANDLE_CHAT_ROOM_REQUEST = 'HANDLE_CHAT_ROOM_REQUEST';
export const CLEAR_CHAT_ROOM = 'CLEAR_CHAT_ROOM';
export const GET_NOTES = 'GET_NOTES';
export const ADD_NOTES = 'ADD_NOTES';
export const CAN_SUBMIT_JOURNEY_FEEDBACK = 'CAN_SUBMIT_JOURNEY_FEEDBACK';
export const SUBMIT_JOURNEY_FEEDBACK = 'SUBMIT_JOURNEY_FEEDBACK';
export const UPDATE_FLAG = 'UPDATE_FLAG';
export const GET_STATS = 'GET_STATS';
export const GET_FAQ_CATEGORIES_LIST = 'GET_FAQ_CATEGORIES_LIST';
export const GET_FAQ_CATEGORIES = 'GET_FAQ_CATEGORIES';
export const GET_FAQ_LISTING = 'GET_FAQ_LISTING';
export const SWITCH_THERAPIST = 'SWITCH_THERAPIST';
export const SHARE_JOURNAL_WITH_THERAPIST = 'SHARE_JOURNAL_WITH_THERAPIST';

// WELL BEING
export const SET_POSTS = 'SET_POSTS';
export const SET_VIDEO_POSTS = 'SET_VIDEO_POSTS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const SET_PAGE = 'SET_PAGE';
export const SET_HAS_MORE = 'SET_HAS_MORE';
export const SET_SELECTED_CATEGORIES = 'SET_SELECTED_CATEGORIES';

// THERAPIST
export const GET_THERAPIST_SCHEDULE_LIST = 'GET_THERAPIST_SCHEDULE_LIST';
export const GET_PUBLIC_THERAPIST_PROFILE = 'GET_PUBLIC_THERAPIST_PROFILE';
export const GET_PUBLIC_THERAPIST_BEGIN = 'GET_PUBLIC_THERAPIST_BEGIN';
export const ACTIVE_CHAT_ROOMS = 'ACTIVE_CHAT_ROOMS';
export const UPDATE_THERAPIST_PROFILE = 'UPDATE_THERAPIST_PROFILE';
export const GET_PATIENT_PROFILE = 'GET_PATIENT_PROFILE';
export const GET_PATIENT_STATS = 'GET_PATIENT_STATS';
export const GET_COMMISSION = 'GET_COMMISSION';
export const GET_QUESTIONNAIRE_RESULT = 'GET_QUESTIONNAIRE_RESULT';
export const GET_TOOLS = 'GET_TOOLS';
export const GET_THERAPIST_TOOLS = 'GET_THERAPIST_TOOLS';
export const GET_CONCERNING_ISSUES = 'GET_CONCERNING_ISSUES';
export const GET_SUB_CONCERNING_ISSUES = 'GET_SUB_CONCERNING_ISSUES';
export const UPDATE_THERAPIST_TOOLS = 'UPDATE_THERAPIST_TOOLS';
export const DELETE_THERAPIST_TOOL = 'DELETE_THERAPIST_TOOL';
export const GET_SPECIALISM = 'GET_SPECIALISM';
export const GET_SPECIALISMS = 'GET_SPECIALISMS';
export const UPDATE_SPECIALISM = 'UPDATE_SPECIALISM';
export const DELETE_SPECIALISM = 'DELETE_SPECIALISM';
export const GET_PROFESSIONAL_TYPES = 'GET_PROFESSIONAL_TYPES';
export const GET_PROFESSIONAL_TYPE = 'GET_PROFESSIONAL_TYPE';

// PATIENT
export const GET_MOOD_BOX = 'GET_MOOD_BOX';
export const GET_PATIENT_ANALYTICS = 'GET_PATIENT_ANALYTICS';
export const GET_SUBSCRIPTION_STATUS = 'GET_SUBSCRIPTION_STATUS';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const SAVE_MOOD = 'SAVE_MOOD';
export const CLOSE_MOOD_BOX_THX_MESSAGE = 'CLOSE_MOOD_BOX_THX_MESSAGE';
export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS';
export const UPDATE_NOTIFICATIONS_SETTINGS = 'UPDATE_NOTIFICATIONS_SETTINGS';
export const UPDATE_PROFILE_2FA = 'UPDATE_PROFILE_2FA';
export const UPDATE_PROFILE_ONLINE_STATUS = 'UPDATE_PROFILE_ONLINE_STATUS';
export const REDEEM_VOUCHER = 'REDEEM_VOUCHER';
export const CLEAR_PATIENT_STATS = 'CLEAR_PATIENT_STATS';
export const SHOW_ANNOUNCEMENT = 'SHOW_ANNOUNCEMENT';
export const HIDE_ANNOUNCEMENT = 'HIDE_ANNOUNCEMENT';

// PATIENT & THERAPIST
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_MORE_NOTIFICATIONS = 'GET_MORE_NOTIFICATIONS';

// QUESTIONNAIRE
export const STARTQUIZ_SUCCESS = 'STARTQUIZ_SUCCESS';
export const FETCHING_QUIZ_DATA = 'FETCHING_QUIZ_DATA';
export const SUBMIT_QUIZ_ANSWER_SUCCESS = 'SUBMIT_QUIZ_ANSWER_SUCCESS';
export const QUESTION_PROGRESS = 'QUESTION_PROGRESS';
export const QUESTIONNAIRE_BACK_SUCCESS = 'QUESTIONNAIRE_BACK_SUCCESS';
export const QUESTIONNAIRE_BACK_BEGIN = 'QUESTIONNAIRE_BACK_BEGIN';
export const SUBMITTING_ANSWER = 'SUBMITTING_ANSWER';
export const QUIZ_COMPLETED = 'QUIZ_COMPLETED';
export const TERMINATE_QUESTIONNAIRE = 'TERMINATE_QUESTIONNAIRE';

// TEAMS

export const SET_TEAMS = 'SET_TEAMS';

// Language

export const SET_LANGUAGE = 'SET_LANGUAGE';
