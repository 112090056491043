export const UKIContactsData = [
  { id: 0, company: 'Papyrus (between 9am & midnight)', contact: '0800 068 4141' },
  { id: 1, company: 'OCD Action', contact: '0300 636 5478' },
  { id: 2, company: 'AnxietyUK', contact: '03444 775 774' },
  { id: 3, company: 'C.A.L.M. (Campaign against living miserably)', contact: '0800 585858' },
  { id: 4, company: 'The Mix (aimed at people under 25)', contact: '0808 808 4994' },
  { id: 5, company: 'Mood Swings (10am to 4pm)', contact: '0161 832 37 36' },
  {
    id: 6,
    company: 'Seed (Eating Disorder Support Service)',
    contact: '01482 718130',
  },
  {
    id: 7,
    company: 'Aware: Depression & Bipolar Disorder Support (Ireland)',
    contact: '1800 80 48 48',
  },
  {
    id: 8,
    company: 'Pieta House Suicide Therapeutic Support (Ireland)',
    contact: '1800 247 247',
  },
];

export const SouthAfricaContactsData = [
  { id: 0, company: 'Toll Free Crisis Line', contact: '086 157 4747' },
  { id: 1, company: 'Tears Foundation (free SMS helpline)', contact: '*134*7355#' },
  { id: 2, company: 'Suicide Help', contact: '080 056 7567' },
  { id: 3, company: 'The South African Depression and Anxiety Group', contact: '080 021 2223' },
];

export const USContactsData = [
  { id: 0, company: 'Emergency', contact: '911' },
  { id: 1, company: 'National Suicide Prevention Lifeline', contact: '+1 (800) 273-8255' },
  { id: 2, company: 'National Domestic Violence Hotline', contact: '+1 (800) 799-7233' },
  { id: 3, company: 'Family Violence Helpline', contact: '+1 (800) 996-6228' },
  { id: 4, company: 'National Hopeline Network', contact: '+1 (800) 784-2433' },
  { id: 5, company: 'National Crisis Line, Anorexia and Bulimia', contact: '+1 (800) 233-4357' },
];

export const GreeceContactsData = [
  { id: 0, company: 'Klimaka (Suicide Helpline)', contact: '1018' },
  { id: 1, company: 'National Centre for Immediate Social Assistance - EKAKB', contact: '197' },
  { id: 2, company: 'EKAB', contact: '166' },
  { id: 3, company: 'LGBTI Support Hotline (Mon – Fri: 13:00 – 21:00)', contact: '11528' },
  { id: 4, company: 'Drug and Alcohol Addiction Helpline', contact: '210 361 7089' },
];

export const SaudiArabiaContactsData = [
  { id: 0, company: '24/7 Unified Emergency Services', contact: '999 / 112' },
  { id: 1, company: 'Psychological Counseling Contact Center', contact: '920033360 ' },
];

export const CanadaContactsData = [
  { id: 0, company: 'Emergency', contact: '911' },
  { id: 1, company: 'Hotline', contact: '+1 (888) 353-2273' },
];

export const SerbiaContactsData = [
  {
    id: 0,
    company: 'Befrienders Worldwide',
    contact: 'https://www.befrienders.org/serbian',
    url: 'https://www.befrienders.org/serbian',
  },
  { id: 1, company: 'Besplatan broj', contact: '0800/ 300 303' },
  { id: 2, company: 'SRCE Email', contact: 'vanja@centarsrce.org' },
  { id: 3, company: 'Čet preko našeg sajta', contact: 'http://www.centarsrce.org/', url: 'http://www.centarsrce.org/' },
];
