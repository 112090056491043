import React from 'react';
import styled from 'styled-components';
import { Avatar, Heading, Loading } from '../../Atoms';
import { useTranslation } from 'react-i18next';
// import Calendar from 'react-calendar';

const TherapistProfile = ({ user, specialisms, tools }) => {
  const { t } = useTranslation();
  return user ? (
    <ProfileWrapper>
      <TherapistAvatar>
        {user.profile_image && <Avatar src={user.profile_image} alt={user.first_name} width={207} />}
        {user.profile_status && (
          <p style={{ margin: '16px 0' }}>
            <b>{t("profileStatus")}</b> <Badge className={user.profile_status.toLowerCase()}>{user.profile_status}</Badge>
          </p>
        )}
        <table className="compact">
          <tbody>
            {user.city && (
              <tr>
                <th>{t("location")}</th>
                <td>
                  {user.city}, {user.country}
                </td>
              </tr>
            )}
            {user.experience && (
              <tr>
                <th>{t("yearsOfExperience")}</th>
                <td>{user.experience} {t("years")}</td>
              </tr>
            )}
            {user.work_with_children && (
              <tr>
                <th>{t("authorisedToWorkWithChildren")}</th>
                <td>{t("yes")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </TherapistAvatar>
      <TherapistDetails>
        <TherapistName>
          <Heading level={1}>
            {user.first_name} {user.last_name}
          </Heading>
        </TherapistName>

        <ProfileSection>
          <SectionHeading level={3} text={t("experienceSummary")} />
          <p>{user.exp_summary || t("noExperienceSpecified")}</p>
        </ProfileSection>

        <ProfileSection>
          <SectionHeading level={3} text="About Me" />
          {user.about_me ? user.about_me.split('\n').map((item, i) => (
            <p key={i}>{item}</p>
          )) : (
            <p>{t("noInformationSpecified")}</p>
          )}
        </ProfileSection>

        <ProfileSection>
          <SectionHeading level={3} text={t("therapySpecialisms")} />
          <ListWrapper>
            {specialisms?.length ? specialisms.map((item) => (
              <ListItem key={item.id}>{item.specialism_name}</ListItem>
            )) : (
              <p>{t("noTherapySpecialismsSpecified")}</p>
            )}
          </ListWrapper>
        </ProfileSection>

        <ProfileSection>
          <SectionHeading level={3} text={t("therapyTools")} />
          <ListWrapper>
            {tools?.length ? tools.map((item) => (
              <ListItem key={item.id}>{item.tool_name}</ListItem>
            )) : (
              <p>{t("noTherapyToolsSpecified")}</p>
            )}
          </ListWrapper>
        </ProfileSection>

        {/* <Heading level={3} text="Availability" /> */}
        {/* <Calendar /> */}
        {/* <Heading level={3} text="Credentials" /> */}
      </TherapistDetails>
    </ProfileWrapper>
  ) : (
    <Loading />
  );
};

const ProfileWrapper = styled.section`
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 320px 1fr;
    column-gap: ${({ theme }) => theme.spacingXl};
  `}
`;

const TherapistAvatar = styled.div`
  text-align: center;
  filter: drop-shadow(0px 0px 27px rgba(73, 31, 105, 0.14));
`;

const TherapistName = styled.div`
  text-align: center;

  ${({ theme }) => theme.md`
    text-align: left;
    
    h1 {
      margin-top: 0;
    }
  `}
`;

const TherapistDetails = styled.div``;

const ProfileSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingMd};
`;

const SectionHeading = styled(Heading)`
  margin-bottom: ${({ theme }) => theme.spacing};
`;

const ListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  margin-right: ${({ theme }) => theme.spacingSm};
  margin-bottom: ${({ theme }) => theme.spacing};
  background-color: ${({ theme }) => theme.primaryLight};
  border-radius: 5em;
  padding: 4px ${({ theme }) => theme.spacing};
`;

const Badge = styled.span`
  padding: 3px 6px;
  margin-left: 2px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontMedium};
  background-color: ${({ theme }) => theme.statusInfo};
  color: ${({ theme }) => theme.white};

  &.approved {
    background-color: ${({ theme }) => theme.statusSuccess};
  }
`;

export default TherapistProfile;
