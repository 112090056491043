import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, TimeSeriesBarChart } from '../../Molecules';
import { Button, DatePicker, Heading, Widget } from '../../Atoms';

const TimeSeriesWidget = ({ chartData, heading, optionSelects, timeFrame, onTimeFrameChange, onTimePeriodChange }) => {
  const [timePeriod, setTimePeriod] = useState('day');
  const { t } = useTranslation();
  const handleSetTimeFrame = (type, date) => {
    onTimeFrameChange(type, date);
  };

  const handleSetTimePeriod = timePeriod => {
    setTimePeriod(timePeriod);
  };

  useEffect(() => {
    if (onTimePeriodChange) onTimePeriodChange(timePeriod);
  }, [timePeriod, onTimePeriodChange]);

  return (
    <Widget>
      <StyledHeading>
        <Heading level={4}>{heading}</Heading>
        <TimeFrameWrapper>
          <DatePicker
            name="from"
            onChange={date => handleSetTimeFrame('from', date)}
            clearIcon={null}
            format="dd MMM yyyy"
            value={timeFrame.from}
          />
          <span><b>-</b></span>
          <DatePicker
            name="to"
            onChange={date => handleSetTimeFrame('to', date)}
            clearIcon={null}
            format="dd MMM yyyy"
            value={timeFrame.to}
          />
        </TimeFrameWrapper>
      </StyledHeading>
      <ChartControls>
        <OptionSelectWrapper>
          {optionSelects}
        </OptionSelectWrapper>
        <TimePeriodWrapper>
          <ButtonGroup value={timePeriod}>
            <Button name="day" onClick={() => handleSetTimePeriod('day')}>{t("weeklyLabel")}</Button>
            <Button name="month" onClick={() => handleSetTimePeriod('month')}>{t("monthlyLabel")}</Button>
            {/*<Button name="year" onClick={() => handleSetTimePeriod('year')}>Year</Button>*/}
          </ButtonGroup>
        </TimePeriodWrapper>
      </ChartControls>
      <ChartWrapper>
        <TimeSeriesBarChart
          data={chartData.data}
          label={chartData.label}
          timePeriod={timePeriod}
          striped
        />
      </ChartWrapper>
    </Widget>
  );
};

const StyledHeading = styled.header`
  margin-bottom: ${({ theme }) => theme.spacingMd};

  h4 {
    margin: 0;
    text-align: center;
  }
  
  ${({ theme }) => theme.md`
    margin-bottom: ${({ theme }) => theme.spacingXl};
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h4 {
      text-align: initial;
    }
  `}
`;
StyledHeading.displayName = 'StyledHeading';

const OptionSelectWrapper = styled.div`
  display: grid;
  row-gap: 25px;
  
  ${({ theme }) => theme.md`
    grid-template-columns: repeat(auto-fit, minmax(100px, 180px));
    column-gap: 50px;
  `}
`;

const TimeFrameWrapper = styled.div`
   margin: ${({ theme }) => theme.spacingMd} 0;
   display: grid;
   grid-template-columns: 1fr auto 1fr;
   column-gap: 2px;
   align-items: center;

  ${({ theme }) => theme.md`
    margin: 0;
  `}
`;
TimeFrameWrapper.displayName = 'TimeFrameWrapper';

const ChartControls = styled.div`
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 1fr 132px;
    align-items: center;
  `}
`;

const TimePeriodWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacingMd};
  
  ${({ theme }) => theme.md`
    margin-top: initial;
  `}
`;
TimePeriodWrapper.displayName = 'TimePeriodWrapper';

const ChartWrapper = styled.div`
  margin-top: 50px;
`;
ChartWrapper.displayName = 'ChartWrapper';

TimeSeriesWidget.propTypes = {
  chartData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    label: PropTypes.string,
  }).isRequired,
  heading: PropTypes.string.isRequired,
  optionSelects: PropTypes.element.isRequired,
  timeFrame: PropTypes.shape({
    from: PropTypes.object,
    to: PropTypes.object,
  }).isRequired,
  onTimePeriodChange: PropTypes.func,
  onTimeFrameChange: PropTypes.func.isRequired,
};

export default TimeSeriesWidget;
