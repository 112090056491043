import React from 'react';
import styled from 'styled-components';
import { useDeviceType } from '../../Util';
export const ButtonTextWrapper = ({ children }) => {
  const isMobile = useDeviceType();
  return <ButtonTextWrapperSty isMobile={isMobile}>{children}</ButtonTextWrapperSty>;
};
export const IconWrapper = ({ children }) => {
  const isMobile = useDeviceType();
  return <IconWrapperSty isMobile={isMobile}>{children}</IconWrapperSty>;
};

export const SignUpButton = (props) => {
  const isMobile = useDeviceType();
  return (
    <Button isMobile={isMobile} {...props}>
      {props.children}
    </Button>
  );
};
const Button = styled.button`
  position: relative;
  display: flex;
  background: #00c8a0;
  margin-bottom: 10px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '350px')};
  outline: none;
  color: white;
  font-weight: 600;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  font-size: 14px;
  &:hover {
    background: #00b891;
  }
`;
export const SocialIcon = styled.svg`
  width: 20px;
  path {
    fill: white;

    &:first-child {
      fill: white !important;
    }
  }
`;
const ButtonTextWrapperSty = styled.div`
  padding: ${({ isMobile }) => (isMobile ? '10px 35px' : '15px 45px')};
  text-align: left;
  flex: 1;
`;
const IconWrapperSty = styled.div`
  padding: ${({ isMobile }) => (isMobile ? '10px' : '15px')};
  border-right: 1px solid white;
`;
