import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AuthLayout } from '../../Layout';
import { Link } from 'react-router-dom';
import { logout } from '../../Actions/auth.actions';
import { useDispatch } from 'react-redux';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    localStorage.removeItem('loginInfo');
  }, [dispatch]);

  return (
    <AuthLayout>
      <Wrapper>
        <h1>You have been logged out.</h1>

        <Link to="/t/login">Return to Login</Link>
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;
export default Logout;
