import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from '../../../Assets/avatar.png';

const StyledImage = styled.img`
  object-fit: cover;
  border: ${(props) => `${props.borderWidth} solid ${props.borderColor}`};
  border-radius: 100%;
  padding: ${(props) => props.padding || '3px'};
  box-shadow: 0 0 20px rgba(73, 31, 105, 0.1);
`;

const Avatar = (props) => {
  const { src, alt, className, width, borderColor, borderWidth, padding } = props;

  return (
    <StyledImage
      borderColor={borderColor}
      padding={padding}
      borderWidth={borderWidth}
      src={src}
      alt={alt}
      width={width}
      height={width}
      className={className}
    />
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.string,
  padding: PropTypes.string,
};
Avatar.defaultProps = {
  src: Image,
  alt: '',
  className: '',
  width: 90,
};

export default Avatar;
