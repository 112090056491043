import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getActiveChatRooms } from '../../Actions/chatroom.actions';
import { getSubscriptionStatus } from '../../Actions/patient.actions';
import { useTranslation } from 'react-i18next';
import { Button, Heading } from '../../Components/Atoms';
import { AuthLayout } from "../../Layout";

const SuccessPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveChatRooms());
    dispatch(getSubscriptionStatus());
  }, [dispatch]);

  return (
    <AuthLayout>
      <ConfirmationWrapper>
        <Heading align="center" text={t("paymentConfirmation")} />
        <p>{t("paymentConfirmed")}</p>
        <p>{t("emailSent")}</p>
        <Button as={Link} to="/therapist-assignment" className="secondary">
        {t("continue")}
        </Button>
      </ConfirmationWrapper>
    </AuthLayout>
  );
};

const ConfirmationWrapper = styled.div`
  text-align: center;
  margin-top: 50px;
`;

export default SuccessPage;
