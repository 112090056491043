import api from '../Util/api';
import {
  GET_THERAPIST_SCHEDULE_LIST,
  GET_CONCERNING_ISSUES,
  GET_SUB_CONCERNING_ISSUES,
  GET_PUBLIC_THERAPIST_PROFILE,
  ACTIVE_CHAT_ROOMS,
  GET_PROFILE,
  UPDATE_PROFILE,
  GET_PATIENT_PROFILE,
  GET_PATIENT_STATS,
  GET_COMMISSION,
  GET_QUESTIONNAIRE_RESULT,
  GET_TOOLS,
  GET_THERAPIST_TOOLS,
  UPDATE_THERAPIST_TOOLS,
  DELETE_THERAPIST_TOOL,
  GET_SPECIALISMS,
  GET_SPECIALISM,
  GET_PROFESSIONAL_TYPES,
  GET_PROFESSIONAL_TYPE,
  UPDATE_SPECIALISM,
  DELETE_SPECIALISM,
  CLEAR_PATIENT_STATS,
  GET_PUBLIC_THERAPIST_BEGIN,
} from '../Constants/actionTypes';

export const scheduleAppointment = (data) => () =>
  api.therapist
    .scheduleAppointment(data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
    
export const completeAppointment = (data) => () =>
  api.therapist
    .completeAppointment(data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);

export const getProfile = () => (dispatch) =>
  api.therapist
    .getProfile()
    .then((res) => {
      dispatch({
        type: GET_PROFILE,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getConcernigIssues = () => (dispatch) =>
  api.therapist
    .fetchConcerningIssues()
    .then((res) => {
      dispatch({
        type: GET_CONCERNING_ISSUES,
        payload: res.data.data?.concerning_issues,
      });
    })
    .catch((err) => console.log(err));

export const getSubConcernigIssues = (concerning_issue_id) => (dispatch) => {
  api.therapist
    .fetchSubConcerningIssues(concerning_issue_id)
    .then((res) => {
      dispatch({
        type: GET_SUB_CONCERNING_ISSUES,
        payload: { [concerning_issue_id]: res.data.data?.concerning_issues },
      });
    })
    .catch((err) => console.log(err));
};

export const updateProfile = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.therapist
      .updateProfile(data)
      .then((res) => {
        dispatch({
          type: UPDATE_PROFILE,
          payload: res.data?.data,
        });
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const getScheduleList = (status = null) => (dispatch) =>
  api.therapist
    .getScheduleList(status)
    .then((res) => {
      dispatch({
        type: GET_THERAPIST_SCHEDULE_LIST,
        payload: res.data.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const addSchedule = (data) => (dispatch) =>
  api.therapist
    .addSchedule(data)
    .then((res) => {
      dispatch(getScheduleList());
      return res.data;
    })
    .catch((err) => err);

export const copySchedule = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.therapist
      .copySchedule(data)
      .then((res) => {
        dispatch(getScheduleList());
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const deleteScheduledSlot = (id) => (dispatch) =>
  api.therapist
    .deleteScheduledSlot(id)
    .then((res) => {
      dispatch(getScheduleList());
      return res.data;
    })
    .catch((err) => err);

export const getPublicTherapistProfile = (id) => (dispatch) => {
  dispatch({ type: GET_PUBLIC_THERAPIST_BEGIN });
  api.therapist
    .getPublicTherapistProfile(id)
    .then((res) => {
      dispatch({
        type: GET_PUBLIC_THERAPIST_PROFILE,
        payload: res.data.data,
      });
      return res.data;
    })
    .catch((err) => err);
};

export const getActiveChatRooms = () => (dispatch) =>
  api.therapist
    .activeChatRooms()
    .then((res) => {
      dispatch({
        type: ACTIVE_CHAT_ROOMS,
        payload: res?.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getPatientProfile = (id) => (dispatch) =>
  api.therapist
    .getPatientProfile(id)
    .then((res) => {
      dispatch({
        type: GET_PATIENT_PROFILE,
        payload: res.data.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getPatientStats = (id, startDate = null, endDate = null) => (dispatch) =>
  api.therapist
    .getPatientStats(id, startDate, endDate)
    .then((res) => {
      dispatch({
        type: GET_PATIENT_STATS,
        payload: res.data.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const clearPatientStats = () => (dispatch) => dispatch({ type: CLEAR_PATIENT_STATS });

export const getCommission = () => (dispatch) =>
  api.therapist
    .getCommission()
    .then((res) => {
      dispatch({
        type: GET_COMMISSION,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getQuestionnaireResult = (id) => (dispatch) =>
  api.chatroom
    .getQuestionnaireResult(id)
    .then((res) => {
      dispatch({
        type: GET_QUESTIONNAIRE_RESULT,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getTools = () => (dispatch) =>
  api.general
    .getTools()
    .then((res) => {
      dispatch({
        type: GET_TOOLS,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getTherapistTools = () => (dispatch) =>
  api.therapist
    .getTools()
    .then((res) => {
      dispatch({
        type: GET_THERAPIST_TOOLS,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const updateTools = (toolID) => (dispatch) =>
  api.therapist
    .updateTools(toolID)
    .then((res) => {
      dispatch({
        type: UPDATE_THERAPIST_TOOLS,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const deleteTool = (toolID) => (dispatch) =>
  api.therapist
    .deleteTool(toolID)
    .then((res) => {
      dispatch({
        type: DELETE_THERAPIST_TOOL,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getSpecialism = () => (dispatch) =>
  api.general
    .getSpecialism()
    .then((res) => {
      dispatch({
        type: GET_SPECIALISM,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getSpecialisms = () => (dispatch) =>
  api.therapist
    .getSpecialisms()
    .then((res) => {
      dispatch({
        type: GET_SPECIALISMS,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getProfessionalTypes = () => (dispatch) =>
  api.therapist
    .getProfessionalTypes()
    .then((res) => {
      dispatch({
        type: GET_PROFESSIONAL_TYPES,
        payload: res.data?.data,
      });
      return res.data;
    })
  .catch((err) => err);

export const getProfessionalType = (id) => (dispatch) =>
  api.therapist
    .getProfessionalType(id)
    .then((res) => {
      dispatch({
        type: GET_PROFESSIONAL_TYPE,
        payload: res.data?.data,
      });
      return res.data;
    })
  .catch((err) => err)

export const updateSpecialism = (specialismID) => (dispatch) =>
  api.therapist
    .updateSpecialism(specialismID)
    .then((res) => {
      dispatch({
        type: UPDATE_SPECIALISM,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const deleteSpecialism = (specialismID) => (dispatch) =>
  api.therapist
    .deleteSpecialism(specialismID)
    .then((res) => {
      dispatch({
        type: DELETE_SPECIALISM,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

export const getBankDetails = () => (dispatch) =>
  api.therapist
    .getBankDetails()
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);

export const saveBankDetails = (data) => (dispatch) =>
  api.therapist.saveBankDetails(data).then((res) => {
    return res.data;
  });
