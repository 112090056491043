export const PaymentPlansData = (t) => [
  {
    id: 0,
    title: t("PaymentPlan1"),
    price: 39.99
  },
  {
    id: 1,
    title: t("PaymentPlan2"),
    price: 79.99,
    description: t("optionalDescription"),
  },
  {
    id: 2,
    title: t("PaymentPlan3"),
    price: 109.99
  },
  {
    id: 3,
    title: t("PaymentPlan4"),
    price: 249.99
  },
];
