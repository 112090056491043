import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const SectionWrapper = styled.section`
  padding: ${props => (props.padded ? "2em 0" : "0")};
`;

const Section = props => {
  const { children } = props;
  return (
    <>
      <SectionWrapper {...props}>{children}</SectionWrapper>
    </>
  );
};

Section.propTypes = {
  children: PropTypes.node,
};

export default Section;
