import React from 'react';
import styled from 'styled-components';

import { Heading } from '../../Components/Atoms';
import { PaymentPlans } from '../../Components/Organisms';
import { AuthLayout } from '../../Layout';
import { useTranslation } from 'react-i18next';

const Checkout = () => {
  const { t } = useTranslation();
  return (
    <AuthLayout>
      <Wrapper>
        <Heading align="center" text={t("selectAPlan")} />
        <PaymentPlans />
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  margin: auto;
  max-width: 900px;
  width: 100%;
  text-align: left;
`;

export default Checkout;
