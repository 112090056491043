import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import IBAN from 'iban';

import { getBankDetails, saveBankDetails } from '../../../Actions/therapist.actions';
import { Alert, Button, FormGroup, Label, Loading } from '../../../Components/Atoms';

const BankDetails = () => {
  const [bankDetails, setBankDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [responseMessage, setResponseMessage] = useState({ type: '', message: null });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const fetchBankDetails = useCallback(async () => {
    dispatch(getBankDetails()).then((res) => {
      if (res.status !== 'error') {
        setBankDetails(res.data);
      }
      setIsEditing(res.status === 'error');
      setIsEmpty(res.status === 'error');
      setIsLoading(false);
    });
  }, [dispatch]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    setLoading(true);
    try {
      await dispatch(saveBankDetails(values));
      await fetchBankDetails();
    } catch (err) {
      const errors = err.response.data.errors;
      setResponseMessage({
        type: 'danger',
        message: Object.keys(errors).map((key) => (
          <>
            <span key={key}>{Object.values(errors[key]).join(' ')}</span>
            <br />
          </>
        )),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBankDetails();
  }, [fetchBankDetails]);

  const validationSchema = Yup.object().shape({
    bank_name: Yup.string().required('Bank name is required'),
    sort_code: Yup.string(),
    account_number: Yup.number(),
    swift: Yup.number(),
    iban: Yup.string().test('isValid', 'IBAN is not valid', (iban) => !iban || IBAN.isValid(iban)),
  });

  const initialValues = useMemo(
    () => ({
      bank_name: bankDetails?.bank_name || '',
      sort_code: bankDetails?.sort_code || '',
      account_number: bankDetails?.account_number || '',
      swift: bankDetails?.swift || '',
      iban: bankDetails?.iban || '',
    }),
    [bankDetails],
  );

  if (isLoading) return <Loading />;

  return (
    <>
      {isEmpty && (
        <StyledAlert className={'danger'}>
          You haven't provided your bank details yet. Please, fill in the form below.
        </StyledAlert>
      )}
      {isEditing ? (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <StyledFormGroup>
                <Label htmlFor={'bank_name'}>Bank Name</Label>
                <Field
                  id={'bank_name'}
                  name={'bank_name'}
                  className={'form-control' + (errors.bank_name && touched.bank_name ? ' is-invalid' : '')}
                />
                <ErrorMessage name={'bank_name'} component={'div'} className={'invalid-feedback'} />
              </StyledFormGroup>

              <StyledFormGroup>
                <Label htmlFor={'sort_code'}>Sort Code</Label>
                <Field
                  id={'sort_code'}
                  name={'sort_code'}
                  className={'form-control' + (errors.sort_code && touched.sort_code ? ' is-invalid' : '')}
                />
                <ErrorMessage name={'sort_code'} component={'div'} className={'invalid-feedback'} />
              </StyledFormGroup>

              <StyledFormGroup>
                <Label htmlFor={'account_number'}>Account Number</Label>
                <Field
                  id={'account_number'}
                  name={'account_number'}
                  className={'form-control' + (errors.account_number && touched.account_number ? ' is-invalid' : '')}
                  onChange={({ target }) => setFieldValue('account_number', target.value)}
                />
                <ErrorMessage name={'account_number'} component={'div'} className={'invalid-feedback'} />
              </StyledFormGroup>

              <StyledFormGroup>
                <Label htmlFor={'iban'}>IBAN</Label>
                <Field
                  id={'iban'}
                  name={'iban'}
                  className={'form-control' + (errors.iban && touched.iban ? ' is-invalid' : '')}
                />
                <ErrorMessage name={'iban'} component={'div'} className={'invalid-feedback'} />
              </StyledFormGroup>

              <StyledFormGroup>
                <Label htmlFor={'swift'}>SWIFT</Label>
                <Field
                  id={'swift'}
                  name={'swift'}
                  className={'form-control' + (errors.swift && touched.swift ? ' is-invalid' : '')}
                  onChange={({ target }) => setFieldValue('swift', target.value)}
                />
                <ErrorMessage name={'swift'} component={'div'} className={'invalid-feedback'} />
              </StyledFormGroup>

              {responseMessage.type && (
                <StyledAlert className={responseMessage.type}>{responseMessage.message}</StyledAlert>
              )}

              <Button type={'submit'} loading={loading} disabled={loading}>
                Save
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <BankDetailsWrapper>
            <BankDetail>
              <div>
                <b>Bank Name:</b>
              </div>
              <div>
                <span>{bankDetails.bank_name}</span>
              </div>
            </BankDetail>
            <BankDetail>
              <div>
                <b>Sort Code:</b>
              </div>
              <div>
                <span>{bankDetails.sort_code || '-'}</span>
              </div>
            </BankDetail>
            <BankDetail>
              <div>
                <b>Account Number:</b>
              </div>
              <div>
                <span>{bankDetails.account_number || '-'}</span>
              </div>
            </BankDetail>
            <BankDetail>
              <div>
                <b>IBAN:</b>
              </div>
              <div>
                <span>{bankDetails.iban || '-'}</span>
              </div>
            </BankDetail>
            <BankDetail>
              <div>
                <b>SWIFT:</b>
              </div>
              <div>
                <span>{bankDetails.swift || '-'}</span>
              </div>
            </BankDetail>
            <ButtonWrapper>
              <Button onClick={() => setIsEditing(true)}>Edit</Button>
            </ButtonWrapper>
          </BankDetailsWrapper>
        </>
      )}
    </>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing};
`;
StyledAlert.displayName = 'StyledAlert';

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: ${({ theme }) => theme.spacing};
  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 1fr 2fr;
  `};
`;
StyledFormGroup.displayName = 'StyledFormGroup';

const BankDetailsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacingMd} ${({ theme }) => theme.spacingLg};
  max-width: 500px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.greyLight};
`;

const BankDetail = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing};
  word-break: break-all;

  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: ${theme.spacing};
  `};
`;
BankDetail.displayName = 'BankDetail';

const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacingMd};
  display: flex;
  justify-content: flex-end;
`;

export default BankDetails;
