import React from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Loading from '../../Components/Atoms/Loading/Loading';
import { Heading } from '../../Components/Atoms';
import PersonalInfo from './Components/PersonalInfo';
import { ChangePasswordForm } from '../../Components/Organisms';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Edit = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((store) => store.user.profile);

  return (
    <DetailsWrapper>
      <Heading text={t("editAccountDetails")} />
      {!userInfo ? (
        <Loading />
      ) : !userInfo['social_account'] ? (
        <Tabs>
          <TabList>
            <Tab>{t("personalInfo")}</Tab>
            <Tab>{t("changePassword")}</Tab>
          </TabList>
          <TabPanel>
            <PersonalInfo userInfo={userInfo} />
          </TabPanel>
          <TabPanel>
            <ChangePasswordForm />
          </TabPanel>
        </Tabs>
      ) : (
        <PersonalInfo userInfo={userInfo} />
      )}
    </DetailsWrapper>
  );
};

const DetailsWrapper = styled.ul`
  max-width: 500px;
`;

export default Edit;
