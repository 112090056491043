import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, Heading } from '../../../Components/Atoms';
import { connect, useDispatch } from 'react-redux';
import { getPublicTherapistProfile } from '../../../Actions/therapist.actions';
import { getActiveChatRooms } from '../../../Actions/chatroom.actions';
import Tooltip from '../../../Components/Atoms/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import OnlineIcon from '../../../Components/Molecules/Chatroom/OnlineIcon';

const Profile = ({ chatRoom, therapistProfile, showBio }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const therapistId = useMemo(() => {
    if (chatRoom?.rooms?.length) return chatRoom.rooms[0].therapist_id;
    return undefined;
  }, [chatRoom]);

  useEffect(() => {
    if (chatRoom.rooms === null) {
      dispatch(getActiveChatRooms()).then((res) => {
        if (res.length === 0) history.push('/questionnaire');
      });
    }
  }, [chatRoom, dispatch, history]);

  useEffect(() => {
    if (therapistId) dispatch(getPublicTherapistProfile(therapistId));
  }, [therapistId, dispatch]);

  return (
    <ProfileWrapper>
      <Tooltip
        id="therapistOnline"
        text={therapistProfile?.is_online ? t('therapistIsOnline') : t('therapistIsOffline')}
        place="top"
      >
        <div style={{ position: 'relative' }}>
          <Avatar
            src={therapistProfile?.profile_image || undefined}
            width={50}
            borderColor={therapistProfile?.is_online ? '#2dae95' : '#aeadad'}
            borderWidth="3px"
          />
        </div>
      </Tooltip>
      <ProfileDetails>
        <StyledHeading level={3} text={therapistProfile?.full_name} />
        {showBio && <StyledDescription>{therapistProfile?.about_me}</StyledDescription>}
        <Tooltip id="viewProfile" text={t('viewTherapistProfile')} place="right" offset={{ right: 10 }}>
          <StyledLink to="/therapistprofile">{t('viewProfile')}</StyledLink>
        </Tooltip>
      </ProfileDetails>
    </ProfileWrapper>
  );
};

// const OnlineIcon = styled.span`
//   content: '';
//   height: 10px;
//   width: 10px;
//   border: 1px solid #fff;
//   display: inline-block;
//   background-color: green;
//   border-radius: 50%;
//   position: absolute;
//   right: 2px;
//   top: 66%;
//   -webkit-transform: translateY(-50%);
//   transform: translateY(-50%);
//   margin-top: 9px;
// `;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 768px) {
    margin: 12px 0 10px;
  }
`;

const ProfileDetails = styled.div`
  margin-left: ${({ theme }) => theme.spacing};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 0;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.primary};
  font-weight: normal;
  text-decoration: underline;
`;

const StyledDescription = styled.div`
  max-width: 300px;
  margin: ${({ theme }) => theme.spacing} 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const mapStateToProps = (store) => ({
  chatRoom: store.chatroom,
  therapistProfile: store.user.therapistProfile,
});

export default connect(mapStateToProps)(Profile);
