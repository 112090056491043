import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Heading } from '../../Components/Atoms';
import Loading from '../../Components/Atoms/Loading/Loading';
import api from '../../Util/api';
import { useHistory } from 'react-router-dom';
import Tooltip from '../../Components/Atoms/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

const DetailItem = (props) => {
  const { t } = useTranslation();

  return (
    <DetailItemWrapper>
      {props.label === t("anonymousProfile?")  ? (
        <Tooltip
          id="Anonymous"
          text={t("anonymousProfileTooltip")}
          place="right"
        >
          <Heading level={5} text={props.label} />
        </Tooltip>
      ) : (
        <Heading level={5} text={props.label} />
      )}
      <ValueWrapper>
        <p>{props.value ? props.value : t("notSet")}</p>
      </ValueWrapper>
    </DetailItemWrapper>
  );
};

const Details = () => {
  const [userInfo, setUserInfo] = useState(undefined);
  const history = useHistory();
  const { t } = useTranslation();

  const getUser = () => {
    api.patient
      .getProfile()
      .then((user) => {
        if (user.status === 200) {
          setUserInfo(user.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getUser();
  }, []);

  if (!userInfo) return <Loading />;

  return (
    <>
      <Heading text={t("accountDetails")} />
      <DetailsWrapper>
        <DetailItem label= {t("fullName")} value={`${userInfo.first_name} ${userInfo.last_name}`} />
        <DetailItem label={t("nickname")} value={userInfo.nickname ? userInfo.nickname : null} />
        <DetailItem label={t("emailAddress")} value={userInfo.email} />
        <DetailItem label={t("phoneNumber")} value={userInfo.mobile} />
        <DetailItem label={t("dateOfBirth")} value={userInfo.date_of_birth ? userInfo.date_of_birth : null} />
        <DetailItem label={t("anonymousProfile?")} value={userInfo.anonymous ? t("yes") : t("no")} />
        {!userInfo['social_account'] && (
          <DetailItem label={t("twoFactorAuthentication")} value={userInfo['2fa_active'] ?  t("activated") : t("deactivated")} />
        )}
      </DetailsWrapper>
      <Tooltip id="EditProfile" text={t("editYourProfile")} place="right">
        <Button
          onClick={() => {
            history.push('/account/edit');
          }}
        >
          {t("edit")}
        </Button>
      </Tooltip>
    </>
  );
};

const DetailsWrapper = styled.ul`
  max-width: 500px;
`;
const DetailItemWrapper = styled.li`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: ${({ theme }) => theme.spacing};
`;
const ValueWrapper = styled.div`
  width: 100%;
`;

export default Details;
