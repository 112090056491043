import React from 'react';
import { useSelector } from 'react-redux';
import { ModalComponent } from '../../Atoms';
import ReactPlayer from 'react-player/vimeo';

const TrainingVideo = ({ showVideo, setShowVideoFlag }) => {
  const isB2b = useSelector((store) => store.user?.profile?.['B2B'] !== 0);
  return (
    <>
      {isB2b && (
        <ModalComponent open={showVideo} closeModal={() => setShowVideoFlag(false)} closeOnDocumentClick={false}>
          <div>
            <ReactPlayer
              width="100%"
              url="https://vimeo.com/638532810/7c9cc2b4b3"
              playing
              controls
              onPlay={() => localStorage.setItem('showTutorialVideo', 'false')}
            />
          </div>
        </ModalComponent>
      )}
    </>
  );
};

export default TrainingVideo;
