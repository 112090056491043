import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { AuthLayout } from '../../Layout';
import { TherapistSignupForm } from '../../Components/Organisms';
import { OtherSectionPrompt } from '../../Components/Molecules';
import { Loading } from '../../Components/Atoms';
import { Logo, PatientSpecialLayout } from '../../Patient/Signup';
import config, { isNixy } from '../../Config';

const Signup = () => {
  const loginInfo = localStorage.getItem('loginInfo');
  const isLoggedIn = !!loginInfo;
  const userRole = loginInfo ? JSON.parse(loginInfo).role : '';
  const history = useHistory();
  const therapistProfile = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (userRole === 'patient') {
      history.push('/chatroom');
    } else if (therapistProfile) {
      if (!therapistProfile.licence_no) history.push('/t/signup/step-5');
      else history.push('/t/dashboard');
    }
  }, [userRole, history, therapistProfile]);

  return (
    <TherapistSpecialLayout>
      <Helmet>
        <title>Therapist SignUp</title>
        <meta
          name="description"
          content="Are you a registered therapist interested in providing therapy and counselling services online? Click here and discover how you can help people today."
        />
      </Helmet>
      <AuthLayout>
        {isLoggedIn ? (
          <Loading />
        ) : (
          <>
            <Logo src={config.nameLogo} />
            <TherapistSignupForm userType="therapist" heading="Therapist Sign Up" />
            {!isNixy &&
              <OtherSectionPrompt>
                <h5>
                  Are you a Patient? <a href="/signup">Sign up here</a>
                </h5>
              </OtherSectionPrompt>
            }
          </>
        )}
      </AuthLayout>
    </TherapistSpecialLayout>
  );
};

export const TherapistSpecialLayout = styled(PatientSpecialLayout)`
  #siteWrapper {
    background: url('https://mysupportroom.com/static/media/ezgif.com-gif-maker_2.gif') no-repeat center center fixed;
    background-size: cover;
  }
`;

export default Signup;