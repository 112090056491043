import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Heading, Button, ProgressBar } from '../../Components/Atoms';
import { AuthLayout } from '../../Layout';
import CalendarComponent from '../../Components/Atoms/Calendar/Calendar';
import { getCalendarEvents } from '../../Util/calendar';
import { addSchedule, deleteScheduledSlot, getScheduleList } from '../../Actions/therapist.actions';
import moment from 'moment';
import ModalComponent from '../../Components/Atoms/Modal/Modal';
import { getToLocalSchedulesUser } from '../../Selectors/schedules.selector';

const Step5 = () => {
  const [isAvailabilityEditing, setIsAvailabilityEditing] = useState(false);
  const [calendarData, setCalendarDate] = useState(new Date());
  const [availability, setAvailability] = useState([]);

  // States for create Therapist availability
  const [modalState, setModalState] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const schedules = useSelector((store) => getToLocalSchedulesUser(store));

  const history = useHistory();
  const dispatch = useDispatch();

  const nextStep = () => history.push('/t/signup/step-9');

  const toggleEdit = useCallback(() => {
    setIsAvailabilityEditing(!isAvailabilityEditing);
  }, [isAvailabilityEditing]);

  const onSelectSlot = useCallback((e) => {
    setModalState(true);
    setSelectedSchedule(e);
  }, []);

  const navigateToDate = useCallback((date) => setCalendarDate(date), []);

  const deleteSlot = (id) => dispatch(deleteScheduledSlot(id));

  const closeModal = useCallback(() => {
    setModalState(false);
    setSelectedSchedule(null);
  }, []);

  const createAvailability = useCallback(() => {
    const momentDateStart = moment(selectedSchedule.start).utc();
    const momentDateEnd = moment(selectedSchedule.end).utc();

    dispatch(
      addSchedule({
        start_date: momentDateStart.format('YYYY-MM-DD'),
        start_time: momentDateStart.format('HH:mm'),
        end_time: momentDateEnd.format('HH:mm'),
      }),
    ).then(() => setModalState(false));
  }, [selectedSchedule, dispatch]);

  // useEffect: 1. convert stringDates to Date() | 2.Sorting dates | 3.Grouping slots | 4. Pass to calendar
  useEffect(() => {
    if (schedules && schedules.length) {
      const [availabilityEvents] = getCalendarEvents(schedules, isAvailabilityEditing);

      if (availabilityEvents.length) setAvailability(availabilityEvents);
    }
  }, [schedules, isAvailabilityEditing]);

  useEffect(() => {
    dispatch(getScheduleList());
  }, [dispatch]);

  return (
    <AuthLayout>
      <ModalComponent open={modalState} closeModal={closeModal}>
        <TimeRow>
          <TimeWrapper>
            <h4>Start:</h4>
            <p>{moment(selectedSchedule?.start).format('YYYY-MM-DD HH:mm:ss')}</p>
          </TimeWrapper>
          <TimeWrapper>
            <h4>End:</h4>
            <p>{moment(selectedSchedule?.end).format('YYYY-MM-DD HH:mm:ss')}</p>
          </TimeWrapper>
        </TimeRow>
        <Button onClick={createAvailability}>Create availability</Button>
      </ModalComponent>
      <Wrapper>
        <Heading align="center">Availability</Heading>
        <ProgressBar progress="70" style={{ margin: '20px auto', maxWidth: 500 }} />

        <p>
          In order to work as a therapist you need to set up your availability so patients can book video sessions with
          you and so they know when you are more likely to be responsive on messaging chats. To create availability drag
          the timings that are preferential for you. Clients can only pick 30 minute slots for video sessions based on
          this availability (provided they have the right subscription). Once they pick a slot the booking is
          automatically confirmed and you will be notified. Clients will also be able to see when you are more likely to
          be responsive via messaging chats.
        </p>

        <br />

        <CalendarWrapper>
          <StyledButton className="info small" onClick={toggleEdit}>
            {isAvailabilityEditing ? 'Finish Editing' : 'Edit Availability'}
          </StyledButton>
          <CalendarComponent
            date={calendarData}
            events={availability}
            type="availability"
            showMultiDayTimes={true}
            onSelectSlot={onSelectSlot}
            // onSelectEvent={toggleEdit}
            onNavigate={navigateToDate}
            editState={isAvailabilityEditing}
            deleteSlot={deleteSlot}
            // default library settings
            selectable={true}
            style={{ height: '1200px' }}
          />
          <br />
          <Button variant="primary" onClick={nextStep}>
            Continue
          </Button>
        </CalendarWrapper>
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  max-width: 100%;
  margin: auto;
`;
Wrapper.displayName = 'Wrapper';

const ButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.spacingXl} 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 32px;
  }
`;
ButtonWrapper.displayName = 'ButtonWrapper';

const StyledButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 27px;
  z-index: 1;
`;

const CalendarWrapper = styled.div`
  position: relative;
  background: white;
  padding: 30px 20px 20px 20px;
  border-radius: 10px;

  @media screen and (max-width: 700px) {
    .rbc-toolbar {
      margin-top: 40px;
    }
  }
`;

const TimeRow = styled.div`
  display: flex;
  align-items: center;
`;

const TimeWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacingLg};
`;

export default Step5;
