import React from 'react';
import styled from 'styled-components';
import { Container } from '../Components/Atoms';
import config from '../Config';
import GlobalStyle from '../globalStyles';
import { Link } from 'react-router-dom';
import LanguageSwitch from '../Components/LangSwitcher/LanguageSwitch';

const AuthLayout = (props) => {
  return (
    <>
      <GlobalStyle />
      <StyledWrapper id="siteWrapper" {...props}>
        <StyledHeader role="banner">
          <Container>
            <HeaderContent>
              <LogoContainer>
                <Link to="/">
                  <img src={config.iconLogo} alt="" />
                </Link>
              </LogoContainer>
            </HeaderContent>
            {props.showLanguageChangeIcon && (
              <Language>
                <LanguageSwitch showBlueGlobe />
              </Language>
            )}
          </Container>
        </StyledHeader>
        <StyledMain id="mainContent">
          <Container>{props.children}</Container>
        </StyledMain>
      </StyledWrapper>
    </>
  );
};

const Language = styled.div`
  position: absolute;
  right: 50px;
  top: 50px;
  margin-top: 20px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${(props) => props.theme.primaryLight};
  text-align: center;
  padding: ${(props) => props.theme.spacingMd} 0;
`;
const StyledHeader = styled.header`
  background-color: ${(props) => props.theme.primaryLight};
  padding: ${(props) => props.theme.spacingMd} 0;
`;
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LogoContainer = styled.div`
  width: 250px;
  ${({ theme }) => theme.md`
    width: 350px;
  `}
`;
const StyledMain = styled.main`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
`;

export default AuthLayout;
