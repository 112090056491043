import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { FacebookProvider, LoginButton } from 'react-facebook';

import { SignUpButton, SocialIcon } from './signUpComponent';

import { ReactComponent as FacebookIcon } from '../../Assets/SignUp/facebook.svg';

const FACEBOOK_APP_ID = '173734607757017';

const FacebookInitComponent = ({ buttonText, initAuth }) => {
  const handleResponse = (data) => {
    initAuth(data, 'facebook')
  };

  const handleError = (error) => {
    console.error("handleError", error);
  };

  return (
    <FacebookProvider appId={FACEBOOK_APP_ID}>
      <LoginButton
        scope="public_profile,email"
        onCompleted={handleResponse}
        onError={handleError}
        style={{
          padding: 0,
          borderWidth: 0,
          backgroundColor: 'transparent',
        }}
      >
        <SignUpButton>
          <SocialIcon as={FacebookIcon} />
          <span>{buttonText}</span>
        </SignUpButton>
      </LoginButton>
    </FacebookProvider>
  );
};

FacebookInitComponent.propTypes = {
  buttonText: PropTypes.string.isRequired,
};

export default memo(FacebookInitComponent);
