import React from 'react';
import styled from 'styled-components';
import SelectComponent from 'react-select';

const Select = (props) => (
  <StyledSelect {...props} className={'react-select-container'} classNamePrefix={'react-select'} />
);

const StyledSelect = styled(SelectComponent)`
  .react-select {
    &__control {
      border-color: ${({ theme, border }) => (border ? theme[border] : theme.primaryMedium)};
      border-width: 2px;

      &:hover {
        border-color: ${({ theme, border }) => (border ? theme[border] : theme.primaryMedium)};
      }

      &--is-focused {
        box-shadow: none;
      }
    }

    &__indicator-separator {
      background-color: transparent;
    }
    &__indicators {
      align-items: flex-start !important;
      height:40px;
    }
    &__value-container {
      align-items: flex-start !important;
      padding: 10px;
    }

    &__option {
      cursor: pointer;
      color: inherit;
      background-color: ${({ theme }) => theme.primaryLight};

      :not(:hover) {
        background-color: transparent;
      }
      :active {
        background-color: ${({ theme }) => theme.primaryLight};
      }
      &--is-selected {
        background-color: ${({ theme }) => theme.primaryLight} !important;
      }
    }

    &__multi-value {
      background-color: ${({ theme }) => theme.primaryAlpha20};
    }

    &__input {
      input {
        height: 28px;
      }
    }
  }
`;

export default Select;
