import React from "react";
import styled from "styled-components";

const OtherSectionPrompt = props => {
  return <Prompt>{props.children}</Prompt>;
};
const Prompt = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.spacingXl};
  padding: ${({ theme }) => theme.spacingSm};
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 8px;
  * {
    color: ${({ theme }) => theme.white} !important;
    margin: 0;
    padding: 0;
  }
`;
export default OtherSectionPrompt;
