import * as React from "react";
const VideoSession = (props) => (
  <svg
    width={33}
    height={34}
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.7924 25.8649H8.25189"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5405 6.18054H21.9444C22.7181 6.19542 23.4548 6.51448 23.9949 7.06861C24.5351 7.62275 24.8352 8.36735 24.8302 9.14115V20.6181"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7618 29.6132C24.6518 30.2878 24.3103 30.903 23.796 31.3532C23.2816 31.8034 22.6266 32.0603 21.9434 32.08H11.1131C10.3404 32.0673 9.60439 31.7484 9.06684 31.1932C8.5293 30.638 8.23423 29.8921 8.24652 29.1194V16.5405"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 6.18054H3.07243"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3611 6.18054H9.28864"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.18054 9.28867V11.3611"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.18054 1V3.07243"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.9099 32.0789L24.8206 29.6634C24.4528 29.4113 24.1619 29.0622 23.9802 28.655C23.7986 28.2477 23.7332 27.798 23.7914 27.3559V24.8281L22.3378 23.3008C22.146 23.1094 21.999 22.8778 21.9075 22.6227C21.816 22.3677 21.7822 22.0955 21.8086 21.8258C21.835 21.5561 21.9209 21.2956 22.0601 21.0631C22.1993 20.8306 22.3883 20.6319 22.6136 20.4813C22.966 20.2469 23.3888 20.1417 23.81 20.1836C24.2312 20.2254 24.6249 20.4118 24.9243 20.7111L27.0801 22.8668"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.8281 10.9335L29.8868 18.0283C30.6396 19.0825 31.0443 20.3455 31.0443 21.6409V24.6486C31.0444 25.5137 31.3881 26.3433 31.9999 26.955"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6207 18.6129C18.6235 18.6129 20.2472 16.9893 20.2472 14.9865C20.2472 12.9836 18.6235 11.36 16.6207 11.36C14.6178 11.36 12.9942 12.9836 12.9942 14.9865C12.9942 16.9893 14.6178 18.6129 16.6207 18.6129Z"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.7924 25.8648H10.4045C10.4045 24.2168 11.059 22.6361 12.224 21.4705C13.3891 20.3048 14.9694 19.6495 16.6175 19.6486"
      stroke="#442165"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default VideoSession;
