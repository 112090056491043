import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getFaqListing } from '../../../Actions/chatroom.actions';

import { Heading } from '../../../Components/Atoms';

const FaqListing = ({ heading, faq, accordion }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(!accordion);

  const toggleAccordion = () => {
    setIsOpen((prevState) => !prevState);
  };

  const generateList = (lines, char = '●') => {
    return (
      <ul>
        {lines
          .filter((line) => line.trim())
          .map((line) => {
            const [headline, ...rest] = line.split('○');
            return (
              <li key={line}>
                {char} {headline}
                {rest.length >= 0 && generateList(rest, '○')}
              </li>
            );
          })}
      </ul>
    );
  };

  const parseAnswer = (answer) => {
    const listStartIndex = answer.indexOf('●');
    return (
      <>
        {listStartIndex >= 0 ? answer.slice(0, listStartIndex) : answer}
        {listStartIndex >= 0 && generateList(answer.slice(listStartIndex).split('●'))}
      </>
    );
  };

  return (
    <>
      {heading ? (
        <>
          {accordion ? (
            <AccordionHeader className={isOpen && 'open'} onClick={toggleAccordion} level={3}>
              {heading}
            </AccordionHeader>
          ) : (
            <Heading level={3}>{heading}</Heading>
          )}
        </>
      ) : null}
      {faq.length
        ? faq.map(({ id, question, answer }) => (
            <div key={id}>
              {isOpen && (
                <>
                  <Heading level={5}>{question}</Heading>
                  <AnswerWrapper>{parseAnswer(answer)}</AnswerWrapper>
                </>
              )}
            </div>
          ))
        : null}
    </>
  );
};

const AccordionHeader = styled(Heading)`
  cursor: pointer;
  position: relative;

  &.open::after {
    transform: rotate(45deg) translate(-100%) scale(-1);
  }

  &::after {
    width: 10px;
    height: 10px;
    right: 0;
    top: 50%;
    display: block;
    content: '';
    position: absolute;
    border-style: solid;
    border-color: ${({ theme }) => theme.primary};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-100%);
  }
`;
AccordionHeader.displayName = 'AccordionHeader';

const AnswerWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing};
`;
AnswerWrapper.displayName = 'AnswerWrapper';

FaqListing.propTypes = {
  heading: PropTypes.string,
  categoryID: PropTypes.number.isRequired,
  accordion: PropTypes.bool,
};

export default memo(FaqListing);
