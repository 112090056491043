import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  text-align: ${props => (props.center ? "center" : null)};
  height: inherit;
  padding: 0 ${({ theme }) => theme.spacing};

  ${({ theme }) => theme.md`
    padding: 0 ${({ theme }) => theme.spacingMd};
  `}
`;

const Container = props => {
  const { children } = props;
  return (
    <>
      <StyledContainer {...props}>{children}</StyledContainer>
    </>
  );
};

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
