import React from 'react';
import { AuthLayout } from '../Layout';
import { useTranslation } from 'react-i18next';

const Terms = () => {
  const { t } = useTranslation();
  return (
    <div style={{ height: '100vh' }}>
      <AuthLayout showLanguageChangeIcon>
        <section id="content" style={{ width: '100%' }}>
          <div id="post-539" className="post-539 page type-page status-publish has-post-thumbnail hentry">
            <div className="post-content">
              <h2
                data-fontsize={28}
                data-lineheight="33.6px"
                className="fusion-responsive-typography-calculated"
                style={{ fontsize: 28, lineHeight: '1.2' }}
              >
                {t('terms2')} &amp; {t('terms3')}
              </h2>
              <p>
                {' '}
                {t('terms4')}
                <br />
                <b>{t('terms5')}</b>
                <br />
                {t('terms6')} &amp; {t('terms7')}
              </p>
              <p>
                {t('terms8')}
                <br />
                <strong>{t('terms9')}</strong>
                <br />
                {t('terms10')}
                <br />
                <strong>{t('terms11')}</strong>
                <br />
                {t('terms12')}
                <br />
                <strong>{t('terms13')}</strong>
                <br />
                {t('terms14')}
                <br />
                <b>{t('terms15')}</b>
                <br />
                {t('terms16')}
                <br />
                {t('terms17')}
                <br />
                {t('terms18')}{' '}
                <a href="https://supportroom.com/cookie-policy/" rel="noopener noreferrer" target="_blank">
                  {t('terms19')}
                </a>
                {t('terms20')}
                <br />
                {t('terms21')}
                <br />
                <b>{t('terms22')}</b>
                <br />
                {t('terms23')}
              </p>
              <p>
                {t('terms24')}
                <br />
                <strong>{t('terms25')}</strong>
                <br />
                {t('terms26')}
              </p>
              <p>{t('terms27')}</p>
              <p>{t('terms28')}</p>
              <p>{t('terms29')}</p>
              <p>{t('terms30')}</p>
              <p>
                {t('terms31')}
                <br />
                <strong>{t('terms32')}</strong>
                <br />
                {t('terms33')}
              </p>
              <p>
                {t('terms34')}
                <br />
                <strong>{t('terms35')}</strong>
                <br />
                {t('terms36')}
              </p>
              <p>
                {t('terms37')}
                <br />
                <b>{t('terms38')}</b>
              </p>
              <p>
                <b>{t('terms39')}</b>
                <br />
                {t('terms40')}
              </p>
              <p>
                <b>{t('terms41')}</b>
                <br />
                {t('terms42')}
                <br />
                {t('terms43')}
              </p>
              <p>
                <b>{t('terms44')}</b>
                <br />
                {t('terms45')}
                <br />
                {t('terms46')}
              </p>
              <ul>
                <li>{t('terms47')}</li>
                <li>{t('terms48')}</li>
              </ul>
              <p>
                {t('terms49')}
                <br />
                {t('terms50')}
                <br />
                {t('terms51')}
                <br />
                {t('terms52')}
                <br />
                {t('terms53')}
                <br />
                {t('terms54')}
              </p>
              <p>
                <strong>{t('terms55')}</strong>
                <br />
                {t('terms56')}
              </p>
              <p>
                {t('terms57')}
                <br />
                <b>{t('terms58')}</b>
                <br />
                {t('terms59')}
              </p>
              <p>{t('terms60')}</p>
              <p>
                {t('terms61')}
                <br />
                <b>{t('terms62')}</b>
                <br />
                {t('terms63')}
              </p>
              <p>{t('terms64')}</p>
              <p>
                {t('terms65')}
                <br />
                <b>{t('terms66')}</b>
                <br />
                <br />
                {t('terms67')}
                <br />
                {t('terms68')}
                <br />
                {t('terms69')}
                <br />
                {t('terms70')}
                <br />
                {t('terms71')}
                <br />
                {t('terms72')}
                <br />
                <b>{t('terms73')}</b>
                <br />
                <br />
                {t('terms74')}
                <br />
                <b>{t('terms75')}</b>
                <br />
                {t('terms76')}
              </p>
              <p>
                {t('terms77')}
                <br />
                <b>{t('terms78')}</b>
                <br />
                {t('terms79')}
              </p>
            </div>
          </div>
        </section>
      </AuthLayout>
    </div>
  );
};

export default Terms;
