import React, { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import FileIcon from '../../../Assets/Icons/File.svg';
import { useWindowSize } from '../../../Hooks';
import { useTranslation } from 'react-i18next';

const Bubble = ({
  item,
  item: { sent_at, sent_by_role: user, sent_by: name, message },
  currentMessage,
  previousMessage,
  isSender,
}) => {
  let messageTime = useMemo(() => (sent_at ? moment(sent_at).format('LT') : ''), [sent_at]);
  let messageDate = useMemo(() => (sent_at ? moment(sent_at).format('MMM DD YYYY') : ''), [sent_at]);
  var REFERENCE = moment();
  var TODAY = REFERENCE.clone().startOf('day');
  var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
  const { t } = useTranslation();

  function isToday(momentDate) {
    return momentDate.isSame(TODAY, 'd');
  }

  function isYesterday(momentDate) {
    return momentDate.isSame(YESTERDAY, 'd');
  }

  if (isToday(moment(messageDate))) {
    messageDate =  t('Today');
  } else if (isYesterday(moment(messageDate))) {
    messageDate = t('Yesterday');
  }

  let isSameDate = false;
  if (moment(currentMessage.sent_at).format('YYYY-MM-DD') === moment(previousMessage.sent_at).format('YYYY-MM-DD')) {
    isSameDate = true;
  }

  let isFollowUp = false;
  if (currentMessage.sent_by_role === previousMessage.sent_by_role) {
    isFollowUp = true;
  }
  const openFileLink = () => window.open(item[item.type].url);
  const viewportWidth = useWindowSize().width;

  const getMessageText = () => {
    switch (item.type) {
      case 'voice':
        return (
          <>
            <AudioPlayer viewportWidth={viewportWidth} controls>
              <source src={item?.voice?.url} type="audio/mp3" />
              {item?.voice?.name && <OpenLink onClick={openFileLink}>{item?.voice?.name}</OpenLink>}
            </AudioPlayer>
          </>
        );
      case 'file':
        return (
          <>
            {['jpeg', 'jpg', 'png', 'gif'].includes(item.file.type) ? (
              <OpenLink onClick={openFileLink}>
                <img src={item.file.url} alt={item.file.name} style={{ maxWidth: '300px', width: '100%' }} />
              </OpenLink>
            ) : item.file.type === 'webm' ? (
              <VideoPlayer width="150" height="150" src={item?.file?.url} controls></VideoPlayer>
            ) : (
              <OpenLink onClick={openFileLink}>
                <img src={FileIcon} alt="" style={{ maxWidth: '16px', marginRight: '8px' }} />{' '}
                <span>
                  {t('viewFile')} <small>({item?.file?.name})</small>
                </span>
              </OpenLink>
            )}
          </>
        );
      default:
        return typeof message === 'string' ? <span dangerouslySetInnerHTML={{ __html: message }} /> : message;
    }
  };

  return (
    <>
      {!isSameDate && messageDate && (
        <BubbleWrapper
          style={{
            alignSelf: 'center',
            backgroundColor: 'white',
            padding: '2px 10px',
            position: 'sticky',
            top: '0px',
            zIndex: 99,
            width: '115px',
            textAlign: 'center',
            border: '0.1px solid #00000026',
            // boxShadow: '0px 4px 27px rgba(73, 31, 105, 0.14)'
          }}
        >
          {messageDate}
        </BubbleWrapper>
      )}
      <BubbleWrapper
        className={`${user}${isFollowUp ? ' followUp' : ''}`}
        style={messageTime ? { paddingRight: '65px' } : null}
        isSender={isSender}
      >
        {!isFollowUp && name && <UserName>{name}:</UserName>}
        {getMessageText()}
        {messageTime && <Time>{messageTime}</Time>}
      </BubbleWrapper>
    </>
  );
};

const messageTimeStyle = (messageTime, type) => {
  if (!messageTime) return {};
  if (['voice', 'file'].includes(type)) {
    return { padding: '15px 15px 22px 15px' };
  }
  return { paddingRight: '65px' };
};

const BubbleWrapper = styled.div`
  position: relative;
  align-self: flex-start;
  overflow-wrap: break-word;

  ${({ isSender }) =>
    isSender &&
    css`
      margin-left: auto;
    `};

  max-width: 90%;

  font-size: 14px;
  border-radius: 8px;
  margin-top: ${({ theme }) => theme.spacingSm};
  padding: ${({ theme }) => theme.spacingSm};

  ${({ theme }) => theme.md`
    font-size: 15px;
    border-radius: 12px;
    margin-top: ${({ theme }) => theme.spacingMd};
    padding: ${({ theme }) => theme.spacing};
    margin-left: 0px;
  `}

  &.therapist {
    background-color: ${({ theme }) => theme.primaryLight};
  }
  &.patient {
    background-color: ${({ theme }) => theme.secondaryLight};
  }
  &.followUp {
    margin-top: 3px;
  }
`;

const UserName = styled.p`
  margin: 0;
  font-weight: ${({ theme }) => theme.fontBold};
  ${({ theme }) => theme.md`
    margin-bottom: ${({ theme }) => theme.spacingSm};
  `}
`;
const Time = styled.span`
  position: absolute;
  font-size: 11px;
  bottom: 8px;
  right: 8px;
  color: ${({ theme }) => theme.greyMedium};
`;

const OpenLink = styled.a`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-decoration: none;
  cursor: pointer;
  > * {
    flex-shrink: 0;
  }
`;

const VideoPlayer = styled.video`
  border-radius: 10px;
  object-fit: cover;
`;

const AudioPlayer = styled.audio`
  min-width: 100%;
  height: 24px;
  border-radius: 16px;

  ${({ viewportWidth }) =>
    viewportWidth < 500 &&
    css`
      width: 150px;
    `}
`;

export default memo(Bubble);
