import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';

import Popup from 'reactjs-popup';
import { Heading, Tooltip } from '../../../Components/Atoms';
import { useTranslation } from 'react-i18next';
import './Availability.scss';
import { connect, useDispatch, useSelector } from 'react-redux';
import { cancelAppointments } from '../../../Actions/chatroom.actions';
import { CommonTheme } from '../../../Styles/theme';
import { useWindowSize } from '../../../Hooks';
import { getSubscriptionStatus } from '../../../Actions/patient.actions';

export const isTimeGreaterThanNow = (localScheduleDateTime) => {
  const diff = moment.duration(moment(localScheduleDateTime).diff(moment())).asMinutes().toFixed(0);
  return Number(diff) + 31 > 0;
};

const Availability = ({ heading, slots, bookEvent, isAllowedForBooking, therapistProfile, canBookAfterCancel }) => {
  // const count = slots.length;
  // const viewportWidth = count * 90 + 'px';
  const { t } = useTranslation();
  const windowWidth = useWindowSize().width;
  const [showSuccessBook, setShowSuccessBook] = useState(false);
  const [nextAvailable, setNextAvailable] = useState('');

  const dispatch = useDispatch();
  const chatRoomId = useSelector((store) => store.chatroom.chatRoomID);
  const patientId = useSelector((store) => store.user?.profile?.id);
  const isB2b = useSelector((store) => store.user?.profile?.['B2B'] !== 0);

  const getDif = useCallback((data) => {
    const momentCurrentTime = moment();
    const diff = moment.duration(
      moment.utc(`${data.date.schedule_date} ${data.slot.schedule_time}`).local().diff(moment(momentCurrentTime)),
    );

    return Math.round(diff.asHours());
  }, []);

  const availableSlots = useMemo(() => {
    const now = moment();
    return slots.reduce((dates, date) => {
      const isWithinTwoDays = moment.duration(moment(date.schedule_date).diff(now)).asDays() <= 2;
      if (isWithinTwoDays) {
        const availableSlots = date.slots.filter((slot) => {
          return (
            getDif({ date, slot }) >= +process.env.REACT_APP_APPOINTMENT_WITHIN ||
            (slot.status === 'booked' &&
              slot?.patient_id === patientId &&
              isTimeGreaterThanNow(slot?.local_schedule_date_time))
          );
        });
        if (availableSlots.length) dates.push({ ...date, slots: availableSlots });
      } else dates.push(date);
      return dates;
    }, []);
  }, [slots, getDif]);

  const slotOnClick = async (e, data) => {
    if (getDif(data) >= process.env.REACT_APP_APPOINTMENT_WITHIN) {
      await bookEvent(e.target.getAttribute('name'));
      setShowSuccessBook(true);
      dispatch(getSubscriptionStatus());
    }
  };

  const removeSchedule = (data) => {
    // if (getDif(data) >= process.env.REACT_APP_APPOINTMENT_WITHIN) {
    dispatch(cancelAppointments(chatRoomId, data.slot.appointment_id)).then(() => {
      dispatch(getSubscriptionStatus());
    });
    // }
  };
  const formatAvailability = (date) => moment(date, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do HH:mm:ss');

  useEffect(() => {
    if (slots.length) {
      const now = moment();
      let indexOfDayAvailability = null;

      slots.every((day, index) => {
        const positiveStatus = day.slots.findIndex((slot) => {
          const scheduleHour = moment.utc(`${day.schedule_date} ${slot.schedule_time}`, 'YYYY-MM-DD HH:mm:ss').local();
          const duration = moment.duration(now.diff(scheduleHour));
          const hours = duration.asHours();

          return slot.status === 'available' && hours < 0;
        });

        if (positiveStatus !== undefined && positiveStatus >= 0) {
          indexOfDayAvailability = index;
          return false;
        }

        return true;
      });

      if (indexOfDayAvailability >= 0 && slots[indexOfDayAvailability]?.slots.length) {
        const convertedToLocal = slots[indexOfDayAvailability].slots.map((time) => ({
          ...time,
          schedule_time: moment
            .utc(`${slots[indexOfDayAvailability].schedule_date} ${time.schedule_time}`, 'YYYY-MM-DD HH:mm:ss')
            .local(),
          schedule_end_time: moment
            .utc(`${slots[indexOfDayAvailability].schedule_date} ${time.schedule_end_time}`, 'HH:mm:ss')
            .local(),
        }));

        const sortedHours = convertedToLocal.sort((a, b) => a.schedule_time.diff(b.schedule_time));

        let nextAvailability = '';
        sortedHours.forEach((h) => {
          if (h.status === 'available') {
            const scheduleHour = moment.utc(h.schedule_time, 'HH:mm:ss').local();
            const duration = moment.duration(now.diff(scheduleHour));
            const hours = duration.asHours();

            if (!nextAvailability && hours < 0) {
              nextAvailability = `${slots[indexOfDayAvailability].schedule_date} ${scheduleHour.format('HH:mm:ss')}`;
              setNextAvailable(nextAvailability);
            }
          }
        });
      }
    }
  }, [slots]);

  const handleAvailability = () => {
    if (therapistProfile?.is_online) {
      return t('yourTherapistIsOnline');
    }
    return nextAvailable ? (
      <div>
        <p style={{ marginBottom: 3 }}>{t('therapistWillBeAvailable')}</p>
        {formatAvailability(nextAvailable)}
      </div>
    ) : (
      t('notifiedWhenTherapistAvailable')
    );
  };

  return (
    <div className="availability-component">
      <div style={{ position: 'relative' }}>
        <p style={{ fontWeight: 600, color: 'green' }}>{handleAvailability()}</p>
      </div>
      {heading && <AvailabilityHeading level={4}>{heading}:</AvailabilityHeading>}
      <AvailabilityWrapper>
        <AvailabilityViewport style={{ width: `${availableSlots.length * 90}px` }}>
          {availableSlots.map((date) => {
            const isDateHavingAppointments = date.slots.findIndex(
              (slot) => slot.status === 'booked' && slot.appointment_type === 'video' && slot.patient_id === patientId,
            );
            return (
              <Popup
                modal={windowWidth < 768}
                key={date.schedule_date}
                className="availability-component"
                trigger={
                  <Slot isDateHavingAppointments={isDateHavingAppointments > -1}>
                    {moment(date.schedule_date).format('Do MMM')}
                  </Slot>
                }
                position="right center"
                closeOnDocumentClick={!showSuccessBook}
              >
                {(close) => (
                  <PopupWrapper>
                    {windowWidth < 768 && <PopupClose onClick={close}>&times;</PopupClose>}
                    <Heading level={5} align="center">
                      {t('selectTimeSlot')}
                    </Heading>
                    {!isAllowedForBooking && (
                      <Heading style={{ color: CommonTheme.statusWarning }} level={5} align="center">
                        {t('noSlotsAvailable')}
                      </Heading>
                    )}
                    <HoursWrapper>
                      {date.slots.map((slot) => {
                        let timeSlot = (
                          <SlotWrapper key={slot.schedule_id}>
                            {slot.status === 'booked' &&
                              patientId === slot.patient_id &&
                              slot.appointment_type !== 'message' && (
                                <RemoveSchedule onClick={() => removeSchedule({ slot, date })}>x</RemoveSchedule>
                              )}
                            <TimeSlot
                              allowedToBok={
                                canBookAfterCancel ? false : isAllowedForBooking && slot.status !== 'booked'
                              }
                              booked={slot.status === 'booked' && patientId === slot.patient_id}
                              isVideo={slot.appointment_type === 'video'}
                              name={slot.schedule_id}
                              onClick={(e) => slotOnClick(e, { slot, date })}
                            >
                              {moment.utc(slot.schedule_time, 'HH:mm:ss').local().format('HH:mm')}
                            </TimeSlot>
                          </SlotWrapper>
                        );

                        return canBookAfterCancel ? (
                          <Tooltip
                            id="slotBookingDisabled"
                            text={`${t('canBookAfterCancelTooltip1')} ${
                              isB2b ? t('you') : t('canBookAfterCancelTooltip3')
                            } ${t('canBookAfterCancelTooltip4')}`}
                            place="top"
                          >
                            {timeSlot}
                          </Tooltip>
                        ) : (
                          timeSlot
                        );
                      })}
                    </HoursWrapper>
                    <HistoryWrapper>
                      <div style={{ display: 'flex', marginRight: 10 }}>
                        <Dot isVideo={true} />
                        <span>{t('videoAppointment')}</span>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <Dot isVideo={false} />
                        <span>{t('messageAppointment')}</span>
                      </div>
                    </HistoryWrapper>
                  </PopupWrapper>
                )}
              </Popup>
            );
          })}
        </AvailabilityViewport>
      </AvailabilityWrapper>
      {showSuccessBook && (
        <Popup open closeOnDocumentClick={false}>
          <PopupClose onClick={() => setShowSuccessBook(false)}>&times;</PopupClose>
          <Heading level={3}>{t('videoSessBooked')}</Heading>
        </Popup>
      )}
    </div>
  );
};

const AvailabilityWrapper = styled.div`
  max-width: 270px;
  overflow-x: auto;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
    background: #bfbfbf;
  }
`;
const AvailabilityViewport = styled.div`
  overflow: hidden;
`;
const AvailabilityHeading = styled(Heading)`
  display: none;

  ${({ theme }) => theme.md`
    display: block;
  `};
`;
const Slot = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;
  width: 80px;
  height: 25px;

  background: #d2bbe2;
  border-radius: 5px;

  cursor: pointer;

  &:hover {
    background: #b08fc6;
  }

  ${({ isDateHavingAppointments }) =>
    isDateHavingAppointments &&
    css`
      background: #8fcf7f;

      &:hover {
        background: #67a059;
      }
    `}
`;

const PopupWrapper = styled.div``;

const PopupClose = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  box-shadow: 0 2px 7px 1px #8c8c8c;
  cursor: pointer;

  &:hover {
    background: #e7e7e7;
  }
`;

const HoursWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TimeSlot = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 50px;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #d2bbe2;
  border-radius: 5px;

  cursor: pointer;

  &:hover {
    background: #b08fc6;
  }

  ${({ booked, isVideo }) =>
    booked && isVideo
      ? css`
          opacity: 0.8;
          pointer-events: none;
          background: #8fcf7f !important;
          color: black;
        `
      : booked &&
        !isVideo &&
        css`
          opacity: 0.8;
          pointer-events: none;
          background: #068ec4 !important;
          color: black;
        `}

  ${({ allowedToBok }) =>
    !allowedToBok &&
    css`
      background: #cbc3c3;
      pointer-events: none;
    `}
`;

const SlotWrapper = styled.div`
  position: relative;
`;

const RemoveSchedule = styled.div`
  position: absolute;
  right: 4px;
  top: -5px;
  background: ${({ theme }) => theme.statusDanger};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  color: white;
`;

const HistoryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 10px;
  }
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ isVideo }) => (isVideo ? '#8fcf7f' : '#068ec4')};
  border-radius: 50%;
  margin-right: 10px;
`;

const mapStateToProps = (store) => ({
  therapistProfile: store.user.therapistProfile,
});

export default connect(mapStateToProps)(Availability);
