import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../../../Hooks';
import { Link } from 'react-router-dom';

import { TherapistMenu, DashboardNav } from '../../../Components/Molecules';
import { Avatar, Container } from '../../../Components/Atoms';
import { CompanyMenuLinks } from '../../../Data/Menus';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import config from '../../../Config';

const Toolbar = ({ links, viewportWidth }) => {
  const { t } = useTranslation();
  const [showProfileActions, setShowProfileActions] = useState(false);

  const profileData = useSelector((store) => store.user?.profile);
  const profileRef = useRef();

  const handleProfileClick = (e) => {
    e.stopPropagation();
    if (showProfileActions) {
      e.currentTarget.classList.remove('active');
      setShowProfileActions(false);
    } else {
      e.currentTarget.classList.add('active');
      setShowProfileActions(true);
    }
  };

  useOnClickOutside(profileRef, () => {
    setShowProfileActions(false);
    profileRef.current.classList.remove('active');
  });

  return viewportWidth > 1170 ? (
    <DashboardToolbar>
      <DashboardNavHeader>
        <LogoLink to="/t/dashboard">
          <img src={config.nameLogo} alt="" height={36} />
        </LogoLink>
      </DashboardNavHeader>

      {profileData && (
        <ToolbarItem className="profile" ref={profileRef} onClick={(e) => handleProfileClick(e)}>
          <ProfileWrapper>
            <Avatar src={profileData.profile_image ? profileData.profile_image : undefined} width={50} />
            <Name>
              {t('admin')} ({`${profileData.company_name}`})
            </Name>
          </ProfileWrapper>
          {showProfileActions && (
            <ActionsPanel>
              <ul>
                {CompanyMenuLinks(t).map((item) => (
                  <li key={item.id}>
                    <Link to={item.url}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </ActionsPanel>
          )}
        </ToolbarItem>
      )}
    </DashboardToolbar>
  ) : (
    <StyledHeader role="banner">
      <Container>
        <HeaderContent>
          <DashboardNav links={links} />
          <AltLogoLink to="/t/dashboard">
            <img src={config.nameLogo} alt="" height={36} />
          </AltLogoLink>
          <TherapistMenu />
        </HeaderContent>
      </Container>
    </StyledHeader>
  );
};

const DashboardToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
  min-height: 80px;
  border-bottom: 1px solid #ebebeb;
`;

const DashboardNavHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing};
  border-bottom: 1px solid #96fbe7;
  background-color: ${({ theme }) => theme.primary};
  width: 320px;
  height: 80px;
  margin-right: auto;
`;
const LogoLink = styled(Link)`
  display: block;
  width: 180px;
  line-height: 1;
`;

const ToolbarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => theme.spacing} ${({ theme }) => theme.spacingLg};
  border-left: 1px solid #ebebeb;
  position: relative;

  &.active {
    background-color: ${({ theme }) => theme.primaryLight};
  }

  &.profile {
    cursor: pointer;
  }
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  margin-left: ${({ theme }) => theme.spacing};
  font-weight: ${({ theme }) => theme.fontBold};
`;

const ActionsPanel = styled.div`
  position: absolute;
  background: white;
  top: 79px;
  right: -1px;
  padding: ${({ theme }) => theme.spacing};
  width: calc(100% + 2px);
  border: 1px solid #ebebeb;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 4px 27px rgba(73, 31, 105, 0.14);
  z-index: 1000;

  li {
    margin: ${({ theme }) => theme.spacingSm} 0;
  }
  a {
    font-weight: ${({ theme }) => theme.fontBold};
    font-size: 17px;
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }
`;

const StyledHeader = styled.header`
  height: ${({ theme }) => theme.headerHeight};
`;
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;
const AltLogoLink = styled(Link)`
  width: 160px;
  ${({ theme }) => theme.md`
    width: 220px;
  `}
`;

export default Toolbar;
