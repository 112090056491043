import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { AuthLayout } from '../../Layout';
import { SignupForm } from '../../Components/Organisms';
import Redirect from '../../Util/Redirect';
import { OtherSectionPrompt } from '../../Components/Molecules';
import config from '../../Config';
import { useTranslation } from 'react-i18next';

const Signup = (props) => {
  const { t } = useTranslation();
  const LoggedIn = !!localStorage.getItem('loginInfo');

  return (
    <PatientSpecialLayout>
      <Helmet>
        <title>{t("patientSignUp")}</title>
        <meta
          name="description"
          content={t("metaContent")}
        />
      </Helmet>
      <AuthLayout>
        {LoggedIn ? (
          <Redirect {...props} url="/chatroom" />
        ) : (
          <>
            <Logo src={config.nameLogo} />
            <SignupForm userType="patient" heading="Patient Sign Up" />
            <OtherSectionPrompt>
              <h5>
                {t("areYouATherapist")} <a href="/t/signup">{t("signUpHere")}</a>
              </h5>
            </OtherSectionPrompt>
          </>
        )}
      </AuthLayout>
    </PatientSpecialLayout>
  );
};

export const Logo = styled.img`
  width: 250px;
  margin: 20px 0;
  height: 80px;
  object-fit: contain;
  ${({ theme }) => theme.md`
    width: 360px;
    height: 80px;
  `}
`;

export const PatientSpecialLayout = styled.div`
  #siteWrapper {
    background: #eb01a5;
    background: url('https://mysupportroom.com/static/media/ezgif-com-gif-maker.gif') no-repeat center center fixed;
    background-size: cover;

    &:before {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: ${({ theme }) => theme.primary};
      opacity: 0.7;
    }

    #mainContent {
      z-index: 100;
      color: white;

      h1 {
        color: white;
      }

      b {
        color: white;
        text-decoration-color: white;
      }

      label {
        color: white;
      }
    }

    button {
      background: white;
      color: ${({ theme }) => theme.primary};
    }

    header {
      display: none;
      background: none;
    }
  }
`;

export default Signup;