import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { DefaultLayout } from '../../Layout';
// import TherapistInfo from './Components/TherapistInfo';
import { Typing, Button, Heading, Loading, ModalComponent } from '../../Components/Atoms';
import { useHistory } from 'react-router-dom';
import api from '../../Util/api';
import { useSelector } from 'react-redux';

const Questionnaire = () => {
  const messagesEndRef = useRef(null);
  const answerButton = useRef(null);

  /* ---------------------------------- State --------------------------------- */
  const [state, setState] = useState({
    emptyState: true,
    complete: false,
    typing: false,
    data: { answers: [] },
  });
  const [chatHistory, setChatHistory] = useState([
    {
      user: 'therapist',
      messages: ['Welcome to SupportRoom. Help us find the right therapist for you.'],
    },
  ]);
  const [selectedAnswerIDs, setSelectedAnswerIDs] = useState([]);
  const [selectedAnswerMessages, setSelectedAnswerMessages] = useState([]);
  const [submitActive, setSubmitActive] = useState(false);
  const [showRedirect, setShowRedirect] = useState(false);
  const [dataHistory, setDataHistory] = useState({});
  const [isPopupShown, setIsPopupShown] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const profile = useSelector((store) => store.user.profile);

  /* --------------------------------- Methods -------------------------------- */

  const getQuizData = useCallback(() => {
    setState((prevState) => ({ ...prevState, complete: false, typing: true }));
    setTimeout(() => {
      api.patient
        .startQuiz()
        .then((response) => response.data)
        .then((result) => {
          setState({
            ...result,
            complete: false,
            typing: false,
            emptyState: false,
          });
          setDataHistory((history) => ({ ...history, [result.data.id]: result }));
          updateChatHistory([result.data.question], 'therapist');
        })
        .catch((error) => console.log('error', error));
    }, 1000);
  }, []);

  const handleAnswerClick = (e, id, message) => {
    e.currentTarget.classList.toggle('active');
    let ids = [];
    let messages = [];
    if (selectedAnswerIDs.includes(id)) {
      ids = selectedAnswerIDs.filter((answer) => answer !== id);
      messages = selectedAnswerMessages.filter((answer) => answer !== message);
    } else {
      ids = [...selectedAnswerIDs, id];
      messages = [...selectedAnswerMessages, message];
    }

    setSelectedAnswerIDs(ids);
    setSelectedAnswerMessages(messages);

    if (state?.data?.sort_order !== 3) submitAnswers(messages, ids);
  };

  const checkNumberOfAnswers = useCallback(() => {
    if (state.data) {
      if (selectedAnswerIDs.length !== 0 && selectedAnswerIDs.length <= state.data.no_of_answer) {
        setSubmitActive(true);
      } else {
        setSubmitActive(false);
      }
    }
  }, [state, selectedAnswerIDs]);

  const isDisable = (answer) => !selectedAnswerMessages.includes(answer.answer);

  const updateChatHistory = (messageArr, userType) => {
    setChatHistory((prevState) => [
      ...prevState,
      {
        user: userType,
        messages: messageArr,
      },
    ]);
  };

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      messagesEndRef.current &&
        messagesEndRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
    }, 1);
  }, []);

  const handleQuestionnaireCompletion = useCallback(() => {
    if (state.complete && !showRedirect) {
      if (profile['B2B'] === 1) {
        setTimeout(() => {
          history.push('/moodbox');
        }, 1000);
      } else {
        setTimeout(() => {
          history.push('/therapist-assignment');
        }, 1000);
      }
    }
  }, [state, profile, showRedirect, history]);

  const redirectToHelp = useCallback(() => (window.location.href = state.redirect_link), [state]);

  const submitAnswers = useCallback(
    (messages, ids) => {
      scrollToBottom();
      setState({ ...state, complete: false, typing: true });
      updateChatHistory([...(messages || selectedAnswerMessages)], 'patient');

      setDataHistory((history) => ({ ...history, [state.data.id]: state }));

      setLoading(true);

      setTimeout(() => {
        api.patient
          .submitQuizAnswer(state.quiz_journey, state.data.id, ids || selectedAnswerIDs)
          .then((response) => response.data)
          .then((result) => {
            setLoading(false);
            if (result.redirect_link) {
              setShowRedirect(true);
              setState({ ...result, complete: true, typing: false });
            } else if (
              result.message === 'We are finding best therapist for you, please wait...' ||
              result.translation_id === 157
            ) {
              setState({ ...result, complete: true, typing: false });
            } else {
              scrollToBottom();
              setState({ ...result, complete: false, typing: false });
              updateChatHistory([result.data.question], 'therapist');
            }
            setSelectedAnswerIDs([]);
            setSelectedAnswerMessages([]);
          })
          .catch((error) => console.log('error', error));
      }, 500);
    },
    [scrollToBottom, selectedAnswerIDs, state, selectedAnswerMessages],
  );

  const goBack = () => {
    api.patient.questionnaireGoBack({ quiz_journey: `${state.quiz_journey}` }).then((res) => {
      setChatHistory([...chatHistory].slice(0, -2));
      setState({ ...dataHistory[res?.data?.data.id] });
      setSelectedAnswerIDs([]);
      setSelectedAnswerMessages([]);
    });
  };

  /* --------------------------------- Effects -------------------------------- */

  useEffect(() => {
    getQuizData();
  }, [getQuizData]);

  useEffect(() => {
    scrollToBottom();
    handleQuestionnaireCompletion();
    checkNumberOfAnswers();
    scrollToBottom();
    handleQuestionnaireCompletion();
  }, [checkNumberOfAnswers, scrollToBottom, getQuizData, handleQuestionnaireCompletion]);

  return (
    <>
      <DefaultLayout noPadding noTopBar>
        <ChatWrapper>
          {/*<TherapistInfo />*/}
          <ChatWindow>
            {showRedirect ? (
              <ConversationWindow>
                <Heading align="center">Let's get you the right type of therapy</Heading>
                <p className="lead">Based on your responses, we'd advise getting immediate assistance.</p>
                {state.redirect_link && <Button onClick={redirectToHelp}>Click here to continue</Button>}

                {/* <Loading inline /> */}
              </ConversationWindow>
            ) : state.complete ? (
              <ConversationWindow>
                <Heading align="center">We are finding best therapist for you, please wait...</Heading>
                <Loading inline />
              </ConversationWindow>
            ) : (
              <>
                <ConversationWindow>
                  {chatHistory.map((item) =>
                    item.messages.map((m, i) => <ChatHistoryItem key={i} user={item.user} message={m} />),
                  )}
                  {state.typing && <Typing />}
                  <Spacer>&nbsp;</Spacer>
                  <div ref={messagesEndRef} />
                </ConversationWindow>
                <ChatForm>
                  {!state.emptyState && (
                    <>
                      {state?.data?.sort_order > 1 && (
                        <GoBackButton onClick={goBack}>
                          <svg width="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M2.03023 9.28026L6.31048 5.00001L2.03023 0.719757L0.969727 1.78026L4.18948 5.00001L0.969727 8.21976L2.03023 9.28026Z"
                              fill="#ffffff"
                            />
                          </svg>
                          <span>Back</span>
                        </GoBackButton>
                      )}
                      {state.data.no_of_answer > 1 && (
                        <p>
                          <b>Please select up to {state.data.no_of_answer} answers.</b>
                        </p>
                      )}
                      <FormWrapper>
                        <AnswerWrapper>
                          {state.data.answers &&
                            state.data.answers.map((answer) => (
                              <AnswerOption
                                key={answer.id}
                                className="patient"
                                ref={answerButton}
                                disable={
                                  (selectedAnswerMessages.length === state?.data?.no_of_answer && isDisable(answer)) ||
                                  loading
                                }
                                onClick={(e) => handleAnswerClick(e, answer.id, answer.answer)}
                              >
                                {answer.answer}
                              </AnswerOption>
                            ))}
                        </AnswerWrapper>
                        {state?.data?.sort_order === 3 && (
                          <AnswerSubmit>
                            <Button
                              style={{ display: 'flex', alignItems: 'center' }}
                              block
                              disabled={!submitActive || loading}
                              onClick={() => submitAnswers()}
                            >
                              Next
                              <svg
                                style={{ marginLeft: 5 }}
                                width="10"
                                viewBox="0 0 7 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.03023 9.28026L6.31048 5.00001L2.03023 0.719757L0.969727 1.78026L4.18948 5.00001L0.969727 8.21976L2.03023 9.28026Z"
                                  fill="#ffffff"
                                />
                              </svg>
                            </Button>
                          </AnswerSubmit>
                        )}
                      </FormWrapper>
                    </>
                  )}
                </ChatForm>
              </>
            )}
          </ChatWindow>
        </ChatWrapper>
        {isPopupShown && (
          <ModalComponent open closeModal={() => setIsPopupShown(false)}>
            <Heading level={3}>Welcome to SupportRoom</Heading>
            <div>
              <p>Let us know how we can help.</p>
              <p>
                Hit OK, and complete our simple questionnaire. Select answers that feel closest to how you’re feeling -
                don’t worry if they don’t match precisely. We’ll use your responses to pair you up with a therapist. You
                can change your assigned therapist at any time.
              </p>
            </div>
            <ModalActions>
              <Button onClick={() => setIsPopupShown(false)}>Ok</Button>
            </ModalActions>
          </ModalComponent>
        )}
      </DefaultLayout>
    </>
  );
};

const ChatHistoryItem = ({ user, message }) => {
  const brExp = /<br\s*\/?>/i;
  const lines = message?.split(brExp).map((line, i, arr) => (
    <span key={i}>
      {line.trim()}
      {arr[i + 1] && <br />}
    </span>
  ));

  return <BubbleWrapper className={user}>{lines}</BubbleWrapper>;
};

/* --------------------------------- Styles --------------------------------- */
const ChatWrapper = styled.section`
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
`;
ChatWrapper.displayName = 'ChatWrapper';

const ChatWindow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: auto;
  height: inherit;
  ${({ theme }) => theme.md`
    padding: ${({ theme }) => theme.spacingSm} ${({ theme }) => theme.spacing} 0;
  `}
`;
ChatWindow.displayName = 'ChatWindow';

const ConversationWindow = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${({ theme }) => theme.md`
    padding: ${({ theme }) => theme.spacingLg} 0;
  `}
`;
ConversationWindow.displayName = 'ConversationWindow';

const ChatForm = styled.div`
  margin-top: ${({ theme }) => theme.spacing};
  border-top: 1px solid ${({ theme }) => theme.primaryMedium};
  padding: ${({ theme }) => theme.spacing} 0;
`;
ChatForm.displayName = 'ChatForm';

const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
FormWrapper.displayName = 'FormWrapper';

const AnswerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 150px;
  overflow: auto;

  ${({ theme }) => theme.md`
    max-height: 50%;
  `}
`;
AnswerWrapper.displayName = 'AnswerWrapper';

const AnswerOption = styled.div`
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  font-size: 14px;
  padding: 5px 8px;
  background-color: ${({ theme }) => theme.secondaryMedium};
  color: ${({ theme }) => theme.white};
  border: 2px solid transparent;

  ${({ disable }) =>
    disable &&
    css`
      background-color: ${({ theme }) => theme.greyMedium};
      pointer-events: none;
    `}

  ${({ theme }) => theme.md`
    font-size: 15px;
  `}

  &.active {
    border-color: black;
  }
`;
AnswerOption.displayName = 'AnswerOption';

const AnswerSubmit = styled.div`
  margin-left: ${({ theme }) => theme.spacingSm};

  button {
    border-radius: 8px;
    height: 35px;
    line-height: 0;
  }
`;
AnswerSubmit.displayName = 'AnswerSubmit';

const Spacer = styled.div`
  height: 64px;
`;

const BubbleWrapper = styled.div`
  position: relative;
  align-self: flex-start;
  max-width: 90%;

  font-size: 14px;
  border-radius: 8px;
  margin-top: ${({ theme }) => theme.spacingSm};
  padding: ${({ theme }) => theme.spacingSm};

  ${({ theme }) => theme.md`
    font-size: 15px;
    border-radius: 12px;
    margin-top: ${({ theme }) => theme.spacingMd};
    padding: ${({ theme }) => theme.spacing};
  `}

  &.therapist {
    background-color: ${({ theme }) => theme.primaryLight};
  }
  &.patient {
    background-color: ${({ theme }) => theme.secondaryLight};
  }
`;
BubbleWrapper.displayName = 'BubbleWrapper';

const GoBackButton = styled.div`
  margin-bottom: 10px;
  width: 70px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #444;
  background: ${({ theme }) => theme.primaryMedium};
  padding: 5px 10px;

  cursor: pointer;

  &:hover {
    background: #cfb5e3;
  }

  svg {
    margin-right: 5px;
    transform: rotate(180deg);

    path {
      fill: #444;
    }
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
ModalActions.displayName = 'ModalActions';

export default Questionnaire;
