import {
  Login,
  Logout,
  Signup,
  Dashboard,
  SignupStep2,
  SignupStep3,
  SignupStep4,
  SignupConfirm,
  SignupStep5,
  SignupStep6,
  SignupStep7,
  SignupStep8,
  SignupStep9,
  Chatroom,
  Profile,
  PatientProfile,
  Account,
  TherapyTracker,
  TherapyJourney,
  Note,
  Notes,
  Contact,
  Journal,
} from '../Therapist';

import AdminLogin from '../Therapist/Login/adminLogin';

import { WellBeing, WellBeingPost } from '../Patient';
// import Teams from '../Therapist/Teams';

export default [
  { path: '/t/login', component: Login },
  // { path: '/t/teams', component: Teams },
  // { path: '/teams', component: Teams },
  { path: '/t/admin-login', component: AdminLogin },
  { path: '/t/logout', component: Logout },
  { path: '/t/signup/complete', component: SignupConfirm },
  { path: '/t/signup', component: Signup },
  { path: '/t/signup/step-2', component: SignupStep2, isPrivate: true },
  { path: '/t/signup/step-3', component: SignupStep3, isPrivate: true },
  { path: '/t/signup/step-4', component: SignupStep4, isPrivate: true },
  { path: '/t/signup/step-5', component: SignupStep5, isPrivate: true },
  { path: '/t/signup/step-6', component: SignupStep6, isPrivate: true },
  { path: '/t/signup/step-7', component: SignupStep7, isPrivate: true },
  { path: '/t/signup/step-8', component: SignupStep8, isPrivate: true },
  { path: '/t/signup/step-9', component: SignupStep9, isPrivate: true },
  { path: '/t/dashboard/:id*', component: Dashboard, isPrivate: true },
  { path: '/t/dashboard', component: Dashboard, isPrivate: true },
  { path: '/t/chatroom/:roomID*', component: Chatroom, isPrivate: true },
  { path: '/t/chatroom', component: Chatroom, isPrivate: true },
  { path: '/t/profile', component: Profile, isPrivate: true },
  { path: '/t/patient-profile/:chatRoomId/:patientId', component: PatientProfile, isPrivate: true },
  { path: '/t/account/:id*', component: Account, isPrivate: true },
  { path: '/t/account', component: Account, isPrivate: true },
  { path: '/t/contact', component: Contact, isPrivate: true },
  { path: '/t/therapytracker/:roomID*', component: TherapyTracker, isPrivate: true },
  { path: '/t/therapy-journey/:chatRoomId/:stageId/:subStageId/:noteId*', component: TherapyJourney, isPrivate: true },
  { path: '/t/note/:chatRoomId', component: Notes, isPrivate: true },
  { path: '/t/note/:chatRoomId/:noteId', component: Note, isPrivate: true },
  { path: '/t/journal/:chatRoomId/', component: Journal, isPrivate: true },
  { path: '/t/journal/:chatRoomId/:journalId', component: Journal, isPrivate: true },
  { path: '/t/well-being', component: WellBeing, isPrivate: true },
  { path: '/t/well-being/:id', component: WellBeingPost, isPrivate: true },
];
