import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import api from '../Util/api';
import { passwordValidation } from '../Constants/validations';

import { Heading, FormGroup, Button, Alert } from '../Components/Atoms';
import { NeutralLayout } from '../Layout';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [showResponse, setShowResponse] = useState(false);
  const [state, setState] = useState({
    showError: false,
    errorMessage: 'false',
  });

  const handleOnSubmit = (values, actions) => {
    let formData = new FormData();
    formData.append('email', values.email);
    formData.append('reset_code', values.reset_code);
    formData.append('new_password', values.new_password);
    formData.append('confirm_password', values.confirm_password);

    api.auth
      .resetPassword(formData)
      .then((response) => {
        actions.setSubmitting(false);
        actions.resetForm();
        if (response.status === 200) {
          setShowResponse(true);
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        console.error(error.response);
        setState({
          showError: true,
          errorMessage: error.response.data.message,
        });
      });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("emailIsInvalid")).required(t("emailIsRequired")),
    reset_code: Yup.string().required(t("resetCodeIsRequired")),
    new_password: passwordValidation(t),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], t("passwordsMustMatch"))
      .required(t("confirmPasswordIsRequired")),
  });

  return (
    <NeutralLayout>
      <Wrapper>
        {showResponse ? (
          <>
            <Heading>{t("thankYou1")}</Heading>
            <p className="lead">{t("passwordReset")}</p>
            <br />
            <br />
            <Button as={Link} to="/" className="secondary">
            {t("returnToLogin")}
            </Button>
          </>
        ) : (
          <>
            <Heading>{t("forgottenPassword")}</Heading>
            <p className="lead">{t("emailLinkToResetYourPassword")}</p>
            <Formik
              initialValues={{
                email: '',
                reset_code: '',
                new_password: '',
                confirm_password: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleOnSubmit}
              render={({ errors, touched }) => (
                <Form>
                  <FormGroup>
                    <Field
                      id="email"
                      name="email"
                      placeholder={t("emailAddress")}
                      type="email"
                      className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      id="reset_code"
                      name="reset_code"
                      placeholder={t("enterYourResetCode")}
                      type="text"
                      className={'form-control' + (errors.reset_code && touched.reset_code ? ' is-invalid' : '')}
                    />
                    <ErrorMessage name="reset_code" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      id="new_password"
                      name="new_password"
                      placeholder={t("newPassword")}
                      type="password"
                      className={'form-control' + (errors.new_password && touched.new_password ? ' is-invalid' : '')}
                    />
                    <ErrorMessage name="new_password" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      id="confirm_password"
                      name="confirm_password"
                      placeholder={t("confirmNewPassword")}
                      type="password"
                      className={
                        'form-control' + (errors.confirm_password && touched.confirm_password ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage name="confirm_password" component="div" className="invalid-feedback" />
                  </FormGroup>
                  <Button type="submit">{t("resetPassword")}</Button>

                  {state.showError && (
                    <Alert className="danger" addMargin>
                      {state.errorMessage}
                    </Alert>
                  )}
                </Form>
              )}
            />
          </>
        )}
      </Wrapper>
    </NeutralLayout>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

export default ResetPassword;
