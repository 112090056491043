import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, ChevronIcon, TextBlock, Logo, FormGroup } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import { MainSectionWrapper, BottomSectionWrapper, ModalOverlay } from '../Components/';
import { useTranslation } from 'react-i18next';

const AddPayment = () => {
  const { t } = useTranslation();
  const [addingPayment, setAddingPayment] = useState(false);

  const handleOnSubmit = async (values) => {
    //Payment method add api needs to be called
    setAddingPayment(true);
  };

  const validationSchema = useMemo(() => {
    const validations = {
      name: Yup.string().required(t("nameIsRequired")),
      card_number: Yup.string().required(t("cardNumberRequired")),
      unique_code: Yup.string(),
      cvc: Yup.string().required(t("cvcIsRequired")),
      expiry: Yup.string()
        .matches(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/, t("invalidDateFormat"))
        .required(t("expiryIsRequired")),
    };
    return Yup.object().shape(validations);
  }, []);

  const initialValues = useMemo(() => {
    return {
      name: '',
      card_number: '',
      unique_code: '',
      cvc: '',
      expiry: '',
    };
  }, []);

  return (
    <WelcomeLayout noPadding noTopBar>
      <section>
        <MainSectionWrapper>
          <Logo />
          <TextBlock type={'primary'}>{t("addPaymentDetails")}</TextBlock>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleOnSubmit}
          >
            <Form>
              <StyledFormGroup>
                <FieldWrapper>
                  <Field id="name" name="name" type="text" className={'form-control'} />
                  <label htmlFor="name">{t("cardholderName")}</label>
                  <ErrorMessage name="name" component="div" className="invalid-feedback" />
                </FieldWrapper>
                <FieldWrapper>
                  <Field id="unique_code" name="unique_code" type="text" className={'form-control'} />
                  <label htmlFor="unique_code">{t("uniqueCode")}</label>
                  <ErrorMessage name="unique_code" component="div" className="invalid-feedback" />
                </FieldWrapper>
              </StyledFormGroup>
              <StyledFormGroup>
                <FieldWrapper>
                  <Field id="card_number" name="card_number" type="text" className={'form-control'} minLength="16" />
                  <label htmlFor="card_number">{t("longCardNumber")}</label>
                  <ErrorMessage name="card_number" component="div" className="invalid-feedback" />
                </FieldWrapper>
              </StyledFormGroup>
              <StyledFormGroup>
                <FieldWrapper>
                  <Field id="expiry" name="expiry" type="text" className={'form-control'} />
                  <label htmlFor="expiry">{t("expiry")}</label>
                  <ErrorMessage name="expiry" component="div" className="invalid-feedback" />
                </FieldWrapper>
                <FieldWrapper>
                  <Field id="cvc" name="cvc" type="text" className={'form-control'} minLength="3" />
                  <label htmlFor="cvc">{t("cvc")}</label>
                  <ErrorMessage name="cvc" component="div" className="invalid-feedback" />
                </FieldWrapper>
              </StyledFormGroup>
              <BottomSectionWrapper>
                <Button
                  variant="secondary"
                  style={{
                    fontWeight: 600,
                    color: 'white',
                    background: '#33c8a0',
                    width: '300px',
                    padding: '15px 0 15px 0',
                    marginBottom: '40px',
                  }}
                  type="submit"
                >
                  {t("submit")}
                  <ChevronIcon direction="right" width="13" height="13" />
                </Button>
              </BottomSectionWrapper>
            </Form>
          </Formik>
        </MainSectionWrapper>
      </section>
      {addingPayment && (
        <ModalOverlay>
          <LoadingLabel>{t("finishingSetUp")}</LoadingLabel>
        </ModalOverlay>
      )}
    </WelcomeLayout>
  );
};

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: initial;
  label,
  .invalid-feedback {
    margin-left: 8px;
  }

  input,
  select {
    width: 100%;
    height: 50px;
    flex-grow: initial;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    &:focus {
      border: 2px solid #00c8a0;
    }
  }

  input {
    svg {
      path {
        fill: #797979 !important;
      }
    }
  }

  label {
    span {
      border-radius: 0;
      border-color: #c4c4c4;

      a {
        color: black;
      }
    }

    &:hover {
      .checkmark {
        background: #f6f6f6;
      }
    }
  }

  input:checked ~ .checkmark {
    background-color: #00c8a0;
  }
`;
export const FieldWrapper = styled.div`
  width: 100%;
  margin-right: 5px;
  input {
    margin-top: 5px;
  }

  select {
    margin-top: 5px;
  }

  label {
    font-size: 13px;
    color: gray;
  }
`;
const LoadingLabel = styled.div`
  color: white;
  font-weight: 300;
`;
export default AddPayment;
