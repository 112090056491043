import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AuthLayout } from '../../Layout';
import { Alert, Button, Heading } from '../../Components/Atoms';
import { Link } from 'react-router-dom';
import api from '../../Util/api';

const SignupConfirm = () => {
  const [userInfo, setUserInfo] = useState(undefined);

  const getUser = () => {
    api.therapist
      .getProfile()
      .then((response) => response.data)
      .then((user) => {
        setUserInfo(user.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!window.location.pathname.includes('signup/complete')) getUser();
  }, []);

  return (
    <AuthLayout>
      <Wrapper>
        {userInfo ? (
          <>
            <Heading>Hello {userInfo?.first_name}, Welcome to Support Room</Heading>
            <p className="lead">
              Your profile status is: <b>{userInfo.profile_status}</b>
            </p>

            <br />
            <Button as={Link} to="/t/dashboard">
              View My Dashboard
            </Button>
          </>
        ) : (
          <>
            <Heading>Welcome to Support Room</Heading>
            <p className="lead">It's great to have you as part of our powerful online therapy platform.</p>
            <Alert className="info" addMargin>
              Please check your email to verify your account (check spam).
            </Alert>
            <Button as={Link} to="/t/login" className="secondary">
              Login
            </Button>
          </>
        )}
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  text-align: center;
`;

export default SignupConfirm;
