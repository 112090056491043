import React from "react";
import styled from "styled-components";
import { Label } from "..";
import { Field } from "formik";

const Checkbox = props => {
  const { label, basic, ...other } = props;

  return (
    <StyledLabel>
      {label}
      {basic ? (
        <StyledInput type="checkbox" {...other} />
      ) : (
        <StyledField type="checkbox" {...other} />
      )}
      <StyledCheckmark className="checkmark" />
    </StyledLabel>
  );
};

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.primary};
  font-weight: ${({ theme }) => theme.fontBold};

  position: relative;
  padding-left: ${({ theme }) => theme.spacingLg};
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display:inline-block;
  width:auto;
  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: ${({ theme }) => theme.greyLight};
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.primary};
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 7px;
    top: 1px;
    width: 7px;
    height: 16px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  /* The label inside the check box, add padding top to allign items to center */
  span{
    padding-top:4px;
    display:inline-block;
  }
`;
const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  display:none;
  cursor: pointer;
  height: 0;
  width: 0;
`;
const StyledField = styled(Field)`
  position: absolute;
  opacity: 0;
  display:none;
  cursor: pointer;
  height: 0;
  width: 0;
`;
const StyledCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.primary};

  /* Create the checkmark/indicator (hidden when not checked) */
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export default Checkbox;
